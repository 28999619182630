define("funnelytics/templates/components/canvas-elements/context-menu/tracking-settings/advanced-actions/attribute-values", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3N3gp43B",
    "block": "{\"symbols\":[\"attributeValue\"],\"statements\":[[4,\"each\",[[23,[\"attributeValues\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"canvas-elements/context-menu/tracking-settings/advanced-actions/dropdown-selection\",null,[[\"name\",\"hits\",\"onClick\",\"displayNameConverter\"],[[22,1,[\"value\"]],[22,1,[\"hits\"]],[27,\"action\",[[22,0,[]],\"setAttributeValue\"],null],[23,[\"actionValueDisplayConverter\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/context-menu/tracking-settings/advanced-actions/attribute-values.hbs"
    }
  });

  _exports.default = _default;
});