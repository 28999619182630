define("funnelytics/components/upsells/performance-lite", ["exports", "funnelytics/custom/constants/MembershipConstants"], function (_exports, _MembershipConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    /**
     * @params {Function} onClose - function to close the modal
     * @params {string} buttonUrl - url for the button
     * @params {string} buttonText - text for the button
     * @params {string} isEligibleForFreeTrial - boolean to check if the user is eligible for free trial
     */
    upsellManager: Ember.inject.service(),
    router: Ember.inject.service(),
    unlockCanvases: Ember.inject.service(),
    isUpgradeModalOpen: Ember.computed('upsellManager.showPerformanceUpsell', 'upsellManager.showPerformanceProUpsell', function () {
      return this.get('upsellManager.showPerformanceProUpsell') && this.get('upsellManager.showPerformanceUpsell');
    }),
    showUnlockNotice: Ember.computed('upsellManager.showUnlockNotice', function () {
      return this.upsellManager.showUnlockNotice;
    }),
    performancePlusAlt: Ember.computed('upsellManager.performanceProUpsellToPerformancePlus', function () {
      return this.upsellManager.performanceProUpsellToPerformancePlus;
    }),
    // With recent plans changes, only starting with Performance Business user has unlimited canvases
    planName: Ember.computed('performancePlusAlt', function () {
      return _MembershipConstants.MembershipDisplayNames[_MembershipConstants.Membership.isPerformancePlus];
    }),
    unlocksAllowed: Ember.computed('unlockCanvases.unlocksAllowed', function () {
      return this.get('unlockCanvases.unlocksAllowed');
    })
  }, _defineProperty(_EmberComponent$exte, "unlocksAllowed", Ember.computed('unlockCanvases.unlocksAllowed', function () {
    return this.get('unlockCanvases.unlocksAllowed');
  })), _defineProperty(_EmberComponent$exte, "onClose", function onClose() {}), _defineProperty(_EmberComponent$exte, "actions", {
    close: function close() {
      var closeHandler = this.get('onClose');
      closeHandler();
    },
    acceptOffer: function acceptOffer(offeredPlan) {
      this.get('onClose')();
      this.router.transitionTo('dashboard.settings.my-subscriptions', {
        queryParams: {
          h: offeredPlan
        }
      });
    }
  }), _EmberComponent$exte));

  _exports.default = _default;
});