define("funnelytics/helpers/has-product", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasProduct = hasProduct;
  _exports.default = void 0;

  function hasProduct(params, hash) {
    var products = hash.products;
    var product = hash.product;

    if (products.toArray) {
      products = _lodash.default.map(products.toArray(), function (product) {
        if (product.getProperties) {
          return product.get('id');
        }

        return product;
      });
    }

    if (product instanceof _emberData.default.Model) {
      product = product.get('id');
    }

    return _lodash.default.map(products, function (product) {
      return product.toLowerCase();
    }).indexOf(product.toLowerCase()) > -1;
  }

  var _default = Ember.Helper.helper(hasProduct);

  _exports.default = _default;
});