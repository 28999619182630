define("funnelytics/components/canvas-elements/explorer", ["exports", "@funnelytics/utilities", "lodash", "funnelytics/custom/canvas/ContextMenu/ContextMenuConstants", "funnelytics/custom/constants/CanvasConstants", "funnelytics/custom/debug/logger", "funnelytics/custom/analytics/analytics/canvas/EntityParameter", "funnelytics/custom/constants/AnalyticsConstants", "funnelytics/custom/analytics/settings/config/standard-action-names-mapping", "funnelytics/custom/analytics/settings/config/standard-action-keys-mapping", "funnelytics/custom/constants/TrackingConstants", "funnelytics/custom/tracking/ProductTracker", "funnelytics/custom/constants/Upsell"], function (_exports, _utilities, _lodash, _ContextMenuConstants, _CanvasConstants, _logger, _EntityParameter, _AnalyticsConstants, _standardActionNamesMapping, _standardActionKeysMapping, _TrackingConstants, _ProductTracker, _Upsell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var CATEGORY_PAGES = 'pages';
  var CATEGORY_ACTIONS = 'actions';
  var CATEGORY_ATTRIBUTES = 'attributes';
  var CATEGORY_PARAMETERS = 'parameters';
  var CATEGORY_TRAFFIC = 'traffic';

  function filteredMappedParameters(parameters, mappedParameters) {
    var mappedParameterKeys = mappedParameters.map(function (entityParameter) {
      return entityParameter.getKey();
    });
    /**
     * Prevent already mapped entity parameters from appearing in explore lists.
     */

    return parameters.filter(function (parameter) {
      return !mappedParameterKeys.includes(_lodash.default.get(parameter, [_AnalyticsConstants.default.ATTRIBUTE_KEY]));
    });
  }

  var ALL_STEPS_LABEL = 'All Steps';
  var NEXT_STEPS_LABEL = 'Next Steps';
  var PREVIOUS_STEPS_LABEL = 'Previous Steps';
  var STEP_ATTRIBUTES_LABEL = 'All Steps';
  var STEPS_LABEL = 'Steps';
  var STEP_CATEGORY_NEXT_PAGES_IMMEDIATE = 'Next pages';
  var STEP_CATEGORY_NEXT_PAGES_ALL = 'All next pages';
  var STEP_CATEGORY_NEXT_ACTIONS_IMMEDIATE = 'Next actions';
  var STEP_CATEGORY_NEXT_ACTIONS_ALL = 'All next Actions';
  var STEP_CATEGORY_PREVIOUS_PAGES_IMMEDIATE = 'Previous pages';
  var STEP_CATEGORY_PREVIOUS_PAGES_ALL = 'All previous pages';
  var STEP_CATEGORY_PREVIOUS_ACTIONS_ALL = 'All previous actions';
  var STEP_CATEGORY_PREVIOUS_PARAMETERS_IMMEDIATE = 'Traffic sources';
  var STEP_CATEGORY_PREVIOUS_PARAMETERS_ALL = 'All traffic sources';
  var CATEGORY_LABEL = 'label';
  var CATEGORY_IGNORE_IN_BETWEEN = 'isIgnoreInBetween';

  var _default = Ember.Component.extend({
    canvasService: Ember.inject.service(),
    analyticsService: Ember.inject.service(),
    category: CATEGORY_PAGES,
    isShowingPages: Ember.computed.equal('category', CATEGORY_PAGES),
    isShowingActions: Ember.computed.equal('category', CATEGORY_ACTIONS),
    isShowingAttributes: Ember.computed.equal('category', CATEGORY_ATTRIBUTES),
    isShowingParameters: Ember.computed.equal('category', CATEGORY_PARAMETERS),
    isShowingTraffic: Ember.computed.equal('category', CATEGORY_TRAFFIC),
    upsellTextStartTrial: _Upsell.UPSELL_TEXTS.start_free_trial,
    upsellUrlStartTrial: _Upsell.UPSELL_URLS.start_free_trial,
    hasNoAnalyticsReadPermission: Ember.computed.not('canvasService.hasAnalyticsReadPermission'),
    standardActionKeysMapping: _standardActionKeysMapping.default,
    isLinkingPreviousSteps: Ember.computed.equal('initialEntityType', _ContextMenuConstants.default.INITIAL_ENTITY_TYPE_TARGET),
    isLinkingNextSteps: Ember.computed.equal('initialEntityType', _ContextMenuConstants.default.INITIAL_ENTITY_TYPE_SOURCE),
    isExploreTypeStep: Ember.computed.equal('initialEntityType', _ContextMenuConstants.default.INITIAL_ENTITY_TYPE_EXPLORE),
    isExplorerExploredEntityAvailable: Ember.computed.readOnly('analyticsService.isExplorerExploredEntityAvailable'),
    actionsLabel: 'Actions',
    pagesLabel: 'Pages',
    trafficLabel: 'Traffic',
    explorerLabel: Ember.computed('isExplorerExploredEntityAvailable', 'isLinkingNextSteps', 'isLinkingPreviousSteps', function () {
      if (!this.get('isExplorerExploredEntityAvailable')) {
        return ALL_STEPS_LABEL;
      }

      if (this.get('isLinkingNextSteps')) {
        return NEXT_STEPS_LABEL;
      }

      if (this.get('isLinkingPreviousSteps')) {
        return PREVIOUS_STEPS_LABEL;
      }

      if (this.get('isExploreTypeStep')) {
        return STEP_ATTRIBUTES_LABEL;
      }

      return STEPS_LABEL;
    }),
    explorerLabelDisplay: Ember.computed('explorerLabel', 'isShowingTraffic', function () {
      var explorerLabel = this.get('explorerLabel');

      if (this.get('isShowingTraffic') && explorerLabel === PREVIOUS_STEPS_LABEL) {
        return STEP_CATEGORY_PREVIOUS_PARAMETERS_IMMEDIATE;
      }

      return explorerLabel;
    }),
    hasExplorerStepCategories: Ember.computed('exploredEntity', 'explorerLabel', 'category', 'isShowingPages', 'isShowingActions', 'isShowingTraffic', function () {
      var exploredEntity = this.get('exploredEntity');

      if (!exploredEntity) {
        return false;
      }

      var explorerLabel = this.get('explorerLabel');

      if (explorerLabel === NEXT_STEPS_LABEL) {
        var stepTypesWithCategories = [_CanvasConstants.default.TYPE_PAGE, _CanvasConstants.default.TYPE_EVENT, _CanvasConstants.default.TYPE_SOURCE];
        return stepTypesWithCategories.includes(exploredEntity.getType());
      }

      if (explorerLabel === PREVIOUS_STEPS_LABEL) {
        if (exploredEntity.getType() === _CanvasConstants.default.TYPE_PAGE && (this.get('isShowingPages') || this.get('isShowingActions')) || this.get('isShowingTraffic')) {
          return true;
        }

        if (exploredEntity.getType() === _CanvasConstants.default.TYPE_EVENT && (this.get('isShowingPages') || this.get('isShowingActions') || this.get('isShowingTraffic'))) {
          return true;
        }
      }

      return false;
    }),
    explorerStepCategories: Ember.computed('hasExplorerStepCategories', 'exploredEntity', 'explorerLabel', 'isShowingPages', 'isShowingActions', function () {
      if (!this.get('hasExplorerStepCategories')) {
        return [];
      }

      var exploredEntity = this.get('exploredEntity');

      if (!exploredEntity) {
        return [];
      }

      var explorerLabel = this.get('explorerLabel');

      switch (exploredEntity.getType()) {
        case _CanvasConstants.default.TYPE_SOURCE:
          if (explorerLabel !== NEXT_STEPS_LABEL) {
            return [];
          }

          if (this.get('isShowingPages')) {
            var _ref, _ref2;

            return [(_ref = {}, _defineProperty(_ref, CATEGORY_LABEL, STEP_CATEGORY_NEXT_PAGES_IMMEDIATE), _defineProperty(_ref, CATEGORY_IGNORE_IN_BETWEEN, false), _ref), (_ref2 = {}, _defineProperty(_ref2, CATEGORY_LABEL, STEP_CATEGORY_NEXT_PAGES_ALL), _defineProperty(_ref2, CATEGORY_IGNORE_IN_BETWEEN, true), _ref2)];
          } else if (this.get('isShowingActions')) {
            return [{
              label: STEP_CATEGORY_NEXT_ACTIONS_ALL,
              isIgnoreInBetween: true
            }];
          }

          break;

        case _CanvasConstants.default.TYPE_PAGE:
          if (this.get('isShowingPages')) {
            if (explorerLabel === NEXT_STEPS_LABEL) {
              var _ref3, _ref4;

              return [(_ref3 = {}, _defineProperty(_ref3, CATEGORY_LABEL, STEP_CATEGORY_NEXT_PAGES_IMMEDIATE), _defineProperty(_ref3, CATEGORY_IGNORE_IN_BETWEEN, false), _ref3), (_ref4 = {}, _defineProperty(_ref4, CATEGORY_LABEL, STEP_CATEGORY_NEXT_PAGES_ALL), _defineProperty(_ref4, CATEGORY_IGNORE_IN_BETWEEN, true), _ref4)];
            } else if (explorerLabel === PREVIOUS_STEPS_LABEL) {
              var _ref5, _ref6;

              return [(_ref5 = {}, _defineProperty(_ref5, CATEGORY_LABEL, STEP_CATEGORY_PREVIOUS_PAGES_IMMEDIATE), _defineProperty(_ref5, CATEGORY_IGNORE_IN_BETWEEN, false), _ref5), (_ref6 = {}, _defineProperty(_ref6, CATEGORY_LABEL, STEP_CATEGORY_PREVIOUS_PAGES_ALL), _defineProperty(_ref6, CATEGORY_IGNORE_IN_BETWEEN, true), _ref6)];
            }
          } else if (this.get('isShowingActions')) {
            if (explorerLabel === NEXT_STEPS_LABEL) {
              var _ref7, _ref8;

              return [(_ref7 = {}, _defineProperty(_ref7, CATEGORY_LABEL, STEP_CATEGORY_NEXT_ACTIONS_IMMEDIATE), _defineProperty(_ref7, CATEGORY_IGNORE_IN_BETWEEN, false), _ref7), (_ref8 = {}, _defineProperty(_ref8, CATEGORY_LABEL, STEP_CATEGORY_NEXT_ACTIONS_ALL), _defineProperty(_ref8, CATEGORY_IGNORE_IN_BETWEEN, true), _ref8)];
            } else if (explorerLabel === PREVIOUS_STEPS_LABEL) {
              var _ref9;

              return [(_ref9 = {}, _defineProperty(_ref9, CATEGORY_LABEL, STEP_CATEGORY_PREVIOUS_ACTIONS_ALL), _defineProperty(_ref9, CATEGORY_IGNORE_IN_BETWEEN, true), _ref9)];
            }
          } else if (this.get('isShowingTraffic')) {
            if (explorerLabel === PREVIOUS_STEPS_LABEL) {
              var _ref10, _ref11;

              return [(_ref10 = {}, _defineProperty(_ref10, CATEGORY_LABEL, STEP_CATEGORY_PREVIOUS_PARAMETERS_IMMEDIATE), _defineProperty(_ref10, CATEGORY_IGNORE_IN_BETWEEN, false), _ref10), (_ref11 = {}, _defineProperty(_ref11, CATEGORY_LABEL, STEP_CATEGORY_PREVIOUS_PARAMETERS_ALL), _defineProperty(_ref11, CATEGORY_IGNORE_IN_BETWEEN, true), _ref11)];
            }
          }

          break;

        case _CanvasConstants.default.TYPE_EVENT:
          if (explorerLabel === NEXT_STEPS_LABEL) {
            if (this.get('isShowingPages')) {
              var _ref12, _ref13;

              return [(_ref12 = {}, _defineProperty(_ref12, CATEGORY_LABEL, STEP_CATEGORY_NEXT_PAGES_IMMEDIATE), _defineProperty(_ref12, CATEGORY_IGNORE_IN_BETWEEN, false), _ref12), (_ref13 = {}, _defineProperty(_ref13, CATEGORY_LABEL, STEP_CATEGORY_NEXT_PAGES_ALL), _defineProperty(_ref13, CATEGORY_IGNORE_IN_BETWEEN, true), _ref13)];
            } else if (this.get('isShowingActions')) {
              var _ref14;

              return [(_ref14 = {}, _defineProperty(_ref14, CATEGORY_LABEL, STEP_CATEGORY_NEXT_ACTIONS_ALL), _defineProperty(_ref14, CATEGORY_IGNORE_IN_BETWEEN, true), _ref14)];
            }
          } else if (explorerLabel === PREVIOUS_STEPS_LABEL) {
            if (this.get('isShowingPages')) {
              var _ref15, _ref16;

              return [(_ref15 = {}, _defineProperty(_ref15, CATEGORY_LABEL, STEP_CATEGORY_PREVIOUS_PAGES_IMMEDIATE), _defineProperty(_ref15, CATEGORY_IGNORE_IN_BETWEEN, false), _ref15), (_ref16 = {}, _defineProperty(_ref16, CATEGORY_LABEL, STEP_CATEGORY_PREVIOUS_PAGES_ALL), _defineProperty(_ref16, CATEGORY_IGNORE_IN_BETWEEN, true), _ref16)];
            } else if (this.get('isShowingActions')) {
              var _ref17;

              return [(_ref17 = {}, _defineProperty(_ref17, CATEGORY_LABEL, STEP_CATEGORY_PREVIOUS_ACTIONS_ALL), _defineProperty(_ref17, CATEGORY_IGNORE_IN_BETWEEN, true), _ref17)];
            } else if (this.get('isShowingTraffic')) {
              var _ref18;

              return [(_ref18 = {}, _defineProperty(_ref18, CATEGORY_LABEL, STEP_CATEGORY_PREVIOUS_PARAMETERS_ALL), _defineProperty(_ref18, CATEGORY_IGNORE_IN_BETWEEN, true), _ref18)];
            }
          }

          break;
      }

      return [];
    }),
    hasOnlyStepCategory: Ember.computed.equal('explorerStepCategories.length', 1),
    firstExplorerStepCategoryLabel: Ember.computed('explorerStepCategories', function () {
      var categories = this.get('explorerStepCategories');

      if (!categories || _lodash.default.get(categories, 'length') <= 0) {
        return null;
      }

      var firstCategory = _lodash.default.first(categories);

      return _lodash.default.get(firstCategory, [CATEGORY_LABEL]);
    }),
    OBSERVE_STEP_CATEGORIES: Ember.observer('explorerStepCategories.[]', function () {
      this.defaultToFirstStepCategory();
    }),
    defaultToFirstStepCategory: function defaultToFirstStepCategory() {
      var stepCategories = this.get('explorerStepCategories');

      if (!stepCategories || stepCategories.length === 0) {
        this.setStepCategory(null);
        return;
      }

      var firstCategory = _lodash.default.first(stepCategories);

      this.setStepCategory(firstCategory[CATEGORY_LABEL], firstCategory[CATEGORY_IGNORE_IN_BETWEEN]);
    },

    /**
     * Here we ensure that a valid category is open since some are not available in certain
     * modes of the explorer.
     */
    INITIAL_ENTITY_TYPE_OBSERVER: Ember.observer('initialEntityType', function () {
      this.openCorrectCategory();
    }),
    openCorrectCategory: function openCorrectCategory() {
      var exploredEntity = this.get('exploredEntity');

      if (exploredEntity) {
        if (this.get('isExploreTypeStep')) {
          if (exploredEntity.getType() === _CanvasConstants.default.TYPE_EVENT) {
            this.send('setCategoryAttributes');
          } else if ([_CanvasConstants.default.TYPE_PAGE, _CanvasConstants.default.TYPE_SOURCE].includes(exploredEntity.getType())) {
            this.send('setCategoryParameters');
          } else {
            _logger.default.error("Invalid entity type \"".concat(exploredEntity.getType(), "\" for initialEntityType \"").concat(this.get('initialEntityType'), "\""));
          }
        } else {
          // Every other type defaults to pages
          this.send('setCategoryPages');
        }
      }
    },
    didInsertElement: function didInsertElement() {
      this.openCorrectCategory();
      this.defaultToFirstStepCategory();
    },
    isLoading: Ember.computed.readOnly('data.isLoading'),

    /**
     * Allows for old data to persist when refreshing explorer data.
     */
    isShowingLoading: Ember.computed('isLoading', 'hasNoCustomParameters', 'hasNoCommonParameters', 'hasNoActionAttributes', 'hasNoExplorerActions', 'hasNoPages', function () {
      if (!this.get('isLoading')) {
        return false;
      }
      /**
       * If current data does not exist, show loading state:
       */


      return this.get('hasNoCustomParameters') && this.get('hasNoCommonParameters') && this.get('hasNoActionAttributes') && this.get('hasNoPages') && this.get('hasNoExplorerActions');
    }),
    isPageExplorerAllowed: Ember.computed('exploredEntity', 'isLinkingPreviousSteps', 'isExploreTypeStep', function () {
      var entity = this.get('exploredEntity');

      if (!entity) {
        // Can view all pages
        return true;
      }

      if (this.get('isExploreTypeStep')) {
        // Page explorer cannot be seen when exploring an entity directly
        return false;
      } // Traffic entities do not have previous steps


      if (entity.getType() === _CanvasConstants.default.TYPE_SOURCE && this.get('isLinkingPreviousSteps')) {
        return false;
      }

      return [_CanvasConstants.default.TYPE_SOURCE, _CanvasConstants.default.TYPE_EVENT, _CanvasConstants.default.TYPE_PAGE].includes(entity.getType());
    }),
    isActionsExplorerAllowed: Ember.computed('isLinkingNextSteps', 'isLinkingPreviousSteps', 'exploredEntity', function () {
      var entity = this.get('exploredEntity');

      if (!entity) {
        // Can view all actions
        return true;
      }

      if (this.get('isLinkingPreviousSteps') && [_CanvasConstants.default.TYPE_PAGE, _CanvasConstants.default.TYPE_EVENT].includes(entity.getType())) {
        return true;
      }

      if (!this.get('isLinkingNextSteps')) {
        return false;
      } // Only page and traffic steps have access to actions


      return [_CanvasConstants.default.TYPE_PAGE, _CanvasConstants.default.TYPE_EVENT, _CanvasConstants.default.TYPE_SOURCE].includes(entity.getType());
    }),
    isTrafficExplorerAllowed: Ember.computed('isLinkingPreviousSteps', 'exploredEntity', function () {
      var entity = this.get('exploredEntity');

      if (!entity) {
        // Can create new traffic entities
        return true;
      }

      if (!this.get('isLinkingPreviousSteps')) {
        // If focused, new traffic entities should be mapped as previous steps for the explored entity.
        return false;
      }

      return [_CanvasConstants.default.TYPE_PAGE, _CanvasConstants.default.TYPE_EVENT].includes(entity.getType());
    }),
    exploredEntityName: Ember.computed('exploredEntity', function () {
      var entity = this.get('exploredEntity');

      if (!entity) {
        return '';
      }

      if (entity.getType() !== _CanvasConstants.default.TYPE_EVENT) {
        return '';
      }

      return entity.getName();
    }),
    exploredEntityDisplayName: Ember.computed('exploredEntityName', function () {
      var name = this.get('exploredEntityName');
      return this.actionNameDisplayConverter(name);
    }),
    isRecognizedActionName: Ember.computed('exploredEntityDisplayName', 'exploredEntityName', function () {
      return this.get('exploredEntityDisplayName') !== this.get('exploredEntityName');
    }),
    hasEntityName: Ember.computed.notEmpty('exploredEntityName'),
    exploredEntityUrl: Ember.computed('exploredEntity', function () {
      var entity = this.get('exploredEntity');

      if (!entity) {
        return '';
      }

      if (entity.getType() !== _CanvasConstants.default.TYPE_PAGE) {
        return '';
      }

      return entity.getUrl();
    }),
    hasEntityUrl: Ember.computed.notEmpty('exploredEntityUrl'),
    entityParameters: Ember.computed('exploredEntity', function () {
      var entity = this.get('exploredEntity');

      if (!entity) {
        return [];
      }

      return entity.getAttributes();
    }),
    hasEntityParameters: Ember.computed.notEmpty('entityParameters'),
    parameterCount: Ember.computed.readOnly('data.pageParameterCount'),
    actionCount: Ember.computed.readOnly('data.actionAttributesCount'),
    actionAttributesUnfiltered: Ember.computed.readOnly('data.actionAttributes'),
    actionAttributes: Ember.computed('actionAttributesUnfiltered.@each', 'entityParameters.@each', function () {
      return filteredMappedParameters(this.get('actionAttributesUnfiltered'), this.get('entityParameters'));
    }),
    hasNoActionAttributes: Ember.computed.empty('actionAttributes'),
    pages: Ember.computed('isExploreTypeStep', 'isLinkingPreviousSteps', 'data.nextPages.@each', 'data.previousPages.@each', 'stepCategory', function () {
      if (this.get('isExploreTypeStep')) {
        return [];
      }

      var stepCategory = this.get('stepCategory');

      if (this.get('isLinkingPreviousSteps')) {
        if (stepCategory === STEP_CATEGORY_PREVIOUS_PAGES_ALL) {
          return this.get('data.previousAllPages');
        } else {
          return this.get('data.previousPages');
        }
      }

      if (!stepCategory || stepCategory === STEP_CATEGORY_NEXT_PAGES_IMMEDIATE) {
        return this.get('data.nextPages');
      } else if (stepCategory === STEP_CATEGORY_NEXT_PAGES_ALL) {
        return this.get('data.nextAllPages');
      } else {
        return [];
      }
    }),
    hasPages: Ember.computed.notEmpty('pages'),
    hasNoPages: Ember.computed.not('hasPages'),
    hasMoreNextPages: Ember.computed.readOnly('data.hasMoreNextPages'),
    hasMorePreviousPages: Ember.computed.readOnly('data.hasMorePreviousPages'),
    hasMorePages: Ember.computed('isLinkingPreviousSteps', 'hasMoreNextPages', 'hasMorePreviousPages', function () {
      // TODO: these need to be improved to actually account for the type of pages we are looking at...
      // TODO: right now it is just looking for next and previous, what about "all" vs "immediate"?
      if (this.get('isLinkingPreviousSteps')) {
        return this.get('hasMorePreviousPages');
      }

      return this.get('hasMoreNextPages');
    }),
    commonParametersUnfiltered: Ember.computed('data.commonParameters.@each', 'stepCategory', function () {
      if (this.get('stepCategory') === STEP_CATEGORY_PREVIOUS_PARAMETERS_ALL) {
        return this.get('data.allCommonParameters');
      }

      return this.get('data.commonParameters');
    }),
    commonParameters: Ember.computed('commonParametersUnfiltered', 'entityParameters.[]', function () {
      return filteredMappedParameters(this.get('commonParametersUnfiltered'), this.get('entityParameters'));
    }),
    hasNoCommonParameters: Ember.computed.empty('commonParameters'),
    customParametersUnfiltered: Ember.computed('data.customParameters.@each', function () {
      if (this.get('stepCategory') === STEP_CATEGORY_PREVIOUS_PARAMETERS_ALL) {
        return this.get('data.allCustomParameters');
      }

      return this.get('data.customParameters');
    }),
    customParameters: Ember.computed('customParametersUnfiltered', 'entityParameters.[]', function () {
      return filteredMappedParameters(this.get('customParametersUnfiltered'), this.get('entityParameters'));
    }),
    hasNoCustomParameters: Ember.computed.empty('customParameters'),
    explorerActions: Ember.computed('data.nextActions', 'stepCategory', 'isLinkingPreviousSteps', function () {
      var stepCategory = this.get('stepCategory');

      if (this.get('isLinkingPreviousSteps')) {
        if (stepCategory === STEP_CATEGORY_PREVIOUS_ACTIONS_ALL) {
          return this.get('data.previousAllActions');
        }
      }

      if (stepCategory === STEP_CATEGORY_NEXT_ACTIONS_ALL) {
        return this.get('data.nextAllActions');
      } else {
        return this.get('data.nextActions');
      }
    }),
    hasExplorerActions: Ember.computed.notEmpty('explorerActions'),
    hasNoExplorerActions: Ember.computed.not('hasExplorerActions'),
    hasMoreActions: Ember.computed.readOnly('data.hasMoreNextActions'),
    actionNameDisplayConverter: function actionNameDisplayConverter(actionName) {
      return _lodash.default.get(_standardActionNamesMapping.default, [actionName], actionName);
    },
    setStepCategory: function setStepCategory(stepCategory) {
      var isIgnoreInBetween = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      this.set('stepCategory', stepCategory);
      this.set('stepCategoryIsIgnoreInBetween', isIgnoreInBetween);
    },
    onAddAction: function onAddAction() {},
    onAddPage: function onAddPage() {},
    onAddTraffic: function onAddTraffic() {},
    onLoadMore: function onLoadMore() {},
    _getTrackingEventName: function _getTrackingEventName() {
      var exploredEntity = this.get('exploredEntity');

      if (!exploredEntity) {
        return;
      }

      var exploredEntityType = exploredEntity.getType();
      var eventName = null;

      if (this.get('isLinkingNextSteps')) {
        switch (exploredEntityType) {
          case _CanvasConstants.default.TYPE_EVENT:
            eventName = _TrackingConstants.default.CANVAS_EXPLORER_ACTIONS_NEXT_STEPS;
            break;

          case _CanvasConstants.default.TYPE_PAGE:
            eventName = _TrackingConstants.default.CANVAS_EXPLORER_PAGE_NEXT_STEPS;
            break;

          case _CanvasConstants.default.TYPE_SOURCE:
            eventName = _TrackingConstants.default.CANVAS_EXPLORER_TRAFFIC_NEXT_STEPS;
            break;

          default:
            break;
        }
      } else if (this.get('isLinkingPreviousSteps')) {
        switch (exploredEntityType) {
          case _CanvasConstants.default.TYPE_EVENT:
            eventName = _TrackingConstants.default.CANVAS_EXPLORER_ACTIONS_PREVIOUS_STEPS;
            break;

          case _CanvasConstants.default.TYPE_PAGE:
            eventName = _TrackingConstants.default.CANVAS_EXPLORER_PAGE_PREVIOUS_STEPS;
            break;

          default:
            break;
        }
      }

      return eventName;
    },
    _getTrackingEventCategory: function _getTrackingEventCategory(mappedStepType) {
      var _ref19 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref19$isIgnoreInBetw = _ref19.isIgnoreInBetween,
          isIgnoreInBetween = _ref19$isIgnoreInBetw === void 0 ? false : _ref19$isIgnoreInBetw;

      var categoryName = null;

      if (this.get('isLinkingNextSteps') && isIgnoreInBetween) {
        switch (mappedStepType) {
          case _CanvasConstants.default.TYPE_EVENT:
            categoryName = _TrackingConstants.default.CANVAS_EXPLORER_CATEGORY_ALL_NEXT_ACTIONS;
            break;

          case _CanvasConstants.default.TYPE_PAGE:
            categoryName = _TrackingConstants.default.CANVAS_EXPLORER_CATEGORY_ALL_NEXT_PAGES;
            break;

          default:
            break;
        }
      } else if (this.get('isLinkingNextSteps') && !isIgnoreInBetween) {
        switch (mappedStepType) {
          case _CanvasConstants.default.TYPE_EVENT:
            categoryName = _TrackingConstants.default.CANVAS_EXPLORER_CATEGORY_NEXT_ACTIONS;
            break;

          case _CanvasConstants.default.TYPE_PAGE:
            categoryName = _TrackingConstants.default.CANVAS_EXPLORER_CATEGORY_NEXT_PAGES;
            break;

          default:
            break;
        }
      } else if (this.get('isLinkingPreviousSteps') && isIgnoreInBetween) {
        switch (mappedStepType) {
          case _CanvasConstants.default.TYPE_EVENT:
            categoryName = _TrackingConstants.default.CANVAS_EXPLORER_CATEGORY_ALL_PREVIOUS_ACTIONS;
            break;

          case _CanvasConstants.default.TYPE_PAGE:
            categoryName = _TrackingConstants.default.CANVAS_EXPLORER_CATEGORY_ALL_PREVIOUS_PAGES;
            break;

          default:
            break;
        }
      } else if (this.get('isLinkingPreviousSteps') && !isIgnoreInBetween) {
        switch (mappedStepType) {
          case _CanvasConstants.default.TYPE_EVENT:
            categoryName = _TrackingConstants.default.CANVAS_EXPLORER_CATEGORY_PREVIOUS_ACTIONS;
            break;

          case _CanvasConstants.default.TYPE_PAGE:
            categoryName = _TrackingConstants.default.CANVAS_EXPLORER_CATEGORY_PREVIOUS_PAGES;
            break;

          default:
            break;
        }
      }

      return categoryName;
    },
    sendTrackingEvent: function sendTrackingEvent(mappedStepType) {
      var eventName = this._getTrackingEventName();

      if (!eventName) {
        return;
      }

      var category = this._getTrackingEventCategory(mappedStepType, {
        isIgnoreInBetween: this.get('stepCategoryIsIgnoreInBetween')
      });

      var otherOptions = undefined;

      if (category) {
        otherOptions = {
          category: category
        };
      }

      _ProductTracker.default.trigger(eventName, otherOptions);
    },
    actions: {
      handleUpdateStepCategory: function handleUpdateStepCategory(newStepCategoryLabel) {
        var foundCategory = _lodash.default.find(this.get('explorerStepCategories'), function (stepCategoriesObject) {
          return stepCategoriesObject.label === newStepCategoryLabel;
        });

        var label = _lodash.default.get(foundCategory, ['label']);

        var isIgnoreInBetween = _lodash.default.get(foundCategory, ['isIgnoreInBetween']);

        this.setStepCategory(label, isIgnoreInBetween);
      },
      setCategory: function setCategory(category) {
        this.set('category', category);
      },
      setCategoryPages: function setCategoryPages() {
        this.send('setCategory', CATEGORY_PAGES);
      },
      setCategoryActions: function setCategoryActions() {
        this.send('setCategory', CATEGORY_ACTIONS);
      },
      setCategoryAttributes: function setCategoryAttributes() {
        this.send('setCategory', CATEGORY_ATTRIBUTES);
      },
      setCategoryTraffic: function setCategoryTraffic() {
        this.send('setCategory', CATEGORY_TRAFFIC);
      },
      setCategoryParameters: function setCategoryParameters() {
        this.send('setCategory', CATEGORY_PARAMETERS);
      },
      addAction: function addAction(action) {
        this.sendTrackingEvent(_CanvasConstants.default.TYPE_EVENT);
        var handler = this.get('onAddAction');
        handler(action, this.get('stepCategoryIsIgnoreInBetween'));
      },
      addPage: function addPage(page) {
        this.sendTrackingEvent(_CanvasConstants.default.TYPE_PAGE);
        var handler = this.get('onAddPage');
        handler(page, this.get('stepCategoryIsIgnoreInBetween'));
      },
      addTraffic: function addTraffic(traffic) {
        this.sendTrackingEvent(_CanvasConstants.default.TYPE_SOURCE);
        var handler = this.get('onAddTraffic');
        handler(traffic, this.get('stepCategoryIsIgnoreInBetween'));
      },
      loadMore: function loadMore() {
        var handler = this.get('onLoadMore');
        handler();
      },
      addAttribute: function addAttribute(newParameter) {
        var exploredEntity = this.get('exploredEntity');

        if (!exploredEntity) {
          return;
        }

        if (![_CanvasConstants.default.TYPE_PAGE, _CanvasConstants.default.TYPE_EVENT, _CanvasConstants.default.TYPE_SOURCE].includes(exploredEntity.getType())) {
          return;
        }

        exploredEntity.setAttributes([newParameter]);
        this.notifyPropertyChange('entityParameters');
      },
      deleteAttribute: function deleteAttribute(parameter) {
        _utilities.Assertion.instanceOf(parameter, _EntityParameter.default);

        var exploredEntity = this.get('exploredEntity');

        if (!exploredEntity) {
          return;
        }

        if (![_CanvasConstants.default.TYPE_PAGE, _CanvasConstants.default.TYPE_EVENT, _CanvasConstants.default.TYPE_SOURCE].includes(exploredEntity.getType())) {
          return;
        }

        exploredEntity.deleteAttribute(parameter);
        this.notifyPropertyChange('entityParameters');
      }
    }
  });

  _exports.default = _default;
});