define("funnelytics/templates/components/account/invoice-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yGW7ish4",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"if\",[[23,[\"showLineItem\"]]],null,{\"statements\":[[4,\"each\",[[23,[\"invoice\",\"items\"]]],null,{\"statements\":[[0,\"    \"],[7,\"tr\"],[12,\"id\",[23,[\"invoice\",\"id\"]]],[11,\"class\",\"invoice-row \"],[9],[0,\"\\n      \"],[7,\"td\"],[11,\"class\",\"fl-table-row card-body-text\"],[9],[0,\"\\n        \"],[1,[27,\"date-parser\",[[23,[\"invoice\",\"closed_at\"]]],null],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"td\"],[11,\"class\",\"fl-table-row card-body-text\"],[9],[0,\"\\n        \"],[1,[22,1,[\"description\"]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"td\"],[11,\"class\",\"fl-table-row card-body-text\"],[9],[0,\"\\n        \"],[1,[27,\"format-currency\",[[22,1,[\"total_in_cents\"]],\"cents\"],null],false],[0,\" USD\\n      \"],[10],[0,\"\\n      \"],[7,\"td\"],[11,\"class\",\"fl-table-row card-body-text\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"invoice\",\"state\"]],\"paid\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"payment-status paid\"],[9],[0,\"\\n          PAID\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"payment-status failed\"],[9],[0,\"\\n          FAILED\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n      \"],[7,\"td\"],[9],[0,\"\\n        \"],[1,\" \",false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"td\"],[9],[0,\"\\n        \"],[1,\" \",false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"tr\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/account/invoice-detail.hbs"
    }
  });

  _exports.default = _default;
});