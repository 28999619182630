define("funnelytics/routes/dashboard/settings/account/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    actions: {
      didTransition: function didTransition() {
        if (this.controller.get('email-update')) {
          var emailUpdateToken = this.controller.get('email-update');
          this.controller.set('email-update', null);
          return this.transitionTo('dashboard.settings.account.email-update', emailUpdateToken);
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});