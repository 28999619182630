define("funnelytics/controllers/dashboard/settings", ["exports", "funnelytics/custom/constants/MembershipConstants"], function (_exports, _MembershipConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    isUserPermissions: 'isUserPermissions',
    versionManager: Ember.inject.service(),
    projectPermissions: Ember.inject.service(),
    activeProjectVersion: Ember.computed('versionManager.activeDashboardVersion', function () {
      return this.get('versionManager.activeDashboardVersion');
    }),
    isLimitedWithoutCollaboration: Ember.computed('model.projectClients', 'projectPermissions.userDetails.userId', 'projectPermissions.userDetails.membershipId', function () {
      var userId = this.get('projectPermissions.userDetails.userId');
      var membershipId = this.get('projectPermissions.userDetails.membershipId');
      var isCollaborator = this.model.projectClients.find(function (item) {
        return item.get('user.id') === userId;
      });
      return membershipId === _MembershipConstants.MEMBERSHIP_CODES.Limited && !isCollaborator;
    }),
    actions: {
      feedback: function feedback() {
        /*
        _dcq.push([
          'track', 'press_feedback'
        ]);
        */
        var win = window.open('http://feedback.funnelytics.io', '_blank');
        win.focus();
      }
    }
  });

  _exports.default = _default;
});