define("funnelytics/custom/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.UPDATED_ICON_PATH = _exports.NULL_PROJECT = _exports.EVENT_ICONS_PATH = _exports.DEFAULT_ICONS_PATH = void 0;
  var DEFAULT_ICONS_PATH = 'https://s3-us-west-2.amazonaws.com/funnelytics-thumbnails/';
  _exports.DEFAULT_ICONS_PATH = DEFAULT_ICONS_PATH;
  var EVENT_ICONS_PATH = 'https://s3-us-west-2.amazonaws.com/funnelytics-production/icons/standard/';
  _exports.EVENT_ICONS_PATH = EVENT_ICONS_PATH;
  var UPDATED_ICON_PATH = 'https://funnelytics-production.s3-us-west-2.amazonaws.com/icons/standard/default/';
  _exports.UPDATED_ICON_PATH = UPDATED_ICON_PATH;
  var NULL_PROJECT = 'no-project';
  _exports.NULL_PROJECT = NULL_PROJECT;
});