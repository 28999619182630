define("funnelytics/components/containers/funnel-editor/components/right-sidebar-action-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onEventChange: function onEventChange() {},
    property: null,
    parameterChanged: Ember.observer('parameter.{key,value,type}', function () {
      this.get('model').set('parameters', this.get('parameters'));
    }),
    onPropertyChange: Ember.observer('property.key', 'property.value', function () {
      this.eventValidation();
    }),
    eventValidation: function eventValidation() {
      var _this$get = this.get('attrs.property'),
          key = _this$get.key,
          value = _this$get.value;

      if (!key && !value) {
        this.set('eventKeyContent', null);
        this.set('eventKeyIcon', null);
        this.set('eventValueContent', null);
        this.set('eventValueIcon', null);
        return;
      }

      if (!key) {
        this.set('eventKeyIcon', 'warning-icon');
        this.set('eventKeyContent', "Action attribute keys can't be left blank.");
      } else if (key.length >= 512) {
        this.set('eventKeyContent', "Action attribute keys can't exceed 512 characters.");
        this.set('eventKeyIcon', 'error-icon');
      } else {
        this.set('eventKeyContent', null);
        this.set('eventKeyIcon', 'success-icon');
      }

      if (!key && value) {
        this.set('eventValueContent', "Actions can't have a value without a key.");
        this.set('eventValueIcon', 'error-icon');
      } else if (value && value.length >= 2048) {
        this.set('eventValueContent', "Action attribute value can't exceed 2048 characters.");
        this.set('eventValueIcon', 'error-icon');
      } else {
        this.set('eventValueContent', null);
        this.set('eventValueIcon', 'success-icon');
      }
    },
    didInsertElement: function didInsertElement() {
      this.eventValidation();
    },
    actions: {
      onEventChange: function onEventChange() {
        this.eventValidation();
      }
    }
  });

  _exports.default = _default;
});