define("funnelytics/templates/dashboard/templates/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o4Ro28kV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"routable-modal--dialog\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"fl-modal-proj-fun\"],[9],[0,\"\\n    \"],[7,\"h4\"],[11,\"class\",\"fl-modal-header\"],[9],[0,\"Delete Template \"],[1,[27,\"routable-modal-close-button\",null,[[\"class\"],[\"routable-modal--close\"]]],false],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fl--plr--2 delete-template-modal\"],[9],[0,\"\\n    Are you sure you want to delete \"],[1,[23,[\"model\",\"name\"]],false],[0,\"?\\n    \"],[7,\"br\"],[9],[10],[7,\"b\"],[9],[0,\" This cannot be undone \"],[10],[7,\"br\"],[9],[10],[0,\"\\n         \"],[7,\"button\"],[11,\"class\",\"btn btn-danger fl--mt--1\"],[11,\"data-dismiss\",\"modal\"],[11,\"type\",\"button\"],[3,\"action\",[[22,0,[]],\"deleteTemplate\",[23,[\"model\"]]]],[9],[0,\"Delete\"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/dashboard/templates/settings.hbs"
    }
  });

  _exports.default = _default;
});