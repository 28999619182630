define("funnelytics/templates/components/canvas-elements/entity-toolbar/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IOAcrj1S",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"label\"],[12,\"for\",[28,[[21,\"uniqueIdentifier\"]]]],[11,\"class\",\"sr-only\"],[9],[0,\"\\n  \"],[1,[21,\"label\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"button\"],[12,\"id\",[28,[[21,\"uniqueIdentifier\"]]]],[12,\"title\",[28,[[21,\"label\"]]]],[12,\"class\",[28,[\"\\n    fl-btn fl-btn--toolbar\\n    \",[21,\"additionalButtonClasses\"],\"\\n    \",[27,\"if\",[[23,[\"isSelected\"]],\"fl-btn--toolbar-selected\"],null],\"\\n  \"]]],[3,\"action\",[[22,0,[]],\"handleClick\"]],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/entity-toolbar/item.hbs"
    }
  });

  _exports.default = _default;
});