define("funnelytics/templates/components/containers/funnel-editor/components/right-sidebar-action-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZX8w+sOj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"fl-row\"],[9],[0,\"\\n  \"],[1,[27,\"input-validation/input-validation\",null,[[\"value\",\"label\",\"key\",\"typeOfInput\",\"maxlength\",\"icon\",\"content\",\"link\"],[[23,[\"property\",\"key\"]],\"Key\",[23,[\"key\"]],\"text\",512,[23,[\"eventKeyIcon\"]],[23,[\"eventKeyContent\"]],[23,[\"paramLink\"]]]]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"rs-divider\"],[9],[10],[0,\"\\n  \"],[1,[27,\"input-validation/input-validation\",null,[[\"value\",\"label\",\"key\",\"typeOfInput\",\"maxlength\",\"icon\",\"content\",\"link\"],[[23,[\"property\",\"value\"]],\"Value\",[23,[\"key\"]],\"text\",2048,[23,[\"eventValueIcon\"]],[23,[\"eventValueContent\"]],[23,[\"paramLink\"]]]]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"rs-divider\"],[9],[10],[0,\"\\n  \"],[7,\"svg\"],[11,\"class\",\"icon delete-parameter-x pointer-click flex-auto\"],[3,\"action\",[[22,0,[]],[23,[\"removeProperty\"]],[23,[\"property\"]]]],[9],[0,\"\\n    \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/toolbar-icons/toolbar-icon-def.svg#delete-icon\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/containers/funnel-editor/components/right-sidebar-action-property.hbs"
    }
  });

  _exports.default = _default;
});