define("funnelytics/components/dashboard-elements/subscriptions/manage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    subscriptionName: Ember.computed.readOnly('userSubscription.subscription.name'),
    // This "hasQueuedSubscription" is weird, not sure how it works but just leaving it as is for now:
    hasQueuedSubscription: Ember.computed.readOnly('userSubscription.queued_subscription'),
    termEndDate: Ember.computed.readOnly('userSubscription.term_ends_at'),
    isActive: Ember.computed.equal('userSubscription.status', 'active'),
    subscriptionStatus: Ember.computed.readOnly('userSubscription.status'),
    canBeCancelled: Ember.computed.alias('isActive'),
    subscriptionPrice: Ember.computed.readOnly('userSubscription.subscriptionPrice'),
    isSubscriptionNotFree: Ember.computed.gt('subscriptionPrice', 0),
    onClickDowngrade: function onClickDowngrade() {},
    actions: {
      clickDowngrade: function clickDowngrade() {
        var handler = this.get('onClickDowngrade');
        handler(this.get('userSubscription'), 'pro');
      }
    }
  });

  _exports.default = _default;
});