define("funnelytics/templates/components/library/canvas-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Six2wVf6",
    "block": "{\"symbols\":[\"cardData\"],\"statements\":[[7,\"div\"],[11,\"class\",\"flex-wrap-row-v2\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"cardsData\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"library/canvas-card\",null,[[\"cardData\",\"onClick\"],[[22,1,[]],null]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"isFetching\"]]],null,{\"statements\":[[7,\"div\"],[11,\"style\",\"display:flex; width: 100%; justify-content: center;\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"d-flex p-2 m-2\"],[11,\"style\",\"justify-content: center; width: 40%; border: 1px solid white;\"],[9],[0,\"\\n        \"],[7,\"div\"],[9],[0,\"Loading\"],[10],[0,\"\\n        \"],[1,[21,\"loading-dots\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/library/canvas-list.hbs"
    }
  });

  _exports.default = _default;
});