define("funnelytics/templates/components/canvas-elements/forecasting-outbound-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ultGK9xx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\"],[11,\"height\",\"60\"],[11,\"y\",\"20\"],[11,\"class\",\"forecasting-links__img\"],[12,\"src\",[21,\"targetImage\"]],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"forecasting-links__info-section\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"forecasting-links__title\"],[9],[0,\"\\n    \"],[1,[21,\"targetLabel\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"right-sidebar__input-wrapper\"],[9],[0,\"\\n\"],[0,\"    \"],[7,\"label\"],[11,\"class\",\"sideBarLabel\"],[9],[0,\"\\n      Click Through Rate\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"canvas-elements/numerical-input\",null,[[\"inputClasses\",\"value\",\"onInput\",\"syncDelay\"],[\"rs-input--no-padding\",[23,[\"conversionRate\"]],[27,\"action\",[[22,0,[]],\"setOutboundLinkConversionRate\"],null],[23,[\"setValueDelayInMs\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/forecasting-outbound-link.hbs"
    }
  });

  _exports.default = _default;
});