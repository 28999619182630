define("funnelytics/templates/components/dashboard-elements/upsell/lite-trial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fzlq4LCg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"routable-modal--content\"],[11,\"style\",\"height: 30vh;\\n    margin: 35vh -120px;\\n    display: flex;\\n    flex-direction: column;\\n    justify-content: space-between;\\n    border-radius: 25px;\\n    padding: 33px 90px;\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"style\",\"display: flex; justify-content: center;\"],[9],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"/assets/canvas-logo.svg\"],[11,\"style\",\"border-radius: 6px; width: 44px\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"style\",\"font-family: 'Nunito', sans-serif;\\n    color: black;\\n    text-align: center;\\n    display: flex;\\n    flex-direction: column;\\n    justify-content: center;\\n    padding-left: 12px;\\n    font-size: 33px;\\n    letter-spacing: 2.2px;\\n    font-weight: 300;\"],[9],[0,\"\\n      Funnelytics\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"id\",\"upsellBlock\"],[11,\"style\",\"display: flex; flex-direction: column; text-align: center; height: 100%; justify-content: space-evenly;\"],[9],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"style\",\"width: 100%; display: flex; justify-content: center \"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fl-btn fl-btn--bright fl-green-hover\"],[11,\"style\",\"width: 224px;\\n    height: 48px;\\n    }\"],[3,\"action\",[[22,0,[]],\"closeModal\"]],[9],[0,\"\\n      Take me to my account\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/dashboard-elements/upsell/lite-trial.hbs"
    }
  });

  _exports.default = _default;
});