define("funnelytics/controllers/dashboard/funnels/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    edited: false,
    router: Ember.inject.service(),
    userDetails: Ember.inject.service(),
    hasStarterPermissions: Ember.computed('userDetails.isStarter', function () {
      return this.get('userDetails.isStarter');
    }),
    actions: {
      setEdited: function setEdited(edited) {
        this.set('edited', edited);
      },
      goToLogin: function goToLogin() {
        this.get('router').transitionTo('login');
      },
      goToDashboard: function goToDashboard() {
        this.get('router').transitionTo('dashboard');
      }
    }
  });

  _exports.default = _default;
});