define("funnelytics/templates/components/canvas-elements/kpi-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "52pDTupZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\"],[11,\"class\",\"kpi-panel__kpi-row-items\"],[9],[0,\"\\n  \"],[1,[27,\"canvas-elements/kpi-title\",null,[[\"label\",\"hasElement\",\"onClickMarker\"],[[23,[\"rowLabel\"]],true,[27,\"action\",[[22,0,[]],\"selectElement\"],null]]]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"kpi-panel__kpi-data\"],[9],[0,\"\\n    \"],[1,[27,\"canvas-elements/kpi-item\",null,[[\"label\",\"value\",\"forecastValue\"],[[23,[\"countLabel\"]],[23,[\"count\"]],[23,[\"forecastAudience\"]]]]],false],[0,\"\\n\\n    \"],[1,[27,\"canvas-elements/kpi-item\",null,[[\"label\",\"value\",\"isPercentage\",\"forecastValue\"],[\"CONV. %\",[23,[\"conversionPercentage\"]],true,[23,[\"forecastConversionRate\"]]]]],false],[0,\"\\n    \\n\"],[4,\"if\",[[27,\"gt\",[[23,[\"goal\",\"value\"]],0],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"canvas-elements/kpi-item\",null,[[\"label\",\"value\",\"isCurrency\",\"isEmptyHidden\",\"forecastValue\"],[\"AMT. $\",[23,[\"value\"]],true,true,[23,[\"forecastValue\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/kpi-row.hbs"
    }
  });

  _exports.default = _default;
});