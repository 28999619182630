define("funnelytics/models/recurly-subscription", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    name: _emberData.default.attr('string'),
    external_action_required: _emberData.default.attr('boolean'),
    user_subscriptions: _emberData.default.hasMany('recurly-user-subscription'),
    subscription_permissions: _emberData.default.hasMany('recurly-subscription-permission')
  });

  _exports.default = _default;
});