define("funnelytics/components/containers/funnel-editor/components/right-sidebar-forecasting-section", ["exports", "lodash", "funnelytics/custom/constants/CanvasConstants", "funnelytics/custom/tracking/ProductTracker", "funnelytics/custom/constants/TrackingConstants"], function (_exports, _lodash, _CanvasConstants, _ProductTracker, _TrackingConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MULTIPLE_OUTBOUND_WARNING = "This source step is mapped to send traffic to multiple pages.\nForecast traffic will be split evenly between the steps that it links to.\nIf you\u2019d like to better forecast traffic,\nplease use separate source steps for each page.";
  var NO_INBOUND_WARNING = "\nThis step has no traffic step defined. Please add a source step to define your audience size.\n";

  var _default = Ember.Component.extend({
    multipleOutboundWarning: MULTIPLE_OUTBOUND_WARNING,
    noInboundWarning: NO_INBOUND_WARNING,
    forecastService: Ember.inject.service(),
    goalManager: Ember.inject.service(),
    canvasService: Ember.inject.service(),
    canvasEntity: Ember.computed('model', function () {
      return this.get('canvasService').wrapEntity(this.get('model'));
    }),
    setValueDelayInMs: 1000,
    unmappedTraffic: Ember.computed('canvasEntity', function () {
      if (this.get('canvasEntity').getType() !== _CanvasConstants.default.TYPE_PAGE) {
        return 0;
      }

      return this.get('canvasEntity').getUnmappedTraffic();
    }),
    dailyAdSpend: Ember.computed('canvasEntity', function () {
      if (this.get('canvasEntity').getType() !== _CanvasConstants.default.TYPE_SOURCE) {
        return 0;
      }

      return this.get('canvasEntity').getForecastDailySpend();
    }),
    audienceSize: Ember.computed('canvasEntity', function () {
      if (this.get('canvasEntity').getType() !== _CanvasConstants.default.TYPE_SOURCE) {
        return 0;
      }

      return this.get('canvasEntity').getForecastAudience();
    }),
    forecastValue: Ember.computed('canvasEntity', function () {
      if (this.get('canvasEntity').getType() !== _CanvasConstants.default.TYPE_EVENT) {
        return 0;
      }

      return this.get('canvasEntity').getForecastValue();
    }),
    costPerAcquisition: Ember.computed('canvasEntity', function () {
      if (this.get('canvasEntity').getType() !== _CanvasConstants.default.TYPE_SOURCE) {
        return 0;
      }

      return this.get('canvasEntity').getForecastCPA();
    }),
    hasGoal: Ember.computed('isLoadingGoals', function () {
      if (!this.get('isLoadingGoals') && this.get('goalManager.elementModel')) {
        return this.get('goalManager.goals.length') > 0;
      } else {
        return true;
      }
    }),
    isLeadKPI: Ember.computed('hasGoal', 'goalManager.goals.firstObject', function () {
      if (this.get('hasGoal')) {
        return this.get('goalManager.goals.firstObject.type') === 'Lead';
      }

      return false;
    }),
    isSaleKPI: Ember.computed('hasGoal', 'goalManager.goals.firstObject', function () {
      if (this.get('hasGoal')) {
        return this.get('goalManager.goals.firstObject.type') === 'Sale';
      }

      return false;
    }),
    isDisplayingSaleWarning: Ember.computed('isLoadingGoals', 'hasGoal', 'isSaleKPI', function () {
      if (this.get('isLoadingGoals')) {
        return false;
      }

      if (!this.get('hasGoal')) {
        return true;
      }

      return !this.get('isSaleKPI');
    }),
    isDisplayingLeadWarning: Ember.computed('isDisplayingSaleWarning', 'isLoadingGoals', 'hasGoal', 'isLeadKPI', function () {
      if (this.get('isDisplayingSaleWarning')) {
        return false;
      }

      if (this.get('isLoadingGoals')) {
        return false;
      }

      if (!this.get('hasGoal')) {
        return true;
      }

      return !this.get('isLeadKPI');
    }),
    settersForCanvasEntity: Ember.observer('canvasEntity', function () {
      this.setSetters();
    }),

    /**
     * Allows us to create debounced setters for all the updateable forecasting values to avoid
     * sending tons of events each time something is typed into an input field.
     */
    setSetters: function setSetters() {
      var _this = this;

      this.set('setUnmappedTrafficDebounce', _lodash.default.debounce(function (newUnmappedTraffic) {
        _this.get('canvasEntity').setUnmappedTraffic(newUnmappedTraffic);

        _this.finishUpdate();
      }, this.get('setValueDelayInMs')));
      this.set('setAudienceSizeDebounce', _lodash.default.debounce(function (newAudienceSize) {
        _this.get('canvasEntity').setForecastAudience(newAudienceSize);

        _ProductTracker.default.trigger(_TrackingConstants.default.CANVAS_FORECAST_CLICKS_TO_NEXT);

        _this.finishUpdate();
      }, this.get('setValueDelayInMs')));
      this.set('setDailySpendDebounce', _lodash.default.debounce(function (newAdSpend) {
        _this.get('canvasEntity').setForecastDailySpend(newAdSpend);

        _this.finishUpdate();
      }, this.get('setValueDelayInMs')));
      this.set('setActionValueDebounce', _lodash.default.debounce(function (newValue) {
        _this.get('canvasEntity').setForecastValue(newValue);

        _this.finishUpdate();
      }, this.get('setValueDelayInMs')));
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.set('isLoadingGoals', true);

      if (!this.get('model')) {
        return;
      }

      this.get('goalManager').resetState(this.get('funnel'), this.get('model')).then(function () {
        _this2.set('isLoadingGoals', false);
      }).catch(function (err) {
        _this2.set('isLoadingGoals', false);

        throw err;
      });
      this.setSetters();
    },
    // canvasEntity gets updated too often, even when underlying model doesn't
    outboundLinks: Ember.computed('model', function () {
      var entity = this.get('canvasEntity');
      var outboundLinks = entity.getOutboundLinks();
      return outboundLinks.map(function (linkEntity) {
        return {
          sourceEntity: entity,
          targetEntity: linkEntity.getTargetEntity()
        };
      });
    }),
    inboundLinksWarning: Ember.computed('canvasEntity', function () {
      var entity = this.get('canvasEntity');
      var inboundLinksCount = entity.getInboundLinksCount();

      if (entity.getType() !== _CanvasConstants.default.TYPE_SOURCE && inboundLinksCount < 1) {
        return true;
      }

      return false;
    }),
    finishUpdate: function finishUpdate() {
      this.notifyPropertyChange('canvasEntity');
      this.get('forecastService').updateForecast();
    },
    actions: {
      finishUpdate: function finishUpdate() {
        this.finishUpdate();
      },
      setAudienceSize: function setAudienceSize(newAudienceSize) {
        var updateAudienceSize = this.get('setAudienceSizeDebounce');
        updateAudienceSize(newAudienceSize);
      },
      setValue: function setValue(newValue) {
        var updateActionValue = this.get('setActionValueDebounce');
        updateActionValue(newValue);
      },
      setAdSpend: function setAdSpend(newAdSpend) {
        var updateAdSpend = this.get('setDailySpendDebounce');
        updateAdSpend(newAdSpend);
      },
      setUnmappedTraffic: function setUnmappedTraffic(newUnmappedTraffic) {
        var updateUnmappedTraffic = this.get('setUnmappedTrafficDebounce');
        updateUnmappedTraffic(newUnmappedTraffic);
      }
    }
  });

  _exports.default = _default;
});