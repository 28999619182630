define("funnelytics/components/vault-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    selected: {
      premium: null,
      offer_types_id: null,
      industries_id: null
    },
    updatedFilters: Ember.observer('selected.{premium,offer_types_id,industries_id}', function () {
      var _this = this;

      this.get('store').query('vault', {
        filter: this.get('selected')
      }).then(function (vaults) {
        _this.get('vaults').clear();

        _this.get('vaults').pushObjects(vaults.toArray());
      });
    })
  });

  _exports.default = _default;
});