define("funnelytics/components/canvas-elements/forecasting-set", ["exports", "@funnelytics/utilities", "lodash", "funnelytics/custom/forecast/ForecastPanelSet", "funnelytics/custom/constants/ForecastConstants", "funnelytics/custom/tracking/ProductTracker", "funnelytics/custom/constants/TrackingConstants"], function (_exports, _utilities, _lodash, _ForecastPanelSet, _ForecastConstants, _ProductTracker, _TrackingConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['forecast-overview__set'],
    canvasService: Ember.inject.service(),
    setId: null,
    setValueDelayInMs: 1000,
    didInsertElement: function didInsertElement() {
      var _this = this;

      _utilities.Assertion.instanceOf(this.get('forecastingSet'), _ForecastPanelSet.default);

      this.set('setValueDelayInMs', parseInt(this.get('setValueDelayInMs'), 10));
      this.set('setOutboundConversionDebounce', _lodash.default.debounce(function (newCTR) {
        var firstEntity = _this.get('forecastingSet').getFirstEntity();

        var secondEntity = _this.get('forecastingSet').getSecondEntity();

        firstEntity.setForecastCtr(secondEntity, newCTR);

        _ProductTracker.default.trigger(_TrackingConstants.default.CANVAS_FORECAST_CTR);

        _this.completeUpdate();
      }, this.get('setValueDelayInMs')));
      this.set('setAudienceSizeDebounce', _lodash.default.debounce(function (newAudienceSize) {
        var firstEntity = _this.get('forecastingSet').getFirstEntity();

        firstEntity.setForecastAudience(newAudienceSize);

        _ProductTracker.default.trigger(_TrackingConstants.default.CANVAS_FORECAST_CLICKS_TO_NEXT);

        _this.completeUpdate();
      }, this.get('setValueDelayInMs')));
      this.set('setDailySpendDebounce', _lodash.default.debounce(function (newDailySpend) {
        var firstEntity = _this.get('forecastingSet').getFirstEntity();

        firstEntity.setForecastDailySpend(newDailySpend);

        _this.completeUpdate();
      }, this.get('setValueDelayInMs')));
      this.set('setActionValueDebounce', _lodash.default.debounce(function (newDailySpend) {
        var secondEntity = _this.get('forecastingSet').getSecondEntity();

        secondEntity.setForecastValue(newDailySpend);

        _this.completeUpdate();
      }, this.get('setValueDelayInMs')));
    },
    firstEntityImage: Ember.computed('forecastingSet', function () {
      var firstEntity = this.get('forecastingSet').getFirstEntity();
      return firstEntity.getImage();
    }),
    firstEntityLabel: Ember.computed('forecastingSet', function () {
      var firstEntity = this.get('forecastingSet').getFirstEntity();
      return firstEntity.getLabel();
    }),
    secondEntityImage: Ember.computed('forecastingSet', function () {
      var secondEntity = this.get('forecastingSet').getSecondEntity();
      return secondEntity.getImage();
    }),
    secondEntityLabel: Ember.computed('forecastingSet', function () {
      var secondEntity = this.get('forecastingSet').getSecondEntity();
      return secondEntity.getLabel();
    }),
    hasThirdEntity: Ember.computed('forecastingSet', function () {
      return this.get('forecastingSet').hasThirdEntity();
    }),
    thirdEntityImage: Ember.computed('forecastingSet', function () {
      var thirdEntity = this.get('forecastingSet').getThirdEntity();
      return thirdEntity.getImage();
    }),
    thirdEntityLabel: Ember.computed('forecastingSet', function () {
      var thirdEntity = this.get('forecastingSet').getThirdEntity();
      return thirdEntity.getLabel();
    }),
    isSource: Ember.computed('forecastingSet', function () {
      return this.get('forecastingSet').getType() === _ForecastConstants.default.TYPE_SET_SOURCE;
    }),
    isDependent: Ember.computed('forecastingSet', function () {
      return this.get('forecastingSet').getType() === _ForecastConstants.default.TYPE_SET_DEPENDENT;
    }),
    isPage: Ember.computed('forecastingSet', function () {
      return this.get('forecastingSet').getType() === _ForecastConstants.default.TYPE_SET_PAGE;
    }),
    audienceSize: Ember.computed('isSource', 'forecastingSet', 'forecastUpdateId', function () {
      if (!this.get('isSource')) {
        return 0;
      }

      var firstEntity = this.get('forecastingSet').getFirstEntity();
      return firstEntity.getForecastAudience();
    }),
    dailySpend: Ember.computed('isSource', 'forecastingSet', 'forecastUpdateId', function () {
      if (!this.get('isSource')) {
        return 0;
      }

      var firstEntity = this.get('forecastingSet').getFirstEntity();
      return firstEntity.getForecastDailySpend();
    }),
    ctr: Ember.computed('isPage', 'isDependent', 'forecastingSet', 'forecastUpdateId', function () {
      if (!this.get('isPage') && !this.get('isDependent')) {
        return 0;
      }

      var firstEntity = this.get('forecastingSet').getFirstEntity();
      var secondEntity = this.get('forecastingSet').getSecondEntity();
      return firstEntity.getForecastCtr(secondEntity);
    }),
    cpa: Ember.computed('isSource', 'forecastingSet', 'forecastUpdateId', function () {
      if (!this.get('isSource')) {
        return 0;
      }

      var firstEntity = this.get('forecastingSet').getFirstEntity();
      return firstEntity.getForecastCPA();
    }),
    value: Ember.computed('isDependent', 'forecastingSet', 'forecastUpdateId', function () {
      if (!this.get('isDependent')) {
        return 0;
      }

      var firstEntity = this.get('forecastingSet').getSecondEntity();
      return firstEntity.getForecastValue();
    }),
    onUpdate: function onUpdate() {},
    completeUpdate: function completeUpdate() {
      this.onUpdate();
    },
    getStepType: function getStepType() {
      var type = this.get('forecastingSet').getType();
      return type === 'dependent' ? 'event.dependent' : type;
    },
    actions: {
      setOutboundConversion: function setOutboundConversion(newCTR) {
        var debouncedSetter = this.get('setOutboundConversionDebounce');
        debouncedSetter(newCTR);
      },
      setAudienceSize: function setAudienceSize(newAudienceSize) {
        var debouncedSetter = this.get('setAudienceSizeDebounce');
        debouncedSetter(newAudienceSize);
      },
      setDailySpend: function setDailySpend(newDailySpend) {
        var debouncedSetter = this.get('setDailySpendDebounce');
        debouncedSetter(newDailySpend);
      },
      setActionValue: function setActionValue(newActionValue) {
        var debouncedSetter = this.get('setActionValueDebounce');
        debouncedSetter(newActionValue);
      },
      findKeyElement: function findKeyElement() {
        var entityManager = this.get('canvasService').getEntityManager();

        if (this.get('isSource') || this.get('isPage')) {
          entityManager.selectAndScrollTo(this.get('forecastingSet').getFirstEntity(), {
            delayBeforeScroll: 300
          });
        } else {
          entityManager.selectAndScrollTo(this.get('forecastingSet').getSecondEntity(), {
            delayBeforeScroll: 300
          });
        }
      }
    }
  });

  _exports.default = _default;
});