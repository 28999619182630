define("funnelytics/services/current-routed-modal", ["exports", "ember-routable-modal/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    routing: Ember.inject.service('-routing'),
    router: Ember.inject.service(),
    routeName: null,
    activeListener: Ember.observer('routeName', function () {
      if (typeof Ember.$ !== 'undefined') {
        Ember.$('body')[this.get('routeName') ? 'addClass' : 'removeClass'](_configuration.default.modalOpenBodyClassName);
      }
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      if (typeof Ember.$ !== 'undefined' && typeof window !== 'undefined') {
        Ember.$(window).on('popstate.ember-routable-modal', function () {
          if (_this.get('routeName') !== _this.get('routing.router.currentRouteName')) {
            _this.set('routeName', null);
          }
        });
      }
    },
    clear: function clear() {
      if (this.get('routeName')) {
        this.set('routeName', null);
      }
    },
    close: function close() {
      var routerMain = this.get('routing.router');
      var routerLib = routerMain._routerMicrolib || routerMain.router;
      var handlerInfos = routerLib.state.handlerInfos;
      var currentController = handlerInfos[handlerInfos.length - 1]._handler.controller;
      this.set('routeName', null);

      if (currentController._isModalRoute) {
        var parentRoute = handlerInfos[handlerInfos.length - 2].name;
        routerLib.transitionTo(parentRoute);
      } else {
        var url = this.get('router').urlFor(this.get('router.currentRouteName'));
        routerLib.updateURL(url);
      }
    }
  });

  _exports.default = _default;
});