define("funnelytics/custom/analytics/analytics/commerce/CommerceConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var CommerceConstants = Object.freeze({
    ACTION: {
      DISPLAY_NAME: 'Purchase',
      DATABASE_NAME: '__commerce_action__'
    },
    ATTRIBUTES: {
      CURRENCY: {
        DISPLAY_NAME: 'Currency',
        DATABASE_NAME: '__currency__'
      },
      ORDER: {
        DISPLAY_NAME: 'Order ID',
        DATABASE_NAME: '__order__'
      },
      SKU: {
        DISPLAY_NAME: 'Product ID',
        DATABASE_NAME: '__sku__'
      },
      TOTAL_IN_CENTS: {
        DISPLAY_NAME: 'Total',
        DATABASE_NAME: '__total_in_cents__'
      },
      DESCRIPTION: {
        DISPLAY_NAME: 'Description',
        DATABASE_NAME: '__label__'
      }
    }
  });
  var _default = CommerceConstants;
  _exports.default = _default;
});