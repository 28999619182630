define("funnelytics/metrics-adapters/funnelytics", ["exports", "ember-metrics/metrics-adapters/base", "config/environment"], function (_exports, _base, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    toStringExtension: function toStringExtension() {
      return 'funnelytics';
    },
    init: function init() {
      (function (funnel) {
        var insert = document.getElementsByTagName('script')[0],
            script = document.createElement('script');
        script.addEventListener('load', function () {
          window.funnelytics.init(funnel);
        });
        script.src = _environment.default.trackerURL;
        script.type = 'text/javascript';
        script.async = true;
        insert.parentNode.insertBefore(script, insert);
      })('962bde72-22f9-480a-a796-cb4a8c6875c9');
    },
    trackPage: function trackPage() {
      if (window.funnelytics) {
        if (!window.funnelytics.stepped) {
          window.funnelytics.stepped = true;
          window.funnelytics.functions.step();
        }
      }
    },
    willDestroy: function willDestroy() {
      Ember.$('script[src*="api.funnelytics.io"]').remove();
      delete window.funnelytics;
    }
  });

  _exports.default = _default;
});