define("funnelytics/templates/reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rJs3eOOr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"mt-4 col-md-4 offset-md-4\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"src\",\"/assets/text-logo.png\"],[11,\"style\",\"width: 100%;\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"hr\"],[11,\"class\",\"col-md-6 offset-md-3\"],[9],[10],[0,\"\\n  \"],[1,[27,\"account/account-password\",null,[[\"requireCurrent\",\"tokenUsed\",\"onUpdate\",\"centered\"],[false,[23,[\"model\",\"used\"]],[27,\"action\",[[22,0,[]],\"updatePassword\"],null],true]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/reset.hbs"
    }
  });

  _exports.default = _default;
});