define("funnelytics/templates/components/collapsible-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XlE+dF29",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"sidebar__header collapsible-panel__heading\"],[11,\"aria-expanded\",\"true\"],[12,\"aria-controls\",[28,[[21,\"targetId\"]]]],[3,\"action\",[[22,0,[]],\"toggleCollapse\"]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"collapsible-panel__icon\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"openOrOpening\"]]],null,{\"statements\":[[0,\"      - \\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      +\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"collapsible-panel__heading-copy\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"backButton\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"txt--blue fl--mr--05 pointer-click\"],[3,\"action\",[[22,0,[]],\"backAction\"]],[9],[0,\"\\n        \"],[7,\"img\"],[11,\"class\",\"txt--blue back-arrow\"],[11,\"src\",\"/assets/sidebar-icons/back-icon.svg\"],[9],[10],[0,\"\\n        BACK\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[21,\"title\"],false],[0,\" \"],[1,[21,\"subtitle\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[12,\"id\",[28,[[21,\"targetId\"]]]],[12,\"style\",[21,\"startHiddenStyle\"]],[9],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/collapsible-panel.hbs"
    }
  });

  _exports.default = _default;
});