define("funnelytics/templates/components/for-reuse/allowance-progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AazTqc3o",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"allowance-progress-bar__header\"],[9],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[1,[21,\"label\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"hasHitLimit\"]],\"allowance-progress-bar--warning\"],null]]]],[9],[0,\"\\n    \"],[1,[27,\"integer-shortener\",[[23,[\"currentCount\"]]],null],false],[0,\" / \"],[1,[27,\"integer-shortener\",[[23,[\"limitAllowed\"]]],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"progress allowance-progress-bar-progress\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"progress-bar allowance-progress-bar-progress-indicator\"],[11,\"role\",\"progressbar\"],[12,\"style\",[21,\"allowanceProgressStyle\"]],[12,\"aria-valuenow\",[21,\"allowanceProgress\"]],[11,\"aria-valuemin\",\"0\"],[12,\"aria-valuemax\",[21,\"limitAllowed\"]],[9],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"showTooltip\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[11,\"class\",\"toolbar-allowance__tooltip\"],[9],[0,\"\\n    Starter plans are limited to 30 steps per funnel. If you require more steps, please\\n    \"],[7,\"a\"],[11,\"href\",\"https://funnelytics.io/discover\"],[11,\"target\",\"_blank\"],[9],[0,\"\\n      upgrade your account.\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/allowance-progress-bar.hbs"
    }
  });

  _exports.default = _default;
});