define("funnelytics/components/funnel-pages/faastrack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['funnelmomentum__container']
  });

  _exports.default = _default;
});