define("funnelytics/helpers/format-currency", ["exports", "@funnelytics/utilities", "lodash"], function (_exports, _utilities, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = formatCurrency;
  _exports.formatCurrencyEmber = formatCurrencyEmber;
  _exports.default = void 0;

  function formatCurrency() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        total = _ref.total,
        _ref$currency = _ref.currency,
        currency = _ref$currency === void 0 ? 'USD' : _ref$currency,
        _ref$isInCents = _ref.isInCents,
        isInCents = _ref$isInCents === void 0 ? true : _ref$isInCents;

    _utilities.Assertion.finite(total);

    var value = isInCents ? total / 100 : total;
    return new Number(value).toLocaleString('en-US', {
      style: 'currency',
      currency: currency
    });
  }

  function formatCurrencyEmber(params
  /*, hash*/
  ) {
    var isInCents = _lodash.default.get(params, 1) === 'cents';
    var rawValue = _lodash.default.get(params, 0, 0) || 0;
    return formatCurrency({
      total: rawValue,
      isInCents: isInCents
    });
  }

  var _default = Ember.Helper.helper(formatCurrencyEmber);

  _exports.default = _default;
});