define("funnelytics/components/dashboard-elements/subscriptions/view/woo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    name: Ember.computed.readOnly('wooUserSubscription.name'),
    nextPayment: Ember.computed.readOnly('wooUserSubscription.next_payment'),
    hasNextPayment: Ember.computed('nextPayment', function () {
      return Boolean(this.get('nextPayment'));
    })
  });

  _exports.default = _default;
});