define("funnelytics/components/containers/funnel-editor/components/all-steps-list", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var TEMPLATE_LABEL = 'label';
  var TEMPLATE_LABEL_LOWER = 'label_lower';
  var TEMPLATE_ID = 'id';

  var convertToTemplateEntity = function convertToTemplateEntity(entity) {
    var _ref;

    return _ref = {}, _defineProperty(_ref, TEMPLATE_LABEL, entity.getLabel()), _defineProperty(_ref, TEMPLATE_LABEL_LOWER, _lodash.default.toLower(entity.getLabel())), _defineProperty(_ref, TEMPLATE_ID, entity.getID()), _ref;
  };

  var filterSteps = function filterSteps(steps, filterString) {
    if (!filterString) {
      return steps;
    }

    var lowercaseFilterString = _lodash.default.toLower(filterString);

    return steps.filter(function (page) {
      return page[TEMPLATE_LABEL_LOWER].includes(lowercaseFilterString);
    });
  };

  var _default = Ember.Component.extend({
    tagName: '',
    canvasService: Ember.inject.service(),
    hasPages: Ember.computed.notEmpty('pages'),
    hasActions: Ember.computed.notEmpty('actionSteps'),
    hasTrafficSources: Ember.computed.notEmpty('trafficSources'),
    _unFilteredPages: Ember.computed.map('pageEntities', convertToTemplateEntity),
    _unFilteredActionSteps: Ember.computed.map('actionsEntities', convertToTemplateEntity),
    _unFilteredTrafficSources: Ember.computed.map('trafficSourceEntities', convertToTemplateEntity),
    pages: Ember.computed('_unFilteredPages', 'stepLabelFilter', function () {
      return filterSteps(this.get('_unFilteredPages'), this.get('stepLabelFilter'));
    }),
    actionSteps: Ember.computed('_unFilteredActionSteps', 'stepLabelFilter', function () {
      return filterSteps(this.get('_unFilteredActionSteps'), this.get('stepLabelFilter'));
    }),
    trafficSources: Ember.computed('_unFilteredTrafficSources', 'stepLabelFilter', function () {
      return filterSteps(this.get('_unFilteredTrafficSources'), this.get('stepLabelFilter'));
    }),
    didInsertElement: function didInsertElement() {
      this.setProperties({
        pageEntities: this.get('canvasService').getAllPageEntities(),
        actionsEntities: this.get('canvasService').getAllEventEntities(),
        trafficSourceEntities: this.get('canvasService').getAllTrafficEntities(),
        stepLabelFilter: ''
      });
    },
    onSelectStep: function onSelectStep() {},
    onFindStep: function onFindStep() {},
    findEntityFromStepId: function findEntityFromStepId(stepId) {
      return _lodash.default.concat(this.get('pageEntities'), this.get('actionsEntities'), this.get('trafficSourceEntities')).find(function (entity) {
        return entity.getID() === stepId;
      });
    },
    actions: {
      selectStep: function selectStep(stepId) {
        var entitySelected = this.findEntityFromStepId(stepId);

        if (entitySelected) {
          var selectStepHandler = this.get('onSelectStep');
          selectStepHandler(entitySelected);
        }
      },
      findStep: function findStep(stepId) {
        var entitySelected = this.findEntityFromStepId(stepId);

        if (entitySelected) {
          var findStepHandler = this.get('onFindStep');
          findStepHandler(entitySelected);
        }
      }
    }
  });

  _exports.default = _default;
});