define("funnelytics/components/canvas-elements/kpi-row", ["exports", "moment", "lodash"], function (_exports, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['kpi-panel__kpi-row'],
    forecastService: Ember.inject.service(),
    analyticsService: Ember.inject.service(),
    numberOfDays: Ember.computed('analyticsService.dates', function () {
      var dateRange = this.get('analyticsService.dates');

      if (!dateRange) {
        return 1;
      }

      var end = (0, _moment.default)(dateRange.min).startOf('day');
      var now = (0, _moment.default)(dateRange.max).startOf('day');

      var duration = _moment.default.duration(now.diff(end)).asDays();

      duration += 1;
      return duration;
    }),
    goal: Ember.computed('kpi', function () {
      return this.get('kpi').getGoal();
    }),
    countLabel: Ember.computed('kpi', function () {
      if (!this.get('kpi')) {
        return null;
      }

      return this.get('kpi').getType();
    }),
    count: Ember.computed('kpi', function () {
      if (!this.get('kpi')) {
        return 0;
      }

      return this.get('kpi').getCount();
    }),
    conversionPercentage: Ember.computed('kpi', function () {
      if (!this.get('kpi')) {
        return 0;
      }

      return this.get('kpi').getConversionPercentage();
    }),
    value: Ember.computed('kpi', function () {
      if (!this.get('kpi')) {
        return 0;
      }

      return this.get('kpi').getValue();
    }),
    rowLabel: Ember.computed('kpi', function () {
      if (!this.get('kpi')) {
        return null;
      }

      return "".concat(this.get('kpi').getName());
    }),
    forecastForKPI: Ember.computed('kpi', 'forecastService.forecastState', function () {
      var forecastState = this.get('forecastService.forecastState');
      return forecastState[this.get('kpi').getTargetId()];
    }),
    forecastAudience: Ember.computed('forecastService.isActive', 'forecastForKPI', function () {
      if (!this.get('forecastService.isActive')) {
        return undefined;
      }

      var audience = this.get('forecastForKPI.forecastedAudience');

      if (!audience) {
        return 0;
      }

      return audience * this.get('numberOfDays');
    }),
    forecastConversionRate: Ember.computed('forecastService.isActive', 'forecastForKPI', 'forecastService.totalAudience', 'numberOfDays', function () {
      if (!this.get('forecastService.isActive')) {
        return undefined;
      }

      var days = this.get('numberOfDays') || 1;
      var audience = this.get('forecastService.totalAudience') * days || 0;
      var views = this.get('forecastForKPI.forecastedAudience') * days || 0;
      var conversion = views / audience * 100;

      if (!_lodash.default.isFinite(conversion)) {
        return '-';
      } else {
        return conversion.toFixed(2);
      }
    }),
    forecastValue: Ember.computed('forecastService.isActive', 'forecastAudience', 'kpi', 'numberOfDays', function () {
      if (!this.get('forecastService.isActive')) {
        return undefined;
      }

      var cost = this.get('kpi').getGoal().get('value') || 0;
      return this.get('forecastForKPI.forecastedAudience') * cost * this.get('numberOfDays');
    }),
    actions: {
      selectElement: function selectElement() {
        this.selectCanvasElement(this.get('kpi').getTargetId());
      }
    }
  });

  _exports.default = _default;
});