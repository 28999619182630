define("funnelytics/templates/dashboard/vault/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ueEedmHU",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"vault-selection\",null,[[\"vaults\",\"offers\",\"industries\"],[[23,[\"model\",\"vaults\"]],[23,[\"model\",\"offers\"]],[23,[\"model\",\"industries\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/dashboard/vault/index.hbs"
    }
  });

  _exports.default = _default;
});