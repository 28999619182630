define("funnelytics/templates/components/account/subscription-billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r3J0YIbm",
    "block": "{\"symbols\":[\"userSubscriptionAddOn\"],\"statements\":[[4,\"if\",[[23,[\"hasNextBill\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"fl-flex-row card-body-text fl--mt--1\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"subscription-billing\"],[9],[0,\"\\n      \"],[7,\"h3\"],[11,\"class\",\"subscription-billing__name\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"userSubscription\",\"queued_subscription\"]]],null,{\"statements\":[[0,\"          \"],[1,[23,[\"userSubscription\",\"queued_subscription\",\"new_plan_name\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[23,[\"userSubscription\",\"subscription\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"hasAddOns\"]]],null,{\"statements\":[[0,\"        \"],[7,\"ul\"],[11,\"class\",\"subscription-billing__add-ons\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"userSubscriptionAddOns\"]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"account/subscription-billing/add-on\",null,[[\"userSubscriptionAddOn\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"h4\"],[11,\"class\",\"subscription-billing__next-bill-title\"],[9],[0,\"\\n        Next Payment:\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"subscription-billing__next-billing-date\"],[9],[0,\"\\n        \"],[1,[27,\"date-parser\",[[23,[\"userSubscription\",\"period_ends_at\"]]],null],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"subscription-billing__price\"],[9],[0,\"\\n        \"],[1,[27,\"format-currency\",[[23,[\"subscriptionPrice\"]],\"cents\"],null],false],[0,\" USD\\n\"],[4,\"if\",[[23,[\"willPayTax\"]]],null,{\"statements\":[[0,\"          (+ Tax)\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/account/subscription-billing.hbs"
    }
  });

  _exports.default = _default;
});