define("funnelytics/components/canvas-elements/context-menu/tracking-settings/setup-advanced-actions-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['setup-advanced-actions-modal'],
    workspaceTextAreaId: Ember.computed('modalId', function () {
      return "".concat(this.get('modalId'), "-workspace-id");
    }),
    apiKeyTextAreaId: Ember.computed('modalId', function () {
      return "".concat(this.get('modalId'), "-api-key");
    }),
    onClose: function onClose() {},
    didInsertElement: function didInsertElement() {
      var thisComponent = this;
      this.$("#".concat(this.get('modalId'))).on('click', function (evt) {
        if (this !== evt.target) {
          return;
        }

        thisComponent.send('onClose');
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$("#".concat(this.get('modalId'))).off('click');
    },
    actions: {
      onClose: function onClose() {
        this.get('onClose')();
      },
      copyToClipboard: function copyToClipboard(textAreaId) {
        var textArea = this.$("#".concat(textAreaId));
        textArea.select();
        document.execCommand('copy');
      }
    }
  });

  _exports.default = _default;
});