define("funnelytics/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    is_password_set: _emberData.default.attr('boolean'),
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    tapfiliate_referrer: _emberData.default.attr('string'),
    recaptcha_token: _emberData.default.attr('string'),
    payment: _emberData.default.attr('payment'),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date'),
    downgrade_date: _emberData.default.attr('date'),
    funnels: _emberData.default.hasMany('funnel'),
    templates: _emberData.default.hasMany('template'),
    projects: _emberData.default.hasMany('projects'),
    segmentation: _emberData.default.hasMany('user-segmentation'),
    role: _emberData.default.belongsTo('role'),
    membership: _emberData.default.belongsTo('membership'),
    user_products: _emberData.default.hasMany('user-product'),
    user_subscriptions: _emberData.default.hasMany('recurly-user-subscription'),
    products: _emberData.default.hasMany('product'),
    meta_properties: _emberData.default.hasMany('user-meta-property'),
    icon_folders: _emberData.default.hasMany('icon_folder'),
    analytics_ranges: _emberData.default.hasMany('analytics-range'),
    utm_source: _emberData.default.attr('string'),
    utm_medium: _emberData.default.attr('string'),
    utm_campaign: _emberData.default.attr('string'),
    utm_content: _emberData.default.attr('string'),
    utm_term: _emberData.default.attr('string'),
    oto_offer_accepted: _emberData.default.attr('boolean'),
    user_type: _emberData.default.attr('string'),
    free_trial_started_at: _emberData.default.attr('date')
  });

  _exports.default = _default;
});