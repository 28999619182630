define("funnelytics/services/tapfiliate", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    init: function init() {
      this._super();
    },
    getAffiliateCookie: function getAffiliateCookie() {
      var item = _lodash.default.find(document.cookie.split(';'), function (cookie) {
        return cookie.split('=')[0].trim() === 'funnelytics_affiliate';
      });

      if (!item) {
        return null;
      }

      return item.split('=')[1];
    },
    setUserAffiliate: function setUserAffiliate(model, affiliate) {
      if (!this.get('session.isAuthenticated') || !model || !affiliate) {
        return Ember.RSVP.Promise.resolve(null);
      }

      if (model.get('tapfiliate_referrer') !== affiliate) {
        model.set('tapfiliate_referrer', affiliate);
        return model.save();
      }
    }
    /*
    convert(order) {
      const items = _.filter(order.items, item => item.amount > 0);
      const description = _.map(items, item => (
        item.description
      )).join(', ');
      const sum = order.amount / 100;
      tap('conversion', order.id, sum, {
        meta_data: {
          description
        }
      }, 'funnelytics-pro-lifetime');
    }
    */

  });

  _exports.default = _default;
});