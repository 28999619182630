define("funnelytics/models/recurly-user-queued-subscription", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    switch_date: _emberData.default.attr('string'),
    new_plan_name: _emberData.default.attr('string'),
    new_plan_code: _emberData.default.attr('string'),
    new_plan_period_price_in_cents: _emberData.default.attr('number'),
    new_plan_period_unit: _emberData.default.attr('string'),
    new_plan_period_length: _emberData.default.attr('string'),
    new_plan_periods_in_term: _emberData.default.attr('number'),
    user_subscription: _emberData.default.belongsTo('recurly-user-subscription')
  });

  _exports.default = _default;
});