define("funnelytics/controllers/order/vault/lifetime", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['from_app'],
    from_app: false,
    showCongratulations: Ember.computed.not('from_app')
  });

  _exports.default = _default;
});