define("funnelytics/components/svg-display", ["exports", "@funnelytics/utilities", "lodash"], function (_exports, _utilities, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _typeToPath;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var HANDLER_CLASS_PREFIX = 'fl-icon__svg--';
  var TYPE_SYMBOLS = 'symbol';
  var TYPE_TOOLBAR = 'toolbar';
  var SYMBOLS_PATH = '/assets/symbol-defs.svg#';
  var TOOLBAR_PATH = '/assets/toolbar-icons/toolbar-icon-def.svg#';
  var typeToPath = (_typeToPath = {}, _defineProperty(_typeToPath, TYPE_SYMBOLS, SYMBOLS_PATH), _defineProperty(_typeToPath, TYPE_TOOLBAR, TOOLBAR_PATH), _typeToPath);

  var _default = Ember.Component.extend({
    type: TYPE_SYMBOLS,
    folder: Ember.computed('type', function () {
      return _lodash.default.get(typeToPath, this.get('type'), SYMBOLS_PATH);
    }),
    svgPath: Ember.computed('folder', 'symbolDefId', function () {
      return "".concat(this.get('folder')).concat(this.get('symbolDefId'));
    }),
    symbolHandlerClass: Ember.computed('symbolDefId', function () {
      var symbolDefId = this.get('symbolDefId');
      return "".concat(HANDLER_CLASS_PREFIX).concat(symbolDefId);
    }),
    didInsertElement: function didInsertElement() {
      _utilities.Assertion.string(this.get('symbolDefId'));
    }
  });

  _exports.default = _default;
});