define("funnelytics/services/custom-ember-methods", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    router: Ember.inject.service(),
    transitionTo: function transitionTo() {
      var _this$get;

      // Fixes bug in this.router.transitionTo(...params). See:
      // https://github.com/emberjs/ember.js/issues/17494#issuecomment-561341173
      var url = (_this$get = this.get('router')).urlFor.apply(_this$get, arguments); // build url first


      this.get('router').transitionTo(url); // and make transition
      // And if you need to preserve query params, I guess you can use `window.location.search` and add this to url like:

      return this.get('router').transitionTo(url + window.location.search);
    }
  });

  _exports.default = _default;
});