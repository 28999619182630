define("funnelytics/services/release-manager", ["exports", "funnelytics/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    //Defining prerelease
    isV2: _environment.default['nodeEnvironment'] !== 'irrelevant_value'
  });

  _exports.default = _default;
});