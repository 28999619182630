define("funnelytics/components/account/registration/phone-country-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['phone-country-code'],
    selectedCountryCode: 'us',
    onSelectCountry: function onSelectCountry() {},
    actions: {
      selectCountry: function selectCountry(countryOption) {
        var handler = this.get('onSelectCountry');
        handler(countryOption);
      }
    }
  });

  _exports.default = _default;
});