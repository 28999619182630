define("funnelytics/custom/constants/AcknowledgementConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AcknowledgementConstants = Object.freeze({
    FLOW: 'seen-flow-video',
    ACTIONS: 'seen-action-video',
    FOCUS: 'seen-focus-video',
    FORECAST: 'seen-forecast-video',
    KPI: 'seen-kpi-video',
    MAPPING: 'seen-mapping-video',
    NUMBERS: 'seen-numbers-video',
    PEOPLE: 'seen-people-video',
    TRACKING: 'seen-tracking-video',
    STARTER: 'seen-starter-modal'
  });
  var _default = AcknowledgementConstants;
  _exports.default = _default;
});