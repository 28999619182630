define("funnelytics/custom/analytics/analytics-data/ProfileAttributesAnalyticsData", ["exports", "@funnelytics/utilities", "lodash", "funnelytics/custom/analytics/AnalyticsData", "funnelytics/custom/constants/AnalyticsConstants"], function (_exports, _utilities, _lodash, _AnalyticsData, _AnalyticsConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ProfileAttributesAnalyticsData = _AnalyticsData.default.extend({
    _type: 'profile-attributes',
    profileCountries: Ember.computed.readOnly('_profileCountries'),
    _profileCountries: [],
    _populate: function _populate(_ref) {
      var data = _ref.data;
      this.setProperties({
        _profileCountries: _lodash.default.get(data, _AnalyticsConstants.default.PROFILE_ATTRIBUTES_OUTPUT.COUNTRIES.LIST, [])
      });
    },
    _replaceWith: function _replaceWith(profileAttributesAnalyticsData) {
      _utilities.Assertion.instanceOf(profileAttributesAnalyticsData, ProfileAttributesAnalyticsData);

      this.setProperties({
        _profileCountries: profileAttributesAnalyticsData.get('profileCountries')
      });
    } // No _merge defined, deliberately

  });

  var _default = ProfileAttributesAnalyticsData;
  _exports.default = _default;
});