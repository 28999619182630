define("funnelytics/templates/components/canvas-elements/analytics-toolbar/filter-dropdown-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5Dwh17RR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"sub-title\"],[3,\"action\",[[22,0,[]],\"handleClick\"]],[9],[0,\"\\n  \"],[7,\"svg\"],[11,\"class\",\"icon kpi-chevron\"],[9],[0,\"\\n    \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-chevron-left\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"\\n    \"],[1,[21,\"name\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/analytics-toolbar/filter-dropdown-header.hbs"
    }
  });

  _exports.default = _default;
});