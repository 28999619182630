define("funnelytics/templates/components/left-sidebar/traffic-explorer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Klyhigeq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"context-menu__explorer\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"hasExploredEntity\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"canvas-elements/explorer\",null,[[\"initialEntityType\",\"data\",\"exploredEntity\",\"onAddAction\",\"onAddPage\",\"onAddTraffic\",\"onLoadMore\",\"isExplorerLoading\",\"isExploredEntityStale\",\"onReloadExplorerData\"],[[23,[\"initialEntityType\"]],[23,[\"explorerExploredData\"]],[23,[\"exploredEntity\"]],[27,\"action\",[[22,0,[]],\"addExplorerAction\"],null],[27,\"action\",[[22,0,[]],\"addExplorerPage\"],null],[27,\"action\",[[22,0,[]],\"addExplorerTraffic\"],null],[27,\"action\",[[22,0,[]],\"loadMoreExploredExplorer\"],null],[23,[\"isExplorerLoading\"]],[23,[\"isExploredEntityStale\"]],[27,\"action\",[[22,0,[]],\"reloadExplorerData\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"canvas-elements/explorer\",null,[[\"data\",\"onAddAction\",\"onAddPage\",\"onAddTraffic\",\"onLoadMore\",\"isExplorerLoading\",\"isExploredEntityStale\",\"onReloadExplorerData\"],[[23,[\"explorerRegularData\"]],[27,\"action\",[[22,0,[]],\"addExplorerAction\"],null],[27,\"action\",[[22,0,[]],\"addExplorerPage\"],null],[27,\"action\",[[22,0,[]],\"addExplorerTraffic\"],null],[27,\"action\",[[22,0,[]],\"loadMoreRegularExplorer\"],null],[23,[\"isExplorerLoading\"]],[23,[\"isExploredEntityStale\"]],[27,\"action\",[[22,0,[]],\"reloadExplorerData\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/left-sidebar/traffic-explorer.hbs"
    }
  });

  _exports.default = _default;
});