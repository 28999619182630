define("funnelytics/custom/constants/SubscriptionConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MembershipToPlans = {
    'Map Free': 'MAPPING',
    'Performance Starter': 'PERFORMANCE_STARTER',
    'Performance Pro': 'PERFORMANCE_PRO',
    'Performance Business': 'PERFORMANCE_BUSINESS',
    Performance: 'PERFORMANCE_BUSINESS',
    'Performance Agency': 'PERFORMANCE_AGENCY'
  };
  var PricingTablePlansConfig = {
    MAPPING: {
      header: {
        membership: 'Map Free',
        description: 'For anyone that wants a simple free tool to map basic customer journeys',
        priceMonthly: 0,
        priceAnnually: 0
      },
      core: {
        adminUsers: 1,
        numberOfCanvases: 3,
        workspaces: 1,
        workspacesCollaborators: false,
        templateLibrary: true
      },
      journeyMapping: {
        canvasDuplicateLink: 'Unlimited',
        canvasViewOnlyLink: false,
        pngExport: 'Low Resolution',
        customImages: false,
        presentationSlides: {
          _recall_name: 'presentationSlides',
          _display_name: 'Presentation Slides (Reports)',
          value: false
        },
        canvasTags: false,
        notes: false,
        checklists: false
      },
      journeyTracking: {
        peopleTrackingMonth: 'Not Applicable',
        sourceTracking: false,
        webpageTracking: false,
        videoViewTracking: false,
        scrollDepthTracking: false,
        buttonClickTracking: false,
        crossDomainTracking: false,
        formTracking: false,
        callScheduleTracking: false,
        purchaseTracking: false,
        dealsAndPipelineTracking: false
      },
      journeyAnalytics: {
        predictiveForecasting: false,
        funnelMode: false,
        dataComparison: false,
        goals: false,
        peopleDataExport: false,
        peopleFilters: false,
        allTrafficMode: true,
        countryWidget: false,
        trendsWidget: false,
        peopleWidget: false,
        pathExploration: false,
        peopleCsvFileExport: {
          _recall_name: 'peopleCsvFileExport',
          _display_name: 'People CSV File Export',
          value: false
        },
        filterComparison: false
      },
      integrations: {
        zapierIntegration: false,
        customWebhooks: false,
        goHighLevelIntegration: {
          _recall_name: 'goHighLevelIntegration',
          _display_name: 'GoHighLevel Integration',
          value: true
        },
        hubspotIntegration: false,
        gtmContainerLibrary: false
      },
      support: {
        communityHub: true,
        productTutorial: true,
        standardSupportEmail: false,
        dedicatedSupportEmail: false,
        oneOnOneKickoffSection: false
      },
      services: {
        fullTrackingSetup: false,
        agencyPartnerProgram: false
      }
    },
    PERFORMANCE_STARTER: {
      header: {
        membership: 'Performance Starter',
        description: 'For strategists who want to forecast their marketing funnels and visualize high level result.',
        priceMonthly: 79,
        priceAnnually: 790
      },
      core: {
        adminUsers: 1,
        numberOfCanvases: 'Unlimited',
        workspaces: 1,
        workspacesCollaborators: 'Unlimited',
        dataRetention: '3 months',
        templateLibrary: true
      },
      journeyMapping: {
        canvasDuplicateLink: 'Unlimited',
        canvasViewOnlyLink: 'Unlimted',
        pngExport: 'High Resolution',
        customImages: true,
        presentationSlides: {
          _recall_name: 'presentationSlides',
          _display_name: 'Presentation Slides (Reports)',
          value: true
        },
        canvasTags: true,
        notes: true,
        checklists: true
      },
      journeyTracking: {
        peopleTrackingMonth: 5000,
        sourceTracking: true,
        webpageTracking: true,
        videoViewTracking: true,
        scrollDepthTracking: true,
        buttonClickTracking: true,
        crossDomainTracking: false,
        formTracking: false,
        callScheduleTracking: false,
        purchaseTracking: false,
        dealsAndPipelineTracking: false
      },
      journeyAnalytics: {
        predictiveForecasting: true,
        funnelMode: true,
        dataComparison: true,
        goals: true,
        peopleDataExport: false,
        peopleFilters: false,
        allTrafficMode: false,
        countryWidget: false,
        trendsWidget: false,
        peopleWidget: false,
        pathExploration: false,
        peopleCsvFileExport: {
          _recall_name: 'peopleCsvFileExport',
          _display_name: 'People CSV File Export',
          value: false
        },
        filterComparison: false
      },
      integrations: {
        zapierIntegration: false,
        customWebhooks: false,
        goHighLevelIntegration: {
          _recall_name: 'goHighLevelIntegration',
          _display_name: 'GoHighLevel Integration',
          value: false
        },
        hubspotIntegration: false,
        gtmContainerLibrary: false
      },
      support: {
        communityHub: true,
        productTutorial: true,
        standardSupportEmail: false,
        dedicatedSupportEmail: false,
        oneOnOneKickoffSection: false
      },
      services: {
        fullTrackingSetup: false,
        agencyPartnerProgram: false
      }
    },
    PERFORMANCE_PRO: {
      header: {
        membership: 'Performance Pro',
        description: 'For marketing pros who want to visualize the performance of their lead generation.',
        priceMonthly: 199,
        priceAnnually: 1990
      },
      core: {
        adminUsers: 1,
        numberOfCanvases: 'Unlimited',
        workspaces: 1,
        workspacesCollaborators: 'Unlimited',
        dataRetention: '6 months',
        templateLibrary: true
      },
      journeyMapping: {
        canvasDuplicateLink: 'Unlimited',
        canvasViewOnlyLink: 'Unlimted',
        pngExport: 'High Resolution',
        customImages: true,
        presentationSlides: {
          _recall_name: 'presentationSlides',
          _display_name: 'Presentation Slides (Reports)',
          value: true
        },
        canvasTags: true,
        notes: true,
        checklists: true
      },
      journeyTracking: {
        peopleTrackingMonth: 25000,
        sourceTracking: true,
        webpageTracking: true,
        videoViewTracking: true,
        scrollDepthTracking: true,
        buttonClickTracking: true,
        crossDomainTracking: true,
        formTracking: true,
        callScheduleTracking: true,
        purchaseTracking: false,
        dealsAndPipelineTracking: false
      },
      journeyAnalytics: {
        predictiveForecasting: true,
        funnelMode: true,
        dataComparison: true,
        goals: true,
        peopleDataExport: true,
        peopleFilters: true,
        allTrafficMode: true,
        countryWidget: true,
        trendsWidget: true,
        peopleWidget: true,
        pathExploration: true,
        peopleCsvFileExport: {
          _recall_name: 'peopleCsvFileExport',
          _display_name: 'People CSV File Export',
          value: false
        },
        filterComparison: false
      },
      integrations: {
        zapierIntegration: true,
        customWebhooks: true,
        goHighLevelIntegration: {
          _recall_name: 'goHighLevelIntegration',
          _display_name: 'GoHighLevel Integration',
          value: false
        },
        hubspotIntegration: false,
        gtmContainerLibrary: false
      },
      support: {
        communityHub: true,
        productTutorial: true,
        standardSupportEmail: false,
        dedicatedSupportEmail: false,
        oneOnOneKickoffSection: false
      },
      services: {
        fullTrackingSetup: false,
        agencyPartnerProgram: false
      }
    },
    PERFORMANCE_BUSINESS: {
      header: {
        membership: 'Performance Business',
        description: 'For data-driven businesses who want to visualize and analyze their leads, deals and revenue journey.',
        priceMonthly: 499,
        priceAnnually: 4990
      },
      core: {
        adminUsers: 1,
        numberOfCanvases: 'Unlimited',
        workspaces: 3,
        workspacesCollaborators: 'Unlimited',
        dataRetention: '24 months',
        templateLibrary: true
      },
      journeyMapping: {
        canvasDuplicateLink: 'Unlimited',
        canvasViewOnlyLink: 'Unlimted',
        pngExport: 'High Resolution',
        customImages: true,
        presentationSlides: {
          _recall_name: 'presentationSlides',
          _display_name: 'Presentation Slides (Reports)',
          value: true
        },
        canvasTags: true,
        notes: true,
        checklists: true
      },
      journeyTracking: {
        peopleTrackingMonth: 100000,
        sourceTracking: true,
        webpageTracking: true,
        videoViewTracking: true,
        scrollDepthTracking: true,
        buttonClickTracking: true,
        crossDomainTracking: true,
        formTracking: true,
        callScheduleTracking: true,
        purchaseTracking: true,
        dealsAndPipelineTracking: true
      },
      journeyAnalytics: {
        predictiveForecasting: true,
        funnelMode: true,
        dataComparison: true,
        goals: true,
        peopleDataExport: true,
        peopleFilters: true,
        allTrafficMode: true,
        countryWidget: true,
        trendsWidget: true,
        peopleWidget: true,
        pathExploration: true,
        peopleCsvFileExport: {
          _recall_name: 'peopleCsvFileExport',
          _display_name: 'People CSV File Export',
          value: true
        },
        filterComparison: true
      },
      integrations: {
        zapierIntegration: true,
        customWebhooks: true,
        goHighLevelIntegration: {
          _recall_name: 'goHighLevelIntegration',
          _display_name: 'GoHighLevel Integration',
          value: true
        },
        hubspotIntegration: true,
        gtmContainerLibrary: true
      },
      support: {
        communityHub: true,
        productTutorial: true,
        standardSupportEmail: true,
        dedicatedSupportEmail: true,
        oneOnOneKickoffSection: true
      },
      services: {
        fullTrackingSetup: 'Annual Only',
        agencyPartnerProgram: 'Annual Only'
      }
    },
    PERFORMANCE_AGENCY: {
      header: {
        membership: 'Performance Agency',
        description: 'For performance-driven agencies who want to map, analyze and optimize funnels for all their clients.',
        priceMonthly: 999,
        priceAnnually: 9999
      },
      core: {
        adminUsers: 1,
        numberOfCanvases: 'Unlimited',
        workspaces: 'Unlimited',
        workspacesCollaborators: 'Unlimited',
        dataRetention: '24 months',
        templateLibrary: true
      },
      journeyMapping: {
        canvasDuplicateLink: 'Unlimited',
        canvasViewOnlyLink: 'Unlimted',
        pngExport: 'High Resolution',
        customImages: true,
        presentationSlides: {
          _recall_name: 'presentationSlides',
          _display_name: 'Presentation Slides (Reports)',
          value: true
        },
        canvasTags: true,
        notes: true,
        checklists: true
      },
      journeyTracking: {
        peopleTrackingMonth: 1000000,
        sourceTracking: true,
        webpageTracking: true,
        videoViewTracking: true,
        scrollDepthTracking: true,
        buttonClickTracking: true,
        crossDomainTracking: true,
        formTracking: true,
        callScheduleTracking: true,
        purchaseTracking: true,
        dealsAndPipelineTracking: true
      },
      journeyAnalytics: {
        predictiveForecasting: true,
        funnelMode: true,
        dataComparison: true,
        goals: true,
        peopleDataExport: true,
        peopleFilters: true,
        allTrafficMode: true,
        countryWidget: true,
        trendsWidget: true,
        peopleWidget: true,
        pathExploration: true,
        peopleCsvFileExport: {
          _recall_name: 'peopleCsvFileExport',
          _display_name: 'People CSV File Export',
          value: true
        },
        filterComparison: true
      },
      integrations: {
        zapierIntegration: true,
        customWebhooks: true,
        goHighLevelIntegration: {
          _recall_name: 'goHighLevelIntegration',
          _display_name: 'GoHighLevel Integration',
          value: true
        },
        hubspotIntegration: true,
        gtmContainerLibrary: true
      },
      support: {
        communityHub: true,
        productTutorial: true,
        standardSupportEmail: true,
        dedicatedSupportEmail: true,
        oneOnOneKickoffSection: true
      },
      services: {
        fullTrackingSetup: 'Application Only',
        agencyPartnerProgram: 'Application Only'
      }
    }
  };
  var SubscriptionConstants = Object.freeze({
    PricingTablePlansConfig: PricingTablePlansConfig,
    MembershipToPlans: MembershipToPlans
  });
  var _default = SubscriptionConstants;
  _exports.default = _default;
});