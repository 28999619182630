define("funnelytics/controllers/dashboard/settings/lookup/user", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    info: Ember.computed('model', function () {
      return {
        fields: _lodash.default.map(this.get('model.attrs').toArray(), function (item) {
          return item.get('key');
        }),
        data: _lodash.default.map(this.get('model.attrs').toArray(), function (item) {
          return item.get('value');
        })
      };
    }),
    steps: Ember.computed('model', function () {
      return _lodash.default.flatten(_lodash.default.map(this.get('model.sessions').toArray(), function (session) {
        return _lodash.default.map(session.get('steps').toArray(), function (step) {
          return {
            page: step.get('page'),
            referrer: step.get('referrer'),
            timestamp: step.get('created_at')
          };
        });
      }));
    }),
    actions: {
      download: function download() {
        var _this = this;

        var zip = new JSZip();
        zip.file('info.csv', Papa.unparse(this.get('info')));
        zip.file('steps.csv', Papa.unparse(this.get('steps')));
        zip.generateAsync({
          type: 'blob'
        }).then(function (file) {
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(file);
          link.download = _this.get('model.id');
          link.click();
        });
      },
      anonymize: function anonymize() {
        var _this2 = this;

        this.get('ajax').request("/tracker-profiles/anonymize/".concat(this.get('model.id')), {
          method: 'POST'
        }).then(function () {
          _this2.set('model.attrs', []);
        });
      },
      delete: function _delete() {
        var _this3 = this;

        this.get('model').destroyRecord().then(function () {
          _this3.get('router').transitionTo('dashboard.settings.lookup');
        });
      }
    }
  });

  _exports.default = _default;
});