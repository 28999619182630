define("funnelytics/components/503-404-fallback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // You can add as many paragraphs as needed in body text...within reason.
  var MAINTAINENCE_CONTENT = {
    subHeader: '503 - App Surgery',
    header: 'Uh oh! App unavailable.',
    bodyText: ['The application may be a tad overwhelmed at the moment, or we may be in the middle of performing surgery on the application.']
  };
  var MISSING_ROUTE_CONTENT = {
    subHeader: 'Uhhhh.....',
    header: "Congrats, you've broken the internet",
    bodyText: ['Or you might just be lost. Try hitting the back button.']
  };

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      if (this.get('404')) {
        this.set('content', MISSING_ROUTE_CONTENT);
        return;
      }

      this.set('content', MAINTAINENCE_CONTENT);
    }
  });

  _exports.default = _default;
});