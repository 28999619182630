define("funnelytics/controllers/dashboard/settings/lookup", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    total: 0,
    page: 0,
    query: '',
    loading: false,
    isDone: Ember.computed('model.@each', function () {
      return !(this.get('page') < this.get('total') / this.get('model.length'));
    }),
    users: Ember.computed('model.@each', function () {
      return _lodash.default.map(this.get('model'), function (session) {
        return {
          id: session.get('profile.id'),
          ips: session.get('profile.attrs') ? _lodash.default.reduce(session.get('profile.attrs').toArray(), function (arr, item) {
            if (item.get('key') === 'ip') {
              arr.push(item.get('value'));
            }

            return arr;
          }, []).join(',') : [],
          emails: session.get('profile.attrs') ? _lodash.default.reduce(session.get('profile.attrs').toArray(), function (arr, item) {
            if (item.get('key') === 'email') {
              arr.push(item.get('value'));
            }

            return arr;
          }, []).join(',') : []
        };
      });
    }),
    actions: {
      load: function load() {
        var _this = this;

        if (this.get('loading')) {
          return;
        }

        this.set('loading', true);
        this.incrementProperty('page');
        return this.get('store').query('tracker-session', {
          include: ['profile.attrs'],
          isNotNull: ['profile'],
          page: this.get('page'),
          query: this.get('query'),
          order: {
            column: 'created_at',
            direction: 'ASC'
          }
        }).then(function (sessions) {
          _this.set('total', sessions.get('meta.count'));

          _this.get('model').pushObjects(sessions.toArray());

          _this.set('loading', false);
        });
      },
      search: function search() {
        this.set('page', 0);
        this.get('model').clear();
        this.send('load');
      }
    }
  });

  _exports.default = _default;
});