define("funnelytics/components/for-reuse/ui-elements/testimonials", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        var element = Ember.$('.trustpilot-widget');

        if (window.Trustpilot) {
          window.Trustpilot.loadFromElement(element[0]);
        }
      });
    }
  });

  _exports.default = _default;
});