define("funnelytics/routes/dashboard/settings/lookup/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    model: function model(params) {
      return this.get('store').query('tracker-profile', {
        include: ['attrs', 'sessions.steps'],
        filter: {
          id: params.id
        }
      }).then(function (profiles) {
        return profiles.get('firstObject');
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('dashboard.settings.lookup.user', {
        into: 'dashboard.settings'
      });
    }
  });

  _exports.default = _default;
});