define("funnelytics/custom/constants/ModalConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DowngradeModals = void 0;
  var DowngradeModals = Object.freeze({
    downgradeAlert: 'isDowngradeAlertOpen',
    downgradeProcess: 'isDowngradeModalOpen',
    cancel: 'isCancelModalOpen',
    projectLock: 'isProjectLockModalOpen'
  });
  _exports.DowngradeModals = DowngradeModals;
});