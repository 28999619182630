define("funnelytics/components/canvas-elements/context-menu/tracking-settings/advanced-actions/attribute-values", ["exports", "lodash", "funnelytics/custom/analytics/settings/config/standard-action-value-converters"], function (_exports, _lodash, _standardActionValueConverters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actionValueDisplayConverter: Ember.computed('attributeKey', function () {
      return _lodash.default.get(_standardActionValueConverters.default, [this.get('attributeKey')], _standardActionValueConverters.default.DEFAULT);
    }),
    onSetAttributeValue: function onSetAttributeValue() {},
    actions: {
      setAttributeValue: function setAttributeValue(attributeValue) {
        var handler = this.get('onSetAttributeValue');
        handler(attributeValue);
      }
    }
  });

  _exports.default = _default;
});