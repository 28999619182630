define("funnelytics/routes/forgot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('email', '');
      controller.set('sending', false);
      controller.set('record', null);
    }
  });

  _exports.default = _default;
});