define("funnelytics/custom/flash-messages/FlashMessage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FlashMessage = Ember.Object.extend({
    isSuccess: Ember.computed.equal('type', 'success'),
    isInfo: Ember.computed.equal('type', 'info'),
    isWarning: Ember.computed.equal('type', 'warning'),
    isDanger: Ember.computed.equal('type', 'danger'),
    links: [],
    isPredefinedTemplate: Ember.computed.notEmpty('predefinedTemplate'),
    defaultTimeout: Ember.computed.alias('flashService.defaultTimeout'),
    queue: Ember.computed.alias('flashService.queue'),
    destroyLater: function () {
      var defaultTimeout = this.get('defaultTimeout');
      var timeout = this.getWithDefault('timeout', defaultTimeout);
      Ember.run.later(this, '_destroyMessage', timeout);
    }.on('init'),
    destroyMessage: function destroyMessage() {
      this._destroyMessage();
    },
    // private
    _destroyMessage: function _destroyMessage() {
      this.get('queue').removeObject(this);
      this.destroy();
    }
  });
  var _default = FlashMessage;
  _exports.default = _default;
});