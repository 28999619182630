define("funnelytics/components/library/canvas-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isFetching: false,
    //from parent, do not modify here.
    scrollEvt: function scrollEvt(evt) {
      var target = evt.target;
      var scrollTop = target.scrollTop,
          scrollHeight = target.scrollHeight,
          clientHeight = target.clientHeight;
      var percentTriggerConstant = 0.8;
      var triggerHeight = percentTriggerConstant * scrollHeight;

      if (scrollTop + clientHeight >= triggerHeight && !this.isFetching) {
        var handler = this.get('fetchMoreCards');
        handler();
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var funnelList = document.getElementById('funnel-list');
      funnelList.addEventListener('scroll', this.scrollEvt.bind(this), {
        passive: false
      });
    },
    willDestroyElement: function willDestroyElement() {
      try {
        var funnelList = document.getElementById('funnel-list');
        funnelList.removeEventListener('scroll');
      } catch (e) {
        console.log('error: ', e);
      }
    },
    fetchMoreCards: function fetchMoreCards() {}
  });

  _exports.default = _default;
});