define("funnelytics/templates/components/planning/notes-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "q4/UDY2r",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"notes-editor\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"id\",\"quill-area\"],[9],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/planning/notes-editor.hbs"
    }
  });

  _exports.default = _default;
});