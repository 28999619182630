define("funnelytics/controllers/dashboard/settings/account/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // If provided, attempt redirect to email-update
    queryParams: ['email-update'],
    'email-update': null
  });

  _exports.default = _default;
});