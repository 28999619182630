define("funnelytics/routes/dashboard/icons/folders/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.get('store').findRecord('icon-folder', params.id);
    },
    actions: {
      willTransition: function willTransition() {
        if (this.get('currentModel.hasDirtyAttributes')) {
          this.get('currentModel').rollbackAttributes();
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});