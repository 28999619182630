define("funnelytics/controllers/dashboard/projects/upgrade", ["exports", "funnelytics/custom/misc/WrappedError"], function (_exports, _WrappedError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_ERROR = 'An unexpected error occurred.';

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    modal: Ember.inject.service('current-routed-modal'),
    password: '',
    updating: false,
    passwordEmpty: Ember.computed.empty('password'),
    cannotSubmit: Ember.computed.or('passwordEmpty', 'updating'),
    emailUpdateHandler: function emailUpdateHandler() {},
    modalCloseHelper: function modalCloseHelper() {},
    userProjects: Ember.computed('allProjects.@each.isNew', function () {
      var projects = this.get('store.recordArrayManager').liveRecordArrayFor('project');
      return projects.filter(function (project) {
        return !project.get('is_locked') && project.get('version') !== 2;
      }).sort(function (a, b) {
        if (a.get('name').toLowerCase() > b.get('name').toLowerCase()) {
          return 1;
        } else if (a.get('name').toLowerCase() < b.get('name').toLowerCase()) {
          return -1;
        } else {
          return 0;
        }
      });
    }),
    cannotUpgradeProject: Ember.computed('selectedProject', function () {
      return !this.get('selectedProject');
    }),
    actions: {
      closeTheModal: function closeTheModal() {
        this.get('modalCloseHelper')();
      },
      upgradeProject: function upgradeProject() {
        var _this = this;

        this.send('closeTheModal');
        return Promise.resolve().then(function () {
          var selectedProject = _this.get('selectedProject');

          return _this.get('ajax').request('/projects/upgrade', {
            method: 'POST',
            data: {
              id: selectedProject
            }
          }).then(function (data) {
            _this.get('store').pushPayload(data);
          }).catch(function (err) {
            _this.set('projectWarning', true);

            _this.set('projectWarningSeverity', 'warning');

            var wrappedError = new _WrappedError.default(err);

            _this.set('projectWarningHeader', wrappedError.getFirstTitle());

            _this.set('projectWarningMessage', wrappedError.getFirstDetail());
          }).finally(function () {
            history.back();
            setTimeout(function () {
              window.location.reload(true);
            }, 100);
          });
        });
      },
      selectProject: function selectProject(projectId) {
        this.set('selectedProject', projectId);
      }
    }
  });

  _exports.default = _default;
});