define("funnelytics/components/tutorial-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TUTORIAL_CANVAS_ID = '049b6218-1269-41a0-8d3d-ec84471c8b78';

  var _default = Ember.Component.extend({
    funnelyticsRedirect: Ember.inject.service(),
    isSmall: false,
    display: true,
    actions: {
      openTutorialCanvas: function openTutorialCanvas() {
        return this.get('funnelyticsRedirect').redirectToTheTutorialCanvas(TUTORIAL_CANVAS_ID);
      }
    }
  });

  _exports.default = _default;
});