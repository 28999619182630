define("funnelytics/components/containers/funnel-editor/components/right-sidebar-tracking-section", ["exports", "lodash", "@funnelytics/utilities", "funnelytics/custom/constants/ListenerConstants", "funnelytics/custom/constants/CanvasConstants"], function (_exports, _lodash, _utilities, _ListenerConstants, _CanvasConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Permissions = _utilities.constants.Permissions;
  var ENABLED_PROPERTIES_KEY = 'properties';
  var DISABLED_PROPERTIES_KEY = 'hidden_properties';
  var ENABLED_PARAMETERS_KEY = 'parameters';
  var DISABLED_PARAMETERS_KEY = 'hidden_parameters';

  var _default = Ember.Component.extend({
    userPermissions: Ember.inject.service(),
    canvasService: Ember.inject.service(),
    ACTION_TYPE_STANDARD: _CanvasConstants.default.ACTION_TYPE_STANDARD,
    ACTION_TYPE_COMMERCE: _CanvasConstants.default.ACTION_TYPE_COMMERCE,
    isActionTypeStandard: Ember.computed.equal('actionType', _CanvasConstants.default.ACTION_TYPE_STANDARD),
    isActionTypeCommerce: Ember.computed.equal('actionType', _CanvasConstants.default.ACTION_TYPE_COMMERCE),
    isEditingPage: Ember.computed.equal('type', _CanvasConstants.default.TYPE_PAGE),
    isEditingTraffic: Ember.computed.equal('type', _CanvasConstants.default.TYPE_SOURCE),
    isEditingAction: Ember.computed.equal('type', _CanvasConstants.default.TYPE_EVENT),
    isSimpleAction: Ember.computed.bool('editing.isSimpleAction'),
    isNotSimpleAction: Ember.computed.not('isSimpleAction'),
    isAdvancedAction: Ember.computed.and('isEditingAction', 'isNotSimpleAction'),
    goals: [],
    sections: {
      parameters: {
        enabled: false
      },
      properties: {
        enabled: false
      },
      utm: {
        enabled: false
      }
    },
    hasSomeAnalyticsPermissions: Ember.computed.or('hasProjectAnalyticsPermission', 'hasAnalyticsAdminPermission'),
    canvasEntity: null,
    watchElementChange: Ember.observer('model', function () {
      this.initializeNewEntity(this.get('model'));
    }),
    initializeNewEntity: function initializeNewEntity(model) {
      var _this = this;

      var entity = this.get('canvasEntity');

      if (entity) {
        entity.removeAllListenersOnThisObject();
        this.set('canvasEntity', null);
      }

      if (model) {
        var newEntity = this.get('canvasService').wrapEntity(model);
        this.set('canvasEntity', newEntity);
        newEntity.on(_ListenerConstants.default.ENTITY_ACTION_TYPE_CHANGE, function (actionType) {
          if (!_this.get('isDestroyed')) {
            _this.send('setActionType', actionType, {
              willUpdateModel: false
            });
          }
        });
      }

      this.send('initialize');
    },
    watchParameterCount: Ember.observer('parameterCount', function () {
      this.send('initialize');
    }),
    initializeProperties: function initializeProperties() {
      this.setProperties({
        isLoadingPermissions: false,
        hasProjectAnalyticsPermission: false,
        hasAnalyticsAdminPermission: false,
        hasForecastingPermissions: false
      });
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.initializeProperties();
      this.initializeNewEntity(this.get('model'));
      this.set('isLoadingPermissions', true);
      Ember.RSVP.hash({
        hasProjectAnalyticsPermission: this.get('userPermissions').hasPermission({
          permission: Permissions.TYPE_ANALYTICS,
          scope: Permissions.SCOPE_PROJECT,
          scopeId: this.get('funnel.project.id')
        }),
        hasAnalyticsAdminPermission: this.get('userPermissions').hasPermission({
          permission: Permissions.TYPE_ANALYTICS,
          accessLevel: Permissions.ACCESS_LEVEL_ADMIN
        }),
        hasForecastingPermissions: this.get('userPermissions').hasPermission({
          permission: Permissions.TYPE_FORECAST,
          scope: Permissions.SCOPE_PROJECT,
          scopeId: this.get('funnel.project.id')
        })
      }).then(function (_ref) {
        var hasProjectAnalyticsPermission = _ref.hasProjectAnalyticsPermission,
            hasAnalyticsAdminPermission = _ref.hasAnalyticsAdminPermission,
            hasForecastingPermissions = _ref.hasForecastingPermissions;

        _this2.setProperties({
          isLoadingPermissions: false,
          hasProjectAnalyticsPermission: hasProjectAnalyticsPermission,
          hasAnalyticsAdminPermission: hasAnalyticsAdminPermission,
          hasForecastingPermissions: hasForecastingPermissions
        });
      });
    },
    onUpdateParameter: function onUpdateParameter() {},
    onPreviewEdit: function onPreviewEdit() {},
    actions: {
      initialize: function initialize() {
        if (!this.get('model')) {
          return;
        }

        var isSimpleAction = this.get('model').prop('isSimpleAction');
        var properties = this.get('model').attr('properties');

        if (!isSimpleAction && this.get('model').attr('properties') && Object.keys(properties).length > 0) {
          this.set('sections.properties.enabled', true);
        } else {
          this.set('sections.properties.enabled', false);
        }

        if ((this.get('model').get('parameters') || []).length > 0) {
          this.set('sections.parameters.enabled', true);
        } else {
          this.set('sections.parameters.enabled', false);
        }

        var entity = this.get('canvasEntity');

        if (!entity) {
          return;
        }

        if (entity.getType() === _CanvasConstants.default.TYPE_EVENT) {
          this.send('setActionType', entity.getActionType(), {
            willUpdateModel: false
          });
        }
      },
      setAsSimpleAction: function setAsSimpleAction() {
        var canvasEntity = this.get('canvasEntity');

        if (!canvasEntity) {
          return;
        }

        canvasEntity.setIsSimpleAction(true);
        this.set('editing.isSimpleAction', true);
        this.send('setFeatureEnabled', 'properties', false);
      },
      setAsAdvancedAction: function setAsAdvancedAction() {
        var canvasEntity = this.get('canvasEntity');

        if (!canvasEntity) {
          return;
        }

        canvasEntity.setIsSimpleAction(false);
        this.set('editing.isSimpleAction', false);
        this.send('setFeatureEnabled', 'properties', false);
      },
      setFeatureEnabled: function setFeatureEnabled(section, toggle) {
        var enabled;

        if (typeof toggle === 'boolean') {
          enabled = toggle;
        } else {
          enabled = _lodash.default.get(toggle, ['target', 'checked']) || false;
        }

        this.set("sections.".concat(section, ".enabled"), enabled);

        switch (section) {
          /**
           * When parameters are disabled, we safely store them in the cell and keep them hidden from the analytics.
           * Upon re-enabling parameter filtering, we check to see if hidden parameters exist. In case they do, we make
           * them visible to analytics once again. Same idea with event properties.
           */
          case 'parameters':
            if (enabled) {
              var hidden = this.get('model').prop(DISABLED_PARAMETERS_KEY);
              this.get('model').prop(ENABLED_PARAMETERS_KEY, hidden);
              this.get('model').prop(DISABLED_PARAMETERS_KEY, undefined);
            } else {
              var existing = this.get('model').prop(ENABLED_PARAMETERS_KEY);
              this.get('model').prop(DISABLED_PARAMETERS_KEY, existing);
              this.get('model').prop(ENABLED_PARAMETERS_KEY, undefined);
            }

            break;

          case 'properties':
            if (enabled) {
              var _hidden = _lodash.default.get(this.get('model'), ['attributes', 'attrs', DISABLED_PROPERTIES_KEY]);

              if (_hidden) {
                _lodash.default.set(this.get('model'), ['attributes', 'attrs', ENABLED_PROPERTIES_KEY], _hidden);

                _lodash.default.set(this.get('model'), ['attributes', 'attrs', DISABLED_PROPERTIES_KEY], null);
              }
            } else {
              var _existing = _lodash.default.get(this.get('model'), ['attributes', 'attrs', ENABLED_PROPERTIES_KEY]);

              if (_existing) {
                _lodash.default.set(this.get('model'), ['attributes', 'attrs', DISABLED_PROPERTIES_KEY], _existing);

                _lodash.default.set(this.get('model'), ['attributes', 'attrs', ENABLED_PROPERTIES_KEY], null);
              }
            }

            break;
        }
      },
      handleUpdateParameter: function handleUpdateParameter() {
        var updateHandler = this.get('onUpdateParameter');
        updateHandler();
      },
      handlePreviewEdit: function handlePreviewEdit(evt) {
        var editHandler = this.get('onPreviewEdit');
        editHandler(evt);
      },
      setActionType: function setActionType(type) {
        var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
            _ref2$willUpdateModel = _ref2.willUpdateModel,
            willUpdateModel = _ref2$willUpdateModel === void 0 ? true : _ref2$willUpdateModel;

        var entity = this.get('canvasEntity');

        if (!entity) {
          return;
        }

        if (entity.getType() !== _CanvasConstants.default.TYPE_EVENT) {
          return;
        }

        if (willUpdateModel) {
          entity.setActionType(type);
        }

        this.set('actionType', type);

        if (type === _CanvasConstants.default.ACTION_TYPE_COMMERCE) {
          // Properties must be enabled for the commerce action to work
          this.set('sections.properties.enabled', true);
        }
      }
    }
  });

  _exports.default = _default;
});