define("funnelytics/components/library/sort-filter", ["exports", "funnelytics/custom/constants/LibraryConstants", "lodash"], function (_exports, _LibraryConstants, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var SortbyConstants = {
    NAME_ASC: 'name:asc',
    NAME_DESC: 'name:desc',
    POPULARITY: 'import_count:desc',
    LATEST: 'updated_at:desc',
    OLDEST: 'updated_at:asc'
  };

  var _default = Ember.Component.extend({
    /**
     * @property {Array.<String>} purposesOption - Array of strings for purposes option in dropdown
     * @property {Array.<String>} industriesOption - Array of strings for industries option in dropdown
     * @property {Array.<String>} offerTypesOption - Array of strings for offer types option in dropdown
     * @property {Array.<String>} creatorOption - Array of strings for creator option in dropdown. Related to is_funnelytics.
     * @property {(Array.<String> | null)} filteredIndustry - Array of strings for API returned industries that are unique to the current filters
     * @property {(Array.<String> | null)} filteredPurpose - Array of strings for API returned purposes that are unique to the current filters
     * @property {(Array.<String> | null)} filteredOfferType - Array of strings for API returned offer types that are unique to the current filters
     * @property {(Boolean | null)} filteredCreator - If filter applied, else null
     */
    purposesOption: null,
    industriesOption: null,
    offerTypesOption: null,
    creatorOption: null,
    filteredIndustry: null,
    filteredPurpose: null,
    filteredOfferType: null,
    filteredCreator: null,
    creator: null,
    creatorEquivalent: Ember.computed('creator', function () {
      var creator = this.creator;
      var _LibraryConstants$CRE = _LibraryConstants.default.CREATOR,
          FUNNELYTICS = _LibraryConstants$CRE.FUNNELYTICS,
          COMMUNITY = _LibraryConstants$CRE.COMMUNITY;

      if (creator === 'true') {
        return FUNNELYTICS;
      } else if (creator === 'false') {
        return COMMUNITY;
      } else {
        return null;
      }
    }),
    observeFilteredChanges: Ember.observer('filteredIndustry', 'filteredPurpose', 'filteredOfferType', 'filteredCreator', function () {
      this.setAllOptions();
    }),
    init: function init() {
      this._super.apply(this, arguments);

      this.setAllOptions();
    },
    setAllOptions: function setAllOptions() {
      //filter options
      var defaultOption = {
        text: null,
        displayText: 'Show All'
      };
      var INDUSTRIES = _LibraryConstants.default.INDUSTRIES,
          PURPOSES = _LibraryConstants.default.PURPOSES,
          OFFER_TYPES = _LibraryConstants.default.OFFER_TYPES,
          CREATOR = _LibraryConstants.default.CREATOR;
      var definitions = [{
        lib: INDUSTRIES,
        filteredLib: this.filteredIndustry,
        type: 'industry'
      }, {
        lib: PURPOSES,
        filteredLib: this.filteredPurpose,
        type: 'purpose'
      }, {
        lib: OFFER_TYPES,
        filteredLib: this.filteredOfferType,
        type: 'offerType'
      }, {
        lib: CREATOR,
        filteredLib: this.filteredCreator,
        type: 'creator'
      }];

      var _definitions$map = definitions.map(function (libObj) {
        var lib = libObj.lib,
            filteredLib = libObj.filteredLib,
            type = libObj.type;
        return Object.values(lib).sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        }).filter(function (item) {
          if (type === 'creator') {
            if (filteredLib === null || filteredLib === true && item === CREATOR.FUNNELYTICS || filteredLib === false && item === CREATOR.COMMUNITY) return true;
            return false;
          }

          if (!filteredLib) return true;
          return filteredLib.includes(item);
        }).map(function (item) {
          // eslint-disable-next-line no-underscore-dangle
          var displayText = function displayText(value) {
            if (item === 'SaaS') {
              return 'SaaS';
            } else {
              return _lodash.default.startCase(value);
            }
          };

          return {
            text: item,
            displayText: displayText(item)
          };
        });
      }),
          _definitions$map2 = _slicedToArray(_definitions$map, 4),
          industriesOption = _definitions$map2[0],
          purposesOption = _definitions$map2[1],
          offerTypesOption = _definitions$map2[2],
          creatorOption = _definitions$map2[3];

      this.set('industriesOption', [defaultOption].concat(_toConsumableArray(industriesOption)));
      this.set('purposesOption', [defaultOption].concat(_toConsumableArray(purposesOption)));
      this.set('offerTypesOption', [defaultOption].concat(_toConsumableArray(offerTypesOption)));
      this.set('creatorOption', [defaultOption].concat(_toConsumableArray(creatorOption))); //sort options

      var defineOptionsText = function defineOptionsText(value) {
        if (value === 'NAME_DESC') {
          return 'Name (Z-A)';
        } else if (value === 'NAME_ASC') {
          return 'Name (A-Z)';
        } else {
          return _lodash.default.capitalize(value);
        }
      };

      var sortOptions = _toConsumableArray(Object.entries(SortbyConstants).map(function (value) {
        return {
          text: value[1],
          displayText: defineOptionsText(value[0])
        };
      }));

      this.set('sortOptions', sortOptions);
    },
    onSortChange: function onSortChange() {},
    onPurposeChange: function onPurposeChange() {},
    onIndustryChange: function onIndustryChange() {},
    onOfferTypeChange: function onOfferTypeChange() {},
    onCreatorChange: function onCreatorChange() {},
    actions: {
      handleCreatorChange: function handleCreatorChange(value) {
        var onChangeHandler = this.get('onCreatorChange');
        onChangeHandler(value);
      },
      handlePurposeChange: function handlePurposeChange(value) {
        var onChangeHandler = this.get('onPurposeChange');
        onChangeHandler(value);
      },
      handleIndustryChange: function handleIndustryChange(value) {
        var onChangeHandler = this.get('onIndustryChange');
        onChangeHandler(value);
      },
      handleOfferTypeChange: function handleOfferTypeChange(value) {
        var onChangeHandler = this.get('onOfferTypeChange');
        onChangeHandler(value);
      },
      handleSortChange: function handleSortChange(value) {
        var onChangeHandler = this.get('onSortChange');
        onChangeHandler(value);
      }
    }
  });

  _exports.default = _default;
});