define("funnelytics/components/for-reuse/truncated-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_MAX_LENGTH = 10;

  var _default = Ember.Component.extend({
    tagName: '',
    maxLengthFinal: Ember.computed('maxLength', function () {
      var maxLengthProp = this.get('maxLength');

      if (!maxLengthProp) {
        return DEFAULT_MAX_LENGTH;
      }

      return maxLengthProp;
    }),
    truncatedText: Ember.computed('text', 'maxLengthFinal', function () {
      var text = this.get('text');
      var maxLength = this.get('maxLengthFinal');

      if (text.length > maxLength) {
        return "".concat(text.substring(0, maxLength), "...");
      }

      return text;
    })
  });

  _exports.default = _default;
});