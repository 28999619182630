define("funnelytics/controllers/login", ["exports", "lodash/object", "lodash/get"], function (_exports, _object2, _get2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['url', 'param', 'updated', 'redirectTo', 'performance', 'dcc', 'discount', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'partner', 'ref', 'checkout', 'jn', 'cart', 'ws', 'next'],
    exclusionQueryParamsList: ['param', 'updated'],
    session: Ember.inject.service(),
    paramBasedRedirect: Ember.inject.service(),
    router: Ember.inject.service(),
    cookiesService: Ember.inject.service(),
    ajax: Ember.inject.service(),
    url: null,
    param: null,
    updated: null,
    redirectTo: null,
    performance: null,
    dcc: null,
    discount: null,
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
    utm_term: null,
    checkout: null,
    jn: null,
    cart: null,
    ws: null,
    // worskpace id, to open by default
    next: null,
    loggingIn: false,
    emailProvided: Ember.computed.notEmpty('identification'),
    passwordProvided: Ember.computed.notEmpty('password'),
    notLoggingIn: Ember.computed.not('loggingIn'),
    canLogIn: Ember.computed.and('notLoggingIn', 'emailProvided', 'passwordProvided'),
    cannotLogIn: Ember.computed.not('canLogIn'),
    registerUrl: Ember.computed('performance', 'checkout', 'next', function () {
      return '/register?' + this.get('paramBasedRedirect').allQueryParams(this, this.queryParams, this.exclusionQueryParamsList).paramString;
    }),
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var self = this;

        var _this$getProperties = this.getProperties('identification', 'password'),
            identification = _this$getProperties.identification,
            password = _this$getProperties.password;

        if (this.get('canLogIn')) {
          this.set('loggingIn', true);
          this.get('session').authenticate('authenticator:jwt', {
            username: identification,
            password: password
          }).then(function () {
            var flSession = _this.get('cookiesService').getFLSessionFromCookie();

            if (!flSession) {
              return;
            }

            _this.get('ajax').request('/tracking/session', {
              method: 'POST',
              data: {
                flSession: flSession
              }
            });
          }).catch(function (res) {
            _this.set('loggingIn', false);

            var defaultMessage = 'Failed to login';
            var error = (0, _get2.default)(res, 'json.errors[0]');
            var msg;

            if (error) {
              var messageFromError = "You have not set a password yet.\n                Please follow the link in the email we sent you to complete the setup process.";
              var isForbiddenPasswordError = (0, _get2.default)(error, 'status') === 403 && (0, _get2.default)(error, 'detail') === 'password_not_set';
              msg = isForbiddenPasswordError ? messageFromError : defaultMessage;
            } else {
              msg = _object2.default.has(res, 'message') ? res.message : defaultMessage;
            }

            self.set('error', msg);
          });
        }
      }
    }
  });

  _exports.default = _default;
});