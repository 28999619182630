define("funnelytics/mixins/vimeo-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    validVideoLink: function validVideoLink(link) {
      /**
       * Takes link, validates it is a a vimeo player or embeded link.
       * If it is a valid link, returns embeded link, otherwise returns empty string.
       * @param {string} link - vimeo link
       * @returns {string} - vimeo embeded link
       */
      // eslint-disable-next-line no-underscore-dangle
      var _getVimeoVideoId = function _getVimeoVideoId(link) {
        var regex = /(?:player\.)?vimeo\.com\/((?:video|external)\/.*?(\d+))|(?:player\.)?vimeo\.com\/.*?(\d+)/i;
        if (!link) return;
        var match = link.match(regex);

        if (!match) {
          return '';
        }

        var found = match[3] || match[2] || match[1];

        if (match && found) {
          return found;
        } // Return an empty string or handle the case where the link is invalid


        return '';
      }; // eslint-disable-next-line no-underscore-dangle


      var _createEmbededVideo = function _createEmbededVideo(id) {
        return "https://player.vimeo.com/video/".concat(id, "?title=0&byline=0&portrait=0");
      };

      var validVimeoLink = function validVimeoLink(link) {
        var vimeoId = _getVimeoVideoId(link);

        if (!vimeoId) {
          return '';
        }

        return _createEmbededVideo(vimeoId);
      };

      return validVimeoLink(link);
    }
  });

  _exports.default = _default;
});