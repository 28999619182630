define("funnelytics/custom/canvas/ContextMenu/ContextMenuConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Object.freeze({
    INITIAL_ENTITY_TYPE_SOURCE: 'INITIAL_ENTITY_TYPE_SOURCE',
    INITIAL_ENTITY_TYPE_TARGET: 'INITIAL_ENTITY_TYPE_TARGET',
    INITIAL_ENTITY_TYPE_EXPLORE: 'INITIAL_ENTITY_TYPE_EXPLORE',
    INITIAL_ENTITY_TYPE_TRACKING: 'INITIAL_ENTITY_TYPE_TRACKING',
    INITIAL_ENTITY_TYPE_NULL: null,
    STYLE_LIST: 'ctx.list',
    STYLE_GRID: 'ctx.grid'
  });

  _exports.default = _default;
});