define("funnelytics/routes/dashboard/settings/index", ["exports", "lodash/get"], function (_exports, _get2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      return this.transitionTo('dashboard.settings.account');
    },
    model: function model() {
      var projectClients = (0, _get2.default)(this.modelFor('dashboard'), 'projectClients', []);
      return {
        projectClients: projectClients
      };
    }
  });

  _exports.default = _default;
});