define("funnelytics/components/for-reuse/tracking-script", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onScriptClick: function onScriptClick() {},
    isToggled: false,
    actions: {
      onScriptClick: function onScriptClick() {
        this.get('onScriptClick')();
      }
    }
  });

  _exports.default = _default;
});