define("funnelytics/templates/components/left-sidebar/navigation/project-nav", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s3WX0RoY",
    "block": "{\"symbols\":[\"icon\"],\"statements\":[[7,\"div\"],[11,\"class\",\"sidebar-workspace\"],[12,\"style\",[28,[\"height: \",[21,\"containerHeight\"],\";\"]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"sideNavBarIconsV2\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[1,[27,\"project-icon\",null,[[\"shortName\",\"name\",\"color\",\"projectId\",\"locked\",\"updateSelected\"],[[22,1,[\"shortName\"]],[22,1,[\"name\"]],[22,1,[\"color\"]],[22,1,[\"id\"]],[22,1,[\"locked\"]],[27,\"action\",[[22,0,[]],\"updateSelected\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/left-sidebar/navigation/project-nav.hbs"
    }
  });

  _exports.default = _default;
});