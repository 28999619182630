define("funnelytics/templates/components/containers/funnel-editor/icon-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "STHHuDV1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"iconType\"]],\"offline\"],null]],null,{\"statements\":[[0,\"  Misc\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"iconType\"]],\"source\"],null]],null,{\"statements\":[[0,\"  Traffic\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[27,\"capitalize\",[[23,[\"iconType\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/containers/funnel-editor/icon-type.hbs"
    }
  });

  _exports.default = _default;
});