define("funnelytics/components/account/registration/user-type-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var USER_TYPES = {
    solo: 'solo',
    business: 'business',
    agency: 'agency'
  };

  var _default = Ember.Component.extend({
    selected: null,
    types: [USER_TYPES.solo, USER_TYPES.business, USER_TYPES.agency],
    actions: {
      setActive: function setActive(userType) {
        this.set('selected', userType);
      }
    }
  });

  _exports.default = _default;
});