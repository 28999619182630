define("funnelytics/components/planning/funnel-notes", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    canvasService: Ember.inject.service(),
    stepsWithNotes: [],
    isNotesEditorExpanded: Ember.computed.readOnly('canvasService.isNotesEditorExpanded'),
    onOpenStepNotes: function onOpenStepNotes() {},
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('stepsWithNotes', []);
      this.get('paper.model.model.attributes.cells.models').forEach(function (cell) {
        if (_lodash.default.get(cell, 'attributes.attrs.oldNoteStorage') || _lodash.default.get(cell, 'attributes.attrs.planningNotes.ops[0].insert.length') >= 2) {
          _this.get('stepsWithNotes').addObject(cell);
        }
      });
    }
  });

  _exports.default = _default;
});