define("funnelytics/templates/components/input/canvas-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NnItI+iG",
    "block": "{\"symbols\":[\"canvasTag\",\"index\",\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[21,\"classModifier\"]],[12,\"style\",[27,\"if\",[[23,[\"hideHeader\"]],\"border: none;\"],null]],[12,\"id\",[27,\"if\",[[23,[\"tagId\"]],[23,[\"tagId\"]]],null]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"canvasTags\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"dynamicClosingTags\"]]],null,{\"statements\":[[4,\"if\",[[27,\"lt\",[[22,2,[]],[23,[\"numCloseTagsEnabled\"]]],null]],null,{\"statements\":[[0,\"        \"],[1,[27,\"component\",[\"tags/input-tag\"],[[\"mode\",\"text\",\"title\",\"maxTagCharacters\",\"onClick\",\"onClose\"],[[23,[\"mode\"]],[22,1,[\"text\"]],[22,1,[\"hoverText\"]],[23,[\"maxTagCharacters\"]],[23,[\"onClick\"]],[23,[\"onClose\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[27,\"component\",[\"tags/input-tag\"],[[\"mode\",\"text\",\"title\",\"maxTagCharacters\",\"onClick\"],[[23,[\"mode\"]],[22,1,[\"text\"]],[22,1,[\"hoverText\"]],[23,[\"maxTagCharacters\"]],[23,[\"onClick\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[27,\"component\",[\"tags/input-tag\"],[[\"mode\",\"text\",\"title\",\"maxTagCharacters\",\"onClick\"],[[23,[\"mode\"]],[22,1,[\"text\"]],[22,1,[\"hoverText\"]],[23,[\"maxTagCharacters\"]],[23,[\"onClick\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1,2]},null],[0,\"  \"],[14,3],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/input/canvas-filter.hbs"
    }
  });

  _exports.default = _default;
});