define("funnelytics/custom/constants/CanvasConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Object$freeze;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  // TODO: This should now be known (in variable names) as a TRAFFIC
  var TYPE_SOURCE_NAME = 'Source';
  var TYPE_PAGE_NAME = 'Page';
  var TYPE_EVENT_NAME = 'Event';
  var TYPE_OFFLINE_NAME = 'Offline';
  var CANVAS_TYPE_FUNNEL = 'funnel';
  var CANVAS_TYPE_TEMPLATE = 'template';
  var TYPE_CONNECTION = 'funnel_misc.Connection';
  var TYPE_GHOST_LINE = 'funnel_misc.GhostLine';
  var TYPE_RECTANGLE = 'standard.Rectangle';
  var TYPE_CIRCLE = 'standard.Ellipse';
  var TYPE_POLYGON = 'standard.Polygon';
  var TYPE_IMAGE = 'standard.Image';
  var SHAPE_RECTANGLE = 'Rectangle';
  var SHAPE_CIRCLE = 'Circle';
  var SHAPE_TRIANGLE = 'Triangle';
  var CUSTOM_IMAGE = 'customImage';
  var TEXT_AREA = 'textArea';
  var ACTION_TYPE_STANDARD = 'standard';
  var ACTION_TYPE_COMMERCE = 'commerce';
  var CANVAS_LABEL_KEY = '.label';
  var ENTITY_NUMBERS_LABEL = '.visits';
  var ENTITY_FORECAST_LABEL = '.forecast-label';
  var CONNECTION_KEY = '.connection';
  var ENTITY_LEAD_TIME_LABEL = '.lead_time-label';
  var ENTITY_COMPARE_SOURCE_LABEL = '.compare-source__label';
  var ENTITY_COMPARE_TARGET_LABEL = '.compare-target__label';
  var ENTITY_COMPARE_DIFF_LABEL = '.compare-diff__label';
  var ENTITY_COMPARE_WRAPPER = 'compare__wrapper';
  var CanvasConstants = Object.freeze({
    TYPE_SOURCE_NAME: TYPE_SOURCE_NAME,
    TYPE_PAGE_NAME: TYPE_PAGE_NAME,
    TYPE_EVENT_NAME: TYPE_EVENT_NAME,
    TYPE_OFFLINE_NAME: TYPE_OFFLINE_NAME,
    TYPE_PAGE: "funnel_steps.".concat(TYPE_PAGE_NAME),
    TYPE_SOURCE: "funnel_steps.".concat(TYPE_SOURCE_NAME),
    TYPE_EVENT: "funnel_steps.".concat(TYPE_EVENT_NAME),
    TYPE_OFFLINE: 'funnel_steps.Offline',
    TYPE_GROUP: 'funnel_misc.Group',
    TYPE_CONNECTION: TYPE_CONNECTION,
    TYPE_GHOST_LINE: TYPE_GHOST_LINE,
    TYPE_BRIDGE: 'funnel_misc.Bridge',
    TYPE_TEXTAREA: 'funnel_misc.TextArea',
    TYPE_DOT: 'funnel_misc.Dot',
    TYPE_TEXT: 'basic.text',
    TYPE_RECTANGLE: TYPE_RECTANGLE,
    TYPE_CIRCLE: TYPE_CIRCLE,
    TYPE_POLYGON: TYPE_POLYGON,
    TYPE_IMAGE: TYPE_IMAGE,
    LINE_TYPES: Object.freeze([TYPE_CONNECTION, TYPE_GHOST_LINE]),
    ROTATABLE_TYPES: Object.freeze([TYPE_RECTANGLE, TYPE_CIRCLE, TYPE_POLYGON, TYPE_IMAGE]),
    ACTION_TYPE_STANDARD: 'standard',
    ACTION_TYPE_COMMERCE: 'commerce',
    ACTION_TYPES: Object.freeze([ACTION_TYPE_STANDARD, ACTION_TYPE_COMMERCE]),
    JSON_TYPE_DEPENDENT_EVENT: 'event.dependent',
    JSON_TYPE_DEPENDENT_EVENT_IGNORE_IN_BETWEEN: 'event.dependent.ignore-in-between',
    JSON_TYPE_EVENT: 'event',
    JSON_TYPE_PAGE: 'page',
    JSON_TYPE_OFFLINE: 'offline',
    JSON_TYPE_SOURCE: 'source',
    JSON_CATEGORY_CONNECTION: 'connection',
    JSON_CATEGORY_STEP: 'step',
    CLASS_ANIMATION_DOTS: 'link-animation-circle',
    // These are awkwardly both css classes and jointjs attributes (one and the same), the leading dot (.) must remain
    CANVAS_LABEL_KEY: CANVAS_LABEL_KEY,
    ENTITY_NUMBERS_LABEL: ENTITY_NUMBERS_LABEL,
    ENTITY_FORECAST_LABEL: ENTITY_FORECAST_LABEL,
    ENTITY_LEAD_TIME_LABEL: ENTITY_LEAD_TIME_LABEL,
    ENTITY_COMPARE_SOURCE_LABEL: ENTITY_COMPARE_SOURCE_LABEL,
    ENTITY_COMPARE_TARGET_LABEL: ENTITY_COMPARE_TARGET_LABEL,
    ENTITY_COMPARE_DIFF_LABEL: ENTITY_COMPARE_DIFF_LABEL,
    ENTITY_COMPARE_WRAPPER: ENTITY_COMPARE_WRAPPER,
    CONNECTION_KEY: CONNECTION_KEY,
    // \ end css classes / jointjs attributes
    ENTITY_NUMBERS_LABEL_TEXT_ARRAY: Object.freeze([ENTITY_NUMBERS_LABEL, 'text']),
    ENTITY_FORECASTING_LABEL_TEXT_ARRAY: Object.freeze([ENTITY_FORECAST_LABEL, 'text']),
    ENTITY_LEAD_TIME_LABEL_TEXT_ARRAY: Object.freeze([ENTITY_LEAD_TIME_LABEL, 'text']),
    ENTITY_COMPARE_SOURCE_LABEL_TEXT_ARRAY: Object.freeze([ENTITY_COMPARE_SOURCE_LABEL, 'text']),
    ENTITY_COMPARE_TARGET_LABEL_TEXT_ARRAY: Object.freeze([ENTITY_COMPARE_TARGET_LABEL, 'text']),
    ENTITY_COMPARE_DIFF_LABEL_TEXT_ARRAY: Object.freeze([ENTITY_COMPARE_DIFF_LABEL, 'text']),
    ENTITY_COMPARE_DIFF_LABEL_FILL_ARRAY: Object.freeze([ENTITY_COMPARE_DIFF_LABEL, 'fill']),
    ENTITY_COMPARE_WRAPPER_DISPLAY_ARRAY: Object.freeze([ENTITY_COMPARE_WRAPPER, 'display']),
    ENTITY_LABEL_TYPE_NUMBERS: 'analytics-label',
    ENTITY_LABEL_TYPE_LEAD_TIME: 'lead-time-label',
    ENTITY_LABEL_TYPE_FORECAST: 'forecast-label',
    ENTITY_LABEL_TYPE_COMPARE: 'compare-label',
    ENTITY_LABEL_TYPE_COMPARE_SOURCE: 'compare-source-label',
    ENTITY_LABEL_TYPE_COMPARE_TARGET: 'compare-source-target',
    ENTITY_LABEL_TYPE_COMPARE_DIFF: 'compare-source-diff',
    ICON_LIMIT_HANDLER: 'onIconLimitReach',
    NOTES_CHANGED_HANDLER: 'onNotesChanged',
    LINE_STYLE_SOLID: 'solid',
    LINE_STYLE_DASHED: 'dashed',
    LINE_COLOR_DEFAULT: '#009BF3',
    // Entity Editor
    EDITOR_TYPE_CANVAS_SETTINGS: 'canvas-settings',
    EDITOR_TYPE_CANVAS_NOTES: 'funnel-notes',
    EDITOR_TYPE_STEP_SETTINGS: 'step-settings',
    EDITOR_TYPE_STEP_NOTES: 'step-notes',
    // Canvas Types
    CANVAS_TYPE_FUNNEL: CANVAS_TYPE_FUNNEL,
    CANVAS_TYPE_TEMPLATE: CANVAS_TYPE_TEMPLATE,
    VALID_CANVAS_TYPES: [CANVAS_TYPE_FUNNEL, CANVAS_TYPE_TEMPLATE],
    // Toolbar:
    TYPE_MULTIPLE: 'multiple-icons-selected',
    ID_ENTITY_TOOLBAR: '_entity-toolbar',
    // with all the other options
    // Image Uploader:
    ID_IMAGE_UPLOAD: '_image-upload-component',
    // Setup advanced actions modal:
    ID_SETUP_ADVANCED_ACTIONS: '_setup-advanced-actions-modal',
    // Shapes:
    SHAPE_RECTANGLE: SHAPE_RECTANGLE,
    SHAPE_CIRCLE: SHAPE_CIRCLE,
    SHAPE_TRIANGLE: SHAPE_TRIANGLE,
    CUSTOM_IMAGE: CUSTOM_IMAGE,
    TEXT_AREA: TEXT_AREA,
    ICON_IMAGES: Object.freeze((_Object$freeze = {}, _defineProperty(_Object$freeze, SHAPE_RECTANGLE, 'https://s3-us-west-2.amazonaws.com/funnelytics-thumbnails/Square-Icon.png'), _defineProperty(_Object$freeze, SHAPE_CIRCLE, 'https://s3-us-west-2.amazonaws.com/funnelytics-thumbnails/Circle-Icon.png'), _defineProperty(_Object$freeze, SHAPE_TRIANGLE, 'https://s3-us-west-2.amazonaws.com/funnelytics-thumbnails/Triangle-Icon.png'), _defineProperty(_Object$freeze, CUSTOM_IMAGE, 'https://funnelytics-production.s3-us-west-2.amazonaws.com/icons/standard/utility-icons/double-click-to-upload.svg'), _defineProperty(_Object$freeze, TEXT_AREA, 'https://s3-us-west-2.amazonaws.com/funnelytics-thumbnails/Text-Icon.png'), _Object$freeze)),
    // Z Index of icons:
    Z_INDEX_MAX_SHAPE: -1,
    Z_INDEX_MIN_LINE: 100,
    Z_INDEX_MAX_LINE: 200,
    Z_INDEX_MIN_ICON: 10000,
    FOCUS_COLOUR: '#7b47ea',
    SESSION_FOCUS_COLOUR: '#e74c3c',
    TEXT_X_OFFSET_ACTION_CENTER: 22.5,
    TEXT_X_OFFSET_LEFT: -30,
    ENTITY_LABEL_MARGIN_BOTTOM_PAGE: 28,
    ENTITY_LABEL_MARGIN_BOTTOM_ACTION: 15,
    ENTITY_LABEL_MARGIN_BOTTOM_TRAFFIC: 18,
    ENTITY_LABEL_MARGIN_BETWEEN_LABELS: 7.5,
    ENTITY_COMPARE_LABEL_MARGIN_BETWEEN_LABELS: 3,
    ENTITY_LABEL_MARGIN_PER_LINE: 12.5
  });
  var _default = CanvasConstants;
  _exports.default = _default;
});