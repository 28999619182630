define("funnelytics/components/vault/vault-selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notPermittedToPremium: Ember.computed('permitted', 'selectedTemplate.premium', function () {
      if (!this.get('permitted') && this.get('selectedTemplate.premium')) {
        return true;
      }

      return false;
    }),
    actions: {
      toggleSelectedVault: function toggleSelectedVault() {
        this.get('toggleSelectedVault')();
      },
      addToFunnel: function addToFunnel(id, canvasOrClickFunnels, vault) {
        this.get('addToFunnel')(id, canvasOrClickFunnels, vault);
      }
    }
  });

  _exports.default = _default;
});