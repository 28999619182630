define("funnelytics/templates/components/upsells/funnel-project-quota-upsells", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Epq+trlw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"fl-modal \",[27,\"if\",[[23,[\"isVisible\"]],\"fl-modal--open\"],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n    \"],[1,[27,\"upsells/project-unlock\",null,[[\"header\",\"firstCopy\",\"warning\",\"warningSeverity\",\"warningHeader\",\"warningMessage\",\"buttonAction\",\"onClickClose\",\"isLoading\",\"suggestedActionsSet\"],[\"Would you like to unlock this workspace?\",\"This workspace is currently locked, and must first be unlocked before proceeding.\",[23,[\"warning\"]],[23,[\"warningSeverity\"]],[23,[\"warningHeader\"]],[23,[\"warningMessage\"]],[23,[\"pickProjectToUnlock\"]],[27,\"action\",[[22,0,[]],\"handleClose\"],null],[23,[\"isProcessingUnlock\"]],[23,[\"suggestedActionsSet\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/upsells/funnel-project-quota-upsells.hbs"
    }
  });

  _exports.default = _default;
});