define("funnelytics/components/canvas-elements/context-menu/tracking-settings/advanced-actions", ["exports", "lodash", "funnelytics/custom/analytics/analytics/canvas/EntityParameter", "funnelytics/custom/analytics/settings/config/standard-action-names-mapping"], function (_exports, _lodash, _EntityParameter, _standardActionNamesMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filteredAdvancedActions: Ember.computed('advancedActionsFilterQuery', 'advancedActions', function () {
      return this._filterAdvancedActions(this.get('advancedActions'));
    }),
    filteredCustomAdvancedActions: Ember.computed('advancedActionsFilterQuery', 'customAdvancedActions', function () {
      return this._filterAdvancedActions(this.get('customAdvancedActions'));
    }),
    isAdvancedActionSelected: Ember.computed.notEmpty('selectedAdvancedAction'),
    selectedActionNameDisplay: Ember.computed('selectedAdvancedAction', function () {
      return _lodash.default.get(_standardActionNamesMapping.default, [this.get('selectedAdvancedAction')], this.get('selectedAdvancedAction'));
    }),
    filteredOperators: Ember.computed('operatorsFilterQuery', 'operators', function () {
      var query = this.get('operatorsFilterQuery');
      var formattedQuery = query ? query.trim().toLowerCase() : null;
      return this.get('operators').filter(function (o) {
        return !formattedQuery || o.toLowerCase().includes(formattedQuery);
      });
    }),
    actionNameDisplayConverter: function actionNameDisplayConverter(actionName) {
      return _lodash.default.get(_standardActionNamesMapping.default, [actionName], actionName);
    },
    _filterAdvancedActions: function _filterAdvancedActions(actionsToFilter) {
      var query = this.get('advancedActionsFilterQuery');
      var formattedQuery = query ? query.trim().toLowerCase() : null;
      return actionsToFilter.filter(function (action) {
        var actionName = action.name;

        var mappedActionName = _lodash.default.get(_standardActionNamesMapping.default, [actionName], actionName);

        return !formattedQuery || mappedActionName.toLowerCase().includes(formattedQuery);
      });
    },
    isMetricsDropdownEnabled: Ember.computed.bool('isStandardAction'),
    isMetricsDropdownDisabled: Ember.computed.not('isMetricsDropdownEnabled'),
    standardActionsMapping: _standardActionNamesMapping.default,
    onSelectAdvancedAction: function onSelectAdvancedAction() {},
    onSelectPropertyValue: function onSelectPropertyValue() {},
    onLoadMoreAdvancedActions: function onLoadMoreAdvancedActions() {},
    selectOperator: function selectOperator() {},
    onDeleteActionAttribute: function onDeleteActionAttribute() {},
    onToggleMetric: function onToggleMetric() {},
    onAddEmptyEntityParameter: function onAddEmptyEntityParameter() {},
    didInsertElement: function didInsertElement() {
      this.setProperties({
        isAdvancedActionsDropdownOpened: false,
        advancedActionsFilterQuery: null,
        expandedAdvancedActions: false,
        expandedCustomAdvancedActions: false,
        isOperatorsDropdownOpened: false,
        operatorsFilterQuery: null,
        newEntityParameter: new _EntityParameter.default()
      });
    },
    actions: {
      toggleAdvancedActionsDropdownOpened: function toggleAdvancedActionsDropdownOpened() {
        this.toggleProperty('isAdvancedActionsDropdownOpened');
        this.set('advancedActionsFilterQuery', null);
      },
      selectAdvancedAction: function selectAdvancedAction(actionName) {
        this.send('toggleAdvancedActionsDropdownOpened');
        this.get('onSelectAdvancedAction')(actionName);
      },
      toggleExpandedAdvancedActions: function toggleExpandedAdvancedActions() {
        this.toggleProperty('expandedAdvancedActions');
      },
      toggleExpandedCustomAdvancedActions: function toggleExpandedCustomAdvancedActions() {
        this.toggleProperty('expandedCustomAdvancedActions');
      },
      openNewPropertyEditor: function openNewPropertyEditor() {
        this.get('onAddEmptyEntityParameter')();
      },
      toggleOperatorsDropdownOpened: function toggleOperatorsDropdownOpened() {
        this.toggleProperty('isOperatorsDropdownOpened');
        this.set('operatorsFilterQuery', null);
      },
      onSelectOperator: function onSelectOperator(operator) {
        this.send('toggleOperatorsDropdownOpened');
        this.get('selectOperator')(operator);
      },
      selectNewPropertyKey: function selectNewPropertyKey(key) {
        this.get('newEntityParameter').setKey(key);
      },
      selectNewPropertyValue: function selectNewPropertyValue(value) {
        this.get('newEntityParameter').setValue(value);
      },
      selectPropertyValue: function selectPropertyValue(entityParameter) {
        var updateProperlyValue = this.get('onSelectPropertyValue');
        updateProperlyValue(entityParameter);
      },
      deleteAttribute: function deleteAttribute(attributeKey) {
        this.get('onDeleteActionAttribute')(attributeKey);
      },
      loadMoreAdvancedActions: function loadMoreAdvancedActions() {
        var loadMoreAdvancedActionsHandler = this.get('onLoadMoreAdvancedActions');
        loadMoreAdvancedActionsHandler();
      },
      toggleMetric: function toggleMetric(metricId) {
        var toggleMetricHandler = this.get('onToggleMetric');
        toggleMetricHandler(metricId);
      }
    }
  });

  _exports.default = _default;
});