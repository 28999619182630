define("funnelytics/models/project", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    name: _emberData.default.attr('string'),
    is_locked: _emberData.default.attr('boolean'),
    project_clients: _emberData.default.hasMany('project-client'),
    user: _emberData.default.belongsTo('user'),
    funnels: _emberData.default.hasMany('funnel'),
    domains: _emberData.default.hasMany('project-domain'),
    domains_verified: _emberData.default.attr('boolean'),
    logo_url: _emberData.default.attr('string'),
    analytics_script_installed: _emberData.default.attr('boolean'),
    facebook_account_id: _emberData.default.attr('string'),
    facebook_access_token: _emberData.default.attr('string'),
    version: _emberData.default.attr('number'),
    rate_limiting_enabled: _emberData.default.attr('boolean'),
    tracking: _emberData.default.attr('boolean'),
    auto_tracking: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});