define("funnelytics/templates/components/top-notification-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kJAjlJxo",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"div\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"top-notification-bar__content\"],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"We're performing maintenance on \"],[7,\"b\"],[9],[0,\"Tuesday, November 19th\"],[10],[0,\". The app will be down on this day from \"],[7,\"b\"],[9],[0,\"8am to 10am EST\"],[10],[0,\". We apologize for any inconvenience.\"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"showButton\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"href\",\"https://funnelytics.io/big-changes-are-coming/\"],[11,\"target\",\"_blank\"],[11,\"class\",\"top-notification-bar__link\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"fl-btn fl-btn--brand fl-blue-hover fl--s--font fl--ml--2\"],[9],[0,\"\\n        Learn more\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"top-notification-bar__dismiss\"],[3,\"action\",[[22,0,[]],\"dismissTopNotificationBar\"]],[9],[0,\"\\n    ×\\n  \"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/top-notification-bar.hbs"
    }
  });

  _exports.default = _default;
});