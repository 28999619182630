define("funnelytics/controllers/dashboard/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    timestamp: new Date().getTime(),
    isFirefox: navigator.userAgent.toLowerCase().indexOf('firefox') > -1
  });

  _exports.default = _default;
});