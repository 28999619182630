define("funnelytics/custom/analytics/analytics-data/ExplorerAnalyticsData", ["exports", "@funnelytics/utilities", "lodash", "funnelytics/custom/analytics/AnalyticsData"], function (_exports, _utilities, _lodash, _AnalyticsData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ExplorerAnalyticsData = _AnalyticsData.default.extend({
    _type: 'explorer',
    _exploredEntityId: null,
    exploredEntityId: Ember.computed.readOnly('_exploredEntityId'),
    nextPages: Ember.computed.readOnly('_nextPages'),
    hasMoreNextPages: Ember.computed.readOnly('_hasMoreNextPages'),
    nextAllPages: Ember.computed.readOnly('_nextAllPages'),
    hasMoreNextAllPages: Ember.computed.readOnly('_hasMoreNextAllPages'),
    previousPages: Ember.computed.readOnly('_previousPages'),
    hasMorePreviousPages: Ember.computed.readOnly('_hasMorePreviousPages'),
    previousAllPages: Ember.computed.readOnly('_previousAllPages'),
    hasMorePreviousAllPages: Ember.computed.readOnly('_hasMorePreviousAllPages'),
    nextActions: Ember.computed.readOnly('_nextActions'),
    hasMoreNextActions: Ember.computed.readOnly('_hasMoreNextActions'),
    previousAllActions: Ember.computed.readOnly('_previousAllActions'),
    hasMorePreviousAllActions: Ember.computed.readOnly('_hasMorePreviousAllActions'),
    nextAllActions: Ember.computed.readOnly('_nextAllActions'),
    hasMoreNextAllActions: Ember.computed.readOnly('_hasMoreNextAllActions'),
    actionAttributesCount: Ember.computed.readOnly('_actionAttributesCount'),
    actionAttributes: Ember.computed.readOnly('_actionAttributes'),
    pageParameterCount: Ember.computed.readOnly('_pageParameterCount'),
    commonParameters: Ember.computed.readOnly('_commonParameters'),
    customParameters: Ember.computed.readOnly('_customParameters'),
    allCommonParameters: Ember.computed.readOnly('_allCommonParameters'),
    allCustomParameters: Ember.computed.readOnly('_allCustomParameters'),
    paginationPage: Ember.computed.readOnly('_paginationPage'),
    hasError: Ember.computed.readOnly('_hasError'),
    _nextPages: [],
    _hasMoreNextPages: false,
    _nextAllPages: [],
    _hasMoreNextAllPages: false,
    _previousPages: [],
    _hasMorePreviousPages: false,
    _previousAllPages: [],
    _hasMorePreviousAllPages: false,
    _nextActions: [],
    _hasMoreNextActions: false,
    _nextAllActions: [],
    _hasMoreNextAllActions: false,
    _previousAllActions: [],
    _hasMorePreviousAllActions: false,
    _actionAttributesCount: 0,
    _actionAttributes: [],
    _pageParameterCount: 0,
    _commonParameters: [],
    _customParameters: [],
    _allCommonParameters: [],
    _allCustomParameters: [],
    _paginationPage: 0,
    _hasError: false,
    _populate: function _populate(_ref) {
      var data = _ref.data,
          meta = _ref.meta;
      this.setProperties({
        _exploredEntityId: _lodash.default.get(meta, 'exploredEntityId'),
        _nextPages: _lodash.default.get(data, ['next_pages', 'list'], []),
        _hasMoreNextPages: _lodash.default.get(data, ['next_pages', 'has_more'], false),
        _nextAllPages: _lodash.default.get(data, ['next_pages_all', 'list'], []),
        _hasMoreNextAllPages: _lodash.default.get(data, ['next_pages_all', 'has_more'], false),
        _previousPages: _lodash.default.get(data, ['previous_pages', 'list'], []),
        _hasMorePreviousPages: _lodash.default.get(data, ['previous_pages', 'has_more'], false),
        _previousAllPages: _lodash.default.get(data, ['previous_pages_all', 'list'], []),
        _hasMorePreviousAllPages: _lodash.default.get(data, ['previous_pages_all', 'has_more'], false),
        _nextActions: _lodash.default.get(data, ['next_actions', 'list'], []),
        _hasMoreNextActions: _lodash.default.get(data, ['next_actions', 'has_more'], false),
        _nextAllActions: _lodash.default.get(data, ['next_actions_all', 'list'], []),
        _hasMoreNextAllActions: _lodash.default.get(data, ['next_actions_all', 'has_more'], false),
        _previousAllActions: _lodash.default.get(data, ['previous_actions_all', 'list'], []),
        _hasMorePreviousAllActions: _lodash.default.get(data, ['previous_actions_all', 'has_more'], false),
        _actionAttributesCount: _lodash.default.get(data, ['action_attributes', 'hits'], []),
        _actionAttributes: _lodash.default.get(data, ['action_attributes', 'key_values'], []),
        _pageParameterCount: _lodash.default.get(data, ['page_parameters', 'hits'], []),
        _commonParameters: _lodash.default.get(data, ['page_parameters', 'common_parameters'], []),
        _customParameters: _lodash.default.get(data, ['page_parameters', 'custom_parameters'], []),
        _allCommonParameters: _lodash.default.get(data, ['page_parameters_all', 'common_parameters'], []),
        _allCustomParameters: _lodash.default.get(data, ['page_parameters_all', 'custom_parameters'], []),
        _paginationPage: parseInt(_lodash.default.get(data, ['page'], 1), 10)
      });
    },
    _replaceWith: function _replaceWith(explorerAnalyticsData) {
      _utilities.Assertion.instanceOf(explorerAnalyticsData, ExplorerAnalyticsData);

      var paginationPage = explorerAnalyticsData.get('paginationPage');

      if (paginationPage > 1) {
        throw new Error("Incorrectly using _replaceWith method with paginationPage ".concat(paginationPage, ". \nShould have called _merge instead."));
      }

      this.setProperties({
        _exploredEntityId: explorerAnalyticsData.get('exploredEntityId'),
        _nextPages: explorerAnalyticsData.get('nextPages'),
        _hasMoreNextPages: explorerAnalyticsData.get('hasMoreNextPages'),
        _nextAllPages: explorerAnalyticsData.get('nextAllPages'),
        _hasMoreNextAllPages: explorerAnalyticsData.get('hasMoreNextAllPages'),
        _previousPages: explorerAnalyticsData.get('previousPages'),
        _hasMorePreviousPages: explorerAnalyticsData.get('hasMorePreviousPages'),
        _previousAllPages: explorerAnalyticsData.get('previousAllPages'),
        _hasMorePreviousAllPages: explorerAnalyticsData.get('hasMorePreviousAllPages'),
        _nextActions: explorerAnalyticsData.get('nextActions'),
        _hasMoreNextActions: explorerAnalyticsData.get('hasMoreNextActions'),
        _nextAllActions: explorerAnalyticsData.get('nextAllActions'),
        _hasMoreNextAllActions: explorerAnalyticsData.get('hasMoreNextAllActions'),
        _previousAllActions: explorerAnalyticsData.get('previousAllActions'),
        _hasMorePreviousAllActions: explorerAnalyticsData.get('hasMorePreviousAllActions'),
        _actionAttributesCount: explorerAnalyticsData.get('actionAttributesCount'),
        _actionAttributes: explorerAnalyticsData.get('actionAttributes'),
        _pageParameterCount: explorerAnalyticsData.get('pageParameterCount'),
        _commonParameters: explorerAnalyticsData.get('commonParameters'),
        _customParameters: explorerAnalyticsData.get('customParameters'),
        _allCommonParameters: explorerAnalyticsData.get('allCommonParameters'),
        _allCustomParameters: explorerAnalyticsData.get('allCustomParameters'),
        _paginationPage: explorerAnalyticsData.get('paginationPage')
      });
    },
    _merge: function _merge(explorerAnalyticsData) {
      _utilities.Assertion.instanceOf(explorerAnalyticsData, ExplorerAnalyticsData);

      var paginationPage = explorerAnalyticsData.get('paginationPage');

      if (paginationPage === 1) {
        throw new Error("Incorrectly using _merge method with paginationPage ".concat(paginationPage, ". \nShould have called _replaceWith instead."));
      } // Update arrays with new data


      this.get('_nextPages').addObjects(explorerAnalyticsData.get('nextPages'));
      this.get('_nextAllPages').addObjects(explorerAnalyticsData.get('nextAllPages'));
      this.get('_previousPages').addObjects(explorerAnalyticsData.get('previousPages'));
      this.get('_previousAllPages').addObjects(explorerAnalyticsData.get('previousAllPages'));
      this.get('_nextActions').addObjects(explorerAnalyticsData.get('nextActions'));
      this.get('_nextAllActions').addObjects(explorerAnalyticsData.get('nextAllActions'));
      this.get('_previousAllActions').addObjects(explorerAnalyticsData.get('previousAllActions'));
      this.get('_actionAttributes').addObjects(explorerAnalyticsData.get('actionAttributes'));
      this.get('_commonParameters').addObjects(explorerAnalyticsData.get('commonParameters'));
      this.get('_customParameters').addObjects(explorerAnalyticsData.get('customParameters'));
      this.get('_allCommonParameters').addObjects(explorerAnalyticsData.get('allCommonParameters'));
      this.get('_allCustomParameters').addObjects(explorerAnalyticsData.get('allCustomParameters')); // Update standalone values

      this.setProperties({
        _exploredEntityId: explorerAnalyticsData.get('exploredEntityId'),
        _actionAttributesCount: explorerAnalyticsData.get('actionAttributesCount'),
        _pageParameterCount: explorerAnalyticsData.get('pageParameterCount'),
        _paginationPage: explorerAnalyticsData.get('paginationPage'),
        _hasMoreNextPages: explorerAnalyticsData.get('hasMoreNextPages'),
        _hasMoreNextAllPages: explorerAnalyticsData.get('hasMoreNextAllPages'),
        _hasMorePreviousPages: explorerAnalyticsData.get('hasMorePreviousPages'),
        _hasMorePreviousAllPages: explorerAnalyticsData.get('hasMorePreviousPages'),
        _hasMoreNextActions: explorerAnalyticsData.get('hasMoreNextActions'),
        _hasMoreNextAllActions: explorerAnalyticsData.get('hasMoreNextAllActions'),
        _hasMorePreviousAllActions: explorerAnalyticsData.get('hasMorePreviousAllActions')
      });
    }
  });

  var _default = ExplorerAnalyticsData;
  _exports.default = _default;
});