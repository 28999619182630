define("funnelytics/components/containers/funnel-editor/components/right-sidebar-simple-action-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onSelectSimple: function onSelectSimple() {},
    onSelectAdvanced: function onSelectAdvanced() {},
    actions: {
      setSimple: function setSimple() {
        var handler = this.get('onSelectSimple');
        handler();
      },
      setAdvanced: function setAdvanced() {
        var handler = this.get('onSelectAdvanced');
        handler();
      }
    }
  });

  _exports.default = _default;
});