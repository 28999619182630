define("funnelytics/custom/misc/PlanConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PlanConstants = {
    PRO_2_MONTHLY: {
      type: 'subscription',
      userFacingName: 'Pro Monthly',
      planCode: 'pro_monthly_2_projects',
      cost: null,
      periodOfTime: 'month',
      periodSeparator: '/',
      upsellRoute: 'order.confirmation'
    },
    PRO_2_ANNUALLY: {
      type: 'subscription',
      userFacingName: 'Pro Annually',
      planCode: 'pro_annually_2_projects',
      cost: null,
      periodOfTime: 'year',
      periodSeparator: '/',
      upsellRoute: 'order.confirmation'
    }
  };
  var _default = PlanConstants;
  _exports.default = _default;
});