define("funnelytics/components/dashboard/retirement-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    btnLink: 'https://funnelytics.io/company-announcements/1-0-eol-announcement/'
  });

  _exports.default = _default;
});