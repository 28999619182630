define("funnelytics/templates/components/funnel-pages/confirmation/invoice-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rMNq17XN",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isDisplayed\"]]],null,{\"statements\":[[0,\"  \"],[7,\"hr\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"fl-flex-row\"],[9],[0,\"\\n    \"],[7,\"div\"],[9],[1,[21,\"description\"],false],[10],[0,\"\\n    \"],[7,\"div\"],[9],[1,[27,\"format-currency\",[[23,[\"totalInCents\"]],\"cents\"],null],false],[0,\" USD\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/funnel-pages/confirmation/invoice-item.hbs"
    }
  });

  _exports.default = _default;
});