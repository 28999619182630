define("funnelytics/routes/index", ["exports", "funnelytics/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    queryParams: {
      performance: {
        refreshModel: true
      },
      dcc: {
        refreshModel: true
      },
      discount: {
        refreshModel: true
      }
    },
    session: Ember.inject.service(),
    model: function model(params) {
      this.set('params', params);
      return;
    },
    redirect: function redirect() {
      if (this.get('params.performance') && this.get('session.isAuthenticated')) {
        this.transitionTo(_environment.default['ember-simple-auth'].routeIfAlreadyAuthenticated, this.get('params'));
      } else if (this.get('session.isAuthenticated')) {
        this.transitionTo(_environment.default['ember-simple-auth'].routeIfAlreadyAuthenticated);
      } else {
        this.transitionTo(_environment.default['ember-simple-auth'].authenticationRoute);
      }
    }
  });

  _exports.default = _default;
});