define("funnelytics/components/url-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analyticsService: Ember.inject.service(),
    listener: null,
    input: null,
    sourceUrlStatus: null,
    copy: null,
    triggerURLUPDate: Ember.observer('urlPreview', function () {
      this.validateUrl();
    }),
    validateUrl: function validateUrl() {
      var url = this.get('urlPreview');

      if (!url) {
        this.set('sourceURLValidation', 'warning-none');
        return;
      }

      var sourceURLValidation = this.get('urlPreview').split('?');
      var urlRegEx = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm; // eslint-disable-line no-useless-escape

      if (sourceURLValidation[0].length === 0) {
        this.set('sourceURLValidation', 'warning-none');
      }

      if (sourceURLValidation[0].length > 0 && !urlRegEx.exec(sourceURLValidation[0])) {
        this.set('sourceURLValidation', 'warning-url-error');
      }

      if (urlRegEx.exec(sourceURLValidation[0])) {
        this.set('sourceURLValidation', 'success');
      }
    },
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        this.validateUrl();
      });
    },
    onEditPreview: function onEditPreview() {},
    actions: {
      inputChange: function inputChange(evt) {
        if (evt.isTrusted) {
          var inputHandler = this.get('onEditPreview');
          inputHandler(evt.target.value);
        }
      },
      copyURL: function copyURL() {
        var urlTextArea = Ember.$('#url');
        urlTextArea.select();
        document.execCommand('copy');
      }
    }
  });

  _exports.default = _default;
});