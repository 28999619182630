define("funnelytics/custom/upgrade/PrivateUpgradeConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SUGGESTED_ACTIONS = 'suggestedActions';
  var CTA = 'callToAction';
  var PATH = 'path';
  var NAME = 'name';
  var MESSAGE_OBJECT = 'messageObject';
  var MESSAGE_TEXT = 'messageText';
  var MESSAGE_TYPE = 'messageType';
  var REQUIRES_CONFIRMATION = 'requiredConfirmation';
  var CONFIRMATION_TEXT = 'confirmationText';
  var FINE_PRINT = 'finePrint';
  var PrivateUpgradeConstants = Object.freeze({
    SUGGESTED_ACTIONS: SUGGESTED_ACTIONS,
    CTA: CTA,
    PATH: PATH,
    NAME: NAME,
    MESSAGE_OBJECT: MESSAGE_OBJECT,
    MESSAGE_TEXT: MESSAGE_TEXT,
    MESSAGE_TYPE: MESSAGE_TYPE,
    REQUIRES_CONFIRMATION: REQUIRES_CONFIRMATION,
    CONFIRMATION_TEXT: CONFIRMATION_TEXT,
    FINE_PRINT: FINE_PRINT
  });
  var _default = PrivateUpgradeConstants;
  _exports.default = _default;
});