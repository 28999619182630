define("funnelytics/routes/dashboard/templates/settings", ["exports", "ember-routable-modal/mixins/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_route.default, {
    model: function model(params) {
      return this.get('store').find('template', params.id);
    }
  });

  _exports.default = _default;
});