define("funnelytics/templates/components/goal-creator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7fEUwlvY",
    "block": "{\"symbols\":[\"goal\",\"key\"],\"statements\":[[4,\"each\",[[23,[\"goals\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"goal-field\",null,[[\"goal\",\"key\"],[[22,1,[]],[22,2,[]]]]],false],[0,\"\\n    \"],[7,\"hr\"],[11,\"class\",\"mt-1 mb-1\"],[9],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[7,\"div\"],[11,\"class\",\"row mt-3\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-12\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"sideBarButton\"],[3,\"action\",[[22,0,[]],\"addGoal\"]],[9],[0,\"Add KPI\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/goal-creator.hbs"
    }
  });

  _exports.default = _default;
});