define("funnelytics/components/canvas-elements/forecasting-set-input", ["exports", "@funnelytics/utilities", "ember-uuid"], function (_exports, _utilities, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['forecasting-overview__input'],
    isLeftPaddingSmaller: false,
    didInsertElement: function didInsertElement() {
      _utilities.Assertion.string(this.get('identifier'));

      _utilities.Assertion.string(this.get('label'));

      this.set('uniqueId', (0, _emberUuid.v4)());
    },
    onInput: function onInput() {},
    actions: {
      handleInput: function handleInput(newValue) {
        var onInputHandler = this.get('onInput');
        onInputHandler(newValue);
      }
    }
  });

  _exports.default = _default;
});