define("funnelytics/components/subscriptions/modals/downgrade-alert", ["exports", "funnelytics/custom/constants/MembershipConstants"], function (_exports, _MembershipConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userDetails: Ember.inject.service(),
    downgradeModalManager: Ember.inject.service(),
    isOpen: false,
    isAnnual: false,
    currentMembership: null,
    onClose: function onClose() {},
    onSucess: function onSucess() {},
    downgradeToMembership: Ember.computed('downgradeModalManager.selectedMembership', function () {
      return this.downgradeModalManager.selectedMembership;
    }),
    isPerformanceDowngrade: Ember.computed('downgradeToMembership', function () {
      return this.downgradeToMembership === _MembershipConstants.Membership.isPerformancePlus;
    }),
    losingBenefits: Ember.computed('downgradeToMembership', function () {
      if (this.downgradeToMembership === _MembershipConstants.Membership.isPerformancePlus) {
        return {};
      }

      if (this.downgradeToMembership === _MembershipConstants.Membership.isPerformancePro) {
        return {
          premium: "You'll lose access to Integrations, Action Tracking, Multiple Workspaces.",
          tracking: "Your tracking threshold will be lowered to only 25k unique users per month resulting in overages after that quota.",
          support: "Premium support will no longer be available, which includes Script installation Call, Tracking Setup onboarding, 1-on-1 Roadmap Chat."
        };
      }

      if (this.downgradeToMembership === _MembershipConstants.Membership.isPerformanceStarter) {
        return {
          premium: "You'll lose access to Page Tracking, Source Tracking, Data Widgets.",
          tracking: "Your tracking threshold will be blocked.",
          support: "Premium support will no longer be available, which includes Script installation Call, Tracking Setup onboarding, 1-on-1 Roadmap Chat."
        };
      }
    }),
    userId: Ember.computed('userDetails.userId', function () {
      return this.userDetails.get('userId');
    }),
    actions: {
      close: function close() {
        this.onClose();
      },
      success: function success() {
        this.onSuccess();
      }
    }
  });

  _exports.default = _default;
});