define("funnelytics/components/canvas-elements/entity-toolbar/item", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['entity-toolbar__item-wrapper'],
    onClick: function onClick() {},
    isSelected: false,
    didInsertElement: function didInsertElement() {
      this.set('uniqueIdentifier', (0, _emberUuid.v4)());
    },
    actions: {
      handleClick: function handleClick() {
        var handler = this.get('onClick');
        handler();
      }
    }
  });

  _exports.default = _default;
});