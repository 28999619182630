define("funnelytics/components/canvas-elements/analytics-toolbar/filter-dropdown-button", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedOptionDisplayValue: Ember.computed('optionsObject', 'selectedOption', 'defaultValue', function () {
      var selectedOption = this.get('selectedOption');
      var defaultValue = this.get('defaultValue');

      var displayValue = _lodash.default.get(this.get('optionsObject'), [selectedOption], selectedOption || defaultValue);

      return displayValue;
    }),
    onClick: function onClick() {},
    actions: {
      handleClick: function handleClick() {
        var clickHandler = this.get('onClick');
        clickHandler();
      }
    }
  });

  _exports.default = _default;
});