define("funnelytics/components/containers/funnel-editor/components/right-sidebar-single-parameter", ["exports", "lodash", "funnelytics/custom/encoding/converter", "funnelytics/custom/constants/AnalyticsConstants"], function (_exports, _lodash, _converter, _AnalyticsConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_LENGTH_KEY = 512;
  var MAX_LENGTH_VALUE = 2048;
  var PARAM_EMPTY_MSG = 'Please fill in a key and value for this parameter.';
  var PARAM_LENGTH_MSG = "Parameter keys can't exceed 512 characters.";
  var PARAM_LINK = 'http://help.funnelytics.io/analytics-features/tracking/query-string-parameters';

  var _default = Ember.Component.extend({
    analyticsService: Ember.inject.service(),
    containsValue: _AnalyticsConstants.default.PARAM_CONTAINS,
    notContainsValue: _AnalyticsConstants.default.PARAM_NOT_CONTAINS,
    CONTAINS: Ember.computed.readOnly('containsValue'),
    NOT_CONTAINS: Ember.computed.readOnly('notContainsValue'),
    parameterChanged: Ember.observer('parameter.{key,value,type}', function () {
      this.get('model').set('parameters', this.get('parameters'));
      this.paramValidation();
      this.get('analyticsService').set('analyticsAreStale', true);
    }),
    paramStatusKey: Ember.Object.create({}),
    paramStatusValue: Ember.Object.create({}),
    parameterType: Ember.computed('parameter.type', function () {
      return this.get('parameter.type') === _AnalyticsConstants.default.PARAM_NOT_CONTAINS ? _AnalyticsConstants.default.PARAM_NOT_CONTAINS : _AnalyticsConstants.default.PARAM_CONTAINS;
    }),
    bothKeyAndValueAreEmpty: function bothKeyAndValueAreEmpty() {
      var _this$get = this.get('parameter'),
          key = _this$get.key,
          value = _this$get.value;

      if ((!key || key.length === 0) && (!value || value.length === 0)) {
        return true;
      }

      return false;
    },
    duplicateKeyDetected: function duplicateKeyDetected(key) {
      var parameters = this.get('model').get('parameters');
      return _lodash.default.filter(parameters, function (parameter) {
        return parameter.key === key;
      }).length <= 1;
    },
    paramValidation: function paramValidation() {
      var _this$get2 = this.get('parameter'),
          key = _this$get2.key,
          value = _this$get2.value;

      if (this.bothKeyAndValueAreEmpty()) {
        this.setProperties({
          parameterKeyIcon: 'warning-icon',
          parameterKeyContent: PARAM_EMPTY_MSG,
          paramLink: PARAM_LINK
        });
      } else if (!key) {
        this.setProperties({
          parameterKeyIcon: 'warning-icon',
          parameterKeyContent: PARAM_EMPTY_MSG,
          paramLink: PARAM_LINK
        });
      } else if (!this.duplicateKeyDetected(key)) {
        this.setProperties({
          parameterKeyIcon: 'error-icon',
          parameterKeyContent: "You can't have duplicate keys",
          parameterValueIcon: 'error-icon',
          parameterValueContent: "You can't have duplicate keys",
          paramLink: PARAM_LINK
        });
      } else if (_lodash.default.get(key, 'length') >= MAX_LENGTH_KEY) {
        this.setProperties({
          parameterKeyIcon: 'error-icon',
          parameterKeyContent: PARAM_LENGTH_MSG,
          paramLink: PARAM_LINK
        });
      } else {
        this.setProperties({
          parameterKeyIcon: 'success-icon',
          parameterKeyContent: ''
        });
      }

      if (this.bothKeyAndValueAreEmpty()) {
        this.setProperties({
          parameterValueIcon: 'warning-icon',
          parameterValueContent: PARAM_EMPTY_MSG,
          paramLink: PARAM_LINK
        });
      } else if (!this.duplicateKeyDetected(key)) {
        this.setProperties({
          parameterValueIcon: 'error-icon',
          parameterValueContent: "You can't have duplicate keys",
          paramLink: PARAM_LINK
        });
      } else if (_lodash.default.get(value, 'length') >= MAX_LENGTH_VALUE) {
        this.setProperties({
          parameterValueIcon: 'error-icon',
          parameterValueContent: PARAM_LENGTH_MSG,
          paramLink: PARAM_LINK
        });
      } else {
        this.setProperties({
          parameterValueIcon: 'success-icon',
          parameterValueContent: ''
        });
      }
    },
    didInsertElement: function didInsertElement() {
      this.paramValidation();
    },
    willDestroyElement: function willDestroyElement() {
      this.set('paramStatusKey', null);
      this.set('paramStatusValue', null);
    },
    onChange: function onChange() {},
    actions: {
      selectType: function selectType(value) {
        this.set('parameter.type', value);
        this.send('handleChange');
      },
      delete: function _delete() {
        this.get('parameters').removeObject(this.get('parameter'));
        this.get('analyticsService').set('analyticsAreStale', true);
        this.send('handleChange');
      },
      encodedCharacters: function encodedCharacters() {
        var _this$get3 = this.get('parameter'),
            key = _this$get3.key,
            value = _this$get3.value;

        this.set('parameter.key', _converter.default.decode(key));
        this.set('parameter.value', _converter.default.decode(value));
      },
      handleChange: function handleChange() {
        var changeHandler = this.get('onChange');
        changeHandler();
      }
    }
  });

  _exports.default = _default;
});