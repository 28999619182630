define("funnelytics/components/subscriptions/upcoming-changes", ["exports", "funnelytics/custom/constants/MembershipConstants", "funnelytics/mixins/standardized-date", "lodash", "funnelytics/helpers/get-plan-name"], function (_exports, _MembershipConstants, _standardizedDate, _lodash, _getPlanName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // eslint-disable-next-line no-underscore-dangle
  var _isEmpty = _lodash.default.isEmpty; // eslint-disable-next-line no-underscore-dangle

  var _invert = _lodash.default.invert;

  var _default = Ember.Component.extend(_standardizedDate.default, {
    ajax: Ember.inject.service(),
    hasChanges: null,
    date: null,
    planName: null,
    changeType: null,
    statement: null,
    changeParams: function changeParams() {},
    handledChangeParams: function handledChangeParams(key, value) {
      var paramsMapping = ['paymentPeriodFromStripe'];
      if (!paramsMapping.includes(key)) return;
      var handler = this.changeParams;
      handler(key, value);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._processResponse();
    },
    _processResponse: function _processResponse() {
      if (_isEmpty(this.upcomingChanges)) return;

      if (this.upcomingChanges.success) {
        var phases = this.upcomingChanges.data.phases;
        var isValid = phases && phases.length === 2;

        if (_isEmpty(phases) || !isValid) {
          this.set('hasChanges', false);
        } else {
          var invertMembershipCodes = _invert(_MembershipConstants.MEMBERSHIP_CODES);

          var firstPhase = phases[0];
          var secondPhase = phases[1];
          var firstMembership = firstPhase.membership;
          var secondMembership = secondPhase.membership;
          var firstPaymentPeriod = firstPhase.payment_period;
          var secondPaymentPeriod = secondPhase.payment_period ? " (".concat(secondPhase.payment_period, ")") : '';
          var changeType;

          if (firstPhase.is_cancel || secondPhase.is_cancel) {
            changeType = 'Cancel and downgrade';
          } else {
            var firstMembershipIndex = _MembershipConstants.MEMBERSHIP_HIERARCHY.indexOf(firstMembership);

            var secondMembershipIndex = _MembershipConstants.MEMBERSHIP_HIERARCHY.indexOf(secondMembership);

            changeType = firstMembershipIndex < secondMembershipIndex ? 'Downgrade' : 'Upgrade';
          }

          var date = new Date(secondPhase.start_date * 1000);
          var planName = secondPhase.price_id;
          var membershipName = (0, _getPlanName.getPlanName)([invertMembershipCodes[secondMembership] || 'unknown']);
          var statement = "".concat(changeType, " to ").concat(membershipName).concat(secondPaymentPeriod, " on ").concat(this.getFormattedDate(date));
          this.set('hasChanges', true);
          this.set('date', date);
          this.set('planName', planName);
          this.set('changeType', changeType);
          this.handledChangeParams('paymentPeriodFromStripe', firstPaymentPeriod);
          this.set('statement', statement);
        }
      } else {
        var error = this.upcomingChanges.data;

        if (error.status === 500) {
          this.set('statement', error.message);
          this.set('hasChanges', true);
        } else {
          this.set('hasChanges', false);
        }
      }
    }
  });

  _exports.default = _default;
});