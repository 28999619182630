define("funnelytics/templates/components/dashboard/mapping-lock-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6wg1eQpI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"announcement-banner \",[27,\"if\",[[23,[\"showbanner\"]],\" show\"],null]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"w-100\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"centering-container\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"style\",\"font-size: 16px;\"],[9],[0,\"Your Free Funnelytics Map account will be locked on \"],[1,[21,\"lockDate\"],false],[0,\". \"],[7,\"b\"],[9],[0,\"Upgrade now to keep your account active!\"],[10],[10],[0,\"\\n            \"],[7,\"div\"],[9],[0,\"\\n                \"],[7,\"a\"],[11,\"class\",\"fl-btn fl-btn--brand fl-btn-brand-banner banner-lock-btn\"],[12,\"href\",[21,\"btnLink\"]],[11,\"target\",\"_blank\"],[9],[0,\"Upgrade your account\"],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/dashboard/mapping-lock-banner.hbs"
    }
  });

  _exports.default = _default;
});