define("funnelytics/services/colors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    //V2 Side navbar icons
    generateNextColor: function generateNextColor(count) {
      var counter = count % this.iconRGBColors.length;
      return this.iconRGBColors[counter];
    },
    iconRGBColors: ['#009CFA', '#00CA64', '#FFB00C', '#F66035', '#F93646', '#934CFF', '#8FADFA', '#BC0032', '#009696', '#5BAE5E']
  });

  _exports.default = _default;
});