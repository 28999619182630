define("funnelytics/templates/components/planning/funnel-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5mHbG71x",
    "block": "{\"symbols\":[\"step\"],\"statements\":[[4,\"collapsible-panel\",null,[[\"title\",\"startHidden\"],[\"FUNNEL NOTES\",false]],{\"statements\":[[0,\"  \"],[1,[27,\"planning/notes-editor\",null,[[\"funnel\",\"isFunnel\"],[[23,[\"funnel\"]],[23,[\"isFunnel\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"gt\",[[23,[\"stepsWithNotes\",\"length\"]],0],null]],null,{\"statements\":[[4,\"collapsible-panel\",null,[[\"title\",\"startHidden\"],[\"STEP NOTES\",false]],{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[\"notes-list__section \",[27,\"if\",[[23,[\"isNotesEditorExpanded\"]],\"expanded\"],null]]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"stepsWithNotes\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"canvas-elements/entity-notes-preview\",null,[[\"step\",\"onOpenStepNotes\",\"isNotesEditorExpanded\"],[[22,1,[]],[23,[\"onOpenStepNotes\"]],[23,[\"isNotesEditorExpanded\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/planning/funnel-notes.hbs"
    }
  });

  _exports.default = _default;
});