define("funnelytics/components/canvas-elements/analytics-toolbar", ["exports", "moment", "lodash", "funnelytics/custom/tracking/ProductTracker", "funnelytics/custom/constants/TrackingConstants", "funnelytics/custom/constants/AnalyticsConstants"], function (_exports, _moment, _lodash, _ProductTracker, _TrackingConstants, _AnalyticsConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DATE_RANGE_SELECTOR_EVENT_CLASS = 'analytics-toolbar__date-range-selector';

  var _require = require("@funnelytics/utilities"),
      Permissions = _require.constants.Permissions;

  var _default = Ember.Component.extend({
    analyticsService: Ember.inject.service(),
    canvasService: Ember.inject.service(),
    isFiltersOpen: false,
    isComparing: Ember.computed.readOnly('analyticsService.isComparing'),
    dateRangeSelectorClassNames: '',
    areAnalyticsSessionsLoading: Ember.computed.readOnly('analyticsService.areSessionsLoading'),
    typeTotalNumbers: Permissions.TYPE_TOTAL_NUMBERS,
    analyticsSessionsCount: Ember.computed.readOnly('analyticsService.sessionsTotal'),
    analyticsSessionsList: Ember.computed.readOnly('analyticsService.sessionsList'),
    analyticsCompareSessionsCount: Ember.computed.readOnly('analyticsService.sessionsCompareTotal'),
    analyticsCompareSessionsList: Ember.computed.readOnly('analyticsService.sessionsCompareList'),
    analyticsSessionFocusId: Ember.computed.readOnly('analyticsService.sessionFocusId'),
    compareDatePickerInputClass: Ember.computed('isDatesComparing', function () {
      return this.get('isDatesComparing') ? 'compare-dropdown__daterangepicker-input' : 'compare-dropdown__daterangepicker-input-hidden';
    }),
    isDatesComparing: Ember.computed('isComparing', 'analyticsService.compareMode', function () {
      return this.get('isComparing') && this.get('analyticsService.compareMode') === _AnalyticsConstants.default.ANALYTICS_COMPARE_MODE_DATES;
    }),
    isPeopleComparing: Ember.computed('isComparing', 'analyticsService.compareMode', function () {
      return this.get('isComparing') && this.get('analyticsService.compareMode') === _AnalyticsConstants.default.ANALYTICS_COMPARE_MODE_PEOPLE;
    }),
    isSelectedCompareDateOption: Ember.computed.equal('selectedCompareOption', 'date'),
    isSelectedPeopleDateOption: Ember.computed.equal('selectedCompareOption', 'people'),
    onStartComparing: function onStartComparing() {},
    onStopComparing: function onStopComparing() {},
    numberOfCompareFilters: Ember.computed('comparePeopleFilters.device', 'numberOfCompareFocusFilters', 'comparePeopleFilters.countries.[]', function () {
      var filters = this.get('numberOfCompareFocusFilters');

      if (this.get('comparePeopleFilters.device')) {
        filters++;
      }

      var selectedCountryNames = this.get('comparePeopleFilters.countries');

      if (selectedCountryNames && selectedCountryNames.length > 0) {
        filters++;
      }

      return filters;
    }),
    numberOfFilters: Ember.computed('analyticsService.device', 'numberOfFocusFilters', 'selectedCountryNames.[]', function () {
      var filters = this.get('numberOfFocusFilters');

      if (this.get('analyticsService.device')) {
        filters++;
      }

      var selectedCountryNames = this.get('selectedCountryNames');

      if (selectedCountryNames && selectedCountryNames.length > 0) {
        filters++;
      }

      return filters;
    }),
    focusFilterStepId: Ember.computed('analyticsService.focusEntity', function () {
      var focusEntity = this.get('analyticsService.focusEntity');

      if (!focusEntity) {
        return null;
      }

      return focusEntity.getID();
    }),
    compareFocusEntity: Ember.computed('comparePeopleFilters.page.model', function () {
      var focusedCell = this.get('comparePeopleFilters.page.model');
      return !focusedCell ? null : this.get('canvasService').wrapEntity(focusedCell);
    }),
    compareFocusFilterStepId: Ember.computed('compareFocusEntity', function () {
      var focusEntity = this.get('compareFocusEntity');
      return !focusEntity ? null : focusEntity.getID();
    }),
    profileAttributeCountriesData: Ember.computed.readOnly('analyticsService.profileAttributesData.profileCountries'),
    countriesData: Ember.computed('profileAttributeCountriesData', 'analyticsSessionsCount', function () {
      var profileAttributesCountry = this.get('profileAttributeCountriesData') || [];
      return profileAttributesCountry.map(function (country) {
        var hits = _lodash.default.get(country, [_AnalyticsConstants.default.PROFILE_ATTRIBUTES_OUTPUT.COUNTRIES.HITS]);

        var countryName = _lodash.default.get(country, [_AnalyticsConstants.default.PROFILE_ATTRIBUTES_OUTPUT.COUNTRIES.NAME]);

        return {
          countryName: countryName,
          visitorsCount: hits
        };
      });
    }),
    isCountriesLoading: Ember.computed.readOnly('analyticsService.isProfileAttributesDataLoading'),
    countriesFilter: Ember.computed.readOnly('analyticsService.countriesFilter'),
    setSelectedCountries: function setSelectedCountries() {
      this.set('selectedCountryNames', this.get('countriesFilter'));
    },
    COUNTRIES_FILTER_OBSERVER: Ember.observer('countriesFilter.[]', function () {
      this.setSelectedCountries();
    }),
    dateRanges: {
      Today: [(0, _moment.default)(), (0, _moment.default)()],
      Yesterday: [(0, _moment.default)().subtract(1, 'days'), (0, _moment.default)().subtract(1, 'days')],
      'Last 7 Days': [(0, _moment.default)().subtract(6, 'days'), (0, _moment.default)()],
      'Last 30 Days': [(0, _moment.default)().subtract(29, 'days'), (0, _moment.default)()],
      'This Month': [(0, _moment.default)().startOf('month'), (0, _moment.default)().endOf('month')],
      'Last Month': [(0, _moment.default)().subtract(1, 'month').startOf('month'), (0, _moment.default)().subtract(1, 'month').endOf('month')]
    },
    numberOfFocusFilters: Ember.computed('analyticsService.sessionFocus', 'analyticsService.page', function () {
      var sessionFilter = this.get('analyticsService.sessionFocus');
      var stepFilter = this.get('analyticsService.page');
      return [sessionFilter, stepFilter].reduce(function (count, filter) {
        if (!_lodash.default.isEmpty(filter)) {
          return count + 1;
        }

        return count;
      }, 0);
    }),
    numberOfCompareFocusFilters: Ember.computed('comparePeopleFilters.sessionFocus', 'comparePeopleFilters.page', function () {
      var filters = [this.get('comparePeopleFilters.sessionFocus'), this.get('comparePeopleFilters.page')];
      return filters.filter(function (f) {
        return !_lodash.default.isEmpty(f);
      }).length;
    }),
    CLOSE_DROPDOWNS_LISTENER: Ember.observer('canvasClickId', function () {
      this.send('closeFiltersDropdown');
      this.send('closeCompareDropdown');
      this.set('selectedCompareOption', undefined);
    }),
    initializeProperties: function initializeProperties() {
      this.setSelectedCountries();
      this.initPeopleCompareFilters();
    },
    initPeopleCompareFilters: function initPeopleCompareFilters() {
      this.set('comparePeopleFilters', {
        countries: [],
        device: null,
        sessionFocus: null,
        page: null
      });
    },
    highlightDatePicker: function highlightDatePicker() {
      this.set('dateRangeSelectorClassNames', 'date-range-selector--active');
    },
    unhighlightDatePicker: function unhighlightDatePicker() {
      this.set('dateRangeSelectorClassNames', '');
    },
    attachDatePickerHighlightEventHandler: function attachDatePickerHighlightEventHandler() {
      var _this = this;

      this.$(".".concat(DATE_RANGE_SELECTOR_EVENT_CLASS)).on('focusin', function () {
        _this.highlightDatePicker();
      });
    },
    detachDatePickerHighlightEventHandler: function detachDatePickerHighlightEventHandler() {
      this.$(".".concat(DATE_RANGE_SELECTOR_EVENT_CLASS)).off('focusin');
    },
    didInsertElement: function didInsertElement() {
      this.initializeProperties();
      this.attachDatePickerHighlightEventHandler();
    },
    willDestroyElement: function willDestroyElement() {
      this.detachDatePickerHighlightEventHandler();
    },
    onFocusOnEntity: function onFocusOnEntity() {},
    onSelectCanvasElement: function onSelectCanvasElement() {},
    findStep: function findStep(entity) {
      var entityManager = this.get('canvasService').getEntityManager();
      entityManager.selectAndScrollTo(entity, {
        select: false
      });
    },
    onOpenFiltersDropdown: function onOpenFiltersDropdown() {},
    onCloseFiltersDropdown: function onCloseFiltersDropdown() {},
    onOpenCompareDropdown: function onOpenCompareDropdown() {},
    onCloseCompareDropdown: function onCloseCompareDropdown() {},
    actions: {
      toggleFiltersOpen: function toggleFiltersOpen() {
        this.send('toggleFiltersDropdown');
        this.send('closeCompareDropdown');
      },
      toggleCompareDropdown: function toggleCompareDropdown() {
        this.send('closeFiltersDropdown');
        this.set('selectedCompareOption', undefined);
        this.send('handleToggleCompareDropdown');
      },
      selectCompareDateOption: function selectCompareDateOption() {
        this.set('selectedCompareOption', 'date');
        this.send('closeCompareDropdown');
        this.$('.analytics-toolbar__compare .daterangepicker-input').click();
      },
      cancelComparePeopleFilters: function cancelComparePeopleFilters() {
        this.set('selectedCompareOption', undefined);
        this.send('closeCompareDropdown');
      },
      comparePeople: function comparePeople() {
        this.set('selectedCompareOption', undefined);
        this.send('closeCompareDropdown');
        var handler = this.get('onStartComparing');
        handler(_AnalyticsConstants.default.ANALYTICS_COMPARE_MODE_PEOPLE, this.get('comparePeopleFilters'));

        _ProductTracker.default.trigger(_TrackingConstants.default.CANVAS_COMPARE_PEOPLE_FILTER);
      },
      selectComparePeopleOption: function selectComparePeopleOption() {
        this.set('selectedCompareOption', 'people');
      },
      togglePeopleCompareFilters: function togglePeopleCompareFilters() {
        this.send('closeFiltersDropdown');
        var shouldOpen = !this.get('isCompareDropdownOpened');
        this.send('handleToggleCompareDropdown');
        this.set('selectedCompareOption', shouldOpen ? 'people' : undefined);
      },
      hideCompareDatePicker: function hideCompareDatePicker() {
        this.set('selectedCompareOption', undefined);
      },
      compareDates: function compareDates(start, end) {
        this.set('selectedCompareOption', undefined);
        this.send('closeCompareDropdown');
        var handler = this.get('onStartComparing');
        handler(_AnalyticsConstants.default.ANALYTICS_COMPARE_MODE_DATES, {
          dates: {
            start: start,
            end: end
          }
        });

        _ProductTracker.default.trigger(_TrackingConstants.default.CANVAS_COMPARE_DATE_FILTER);
      },
      stopComparing: function stopComparing() {
        this.send('closeCompareDropdown');
        this.set('selectedCompareOption', undefined);
        var handler = this.get('onStopComparing');
        handler();
      },
      setDateRange: function setDateRange(start, end) {
        this.get('analyticsService').setDateRange(start, end);

        _ProductTracker.default.trigger(_TrackingConstants.default.CANVAS_PRIMARY_DATE_FILTER);
      },
      clearUserFocus: function clearUserFocus() {
        this.get('analyticsService').clearFocusUser();
      },
      clearStepFocus: function clearStepFocus() {
        this.get('analyticsService').clearFocusStep();
      },
      clearCompareStepFocusFilters: function clearCompareStepFocusFilters() {
        this.set('comparePeopleFilters.page', null);
      },
      clearCompareUserFocusFilters: function clearCompareUserFocusFilters() {
        this.set('comparePeopleFilters.sessionFocus', null);
      },
      setDevice: function setDevice(device) {
        this.get('analyticsService').selectDeviceFilter(device);

        _ProductTracker.default.trigger(_TrackingConstants.default.CANVAS_FILTER_DEVICE);
      },
      findStep: function findStep() {
        this.findStep(this.get('canvasService').wrapEntity(this.get('analyticsService.page.model')));
      },
      findCompareStep: function findCompareStep() {
        this.findStep(this.get('canvasService').wrapEntity(this.get('comparePeopleFilters.page.model')));
      },
      setCompareDevice: function setCompareDevice(device) {
        this.set('comparePeopleFilters.device', device);
      },
      unhighlightDatePicker: function unhighlightDatePicker() {
        this.unhighlightDatePicker();
      },
      setCountries: function setCountries(countryNames) {
        this.set('selectedCountryNames', countryNames);
        this.get('analyticsService').setCountriesFilter(countryNames);
      },
      setCompareCountries: function setCompareCountries(countryNames) {
        this.set('comparePeopleFilters.countries', countryNames);
      },
      focusOnEntity: function focusOnEntity(entity) {
        var focusOnEntityHandler = this.get('onFocusOnEntity');
        focusOnEntityHandler(entity);
      },
      focusOnEntityForCompare: function focusOnEntityForCompare(entityToFocusOn) {
        var canvasView = this.get('canvasService').getEntityView(entityToFocusOn);
        this.set('comparePeopleFilters.page', canvasView);
      },
      selectEntity: function selectEntity(entity) {
        if (!entity) {
          return;
        }

        var selectEntityHandler = this.get('onSelectCanvasElement');
        selectEntityHandler(entity.getID());
      },
      focusOnSession: function focusOnSession(session) {
        this.get('analyticsService').selectSession(session);

        _ProductTracker.default.trigger(_TrackingConstants.default.CANVAS_ANALYTICS_FOCUS_ON_SESSION);
      },
      focusOnSessionForCompare: function focusOnSessionForCompare(session) {
        this.set('comparePeopleFilters.sessionFocus', session);
      },
      loadAdditionalSessions: function loadAdditionalSessions() {
        this.get('analyticsService').loadNextUsersPage();
      },
      openFiltersDropdown: function openFiltersDropdown() {
        var openFiltersDropdownHandler = this.get('onOpenFiltersDropdown');
        openFiltersDropdownHandler();
      },
      closeFiltersDropdown: function closeFiltersDropdown() {
        var closeFiltersDropdownHandler = this.get('onCloseFiltersDropdown');
        closeFiltersDropdownHandler();
      },
      toggleFiltersDropdown: function toggleFiltersDropdown() {
        if (this.get('isFiltersOpen')) {
          this.send('closeFiltersDropdown');
        } else {
          this.send('openFiltersDropdown');
        }
      },
      openCompareDropdown: function openCompareDropdown() {
        var openFiltersDropdownHandler = this.get('onOpenCompareDropdown');
        openFiltersDropdownHandler();
      },
      closeCompareDropdown: function closeCompareDropdown() {
        var openFiltersDropdownHandler = this.get('onCloseCompareDropdown');
        openFiltersDropdownHandler();
      },
      handleToggleCompareDropdown: function handleToggleCompareDropdown() {
        if (this.get('isCompareDropdownOpened')) {
          this.send('closeCompareDropdown');
        } else {
          this.send('openCompareDropdown');
        }
      },
      sendAnalyticsRequest: function sendAnalyticsRequest() {
        this.get('analyticsService').alignAllRequests({
          isNew: true
        });
        this.send('closeFiltersDropdown');

        _ProductTracker.default.trigger(_TrackingConstants.default.CANVAS_PRIMARY_PEOPLE_FILTER);
      }
    }
  });

  _exports.default = _default;
});