define("funnelytics/components/input/input-tags", ["exports", "funnelytics/custom/tag"], function (_exports, _tag) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      var _this$canvasTags$leng, _this$canvasTags;

      this._super.apply(this, arguments);

      var tagsCount = (_this$canvasTags$leng = (_this$canvasTags = this.canvasTags) === null || _this$canvasTags === void 0 ? void 0 : _this$canvasTags.length) !== null && _this$canvasTags$leng !== void 0 ? _this$canvasTags$leng : 0;

      if (this.restrictNumTagDisplayed && tagsCount > this.restrictNumTagDisplayed) {
        var _this$canvasTags2, _this$canvasTags3;

        this.set('tagsDisplayed', this.restrictNumTagDisplayed);
        var shortenedTags = ((_this$canvasTags2 = this.canvasTags) === null || _this$canvasTags2 === void 0 ? void 0 : _this$canvasTags2.slice(0, this.restrictNumTagDisplayed)) || [];
        var createTags = shortenedTags.map(function (t) {
          return new _tag.default(t, t);
        });
        var compressedTags = ((_this$canvasTags3 = this.canvasTags) === null || _this$canvasTags3 === void 0 ? void 0 : _this$canvasTags3.slice(this.restrictNumTagDisplayed, this.canvasTags.length)) || [];
        createTags.push(new _tag.default("+".concat(tagsCount - this.restrictNumTagDisplayed), compressedTags.join(', ')));
        this.set('shortenedCTags', createTags);
      } else {
        var _this$canvasTags4;

        var _createTags = ((_this$canvasTags4 = this.canvasTags) === null || _this$canvasTags4 === void 0 ? void 0 : _this$canvasTags4.map(function (t) {
          return new _tag.default(t, t);
        })) || [];

        this.set('shortenedCTags', _createTags);
      }

      if (this.numCloseTagsEnabled && this.numCloseTagsEnabled > 0) {
        this.set('dynamicClosingTags', true);
      }
    },
    tagsClassModifier: Ember.computed('showAllTags', 'highlightFirst', function () {
      var baseClass = 'tags';
      var classes = [];
      this.showAllTags ? classes.push('show') : null;
      this.highlightFirst ? classes.push('highlightFirst') : null;
      return baseClass + ' ' + classes.join(' ');
    }),
    modifiedCanvasTags: Ember.computed('shortenedCTags', 'canvasTags', function () {
      return this.get('shortenedCTags') || [];
    }),
    tagsInline: Ember.computed('mode', function () {
      return this.get('mode') === 'card' ? 'tags inline' : 'tags';
    }),
    showTagList: false,
    actions: {
      filterTags: function filterTags(e) {},
      checkKeystroke: function checkKeystroke(e) {
        if (e.key === 'Enter') {
          e.preventDefault(); //insert tag to list
        }

        if (e.key === 'Escape') {
          e.preventDefault(); //clear input
        }
      }
    }
  });

  _exports.default = _default;
});