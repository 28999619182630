define("funnelytics/models/recurly-subscription-permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    permission: _emberData.default.belongsTo('permission'),
    subscription: _emberData.default.belongsTo('recurly-subscription')
  });

  _exports.default = _default;
});