define("funnelytics/components/top-notification-bar", ["exports", "funnelytics/custom/storage/StorageHelpers"], function (_exports, _StorageHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Incremenent up to enable new notification
  var NOTIFICATION_ID = 1;

  var _default = Ember.Component.extend({
    classNames: ['top-notification-bar'],
    classNameBindings: ['isClosed:top-notification-bar--hidden'],
    didInsertElement: function didInsertElement() {
      var showNotificationBar = _StorageHelpers.default.getTopNotificationBarStatus();

      if (showNotificationBar === NOTIFICATION_ID) {
        this.set('isClosed', true);
      }
    },
    actions: {
      dismissTopNotificationBar: function dismissTopNotificationBar() {
        _StorageHelpers.default.setTopNotificationBar(NOTIFICATION_ID);

        this.set('isClosed', true);
      }
    }
  });

  _exports.default = _default;
});