define("funnelytics/templates/components/for-reuse/tracking-standard-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6oCcY2dZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\"],[12,\"class\",[28,[[21,\"additionalClasses\"]]]],[11,\"src\",\"/assets/canvas-logo.svg\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/tracking-standard-icon.hbs"
    }
  });

  _exports.default = _default;
});