define("funnelytics/components/canvas-elements/context-menu/tracking-settings/advanced-actions/attribute", ["exports", "lodash", "funnelytics/custom/analytics/settings/config/standard-action-keys-mapping", "funnelytics/custom/analytics/settings/config/standard-action-value-converters"], function (_exports, _lodash, _standardActionKeysMapping, _standardActionValueConverters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_VALUE_LENGTH = 12;
  var VALUE_ABRIDGED_LENGTH = MAX_VALUE_LENGTH - 3;

  var _default = Ember.Component.extend({
    classNames: ['tracking-settings__action-attribute'],
    // TODO: filtering?
    availableActionValues: Ember.computed('availableActionAttributes', 'attributeKey', function () {
      var attributesKey = this.get('attributeKey');
      var EMPTY_VALUES = [];

      if (!attributesKey) {
        return EMPTY_VALUES;
      } // TODO: Analytics API only send us 20 of these at a time


      var key = _lodash.default.find(this.get('availableActionAttributes'), function (actionAttribute) {
        // TODO: This 'key' should be a constant synced with the Analytics API
        return _lodash.default.get(actionAttribute, ['key']) === attributesKey;
      }); // TODO: This 'values' should be a constant synced with the Analytics API


      return _lodash.default.get(key, ['values'], EMPTY_VALUES);
    }),
    attributeKey: Ember.computed('entityParameter', function () {
      return this.get('entityParameter').getKey();
    }),
    selectedAttributeKeyDisplayRaw: Ember.computed('attributeKey', function () {
      return _lodash.default.get(_standardActionKeysMapping.default, [this.get('attributeKey')], this.get('attributeKey'));
    }),
    selectedAttributeKeyDisplay: Ember.computed('selectedAttributeKeyDisplayRaw', function () {
      var rawValue = this.get('selectedAttributeKeyDisplayRaw');

      if (_lodash.default.isString(rawValue) && rawValue.length > MAX_VALUE_LENGTH) {
        return "".concat(rawValue.substring(0, VALUE_ABRIDGED_LENGTH), "...");
      }

      return rawValue;
    }),
    hasNotAttributeKey: Ember.computed.empty('attributeKey'),
    attributeValue: Ember.computed('entityParameter', function () {
      return this.get('entityParameter').getValue();
    }),
    actionValueDisplayConverter: Ember.computed('attributeKey', function () {
      return _lodash.default.get(_standardActionValueConverters.default, [this.get('attributeKey')], _standardActionValueConverters.default.DEFAULT);
    }),
    convertedAttributeValue: Ember.computed('actionValueDisplayConverter', 'attributeValue', function () {
      return this.get('actionValueDisplayConverter')(this.get('attributeValue'));
    }),
    displayAttributeValue: Ember.computed('convertedAttributeValue', function () {
      var fullValue = this.get('convertedAttributeValue');

      if (_lodash.default.isString(fullValue) && fullValue.length > MAX_VALUE_LENGTH) {
        return "".concat(fullValue.substring(0, VALUE_ABRIDGED_LENGTH), "...");
      }

      return fullValue;
    }),
    onAddAttribute: function onAddAttribute() {},
    onSelectPropertyValue: function onSelectPropertyValue() {},
    onDeleteAttribute: function onDeleteAttribute() {},
    didInsertElement: function didInsertElement() {
      this.setProperties({
        isPropertyValueDropdownOpened: false,
        customAttributeValue: ''
      });
    },
    actions: {
      togglePropertyValueDropdownOpened: function togglePropertyValueDropdownOpened() {
        this.toggleProperty('isPropertyValueDropdownOpened');
      },
      deleteAttribute: function deleteAttribute() {
        var deleteAttributeByKey = this.get('onDeleteAttribute');
        var attributeKey = this.get('attributeKey');
        deleteAttributeByKey(attributeKey);
      },
      setAttributeKey: function setAttributeKey(key) {
        this.send('deleteAttribute');
        this.get('entityParameter').setKey(key);
        this.notifyPropertyChange('attributeKey');
        this.send('setAttributeValue', null);
      },
      setAttributeValue: function setAttributeValue(value) {
        this.get('entityParameter').setValue(value);
        this.notifyPropertyChange('attributeValue');
        var updateAttributeValue = this.get('onSelectPropertyValue');
        updateAttributeValue(this.get('entityParameter'));
      },
      setCustomAttributeValue: function setCustomAttributeValue() {
        this.send('setAttributeValue', this.get('customAttributeValue'));
        this.set('customAttributeValue', '');
      }
    }
  });

  _exports.default = _default;
});