define("funnelytics/templates/components/billing/next-phase", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pHmGcArl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"d-flex\"],[11,\"style\",\"line-height: 26px;\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"mb-1\"],[11,\"style\",\"width: 100%; height: fit-content;\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"loading\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"style\",\"width: 80px; height: 26px; margin-bottom: 6px;\"],[9],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"ml-4 shimmerBG content-line\"],[9],[10],[0,\"\\n            \"],[10],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"mb-1\"],[11,\"style\",\"width: 200px; height: 26px;\"],[9],[0,\"\\n                \"],[7,\"div\"],[11,\"class\",\"ml-4 shimmerBG content-line\"],[9],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"invalid\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"error\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[9],[0,\" Something went wrong. Try agin later.\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"ml-4\"],[11,\"style\",\"font-size: 14px;\"],[9],[1,[21,\"nextPhase\"],false],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/billing/next-phase.hbs"
    }
  });

  _exports.default = _default;
});