define("funnelytics/custom/tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  var Tag = function Tag(text, hoverText) {
    _classCallCheck(this, Tag);

    this.text = text;
    this.hoverText = hoverText;
  };

  _exports.default = Tag;
});