define("funnelytics/components/library/canvas-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    routerService: Ember.inject.service('router'),
    hideHeader: true,
    tags: Ember.computed('cardData.purpose', 'cardData.offer_type', 'cardData.industry', function () {
      var _this$cardData = this.cardData,
          purpose = _this$cardData.purpose,
          industry = _this$cardData.industry,
          offer_type = _this$cardData.offer_type;
      return [purpose, industry, offer_type];
    }),
    maxTagCharacters: 10,
    restrictNumTagDisplayed: 3,
    creatorType: Ember.computed('cardData.is_funnelytics', function () {
      return this.cardData.is_funnelytics ? 'funnelytics' : 'user:free';
    }),
    actions: {
      onClick: function onClick() {
        var libraryId = this.cardData.get('id');
        var routerService = this.get('routerService');
        var url = routerService.urlFor('library.share', libraryId);
        window.open(url, '_blank').focus();
      }
    }
  });

  _exports.default = _default;
});