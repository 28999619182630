define("funnelytics/services/command-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  
    Wrap all actions that are meant to be undo in a single action with:
  
    startBatchCommand()
  
    ... your super sick 🔥🔥🔥 code
  
    endBatchCommand()
  
    Note: startBatchCommand and endBatchCommand must be called an equal number of times
  
  */
  var _default = Ember.Service.extend({
    setCommandManager: function setCommandManager(commandManager) {
      this.set('commandManager', commandManager.manager);
      return;
    },
    startBatchCommand: function startBatchCommand() {
      if (!this.get('commandManager')) {
        throw new Error('Ensure commandManager is available before attempting to use it.');
      }

      this.get('commandManager').initBatchCommand();
      return;
    },
    endBatchCommand: function endBatchCommand() {
      if (!this.get('commandManager')) {
        throw new Error('Ensure commandManager is available before attempting to use it.');
      }

      this.get('commandManager').storeBatchCommand();
      return;
    }
  });

  _exports.default = _default;
});