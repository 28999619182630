define("funnelytics/templates/components/vault-media-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4GqEJ40+",
    "block": "{\"symbols\":[\"medium\"],\"statements\":[[7,\"div\"],[11,\"id\",\"prev\"],[9],[0,\"\\n  \"],[7,\"svg\"],[11,\"class\",\"arrow icon icon-chevron-left\"],[9],[0,\"\\n    \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-chevron-left\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"id\",\"slick\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"media\"]]],null,{\"statements\":[[4,\"if\",[[27,\"eq\",[[22,1,[\"media_type\"]],\"image\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"img\"],[12,\"src\",[22,1,[\"link\"]]],[11,\"class\",\"img-fluid\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[7,\"div\"],[11,\"id\",\"next\"],[9],[0,\"\\n  \"],[7,\"svg\"],[11,\"class\",\"arrow icon icon-chevron-right\"],[9],[0,\"\\n    \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-chevron-right\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/vault-media-viewer.hbs"
    }
  });

  _exports.default = _default;
});