define("funnelytics/components/canvas-elements/context-menu/explorer-attribute", ["exports", "lodash", "funnelytics/custom/analytics/analytics/canvas/EntityParameter"], function (_exports, _lodash, _EntityParameter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // TODO: Should align with the OutputConstants of analytics API:
    attributeKey: Ember.computed.readOnly('attribute.key'),
    attributeHits: Ember.computed.readOnly('attribute.hits'),
    attributeValues: Ember.computed.readOnly('attribute.values'),
    isRecognizedAttribute: Ember.computed('attributeKey', 'displayKey', function () {
      return this.get('attributeKey') !== this.get('displayKey');
    }),
    displayKey: Ember.computed('attributeKey', 'displayKeyMapping', function () {
      var attributeKey = this.get('attributeKey');
      return _lodash.default.get(this.get('displayKeyMapping'), [attributeKey], attributeKey);
    }),
    hasValues: Ember.computed('attributeKey', 'keysWithHiddenValues', function () {
      return !this.get('keysWithHiddenValues').includes(this.get('attributeKey'));
    }),
    hasNoValues: Ember.computed.empty('attributeValues'),
    isShowingValues: Ember.computed.and('hasValues', 'isExpanded'),
    keysWithHiddenValues: ['fbclid', 'gclid'],
    buttonText: 'Add',
    initializeProperties: function initializeProperties() {
      this.setProperties({
        isExpanded: false
      });
    },
    didInsertElement: function didInsertElement() {
      this.initializeProperties();
    },
    onAddValue: function onAddValue() {},
    actions: {
      toggleExpand: function toggleExpand() {
        this.toggleProperty('isExpanded');
      },
      addValue: function addValue(value) {
        var addValueHandler = this.get('onAddValue');
        var entityParameter = new _EntityParameter.default({
          key: this.get('attributeKey'),
          value: value
        });
        addValueHandler(entityParameter);
      }
    }
  });

  _exports.default = _default;
});