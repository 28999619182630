define("funnelytics/controllers/dashboard/templates/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      create: function create(model) {
        var self = this,
            template = this.store.createRecord('template', {
          name: model.name
        });
        template.save().then(function (record) {
          self.transitionToRoute('dashboard.funnels.view', record);
        }, function () {
          template.deleteRecord();
          self.set('error', 'There was an error creating your template');
        });
      }
    }
  });

  _exports.default = _default;
});