define("funnelytics/components/permissions/has-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /** Depreciate this permission fetching component
     * in favor of the new permission component 'permission/has-permission-2'
     */
    userPermissions: Ember.inject.service(),
    ajax: Ember.inject.service(),
    tagName: '',
    // state
    pending: true,
    permitted: null,
    error: null,
    // All args
    showLoadingDots: false,
    // permission args
    permission: undefined,
    scope: undefined,
    scopeId: undefined,
    count: undefined,
    accessLevel: undefined,
    isPermissionCheckIgnored: false,
    reloadPermission: Ember.observer('permission', 'scope', 'scopeId', 'count', 'accessLevel', function () {
      this.loadPermission();
    }),
    loadPermission: function loadPermission() {
      var _this = this;

      this.setProperties({
        pending: true,
        permitted: null,
        error: null
      });
      return this.get('userPermissions').hasPermission({
        permission: this.get('permission'),
        scope: this.get('scope'),
        scopeId: this.get('scopeId'),
        count: this.get('count'),
        accessLevel: this.get('accessLevel'),
        isPermissionCheckIgnored: this.get('isPermissionCheckIgnored')
      }).then(function (permitted) {
        if (_this.get('isDestroyed')) {
          return;
        }

        _this.set('permitted', permitted);

        _this.set('pending', false);

        _this.set('error', false);
      }).catch(function () {
        _this.set('permitted', false);

        _this.set('pending', false);

        _this.set('error', true);
      });
    },
    didInsertElement: function didInsertElement() {
      this.loadPermission();
    }
  });

  _exports.default = _default;
});