define("funnelytics/templates/components/dashboard-elements/subscriptions/manage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "73KlXpoz",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isSubscriptionNotFree\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"fl-flex-row card-body-text fl--mt--2\"],[9],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n      \"],[1,[21,\"subscriptionName\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"hasQueuedSubscription\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[9],[0,\"\\n        Expires on \"],[1,[27,\"date-parser\",[[23,[\"termEndDate\"]],\"hide null\",\"YYYY-MM-DD\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/dashboard-elements/subscriptions/manage.hbs"
    }
  });

  _exports.default = _default;
});