define("funnelytics/services/recurly", ["exports", "funnelytics/custom/commerce/RecurlyHelpers"], function (_exports, _RecurlyHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    fetchInvoicePdf: function fetchInvoicePdf(invoiceId) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        try {
          var xhr = new XMLHttpRequest();
          xhr.open('GET', _RecurlyHelpers.default.getInvoicePdfUrl(invoiceId), true);
          xhr.setRequestHeader('Authorization', "Bearer ".concat(_this.get('session.data.authenticated.access_token')));
          xhr.responseType = 'blob';

          xhr.onload = function () {
            var status = this.status;

            if (status !== 200) {
              reject(new Error(this.statusText));
              return;
            }

            var response = xhr.response;
            var blob = new Blob([response], {
              type: 'application/png'
            });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "funnelytics-invoice-".concat(invoiceId, ".pdf");
            link.click();
            resolve(true);
          };

          xhr.send();
        } catch (err) {
          reject(err);
        }
      });
    }
  });

  _exports.default = _default;
});