define("funnelytics/templates/components/for-reuse/multi-select-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oJqwGCl/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[11,\"class\",\"fl-multi-select__item\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"fl-multi-select__item-button\"],[3,\"action\",[[22,0,[]],\"handleClick\",[23,[\"item\"]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fl-multi-select__checkbox-and-label\"],[9],[0,\"\\n      \"],[7,\"input\"],[11,\"class\",\"fl-multi-select__checkbox\"],[12,\"checked\",[23,[\"item\",\"isSelected\"]]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"fl-multi-select__item-label\"],[9],[0,\"\\n        \"],[1,[23,[\"item\",\"label\"]],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"fl-multi-select__item-secondary\"],[9],[0,\"\\n      \"],[1,[23,[\"item\",\"secondary\"]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/multi-select-item.hbs"
    }
  });

  _exports.default = _default;
});