define("funnelytics/templates/components/hubspot-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "reATp48m",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"hubspot-chat-close-button modal__close-btn\"],[11,\"type\",\"button\"],[3,\"action\",[[22,0,[]],\"onClose\"]],[9],[0,\"\\n  \"],[1,[27,\"svg-display\",null,[[\"symbolDefId\"],[\"icon-close-modal\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"id\",\"hubspot-frame-wrapper\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/hubspot-chat.hbs"
    }
  });

  _exports.default = _default;
});