define("funnelytics/components/vault-media-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['col-lg-7 col-md-12 col-sm-12 slick--container'],
    viewer: null,
    didInsertElement: function didInsertElement() {
      this.set('viewer', this.$().find('#slick'));
      this.get('viewer').slick({
        dots: true,
        dotsClass: 'custom-dot-class'
      });
      this.$().find('#next').on('click', function () {
        this.get('viewer').slick('slickNext');
      });
      this.$().find('#next').on('click', function () {
        this.get('viewer').slick('slickPrev');
      });
    },
    willDestroyElement: function willDestroyElement() {}
  });

  _exports.default = _default;
});