define("funnelytics/custom/analytics/AttributesRequest", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AttributesRequest = Ember.Object.extend({
    // required props
    parameterKey: '',
    request: null,
    analyticsService: null,
    actionStep: null,
    attributesData: Ember.computed.readOnly('request.mainAnalyticsData.actionAttributes'),
    isLoading: Ember.computed.readOnly('request.mainAnalyticsData.isLoading'),
    init: function init() {
      this.reloadAnalytics();
    },
    reloadAnalytics: function reloadAnalytics() {
      var _this = this;

      var analyticsService = this.get('analyticsService');
      var actionStep = this.get('actionStep');

      if (!analyticsService || !actionStep) {
        return;
      }

      analyticsService.sendAnalyticsRequest(this.get('request'), {
        isClearingExisting: true,
        // may not be correct
        exploredEntity: actionStep,
        beforeRequestHooks: function beforeRequestHooks(analyticsSettings) {
          // TODO: We may have an issue since we are not modifying the same entity passed through the dictionary...
          analyticsSettings.removeExploredEntityAttributeByKey(_this.get('parameterKey'));
        }
      });
    }
  });
  var _default = AttributesRequest;
  _exports.default = _default;
});