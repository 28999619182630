define("funnelytics/templates/components/canvas-elements/entity-toolbar/custom-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "23bcGWmD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"\\n  entity-toolbar__contents \\n  \",[27,\"if\",[[23,[\"isVisible\"]],\"entity-toolbar__contents--visible\"],null],\"\\n\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"entity-toolbar__body\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"entity-toolbar__section\"],[9],[0,\"\\n\"],[4,\"canvas-elements/entity-toolbar/item\",null,[[\"label\",\"onClick\"],[\"Rotate clockwise\",[27,\"action\",[[22,0,[]],\"clickRotate\"],null]]],{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"fl-btn__icon\"],[9],[0,\"\\n          \"],[1,[27,\"svg-display\",null,[[\"symbolDefId\"],[\"icon-rotate\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"canvas-elements/entity-toolbar/item\",null,[[\"label\",\"onClick\"],[\"Replace image\",[27,\"action\",[[22,0,[]],\"clickReplaceImage\"],null]]],{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"fl-btn__icon\"],[9],[0,\"\\n          \"],[1,[27,\"svg-display\",null,[[\"symbolDefId\"],[\"icon-image\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\" \"],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"entity-toolbar__section\"],[9],[0,\"\\n\"],[4,\"canvas-elements/entity-toolbar/item\",null,[[\"label\",\"onClick\",\"isSelected\"],[\"More actions\",[27,\"action\",[[22,0,[]],\"clickContext\"],null],false]],{\"statements\":[[0,\"        \"],[7,\"span\"],[11,\"class\",\"fl-btn__icon fl-btn__icon--faded\"],[9],[0,\"\\n          \"],[1,[27,\"svg-display\",null,[[\"symbolDefId\"],[\"icon-three-dots\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\" \"],[0,\"\\n  \"],[10],[0,\" \"],[0,\"\\n\"],[10],[0,\" \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/entity-toolbar/custom-image.hbs"
    }
  });

  _exports.default = _default;
});