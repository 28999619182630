define("funnelytics/templates/components/canvas-elements/entity-notes-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+cCDiDAm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"notes-card pointer-click\\n  \",[27,\"if\",[[23,[\"isNotesEditorExpanded\"]],\"expanded\"],null]]]],[3,\"action\",[[22,0,[]],\"openStepNotes\",[23,[\"step\"]]]],[9],[0,\"\\n  \"],[7,\"img\"],[12,\"src\",[21,\"image\"]],[11,\"class\",\"notes-image\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"notes-label\"],[9],[0,\"\\n    \"],[1,[21,\"label\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"notes-text-preview\"],[9],[0,\"\\n    \"],[1,[21,\"notesPreview\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/entity-notes-preview.hbs"
    }
  });

  _exports.default = _default;
});