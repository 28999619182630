define("funnelytics/controllers/dashboard/settings/account/email-update", ["exports", "moment", "lodash"], function (_exports, _moment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_ERROR = 'An unexpected error occurred.';

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    modal: Ember.inject.service('current-routed-modal'),
    password: '',
    updating: false,
    passwordEmpty: Ember.computed.empty('password'),
    cannotSubmit: Ember.computed.or('passwordEmpty', 'updating'),
    emailUpdateHandler: function emailUpdateHandler() {},
    modalCloseHelper: function modalCloseHelper() {},
    tokenNotUsed: Ember.computed.not('model.used'),
    tokenAlreadyUsed: Ember.computed.not('tokenNotUsed'),
    tokenFound: Ember.computed.notEmpty('model.id'),
    tokenExpired: Ember.computed('model.expires_at', function () {
      return (0, _moment.default)().isAfter(this.get('model.expires_at'));
    }),
    tokenNotExpired: Ember.computed.not('tokenExpired'),
    tokenValid: Ember.computed.and('tokenFound', 'tokenNotUsed', 'tokenNotExpired'),
    actions: {
      closeTheModal: function closeTheModal() {
        this.get('modalCloseHelper')();
      },
      update: function update() {
        var _this = this;

        if (this.get('cannotSubmit')) {
          return;
        }

        this.set('updating', true);
        var id = this.get('model').get('id');
        return this.get('ajax').request("/email-updates/".concat(id), {
          method: 'PATCH',
          data: {
            password: this.get('password')
          }
        }).then(function (data) {
          _this.set('error', '');

          _this.set('updating', false);

          _this.get('store').pushPayload('email-update', data);

          _this.send('closeTheModal');

          _this.get('emailUpdateHandler')();

          _this.transitionToRoute('dashboard.settings.account');
        }).catch(function (err) {
          var errorMessage = _lodash.default.get(err, ['payload', 'errors', 0, 'detail'], _lodash.default.get(err, ['payload', 'detail'], _lodash.default.get(err, 'message', DEFAULT_ERROR)));

          _this.set('error', errorMessage);

          _this.set('updating', false);
        });
      }
    }
  });

  _exports.default = _default;
});