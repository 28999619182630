define("funnelytics/templates/components/canvas-elements/context-menu/tracking-settings/advanced-actions/dropdown-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TdiWpsk/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"tracking-settings__dropdown-group-item\"],[3,\"action\",[[22,0,[]],\"handleClick\"]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"fl-flex-center\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isRecognizedAction\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"for-reuse/tracking-standard-icon\",null,[[\"additionalClasses\"],[\"tracking-settings__option-icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"span\"],[11,\"class\",\"fl--pl--05\"],[9],[0,\"\\n      \"],[1,[21,\"displayName\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"hasHits\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"tracking-settings__dropdown-group-item-count\"],[9],[0,\"\\n      \"],[1,[21,\"hits\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/context-menu/tracking-settings/advanced-actions/dropdown-selection.hbs"
    }
  });

  _exports.default = _default;
});