define("funnelytics/helpers/from-now", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fromNow = fromNow;
  _exports.default = void 0;

  function fromNow(params) {
    var fromNowString = (0, _moment.default)(params[0]).local().fromNow();
    return fromNowString;
  }

  var _default = Ember.Helper.helper(fromNow);

  _exports.default = _default;
});