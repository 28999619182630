define("funnelytics/routes/dashboard/projects/project/funnels/upsell", ["exports", "ember-routable-modal/mixins/route", "funnelytics/custom/constants/CookieConstants"], function (_exports, _route, _CookieConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_route.default, {
    modal: Ember.inject.service('current-routed-modal'),
    cookies: Ember.inject.service(),
    workspaceSelector: Ember.inject.service(),
    router: Ember.inject.service(),
    projectPermissions: Ember.inject.service(),
    close: null,
    init: function init() {
      var cookiesService = this.get('cookies');
      var cookies = cookiesService.read();
      var isFreeMembershipId = this.get('projectPermissions.isFreeMembershipId');

      if (isFreeMembershipId && (!cookies[_CookieConstants.default.UPSELL] || cookies[_CookieConstants.default.UPSELL] !== _CookieConstants.default.LITE_TRIAL)) {
        this.set('close', true);
      }
    },
    redirect: function redirect() {
      if (this.close) {
        this.modal.close();
        this.router.transitionTo('dashboard');
      }
    }
  });

  _exports.default = _default;
});