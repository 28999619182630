define("funnelytics/custom/constants/ForecastConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ForecastConstants = Object.freeze({
    TYPE_SET_SOURCE: 'source',
    TYPE_SET_PAGE: 'page',
    TYPE_SET_DEPENDENT: 'dependent'
  });
  var _default = ForecastConstants;
  _exports.default = _default;
});