define("funnelytics/components/funnel-pages/checkout/add-ons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onSelect: function onSelect() {},
    actions: {
      select: function select(addOn, eventObject) {
        var selectHandler = this.get('onSelect');
        selectHandler(addOn, eventObject);
      }
    }
  });

  _exports.default = _default;
});