define("funnelytics/templates/components/for-reuse/chip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KXgMk+LO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[12,\"class\",[28,[\"fl-chip \",[27,\"if\",[[23,[\"active\"]],\"fl-chip--active\",\"\"],null]]]],[3,\"action\",[[22,0,[]],[23,[\"toggleChip\"]]]],[9],[0,\"\\n  \"],[1,[21,\"chipTitle\"],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/chip.hbs"
    }
  });

  _exports.default = _default;
});