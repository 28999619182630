define("funnelytics/templates/components/order-forms/confirmation/vip-setup-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rL7D/FA2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"funnelmomentum\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"funnelmomentum__video-btn-container\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"funnelmomentum__title\"],[9],[0,\"Schedule Your Free 1-on-1 VIP Onboarding Session\"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"id\",\"calendly-calendar\"],[11,\"class\",\"calendly-inline-widget\"],[11,\"data-url\",\"https://calendly.com/funnelytics-vip/vip-onboarding\"],[11,\"style\",\"min-width:320px;height:630px;\"],[9],[10],[0,\"\\n    \"],[7,\"script\"],[11,\"src\",\"https://assets.calendly.com/assets/external/widget.js\"],[11,\"type\",\"text/javascript\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/order-forms/confirmation/vip-setup-calendar.hbs"
    }
  });

  _exports.default = _default;
});