define("funnelytics/helpers/is-integer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isInteger = isInteger;
  _exports.default = void 0;

  function isInteger(params) {
    return isNaN(parseInt(params[0])) === false;
  }

  var _default = Ember.Helper.helper(isInteger);

  _exports.default = _default;
});