define("funnelytics/templates/components/upsells/project-unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LlhjQ1B/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"upsell-modal__header\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"close\"],[3,\"action\",[[22,0,[]],\"close\"]],[9],[0,\"\\n    ×\\n  \"],[10],[0,\"\\n  \"],[1,[21,\"header\"],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"upsell-modal__body\"],[9],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n    \"],[1,[21,\"firstCopy\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[9],[0,\"\\n    \"],[1,[21,\"secondCopy\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"thirdCopy\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\"\\n      \"],[1,[21,\"thirdCopy\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"warning\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"fl--mb--1\"],[9],[0,\"\\n    \"],[1,[27,\"for-reuse/alert\",null,[[\"severity\",\"header\",\"message\"],[[23,[\"warningSeverity\"]],[23,[\"warningHeader\"]],[23,[\"warningMessage\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"hasSuggestedActions\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"for-reuse/suggested-actions-set\",null,[[\"suggestedActionsSet\"],[[23,[\"suggestedActionsSet\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"button\"],[11,\"class\",\"fl-btn fl-btn--brand\"],[12,\"disabled\",[21,\"isLoading\"]],[3,\"action\",[[22,0,[]],[23,[\"buttonAction\"]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[0,\"      Loading...\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"buttonText\"]]],null,{\"statements\":[[0,\"      \"],[1,[21,\"buttonText\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      Unlock Workspace\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/upsells/project-unlock.hbs"
    }
  });

  _exports.default = _default;
});