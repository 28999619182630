define("funnelytics/components/planning/notes-editor", ["exports", "lodash", "@funnelytics/utilities"], function (_exports, _lodash, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Permissions = _utilities.constants.Permissions;
  var PLACEHOLDER_QUOTES = ['“By failing to prepare, you are preparing to fail.” ― Benjamin Franklin', '“A man who does not plan long ahead will find trouble at his door.” ― Confucius', '“The more time you spend contemplating what you should have done… you lose valuable time planning what you can and will do.” ― Lil Wayne', '“It takes as much energy to wish as it does to plan.” ― Eleanor Roosevelt', '“Good fortune is what happens when opportunity meets with planning.” ― Thomas Edison', '“Before anything else, preparation is the key to success.” ― Alexander Graham Bell', '"Plan your work and work your plan - Napoleon Hill"'];

  var _default = Ember.Component.extend({
    userPermissions: Ember.inject.service(),
    canvasService: Ember.inject.service(),
    quill: null,
    watchStepChange: Ember.observer('model', function () {
      this.get('quill').setContents(this.get('model').attr('planningNotes'));
    }),
    initializeQuill: function initializeQuill() {
      var _this = this;

      if (this.get('isFunnel')) {
        this.get('userPermissions').hasPermission({
          permission: 'feature.starter',
          scope: 'funnel',
          scopeId: this.get('funnel.id'),
          accessLevel: Permissions.ACCESS_LEVEL_WRITE
        }).then(function (writeAccessPermitted) {
          _this._initializeQuill(writeAccessPermitted);
        });
        return;
      }

      this._initializeQuill(true);
    },
    _initializeQuill: function _initializeQuill(writeAccessPermitted) {
      var _this2 = this;

      this.set('quill', new Quill('#quill-area', {
        modules: {
          toolbar: {
            container: [[{
              header: [1, 2, false]
            }, 'bold', 'italic', 'underline'], ['image', 'link', 'video'], [{
              align: ['', 'center', 'right']
            }], [{
              list: 'ordered'
            }, {
              list: 'bullet'
            }, {
              list: 'check'
            }] // ['expandSidebar']
            ],
            handlers: {
              image: function image() {
                var cursorPoint = 0;

                var range = _this2.get('quill').getSelection();

                if (range) {
                  cursorPoint = range.index;
                }

                var href = prompt('Enter the URL of the image');

                _this2.get('quill').insertEmbed(cursorPoint, 'image', href);
              }
            }
          }
        },
        placeholder: PLACEHOLDER_QUOTES[Math.floor(Math.random() * 7)],
        theme: 'snow',
        readOnly: !writeAccessPermitted
      }));

      if (this.get('model')) {
        // FOR A CANVAS ELEMENT
        this.get('quill').setContents(this.get('model').attr('planningNotes'));

        var oldNotes = _lodash.default.get(this.get('model'), 'attributes.attrs.oldNoteStorage', null);

        if (oldNotes && this.get('quill').getLength() < 2) {
          this.get('quill').setText(oldNotes);
          this.get('model').removeAttr('notes');
          this.get('model').removeAttr('oldNoteStorage');
          this.get('model').attr('planningNotes', this.get('quill').getContents());
        }

        this.get('quill').on('editor-change', function () {
          _this2.get('model').attr('planningNotes', _this2.get('quill').getContents());
        });
        return;
      } // WHOLE FUNNEL NOTES


      this.get('quill').setContents(this.get('canvasService').getPaper().getNotes());
      this.get('quill').on('editor-change', function () {
        _this2.get('canvasService').getPaper().setNotes(_this2.get('quill').getContents());
      });
    },
    didInsertElement: function didInsertElement() {
      this.initializeQuill();
    }
  });

  _exports.default = _default;
});