define("funnelytics/custom/upgrade/SuggestedAction", ["exports", "@funnelytics/utilities", "lodash", "funnelytics/custom/upgrade/PrivateUpgradeConstants"], function (_exports, _utilities, _lodash, _PrivateUpgradeConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SuggestedAction = Ember.Object.extend({
    hasPath: Ember.computed('_path', function () {
      return this.get('_path') !== null;
    }),
    path: Ember.computed.readOnly('_path'),
    callToAction: Ember.computed.readOnly('_cta'),
    isDisabled: Ember.computed.bool('_isDisabled'),
    _isDisabled: false,
    _handledAction: function _handledAction() {
      return Ember.RSVP.Promise.resolve(true);
    },
    setCallToAction: function setCallToAction(cta) {
      _utilities.Assertion.string(cta, {
        allowEmpty: false
      });

      this.set('_cta', cta);
    },
    setPath: function setPath(path) {
      _utilities.Assertion.string(path, {
        allowEmpty: false,
        allowNull: true
      });

      this.set('_path', path);
    },
    setName: function setName(name) {
      _utilities.Assertion.string(name, {
        allowEmpty: false
      });

      this.set('_name', name);
    },
    setHandledAction: function setHandledAction(handledAction) {
      _utilities.Assertion.function(handledAction);

      this.set('_handledAction', handledAction);
    },
    setIsDisabled: function setIsDisabled(isDisabled) {
      _utilities.Assertion.boolean(isDisabled);

      this.set('_isDisabled', isDisabled);
    },
    getCallToAction: function getCallToAction() {
      return this.get('_cta');
    },
    getPath: function getPath() {
      return this.get('_path');
    },
    getName: function getName() {
      return this.get('_name');
    },
    performHandledAction: function performHandledAction() {
      return this.get('_handledAction')();
    }
  });
  SuggestedAction.reopenClass({
    createAction: function createAction(options) {
      var cta = _lodash.default.get(options, [_PrivateUpgradeConstants.default.CTA]);

      var path = _lodash.default.get(options, [_PrivateUpgradeConstants.default.PATH], null);

      var name = _lodash.default.get(options, [_PrivateUpgradeConstants.default.NAME], null);

      var suggestedAction = SuggestedAction.create();
      suggestedAction.setCallToAction(cta);
      suggestedAction.setPath(path);
      suggestedAction.setName(name);
      return suggestedAction;
    }
  });
  var _default = SuggestedAction;
  _exports.default = _default;
});