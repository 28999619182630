define("funnelytics/components/funnel-pages/upsell", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    upsellError: false,
    upgradeButtonDisabled: false,
    didInsertElement: function didInsertElement() {
      window.scrollTo(0, 0);
    },
    actions: {
      downsellTrigger: function downsellTrigger() {
        if (this.get('downsellRoute')) {
          this.get('router').transitionTo('order.confirmation');
        } else {
          this.get('router').transitionTo('dashboard.yearly.downsell');
        }
      },
      upsellTrigger: function upsellTrigger() {
        var _this = this;

        if (this.get('upsellError')) {
          this.get('router').transitionTo('order.confirmation');
          return;
        }

        this.set('upgradeButtonDisabled', true);
        this.get('ajax').request('/purchases/create', {
          method: 'POST',
          data: {
            subscriptions: [this.get('upsellProduct.productCode')]
          }
        }).then(function (result) {
          var currentInvoices = localStorage.getItem('currentInvoices').split(',');
          currentInvoices.push(result.invoices);
          localStorage.setItem('currentInvoices', currentInvoices);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
              purchase: {
                actionField: {
                  id: result.invoices,
                  affiliation: _this.get('user.tapfiliate_referrer')
                },
                products: [{
                  name: _this.get('upsellProduct.userFacingName'),
                  id: _this.get('upsellProduct.productCode'),
                  price: _this.get('upsellProduct.cost'),
                  category: _this.get('upsellProduct.type'),
                  variant: 'upsell'
                }]
              }
            },
            user: {
              id: _this.get('user.id'),
              email: _this.get('user.email')
            }
          });

          _this.get('router').transitionTo('order.confirmation');
        }).catch(function (err) {
          _this.set('upsellError', true);

          _this.set('upgradeButtonDisabled', false);

          _this.set('paymentrrorMessage', _lodash.default.get(err, 'payload.error.errors.error._', 'Please Try Again'));

          _this.set('paymentError', true);
        });
      }
    }
  });

  _exports.default = _default;
});