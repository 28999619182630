define("funnelytics/templates/components/right-sidebar/icon-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m62t0j7o",
    "block": "{\"symbols\":[\"icon\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"\\n    right-icon-section \\n    \",[27,\"if\",[[23,[\"eventSection\"]],\"right-icon-section--event-icons\",\"\"],null],\" \\n  \"]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"icons\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[\"right-icon-section__icon \",[27,\"if\",[[23,[\"eventSection\"]],\"right-icon-section__icon--event-icons\",\"\"],null]]]],[3,\"action\",[[22,0,[]],\"updateIcon\",[22,1,[]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"eventSection\"]]],null,{\"statements\":[[0,\"        \"],[7,\"img\"],[11,\"class\",\"right-icon-section__image\"],[12,\"src\",[22,1,[\"path\"]]],[9],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"right-icon-section__icon-name right-icon-section__icon-name--event-icons \"],[9],[0,\"\\n          \"],[1,[22,1,[\"name\"]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"right-icon-section__icon-name\"],[9],[0,\"\\n          \"],[1,[22,1,[\"name\"]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"img\"],[11,\"class\",\"right-icon-section__image\"],[12,\"src\",[22,1,[\"path\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/right-sidebar/icon-section.hbs"
    }
  });

  _exports.default = _default;
});