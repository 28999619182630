define("funnelytics/components/canvas-elements/analytics-loading-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_DOTS = 4;

  var _default = Ember.Component.extend({
    shownLocations: [],
    renderedDots: Ember.computed('loadingDotCount', function () {
      var dotString = '';
      var currentCount = parseInt(this.get('loadingDotCount'), 10) || 1;

      for (var index = 0; index < currentCount; index++) {
        dotString = "".concat(dotString, ".");
      }

      return dotString;
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('loadingDotCount', 1);
      var interval = setInterval(function () {
        var currentCount = _this.get('loadingDotCount');

        var isStartingOver = currentCount >= MAX_DOTS;

        if (isStartingOver) {
          _this.set('loadingDotCount', 1);

          return;
        }

        _this.set('loadingDotCount', currentCount + 1);
      }, 500);
      this.set('interval', interval);
    },
    willDestroyElement: function willDestroyElement() {
      clearInterval(this.get('interval'));
    }
  });

  _exports.default = _default;
});