define("funnelytics/components/canvas-elements/commerce-skus/option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    value: Ember.computed('overrideValue', 'sku', function () {
      if (this.get('overrideValue')) {
        return this.get('overrideValue');
      }

      return this.get('sku');
    }),
    isSelected: Ember.computed('overrideSelected', 'sku', 'currentSku', function () {
      if (this.get('overrideSelected')) {
        return this.get('overrideSelected');
      }

      return this.get('sku') === this.get('currentSku');
    })
  });

  _exports.default = _default;
});