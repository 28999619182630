define("funnelytics/services/param-based-redirect", ["exports", "funnelytics/custom/constants/Redirect"], function (_exports, _Redirect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var urlHasParamsRegex = /[?]/;
  var appIsValidRegex = /^[a-zA-Z0-9.-]*$/;
  var hasHttpsRegex = /(https:\/\/)/;
  var REDIRECT_TYPE = {
    TRANSITION_TO: 'TRANSITION_TO',
    URL_REDIRECT: 'URL_REDIRECT'
  };
  var URL_WHITELIST = ['canvas.funnelytics.io', 'app.funnelytics.io', 'hub.funnelytics.io', 'funnelytics.io', 'help.funnelytics.io'];

  var _default = Ember.Service.extend({
    router: Ember.inject.service(),
    redirectProperties: null,
    exclusionParams: [],
    customParams: {},
    error: null,
    setRedirectParams: function setRedirectParams() {
      var isRegisterRoute = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

      /**
       * Dynamically get and set all query params.
       * This should be called in the model(routes) to get all query params dynamically. If we set in controller,
       * only declared query params will be available. Sets redirectProperties.
       */
      try {
        var fullQueryParams = _objectSpread({}, this.get('router._router.targetState.routerJsState.queryParams'));
        /**
         * After register, if no params present, redirect to pricing table
         */


        if (isRegisterRoute && (!fullQueryParams.performance || !fullQueryParams.next)) {
          fullQueryParams.url = _Redirect.default.pricingTableLink;
        }

        var redirectType = this._getRedirectType(fullQueryParams);

        if (!redirectType) {
          this.set('redirectProperties', null);
          return;
        }

        var redirectProps = {
          exclusionParamsList: [],
          customParams: {},
          params: fullQueryParams,
          redirectType: redirectType
        };

        this._applyCustomRedirectURL(redirectProps, fullQueryParams, isRegisterRoute);

        this.set('redirectProperties', redirectType ? redirectProps : null);
      } catch (err) {
        console.log(err);
      }
    },
    redirect: function redirect() {
      var customParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      /**
       * Redirect Or TransitionTo based on redirectProperties values by setRedirectParams
       * @params customParams {Object} additional params hash key value to be included
       */
      // Need to add additional code for transitionTo
      try {
        var redirectProps = this.get('redirectProperties');

        var mergedCustomParams = _objectSpread({}, redirectProps.customParams, {}, customParams);

        var preparedParams = this.allQueryParams(redirectProps.params, redirectProps.params, redirectProps.exclusionParamsList, mergedCustomParams).dict;

        if (redirectProps.redirectType === REDIRECT_TYPE.TRANSITION_TO) {
          var redirectContent = this._paramRedirect(preparedParams);

          if (!redirectContent) return;
          return this.get('router').transitionTo(redirectContent.routing);
        }

        if (redirectProps.redirectType === REDIRECT_TYPE.URL_REDIRECT) {
          var _redirectContent = this._paramRedirect(preparedParams);

          if (!_redirectContent) return;
          return window.location.replace(_redirectContent);
        }

        return false;
      } catch (e) {
        console.log(e);
      }
    },
    allQueryParams: function allQueryParams(self) {
      var queryParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
      var exclusionQueryParamsList = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
      var customParams = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      /**
       * Takes the query parameters and custom query parameters to create a complete query parameter object
       * which contains the key value or parameter string.
       * @params self {Object}
       * @params queryParams {Object} queryParams Array or Object of params (ie Controller or Route queryparams)
       * @params exclusionQueryParamsList {Array} - list of queryParams to exclude
       * @returns {Object: dict:{Object}, paramString:{String}}
       */
      var defaultResp = {
        dict: {},
        paramString: ''
      };

      var filterParams = function filterParams(params, excludedParams) {
        return params.filter(function (el) {
          return !excludedParams.includes(el);
        });
      };

      try {
        var paramsToExclude = exclusionQueryParamsList || []; //check typeof for queryParams

        var paramsIsArray = Array.isArray(queryParams);
        var paramsIsObject = _typeof(queryParams) === 'object';
        var paramsIsUndefined = typeof queryParams === 'undefined';
        if (paramsIsUndefined) return;
        var paramsArr;

        if (paramsIsObject && !paramsIsArray) {
          paramsArr = Object.keys(queryParams);
        } else if (paramsIsArray) {
          paramsArr = queryParams;
        }

        var filteredParams = filterParams(paramsArr, paramsToExclude);

        var resp = _objectSpread({}, defaultResp);

        filteredParams.forEach(function (el) {
          if (!self[el]) return;
          resp.dict[el] = self[el];
        });
        resp.dict = _objectSpread({}, resp.dict, {}, customParams);
        resp.paramString = Ember.$.param(resp.dict); //overrides url if customUrl is set

        var customUrl = this.get('redirectProperties.customUrl');

        if (customUrl) {
          resp.dict['url'] = customUrl;
        }

        return resp; //output queryparam string and hash
      } catch (e) {
        console.log(e);
        return defaultResp;
      }
    },
    _paramRedirect: function _paramRedirect() {
      var qParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      try {
        var eligibleNextPages = [_Redirect.default.stripePaymentLinks.discover, _Redirect.default.stripePaymentLinks['interactive-demo']]; // redirect to stripe payment link

        if (qParams.next && qParams.url && (qParams.url.indexOf('buy.stripe.com') !== -1 || eligibleNextPages.includes(qParams.url))) {
          var url = qParams.url,
              client_reference_id = qParams.aID,
              prefilled_email = qParams.cea;
          return this._createUrlRedirect({
            url: url,
            client_reference_id: client_reference_id,
            prefilled_email: prefilled_email
          });
        }

        if (qParams.url) {
          //whitelist check
          if (!this._whitelistedUrl(qParams)) return false;
          return this._createUrlRedirect(qParams);
        }

        if (qParams.app) {
          return this._createAppRedirect(qParams);
        }

        return false;
      } catch (e) {
        console.log(e);
      }
    },
    _createAppRedirect: function _createAppRedirect(qParams) {
      /**
       * @params {Object} qParams
       * @returns {hash}
       *  routing: {string} Ember transitionTo valid.
       *  qParams: {hash} param key value pair.
       * @error {bool} false
       */
      try {
        if (!appIsValidRegex.test(qParams.app)) return false;

        var params = _objectSpread({}, qParams);

        var routing = params.app;
        delete params.app;
        return {
          routing: routing,
          qParams: params
        };
      } catch (e) {
        console.log(e);
      }
    },
    _createUrlRedirect: function _createUrlRedirect(qParams) {
      /**
       * @params {hash} qParams
       * @returns {string} url
       * @error {bool} false
       */
      try {
        var params = _objectSpread({}, qParams);

        var urlParam = qParams.url;
        delete params.url;
        var paramsStr = Ember.$.param(params);
        var sep = urlHasParamsRegex.test(urlParam) ? '&' : '?';
        var url = "".concat(urlParam).concat(sep).concat(paramsStr);

        if (!hasHttpsRegex.test(url)) {
          url = 'https://' + url;
        }

        return url;
      } catch (e) {
        console.log(e);
      }
    },
    _whitelistedUrl: function _whitelistedUrl(qParams) {
      if (qParams['customUrl'] && !qParams.url) return true;
      return !!URL_WHITELIST.find(function (url) {
        return qParams.url.includes(url);
      });
    },
    _getRedirectType: function _getRedirectType(queryParams) {
      var app = queryParams.app,
          next = queryParams.next,
          performance = queryParams.performance,
          redirectTo = queryParams.redirectTo,
          url = queryParams.url,
          ws = queryParams.ws;

      if (performance || next || (url || redirectTo) && !app) {
        return REDIRECT_TYPE.URL_REDIRECT;
      }

      if (app && !url || ws) {
        return REDIRECT_TYPE.TRANSITION_TO;
      }

      return null;
    },
    _applyCustomRedirectURL: function _applyCustomRedirectURL(redirectProps, queryParams, isRegisterRoute) {
      var performance = queryParams.performance,
          next = queryParams.next;

      if (redirectProps.redirectType !== REDIRECT_TYPE.URL_REDIRECT || !(performance || next)) {
        return;
      }

      redirectProps.customUrl = performance ? _Redirect.default[performance] : _Redirect.default.stripePaymentLinks[next];
    }
  });

  _exports.default = _default;
});