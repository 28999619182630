define("funnelytics/templates/components/account/registration/user-type-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "84PdApl7",
    "block": "{\"symbols\":[\"type\"],\"statements\":[[7,\"div\"],[11,\"class\",\"user-type-selector\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"types\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[27,\"if-else\",[[27,\"eq\",[[22,1,[]],[23,[\"selected\"]]],null],\"option active\",\"option\"],null]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"setActive\",[22,1,[]]],null]],[9],[0,\"\\n      \"],[1,[22,1,[]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/account/registration/user-type-selector.hbs"
    }
  });

  _exports.default = _default;
});