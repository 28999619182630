define("funnelytics/transforms/payment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    serialize: function serialize(deserialized) {
      var payment = isJsonString(deserialized) ? deserialized : JSON.stringify(deserialized);
      return payment;
    },
    deserialize: function deserialize(serialized) {
      // var markdownOptions = options.payment || {};
      var payment = isJsonString(serialized) ? JSON.parse(serialized) : serialized;
      return payment;
    }
  });

  _exports.default = _default;

  var isJsonString = function isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }

    return true;
  };
});