define("funnelytics/templates/components/subscriptions/cancel-subscription", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b9Aiuuvt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"routable-modal--content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"routable-modal--header fl-header-borderless\"],[9],[0,\"\\n\"],[0,\"    \"],[7,\"div\"],[11,\"class\",\"routable-modal--close fl-close-hang-right\"],[3,\"action\",[[22,0,[]],\"closeModal\"]],[9],[0,\"X\"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"routable-modal--body fl-center-iframe\"],[9],[0,\"\\n    \"],[7,\"iframe\"],[12,\"src\",[23,[\"model\",\"link\"]]],[11,\"title\",\"Survey\"],[11,\"class\",\"fl-cancelsub-iframe\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/subscriptions/cancel-subscription.hbs"
    }
  });

  _exports.default = _default;
});