define("funnelytics/services/funnelytics-segmentation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    currentlySegmenting: false,
    setIsSegmenting: function setIsSegmenting(bool) {
      this.set('currentlySegmenting', bool);
    },
    isSegmenting: function isSegmenting() {
      return this.get('currentlySegmenting');
    }
  });

  _exports.default = _default;
});