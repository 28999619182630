define("funnelytics/components/canvas-elements/forecasting-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    colourWatcher: Ember.observer('color', function () {
      this.send('applyColour');
    }),
    didInsertElement: function didInsertElement() {
      this.send('applyColour');
    },
    actions: {
      applyColour: function applyColour() {
        if (this.get('color') === 'green') {
          this.set('cardColour', 'data-card--green');
        } else if (this.get('color') === 'red') {
          this.set('cardColour', 'data-card--red');
        } else {
          this.set('cardColour', '');
        }
      }
    }
  });

  _exports.default = _default;
});