define("funnelytics/components/canvas-elements/entity-toolbar/old-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    canvasService: Ember.inject.service(),
    onClickConvert: function onClickConvert() {},
    hasEditPermission: Ember.computed.readOnly('canvasService.hasEditPermission'),
    actions: {
      handleConvert: function handleConvert() {
        var handler = this.get('onClickConvert');
        handler();
      }
    }
  });

  _exports.default = _default;
});