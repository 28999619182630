define("funnelytics/mixins/stripe-setup-links", ["exports", "funnelytics/custom/constants/MembershipConstants"], function (_exports, _MembershipConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    projectPermissions: Ember.inject.service(),
    stripeSetupLink: function stripeSetupLink(membership, time) {
      var isTrial = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

      try {
        var trialUpgradeLink = _MembershipConstants.StripeUpgradeLinks[_MembershipConstants.TrialMembershipNamesMapping[membership]];
        var upgradeLink = isTrial && trialUpgradeLink ? trialUpgradeLink : _MembershipConstants.StripeUpgradeLinks[membership];

        if (!upgradeLink) {
          throw new Error('Invalid membership parameter');
        }

        var userEmail = this.get('projectPermissions.user.email');
        var userId = this.get('projectPermissions.user.id');
        return "".concat(upgradeLink, "?client_reference_id=").concat(userId, "&prefilled_email=").concat(userEmail);
      } catch (e) {
        console.error(e);
      }
    }
  });

  _exports.default = _default;
});