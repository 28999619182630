define("funnelytics/models/tracker-event", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    attrs: _emberData.default.hasMany('tracker-event-attribute'),
    step: _emberData.default.belongsTo('tracker-step'),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date')
  });

  _exports.default = _default;
});