define("funnelytics/templates/components/dashboard-elements/splash-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IXG3u5ar",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"splash-card\"],[9],[0,\"\\n\"],[0,\"    \"],[7,\"img\"],[12,\"src\",[28,[[21,\"image\"]]]],[11,\"class\",\"splash-card__video\"],[9],[10],[0,\"\\n\"],[0,\"  \"],[7,\"h5\"],[11,\"class\",\"splash-card__title\"],[9],[0,\"\\n    \"],[1,[21,\"title\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"splash-card__description\"],[9],[0,\"\\n    \"],[1,[21,\"description\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"callToAction\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"href\",\"#\"],[11,\"class\",\"splash-card__cta\"],[3,\"action\",[[22,0,[]],[23,[\"setModalType\"]],[23,[\"openModalType\"]]]],[9],[0,\"\\n      \"],[1,[27,\"svg-display\",null,[[\"symbolDefId\"],[\"play-icon\"]]],false],[0,\"\\n      \"],[7,\"span\"],[9],[1,[21,\"callToAction\"],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/dashboard-elements/splash-card.hbs"
    }
  });

  _exports.default = _default;
});