define("funnelytics/components/vault/vault-card", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onMouseOver: function onMouseOver() {},
    onMouseLeave: function onMouseLeave() {},
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        cardId: (0, _emberUuid.v4)()
      });
    },
    didInsertElement: function didInsertElement() {
      var mouseOverHandler = this.get('onMouseOver');
      var mouseLeaveHandler = this.get('onMouseLeave');
      this.$("#".concat(this.get('cardId'))).on('mouseenter', function () {
        mouseOverHandler();
      });
      this.$("#".concat(this.get('cardId'))).on('mouseleave', function () {
        mouseLeaveHandler();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$("#".concat(this.get('modalId'))).off('mouseenter mouseleave');
    }
  });

  _exports.default = _default;
});