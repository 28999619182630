define("funnelytics/templates/components/canvas-elements/context-menu/tracking-settings/advanced-actions/metric", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/+7yu9M7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"fl-flex-center\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"class\",\"switch switch-sm fl--mr--05\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"toggle\"],null]],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,[\"isSelected\"]]]]],false],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"fl-slider-round round fl-slider-round-sm fl-slider-green\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"title\"],[9],[1,[21,\"name\"],false],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/context-menu/tracking-settings/advanced-actions/metric.hbs"
    }
  });

  _exports.default = _default;
});