define("funnelytics/components/for-reuse/tool-tip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TOOLTIP_POSITIONS = {
    bottom: 'fl-tooltip__pop-over--bottom',
    left: 'fl-tooltip__pop-over--left',
    top: 'fl-tooltip__pop-over--top'
  };
  var TOOLTIP_CHEVRON = {
    bottom: 'fl-tooltip__chevron--bottom',
    left: 'fl-tooltip__chevron--left',
    top: 'fl-tooltip__chevron--top'
  };

  var _default = Ember.Component.extend({
    classNameBindings: ['isSmall:fl-tooltip--small'],
    isSmall: Ember.computed('size', function () {
      return this.get('size') === 'small';
    }),
    tooltipChevron: Ember.computed('position', function () {
      var position = this.get('position');
      return position && TOOLTIP_CHEVRON[position] || '';
    }),
    toolTipPosition: Ember.computed('position', function () {
      var position = this.get('position');
      return position && TOOLTIP_POSITIONS[position] || '';
    }),
    showToolTipObserver: Ember.observer('dismissableToolTip', function () {
      if (this.get('dismissableToolTip')) {
        this.set('showToolTip', true);
        this.set('dismissButton', true);
      }
    }),
    actions: {
      closeToolTip: function closeToolTip() {
        var _this = this;

        this.set('showToolTip', false);
        this.set('dismissButton', false);
        this.set('forceToolTipClose', true);
        setTimeout(function () {
          _this.set('forceToolTipClose');
        }, 500);
      }
    }
  });

  _exports.default = _default;
});