define("funnelytics/templates/components/sales-funnels/upsell-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P/patUF6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"for-reuse/card\",null,[[\"content\"],[true]],{\"statements\":[[7,\"div\"],[11,\"class\",\"member-bonus__card\"],[9],[0,\"\\n  \"],[7,\"img\"],[12,\"src\",[21,\"image\"]],[11,\"class\",\"member-bonus__img\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"bonus-content\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"upsell-header\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"member-bonus__header\"],[9],[0,\"\\n        \"],[1,[21,\"header\"],false],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"txt--blue\"],[9],[0,\"\\n          \"],[1,[21,\"price\"],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[21,\"firstTextBlock\"],false],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[1,[21,\"secondTextBlock\"],false],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"conditions\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"bonus-content--fine-print\"],[9],[0,\"\\n      \"],[7,\"b\"],[9],[0,\"\\n        \"],[1,[21,\"conditions\"],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/sales-funnels/upsell-card.hbs"
    }
  });

  _exports.default = _default;
});