define("funnelytics/components/tags/input-tag", ["exports", "funnelytics/helpers/text-truncate"], function (_exports, _textTruncate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var maxCharacters = this.maxTagCharacters || 25;
      this.set('shortenedText', (0, _textTruncate.textTruncate)({
        text: this.text,
        truncateLength: maxCharacters,
        appendText: '...'
      })); //If tags are in a card, they shouldn't do hover things

      var noHover = this.mode === 'card';
      this.set('noHover', noHover);
    },
    actions: {
      onClick: function onClick(e) {
        if (!this.onClick) return;
        return this.onClick(e);
      },
      onClose: function onClose(e) {
        return this.onClose(e);
      }
    }
  });

  _exports.default = _default;
});