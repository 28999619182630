define("funnelytics/components/account/invoice-summary", ["exports", "funnelytics/custom/commerce/RecurlyHelpers"], function (_exports, _RecurlyHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    invoiceId: Ember.computed.readOnly('invoice.external_id'),
    invoicePdfUrl: Ember.computed('invoiceId', function () {
      return _RecurlyHelpers.default.getInvoicePdfUrl(this.get('invoiceId'));
    }),
    onClickDownloadPdf: function onClickDownloadPdf() {},
    actions: {
      openBill: function openBill() {
        this.openBill();
      },
      downloadInvoice: function downloadInvoice() {
        var clickHandler = this.get('onClickDownloadPdf');
        return clickHandler(this.get('invoiceId'));
      }
    }
  });

  _exports.default = _default;
});