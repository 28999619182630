define("funnelytics/routes/order/vault/upsell", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    upsellProduct: {
      type: 'subscription',
      userFacingName: 'Pro',
      productCode: 'pro_yearly_upsell',
      cost: null,
      periodOfTime: 'year'
    },
    setupController: function setupController(controller, model) {
      var _this = this;

      this.get('ajax').request('/purchases/information', {
        method: 'GET',
        data: {
          plans: this.get('upsellProduct.productCode')
        }
      }).then(function (result) {
        _this.set('upsellProduct.cost', _lodash.default.get(result, ['plans', _this.get('upsellProduct.productCode'), 'periodPriceInCents']) / 100);
      });
      controller.set('upsellProduct', this.get('upsellProduct'));
      controller.set('downsellRoute', 'order.confirmation');
      controller.set('user', this.controllerFor('order').get('user'));

      this._super(controller, model);
    },
    redirect: function redirect() {
      this.transitionTo('order.vault.index');
    }
  });

  _exports.default = _default;
});