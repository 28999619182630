define("funnelytics/components/account/segmentation-survey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SURVEY_STEPS = [{
    id: 'business',
    question: 'Which of these best describes your business?',
    type: 'select',
    placeholder: 'Select an industry',
    options: [{
      title: 'Affiliate Marketing',
      code: 'affiliate',
      hubspotValue: 'affiliate_marketing'
    }, {
      title: 'Coaching / Consulting ',
      code: 'coaching',
      hubspotValue: 'coaching_consulting'
    }, {
      title: 'Courses / Info Products',
      code: 'courses',
      hubspotValue: 'courses_info_products'
    }, {
      title: 'E-Commerce',
      code: 'ecommerce',
      hubspotValue: 'ecommerce'
    }, {
      title: 'Local Business',
      code: 'localBusiness',
      hubspotValue: 'local_business'
    }, {
      title: 'Marketing / Advertising Services',
      code: 'marketingServices',
      hubspotValue: 'marketing_services'
    }, {
      title: 'SaaS',
      code: 'saas',
      hubspotValue: 'saas'
    }, {
      title: 'Finance',
      code: 'finance',
      hubspotValue: 'finance'
    }, {
      title: 'Real Estate',
      code: 'realEstate',
      hubspotValue: 'real_estate'
    }, {
      title: 'Travel',
      code: 'travel',
      hubspotValue: 'travel'
    }, {
      title: 'Other',
      code: 'other',
      hubspotValue: 'other'
    }],
    additional: {
      question: "Please tell us which industry you're in",
      placeholder: 'Industry name'
    },
    next: 'department'
  }, {
    id: 'department',
    question: 'What department are you in?',
    type: 'chips',
    options: [{
      title: 'Administration / Operations',
      code: 'administration',
      hubspotValue: 'administration_operations'
    }, {
      title: 'Customer Service / Support',
      code: 'customerService',
      hubspotValue: 'service_support'
    }, {
      title: 'Marketing',
      code: 'marketing',
      hubspotValue: 'marketing'
    }, {
      title: 'Sales',
      code: 'sales',
      hubspotValue: 'sales'
    }, {
      title: 'Other',
      code: 'other',
      hubspotValue: 'other'
    }],
    additional: {
      question: "Please tell us which department you're in",
      placeholder: 'Department name'
    },
    next: {
      administration: {
        id: 'role',
        options: [{
          title: 'Administration',
          code: 'administration',
          hubspotValue: 'administration'
        }, {
          title: 'CEO / Founder',
          code: 'ceoFounder',
          hubspotValue: 'ceo_founder'
        }, {
          title: 'Marketing Ops',
          code: 'marketingOps',
          hubspotValue: 'marketing_ops'
        }, {
          title: 'Operations',
          code: 'operations',
          hubspotValue: 'operations'
        }, {
          title: 'Revenue Ops',
          code: 'revenueOps',
          hubspotValue: 'revenue_ops'
        }, {
          title: 'Sales Ops',
          code: 'salesOps',
          hubspotValue: 'sales_ops'
        }, {
          title: 'Other',
          code: 'other',
          hubspotValue: 'other'
        }]
      },
      customerService: {
        id: 'role',
        options: [{
          title: 'Account Manager',
          code: 'accountManager',
          hubspotValue: 'account_manager'
        }, {
          title: 'Administration',
          code: 'administration',
          hubspotValue: 'administration'
        }, {
          title: 'Customer Service Rep',
          code: 'customerServiceRep',
          hubspotValue: 'customer_service_rep'
        }, {
          title: 'Director / Manager',
          code: 'directorManager',
          hubspotValue: 'director'
        }, {
          title: 'Operations',
          code: 'operations',
          hubspotValue: 'operations'
        }, {
          title: 'Project Manager',
          code: 'projectManager',
          hubspotValue: 'project_manager'
        }, {
          title: 'Support Agent',
          code: 'supportAgent',
          hubspotValue: 'support_agent'
        }, {
          title: 'Other',
          code: 'other',
          hubspotValue: 'other'
        }]
      },
      marketing: {
        id: 'role',
        options: [{
          title: 'Account Manager',
          code: 'accountManager',
          hubspotValue: 'account_manager'
        }, {
          title: 'Copywriter',
          code: 'copywriter',
          hubspotValue: 'copywriter'
        }, {
          title: 'Data Analyst',
          code: 'dataAnalyst',
          hubspotValue: 'data_analyst'
        }, {
          title: 'Designer / Developer',
          code: 'designerDeveloper',
          hubspotValue: 'designer_developer'
        }, {
          title: 'Director / Manager',
          code: 'directorManager',
          hubspotValue: 'director'
        }, {
          title: 'Marketing / Content Creator',
          code: 'marketingContentCreator',
          hubspotValue: 'marketing'
        }, {
          title: 'Social Advertising / PPC',
          code: 'socialAdvertisingPPC',
          hubspotValue: 'media_buyer'
        }, {
          title: 'Strategist',
          code: 'strategist',
          hubspotValue: 'strategist'
        }, {
          title: 'Tech / Automation',
          code: 'techAutomation',
          hubspotValue: 'tech_automation'
        }, {
          title: 'Other',
          code: 'other',
          hubspotValue: 'other'
        }]
      },
      sales: {
        id: 'role',
        options: [{
          title: 'Director / Manager',
          code: 'directorManager',
          hubspotValue: 'director'
        }, {
          title: 'Sales Team Lead',
          code: 'salesTeamLead',
          hubspotValue: 'sales_team_lead'
        }, {
          title: 'Salesperson',
          code: 'salesperson',
          hubspotValue: 'salesperson'
        }, {
          title: 'Other',
          code: 'other',
          hubspotValue: 'other'
        }]
      },
      other: {
        id: 'role',
        options: [{
          title: 'Administration',
          code: 'administration',
          hubspotValue: 'administration'
        }, {
          title: 'CEO / Founder',
          code: 'ceoFounder',
          hubspotValue: 'ceo_founder'
        }, {
          title: 'Marketing Ops',
          code: 'marketingOps',
          hubspotValue: 'marketing_ops'
        }, {
          title: 'Operations',
          code: 'operations',
          hubspotValue: 'operations'
        }, {
          title: 'Revenue Ops',
          code: 'revenueOps',
          hubspotValue: 'revenue_ops'
        }, {
          title: 'Sales Ops',
          code: 'salesOps',
          hubspotValue: 'sales_ops'
        }, {
          title: 'Other',
          code: 'other',
          hubspotValue: 'other'
        }]
      },
      default: 'employees'
    }
  }, {
    id: 'role',
    question: 'Which of these best describes your role?',
    placeholder: 'Select a role',
    type: 'select',
    additional: {
      question: 'Please tell us which role you have',
      placeholder: 'Role'
    },
    next: 'employees'
  }, {
    id: 'employees',
    question: 'How many full-time employees work at your company?',
    type: 'select',
    placeholder: 'Select range',
    options: [{
      title: '1',
      code: '1',
      hubspotValue: '1'
    }, {
      title: '2-5',
      code: '2-5',
      hubspotValue: '2-5'
    }, {
      title: '5-10',
      code: '5-10',
      hubspotValue: '5-10'
    }, {
      title: '10-15',
      code: '10-15',
      hubspotValue: '10-15'
    }, {
      title: '15-25',
      code: '15-25',
      hubspotValue: '15-25'
    }, {
      title: '25-50',
      code: '25-50',
      hubspotValue: '25-50'
    }, {
      title: '50-100',
      code: '50-100',
      hubspotValue: '50-100'
    }, {
      title: '100-500',
      code: '100-500',
      hubspotValue: '100-500'
    }, {
      title: '500-1000',
      code: '500-1000',
      hubspotValue: '500-1000'
    }, {
      title: '1000+',
      code: '1000+',
      hubspotValue: '1000+'
    }]
  }];
  var FIRST_STEP = 'business';

  var getNextStepId = function getNextStepId(next, currentAnswer) {
    if (typeof next === 'string') {
      return next;
    }

    if (next[currentAnswer]) {
      return next[currentAnswer].id;
    }

    return typeof next.default === 'string' ? next.default : next.default.id;
  };

  var getNextStepOptions = function getNextStepOptions(next, currentAnswer) {
    if (typeof next === 'string') {
      return null;
    }

    if (next[currentAnswer]) {
      return next[currentAnswer].options || null;
    }

    return typeof next.default === 'string' ? null : next.default.options || null;
  };

  var _default = Ember.Component.extend({
    surveyResult: {},
    currentStep: SURVEY_STEPS.find(function (s) {
      return s.id === FIRST_STEP;
    }),
    allSteps: SURVEY_STEPS,
    hasNextStep: Ember.computed.bool('currentStep.next'),
    otherOptionSelected: Ember.computed.equal('currentAnswer.code', 'other'),
    hasAdditional: Ember.computed.bool('currentStep.additional'),
    showAdditionalDescription: Ember.computed.and('otherOptionSelected', 'hasAdditional'),
    currentAnswer: null,
    currentAnswerAdditionalDescription: '',
    isSubmitting: false,
    isNotSubmitting: Ember.computed.not('isSubmitting'),
    hasAnswer: Ember.computed('currentAnswer', 'showAdditionalDescription', 'currentAnswerAdditionalDescription', function () {
      var currentAnswer = this.get('currentAnswer');
      var showAdditionalDescription = this.get('showAdditionalDescription');
      var currentAnswerAdditionalDescription = this.get('currentAnswerAdditionalDescription');
      return currentAnswer && (!showAdditionalDescription || currentAnswerAdditionalDescription);
    }),
    canSubmit: Ember.computed('hasAnswer', 'isNotSubmitting', function () {
      var hasAnswer = this.get('hasAnswer');
      var isNotSubmitting = this.get('isNotSubmitting');
      return isNotSubmitting && hasAnswer;
    }),
    canGoNext: Ember.computed('hasNextStep', 'hasAnswer', function () {
      var hasAnswer = this.get('hasAnswer');
      var hasNextStep = this.get('hasNextStep');
      return hasNextStep && hasAnswer;
    }),
    submitSurvey: function submitSurvey() {
      var _this = this;

      if (!this.get('canSubmit')) {
        return;
      }

      this.set('isSubmitting', true);
      this.submitSurveyAndTransition(this.get('surveyResult')).then(function () {
        return _this.set('isSubmitting', false);
      });
    },
    saveCurrentStep: function saveCurrentStep() {
      var currentStep = this.get('currentStep');
      var currentAnswer = this.get('currentAnswer');

      if (!currentStep || !currentAnswer) {
        return;
      }

      var answer = {
        hubspotValue: currentAnswer.hubspotValue,
        title: this.get('showAdditionalDescription') ? this.get('currentAnswerAdditionalDescription') : currentAnswer.title
      };
      this.set("surveyResult.".concat(currentStep.id), answer);
    },
    actions: {
      setAnswerForCurrentStep: function setAnswerForCurrentStep(answer) {
        this.set('currentAnswer', answer);
      },
      toNextStep: function toNextStep() {
        var currentStep = this.get('currentStep');
        var currentAnswer = this.get('currentAnswer');

        if (!this.get('canGoNext')) {
          return;
        }

        this.saveCurrentStep();
        var nextStepId = getNextStepId(currentStep.next, currentAnswer.code);
        var nextStepOptions = getNextStepOptions(currentStep.next, currentAnswer.code);
        var nextStep = SURVEY_STEPS.find(function (s) {
          return s.id === nextStepId;
        });
        this.set('currentStep', Object.assign({}, nextStep, nextStepOptions ? {
          options: nextStepOptions
        } : {}));
        this.set('currentAnswer', null);
        this.set('currentAnswerAdditionalDescription', null);
      },
      submitSurvey: function submitSurvey() {
        var canSubmit = this.get('canSubmit');

        if (!canSubmit) {
          return;
        }

        this.saveCurrentStep();
        this.submitSurvey();
      }
    }
  });

  _exports.default = _default;
});