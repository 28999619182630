define("funnelytics/components/canvas-elements/analytics-toolbar/filter-dropdown-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onClick: function onClick() {},
    actions: {
      handleClick: function handleClick() {
        var clickHandler = this.get('onClick');
        clickHandler();
      }
    }
  });

  _exports.default = _default;
});