define("funnelytics/templates/components/containers/funnel-editor/components/right-sidebar-parameters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "seWyjFhj",
    "block": "{\"symbols\":[\"parameter\",\"key\"],\"statements\":[[7,\"div\"],[11,\"class\",\"paramCreator\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"parameters\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"containers/funnel-editor/components/right-sidebar-single-parameter\",null,[[\"model\",\"parameters\",\"parameter\",\"key\",\"onChange\",\"inputFieldChange\",\"inputPasteFieldChange\"],[[23,[\"model\"]],[23,[\"parameters\"]],[22,1,[]],[22,2,[]],[27,\"action\",[[22,0,[]],\"handleChange\"],null],[23,[\"inputFieldChange\"]],[23,[\"inputPasteFieldChange\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[7,\"button\"],[11,\"class\",\"fl-btn bkg--blackgrey\"],[3,\"action\",[[22,0,[]],\"add\"]],[9],[0,\"\\n    + Add Custom Parameter\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/containers/funnel-editor/components/right-sidebar-parameters.hbs"
    }
  });

  _exports.default = _default;
});