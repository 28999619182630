define("funnelytics/components/for-reuse/title-with-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['fl-title-with-icon']
  });

  _exports.default = _default;
});