define("funnelytics/components/for-reuse/suggested-actions-set", ["exports", "ember-uuid", "funnelytics/custom/upgrade/SuggestedActionsSet"], function (_exports, _emberUuid, _SuggestedActionsSet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    hasSuggestedActions: Ember.computed.readOnly('suggestedActionsSet.hasSuggestedActions'),
    suggestedActions: Ember.computed.readOnly('suggestedActionsSet.list'),
    hasMessage: Ember.computed.readOnly('suggestedActionsSet.hasMessage'),
    isMessageTypeWarning: Ember.computed.readOnly('suggestedActionsSet.isMessageTypeWarning'),
    isMessageTypeInfo: Ember.computed.readOnly('suggestedActionsSet.isMessageTypeInfo'),
    messageType: Ember.computed.readOnly('suggestedActionsSet.messageType'),
    messageHeader: Ember.computed('suggestedActionsSet.isMessageTypeWarning', function () {
      if (this.get('suggestedActionsSet.isMessageTypeWarning')) {
        return 'Warning';
      }

      return 'Attention';
    }),
    messageText: Ember.computed.readOnly('suggestedActionsSet.messageText'),
    isConfirmed: Ember.computed.readOnly('suggestedActionsSet.isConfirmed'),
    isNotConfirmed: Ember.computed.not('suggestedActionsSet.isConfirmed'),
    isConfirmationRequired: Ember.computed.readOnly('suggestedActionsSet.isConfirmationRequired'),
    confirmationText: Ember.computed.readOnly('suggestedActionsSet.confirmationText'),
    hasFinePrint: Ember.computed.readOnly('suggestedActionsSet.hasFinePrint'),
    finePrint: Ember.computed.readOnly('suggestedActionsSet.finePrint'),
    isDisplayed: Ember.computed('suggestedActionsSet', function () {
      var set = this.get('suggestedActionsSet');

      if (!(set instanceof _SuggestedActionsSet.default)) {
        return false;
      }

      return true;
    }),
    didInsertElement: function didInsertElement() {
      this.set('uniqueId', (0, _emberUuid.v4)());
    },
    actions: {
      toggleConfirmation: function toggleConfirmation() {
        this.get('suggestedActionsSet').setConfirmed(!this.get('isConfirmed'));
      }
    }
  });

  _exports.default = _default;
});