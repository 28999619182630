define("funnelytics/components/canvas-elements/entity-toolbar/custom-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Actions that need to be passed into the component:
     */
    onClickRotate: function onClickRotate() {},
    onClickReplaceImage: function onClickReplaceImage() {},
    onClickContext: function onClickContext() {},
    actions: {
      clickRotate: function clickRotate() {
        var handleRotate = this.get('onClickRotate');
        handleRotate();
      },
      clickReplaceImage: function clickReplaceImage() {
        var handleReplaceImage = this.get('onClickReplaceImage');
        handleReplaceImage();
      },
      clickContext: function clickContext() {
        var contextHandler = this.get('onClickContext');
        contextHandler();
      }
    }
  });

  _exports.default = _default;
});