define("funnelytics/components/upsells/project-unlock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    upgradeService: Ember.inject.service(),
    isLoading: false,
    suggestedActions: [],
    hasSuggestedActions: false,
    onClickClose: function onClickClose() {},
    actions: {
      close: function close() {
        var closeHandler = this.get('onClickClose');
        closeHandler();
      }
    }
  });

  _exports.default = _default;
});