define("funnelytics/templates/components/tags/input-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+CPNltrT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onClick\"],null]],[12,\"class\",[27,\"if-else\",[[23,[\"noHover\"]],\"tag no-hover\",\"tag\"],null]],[12,\"title\",[21,\"title\"]],[12,\"style\",[28,[[27,\"if\",[[23,[\"fitDiv\"]],\"width: 100%; text-align: center;\"],null],[27,\"if\",[[23,[\"selected\"]],\"color: white; background-color: #009bf3;\"],null]]]],[9],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[27,\"unless\",[[23,[\"onClose\"]],[27,\"if-else\",[[23,[\"noHover\"]],\"tag-body no-hover\",\"tag-body\"],null]],null]],[12,\"title\",[21,\"title\"]],[9],[1,[21,\"shortenedText\"],false],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"onClose\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[12,\"title\",[21,\"title\"]],[11,\"class\",\"tag-close\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onClose\"],null]],[9],[0,\"✕\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/tags/input-tag.hbs"
    }
  });

  _exports.default = _default;
});