define("funnelytics/models/tracker-event-attribute", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    key: _emberData.default.attr('string'),
    value: _emberData.default.attr('string'),
    event: _emberData.default.belongsTo('tracker-event'),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date')
  });

  _exports.default = _default;
});