define("funnelytics/components/for-reuse/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isClosed: Ember.computed.not('isOpen'),
    ariaHidden: Ember.computed('isClosed', function () {
      return this.get('isClosed') ? 'true' : 'false';
    }),
    title: 'Details',
    // default
    onClose: function onClose() {},
    TRANSITION_START_OBSERVER: Ember.observer('isOpen', function () {
      var _this = this;

      this.set('isTransitioning', true);
      setTimeout(function () {
        _this.set('isOpenInternal', _this.get('isOpen'));
      }, 0);
    }),
    onTransitionEnd: function onTransitionEnd() {
      this.set('isTransitioning', false);
    },
    clickOffEventHandler: function clickOffEventHandler() {},
    transitionEndEventHandler: function transitionEndEventHandler() {},
    initializeProperties: function initializeProperties() {
      this.setProperties({
        isTransitioning: false,
        isOpenInternal: false,
        $modalBody: null
      });
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.initializeProperties();
      this.set('transitionEndEventHandler', function () {
        _this2.onTransitionEnd();
      });
      var modalBody = Ember.$(this.get('element')).find('.modal-content').first();
      this.set('$modalBody', modalBody);
      this.set('clickOffEventHandler', function (event) {
        if (_this2.get('isOpen')) {
          var $target = Ember.$(event.target);

          if ($target.closest(_this2.get('$modalBody')).length === 0) {
            if (!_this2.get('isTransitioning')) {
              _this2.handleClose();
            }
          }
        }
      });
      Ember.$('html').on('mousedown', this.get('clickOffEventHandler'));
      this.get('$modalBody').on('transitionend', this.get('transitionEndEventHandler'));
    },
    willDestroyElement: function willDestroyElement() {
      this.get('$modalBody').off('transitionend', this.get('transitionEndEventHandler'));
      Ember.$('html').off('mousedown', this.get('clickOffEventHandler'));
    },
    handleClose: function handleClose() {
      var closeHandler = this.get('onClose');
      closeHandler();
    },
    actions: {
      close: function close() {
        this.handleClose();
      }
    }
  });

  _exports.default = _default;
});