define("funnelytics/components/for-reuse/allowance-progress-bar/funnels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    userPermissions: Ember.inject.service(),
    funnelPermissions: Ember.computed.readOnly('userPermissions.funnelPermissions'),
    areFunnelPermissionsDisplayed: Ember.computed('funnelPermissions.{limit,current}', 'isHiddenBecauseMaxed', function () {
      if (this.get('isHiddenBecauseMaxed')) {
        return false;
      }

      return this.get('funnelPermissions.limit') !== undefined && this.get('funnelPermissions.current') !== undefined;
    }),
    wrapperClasses: '',
    isColourInverted: false,
    isHiddenWhenMaxed: false,
    isAtLimit: Ember.computed('funnelPermissions.{limit,current}', function () {
      return parseInt(this.get('funnelPermissions.current'), 10) >= parseInt(this.get('funnelPermissions.limit'), 10);
    }),
    isHiddenBecauseMaxed: Ember.computed.and('isHiddenWhenMaxed', 'isAtLimit'),
    didInsertElement: function didInsertElement() {
      this.get('userPermissions').clearPermissionCache(); // this.get('userPermissions').loadUpgradeLimits();
    }
  });

  _exports.default = _default;
});