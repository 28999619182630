define("funnelytics/templates/components/dashboard-elements/subscriptions/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VIFBBZFA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isSubscriptionNotFree\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"fl-flex-row card-body-text fl--mt--1\"],[9],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n      \"],[1,[21,\"subscriptionName\"],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"willExpire\"]]],null,{\"statements\":[[0,\"        Expires on \"],[1,[27,\"date-parser\",[[23,[\"termEndDate\"]],\"hide null\",\"YYYY-MM-DD\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        Valid through \"],[1,[27,\"date-parser\",[[23,[\"termEndDate\"]],\"hide null\",\"YYYY-MM-DD\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"and\",[[23,[\"queuedSubscription\"]],[23,[\"isActive\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"fl-flex-row card-body-text fl--mt--1\"],[9],[0,\"\\n      \"],[7,\"div\"],[9],[0,\"\\n        \"],[1,[21,\"newPlanName\"],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[9],[0,\"\\n        Starts on \"],[1,[27,\"date-parser\",[[23,[\"newPlanSwitchDate\"]],\"hide null\",\"YYYY-MM-DD\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/dashboard-elements/subscriptions/view.hbs"
    }
  });

  _exports.default = _default;
});