define("funnelytics/components/billing/stripe-billing-portal", ["exports", "funnelytics/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    loading: true,
    billingPortalLink: null,
    error: false,
    data: null,
    dataObserver: Ember.observer('data', 'error', function () {
      if (this.get('data') || this.get('error')) {
        this.get('data') && this.createPortalLink();
        this.set('loading', false);
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    createPortalLink: function createPortalLink() {
      try {
        var portal = this.data;

        if (!portal || !portal.url) {
          throw new Error('No portal data');
        }

        var popupMech = "".concat(_environment.default.app, "/login?url=").concat(encodeURIComponent(portal.url));
        this.set('billingPortalLink', popupMech);
      } catch (e) {
        this.set('error', true);
      } finally {
        this.set('loading', false);
      }
    }
  });

  _exports.default = _default;
});