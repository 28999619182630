define("funnelytics/components/right-sidebar/icon-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onUpdateIcon: function onUpdateIcon() {},
    actions: {
      updateIcon: function updateIcon() {
        var updateHandler = this.get('onUpdateIcon');
        updateHandler.apply(void 0, arguments);
      }
    }
  });

  _exports.default = _default;
});