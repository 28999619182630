define("funnelytics/helpers/should-show-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.shouldShowDashboard = shouldShowDashboard;
  _exports.default = void 0;

  function shouldShowDashboard(params
  /*, hash*/
  ) {
    var split = params[0].split('.');
    return !(split[1] === 'yearly' && split[2] === 'checkout' || split[1] === 'yearly' && split[2] === 'upsell' || split[1] === 'yearly' && split[2] === 'order-confirmation' || split[1] === 'yearly' && split[2] === 'downsell');
  }

  var _default = Ember.Helper.helper(shouldShowDashboard);

  _exports.default = _default;
});