define("funnelytics/templates/components/containers/content-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JGaQYwaT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"classname\",\"container\"],[11,\"style\",\"\\n            max-width: 1920px; \\n            background-color: #f0f2f7;\\n            position: absolute;\\n            left: 50%;\\n            transform: translate(-50%, 0);\\n            width: 100%;\\n            height: 100%;\\n            overflow: hidden;\\n            border: 1px solid #f0f2f7\"],[9],[0,\"\\n\\n    \"],[14,1],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/containers/content-container.hbs"
    }
  });

  _exports.default = _default;
});