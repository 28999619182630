define("funnelytics/components/account/subscription-billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    isTaxPayer: false,
    willPayTax: Ember.computed.and('isTaxPayer', 'hasNonZeroPrice'),
    // TODO: This will need to be revisted when trying to work with subscriptions that will expire on their own.
    hasNextBill: Ember.computed('userSubscription.{status,remaining_billing_cycles,period_ends_at}', 'hasNonZeroPrice', function () {
      if (!this.get('hasNonZeroPrice')) {
        return false;
      }

      var subscriptionIsActive = this.get('userSubscription.status') === 'active';
      var hasRemainingBillingCycles = this.get('userSubscription.remaining_billing_cycles') > 0;
      var hasPeriodEndsAt = this.get('userSubscription.period_ends_at') !== null;
      return hasPeriodEndsAt && (subscriptionIsActive || hasRemainingBillingCycles);
    }),
    hasAddOns: Ember.computed.notEmpty('userSubscription.add_ons'),
    userSubscriptionAddOns: Ember.computed.readOnly('userSubscription.add_ons'),
    subscriptionPrice: Ember.computed.readOnly('userSubscription.subscriptionPrice'),
    hasNonZeroPrice: Ember.computed.gt('subscriptionPrice', 0)
  });

  _exports.default = _default;
});