define("funnelytics/templates/components/canvas-elements/context-menu/action-attribute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gNisyE8M",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[11,\"class\",\"context-menu__explorer-item context-menu__explorer-item--action-attribute\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"context-menu__explorer-item-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"context-menu__explorer-item-label\"],[9],[0,\"\\n      \"],[7,\"b\"],[9],[1,[21,\"key\"],false],[10],[0,\": \"],[1,[21,\"value\"],false],[0,\" \"],[1,[27,\"unless\",[[23,[\"isContains\"]],\"(must be absent)\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"fl-btn fl-btn--compact fl-btn-grey-light context-menu__explorer-item--delete-button\"],[3,\"action\",[[22,0,[]],\"deleteValue\"]],[9],[0,\"\\n      \"],[7,\"svg\"],[9],[0,\"\\n        \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-minus\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/context-menu/action-attribute.hbs"
    }
  });

  _exports.default = _default;
});