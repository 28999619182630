define("funnelytics/components/canvas-elements/analytics-toolbar/compare-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['analytics-toolbar__compare-dropdown'],
    onSelectDateOption: function onSelectDateOption() {},
    onSelectPeopleOption: function onSelectPeopleOption() {},
    onCancelComparePeopleFilters: function onCancelComparePeopleFilters() {},
    onComparePeople: function onComparePeople() {},
    onSetCountries: function onSetCountries() {},
    onClearStepFocus: function onClearStepFocus() {},
    onFindStep: function onFindStep() {},
    onClearUserFocus: function onClearUserFocus() {},
    onFocusOnEntity: function onFocusOnEntity() {},
    onSelectEntity: function onSelectEntity() {},
    onSetDevice: function onSetDevice() {},
    onFocusOnSession: function onFocusOnSession() {},
    onLoadAdditionalSessions: function onLoadAdditionalSessions() {},
    peopleFiltersOpened: Ember.computed.equal('selectedCompareOption', 'people'),
    actions: {
      selectDateOption: function selectDateOption() {
        var handler = this.get('onSelectDateOption');
        handler();
      },
      selectPeopleOption: function selectPeopleOption() {
        var handler = this.get('onSelectPeopleOption');
        handler();
      },
      cancelComparePeopleFilters: function cancelComparePeopleFilters() {
        var handler = this.get('onCancelComparePeopleFilters');
        handler();
      },
      comparePeople: function comparePeople() {
        var handler = this.get('onComparePeople');
        handler();
      },
      setCountries: function setCountries(countryNames) {
        var handler = this.get('onSetCountries');
        handler(countryNames);
      },
      clearStepFocus: function clearStepFocus() {
        var handler = this.get('onClearStepFocus');
        handler();
      },
      findStep: function findStep() {
        var handler = this.get('onFindStep');
        handler();
      },
      clearUserFocus: function clearUserFocus() {
        var handler = this.get('onClearUserFocus');
        handler();
      },
      focusOnEntity: function focusOnEntity(entity) {
        var handler = this.get('onFocusOnEntity');
        handler(entity);
      },
      selectEntity: function selectEntity(entity) {
        var handler = this.get('onSelectEntity');
        handler(entity);
      },
      setDevice: function setDevice(device) {
        var handler = this.get('onSetDevice');
        handler(device);
      },
      focusOnSession: function focusOnSession(user) {
        var handler = this.get('onFocusOnSession');
        handler(user);
      },
      loadAdditionalSessions: function loadAdditionalSessions() {
        var handler = this.get('onLoadAdditionalSessions');
        handler();
      }
    }
  });

  _exports.default = _default;
});