define("funnelytics/components/right-sidebar/tracking-section/kpi-setter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['kpi-setter'],
    goalManager: Ember.inject.service(),
    canvasService: Ember.inject.service(),
    isInitialized: false,
    isLoadingGoals: true,
    addToKPI: Ember.computed('goals.@each.added_to_kpi', function () {
      return this.get('goals').length > 0 && this.get('goals').every(function (g) {
        return g.get('added_to_kpi');
      });
    }),
    goals: Ember.computed('isLoadingGoals', function () {
      if (!this.get('isLoadingGoals') && this.get('goalManager.elementModel')) {
        return this.get('goalManager.goals');
      } else {
        return [];
      }
    }),
    goalWatcher: Ember.observer('goals.@each.name', 'goals.@each.value', 'goals.@each.type', 'goals.@each.added_to_kpi', function () {
      if (!this.get('isLoadingGoals')) {
        this.get('goalManager').saveGoalsDebounce();
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('isLoadingGoals', true);

      if (!this.get('model')) {
        return;
      }

      this.get('goalManager').resetState(this.get('funnel'), this.get('editing.model')).then(function () {
        if (_this.get('goalManager.goals.length') === 0) {
          var entity = _this.get('canvasService').wrapEntity(_this.get('goalManager.elementModel'));

          _this.get('goalManager').addGoal({
            name: entity.getLabel(),
            type: 'Sale',
            added_to_kpi: false
          });
        }

        _this.set('isLoadingGoals', false);
      });
    },
    actions: {
      toggleAddToKPI: function toggleAddToKPI() {
        var _this2 = this;

        if (this.get('isLoadingGoals')) {
          return;
        }

        this.get('goals').forEach(function (goal) {
          goal.set('added_to_kpi', !_this2.get('addToKPI'));
        });
      },
      setType: function setType(kpi, thisArgumentIsLiterallyNothing, evt) {
        var type = evt.target.value;
        kpi.set('type', type);
      }
    }
  });

  _exports.default = _default;
});