define("funnelytics/services/ajax", ["exports", "funnelytics/config/environment", "ember-ajax/services/ajax"], function (_exports, _environment, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service(),
    host: _environment.default.endpoint,
    trustedHosts: ['api.funnelytics.io', 'analytics.funnelytics.io'],
    headers: Ember.computed('session.data.authenticated.access_token', {
      get: function get() {
        var headers = {};
        var token = this.get('session.data.authenticated.access_token');

        if (token) {
          headers.authorization = "Bearer ".concat(token);
        }

        return headers;
      }
    })
  });

  _exports.default = _default;
});