define("funnelytics/helpers/alphabetical-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.alphabeticalList = alphabeticalList;
  _exports.default = void 0;

  // import _ from 'lodash';
  function alphabeticalList(params
  /*, hash*/
  ) {
    // const sorted = _.orderBy(params[0].content, '_data.name');
    // console.log('model', model);
    // this.model.setProperties({
    //   projects: sorted
    // })
    return params;
  }

  var _default = Ember.Helper.helper(alphabeticalList);

  _exports.default = _default;
});