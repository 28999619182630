define("funnelytics/custom/upgrade/FrontEndUpgradeConstants", ["exports", "@funnelytics/utilities", "funnelytics/custom/upgrade/PrivateUpgradeConstants"], function (_exports, _utilities, _PrivateUpgradeConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _ref, _getValidSuggestion, _ref2, _getValidSuggestion2, _Object$freeze;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Upgrades = _utilities.constants.Upgrades;
  var PATH_PRO_MONTHLY = 'order.pro';
  var PATH_UPGRADE = 'order.upgrade';
  var PATH_DEMO = 'https://funnelytics.io/discover';
  var VALID_SUGGESTIONS = Object.freeze([Upgrades.ANY_SUBSCRIPTION, Upgrades.PRO_SUBSCRIPTION]);

  function getValidSuggestion(suggestion) {
    _utilities.Assertion.validString(suggestion, VALID_SUGGESTIONS);

    return suggestion;
  }

  var PATH_SUGGESTIONS = Object.freeze([getValidSuggestion(Upgrades.PRO_SUBSCRIPTION)]);
  var NAME_UPGRADE_TO_MARKETER = 'Upgrade to Marketer';
  var NAME_UPGRADE_TO_PRO = 'Upgrade to PRO';
  var NAME_UPGRADE = 'Upgrade';
  var NAME_BOOK_DEMO = 'Book a demo';
  var NAME_ADD_PAYMENT_METHOD = 'Add Payment Method';
  var MESSAGE_TYPE_WARNING = 'warning';
  var MESSAGE_TYPE_INFO = 'info';
  var MESSAGE_TYPES = Object.freeze([MESSAGE_TYPE_WARNING, MESSAGE_TYPE_INFO]);
  var SUGGESTED_ACTIONS_MAPPING = Object.freeze((_Object$freeze = {}, _defineProperty(_Object$freeze, getValidSuggestion(Upgrades.ANY_SUBSCRIPTION), (_getValidSuggestion = {}, _defineProperty(_getValidSuggestion, _PrivateUpgradeConstants.default.MESSAGE_OBJECT, _defineProperty({}, _PrivateUpgradeConstants.default.MESSAGE_TEXT, 'In order to perform this action, you must have a Pro subscription.')), _defineProperty(_getValidSuggestion, _PrivateUpgradeConstants.default.SUGGESTED_ACTIONS, [(_ref = {}, _defineProperty(_ref, _PrivateUpgradeConstants.default.NAME, NAME_UPGRADE), _defineProperty(_ref, _PrivateUpgradeConstants.default.CTA, 'Upgrade'), _defineProperty(_ref, _PrivateUpgradeConstants.default.PATH, PATH_UPGRADE), _ref)]), _getValidSuggestion)), _defineProperty(_Object$freeze, getValidSuggestion(Upgrades.PRO_SUBSCRIPTION), (_getValidSuggestion2 = {}, _defineProperty(_getValidSuggestion2, _PrivateUpgradeConstants.default.MESSAGE_OBJECT, _defineProperty({}, _PrivateUpgradeConstants.default.MESSAGE_TEXT, 'In order to perform this action, you must have a Pro subscription.')), _defineProperty(_getValidSuggestion2, _PrivateUpgradeConstants.default.SUGGESTED_ACTIONS, [(_ref2 = {}, _defineProperty(_ref2, _PrivateUpgradeConstants.default.NAME, NAME_BOOK_DEMO), _defineProperty(_ref2, _PrivateUpgradeConstants.default.CTA, 'Book a demo'), _defineProperty(_ref2, _PrivateUpgradeConstants.default.PATH, PATH_DEMO), _ref2)]), _getValidSuggestion2)), _Object$freeze));
  var FrontEndUpgradeConstants = Object.freeze({
    NAME_UPGRADE: NAME_UPGRADE,
    NAME_UPGRADE_TO_MARKETER: NAME_UPGRADE_TO_MARKETER,
    NAME_UPGRADE_TO_PRO: NAME_UPGRADE_TO_PRO,
    NAME_ADD_PAYMENT_METHOD: NAME_ADD_PAYMENT_METHOD,
    PATH_PRO_MONTHLY: PATH_PRO_MONTHLY,
    VALID_SUGGESTIONS: VALID_SUGGESTIONS,
    PATH_SUGGESTIONS: PATH_SUGGESTIONS,
    DEFAULT_CALL_TO_ACTION: 'Upgrade',
    SUGGESTED_ACTIONS_MAPPING: SUGGESTED_ACTIONS_MAPPING,
    INTENTION_CREATE_FUNNEL: 'INTENTION_CREATE_FUNNEL',
    // eslint-disable-next-line max-len
    MESSAGE_FUNNEL_UNLOCK: 'Once unlocked, you cannot lock this funnel again and you will need to delete it or ensure you have sufficient funnel limits to create/unlock other funnels.',
    MESSAGE_TYPE_WARNING: MESSAGE_TYPE_WARNING,
    MESSAGE_TYPE_INFO: MESSAGE_TYPE_INFO,
    MESSAGE_TYPES: MESSAGE_TYPES,
    WARNING_TYPE_CREATE: 'create',
    WARNING_TYPE_UNLOCK: 'unlock',
    // eslint-disable-next-line max-len
    HTML_MESSAGE_ADD_CARD: 'Please provide a payment card to redeem a free extra workspace and enable the ability to later create additional extra workspaces for $9/month. <strong>You will not be billed anything at this time.</strong>'
  });
  var _default = FrontEndUpgradeConstants;
  _exports.default = _default;
});