define("funnelytics/routes/dashboard/order/offer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    params: null,
    price: Ember.computed(function () {
      return this.get('currentModel').sku.price / 100;
    }),
    model: function model(params) {
      this.set('params', params);
      return Ember.RSVP.hash({
        user: this.modelFor('dashboard').user,
        order: this.get('ajax').request("/orders/".concat(params.order)),
        products: this.modelFor('dashboard.order'),
        sku: this.get('ajax').request("/stripe-skus/".concat(params.id), {
          method: 'GET'
        })
      });
    },
    setupController: function setupController(controller, model) {
      var previous = this.get('params.order');
      var isFromPaypal = Boolean(previous && previous.includes('PAY-'));

      this._super(controller, model);

      controller.set('product', this.get('params.id'));
      controller.set('previous', previous);
      controller.set('isFromPaypal', isFromPaypal);
      controller.set('price', this.get('price'));
    },

    /*
      TODO: This is causing issues. We're going to need to create one order
            for several items, as opposed to one order for each item.
    */

    /*redirect(model) {
      const items = _.reduce(model.order.items, (arr, item) => {
        if(item.type === 'sku') {
          arr.push(item.parent);
        }
        return arr;
      }, []);
       if(items.length === 1) {
        const upsell = model.products[items[0]].metadata.upsell;
        if(upsell !== this.get('params.id')) {
          this.get('router').transitionTo('dashboard.order.index', items[0]);
        }
      }
    },*/
    renderTemplate: function renderTemplate() {
      this.render('dashboard.order.offer', {
        into: 'application'
      });
    }
  });

  _exports.default = _default;
});