define("funnelytics/templates/components/canvas-elements/analytics-toolbar/filter-dropdown-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oaJgHcPJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"dropdown-list\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"dropdown\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"dropdown-list__button\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"handleClick\"],null]],[9],[0,\"\\n      \"],[7,\"span\"],[9],[0,\"\\n        \"],[7,\"b\"],[9],[1,[21,\"name\"],false],[0,\": \"],[10],[0,\"\\n        \"],[1,[27,\"for-reuse/truncated-text\",null,[[\"text\",\"maxLength\"],[[23,[\"selectedOptionDisplayValue\"]],50]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"svg\"],[11,\"class\",\"icon kpi-chevron kpi-chevron--inside-button\"],[9],[0,\"\\n        \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-chevron-right\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/analytics-toolbar/filter-dropdown-button.hbs"
    }
  });

  _exports.default = _default;
});