define("funnelytics/custom/constants/SaveConstants", ["exports", "funnelytics/custom/constants/CanvasConstants"], function (_exports, _CanvasConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SAVE_STYLES = "\ntext {\n  font-family: Nunito;\n}\n.connection-wrap {\n  fill: none;\n}\n.label__rectangle {\n  fill: none;\n}\n.connection {\n  fill: none;\n}\n.link-tools {\n  display: none;\n}\n.marker-vertex-remove-area {\n  display: none;\n}\n.marker-vertex-remove {\n  display: none;\n}\n.marker-arrowhead-group-source {\n  display: none;\n}\n.marker-arrowhead-group-target {\n  display: none;\n}\n.marker-vertices {\n  display: none;\n}\n.cls-1,\n.cls-7 {\n  fill: #fff;\n}\n.cls-1,\n.cls-3 {\n  fill-rule: evenodd;\n}\n.cls-2 {\n  fill: #c6cbd5;\n}\n.cls-3 {\n  fill: #e6eaed;\n}\n.cls-4 {\n  fill: #ff6259;\n}\n.cls-5 {\n  fill: #ffbd2d;\n}\n.cls-6 {\n  fill: #2bca41;\n}\n.cls-8 {\n  fill: #038ed6;\n}\n.previewthumbnail {\n  clip-path: inset(1% 0% 4% 0% round 11px);\n}\n.connection__arrowhead {\n  transform: translate(0px, 5px) rotate(180deg);\n}\n";
  var SAVE_ANALYTICS_STYLES = "\n".concat(_CanvasConstants.default.ENTITY_NUMBERS_LABEL, " {\n  display: none;\n}\n.visits__wrapper {\n  display: none;\n}\n.forecast__wrapper {\n  display: none;\n}\n").concat(_CanvasConstants.default.ENTITY_FORECAST_LABEL, " {\n  display: none;\n}\n.label__rectangle {\n  fill: none;\n}\n.label {\n  display: none;\n}\n.joint-type-funnel_misc-connection text {\n  display: none;\n}\n.joint-type-funnel_misc-connection rect {\n  display: none;\n}\n").concat(_CanvasConstants.default.ENTITY_LEAD_TIME_LABEL, " {\n  display: none;\n}\n.lead_time__wrapper {\n  display: none;\n}\n").concat(_CanvasConstants.default.ENTITY_COMPARE_SOURCE_LABEL, " {\n  display: none;\n}\n").concat(_CanvasConstants.default.ENTITY_COMPARE_TARGET_LABEL, " {\n  display: none;\n}\n").concat(_CanvasConstants.default.ENTITY_COMPARE_DIFF_LABEL, " {\n  display: none;\n}\n.compare__wrapper {\n  display: none;\n}\n");
  var SaveSettingConstants = {
    SAVE_STYLES: SAVE_STYLES,
    SAVE_ANALYTICS_STYLES: SAVE_ANALYTICS_STYLES
  };
  var _default = SaveSettingConstants;
  _exports.default = _default;
});