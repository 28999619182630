define("funnelytics/templates/components/canvas-elements/kpi-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PcpI6LiP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"kpi-panel__kpi-row-title\"],[9],[0,\"\\n  \"],[7,\"h3\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[0,\"      \"],[1,[21,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"i\"],[9],[0,\"Untitled KPI\"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"hasElement\"]]],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"simple-icon-button\"],[3,\"action\",[[22,0,[]],\"clickMarker\"]],[9],[0,\"\\n      \"],[7,\"svg\"],[9],[0,\"\\n        \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-map-marker\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/kpi-title.hbs"
    }
  });

  _exports.default = _default;
});