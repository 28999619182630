define("funnelytics/components/ip-blacklist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    timer: null,
    interval: 1000,
    change: Ember.observer('ip.ip', function () {
      var _this = this;

      this.send('clearTimer');
      this.set('timer', window.setTimeout(function () {
        if (_this.get('ip.hasDirtyAttributes')) {
          _this.get('ip').save();
        }
      }, this.get('interval')));
    }),
    didInsertElement: function didInsertElement() {
      this.set('timer', null);
    },
    willDestroyElement: function willDestroyElement() {
      this.send('clearTimer');
    },
    actions: {
      delete: function _delete(model) {
        model.destroyRecord();
      },
      clearTimer: function clearTimer() {
        if (this.get('timer')) {
          window.clearTimeout(this.get('timer'));
          this.set('timer', null);
        }
      }
    }
  });

  _exports.default = _default;
});