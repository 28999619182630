define("funnelytics/services/user-permissions", ["exports", "@funnelytics/utilities", "funnelytics/custom/debug/logger", "funnelytics/custom/permissions/CachedPermissionOptions", "funnelytics/custom/permissions/Permission", "funnelytics/custom/permissions/PermissionCache", "lodash/isInteger", "lodash/get"], function (_exports, _utilities, _logger, _CachedPermissionOptions, _Permission, _PermissionCache, _isInteger2, _get2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Permissions = _utilities.constants.Permissions;
  var UNLIMITED_COUNT_ALLOWANCE = -1;

  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    projectPermissions: Ember.inject.service(),
    workspaceSelector: Ember.inject.service(),
    permissionCache: new _PermissionCache.default(),
    workspacePermissions: Ember.computed('workspaceSelector.selectedV2Workspace', 'projectPermissions.ownedWorkspacesCount', 'projectPermissions.projectPermissions', function () {
      var selectedProjectId = this.get('workspaceSelector.selectedV2Workspace') ? this.get('workspaceSelector.selectedV2Workspace.id') : null;
      var projectsCount = this.get('projectPermissions.ownedWorkspacesCount');
      var noProjectsCount = projectsCount !== 0 && !projectsCount;

      if (!selectedProjectId || noProjectsCount) {
        return {
          current: undefined,
          isInfinite: undefined,
          limit: undefined
        };
      }

      var current = (0, _get2.default)(projectsCount, 'unlocked_workspaces');
      var limit = (0, _get2.default)(projectsCount, 'unlocked_workspaces_allowed', 0);
      var isInfinite = limit === UNLIMITED_COUNT_ALLOWANCE;
      return {
        current: current,
        isInfinite: isInfinite,
        limit: limit
      };
    }),
    funnelPermissions: Ember.computed('workspaceSelector.selectedV2Workspace', 'projectPermissions.ownedCanvasCount', 'projectPermissions.projectPermissions', function () {
      var selectedProjectId = this.get('workspaceSelector.selectedV2Workspace') ? this.get('workspaceSelector.selectedV2Workspace.id') : null;
      var canvasesCount = this.get('projectPermissions.ownedCanvasCount');
      var projectPermissions = selectedProjectId ? this.get("projectPermissions.projectPermissions.".concat(selectedProjectId)) : null;
      var noCanvasesCount = canvasesCount !== 0 && !canvasesCount;

      if (!selectedProjectId || noCanvasesCount || !projectPermissions) {
        return {
          current: undefined,
          isInfinite: undefined,
          limit: undefined
        };
      }

      var limit = (0, _get2.default)(projectPermissions, 'unlockCanvasAllowance.unlocked_canvases_allowed', 0);
      var isInfinite = limit === UNLIMITED_COUNT_ALLOWANCE;
      return {
        current: canvasesCount,
        isInfinite: isInfinite,
        limit: limit
      };
    }),
    workspaceLimitToDisplay: Ember.computed('workspaceLimit', 'hasExtraWorkspace', function () {
      if (!(0, _isInteger2.default)(this.get('workspaceLimit'))) {
        return this.get('workspaceLimit');
      }

      var extraWorkspace = this.get('hasExtraWorkspace') ? 1 : 0;
      return this.get('workspaceLimit') + extraWorkspace;
    }),
    workspaceLimit: Ember.computed.readOnly('workspacePermissions.limit'),
    workspaceCount: Ember.computed.readOnly('workspacePermissions.current'),
    hasInfiniteWorkspaces: Ember.computed.bool('workspacePermissions.isInfinite'),
    isUpgradePermissionsReloadRequired: true,
    isAtWorkspaceLimit: Ember.computed('workspacePermissions.{limit,current,isInfinite}', function () {
      if (this.get('workspacePermissions.isInfinite')) {
        return false;
      }

      var limit = this.get('workspacePermissions.limit');
      var current = this.get('workspacePermissions.current');

      if (limit === undefined || current === undefined) {
        return false;
      }

      return current >= limit;
    }),
    isAtFunnelLimit: Ember.computed('funnelPermissions.{limit,current,isInfinite}', function () {
      if (this.get('funnelPermissions.isInfinite')) {
        return false;
      }

      var limit = this.get('funnelPermissions.limit');
      var current = this.get('funnelPermissions.current');

      if (limit === undefined || current === undefined) {
        return false;
      }

      return current >= limit;
    }),
    RELOAD_WORKSPACE_LIMITS_OBSERVER: Ember.observer('isUpgradePermissionsReloadRequired', function () {
      if (this.get('isUpgradePermissionsReloadRequired')) {
        this.loadUpgradeLimits();
      }
    }),
    // TODO: Remove after cleaning up legacy permissions
    loadUpgradeLimits: function loadUpgradeLimits() {
      var _this = this;

      this.notifyPropertyChange('workspaceSelector.selectedV2Workspace'); // do nothing, permissions were taken from projectPermissions service
      // Returning promise to support legacy code that uses this function

      return Promise.resolve().then(function () {
        return _this.confirmUpgradePermissionReloaded();
      });
    },
    //   loadUpgradeLimits() {
    //     return Promise.resolve().then(() => {
    //       if (!this.get('isUpgradePermissionsReloadRequired')) {
    //         return this.get('workspacePermissions');
    //       }
    //       return promiseHash({
    //         workspaceLimitObject: this.getAllowanceLimit({
    //           permission: Permissions.TYPE_WORKSPACES_ALLOWANCE,
    //         }),
    //         currentWorkspaceCount: this.get('ajax').request('/projects/count'),
    //         funnelLimitObject: this.getAllowanceLimit({
    //           permission: Permissions.TYPE_FUNNELS_ALLOWANCE,
    //         }),
    //         currentFunnelCount: this.get('ajax').request('/funnels/count'),
    //       }).then(
    //         ({
    //           workspaceLimitObject,
    //           currentWorkspaceCount,
    //           funnelLimitObject,
    //           currentFunnelCount,
    //         }) => {
    //           const isInfiniteWorkspaces = _get(
    //             workspaceLimitObject,
    //             ['isInfinite'],
    //             false,
    //           );
    //           const workspacesLimit = _get(workspaceLimitObject, ['limit']);
    //           const workspacesCurrentCount = _get(currentWorkspaceCount, [
    //             'workspaces',
    //             'count',
    //           ]);
    //           const isInfiniteFunnels = _get(
    //             funnelLimitObject,
    //             ['isInfinite'],
    //             false,
    //           );
    //           const funnelsLimit = _get(funnelLimitObject, ['limit']);
    //           const funnelsCurrentCount = _get(currentFunnelCount, [
    //             'funnels',
    //             'count',
    //           ]);
    //           if (
    //             (workspacesLimit === undefined &&
    //               isInfiniteWorkspaces === undefined) ||
    //             (funnelsLimit === undefined && isInfiniteFunnels === undefined)
    //           ) {
    //             // Display nothing
    //             logger.warn(`
    // Malformed request?
    // Workspace limit: ${workspacesLimit}, isInfinite: ${isInfiniteWorkspaces}.
    // Funnel limit: ${funnelsLimit}, isInfinite: ${isInfiniteFunnels}.
    // `);
    //           }
    //           this.set('workspacePermissions', {
    //             isInfinite: isInfiniteWorkspaces,
    //             limit: workspacesLimit,
    //             current: workspacesCurrentCount,
    //           });
    //           this.set('funnelPermissions', {
    //             isInfinite: isInfiniteFunnels,
    //             limit: funnelsLimit,
    //             current: funnelsCurrentCount,
    //           });
    //           this.confirmUpgradePermissionReloaded();
    //         },
    //       );
    //     });
    //   },
    clearPermissionCache: function clearPermissionCache() {
      // Clears "cache" in instances where the workspace/funnel limits may need to be reloaded
      this.set('isUpgradePermissionsReloadRequired', true); // Clears cache for regular, non-limit permission checks done through hasPermission()

      this.get('permissionCache').clearCache();
    },
    confirmUpgradePermissionReloaded: function confirmUpgradePermissionReloaded() {
      // Allows for future "cache" clears
      this.set('isUpgradePermissionsReloadRequired', false);
    },
    hasPermission: function hasPermission(_ref) {
      var permission = _ref.permission,
          _ref$scope = _ref.scope,
          scope = _ref$scope === void 0 ? Permissions.SCOPE_USER : _ref$scope,
          _ref$scopeId = _ref.scopeId,
          scopeId = _ref$scopeId === void 0 ? this.get('session.data.authenticated.id') : _ref$scopeId,
          _ref$count = _ref.count,
          count = _ref$count === void 0 ? undefined : _ref$count,
          _ref$accessLevel = _ref.accessLevel,
          accessLevel = _ref$accessLevel === void 0 ? Permissions.ACCESS_LEVEL_READ : _ref$accessLevel,
          _ref$isPermissionChec = _ref.isPermissionCheckIgnored,
          isPermissionCheckIgnored = _ref$isPermissionChec === void 0 ? false : _ref$isPermissionChec;

      if (isPermissionCheckIgnored) {
        return Promise.resolve(true);
      }

      var permissionObject = new _Permission.default({
        permission: permission,
        scope: scope,
        scopeId: scopeId,
        count: count,
        accessLevel: accessLevel
      });
      var cachedPermission = this.get('permissionCache').getCached(permissionObject);

      if (cachedPermission) {
        return cachedPermission.getPermitted().catch(function (err) {
          _logger.default.error(err);

          return false;
        });
      }

      var options = new _CachedPermissionOptions.default({
        permission: permissionObject,
        ajax: this.get('ajax')
      });
      this.get('permissionCache').addToCache(options);
      return this.get('permissionCache').getCached(permissionObject).getPermitted().catch(function (err) {
        _logger.default.error(err);

        return false;
      });
    },
    getAllowanceLimit: function getAllowanceLimit(_ref2) {
      var permission = _ref2.permission,
          _ref2$scope = _ref2.scope,
          scope = _ref2$scope === void 0 ? Permissions.SCOPE_USER : _ref2$scope,
          _ref2$scopeId = _ref2.scopeId,
          scopeId = _ref2$scopeId === void 0 ? this.get('session.data.authenticated.id') : _ref2$scopeId,
          _ref2$accessLevel = _ref2.accessLevel,
          accessLevel = _ref2$accessLevel === void 0 ? Permissions.ACCESS_LEVEL_WRITE : _ref2$accessLevel;
      return this.get('ajax').request('/user-permissions/allowance-count', {
        method: 'GET',
        data: {
          permission: permission,
          scope: scope,
          scopeId: scopeId,
          level: accessLevel
        }
      }).then(function (returnValue) {
        return {
          limit: returnValue.limit,
          isInfinite: returnValue.isInfinite
        };
      });
    }
  });

  _exports.default = _default;
});