define("funnelytics/components/element-toolbar", ["exports", "lodash", "funnelytics/custom/canvas/managers/ConnectionValidator", "funnelytics/custom/constants/CanvasConstants", "funnelytics/custom/storage/StorageHelpers", "funnelytics/custom/constants/AcknowledgementConstants", "funnelytics/custom/constants/DeferredActionConstants", "funnelytics/custom/canvas/ContextMenu/ContextMenuConstants", "funnelytics/custom/constants/TrackingConstants", "funnelytics/custom/tracking/ProductTracker"], function (_exports, _lodash, _ConnectionValidator, _CanvasConstants, _StorageHelpers, _AcknowledgementConstants, _DeferredActionConstants, _ContextMenuConstants, _TrackingConstants, _ProductTracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LINK_TYPE_NEXT = 'LINK_TYPE_NEXT';
  var LINK_TYPE_PREVIOUS = 'LINK_TYPE_PREVIOUS';
  var LINK_TYPE_NULL = null;

  var _default = Ember.Component.extend({
    analyticsService: Ember.inject.service(),
    goalManager: Ember.inject.service(),
    canvasService: Ember.inject.service(),
    learningModalTrigger: Ember.inject.service(),
    hasEditPermission: Ember.computed.readOnly('canvasService.hasEditPermission'),
    linkType: LINK_TYPE_NULL,
    isNextLink: Ember.computed.equal('linkType', LINK_TYPE_NEXT),
    isPreviousLink: Ember.computed.equal('linkType', LINK_TYPE_PREVIOUS),
    isNotLinking: Ember.computed.equal('linkType', LINK_TYPE_NULL),
    handlezoom: null,
    settingsActive: false,
    isStepAttributesContextMenuOpened: false,
    actionId: 0,
    onFocusAnalyticsOnEntity: function onFocusAnalyticsOnEntity() {},
    elementType: Ember.computed.readOnly('editing.type'),
    canvasEntity: Ember.computed('editing.model', function () {
      return this.get('canvasService').wrapEntity(this.get('editing.model'));
    }),
    entityType: Ember.computed('canvasEntity', function () {
      var entity = this.get('canvasEntity');

      if (!entity) {
        return null;
      }

      return entity.getType();
    }),
    canLinkPrevious: Ember.computed('entityType', function () {
      // Everything apart from traffic entities can be linked backwards.
      return this.get('entityType') !== _CanvasConstants.default.TYPE_SOURCE;
    }),
    // TODO: Should have access to explore/analytics permission
    isExplorable: Ember.computed('canvasEntity', 'entityType', function () {
      var isValidType = [_CanvasConstants.default.TYPE_EVENT, _CanvasConstants.default.TYPE_PAGE, _CanvasConstants.default.TYPE_SOURCE].includes(this.get('entityType'));

      if (this.get('entityType') === _CanvasConstants.default.TYPE_EVENT && this.get('canvasEntity').isSimpleAction()) {
        // Simple actions are not explorable.
        return false;
      }

      return isValidType;
    }),
    hasToolbar: Ember.computed('elementType', function () {
      return !(this.get('elementType') === _CanvasConstants.default.TYPE_TEXTAREA || this.get('elementType') === _CanvasConstants.default.TYPE_RECTANGLE || this.get('elementType') === _CanvasConstants.default.TYPE_CIRCLE || this.get('elementType') === _CanvasConstants.default.TYPE_POLYGON);
    }),
    hasSettings: Ember.computed('elementType', function () {
      return ![_CanvasConstants.default.TYPE_TEXT, _CanvasConstants.default.TYPE_RECTANGLE].includes(this.get('elementType'));
    }),
    canFocus: Ember.computed('canvasEntity', function () {
      return this.get('canvasEntity').hasFocusAnalytics();
    }),
    canMakeSimpleLink: Ember.computed('canvasEntity', function () {
      var entity = this.get('canvasEntity');
      return [_CanvasConstants.default.TYPE_EVENT, _CanvasConstants.default.TYPE_OFFLINE, _CanvasConstants.default.TYPE_SOURCE, _CanvasConstants.default.TYPE_PAGE].includes(entity.getType());
    }),
    watchEditing: Ember.observer('editing', function () {
      this.set('links', this.getLinkCount());
      this.elementChanged();
    }),
    onEnableNumbers: function onEnableNumbers() {},
    isFocusedOn: Ember.computed('analyticsService.focusEntity', 'canvasEntity', function () {
      return this.get('analyticsService').isFocusedOn(this.get('canvasEntity'));
    }),
    observeDeferredAction: Ember.observer('learningModalTrigger.executeDeferredAction', function () {
      switch (this.get('learningModalTrigger.deferredAction')) {
        case _DeferredActionConstants.default.FOCUS:
          this.send('focus');
          break;

        default:
          break;
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      // Watch canvasService
      this.get('canvasService');
      this.set('linkStyle', _StorageHelpers.default.getLinkStyle());
      this.set('links', this.getLinkCount());
      this.setProperties({
        links: this.getLinkCount(),
        linkStyle: _StorageHelpers.default.getLinkStyle()
      });
      var menu = this.get('paper').getContextMenu();
      this.set('onContextMenuVisibilityChange', function () {
        var menu = _this.get('paper').getContextMenu();

        var initialEntityType = menu.getInitialEntityType();
        var isStepAttributes = initialEntityType === _ContextMenuConstants.default.INITIAL_ENTITY_TYPE_EXPLORE;

        _this.set('isStepAttributesContextMenuOpened', menu.isVisible() && isStepAttributes);
      });
      menu.on('menu:visibilityChange', this.get('onContextMenuVisibilityChange'));
      menu.on('menu:initialEntityChange', this.get('onContextMenuVisibilityChange'));
    },
    willDestroyElement: function willDestroyElement() {
      var menu = this.get('paper').getContextMenu();
      menu.off('menu:visibilityChange', this.get('onContextMenuVisibilityChange'));
      menu.off('menu:initialEntityChange', this.get('onContextMenuVisibilityChange'));
    },
    getLinkCount: function getLinkCount() {
      return this.paper.graph.getConnectedLinks(this.editing.model).length;
    },
    applyAnalyticsFocusOnEntity: function applyAnalyticsFocusOnEntity(element) {
      var focusHandler = this.get('onFocusAnalyticsOnEntity');
      focusHandler(element);
    },
    elementChanged: function elementChanged() {
      this.get('canvasService').closeStepSettingsPanel();
    },
    incrementActionId: function incrementActionId() {
      var currentActionId = this.get('actionId');
      this.set('actionId', currentActionId + 1);
    },
    onCannotAddIcon: function onCannotAddIcon() {},
    setIsPreviousLink: function setIsPreviousLink() {
      this.set('linkType', LINK_TYPE_PREVIOUS);
    },
    setIsNextLink: function setIsNextLink() {
      this.set('linkType', LINK_TYPE_NEXT);
    },
    resetLink: function resetLink() {
      this.set('linkType', LINK_TYPE_NULL);
    },
    calculateLinkSource: function calculateLinkSource(_ref) {
      var x = _ref.x,
          y = _ref.y;

      if (this.get('isNextLink')) {
        return {
          id: this.get('editing.model.id')
        };
      }

      if (this.get('isPreviousLink')) {
        return {
          x: x,
          y: y
        };
      }

      return {};
    },
    calculateLinkTarget: function calculateLinkTarget(_ref2) {
      var x = _ref2.x,
          y = _ref2.y;

      if (this.get('isNextLink')) {
        return {
          x: x,
          y: y
        };
      }

      if (this.get('isPreviousLink')) {
        return {
          id: this.get('editing.model.id')
        };
      }

      return {};
    },

    /**
     * This is not perfect since it only prefers trackable elemetns with no other heirarchy being respected,
     * for example, smaller shapes within a shape will not be easily linked to. However, this is a big
     * improvement over the previous version.
     */
    findBestLinkTarget: function findBestLinkTarget(elements) {
      var _this2 = this;

      var overlappedEntities = elements.map(function (element) {
        return _this2.get('canvasService').wrapEntity(element);
      });
      var trackableEntity = overlappedEntities.find(function (entity) {
        return entity.isTrackable();
      });

      if (trackableEntity) {
        return trackableEntity;
      }

      var overlappedEntity = _lodash.default.first(overlappedEntities);

      if (overlappedEntity) {
        return overlappedEntity;
      }

      return this.get('canvasService').createEmptyEntity();
    },
    actions: {
      showContextMenu: function showContextMenu() {
        var dom = Ember.$('.element-toolbar')[0];
        var meta = dom.getBoundingClientRect();
        this.get('paper').getContextMenu().open(this.get('editing.cell').model, {
          clientX: meta.width + meta.x + 7,
          clientY: meta.y
        });
      },
      editToolbar: function editToolbar() {
        this.get('canvasService').toggleStepSettingsPanel(this.get('canvasEntity'));
      },
      focus: function focus() {
        if (this.get('permitted')) {
          this.get('canvasService').closeAnalyticsPanel();

          if (this.get('isFocusedOn')) {
            // TODO: Use analytics service directly here
            this.sendAction('clearStepFocus');
          } else {
            var showingLearningModal = this.get('learningModalTrigger').showLearningModal({
              type: _AcknowledgementConstants.default.FOCUS
            });

            if (showingLearningModal) {
              return;
            }

            var enableNumbersHandler = this.get('onEnableNumbers');
            enableNumbersHandler();

            _ProductTracker.default.trigger(_TrackingConstants.default.CANVAS_ANALYTICS_FOCUS_ON_ENTITY);

            this.applyAnalyticsFocusOnEntity(this.get('canvasEntity'));
          }
        } else {
          this.freeUserPrompt();
        }
      },
      destroyLinks: function destroyLinks() {
        this.incrementActionId();
        var links = this.paper.graph.getConnectedLinks(this.get('editing.model'));

        for (var i = 0; i < links.length; i++) {
          links[i].remove();
        }

        this.set('editing', {
          type: this.attrs.editing.value.type,
          model: this.attrs.editing.value.model,
          cell: this.attrs.editing.value.cell,
          position: this.attrs.editing.value.position,
          linked: false
        });
        this.paper.selection.collection.reset([this.attrs.editing.value.cell.model]);
        this.set('links', this.getLinkCount());
      },
      removeNode: function removeNode() {
        this.get('editing.model').remove();
      },
      startPreviousLink: function startPreviousLink(evt) {
        this.setIsPreviousLink();
        this.send('startLink', evt);
      },
      exploreStep: function exploreStep(evt) {
        var contextMenu = this.get('paper').getContextMenu();
        contextMenu.open(null, evt);
        contextMenu.setInitialEntity(this.get('canvasEntity'), {
          type: _ContextMenuConstants.default.INITIAL_ENTITY_TYPE_EXPLORE
        });
        contextMenu.setStyle(_ContextMenuConstants.default.STYLE_GRID);
        contextMenu.suspendClose(); // prevent the menu from instantly closing due to a potential "click off"
      },
      startNextLink: function startNextLink(evt) {
        this.setIsNextLink();
        this.send('startLink', evt);
      },
      startLink: function startLink(evt) {
        this.paper.commands.manager.initBatchCommand();
        this.get('canvasService').startLinking();
        Ember.$('#paper').find('.joint-paper').css('cursor', 'grabbing');
        var localizedPoint = this.paper.model.clientToLocalPoint(evt.clientX, evt.clientY);
        var linkEntity = this.get('canvasService').createConnection({
          source: this.calculateLinkSource({
            x: localizedPoint.x,
            y: localizedPoint.y
          }),
          target: this.calculateLinkTarget({
            x: localizedPoint.x,
            y: localizedPoint.y
          })
        });
        this.paper.entityManager.addToCanvas(linkEntity);
        var linkView = this.paper.getView(linkEntity);
        this.set('events', {
          onDrag: onDrag.bind(this),
          onDragEnd: onDragEnd.bind(this)
        });
        Ember.$(document).on({
          mousemove: this.get('events.onDrag'),
          mouseup: this.get('events.onDragEnd')
        }, {
          // share data between listeners
          view: linkView,
          this: this,
          paper: this.paper,
          localizedPoint: localizedPoint,
          clientPoint: this.paper.model
        });

        function onDrag(evt) {
          var localizedPoint = this.paper.model.clientToLocalPoint(evt.clientX, evt.clientY);
          var overlappedElements = this.paper.graph.findModelsFromPoint(localizedPoint);

          if (overlappedElements.length > 0) {
            var sourceEntity = this.get('canvasService').wrapEntity(this.get('editing.model'));
            var overlappedEntity = this.findBestLinkTarget(overlappedElements);
            var overlappedView = this.paper.getView(overlappedEntity);

            if (_ConnectionValidator.default.canMakeLineBetween(sourceEntity, overlappedEntity)) {
              this.paper.unhighlightAllCells();
              overlappedView.highlight();
            }
          } else {
            this.paper.unhighlightAllCells();
          }

          if (this.get('isNextLink')) {
            linkEntity.setTarget({
              x: localizedPoint.x,
              y: localizedPoint.y
            });
          } else if (this.get('isPreviousLink')) {
            linkEntity.setSource({
              x: localizedPoint.x,
              y: localizedPoint.y
            });
          }

          evt.data.view.pointermove(evt, localizedPoint.x, localizedPoint.y);
        }

        function onDragEnd(evt) {
          this.incrementActionId();
          this.set('links', this.getLinkCount());
          Ember.$('#paper').find('.joint-paper').css('cursor', 'auto');
          var localizedPoint = this.paper.model.clientToLocalPoint(evt.clientX, evt.clientY);
          var overlappedElements = this.paper.graph.findModelsFromPoint(localizedPoint);
          var entity = this.get('canvasService').wrapEntity(this.get('editing.model'));
          var overlappedEntity = this.findBestLinkTarget(overlappedElements);
          var entityManager = this.get('canvasService').getEntityManager();
          var sourceEntity = this.get('isNextLink') ? entity : overlappedEntity;
          var targetEntity = this.get('isNextLink') ? overlappedEntity : entity;

          if (!overlappedEntity.isEmpty() && !_ConnectionValidator.default.canMakeLineBetween(sourceEntity, targetEntity)) {
            linkEntity.remove();
            this.get('canvasService').stopLinking();
            this.resetLink();
            return;
          }

          if (overlappedEntity.isEmpty() || overlappedEntity.isLink()) {
            this.get('paper').getContextMenu().open(null, evt);
            var type = this.get('isPreviousLink') ? _ContextMenuConstants.default.INITIAL_ENTITY_TYPE_TARGET : _ContextMenuConstants.default.INITIAL_ENTITY_TYPE_SOURCE;
            this.get('paper').getContextMenu().setInitialEntity(entity, {
              linkEntity: linkEntity,
              type: type
            });
            this.get('paper').getContextMenu().setStyle(_ContextMenuConstants.default.STYLE_GRID);
          } else {
            this.paper.entityManager.addToCanvas(linkEntity);
          }

          var isAnalyticsValidForConnection = _ConnectionValidator.default.canMakeConnectionBetween(sourceEntity, targetEntity);

          var isValidGhost = _ConnectionValidator.default.canMakeGhostLineBetween(sourceEntity, targetEntity);

          if (isAnalyticsValidForConnection || isValidGhost) {
            linkEntity.setSource({
              id: sourceEntity.getID()
            });
            linkEntity.setTarget({
              id: targetEntity.getID()
            });
            this.set('editing.linked', true);
          }

          if (isAnalyticsValidForConnection) {
            this.paper.model.trigger('add:link', {
              linkEntity: linkEntity
            });
          } else if (isValidGhost) {
            entityManager.convertLineToGhostLine(linkEntity);
          } else if (!linkEntity.getTargetId()) {
            entityManager.select([entity]);
          }

          this.paper.unhighlightAllCells();
          this.notifyPropertyChange('elementType');
          this.paper.commands.manager.storeBatchCommand();
          this.get('canvasService').stopLinking();
          this.resetLink();
        }

        Ember.$(document).mouseup(function () {
          Ember.$(document).off('mousemove', this.get('events.onDrag'));
          Ember.$(document).off('mouseup', this.get('events.onDragEnd'));
        }.bind(this));
      }
    }
  });

  _exports.default = _default;
});