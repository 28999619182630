define("funnelytics/custom/constants/TrackingConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TrackingConstants = Object.freeze({
    CANVAS_ANALYTICS_LOAD: 'analyze',
    CANVAS_ANALYTICS_FOCUS_ON_ENTITY: 'focus-analytics-on-element',
    CANVAS_ANALYTICS_FOCUS_ON_SESSION: 'focus-on-person',
    CANVAS_CUSTOM_ICONS_UPLOAD: 'custom-icon-upload',
    CANVAS_ENTITY_ADD: 'add-step',
    CANVAS_EXPLORER_ACTIONS_NEXT_STEPS: 'exp-from-actions-to-next',
    CANVAS_EXPLORER_ACTIONS_PREVIOUS_STEPS: 'exp-from-actions-to-prev',
    CANVAS_EXPLORER_CATEGORY_ALL_NEXT_ACTIONS: 'all-next-actions',
    CANVAS_EXPLORER_CATEGORY_ALL_NEXT_PAGES: 'all-next-pages',
    CANVAS_EXPLORER_CATEGORY_ALL_PREVIOUS_ACTIONS: 'all-previous-actions',
    CANVAS_EXPLORER_CATEGORY_ALL_PREVIOUS_PAGES: 'all-previous-pages',
    CANVAS_EXPLORER_CATEGORY_NEXT_ACTIONS: 'next-actions',
    CANVAS_EXPLORER_CATEGORY_NEXT_PAGES: 'next-pages',
    CANVAS_EXPLORER_CATEGORY_PREVIOUS_ACTIONS: 'previous-actions',
    CANVAS_EXPLORER_CATEGORY_PREVIOUS_PAGES: 'previous-pages',
    CANVAS_EXPLORER_PAGE_NEXT_STEPS: 'exp-from-page-to-next',
    CANVAS_EXPLORER_PAGE_PREVIOUS_STEPS: 'exp-from-page-to-prev',
    CANVAS_EXPLORER_TRAFFIC_NEXT_STEPS: 'exp-from-traffic-to-next',
    CANVAS_EXPORT_PNG: 'export-png',
    CANVAS_FILTER_COUNTRY: 'country-filter',
    CANVAS_FILTER_DEVICE: 'device-filter',
    CANVAS_FORECAST_CLICKS_TO_NEXT: 'clicks-to-next-steps',
    CANVAS_FORECAST_CTR: 'click-through-rate',
    CANVAS_IMAGE_UPLOAD: 'upload-image',
    CANVAS_LAYER_FLOW: 'turn-on-flow-layer',
    CANVAS_LAYER_FORECAST: 'turn-on-forecast-layer',
    CANVAS_PAGE_GENERATE_THUMBNAIL: 'generate-thumbnail',
    CANVAS_SETTINGS_TEMPLATE_SAVE: 'save-template',
    CANVAS_SHARE_FUNNEL: 'share-funnel',
    COLLABORATE_INVITE: 'invite-collaborator',
    COLLABORATE_REMOVE: 'remove-collaborator',
    CUSTOMER_PURCHASE: 'purchase',
    FUNNEL_CREATE: 'funnel-create',
    USER_SEGMENTATION_SUBMIT: 'segmentation-survey',
    VAULT_ADD: 'add-vault',
    CANVAS_COMPARE_DATE_FILTER: 'compare-date-filter',
    CANVAS_COMPARE_PEOPLE_FILTER: 'compare-people-filter',
    CANVAS_PRIMARY_DATE_FILTER: 'primary-date-filter',
    CANVAS_PRIMARY_PEOPLE_FILTER: 'primary-people-filter',
    CANVAS_LEAD_LAYER: 'turn-on-lead-layer'
  });
  var _default = TrackingConstants;
  _exports.default = _default;
});