define("funnelytics/helpers/get-element-img", ["exports", "funnelytics/custom/constants/CanvasConstants", "lodash"], function (_exports, _CanvasConstants, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getElementImg = getElementImg;
  _exports.default = void 0;

  function getElementImg(params
  /*, hash*/
  ) {
    var elementType = _lodash.default.get(params, ['0', params[1], 'attributes', 'type']);

    var element = _lodash.default.get(params, ['0', params[1]]);

    var imageAddress;

    if (elementType === _CanvasConstants.default.TYPE_PAGE) {
      imageAddress = _lodash.default.get(element, ['attributes', 'attrs', '.previewthumbnail', 'xlink:href']);
    } else {
      imageAddress = _lodash.default.get(element, ['attributes', 'attrs', 'image', 'xlink:href']);
    }

    return imageAddress;
  }

  var _default = Ember.Helper.helper(getElementImg);

  _exports.default = _default;
});