define("funnelytics/routes/order", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import rsvp from 'rsvp';
  // import EmberObject from '@ember/object';
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    session: Ember.inject.service(),
    tapfiliate: Ember.inject.service(),
    userDetails: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      var _this = this;

      controller.set('user', this.get('store').findRecord('user', this.get('session.data.authenticated.id')));
      controller.get('user').then(function (user) {
        var affiliate = _this.get('tapfiliate').getAffiliateCookie();

        _this.get('userDetails').setUser(user);

        if (affiliate) {
          _this.get('tapfiliate').setUserAffiliate(user, affiliate);
        }
        /*
        _dcq.push(['identify', {
          email: user.get('email'),
          first_name: user.get('first_name'),
          last_name: user.get('last_name'),
        }]);
        */

      });

      this._super(controller, model);

      var application = Ember.getOwner(this).lookup('route:application');
      application.get('actions.didTransition').apply(application);
    }
  });

  _exports.default = _default;
});