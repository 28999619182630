define("funnelytics/controllers/dashboard/icons/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    saving: false,
    saved: false,
    actions: {
      setType: function setType(model, nothing, event) {
        model.set('type', event.target.value);
      },
      save: function save(model) {
        var _this = this;

        this.set('saving', true);
        this.set('saved', false);
        model.save().then(function () {
          _this.set('saving', false);

          _this.set('saved', true);
        });
      },
      delete: function _delete(model) {
        var _this2 = this;

        model.destroyRecord().then(function () {
          _this2.get('router').transitionTo('dashboard.icons');
        });
      }
    }
  });

  _exports.default = _default;
});