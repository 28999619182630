define("funnelytics/modifiers/click-outside", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var HAS_WINDOWS = typeof window !== 'undefined';
  var HAS_NAVIGATOR = typeof navigator !== 'undefined';
  var IS_TOUCH = HAS_WINDOWS && ('ontouchstart' in window || HAS_NAVIGATOR && navigator.msMaxTouchPoints > 0);
  var EVENTS = IS_TOUCH ? ['touchstart'] : ['click'];

  var _default = (0, _emberModifier.modifier)(function clickOutside(element) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [undefined, false],
        _ref2 = _slicedToArray(_ref, 2),
        handlerValue = _ref2[0],
        useCapture = _ref2[1];

    var isFunction = typeof handlerValue === 'function';

    if (!isFunction) {
      throw new Error('{{click-outside}}: Handler value must be a function.');
    }

    var handlers = [];
    EVENTS.forEach(function (eventName) {
      var handler = function handler(event) {
        var isClickOutside = event.target !== element && !element.contains(event.target);

        if (!isClickOutside) {
          return;
        }

        handlerValue(event);
      };

      handlers.push([eventName, handler]);
      document.documentElement.addEventListener(eventName, handler, useCapture);
    });
    return function () {
      handlers.forEach(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
            eventName = _ref4[0],
            handler = _ref4[1];

        document.documentElement.removeEventListener(eventName, handler, useCapture);
      });
    };
  });

  _exports.default = _default;
});