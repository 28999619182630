define("funnelytics/templates/components/containers/funnel-editor/components/right-sidebar-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WrdjERr9",
    "block": "{\"symbols\":[\"nav\"],\"statements\":[[7,\"div\"],[11,\"class\",\"right-sidebar__top-nav\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"navigationOptions\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[\"fl-title right-sidebar__nav-item \",[27,\"if\",[[27,\"eq\",[[23,[\"selectedNavigation\"]],[22,1,[\"key\"]]],null],\"right-sidebar__nav-item--active\"],null]]]],[3,\"action\",[[22,0,[]],\"selectNavigation\",[22,1,[\"key\"]]]],[9],[0,\"\\n      \"],[1,[22,1,[\"label\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/containers/funnel-editor/components/right-sidebar-navigation.hbs"
    }
  });

  _exports.default = _default;
});