define("funnelytics/custom/constants/UtmConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var UTM_KEY = 'utmKey';
  var UTM_VALUE = 'utmValue';
  var MISSING_UTM_VALUE = 'null';
  var UTM_SOURCE = 'utm_source';
  var UTM_MEDIUM = 'utm_medium';
  var UTM_CAMPAIGN = 'utm_campaign';
  var UTM_CONTENT = 'utm_content';
  var UTM_TERM = 'utm_term';
  var UtmConstants = Object.freeze({
    UTM_KEY: UTM_KEY,
    UTM_VALUE: UTM_VALUE,
    UTM_IS_NOT_SET: '(not set)',
    MISSING_UTM_VALUE: MISSING_UTM_VALUE,
    UTM_SOURCE: UTM_SOURCE,
    UTM_MEDIUM: UTM_MEDIUM,
    UTM_CAMPAIGN: UTM_CAMPAIGN,
    UTM_CONTENT: UTM_CONTENT,
    UTM_TERM: UTM_TERM,
    ALLOWED_UTMS: [UTM_SOURCE, UTM_MEDIUM, UTM_CAMPAIGN, UTM_CONTENT, UTM_TERM],
    MATCHING_CANVAS_ID: 'canvasId'
  });
  var _default = UtmConstants;
  _exports.default = _default;
});