define("funnelytics/templates/dashboard/funnels/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ICGfW7NM",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[1,[27,\"funnel-editor\",null,[[\"type\",\"user\",\"funnel\",\"templates\",\"folders\",\"clients\",\"iconLimit\",\"userIcons\",\"edited\",\"onFunnelIsInUnsavedStateChange\",\"hasStarterPermissions\"],[\"funnel\",[23,[\"model\",\"user\"]],[23,[\"model\",\"funnel\"]],[23,[\"model\",\"templates\"]],[23,[\"model\",\"folders\"]],[23,[\"model\",\"clients\"]],[23,[\"model\",\"iconLimit\"]],[23,[\"model\",\"userIcons\"]],[23,[\"edited\"]],[27,\"action\",[[22,0,[]],\"setEdited\"],null],[23,[\"hasStarterPermissions\"]]]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/dashboard/funnels/view.hbs"
    }
  });

  _exports.default = _default;
});