define("funnelytics/components/containers/funnel-editor/components/right-sidebar-parameters", ["exports", "funnelytics/custom/constants/CanvasConstants", "funnelytics/custom/constants/AnalyticsConstants", "funnelytics/custom/constants/ListenerConstants"], function (_exports, _CanvasConstants, _AnalyticsConstants, _ListenerConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    parameters: null,
    analyticsService: Ember.inject.service(),
    canvasService: Ember.inject.service(),
    ENTITY_LISTENERS: Ember.observer('model', function () {
      this.setupListeners();
    }),
    listenersEntity: null,
    setupListeners: function setupListeners() {
      var _this = this;

      this.cleanUpListeners();
      var newEntity = this.get('canvasService').wrapEntity(this.get('model'));

      if (newEntity) {
        newEntity.on(_ListenerConstants.default.ENTITY_ATTRIBUTES_CHANGE, function () {
          _this.updateParameters();
        }, {
          isDebounceLeading: false
        });
        this.set('listenersEntity', newEntity);
      }
    },
    cleanUpListeners: function cleanUpListeners() {
      var listenersEntity = this.get('listenersEntity');

      if (listenersEntity) {
        listenersEntity.removeAllListenersOnThisObject();
      }
    },
    parametersChanged: Ember.observer('parameters.@each', 'parameters.@each.key', 'parameters.@each.value', function () {
      this.get('model').set('parameters', this.get('parameters'));
      this.send('handleChange');
    }),
    updateParameters: function updateParameters() {
      if (!this.get('model').get('parameters')) {
        this.get('model').set('parameters', []);
      }

      this.set('parameters', this.get('model').get('parameters'));
    },
    didInsertElement: function didInsertElement() {
      this.setupListeners();
      this.updateParameters();
    },
    willDestroyElement: function willDestroyElement() {
      this.cleanUpListeners();
    },
    onChange: function onChange() {},
    actions: {
      add: function add() {
        if (this.get('model.attributes.type') === _CanvasConstants.default.TYPE_SOURCE) {
          this.get('parameters').addObject({
            key: null,
            value: null
          });
        } else {
          this.get('parameters').addObject({
            type: _AnalyticsConstants.default.PARAM_CONTAINS,
            key: null,
            value: null
          });
        }

        this.get('analyticsService').set('analyticsAreStale', true);
      },
      remove: function remove(parameter) {
        this.get('parameters').removeObject(parameter);
        this.get('analyticsService').set('analyticsAreStale', true);
      },
      handleChange: function handleChange() {
        var changeHandler = this.get('onChange');
        changeHandler();
      }
    }
  });

  _exports.default = _default;
});