define("funnelytics/components/left-sidebar/traffic-explorer", ["exports", "funnelytics/custom/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var GENERIC_PAGE_ICON = _constants.DEFAULT_ICONS_PATH + 'Generic Page.svg';
  var GENERIC_ACTION_ICON = _constants.UPDATED_ICON_PATH + 'Actions/click.svg';
  var GENERIC_TRAFFIC_ICON = "".concat(_constants.DEFAULT_ICONS_PATH, "Traffic-Icon.png");

  var _default = Ember.Component.extend({
    analyticsService: Ember.inject.service(),
    canvasService: Ember.inject.service(),
    explorerRegularData: Ember.computed.readOnly('analyticsService.explorerRegularData'),
    explorerExploredData: Ember.computed.readOnly('analyticsService.explorerExploredData'),
    isExplorerRegularLoading: Ember.computed.readOnly('explorerRegularData.isLoading'),
    isExplorerExploredEntityLoading: Ember.computed.readOnly('explorerExploredData.isLoading'),
    isExplorerLoading: Ember.computed.or('isExplorerRegularLoading', 'isExplorerExploredEntityLoading'),
    isExploredEntityStale: Ember.computed.readOnly('analyticsService.isExploredEntityStale'),
    exploredEntity: Ember.computed.readOnly('analyticsService.explorerExploredEntity'),
    hasExploredEntity: Ember.computed.readOnly('analyticsService.isExplorerExploredEntityAvailable'),
    actions: {
      reloadExplorerData: function reloadExplorerData() {
        if (this.get('hasExploredEntity')) {
          return this.get('analyticsService').reloadExploredEntityRequest();
        }

        return this.get('analyticsService').loadTrafficExplorerRequest({
          isClearingExisting: false
        });
      },
      addExplorerPage: function addExplorerPage(explorerPage) {
        var isIgnoreInBetween = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        //! Using 'page' here sucks
        this.get('canvasService').addStep('page', explorerPage.url, GENERIC_PAGE_ICON, explorerPage.url, [], isIgnoreInBetween);
      },
      addExplorerAction: function addExplorerAction(explorerAction) {
        var isIgnoreInBetween = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        //! Using 'event' here sucks
        this.get('canvasService').addStep('event', explorerAction.name, GENERIC_ACTION_ICON, explorerAction.name, [], isIgnoreInBetween);
      },
      addExplorerTraffic: function addExplorerTraffic(entityParameter) {
        var isIgnoreInBetween = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        //! Using 'source' here sucks
        var value = entityParameter.getValue();

        if (value === null) {
          value = '*';
        }

        var label = "".concat(entityParameter.getKey(), "=").concat(value);
        this.get('canvasService').addStep('source', label, GENERIC_TRAFFIC_ICON, undefined, [entityParameter], isIgnoreInBetween);
      },
      loadMoreRegularExplorer: function loadMoreRegularExplorer() {
        return this.get('analyticsService').loadTrafficExplorerRequestNextPage();
      },
      loadMoreExploredExplorer: function loadMoreExploredExplorer() {
        return this.get('analyticsService').loadTrafficExplorerExploredEntityRequestNextPage();
      }
    }
  });

  _exports.default = _default;
});