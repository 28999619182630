define("funnelytics/components/account/subscription-billing/add-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['subscription-billing__add-on'],
    name: Ember.computed.readOnly('userSubscriptionAddOn.add_on.name'),
    quantity: Ember.computed.readOnly('userSubscriptionAddOn.quantity'),
    price: Ember.computed.readOnly('userSubscriptionAddOn.unit_amount_in_cents'),
    isQuantityDisplayed: Ember.computed.gt('userSubscriptionAddOn.quantity', 1)
  });

  _exports.default = _default;
});