define("funnelytics/helpers/add-two", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addTwo = addTwo;
  _exports.default = void 0;

  function addTwo(params) {
    return 2 + parseInt(params[0], 10);
  }

  var _default = Ember.Helper.helper(addTwo);

  _exports.default = _default;
});