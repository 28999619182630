define("funnelytics/components/for-reuse/ui-elements/vault-explaination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VAULT = {
    features: ['A Growing Database of Proven Funnels – $20,000+ Value', 'All Swipe Files – $5,000+ Value', 'Video Breakdowns – $1,500+ Value', 'Funnel Maps of Each Funnel – $1,500+ Value', 'Addition of 2 to 4 New Funnels Per Month – $2,000+ value', '**BONUS** ClickFunnel Templates – $10,000+ Value'],
    image: '/assets/orders/upsells/pro/vault.png',
    title: 'FUNNELYTICS VAULT',
    header: "The Funnelytics Vault is a database of funnel hacks from\n    Multi-millionaire entrepreneurs from all different industries!"
  };
  var VAULT_CLICKFUNNELS = {
    features: ['50+ Pre-built funnel templates that you can import in your Clickfunnels account', 'All templates are modelled exactly like the world’s best performing funnels', 'From Frank Kern, to Russell Brunson, to Digital Marketer, and dozens more', 'Hiring a designer and funnel builder to do this for you would easily cost $10,000+', 'Resell the templates to businesses and clients for whatever price you like'],
    image: '/assets/orders/upsells/pro/vault.png',
    title: '50+ Clickfunnel Templates',
    header: 'Get access to 50+ pre-built funnel templates from some of the world’s top converting funnels - modelled exactly after the real funnels!'
  };
  var PRO = {
    features: ['Collaborate with team and clients', 'Forecast the success of your funnels', 'Collect lead, customer and revenue data', 'Track actions people take on your pages (clicks, video views, etc)', 'Visualize the paths people take to become a lead or customer', 'Compare forecasts to real data to make better decisions'],
    image: '/assets/logomark.png',
    title: 'FUNNELYTICS PRO',
    header: 'Visually measure & optimize how customers flow through your funnel/website'
  };

  var _default = Ember.Component.extend({
    isVaultExplainationShown: false,
    didInsertElement: function didInsertElement() {
      if (this.get('explainationType') === 'vault') {
        this.set('content', VAULT);
      } else if (this.get('explainationType') === 'pro') {
        this.set('content', PRO);
      } else if (this.get('explainationType') === 'vault_clickfunnels') {
        this.set('content', VAULT_CLICKFUNNELS);
      }
    },
    actions: {
      expandVaultExplainationList: function expandVaultExplainationList() {
        this.toggleProperty('isVaultExplainationShown');
      }
    }
  });

  _exports.default = _default;
});