define("funnelytics/custom/misc/shapes", ["exports", "funnelytics/custom/constants", "funnelytics/custom/constants/CanvasConstants", "funnelytics/custom/misc/shapemarkup"], function (_exports, _constants, _CanvasConstants, _shapemarkup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _attrs, _attrs2, _attrs3;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = {
    funnel_misc: {
      Bridge: joint.dia.Link.extend({
        defaults: joint.util.deepSupplement({
          markup: ['<path class="connection" stroke="black" d="M 0 0 0 0"/>', '<g class="labels"/>', '<g class="marker-vertices"/>', '<g class="link-tools"/>'].join(''),
          type: _CanvasConstants.default.TYPE_BRIDGE,
          attrs: {
            '.connection': {
              stroke: 'black',
              'stroke-width': 2
            }
          }
        }, joint.dia.Link.prototype.defaults)
      }),
      Connection: joint.dia.Link.define('funnel_misc.Connection', {
        markup: _shapemarkup.default[_CanvasConstants.default.TYPE_CONNECTION]['markup'],
        vertexMarkup: _shapemarkup.default[_CanvasConstants.default.TYPE_CONNECTION]['vertexMarkup'],
        z: 1,
        attrs: {
          '.connection': {
            'font-size': 12,
            stroke: '#009BF3',
            strokeWidth: 1.5,
            strokeLinejoin: 'round',
            'stroke-linecap': 'round',
            targetMarker: {
              d: "M5.707,9.707c-0.391,0.391-1.023,0.391-1.414,0l-4-4c-0.391-0.391-0.391-1.023,0-1.414l4-4\n             c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414L2.414,5l3.293,3.293C6.098,8.684,6.098,9.316,5.707,9.707z",
              class: 'connection__arrowhead'
            }
          }
        }
      }),
      GhostLine: joint.dia.Link.extend({
        markup: _shapemarkup.default[_CanvasConstants.default.TYPE_GHOST_LINE]['markup'],
        arrowheadMarkup: _shapemarkup.default[_CanvasConstants.default.TYPE_GHOST_LINE]['arrowheadMarkup'],
        defaults: joint.util.deepSupplement({
          type: _CanvasConstants.default.TYPE_GHOST_LINE,
          z: 1,
          attrs: {
            '.connection': {
              stroke: '#009BF3',
              'stroke-width': 1.5,
              'stroke-dasharray': 5,
              targetMarker: {
                d: _shapemarkup.default.defaultLinkArrowhead,
                class: 'connection__arrowhead'
              }
            }
          }
        }, joint.dia.Link.prototype.defaults)
      }),
      Group: joint.shapes.basic.Rect.extend({
        defaults: joint.util.deepSupplement({
          type: _CanvasConstants.default.TYPE_GROUP,
          attrs: {
            rect: {
              fill: 'transparent',
              stroke: 'lightgray',
              'stroke-width': 2,
              'stroke-dasharray': '5,5'
            }
          }
        }, joint.shapes.basic.Rect.prototype.defaults)
      }),
      Dot: joint.shapes.basic.Circle.extend({
        markup: [{
          tagName: 'circle'
        }],
        defaults: joint.util.deepSupplement({
          type: _CanvasConstants.default.TYPE_DOT,
          size: {
            width: 2,
            height: 2
          },
          attrs: {
            circle: {
              fill: 'black',
              cx: 2,
              cy: 2,
              r: 2,
              stroke: 'none'
            }
          }
        }, joint.shapes.basic.Circle.prototype.defaults)
      }),
      // CustomImage: joint.shapes.standard.Image.extend({
      //   defaults: joint.util.deepSupplement(
      //     {
      //       type: '',
      //       size: {
      //         width: 150,
      //         height: 100,
      //       },
      //       attrs: {
      //         image: {
      //           'xlinkHref': 'https://funnelytics-production.s3-us-west-2.amazonaws.com/icons/standard/utility-icons/custom-img.svg'
      //         }
      //       }
      //     },
      //     joint.shapes.basic.Circle.prototype.defaults
      //   )
      // }),
      TextArea: joint.shapes.standard.Rectangle.extend({
        defaults: joint.util.deepSupplement({
          type: _CanvasConstants.default.TYPE_TEXTAREA
        }, joint.shapes.standard.Rectangle.prototype.defaults)
      }),
      RemoveLink: joint.linkTools.Remove.extend({
        focusOpacity: 0.5,
        rotate: false,
        markup: [{
          tagName: 'circle',
          selector: 'button',
          event: 'link:removeButton',
          attributes: {
            r: 7,
            fill: '#F56060',
            cursor: 'pointer'
          }
        }, {
          tagName: 'path',
          selector: 'icon',
          attributes: {
            d: 'M -3 -3 3 3 M -3 3 3 -3',
            fill: 'none',
            stroke: 'white',
            'stroke-width': 2
          }
        }]
      })
    },
    funnel_steps: {
      Page: joint.dia.Element.extend({
        defaults: joint.util.deepSupplement({
          markup: _shapemarkup.default[_CanvasConstants.default.TYPE_PAGE].markup,
          type: _CanvasConstants.default.TYPE_PAGE,
          size: {
            width: 105,
            height: 140
          },
          z: 2,
          attrs: (_attrs = {
            label__wrapper: {
              display: 'block',
              'z-index': 100,
              refX: 0,
              refY: 0
            },
            label__rectangle: {
              ref: '.label',
              fill: 'white',
              refWidth: '100%',
              refHeight: '100%',
              refX: 0,
              refY: 0
            },
            visits__rectangle: {
              ref: _CanvasConstants.default.ENTITY_NUMBERS_LABEL,
              fill: 'white',
              refWidth: '100%',
              refHeight: '100%',
              refX: 0,
              refY: 0
            },
            forecast__wrapper: {
              display: 'block'
            },
            visits__wrapper: {
              display: 'block'
            },
            compare__wrapper: {
              display: 'block'
            },
            forecast__rectangle: {
              ref: _CanvasConstants.default.ENTITY_FORECAST_LABEL,
              fill: 'white',
              refWidth: '100%',
              refHeight: '100%',
              refX: 0,
              refY: 0
            },
            '.label': {
              textVerticalAnchor: 'bottom',
              y: -2,
              'font-size': 12,
              textWrap: {
                text: '',
                width: '300%',
                height: 'auto'
              },
              'text-anchor': 'middle'
            }
          }, _defineProperty(_attrs, _CanvasConstants.default.ENTITY_NUMBERS_LABEL, {
            'text-anchor': 'middle',
            x: 52.5,
            y: 145,
            'font-size': 12,
            fill: 'black'
          }), _defineProperty(_attrs, _CanvasConstants.default.ENTITY_FORECAST_LABEL, {
            'font-size': 12,
            fill: '#ED724B',
            'text-anchor': 'middle',
            x: 52.5,
            y: 185
          }), _defineProperty(_attrs, _CanvasConstants.default.ENTITY_COMPARE_SOURCE_LABEL, {
            'text-anchor': 'middle',
            'font-size': 12,
            fill: 'black',
            'font-weight': 'bold',
            x: 52.5,
            y: 165
          }), _defineProperty(_attrs, _CanvasConstants.default.ENTITY_COMPARE_TARGET_LABEL, {
            'font-size': 12,
            'font-weight': 'bold',
            fill: '#934CFF',
            ref: _CanvasConstants.default.ENTITY_COMPARE_SOURCE_LABEL,
            refX: 0,
            y: 180
          }), _defineProperty(_attrs, _CanvasConstants.default.ENTITY_COMPARE_DIFF_LABEL, {
            ref: _CanvasConstants.default.ENTITY_COMPARE_SOURCE_LABEL,
            refX: '120%',
            y: 165,
            'font-weight': 'bold',
            'font-size': 12,
            fill: 'black'
          }), _defineProperty(_attrs, '.body', {
            ref: 'image',
            refHeight: '100%',
            refWidth: '100%'
          }), _defineProperty(_attrs, "image", {
            width: 105,
            height: 140,
            'xlink:href': _constants.DEFAULT_ICONS_PATH + 'Generic Page.svg'
          }), _defineProperty(_attrs, '.previewthumbnail', {
            width: 108,
            height: 144,
            'xlink:href': _constants.DEFAULT_ICONS_PATH + 'Generic Page.svg'
          }), _defineProperty(_attrs, "g", {
            display: 'none'
          }), _defineProperty(_attrs, "url", {
            text: ''
          }), _defineProperty(_attrs, "goal_name", {
            text: ''
          }), _defineProperty(_attrs, "goal_value", {
            text: ''
          }), _defineProperty(_attrs, "goal_source", {
            text: ''
          }), _defineProperty(_attrs, 'in-out-step--in', {
            ref: 'image',
            refY: '110%',
            cursor: 'pointer',
            refX: -20,
            event: 'cell:in-step:pointerup',
            visibility: 'hidden'
          }), _defineProperty(_attrs, 'in-out-step--out', {
            event: 'cell:out-step:pointerup',
            ref: 'image',
            cursor: 'pointer',
            refY: '110%',
            refDx: 5,
            visibility: 'hidden'
          }), _defineProperty(_attrs, 'in-out-step--in-label', {
            ref: '.in-out-step--in',
            refX: '25%',
            refY: '25%',
            text: 'In',
            fill: '#0ed073',
            event: 'cell:in-step:pointerup',
            fontSize: 12,
            visibility: 'hidden'
          }), _defineProperty(_attrs, 'in-out-step--out-label', {
            ref: '.in-out-step--out',
            refX: '25%',
            refY: '30%',
            text: 'Out',
            fill: '#f43b46',
            event: 'cell:out-step:pointerup',
            fontSize: 12,
            visibility: 'hidden'
          }), _attrs)
        }, joint.dia.Element.prototype.defaults)
      }),
      Event: joint.dia.Element.extend({
        defaults: joint.util.deepSupplement({
          markup: _shapemarkup.default[_CanvasConstants.default.TYPE_EVENT].markup,
          type: _CanvasConstants.default.TYPE_EVENT,
          size: {
            width: 45,
            height: 45
          },
          attrs: (_attrs2 = {
            '.label': {
              textVerticalAnchor: 'bottom',
              x: 20,
              y: -2,
              'font-size': 12,
              textWrap: {
                text: '',
                width: '300%',
                height: 'auto'
              },
              'text-anchor': 'middle'
            },
            label__wrapper: {
              display: 'block',
              'z-index': 100,
              refX: 0,
              refY: 0
            },
            compare__wrapper: {
              display: 'block'
            },
            label__rectangle: {
              ref: '.label',
              fill: 'white',
              refWidth: '100%',
              refHeight: '100%',
              refX: 0,
              refY: 0
            }
          }, _defineProperty(_attrs2, _CanvasConstants.default.ENTITY_NUMBERS_LABEL, {
            'text-anchor': 'middle',
            x: 30,
            y: 50,
            'font-size': 12,
            fill: 'black'
          }), _defineProperty(_attrs2, _CanvasConstants.default.ENTITY_LEAD_TIME_LABEL, {
            'text-anchor': 'middle',
            x: 30,
            y: 75,
            'font-size': 12,
            fill: 'black'
          }), _defineProperty(_attrs2, _CanvasConstants.default.ENTITY_FORECAST_LABEL, {
            'font-size': 12,
            fill: '#ED724B',
            textVerticalAnchor: 'bottom',
            textAnchor: 'middle',
            x: 30,
            y: 75
          }), _defineProperty(_attrs2, _CanvasConstants.default.ENTITY_COMPARE_SOURCE_LABEL, {
            'text-anchor': 'middle',
            'font-size': 12,
            fill: 'black',
            'font-weight': 'bold',
            x: 52.5,
            y: 165
          }), _defineProperty(_attrs2, _CanvasConstants.default.ENTITY_COMPARE_TARGET_LABEL, {
            'font-size': 12,
            'font-weight': 'bold',
            fill: '#934CFF',
            ref: _CanvasConstants.default.ENTITY_COMPARE_SOURCE_LABEL,
            refX: 0,
            y: 180
          }), _defineProperty(_attrs2, _CanvasConstants.default.ENTITY_COMPARE_DIFF_LABEL, {
            ref: _CanvasConstants.default.ENTITY_COMPARE_SOURCE_LABEL,
            refX: '120%',
            y: 165,
            'font-size': 12,
            'font-weight': 'bold',
            fill: 'black'
          }), _defineProperty(_attrs2, "visits__rectangle", {
            ref: [_CanvasConstants.default.ENTITY_NUMBERS_LABEL],
            fill: 'white',
            refWidth: '100%',
            refHeight: '100%',
            refX: 0,
            refY: 0
          }), _defineProperty(_attrs2, "forecast__wrapper", {
            display: 'block'
          }), _defineProperty(_attrs2, "visits__wrapper", {
            display: 'block'
          }), _defineProperty(_attrs2, "lead_time__wrapper", {
            display: 'block'
          }), _defineProperty(_attrs2, "forecast__rectangle", {
            ref: _CanvasConstants.default.ENTITY_FORECAST_LABEL,
            fill: 'white',
            refWidth: '100%',
            refHeight: '100%',
            refX: 0,
            refY: 0
          }), _defineProperty(_attrs2, "lead_time__rectangle", {
            ref: _CanvasConstants.default.ENTITY_LEAD_TIME_LABEL,
            fill: 'white',
            refWidth: '100%',
            refHeight: '100%',
            refX: 0,
            refY: 0
          }), _defineProperty(_attrs2, "image", {
            width: 45,
            height: 45,
            'xlink:href': "".concat(_constants.DEFAULT_ICONS_PATH, "Landing-Page.png")
          }), _defineProperty(_attrs2, '.event-target', {
            ref: 'image',
            refX: '50%',
            refY: '50%',
            r: '2px',
            height: '5px',
            fill: 'none'
          }), _attrs2),
          meta: {
            properties: []
          }
        }, joint.dia.Element.prototype.defaults)
      }),
      Offline: joint.dia.Element.extend({
        defaults: joint.util.deepSupplement({
          markup: _shapemarkup.default[_CanvasConstants.default.TYPE_OFFLINE].markup,
          type: _CanvasConstants.default.TYPE_OFFLINE,
          size: {
            width: 40,
            height: 40
          },
          attrs: {
            label__wrapper: {
              display: 'block',
              x: 30,
              y: -15
            },
            label__rectangle: {
              ref: '.label',
              refWidth: '100%',
              refHeight: '100%',
              refX: 0,
              refY: 0
            },
            '.label': {
              textVerticalAnchor: 'bottom',
              'text-anchor': 'middle',
              ref: 'image',
              x: 20,
              y: -2,
              'font-size': 12,
              textWrap: {
                text: '',
                width: '300%',
                height: 'auto'
              }
            },
            image: {
              width: 40,
              height: 40
            },
            url: {
              text: ''
            },
            goal_name: {
              text: ''
            },
            goal_value: {
              text: ''
            },
            goal_source: {
              text: ''
            }
          }
        }, joint.dia.Element.prototype.defaults)
      }),
      Source: joint.dia.Element.extend({
        defaults: joint.util.deepSupplement({
          markup: _shapemarkup.default[_CanvasConstants.default.TYPE_SOURCE].markup,
          type: _CanvasConstants.default.TYPE_SOURCE,
          size: {
            width: 45,
            height: 45
          },
          attrs: (_attrs3 = {
            label__wrapper: {
              display: 'block',
              x: 30,
              y: -15
            },
            compare__wrapper: {
              display: 'block'
            },
            label__rectangle: {
              ref: '.label',
              refWidth: '100%',
              refHeight: '100%',
              refX: 0,
              refY: 0
            },
            '.label': {
              textVerticalAnchor: 'bottom',
              'text-anchor': 'middle',
              ref: 'image',
              x: _CanvasConstants.default.TEXT_X_OFFSET_ACTION_CENTER,
              y: -2,
              'font-size': 12,
              textWrap: {
                text: '',
                width: '300%',
                height: 'auto'
              }
            }
          }, _defineProperty(_attrs3, _CanvasConstants.default.ENTITY_NUMBERS_LABEL, {
            'text-anchor': 'middle',
            textVerticalAnchor: 'bottom',
            y: 85,
            'font-size': 12,
            fill: 'black'
          }), _defineProperty(_attrs3, _CanvasConstants.default.ENTITY_FORECAST_LABEL, {
            'font-size': 12,
            fill: '#ED724B',
            'text-anchor': 'middle',
            textVerticalAnchor: 'bottom',
            x: _CanvasConstants.default.TEXT_X_OFFSET_ACTION_CENTER,
            y: 100
          }), _defineProperty(_attrs3, _CanvasConstants.default.ENTITY_COMPARE_SOURCE_LABEL, {
            'text-anchor': 'middle',
            'font-size': 12,
            fill: 'black',
            'font-weight': 'bold',
            x: 52.5,
            y: 165
          }), _defineProperty(_attrs3, _CanvasConstants.default.ENTITY_COMPARE_TARGET_LABEL, {
            'font-size': 12,
            'font-weight': 'bold',
            fill: '#934CFF',
            ref: _CanvasConstants.default.ENTITY_COMPARE_SOURCE_LABEL,
            refX: 0,
            y: 180
          }), _defineProperty(_attrs3, _CanvasConstants.default.ENTITY_COMPARE_DIFF_LABEL, {
            ref: _CanvasConstants.default.ENTITY_COMPARE_SOURCE_LABEL,
            refX: '120%',
            y: 165,
            'font-size': 12,
            'font-weight': 'bold',
            fill: 'black'
          }), _defineProperty(_attrs3, "visits__rectangle", {
            ref: [_CanvasConstants.default.ENTITY_NUMBERS_LABEL],
            fill: 'white',
            refWidth: '100%',
            refHeight: '100%',
            refX: 0,
            refY: 0
          }), _defineProperty(_attrs3, "forecast__wrapper", {
            display: 'block'
          }), _defineProperty(_attrs3, "visits__wrapper", {
            display: 'block'
          }), _defineProperty(_attrs3, "forecast__rectangle", {
            ref: _CanvasConstants.default.ENTITY_FORECAST_LABEL,
            fill: 'white',
            refWidth: '100%',
            refHeight: '100%',
            refX: 0,
            refY: 0
          }), _defineProperty(_attrs3, "image", {
            width: 45,
            height: 45,
            'xlink:href': "".concat(_constants.DEFAULT_ICONS_PATH, "Traffic-Icon.png")
          }), _defineProperty(_attrs3, "utm_source", {
            text: ''
          }), _defineProperty(_attrs3, "utm_medium", {
            text: ''
          }), _defineProperty(_attrs3, "utm_campaign", {
            text: ''
          }), _defineProperty(_attrs3, "utm_content", {
            text: ''
          }), _defineProperty(_attrs3, "utm_term", {
            text: ''
          }), _defineProperty(_attrs3, "goal_name", {
            text: ''
          }), _defineProperty(_attrs3, "goal_value", {
            text: ''
          }), _defineProperty(_attrs3, "goal_source", {
            text: ''
          }), _attrs3)
        }, joint.dia.Element.prototype.defaults)
      })
    }
  };
  _exports.default = _default;
});