define("funnelytics/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "funnelytics/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This authenticator probably not used anymore, need to double-check
  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: "".concat(_environment.default.endpoint, "/auth")
  });

  _exports.default = _default;
});