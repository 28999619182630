define("funnelytics/components/account/account-details", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    versionManager: Ember.inject.service(),
    activeProjectVersion: Ember.computed('versionManager.activeDashboardVersion', function () {
      return this.get('versionManager.activeDashboardVersion');
    }),
    icon: '',
    content: '',
    newEmail: '',
    showAccountModal: function showAccountModal() {},
    updateBasedOnModel: Ember.observer('user.{email,first_name,last_name}', function () {
      this.resetInputFields();
    }),
    emailUpdated: false,
    saveButtonCopy: 'Save',
    successfullyUpdatedFields: [],
    nameHasBeenUpdated: Ember.computed('successfullyUpdatedFields.@each', function () {
      return this.get('successfullyUpdatedFields').includes('first_name') || this.get('successfullyUpdatedFields').includes('last_name');
    }),
    emailHasBeenUpdated: Ember.computed('successfullyUpdatedFields.@each', function () {
      return this.get('successfullyUpdatedFields').includes('email');
    }),
    firstNameIcon: Ember.computed('userInformation.first_name', function () {
      var firstName = this.get('userInformation.first_name');

      if (!firstName) {
        this.set('firstNameWarning', 'You must provide a first name');
        return 'error-icon';
      } else {
        this.set('firstNameWarning', '');
      }
    }),
    lastNameIcon: Ember.computed('userInformation.last_name', function () {
      var lastName = this.get('userInformation.last_name');

      if (!lastName) {
        this.set('lastNameWarning', 'You must provide a last name');
        return 'error-icon';
      } else {
        this.set('lastNameWarning', '');
      }
    }),
    emailIcon: Ember.computed('userInformation.email', function () {
      var email = this.get('userInformation.email');

      if (!email) {
        this.set('emailWarning', 'You must provide an email');
        return 'error-icon';
      } else {
        this.set('emailWarning', '');
      }
    }),
    userInformation: Ember.Object.create({}),
    currentUserInformation: Ember.Object.create({}),
    submitting: false,
    accountDetailsDisabled: Ember.computed.alias('submitting'),
    saveDisabled: Ember.computed('submitting', 'userInformation.first_name', 'userInformation.last_name', 'userInformation.email', function () {
      if (this.get('submitting')) {
        return true;
      }

      var userInfo = this.get('userInformation');

      if (_lodash.default.isEqual(userInfo, this.get('currentUserInformation'))) {
        return true;
      }

      if (!userInfo.first_name || !userInfo.last_name || !userInfo.email) {
        return true;
      }

      return false;
    }),
    resetStoredUserInfo: function resetStoredUserInfo() {
      this.set('currentUserInformation', {
        first_name: this.get('user.first_name'),
        last_name: this.get('user.last_name'),
        email: this.get('user.email')
      });
    },
    resetInputFields: function resetInputFields() {
      this.set('userInformation', {
        first_name: this.get('user.first_name'),
        last_name: this.get('user.last_name'),
        email: this.get('user.email')
      });
      this.set('error', '');
      this.set('accountDetailsConfirmation', '');
    },
    didInsertElement: function didInsertElement() {
      this.set('firstNameWarning', '');
      this.set('lastNameWarning', '');
      this.resetStoredUserInfo();
      this.resetInputFields();
    },
    actions: {
      saveUserDetails: function saveUserDetails() {
        var _this = this;

        this.set('successfullyUpdatedFields', []);
        this.set('newEmail', this.get('userInformation.email'));
        this.set('submitting', true);
        this.get('onSubmit')(this.get('userInformation')).then(function (updatedFields) {
          _this.resetStoredUserInfo();

          _this.set('successfullyUpdatedFields', updatedFields);

          _this.set('submitting', false);
        }).catch(function () {
          _this.set('error', true);

          _this.set('submitting', false);
        });
      },
      showAccountModal: function showAccountModal() {
        var handler = this.get('showAccountModal');
        handler();
      }
    }
  });

  _exports.default = _default;
});