define("funnelytics/components/containers/funnel-editor/components/right-sidebar-mapping-section", ["exports", "lodash", "funnelytics/custom/misc/icons", "funnelytics/custom/constants/CanvasConstants"], function (_exports, _lodash, _icons, _CanvasConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ELEMENT_TYPES = [_CanvasConstants.default.JSON_TYPE_PAGE, _CanvasConstants.default.JSON_TYPE_SOURCE, _CanvasConstants.default.JSON_TYPE_EVENT, _CanvasConstants.default.JSON_TYPE_OFFLINE];

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    allPages: [],
    allTraffic: [],
    allOffline: [],
    allEvents: [],
    concatCustomAndStandard: function concatCustomAndStandard() {
      var _this = this;

      ELEMENT_TYPES.forEach(function (type) {
        var userGeneratedIcons = _this.get('store').peekAll('icon').toArray();

        var customIcons = userGeneratedIcons.filter(function (icon) {
          return icon.type === type;
        }).map(function (icon) {
          return {
            name: icon.name,
            path: icon.path,
            customIcon: true
          };
        });

        if (type === _CanvasConstants.default.JSON_TYPE_PAGE) {
          _this.set('allPages', _lodash.default.concat(_icons.default.page, customIcons));
        } else if (type === _CanvasConstants.default.JSON_TYPE_SOURCE) {
          _this.set('allTraffic', _lodash.default.concat(_icons.default.source, []));
        } else if (type === _CanvasConstants.default.JSON_TYPE_EVENT) {
          _this.set('allEvents', _icons.default.event);
        } else if (type === _CanvasConstants.default.JSON_TYPE_OFFLINE) {
          _this.set('allOffline', _lodash.default.concat(_icons.default.offline, customIcons));
        }
      });
    },
    didInsertElement: function didInsertElement() {
      this.concatCustomAndStandard();
    },
    onUpdateThumbnail: function onUpdateThumbnail() {},
    onUpdateIcon: function onUpdateIcon() {},
    actions: {
      updateThumbnail: function updateThumbnail() {
        var updateHandler = this.get('onUpdateThumbnail');
        updateHandler.apply(void 0, arguments);
      },
      updateIcon: function updateIcon() {
        var updateHandler = this.get('onUpdateIcon');
        updateHandler.apply(void 0, arguments);
      }
    }
  });

  _exports.default = _default;
});