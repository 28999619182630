define("funnelytics/components/containers/funnel-editor/components/step-list-item-radio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    onSelect: function onSelect() {},
    onFind: function onFind() {},
    actions: {
      handleSelect: function handleSelect() {
        var selectHandler = this.get('onSelect');
        selectHandler(this.get('stepId'));
      },
      handleFind: function handleFind() {
        var findHandler = this.get('onFind');
        findHandler(this.get('stepId'));
      }
    }
  });

  _exports.default = _default;
});