define("funnelytics/templates/components/canvas-elements/explorer/action-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5q0Dj+w+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\"],[11,\"class\",\"context-menu__explorer-item\"],[3,\"action\",[[22,0,[]],\"clickActionItem\"]],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"context-menu__explorer-item-label\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isRecognizedAction\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"for-reuse/tracking-standard-icon\",null,[[\"additionalClasses\"],[\"context-menu__explorer-item-icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[21,\"displayName\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"context-menu__explorer-item-hits\"],[9],[0,\"\\n    \"],[1,[21,\"hits\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/explorer/action-list-item.hbs"
    }
  });

  _exports.default = _default;
});