define("funnelytics/patch/project-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isLocked = void 0;

  // Monkey patching the project model PATCH route response error
  var isLocked = function isLocked(errorEvent) {
    // This patch is implemented to fix a known bug with the is_locked attribute in the project model.
    // Additional considerations need to be made to reloading this object since this patch route will
    // not be able to update the model with the return object.
    var err = errorEvent.toString();
    return err.includes('TypeError: Cannot convert object to primitive value');
  };

  _exports.isLocked = isLocked;
});