define("funnelytics/components/funnel-pages/confirmation/invoice-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    description: Ember.computed.readOnly('invoiceItem.description'),
    totalInCents: Ember.computed.readOnly('invoiceItem.total_in_cents'),
    isDisplayed: Ember.computed('totalInCents', function () {
      var total = parseInt(this.get('totalInCents'), 10);
      return total > 0;
    })
  });

  _exports.default = _default;
});