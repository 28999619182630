define("funnelytics/mixins/permissions", ["exports", "funnelytics/custom/constants/ProjectPermissionConstants"], function (_exports, _ProjectPermissionConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);

      var IS_READONLY_ACCESS = _ProjectPermissionConstants.default.IS_READONLY_ACCESS,
          IS_COLLABORATOR = _ProjectPermissionConstants.default.IS_COLLABORATOR,
          IS_COLLABORATION_ALLOWED = _ProjectPermissionConstants.default.IS_COLLABORATION_ALLOWED,
          IS_ADMIN_IMPERSONATION = _ProjectPermissionConstants.default.IS_ADMIN_IMPERSONATION,
          IS_INTEGRATION_ALLOWED = _ProjectPermissionConstants.default.IS_INTEGRATION_ALLOWED,
          IS_ELIGIBLE_FOR_FREE_TRIAL = _ProjectPermissionConstants.default.IS_ELIGIBLE_FOR_FREE_TRIAL,
          IS_WEBHOOK_ALLOWED = _ProjectPermissionConstants.default.IS_WEBHOOK_ALLOWED,
          IS_ZAPIER_ALLOWED = _ProjectPermissionConstants.default.IS_ZAPIER_ALLOWED,
          UNLOCK_CANVAS_ALLOWANCE = _ProjectPermissionConstants.default.UNLOCK_CANVAS_ALLOWANCE,
          UNLOCKED_CANVASES_ALLOWED = _ProjectPermissionConstants.default.UNLOCKED_CANVASES_ALLOWED,
          UNLOCKED_CANVASES = _ProjectPermissionConstants.default.UNLOCKED_CANVASES;
      this.setProperties({
        IS_READONLY_ACCESS: IS_READONLY_ACCESS,
        IS_COLLABORATOR: IS_COLLABORATOR,
        IS_COLLABORATION_ALLOWED: IS_COLLABORATION_ALLOWED,
        IS_ADMIN_IMPERSONATION: IS_ADMIN_IMPERSONATION,
        IS_INTEGRATION_ALLOWED: IS_INTEGRATION_ALLOWED,
        IS_ELIGIBLE_FOR_FREE_TRIAL: IS_ELIGIBLE_FOR_FREE_TRIAL,
        IS_WEBHOOK_ALLOWED: IS_WEBHOOK_ALLOWED,
        IS_ZAPIER_ALLOWED: IS_ZAPIER_ALLOWED,
        UNLOCK_CANVAS_ALLOWANCE: UNLOCK_CANVAS_ALLOWANCE,
        UNLOCKED_CANVASES_ALLOWED: UNLOCKED_CANVASES_ALLOWED,
        UNLOCKED_CANVASES: UNLOCKED_CANVASES
      });
    }
  });

  _exports.default = _default;
});