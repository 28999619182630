define("funnelytics/templates/components/canvas-elements/utm-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wxWbKXOq",
    "block": "{\"symbols\":[\"value\"],\"statements\":[[7,\"select\"],[12,\"id\",[21,\"inputId\"]],[11,\"class\",\"utm-explorer__filter-item fl-input\"],[12,\"onchange\",[21,\"onChange\"]],[12,\"disabled\",[21,\"isDisabled\"]],[9],[0,\"\\n  \"],[7,\"option\"],[12,\"value\",[21,\"emptyValue\"]],[12,\"selected\",[27,\"eq\",[[23,[\"currentFilterValue\"]],null],null]],[9],[0,\"\\n    Select \"],[1,[21,\"emptyValueLabel\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"filteredValues\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"value\",[22,1,[]]],[12,\"selected\",[27,\"eq\",[[23,[\"currentFilterValue\"]],[22,1,[]]],null]],[9],[0,\"\\n      \"],[1,[22,1,[]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/utm-filter.hbs"
    }
  });

  _exports.default = _default;
});