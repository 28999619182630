define("funnelytics/templates/components/for-reuse/title-with-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XraYoKOU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"fl-title-with-icon__wrapper\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"icon\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\"],[11,\"class\",\"fl-title-with-icon__icon\"],[12,\"src\",[28,[[21,\"icon\"]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"span\"],[11,\"class\",\"fl-title-with-icon__title\"],[9],[0,\"\\n    \"],[1,[21,\"title\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/title-with-icon.hbs"
    }
  });

  _exports.default = _default;
});