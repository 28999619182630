define("funnelytics/controllers/dashboard/templates/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modal: Ember.inject.service('current-routed-modal'),
    actions: {
      deleteTemplate: function deleteTemplate(template) {
        var _this = this;

        template.destroyRecord().then(function () {
          _this.get('modal').close();

          _this.transitionToRoute('dashboard.templates');
        });
      }
    }
  });

  _exports.default = _default;
});