define("funnelytics/custom/analytics/AnalyticsRequest", ["exports", "@funnelytics/utilities", "lodash", "funnelytics/config/environment", "ember-uuid", "funnelytics/custom/analytics/analytics/canvas/CanvasEntity", "funnelytics/custom/constants/AnalyticsConstants", "funnelytics/custom/constants/CanvasConstants", "funnelytics/custom/debug/logger"], function (_exports, _utilities, _lodash, _environment, _emberUuid, _CanvasEntity, _AnalyticsConstants, _CanvasConstants, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  /**
   * This class manages requests the the returned data of those requests for one type of request.
   *
   * To use this class, it must be sub-classed to implement the key _sendRequest, _populateCanvasFilter
   * and _createAnalyticsData methods.
   *
   * Create a new instance of subclasses of this class whenever there is a new set of analytics data
   * that you want to have persist even if the request is the same as that of an existing
   * AnalyticsRequest instance. For example, there might be two ExplorerAnalyticsRequests since we
   * want to persist data for both the canvas-wide data but also for the data pertaining to single
   * "explored" entity so we can see its previous and next steps in particular. Having two instances
   * of this class will then let users switch between the canvas-wide data and the date on the
   * "explored" step without having to reload data each time.
   */
  var AnalyticsRequest = Ember.Object.extend({
    ajax: null,
    // required
    _host: _environment.default.analytics_endpoint,
    canReadAnalytics: false,
    currentRequestId: Ember.computed.readOnly('_currentRequestId'),
    _currentRequestId: null,
    init: function init() {
      this.set('_internalRequestTrackers', []);
    },
    mainAnalyticsData: Ember.computed.readOnly('_mainAnalyticsData'),
    send: function send(analyticsSettings, options) {
      return this._handleRequest(analyticsSettings, options);
    },
    LOADING_STATE_OBSERVER: Ember.observer('_internalRequestTrackers.length', function () {
      this._setIsLoading(this.get('_internalRequestTrackers.length') > 0);
    }),

    /**
     * Internal request trackers are used to determine if valid external currentRequestIds are still being awaited.
     */
    _addInternalRequestId: function _addInternalRequestId(internalRequestId, externalRequestId) {
      this.get('_internalRequestTrackers').addObject({
        internalId: internalRequestId,
        externalId: externalRequestId
      });
    },
    _clearInternalInternalRequestTrackers: function _clearInternalInternalRequestTrackers() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref$expiredInternalI = _ref.expiredInternalId,
          expiredInternalId = _ref$expiredInternalI === void 0 ? null : _ref$expiredInternalI;

      var currentRequestId = this.get('currentRequestId');
      var internalRequestIdsToClear = this.get('_internalRequestTrackers').filter(function (_ref2) {
        var internalId = _ref2.internalId,
            externalId = _ref2.externalId;

        if (expiredInternalId !== null && expiredInternalId === internalId) {
          return true;
        }

        if (currentRequestId !== externalId) {
          // No longer valid external request IDs:
          return true;
        }

        return false;
      });
      this.get('_internalRequestTrackers').removeObjects(internalRequestIdsToClear);
    },
    _hasPendingRequests: function _hasPendingRequests() {
      this._clearInternalInternalRequestTrackers();

      return this.get('_internalRequestTrackers.length') > 0;
    },
    _getAnalyticsApiHostname: function _getAnalyticsApiHostname() {
      return this.get('_host');
    },
    _getAjax: function _getAjax() {
      return this.get('ajax');
    },
    _formCanvasFilter: function _formCanvasFilter(analyticsSettings) {
      var canvasFilter = AnalyticsRequest.createCanvasFilter();

      this._populateCanvasFilter(canvasFilter, analyticsSettings);

      return canvasFilter;
    },
    _handleRequest: function _handleRequest(analyticsSettings) {
      var _this = this;

      var _ref3 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          requestId = _ref3.requestId,
          _ref3$isClearingExist = _ref3.isClearingExisting,
          isClearingExisting = _ref3$isClearingExist === void 0 ? true : _ref3$isClearingExist,
          _ref3$isPartial = _ref3.isPartial,
          isPartial = _ref3$isPartial === void 0 ? false : _ref3$isPartial;

      return Ember.RSVP.Promise.resolve(true).then(function () {
        // Stop requests for users with no access to analytics:
        if (!_this.get('canReadAnalytics')) {
          return false;
        }
        /**
         * Keep track of the request for the purposes of reporting on its status and identifying
         * which data belongs to which request.
         */


        _utilities.Assertion.string(requestId, {
          allowEmpty: false
        });

        var previousRequestId = _this.get('currentRequestId');

        if (previousRequestId !== requestId) {
          /**
           * This is a brand new request and the existing request cannot be preserved.
           * If we want to support something like this without changing these classes
           * we would likely want to create a new instance of AnalyticsRequest.
           */
          isPartial = false;
          isClearingExisting = true;
        }

        _this.set('_currentRequestId', requestId);

        var internalRequestId = (0, _emberUuid.v4)();
        return Ember.RSVP.Promise.resolve(true).then(function () {
          if (isClearingExisting) {
            _this._resetMainAnalyticsData();
          }

          if (!isPartial) {
            _this._handleIsNotPartial();
          }

          _this._addInternalRequestId(internalRequestId, requestId);
          /**
           * Object that will temporarily store the data for this individual request. Later, it will
           * either be appended to the mainAnalyticsData or its data will replace the date inside
           * mainAnalyticsData. Either way, this object will not be returned to the client.
           */


          var requestData = _this._createAnalyticsData({
            _requestId: _this.get('currentRequestId')
          });
          /**
           * Begin request
           */


          return _this._sendRequest(analyticsSettings).then(function (response) {
            /**
             * This request is no longer current and the response can be discarded.
             */
            if (!_this._isValidRequestId(requestId)) {
              //* It may be necessary to reload analytics here, rather than just returning the current AnalyticsData
              return _this._getMainAnalyticsData();
            }

            _this._handleValidRequestBeforePopulate(response);
            /**
             * Append to or replace the current data
             */


            requestData.populate(response);
            var acceptOptions = {};

            if (isPartial) {
              _lodash.default.set(acceptOptions, ['incorporationMethod'], _AnalyticsConstants.default.MERGE);
            }

            _this._getMainAnalyticsData().accept(requestData, acceptOptions);

            return _this._getMainAnalyticsData();
          }).catch(function (err) {
            _logger.default.error(err);

            if (_this._isValidRequestId(requestId)) {
              _this._getMainAnalyticsData().handleFailedRequest();
            }
          }).finally(function () {
            /**
             * End request
             */
            _this._clearInternalInternalRequestTrackers({
              expiredInternalId: internalRequestId
            });
          });
        });
      });
    },
    _setIsLoading: function _setIsLoading(isLoading) {
      _utilities.Assertion.boolean(isLoading);

      this._getMainAnalyticsData().setIsLoading(isLoading);
    },
    _isValidRequestId: function _isValidRequestId(requestId) {
      return this.get('currentRequestId') === requestId;
    },

    /**
     * Gets the AnalyticsData object assigned to each instance of AnalyticsRequest. While
     * new analyticsData objects are made when servicing a request, these are always then
     * used as an argument for the accept method on the _mainAnalyticsData object, updating
     * it to store the new values. This allows values that have not been changed or have
     * only been appended to to avoid forcing a re-render in templates that depend on them.
     */
    _getMainAnalyticsData: function _getMainAnalyticsData() {
      return this.get('mainAnalyticsData');
    },
    _resetMainAnalyticsData: function _resetMainAnalyticsData() {
      return this.set('_mainAnalyticsData', this._createAnalyticsData());
    },

    /**
     * Return a new related AnalyticsData class that will be populated by send and sent to the requester.
     */
    _createAnalyticsData: function _createAnalyticsData() {
      throw new Error('Implement _createAnalyticsData inside a subclass of AnalyticsRequest');
    },
    _sendRequest: function _sendRequest() {
      throw new Error('Implement _sendRequest inside a subclass of AnalyticsRequest');
    },
    _populateCanvasFilter: function _populateCanvasFilter() {
      throw new Error('Implement _populateCanvasFilter inside a subclass of AnalyticsRequest');
    },
    // Implement in subclass
    _handleValidRequestBeforePopulate: function _handleValidRequestBeforePopulate() {},
    _handleIsNotPartial: function _handleIsNotPartial() {}
  });
  AnalyticsRequest.reopenClass({
    /**
     * Artifact from old analytics build. This will need to be rethought at a later time. This should
     * use a new CanvasFilter class;
     */
    createCanvasFilter: function createCanvasFilter() {
      return {
        dictionary: {},
        connections: [],
        isDisabled: false
      };
    },

    /**
     * Re-used from old analytics build. This will need to be rethought at a later time:
     */
    addEntitiesToCanvasFilter: function addEntitiesToCanvasFilter(canvasFilter, entitiesToAdd) {
      var validEntitiesToAdd = entitiesToAdd.filter(function (entity) {
        return entity instanceof _CanvasEntity.default;
      });

      var finalEntitiesToAdd = _toConsumableArray(validEntitiesToAdd);

      validEntitiesToAdd.forEach(function (entity) {
        if (entity.isDependent()) {
          [entity.getDependentSource(), entity.getDependentTarget()].filter(function (entity) {
            return Boolean(entity);
          }).forEach(function (entity) {
            finalEntitiesToAdd.push(entity);
          });
        }
      });
      finalEntitiesToAdd.forEach(function (entity) {
        if (entity.isLineType() || entity.getType() === _CanvasConstants.default.TYPE_EVENT && entity.isSimpleAction()) {
          var isSimpleActionWithNoConnections = entity.isSimpleAction() && (entity.getOutboundLinks({
            isIgnoreInBetweenAllowed: false
          }).length === 0 || entity.getInboundLinks({
            isIgnoreInBetweenAllowed: false
          }).length === 0);
          var isConnectionInsertionRequired = !isSimpleActionWithNoConnections && !canvasFilter.connections.some(function (connection) {
            return connection.id === entity.getID();
          });

          if (isConnectionInsertionRequired) {
            canvasFilter.connections.push(entity.toJSON());
          }
        } else {
          if (!_lodash.default.has(canvasFilter.dictionary, entity.getID())) {
            _lodash.default.set(canvasFilter.dictionary, entity.getID(), entity.toJSON());
          }
        }
      });
    }
  });
  var _default = AnalyticsRequest;
  _exports.default = _default;
});