define("funnelytics/templates/components/account/subscription-billing/add-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dgUKv2fV",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"name\"],false],[0,\" - \"],[1,[27,\"format-currency\",[[23,[\"price\"]],\"cents\"],null],false],[0,\" USD\\n\"],[4,\"if\",[[23,[\"isQuantityDisplayed\"]]],null,{\"statements\":[[0,\"  (x\"],[1,[21,\"quantity\"],false],[0,\")\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/account/subscription-billing/add-on.hbs"
    }
  });

  _exports.default = _default;
});