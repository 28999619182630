define("funnelytics/components/canvas-elements/project-change-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    canvasService: Ember.inject.service(),
    hasAnalyticsReadPermission: Ember.computed.readOnly('canvasService.hasAnalyticsReadPermission'),
    hasForecastReadPermission: Ember.computed.readOnly('canvasService.hasForecastReadPermission'),
    explanationMessage: Ember.computed('hasAnalyticsReadPermission', 'hasForecastReadPermission', function () {
      if (this.get('hasAnalyticsReadPermission')) {
        return 'Move this funnel to a workspace to track & analyze.';
      }

      if (this.get('hasForecastReadPermission')) {
        return 'Move this funnel to a workspace to use forecasting.';
      }

      return '';
    }),
    onSelectProject: function onSelectProject() {},
    actions: {
      selectProject: function selectProject(id) {
        var handler = this.get('onSelectProject');
        handler(id);
      }
    }
  });

  _exports.default = _default;
});