define("funnelytics/templates/components/permissions/has-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5F6NnOn8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"pending\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"showLoadingDots\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"loading-dots\",null,[[\"large\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1,[[23,[\"permitted\"]],[23,[\"error\"]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/permissions/has-permission.hbs"
    }
  });

  _exports.default = _default;
});