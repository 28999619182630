define("funnelytics/initializers/ember-routable-modal", ["exports", "funnelytics/config/environment", "ember-routable-modal/configuration"], function (_exports, _environment, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'ember-routable-modal',
    initialize: function initialize() {
      var config = _environment.default['ember-routable-modal'] || {};

      _configuration.default.load(config);

      Ember.Router.reopen({
        currentRoutedModalService: Ember.inject.service('current-routed-modal'),
        willTransition: function willTransition() {
          this._super.apply(this, arguments);

          this.get('currentRoutedModalService').clear();
        }
      });
    }
  };
  _exports.default = _default;
});