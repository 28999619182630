define("funnelytics/components/canvas-elements/entity-toolbar/arrowhead-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      selectArrowhead: function selectArrowhead(type) {
        var arrowHeadSelect = this.get('onArrowheadSelect');
        arrowHeadSelect(type);
      }
    }
  });

  _exports.default = _default;
});