define("funnelytics/services/drag-manager", ["exports", "lodash", "funnelytics/custom/constants/CanvasConstants"], function (_exports, _lodash, _CanvasConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    element: null,
    shape: null,
    grabbingClass: 'grabbing',
    dragging: false,
    coordinates: {},
    styledCoordinates: Ember.computed('coordinates', function () {
      var coordinates = this.get('coordinates');
      return Ember.String.htmlSafe("top: ".concat(coordinates.y, "px; left: ").concat(coordinates.x, "px;"));
    }),
    imagePath: Ember.computed('element', 'shape', function () {
      if (this.get('element')) {
        return this.get('element.icon.path');
      }

      if (this.get('shape')) {
        return _lodash.default.get(_CanvasConstants.default.ICON_IMAGES, this.get('shape'), '');
      }
    }),
    reset: function reset() {
      this.setProperties({
        element: null,
        shape: null
      });
      this.end();
    },
    start: function start() {
      var _this = this;

      this.set('dragging', true);
      Ember.$('#paper .joint-paper-scroller').addClass(this.get('grabbingClass'));
      this.set('_mm_handler', function (evt) {
        _this.set('coordinates', {
          x: evt.pageX,
          y: evt.pageY
        });
      });
      Ember.$(document).on('mousemove', this.get('_mm_handler'));
    },
    end: function end() {
      this.set('dragging', false);
      Ember.$('#paper .joint-paper-scroller').removeClass(this.get('grabbingClass'));
      Ember.$(document).off('mousemove', this.get('_mm_handler'));
      this.set('_mm_handler', null);
    },
    isDragging: function isDragging() {
      return this.get('dragging');
    }
  });

  _exports.default = _default;
});