define("funnelytics/components/funnel-pages/checkout/payment-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onSubmitMethod: function onSubmitMethod() {},
    onChangePostalCode: function onChangePostalCode() {},
    onSelectExistingCard: function onSelectExistingCard() {},
    onSelectCountry: function onSelectCountry() {},
    actions: {
      submitMethod: function submitMethod() {
        var submitMethodHandler = this.get('onSubmitMethod');
        submitMethodHandler();
      },
      changePostalCode: function changePostalCode(eventObject) {
        var changePostalCodeHandler = this.get('onChangePostalCode');
        changePostalCodeHandler(eventObject);
      },
      selectCard: function selectCard() {
        var selectExistingCardHandler = this.get('onSelectExistingCard');
        selectExistingCardHandler();
      },
      selectCountry: function selectCountry(evt) {
        var selectCountryHandler = this.get('onSelectCountry');
        selectCountryHandler(evt);
      }
    }
  });

  _exports.default = _default;
});