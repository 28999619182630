define("funnelytics/components/upsells/funnel-project-quota-upsells", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onHandleClose: function onHandleClose() {},
    actions: {
      handleClose: function handleClose() {
        var closeHandler = this.get('onHandleClose');
        closeHandler();
      }
    }
  });

  _exports.default = _default;
});