define("funnelytics/components/left-sidebar/icons-mapper", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var stepFilter = function stepFilter(step, query) {
    return _lodash.default.lowerCase(step.name).indexOf(query) > -1;
  };

  var PAGES_TYPE = 'page';
  var TRAFFIC_TYPE = 'source';
  var OFFLINE_TYPE = 'offline';
  var EVENTS_TYPE = 'event';

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    dragManager: Ember.inject.service(),
    searchQuery: '',
    isPagesTabOpen: Ember.computed.equal('sideBarIconType', PAGES_TYPE),
    isTrafficTabOpen: Ember.computed.equal('sideBarIconType', TRAFFIC_TYPE),
    isOfflineTabOpen: Ember.computed.equal('sideBarIconType', OFFLINE_TYPE),
    isEventTabOpen: Ember.computed.equal('sideBarIconType', EVENTS_TYPE),
    isPagesVisible: Ember.computed.or('hasPageSearchResults', 'isPagesTabOpen'),
    isTrafficVisible: Ember.computed.or('hasTrafficSearchResults', 'isTrafficTabOpen'),
    isOfflineVisible: Ember.computed.or('hasOfflineSearchResults', 'isOfflineTabOpen'),
    isEventVisible: Ember.computed.or('hasEventSearchResults', 'isEventTabOpen'),
    iconsDraggable: true,
    iconsSelectable: false,
    // Add user's custom icons into their respective types
    concatCustomAndStandard: function concatCustomAndStandard(standardIcons, stepType) {
      var userGeneratedIcons = this.get('userIcons').toArray();
      var customIcons = userGeneratedIcons.filter(function (icon) {
        return icon.type === stepType;
      }).map(function (icon) {
        return {
          name: icon.name,
          path: icon.path,
          customIcon: true
        };
      });
      return _lodash.default.concat(standardIcons, customIcons);
    },
    allPages: Ember.computed('icons.list.page', 'userIcons.@each.type', function () {
      return this.concatCustomAndStandard(this.get('icons.list.page'), PAGES_TYPE);
    }),
    allTraffic: Ember.computed('icons.list.source', 'userIcons.@each.type', function () {
      return this.concatCustomAndStandard(this.get('icons.list.source'), TRAFFIC_TYPE);
    }),
    allEvent: Ember.computed('icons.list.event', 'userIcons.@each.type', function () {
      return this.concatCustomAndStandard(this.get('icons.list.event'), EVENTS_TYPE);
    }),
    allOffline: Ember.computed('icons.list.offline', 'userIcons.@each.type', function () {
      return this.concatCustomAndStandard(this.get('icons.list.offline'), OFFLINE_TYPE);
    }),
    lowerCaseSearchQuery: Ember.computed('searchQuery', function () {
      return _lodash.default.lowerCase(this.get('searchQuery'));
    }),
    hasSearchQuery: Ember.computed.notEmpty('searchQuery'),
    filteredPages: Ember.computed('allPages', 'lowerCaseSearchQuery', function () {
      var _this = this;

      return this.get('allPages').filter(function (step) {
        return stepFilter(step, _this.get('lowerCaseSearchQuery'));
      });
    }),
    filteredTraffic: Ember.computed('allTraffic', 'lowerCaseSearchQuery', function () {
      var _this2 = this;

      return this.get('allTraffic').filter(function (step) {
        return stepFilter(step, _this2.get('lowerCaseSearchQuery'));
      });
    }),
    filteredOffline: Ember.computed('allOffline', 'lowerCaseSearchQuery', function () {
      var _this3 = this;

      return this.get('allOffline').filter(function (step) {
        return stepFilter(step, _this3.get('lowerCaseSearchQuery'));
      });
    }),
    filteredEvent: Ember.computed('allEvent', 'lowerCaseSearchQuery', function () {
      var _this4 = this;

      return this.get('allEvent').filter(function (step) {
        return stepFilter(step, _this4.get('lowerCaseSearchQuery'));
      });
    }),
    hasFilteredPages: Ember.computed.notEmpty('filteredPages'),
    hasFilteredTraffic: Ember.computed.notEmpty('filteredTraffic'),
    hasFilteredOffline: Ember.computed.notEmpty('filteredOffline'),
    hasFilteredEvents: Ember.computed.notEmpty('filteredEvent'),
    hasPageSearchResults: Ember.computed.and('hasSearchQuery', 'hasFilteredPages'),
    hasTrafficSearchResults: Ember.computed.and('hasSearchQuery', 'hasFilteredTraffic'),
    hasOfflineSearchResults: Ember.computed.and('hasSearchQuery', 'hasFilteredOffline'),
    hasEventSearchResults: Ember.computed.and('hasSearchQuery', 'hasFilteredEvents'),
    hasSearchResults: Ember.computed.or('hasPageSearchResults', 'hasTrafficSearchResults', 'hasOfflineSearchResults', 'hasEventSearchResults'),
    hasNoSearchResults: Ember.computed.not('hasSearchResults'),
    isEmptySearchResult: Ember.computed.and('hasNoSearchResults', 'hasSearchQuery'),
    focusOnSearch: function focusOnSearch() {
      this.$('.left-sidebar__search').focus();
    },
    setIconType: function setIconType() {},
    onIconSelect: function onIconSelect() {},
    didInsertElement: function didInsertElement() {
      this.focusOnSearch();
    },
    actions: {
      focusOnIconType: function focusOnIconType(iconType) {
        this.setIconType(iconType);
        this.focusOnSearch();
      },
      onIconSelect: function onIconSelect(icon, type) {
        this.onIconSelect(icon, type);
      }
    }
  });

  _exports.default = _default;
});