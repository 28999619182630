define("funnelytics/components/public/funnel-view", ["exports", "funnelytics/custom/canvas/paper", "funnelytics/custom/misc/shapes", "funnelytics/custom/constants/CanvasConstants", "funnelytics/custom/analytics/analytics/canvas/CanvasEntityCreator", "funnelytics/custom/analytics/analytics/canvas/EntityChangeManager", "funnelytics/custom/canvas/managers/ZoomManager"], function (_exports, _paper, _shapes, _CanvasConstants, _CanvasEntityCreator, _EntityChangeManager, _ZoomManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    canvasService: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    zoomObserver: Ember.observer('paper.commands.idToNotifyChange', function () {
      this.set('zoom', this.paper.scroller.factor);
    }),
    initializeProperties: function initializeProperties() {
      var _this = this;

      this.setProperties({
        changeManager: _EntityChangeManager.default.create(),
        zoom: 1,
        isZoomDropdownOpen: false,
        canvasJqueryElement: this.$('#paper'),
        setDOMFocusOnPaper: function setDOMFocusOnPaper() {
          _this.get('canvasService').getPaperJqueryNode().focus();
        },
        onPaperWheel: function onPaperWheel(e) {
          if (!e.ctrlKey) {
            return;
          }

          e.preventDefault();

          _this.get('zoomManager').zoomForCursor(e.originalEvent.deltaY, e.clientX, e.clientY);
        }
      });
    },
    initializeCanvasService: function initializeCanvasService() {
      this.get('canvasService').initializeCanvasService({
        entityManager: this.get('paper.entityManager'),
        canvasEntityCreator: new _CanvasEntityCreator.default({
          changeManager: this.get('changeManager'),
          entityManager: this.get('paper.entityManager')
        }),
        paperJqueryNode: this.get('canvasJqueryElement'),
        canvasType: _CanvasConstants.default.CANVAS_TYPE_FUNNEL,
        funnelIsInProject: false,
        paper: this.get('paper')
      });
    },
    canvasScrollerJqueryElement: null,
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.get('canvasService');
      this.initializeProperties(); // To prevent overscroll behaviour when canvas is opened

      Ember.$('html').addClass('no-overscroll-x'); // TODO: Can't we just reference funnel_misc and funnel_steps directly in Paper Manager?

      joint.shapes.funnel_misc = _shapes.default.funnel_misc;
      joint.shapes.funnel_steps = _shapes.default.funnel_steps;
      this.set('paper', new _paper.default(this.get('canvasJqueryElement'), {
        type: _CanvasConstants.default.CANVAS_TYPE_FUNNEL,
        canEdit: false,
        notInteractive: true,
        allowance: Infinity,
        services: {
          session: this.get('session'),
          store: this.get('store'),
          ajax: this.get('ajax'),
          canvas: this.get('canvasService')
        }
      }));
      this.set('zoomManager', new _ZoomManager.default({
        paper: this.get('paper'),
        onZoomChanged: function onZoomChanged(zoomValue) {
          _this2.set('zoom', zoomValue);
        }
      }));
      this.initializeCanvasService();
      this.paper.install(this.get('canvasService').getPaperJqueryNode());
      this.set('canvas', this.get('canvasService').getPaperJqueryNode().find('.joint-paper')[0]);
      this.paper.toggleGrid();
      this.paper.loadFromUrl(this.get('funnelUrl')).then(function () {
        var allElements = _this2.get('canvasService').getEntityManager().getElementEntities();

        if (allElements.length > 0) {
          _this2.paper.scroller.centerContent();
        }

        _this2.set('zoom', _this2.paper.scroller.factor - 0.25);
      });
      this.get('canvasService').getPaperJqueryNode().on('mouseover', this.get('setDOMFocusOnPaper'));
      this.get('canvasService').getPaperJqueryNode().on('wheel', this.get('onPaperWheel'));
      this.paper.createCanvasEventListener({
        event: 'blank:pointerdown',
        handler: function handler() {
          _this2.send('registerCanvasClick');
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$('html').removeClass('no-overscroll-x');
      this.get('canvasService').getPaperJqueryNode().off('mouseover', this.get('setDOMFocusOnPaper'));
      this.get('canvasService').getPaperJqueryNode().off('wheel', this.get('onPaperWheel'));
      this.get('canvasService').tearDownCanvasService();
      this.get('paper').destroy();

      this._super();
    },
    actions: {
      zoom: function zoom(steps) {
        this.get('zoomManager').zoom(steps);
      },
      zoomFit: function zoomFit() {
        this.get('zoomManager').zoomFit();
      },
      toggleZoomDropdown: function toggleZoomDropdown() {
        this.toggleProperty('isZoomDropdownOpen');
      },
      registerCanvasClick: function registerCanvasClick() {
        this.set('isZoomDropdownOpen', false);
      }
    }
  });

  _exports.default = _default;
});