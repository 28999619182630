define("funnelytics/custom/constants/MembershipConstants", ["exports", "funnelytics/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MembershipOrdering = _exports.MembershipIdNameMapping = _exports.FreeMembershipIds = _exports.StripeUpgradeLinks = _exports.TrialMembershipNamesMapping = _exports.MembershipDisplayNames = _exports.SUBSCRIPTION_HIERARCHY = _exports.DowngradeMembershipHierarchy = _exports.DowngradeMembershipNames = _exports.TRIAL_TO_MEMBERSHIP = _exports.COLLABORATOR_ACCESS = _exports.BANNER_CTA = _exports.V2_MEMBERSHIPS_BY_NAME = _exports.V2_MEMBERSHIPS = _exports.Membership = _exports.MEMBERSHIP_HIERARCHY = _exports.MEMBERSHIP_CODES = _exports.LEGACY_MEMBERSHIP = void 0;

  var _Object$freeze, _TrialMembershipNames, _MembershipDisplayNam, _StripeUpgradeLinks;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Subscription_Name = {
    isEnterprise: 'Business',
    isPerformanceAgency: 'Performance Agency',
    isPerformanceBusiness: 'Performance Business',
    isPerformancePro: 'Performance Pro',
    isPerformancePlus: 'Performance Business',
    isPro: 'Pro',
    isMarketer: 'Marketer',
    isVaultMember: 'Vault',
    isStarter: 'Map Free',
    isPerformanceStarter: 'Performance Starter',
    isLifeTime: 'Pro',
    isLegacyPerformance: 'Legacy Performance',
    isClickFunnel: 'Click Funnel',
    isPremium: 'Premium',
    isMapping: 'Map Free',
    isLimited: 'Limited',
    isMap: 'Map',
    isMapProLifetime: 'Map Pro Lifetime',
    isMapPro: 'Map Pro',
    isMapLifetime: 'Map Lifetime'
  }; //Used for check performance pro trial eligibility only.
  //TODO: Rename for clarity.

  var V2_MEMBERSHIPS = Object.freeze(['isLifeTime', 'isEnterprise', 'isPerformanceBusiness', 'isPerformancePlus', 'isPerformancePro', 'isMarketer', 'isPerformanceStarter', 'isStarter', 'isMapPro', 'isMapLifetime', 'isMapProLifetime']);
  _exports.V2_MEMBERSHIPS = V2_MEMBERSHIPS;
  var V2_MEMBERSHIPS_BY_NAME = Object.freeze([Subscription_Name.isLifeTime, Subscription_Name.isEnterprise, Subscription_Name.isPremium, Subscription_Name.isLegacyPerformance, Subscription_Name.isPerformanceBusiness, Subscription_Name.isPerformancePro, Subscription_Name.isPerformancePlus, Subscription_Name.isPerformanceAgency, Subscription_Name.isMarketer, Subscription_Name.isClickFunnel, Subscription_Name.isPerformanceStarter, Subscription_Name.isStarter, Subscription_Name.isMapping, Subscription_Name.isMap, Subscription_Name.isMapPro, Subscription_Name.isMapLifetime, Subscription_Name.isMapProLifetime]);
  _exports.V2_MEMBERSHIPS_BY_NAME = V2_MEMBERSHIPS_BY_NAME;
  var Membership = Object.freeze(_objectSpread({
    LIFETIME: 'isLifeTime',
    ENTERPRISE: 'isEnterprise',
    PERFORMANCE: 'isPerformanceBusiness',
    PERFORMANCE_LEGACY: 'isPerformanceLegacy',
    PRO: 'isPro',
    MARKETER: 'isMarketer',
    CLICK_FUNNEL: 'isClickFunnel',
    VAULT: 'isVaultMember',
    PERFORMANCE_PRO: 'isPerformancePro',
    PRO_WITH_ANALYTICS_2: 'isProWithAnalytics2',
    PERFORMANCE_STARTER: 'isPerformanceStarter',
    STARTER: 'isStarter',
    MAPPING: 'isStarter',
    PERFORMANCE_BUSINESS: 'isPerformancePlus'
  }, Subscription_Name));
  _exports.Membership = Membership;
  var LEGACY_MEMBERSHIP = Object.freeze(['Business', 'Pro', 'Marketer', 'Vault', 'Pro', 'Legacy Performance', 'Click Funnel', 'Premium']);
  _exports.LEGACY_MEMBERSHIP = LEGACY_MEMBERSHIP;
  var MEMBERSHIP_CODES = Object.freeze({
    'Performance Agency': '504a8641-bd82-4cdd-a36e-f18c96526302',
    'Performance Pro': '62e5ae2c-c6e3-44fc-8c51-0f431c1d3c9e',
    // Funnelytics Performance
    'Performance Business': 'd0f483ee-bcfc-48bd-aae9-762e829ff5f3',
    'Map Pro': '37fade7b-05f6-4762-a6a1-63c31095d3c4',
    'Map Free': '1745a86e-9dbf-44e5-8a86-e8e7d7fe8a1a',
    // deprecated
    Premium: '606fea20-969d-4830-b040-72e54e5b2217',
    Limited: 'bb40eb3c-41b9-41e1-8f27-6a2e4d2e0ec1',
    Map: 'c7ad9a57-152d-4e3a-b8ad-d6adbff71554',
    'Map Lifetime': '31dd2e97-eeef-4824-b16d-87dd87bccc53',
    'Map Pro Lifetime': 'aa3bf73b-e935-42a9-ac9f-bd0e356f5f9b',
    'Performance Starter': 'd6b142cb-fdfe-4eb2-a98f-fb9808851bda'
  });
  _exports.MEMBERSHIP_CODES = MEMBERSHIP_CODES;
  var MembershipIdNameMapping = Object.freeze({
    '504a8641-bd82-4cdd-a36e-f18c96526302': 'Performance Agency',
    // former Performance Max
    '62e5ae2c-c6e3-44fc-8c51-0f431c1d3c9e': 'Performance',
    // former Performance Pro
    'd0f483ee-bcfc-48bd-aae9-762e829ff5f3': 'Performance Business',
    // former Performance Plus
    '37fade7b-05f6-4762-a6a1-63c31095d3c4': 'Map Pro',
    '1745a86e-9dbf-44e5-8a86-e8e7d7fe8a1a': 'Map Free',
    '606fea20-969d-4830-b040-72e54e5b2217': 'Premium',
    '0daf5ca9-2b69-4f19-af54-8f2ee1081ea2': 'Click Funnel',
    'bb40eb3c-41b9-41e1-8f27-6a2e4d2e0ec1': 'Limited',
    'c7ad9a57-152d-4e3a-b8ad-d6adbff71554': 'Map',
    '0ebcc13b-3576-4170-9553-db12322eba32': 'Legacy Performance',
    '31dd2e97-eeef-4824-b16d-87dd87bccc53': 'Map Lifetime',
    'aa3bf73b-e935-42a9-ac9f-bd0e356f5f9b': 'Map Pro Lifetime',
    'd6b142cb-fdfe-4eb2-a98f-fb9808851bda': 'Performance Starter'
  });
  _exports.MembershipIdNameMapping = MembershipIdNameMapping;
  var MembershipOrdering = Object.freeze(['c7ad9a57-152d-4e3a-b8ad-d6adbff71554', // Limited
  '1745a86e-9dbf-44e5-8a86-e8e7d7fe8a1a', // Mapping
  'c7ad9a57-152d-4e3a-b8ad-d6adbff71554', // Map
  '31dd2e97-eeef-4824-b16d-87dd87bccc53', // Map Lifetime
  '37fade7b-05f6-4762-a6a1-63c31095d3c4', // Map Pro
  'aa3bf73b-e935-42a9-ac9f-bd0e356f5f9b', // Map Pro Lifetime
  '606fea20-969d-4830-b040-72e54e5b2217', // Premium
  '0daf5ca9-2b69-4f19-af54-8f2ee1081ea2', // Click Funnel
  '0ebcc13b-3576-4170-9553-db12322eba32', // Legacy Performance
  'd6b142cb-fdfe-4eb2-a98f-fb9808851bda', // Performance Starter
  '62e5ae2c-c6e3-44fc-8c51-0f431c1d3c9e', // Performance
  'd0f483ee-bcfc-48bd-aae9-762e829ff5f3', // Performance Business
  '504a8641-bd82-4cdd-a36e-f18c96526302' // Performance Agency
  ]);
  _exports.MembershipOrdering = MembershipOrdering;
  var MEMBERSHIP_HIERARCHY = Object.freeze([MEMBERSHIP_CODES['Performance Agency'], MEMBERSHIP_CODES['Performance Business'], MEMBERSHIP_CODES['Performance Pro'], MEMBERSHIP_CODES['Performance Starter'], MEMBERSHIP_CODES['Map Free'], MEMBERSHIP_CODES.Limited]);
  _exports.MEMBERSHIP_HIERARCHY = MEMBERSHIP_HIERARCHY;
  var BANNER_CTA = Object.freeze([Membership.isMarketer, Membership.isVaultMember, Membership.isPro, Membership.isPerformanceStarter, Membership.isMapping, Membership.isLimited]);
  _exports.BANNER_CTA = BANNER_CTA;
  var COLLABORATOR_ACCESS = Object.freeze([Membership.isLifeTime, Membership.isEnterprise, Membership.isPerformanceBusiness, Membership.isPro, Membership.isPerformancePro, Membership.isPerformancePlus, Membership.isPerformanceAgency, Membership.isPerformanceStarter, Membership.isMapPro, Membership.isMapProLifetime]);
  _exports.COLLABORATOR_ACCESS = COLLABORATOR_ACCESS;
  var TRIAL_TO_MEMBERSHIP = Object.freeze({
    'Performance Pro Trial': Membership.isPerformancePro
  });
  _exports.TRIAL_TO_MEMBERSHIP = TRIAL_TO_MEMBERSHIP;
  var DowngradeMembershipNames = Object.freeze({
    performanceAgency: 'Performance Agency',
    performanceBusiness: 'Performance Business',
    performancePro: 'Performance Pro',
    performanceStarter: 'Performance Starter',
    mapPro: 'Map Pro',
    map: 'Map',
    limited: 'Limited'
  }); // hierarchy going from most to less valuable plans

  _exports.DowngradeMembershipNames = DowngradeMembershipNames;
  var DowngradeMembershipHierarchy = Object.freeze((_Object$freeze = {}, _defineProperty(_Object$freeze, DowngradeMembershipNames.performanceAgency, 0), _defineProperty(_Object$freeze, DowngradeMembershipNames.performanceBusiness, 1), _defineProperty(_Object$freeze, DowngradeMembershipNames.performancePro, 2), _defineProperty(_Object$freeze, DowngradeMembershipNames.performanceStarter, 3), _defineProperty(_Object$freeze, DowngradeMembershipNames.mapPro, 4), _defineProperty(_Object$freeze, DowngradeMembershipNames.map, 5), _defineProperty(_Object$freeze, DowngradeMembershipNames.limited, 6), _Object$freeze)); // legacy hierarchy

  _exports.DowngradeMembershipHierarchy = DowngradeMembershipHierarchy;
  var SUBSCRIPTION_HIERARCHY = [Membership.isLimited, Membership.isMapping, Membership.isMap, Membership.isPerformanceStarter, Membership.isPerformancePro, Membership.isPerformanceBusiness, Membership.isPerformanceAgency]; // some legacy membership names

  _exports.SUBSCRIPTION_HIERARCHY = SUBSCRIPTION_HIERARCHY;
  var PerformanceProTrial = 'Performance Pro Trial';
  var PerformanceLegacy = 'Performance';
  var PerformancePlusTrial = 'Performance Business Trial';
  var PerformanceStarterTrial = 'Performance Starter Trial';
  var TrialMembershipNamesMapping = (_TrialMembershipNames = {}, _defineProperty(_TrialMembershipNames, Membership.isPerformancePro, PerformanceProTrial), _defineProperty(_TrialMembershipNames, Membership.isPerformancePlus, PerformancePlusTrial), _defineProperty(_TrialMembershipNames, Membership.isPerformanceStarter, PerformanceStarterTrial), _TrialMembershipNames);
  /**
   * Legacy -> current mapping
   */

  _exports.TrialMembershipNamesMapping = TrialMembershipNamesMapping;
  var MembershipDisplayNames = (_MembershipDisplayNam = {}, _defineProperty(_MembershipDisplayNam, Membership.isPerformanceStarter, 'Performance Starter'), _defineProperty(_MembershipDisplayNam, Membership.isPerformancePro, 'Performance Pro'), _defineProperty(_MembershipDisplayNam, Membership.isPerformancePlus, 'Performance Business'), _defineProperty(_MembershipDisplayNam, PerformanceProTrial, 'Performance Pro Trial'), _defineProperty(_MembershipDisplayNam, PerformanceLegacy, 'Performance Business'), _MembershipDisplayNam);
  _exports.MembershipDisplayNames = MembershipDisplayNames;
  var StripeUpgradeLinks = (_StripeUpgradeLinks = {}, _defineProperty(_StripeUpgradeLinks, Membership.isPerformanceStarter, _environment.default.deployTarget === 'production' ? 'https://buy.stripe.com/9AQ6oN8U89CIf4ccO6' : 'https://buy.stripe.com/test_fZeaFPgVJ7rDaGY28i'), _defineProperty(_StripeUpgradeLinks, PerformanceStarterTrial, _environment.default.deployTarget === 'production' ? 'https://buy.stripe.com/4gw00p5HW6qwg8geWb' : 'https://buy.stripe.com/test_5kA15f9thbHT7uM4gp'), _defineProperty(_StripeUpgradeLinks, Membership.isPerformancePro, _environment.default.deployTarget === 'production' ? 'https://buy.stripe.com/8wM6oN5HW4io4py6pH' : 'https://buy.stripe.com/test_fZe9BLaxl8vH3ewbIU'), _defineProperty(_StripeUpgradeLinks, PerformanceProTrial, _environment.default.deployTarget === 'production' ? 'https://buy.stripe.com/8wM8wVgmA6qw1dm01i' : 'https://buy.stripe.com/test_28obJTgVJ7rDdTabIT'), _defineProperty(_StripeUpgradeLinks, Membership.isPerformancePlus, 'https://buy.stripe.com/7sI7sRb2gbKQ1dmcNt'), _defineProperty(_StripeUpgradeLinks, PerformancePlusTrial, 'https://buy.stripe.com/4gw7sR4DS9CIg8g4hb'), _defineProperty(_StripeUpgradeLinks, Membership.isPerformanceAgency, 'https://buy.stripe.com/14k9AZ1rG16caNW5lk'), _StripeUpgradeLinks);
  _exports.StripeUpgradeLinks = StripeUpgradeLinks;
  var FreeMembershipIds = [MEMBERSHIP_CODES.Limited, MEMBERSHIP_CODES['Map Free']];
  _exports.FreeMembershipIds = FreeMembershipIds;
});