define("funnelytics/services/goal-manager", ["exports", "lodash", "@funnelytics/utilities"], function (_exports, _lodash, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEBOUNCE_TIMEOUT = 3000;
  var DEFAULT_NAME = 'Untitled Goal';
  var DEFAULT_TYPE = 'Lead';

  var _default = Ember.Service.extend({
    analyticsService: Ember.inject.service(),
    store: Ember.inject.service(),
    funnel: null,
    elementModel: null,
    // Currently selected Canvas Element's model
    goals: null,
    // Array of goals associated with the elementModel
    elementsGraph: null,
    // Graph used to find elements
    // Method we can use to save the goals managed
    saveMethod: _lodash.default.debounce(function () {}, DEBOUNCE_TIMEOUT),

    /**
     * The identifier used for the selected Canvas Element for which we are using Goal Manager.
     */
    elementModelId: Ember.computed('elementModel', function () {
      if (!this.get('elementModel')) {
        return null;
      }

      return this.get('elementModel').prop('id');
    }),

    /**
     * Label for the curretly selected Canvas Element for which we are using Goal Manager.
     */
    elementLabel: Ember.computed('elementModel', function () {
      if (!this.get('elementModel')) {
        return 'unknown element';
      }

      return this.getElementLabel(this.get('elementModel'));
    }),

    /**
     * Boolean to determine if we have access to the graph that can be used for finding canvas elements.
     */
    graphIdentified: Ember.computed('elementsGraph', function () {
      return this.get('elementsGraph') !== null;
    }),
    getGoalName: function getGoalName(goal) {
      var goalName = goal.get('name');
      var goalHasName = true && goalName;

      if (goalHasName) {
        return "goal \"".concat(goalName, "\"");
      }

      return 'unnamed goal';
    },
    setElementsGraph: function setElementsGraph(graph) {
      this.set('elementsGraph', graph);
    },

    /**
     * When moving from one element to another, we have to load the funnel, model and goals associated with
     * the new element and clear the state from the previous model.
     */
    resetState: function resetState(funnel, model) {
      var _this = this;

      this.handleStateTransition();
      this.set('funnel', funnel);
      this.set('elementModel', model);
      this.get('analyticsService').setKPIsAreStale(true);
      return this.get('store').query('goal', {
        filter: {
          funnel: this.get('funnel.id'),
          target: this.get('elementModelId')
        }
      }).then(function (goals) {
        _this.get('goals').addObjects(goals.toArray());

        _this.set('saveMethod', _lodash.default.debounce(function (goalArray) {
          return _this.saveGoals(goalArray);
        }, DEBOUNCE_TIMEOUT));

        return _this.get('goals');
      });
    },

    /**
     * If we are not currently editing goals, we should stop trying to target a source element for a goal, and
     * we should submit any save requests that have been debounced on this service.
     */
    cleanUpAfterGoalEditing: function cleanUpAfterGoalEditing() {
      this.saveOutstandingChanges();
    },

    /**
     * Reset primary state attributes.
     */
    handleStateTransition: function handleStateTransition() {
      this.cleanUpAfterGoalEditing();
      this.set('goals', []);
      this.set('funnel', null);
      this.set('elementModel', null);
    },
    // Call to expediate save method calls that have been debounced.
    saveOutstandingChanges: function saveOutstandingChanges() {
      this.get('saveMethod').flush();
    },

    /**
     * This doesn't really belong on this service and should be called from a different service that manages
     * the graph and how to interact with it.
     */
    getElementLabelById: function getElementLabelById(elementId) {
      if (!this.get('graphIdentified')) {
        return 'Could Not Find Elements';
      }

      var elementCell = this.get('elementsGraph').getCell(elementId);

      if (!elementCell) {
        return 'Element Not Found';
      }

      return this.getElementLabel(elementCell);
    },
    getElementLabel: function getElementLabel(element) {
      var elementLabelText = element.attr('.label/textWrap/text');

      if (!elementLabelText) {
        return 'element with no label';
      }

      return "\"".concat(elementLabelText, "\" element");
    },
    addGoal: function addGoal() {
      var _this2 = this;

      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref$name = _ref.name,
          name = _ref$name === void 0 ? DEFAULT_NAME : _ref$name,
          _ref$type = _ref.type,
          type = _ref$type === void 0 ? DEFAULT_TYPE : _ref$type,
          _ref$added_to_kpi = _ref.added_to_kpi,
          added_to_kpi = _ref$added_to_kpi === void 0 ? true : _ref$added_to_kpi;

      if (_lodash.default.filter(this.get('goalManager.goals', function (goal) {
        return (goal.get('target') || '').toLowerCase() === (_this2.get('elementModelId') || '').toLowerCase();
      })).length > 0) {
        throw new Error('Tried to create a goal on an element which already has a goal.');
      }

      var newGoal = this.get('store').createRecord('goal', {
        name: name,
        type: type,
        funnel: this.get('funnel'),
        target: this.get('elementModelId'),
        added_to_kpi: added_to_kpi
      });
      this.get('goals').addObject(newGoal);
      return newGoal;
    },

    /**
     * Immediately delete a goal.
     */
    deleteGoal: function deleteGoal(goal) {
      if (goal.get('isDeleted')) {
        return;
      }

      this.get('goals').removeObject(goal);
      this.get('analyticsService').set('analyticsAreStale', true);
      this.get('analyticsService').setKPIsAreStale(true);
      this.get('analyticsService').notifyPropertyChange('goalsForReport');
      return goal.destroyRecord();
    },

    /**
     * Consumer of this service can use this to only call a save at given intervals after it has been requested.
     */
    saveGoalsDebounce: function saveGoalsDebounce() {
      var save = this.get('saveMethod');
      return save(this.get('goals'));
    },

    /**
     * Helper method that saves passed goals immediately.
     */
    saveGoals: function saveGoals(goals) {
      var _this3 = this;

      return Ember.RSVP.map(goals, function (goal) {
        if (goal.get('hasDirtyAttributes')) {
          return goal.save();
        } else {
          return new Ember.RSVP.Promise(function (resolve) {
            window.setTimeout(resolve, 200);
          });
        }
      }).then(function () {
        _this3.get('analyticsService').set('analyticsAreStale', true);

        _this3.get('analyticsService').setKPIsAreStale(true);

        _this3.get('analyticsService').notifyPropertyChange('goalsForReport');
      });
    },

    /**
     * Helper method used to add a goal and then reset goal manager to previous element (if provided)
     */
    addGoalQuick: function addGoalQuick(_ref2) {
      var _this4 = this;

      var funnel = _ref2.funnel,
          targetEntity = _ref2.targetEntity,
          _ref2$previousEntity = _ref2.previousEntity,
          previousEntity = _ref2$previousEntity === void 0 ? null : _ref2$previousEntity,
          goalType = _ref2.goalType,
          goalName = _ref2.goalName;

      _utilities.Assertion.string(goalType);

      _utilities.Assertion.string(goalName);

      return this.resetState(funnel, targetEntity.getCell()).then(function () {
        return _this4.addGoal({
          name: goalName,
          type: goalType
        }).save();
      }).then(function () {
        if (previousEntity) {
          return _this4.resetState(funnel, previousEntity.getCell());
        }

        return null;
      });
    }
  });

  _exports.default = _default;
});