define("funnelytics/components/dashboard-elements/subscriptions/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    subscriptionName: Ember.computed.readOnly('userSubscription.subscription.name'),
    queuedSubscription: Ember.computed.readOnly('userSubscription.queued_subscription'),
    willExpire: Ember.computed('userSubscription.status', 'queuedSubscription', function () {
      return this.get('userSubscription.status') === 'cancelling' || this.get('queuedSubscription');
    }),
    termEndDate: Ember.computed.readOnly('userSubscription.term_ends_at'),
    isActive: Ember.computed.equal('userSubscription.status', 'active'),
    newPlanName: Ember.computed.readOnly('queuedSubscription.new_plan_name'),
    newPlanSwitchDate: Ember.computed.readOnly('queuedSubscription.switch_date'),
    subscriptionPrice: Ember.computed.readOnly('userSubscription.subscriptionPrice'),
    isSubscriptionNotFree: Ember.computed.gt('subscriptionPrice', 0)
  });

  _exports.default = _default;
});