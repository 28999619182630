define("funnelytics/components/dashboard-elements/funnel/edit", ["exports", "funnelytics/custom/constants/MembershipConstants", "funnelytics/custom/constants/ProjectPermissionConstants", "funnelytics/custom/constants"], function (_exports, _MembershipConstants, _ProjectPermissionConstants, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _EmberComponent$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Component.extend((_EmberComponent$exte = {
    userDetails: Ember.inject.service(),
    projectPermissions: Ember.inject.service(),
    versionManager: Ember.inject.service(),
    isProjectLocked: null
  }, _defineProperty(_EmberComponent$exte, "isProjectLocked", Ember.computed('project.data.is_locked', function () {
    var _this$project;

    return (_this$project = this.project) === null || _this$project === void 0 ? void 0 : _this$project.data.is_locked;
  })), _defineProperty(_EmberComponent$exte, "isUnorganizedFunnels", Ember.computed('currentProjectId', function () {
    return this.currentProjectId === _constants.NULL_PROJECT;
  })), _defineProperty(_EmberComponent$exte, "hasTagEdit", Ember.computed('projectPermissions.membership', 'project.id', 'isProjectLocked', 'versionManager.activeDashboardVersion', function () {
    //NULL PROJECT is for unorganized funnel projects
    try {
      if (this.currentProjectId === _constants.NULL_PROJECT || this.isProjectLocked) {
        return false;
      }

      var isMapProLifetime = this.get('projectPermissions.membership') === _MembershipConstants.Membership.isMapProLifetime;

      var isValidV2Membership = this.get('projectPermissions').hasValidV2Membership(this.projectPermissions.membership);
      var isNotStarter = !this.get('projectPermissions').hasMembership(_MembershipConstants.Membership.isStarter);
      var isCollaborator = this.get('projectPermissions').hasPermission(this.project.id, _ProjectPermissionConstants.default.IS_COLLABORATOR);
      return (isValidV2Membership && isNotStarter || isCollaborator || isMapProLifetime) && this.get('versionManager.activeDashboardVersion') > 1;
    } catch (e) {
      return false;
    }
  })), _defineProperty(_EmberComponent$exte, "filteredProjects", Ember.computed('projects', 'project.id', 'selectedFunnel', 'currentProjectId', function () {
    if (this.currentProjectId === _constants.NULL_PROJECT || !this.project) {
      var _this$selectedFunnel;

      var funnelVersion = (_this$selectedFunnel = this.selectedFunnel) === null || _this$selectedFunnel === void 0 ? void 0 : _this$selectedFunnel.version;
      var filteredProjects;

      if ([null, 1].includes(funnelVersion)) {
        filteredProjects = this.projects.filter(function (el) {
          return el.version !== 3 && !el.data.is_locked;
        });
      } else {
        filteredProjects = this.projects.filter(function (el) {
          return el.version !== 1 && !el.data.is_locked;
        });
      }

      return filteredProjects;
    }

    return this.projects;
  })), _EmberComponent$exte));

  _exports.default = _default;
});