define("funnelytics/components/for-reuse/dropdown-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['fl-dropdown-button'],
    classNameBindings: ['isSmall:small', 'opened:opened', 'noBorders:no-borders', 'disabled:disabled'],
    isSmall: Ember.computed.equal('size', 'small'),
    noBorders: false,
    opened: false,
    ignoreClickInside: false,
    disabled: false,
    toggleOpened: function toggleOpened() {
      if (this.get('disabled')) {
        return;
      }

      this.toggleProperty('opened');
    },
    actions: {
      toggleOpened: function toggleOpened() {
        this.toggleOpened();
      },
      clickOutside: function clickOutside() {
        this.set('opened', false);
      },
      clickInsideContent: function clickInsideContent() {
        if (this.get('ignoreClickInside')) {
          return;
        }

        this.set('opened', false);
      }
    }
  });

  _exports.default = _default;
});