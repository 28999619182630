define("funnelytics/helpers/user-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.userList = userList;
  _exports.default = void 0;

  function userList(params
  /*, hash*/
  ) {
    return params[0].length;
  }

  var _default = Ember.Helper.helper(userList);

  _exports.default = _default;
});