define("funnelytics/components/funnel-pages/checkout/pro", ["exports", "@funnelytics/utilities", "funnelytics/helpers/integer-shortener"], function (_exports, _utilities, _integerShortener) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ProMonthlyTiers = _utilities.constants.ProMonthlyTiers;

  function mapTierSessionsToVisitors(tier) {
    return "".concat((0, _integerShortener.integerShortener)([tier.sessions]), " Website Visitors");
  }

  var _default = Ember.Component.extend({
    _selectedMonthlyTierOption: null,
    _selectedYearlyTierOption: null,
    selectedMonthlyTier: Ember.computed('_selectedMonthlyTierOption', 'proMonthlyTierOptions', function () {
      return this.get('_selectedMonthlyTierOption') || this.get('proMonthlyTierOptions.firstObject');
    }),
    selectedYearlyTier: Ember.computed('_selectedYearlyTierOption', 'proYearlyTierOptions', function () {
      return this.get('_selectedYearlyTierOption') || this.get('proYearlyTierOptions.firstObject');
    }),
    savings: Ember.computed('primaryProduct.price', 'primaryProduct.alternative.cost', function () {
      if (!this.get('primaryProduct.alternative')) {
        return 0;
      }

      var original = this.get('primaryProduct.cost') || 0;
      var alternative = (this.get('primaryProduct.alternative.cost') || 0) * 12;
      return original !== 0 ? Math.round(100 * (1 - original / alternative)) : 0;
    }),
    proMonthlyTierOptions: ProMonthlyTiers.TIERS.map(function (tier, index) {
      var isFirstTier = index === 0;
      var basePrice = Math.round(ProMonthlyTiers.BASE_PLAN_PRICE / 100);
      var price = basePrice + (isFirstTier ? 0 : Math.round(ProMonthlyTiers.TIERS[index - 1].price / 100));
      return {
        price: price,
        visitors: mapTierSessionsToVisitors(tier)
      };
    }),
    proYearlyTierOptions: Ember.computed('proAnnuallyTiers', function () {
      var proYearlyTiers = this.get('proAnnuallyTiers');

      if (!proYearlyTiers) {
        return [];
      }

      return proYearlyTiers.map(function (tier) {
        return {
          price: Math.floor(tier.price / 1200 + 1),
          visitors: mapTierSessionsToVisitors(tier)
        };
      });
    }),
    selectedTierPrice: Ember.computed('billingFrequency', 'selectedMonthlyTier', 'selectedYearlyTier', function () {
      var frequency = this.get('billingFrequency');
      return frequency === 'monthly' ? this.get('selectedMonthlyTier.price') : this.get('selectedYearlyTier.price');
    }),
    actions: {
      selectMonthlyTier: function selectMonthlyTier(tier) {
        this.set('_selectedMonthlyTierOption', tier);
      },
      selectYearlyTier: function selectYearlyTier(tier) {
        this.set('_selectedYearlyTierOption', tier);
      }
    }
  });

  _exports.default = _default;
});