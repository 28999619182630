define("funnelytics/components/flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['fl-alert', 'fl-alert--large'],
    classNameBindings: ['messageTypeClass'],
    isWarning: Ember.computed.equal('messageType', 'warning'),
    isError: Ember.computed.equal('messageType', 'error'),
    isInfo: Ember.computed.equal('messageType', 'info'),
    links: Ember.computed.readOnly('flash.links'),
    hasLinks: Ember.computed.notEmpty('links'),
    message: Ember.computed.readOnly('flash.message'),
    messageType: Ember.computed.readOnly('flash.type'),
    messageTypeClass: Ember.computed('flash.type', function () {
      var flashType = this.get('flash.type');
      return "fl-alert--".concat(flashType);
    }),
    predefinedTemplate: Ember.computed.readOnly('flash.predefinedTemplate'),
    isPredefinedTemplate: Ember.computed.readOnly('flash.isPredefinedTemplate'),
    flashType: Ember.computed('flash.type', function () {
      var flashType = this.get('flash.type');
      return flashType.classify();
    }),
    click: function click() {
      if (this.get('hasLinks') || this.get('isPredefinedTemplate')) {
        return;
      }

      var flash = this.get('flash');
      flash.destroyMessage();
    }
  });

  _exports.default = _default;
});