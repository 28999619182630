define("funnelytics/services/funnelytics-redirect", ["exports", "funnelytics/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    router: Ember.inject.service(),
    session: Ember.inject.service(),
    performStorageBasedRedirect: function performStorageBasedRedirect() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          transition = _ref.transition,
          _ref$fallback = _ref.fallback,
          fallback = _ref$fallback === void 0 ? null : _ref$fallback;

      var authenticated = this.get('session.isAuthenticated');

      if (!authenticated) {
        return;
      }

      var params = JSON.parse(window.localStorage.getItem('authenticationRedirect')) || {};
      window.localStorage.removeItem('authenticationRedirect');

      if (params.redirect) {
        if (transition) {
          transition.abort();
        }

        window.location = params.redirect;
        return;
      } else if (params.url) {
        if (params.param) {
          this.get('router').transitionTo(params.url, params.param);
        }

        this.get('router').transitionTo(params.url);
        return;
      }

      if (fallback) {
        this.get('router').transitionTo(fallback);
      }
    },
    performRedirectToNewCanvasFunnel: function performRedirectToNewCanvasFunnel(funnelId) {
      var openInNew = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var url = "".concat(_environment.default.newCanvasApp, "/funnels/").concat(funnelId);

      if (openInNew) {
        window.open(url, '_blank');
      }
    },
    redirectToTheTutorialCanvas: function redirectToTheTutorialCanvas(funnelId) {
      var openInNew = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
      var url = "".concat(_environment.default.newCanvasApp, "/tutorial");

      if (openInNew) {
        window.open(url, '_blank');
      }
    }
  });

  _exports.default = _default;
});