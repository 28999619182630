define("funnelytics/templates/registered", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l04BhJsq",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"register-upsell\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"register-upsell__left\"],[9],[0,\"\\n    Hwody\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"register-upsell__left\"],[9],[0,\"\\n    Folks\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/registered.hbs"
    }
  });

  _exports.default = _default;
});