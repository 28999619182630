define("funnelytics/components/containers/funnel-editor/right-sidebar-container", ["exports", "lodash", "funnelytics/custom/constants/AnalyticsConstants", "funnelytics/custom/misc/countries"], function (_exports, _lodash, _AnalyticsConstants, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analyticsService: Ember.inject.service(),
    areAnalyticsSessionsLoading: Ember.computed.readOnly('analyticsService.areSessionsLoading'),
    hasAnalyticsEntityFocus: Ember.computed.readOnly('analyticsService.hasEntityFocus'),
    hasAnalyticsSessionFocus: Ember.computed.readOnly('analyticsService.hasSessionFocus'),
    analyticsDateRangeInDays: Ember.computed.readOnly('analyticsService.dateRangeInDays'),
    analyticsSessionsCount: Ember.computed.readOnly('analyticsService.sessionsTotal'),
    analyticsSessionsList: Ember.computed.readOnly('analyticsService.sessionsList'),
    countriesFilter: Ember.computed.readOnly('analyticsService.countriesFilter'),
    profileAttributeCountriesData: Ember.computed.readOnly('analyticsService.profileAttributesData.profileCountries'),
    analyticsSessionGroups: Ember.computed('profileAttributeCountriesData', 'analyticsSessionsCount', function () {
      var profileAttributesCountry = this.get('profileAttributeCountriesData') || [];
      return profileAttributesCountry.map(function (country) {
        var hits = _lodash.default.get(country, [_AnalyticsConstants.default.PROFILE_ATTRIBUTES_OUTPUT.COUNTRIES.HITS]);

        var countryName = _lodash.default.get(country, [_AnalyticsConstants.default.PROFILE_ATTRIBUTES_OUTPUT.COUNTRIES.NAME]);

        return {
          countryCode: _lodash.default.get(_countries.countryToCodeObject, [countryName]),
          countryName: countryName,
          visitorsCount: hits
        };
      });
    }),
    isAnalyticsSessionGroupsLoading: Ember.computed.readOnly('analyticsService.isProfileAttributesDataLoading'),
    analyticsSessionFocusId: Ember.computed.readOnly('analyticsService.sessionFocusId'),
    analyticsSessionFocusProfileAttributes: Ember.computed.readOnly('analyticsService.sessionFocusProfileAttributes'),
    analyticsSessionFocusSteps: Ember.computed.readOnly('analyticsService.sessionFocusSteps'),
    onFindElement: function onFindElement() {},
    onEnableAnalyticsNumbers: function onEnableAnalyticsNumbers() {},
    onUpdateThumbnail: function onUpdateThumbnail() {},
    onFocusAnalyticsOnEntity: function onFocusAnalyticsOnEntity() {},
    onFunnelIsEdited: function onFunnelIsEdited() {},
    onStartComparing: function onStartComparing() {},
    onStopComparing: function onStopComparing() {},
    actions: {
      // parent actions, later to be moved to funnel-editor container:
      findElement: function findElement() {
        var handler = this.get('onFindElement');
        return handler.apply(void 0, arguments);
      },
      updateThumbnail: function updateThumbnail() {
        var handler = this.get('onUpdateThumbnail');
        return handler.apply(void 0, arguments);
      },
      // end parent actions
      loadAnalyticsReferrers: function loadAnalyticsReferrers(canvasEntity) {
        var focusOnEntity = this.get('onFocusAnalyticsOnEntity');
        var enableNumbers = this.get('onEnableAnalyticsNumbers');
        focusOnEntity(canvasEntity);
        this.get('analyticsService').referrersQuery();
        enableNumbers();
      },
      focusAnalyticsOnSession: function focusAnalyticsOnSession(session) {
        this.get('analyticsService').selectSession(session);
      },
      focusAnalyticsOnEntity: function focusAnalyticsOnEntity(entity) {
        var focusOnEntity = this.get('onFocusAnalyticsOnEntity');
        focusOnEntity(entity);
      },
      loadAnalyticsSessionsNextPage: function loadAnalyticsSessionsNextPage() {
        this.get('analyticsService').loadNextUsersPage();
      },
      setFunnelAsEdited: function setFunnelAsEdited(isFunnelEdited) {
        var handler = this.get('onFunnelIsEdited');
        handler(isFunnelEdited);
      },
      startComparing: function startComparing(mode, filters) {
        var handler = this.get('onStartComparing');
        handler(mode, filters);
      },
      stopComparing: function stopComparing() {
        var handler = this.get('onStopComparing');
        handler();
      }
    }
  });

  _exports.default = _default;
});