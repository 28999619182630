define("funnelytics/templates/components/for-reuse/truncated-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hqnAl1di",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[12,\"title\",[21,\"text\"]],[9],[0,\"\\n  \"],[1,[21,\"truncatedText\"],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/truncated-text.hbs"
    }
  });

  _exports.default = _default;
});