define("funnelytics/templates/dashboard/funnels/unorganized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QJbxMX4y",
    "block": "{\"symbols\":[\"flash\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"wd--90 fl--mlr--3 \",[27,\"if\",[[23,[\"flashMessages\",\"hasFlashes\"]],\"fl--mt--2\"],null]]]],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"flashMessages\",\"queue\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"flash-message\",null,[[\"flash\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"fl-banner fl--mt--4\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fl--m--font fl--alt--font\"],[9],[0,\"\\n      Unorganized Funnels\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"br\"],[9],[10],[0,\"\\n\\n  \"],[1,[27,\"funnel-list\",null,[[\"funnels\",\"projects\",\"canEditFunnels\",\"isFunnelAdmin\",\"currentProjectId\",\"onDelete\",\"onCompleteSave\",\"accessToNewVersion\",\"projectVersion\"],[[23,[\"model\",\"funnels\"]],[23,[\"model\",\"projects\"]],true,true,[23,[\"NULL_PROJECT\"]],[27,\"action\",[[22,0,[]],\"refreshRouteFromController\"],null],[27,\"action\",[[22,0,[]],\"transitionToRouteFromController\"],null],[23,[\"model\",\"accessToNewVersion\"]],1]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/dashboard/funnels/unorganized.hbs"
    }
  });

  _exports.default = _default;
});