define("funnelytics/templates/components/account/subscription-billing/woo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hPZ1be28",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"fl-flex-row card-body-text fl--mt--1\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"subscription-billing\"],[9],[0,\"\\n    \"],[7,\"h3\"],[11,\"class\",\"subscription-billing__name\"],[9],[0,\"\\n      \"],[1,[21,\"name\"],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"h4\"],[11,\"class\",\"subscription-billing__next-bill-title\"],[9],[0,\"\\n      Next Payment:\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"subscription-billing__next-billing-date\"],[9],[0,\"\\n      \"],[1,[27,\"date-parser\",[[23,[\"nextPayment\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"subscription-billing__price\"],[9],[0,\"\\n      This is a legacy plan that we cannot easily report on. Please email support at support@funnelytics.io \\n      to switch over to our new payment gateway so we can better report your billing cycle for you.\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/account/subscription-billing/woo.hbs"
    }
  });

  _exports.default = _default;
});