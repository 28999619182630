define("funnelytics/mixins/query-parameter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Mixin.create({
    removeEmptyQP: function removeEmptyQP() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      /**
       * Takes a queryParams object and filters out any undefined values
       */
      if (queryParams === null || _typeof(queryParams) !== 'object') return new Error('parameter must be an object');
      var filteredQueryParams = Object.keys(queryParams).reduce(function (acc, key) {
        if (queryParams[key]) {
          acc[key] = queryParams[key];
        }

        return acc;
      }, {});
      return filteredQueryParams;
    },
    toSearchParams: function toSearchParams() {
      var queryParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      /**
       * Takes a queryParams object and returns a URLSearchParams object
       */
      var filteredQueryParams = this.removeEmptyQP(queryParams);
      var searchParams = new URLSearchParams(filteredQueryParams);
      return searchParams;
    }
  });

  _exports.default = _default;
});