define("funnelytics/components/left-sidebar/navigation/project-nav", ["exports", "funnelytics/services/banner-display"], function (_exports, _bannerDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    bannerDisplay: Ember.inject.service(),
    containerHeight: Ember.computed("bannerDisplay.displayStates.".concat(_bannerDisplay.BannerType.anouncement), function () {
      var bannerVisible = this.get('bannerDisplay').getBannerState(_bannerDisplay.BannerType.anouncement);
      var heightAdjustment = 64; // anouncement banner height

      if (bannerVisible) {
        heightAdjustment += 48;
      }

      return "calc(100vh - ".concat(heightAdjustment, "px)");
    }),
    setSelectedV2Workspace: function setSelectedV2Workspace() {},
    actions: {
      updateSelected: function updateSelected(id) {
        var handler = this.get('setSelectedV2Workspace');
        handler(id);
      }
    }
  });

  _exports.default = _default;
});