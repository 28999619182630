define("funnelytics/templates/components/for-reuse/radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ch+yHerq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[11,\"class\",\"radio-button--container\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"radio-button--label_text\"],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"input\"],[12,\"name\",[21,\"name\"]],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"radio-button--checkmark\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/radio-button.hbs"
    }
  });

  _exports.default = _default;
});