define("funnelytics/templates/order/vault/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y7zCNDCC",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"funnel-pages/checkout\",null,[[\"totalCost\",\"primaryProduct\",\"bumpProducts\",\"vaultInfo\",\"proInfo\",\"user\",\"hasAlternativeCardOrder\"],[[23,[\"totalCost\"]],[23,[\"primaryProduct\"]],[23,[\"bumpProducts\"]],[23,[\"vaultInfo\"]],[23,[\"proInfo\"]],[23,[\"user\"]],true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/order/vault/index.hbs"
    }
  });

  _exports.default = _default;
});