define("funnelytics/templates/dashboard/funnels/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ArvK2ALx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"wd--90 fl--mlr--3\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"fl--m--auto\"],[9],[0,\"\\n    \"],[1,[27,\"loading-dots\",null,[[\"large\"],[true]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/dashboard/funnels/loading.hbs"
    }
  });

  _exports.default = _default;
});