define("funnelytics/components/canvas-elements/forecasting-outbound-link", ["exports", "lodash", "funnelytics/custom/tracking/ProductTracker", "funnelytics/custom/constants/TrackingConstants"], function (_exports, _lodash, _ProductTracker, _TrackingConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['forecasting-links'],
    conversionRate: Ember.computed('outboundLink.sourceEntity', 'outboundLink.targetEntity', function () {
      var sourceEntity = this.get('outboundLink.sourceEntity');
      var targetEntity = this.get('outboundLink.targetEntity');
      return sourceEntity.getForecastCtr(targetEntity);
    }),
    targetImage: Ember.computed('outboundLink.targetEntity', function () {
      return this.get('outboundLink.targetEntity').getImage();
    }),
    targetLabel: Ember.computed('outboundLink.targetEntity', function () {
      return this.get('outboundLink.targetEntity').getLabel();
    }),
    setValueDelayInMs: 500,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('setOutboundConversionDebounce', _lodash.default.debounce(function (targetEntity, newConversionRate) {
        _this.get('outboundLink.sourceEntity').setForecastCtr(targetEntity, newConversionRate);

        _ProductTracker.default.trigger(_TrackingConstants.default.CANVAS_FORECAST_CTR);

        _this.notifyPropertyChange('conversionRate');

        var onUpdate = _this.get('onUpdate');

        onUpdate();
      }, this.get('setValueDelayInMs')));
    },
    onUpdate: function onUpdate() {},
    actions: {
      setOutboundLinkConversionRate: function setOutboundLinkConversionRate(newValue) {
        var setOutboundConversionRate = this.get('setOutboundConversionDebounce');
        setOutboundConversionRate(this.get('outboundLink.targetEntity'), newValue);
      }
    }
  });

  _exports.default = _default;
});