define("funnelytics/templates/components/sales-funnels/call-to-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eEOyZtnt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"order-cta \",[27,\"if\",[[23,[\"extraMargin\"]],\"extra-margin\",\"\"],null],\" \"]]],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"\\n      cta-header \",[27,\"if\",[[23,[\"onDarkBackground\"]],\"light\",\"\"],null],\" \\n      \",[27,\"if\",[[23,[\"smallText\"]],\"small-text\",\"\"],null],\"\\n      \",[27,\"if\",[[23,[\"hideCTAHeader\"]],\"d-none\",\"d-block\"],null],\"\\n    \"]]],[9],[0,\"\\n    \"],[1,[21,\"header\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"upsellError\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\"],[11,\"class\",\"txt--red\"],[9],[0,\"Sorry! There was an error adding this to your order.\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"button\"],[11,\"class\",\"activate-pro\"],[12,\"disabled\",[21,\"upgradeButtonDisabled\"]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"upsell\"],null]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"upsellError\"]]],null,{\"statements\":[[0,\"      Proceed to Order Confirmation\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[21,\"buttonText\"],false],[0,\" $\"],[1,[23,[\"product\",\"cost\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"cta-subheader \",[27,\"if\",[[23,[\"hideAfterYearPricing\"]],\"d-none\",\"d-block\"],null]]]],[9],[0,\"\\n    \"],[1,[21,\"subheader\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"cta-opt-out\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"downsell\"],null]],[9],[0,\"\\n    \"],[1,[21,\"optOutText\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/sales-funnels/call-to-action.hbs"
    }
  });

  _exports.default = _default;
});