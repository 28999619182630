define("funnelytics/components/canvas-elements/utm-explorer", ["exports", "ember-uuid", "lodash", "funnelytics/custom/constants/CanvasConstants", "funnelytics/custom/constants/UtmConstants", "funnelytics/custom/analytics/analytics/canvas/EntityParameter"], function (_exports, _emberUuid, _lodash, _CanvasConstants, _UtmConstants, _EntityParameter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _ref, _ref2, _ref3, _ref4, _ref5;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var EMPTY_FILTER_VALUE = '?aVc,tU#CaZ62l~4IuHd.BE.xF{mF.nth]wmma:%aIE3*DrM/:';
  var FILTER_SOURCE = 'filterSource';
  var FILTER_MEDIUM = 'filterMedium';
  var FILTER_CAMPAIGN = 'filterCampaign';
  var FILTER_CONTENT = 'filterContent';
  var FILTER_TERM = 'filterTerm';
  var FILTER_KEY = 'filterName';
  var UTM_KEY = 'utm';
  var UNIQUES_KEY = 'uniques';
  var FILTER_VALUE_KEY = 'filterValue';
  var LABEL_KEY = 'label';
  var ID_KEY = 'htmlId';
  var IS_UTM_VISIBLE = 'isUtmVisible';
  var FILTER_TO_UTM_MAP = [(_ref = {}, _defineProperty(_ref, FILTER_KEY, FILTER_SOURCE), _defineProperty(_ref, UTM_KEY, _UtmConstants.default.UTM_SOURCE), _defineProperty(_ref, LABEL_KEY, 'Source'), _defineProperty(_ref, ID_KEY, 'utm_source_filter'), _ref), (_ref2 = {}, _defineProperty(_ref2, FILTER_KEY, FILTER_MEDIUM), _defineProperty(_ref2, UTM_KEY, _UtmConstants.default.UTM_MEDIUM), _defineProperty(_ref2, LABEL_KEY, 'Medium'), _defineProperty(_ref2, ID_KEY, 'utm_medium_filter'), _ref2), (_ref3 = {}, _defineProperty(_ref3, FILTER_KEY, FILTER_CAMPAIGN), _defineProperty(_ref3, UTM_KEY, _UtmConstants.default.UTM_CAMPAIGN), _defineProperty(_ref3, LABEL_KEY, 'Campaign'), _defineProperty(_ref3, ID_KEY, 'utm_campaign_filter'), _ref3), (_ref4 = {}, _defineProperty(_ref4, FILTER_KEY, FILTER_CONTENT), _defineProperty(_ref4, UTM_KEY, _UtmConstants.default.UTM_CONTENT), _defineProperty(_ref4, LABEL_KEY, 'Content'), _defineProperty(_ref4, ID_KEY, 'utm_content_filter'), _ref4), (_ref5 = {}, _defineProperty(_ref5, FILTER_KEY, FILTER_TERM), _defineProperty(_ref5, UTM_KEY, _UtmConstants.default.UTM_TERM), _defineProperty(_ref5, LABEL_KEY, 'Term'), _defineProperty(_ref5, ID_KEY, 'utm_term_filter'), _ref5)];
  var FILTER_NAMES = FILTER_TO_UTM_MAP.map(function (filterMap) {
    return filterMap[FILTER_KEY];
  });

  var _default = Ember.Component.extend({
    analyticsService: Ember.inject.service(),
    canvasService: Ember.inject.service(),
    commandService: Ember.inject.service(),
    tagName: 'section',
    classNames: ['utm-explorer'],
    onSelectEntity: function onSelectEntity() {},
    isLoading: Ember.computed.readOnly('analyticsService.isUtmsRequestLoading'),
    utmsList: Ember.computed.readOnly('analyticsService.utmsList'),
    targetEntity: Ember.computed.readOnly('analyticsService.focusEntity'),
    EMPTY_FILTER_VALUE: EMPTY_FILTER_VALUE,
    setToTopLayer: function setToTopLayer() {
      this.set('utmLayers', []);
    },
    // TODO: This has to be fixed to work more broadly and collaborate with traffic explorer
    recalculateMapped: function recalculateMapped() {
      this.set('mappedUtmsIdentifier', (0, _emberUuid.v4)());
    },
    setFiltersAsEmpty: function setFiltersAsEmpty() {
      var _this = this;

      FILTER_NAMES.forEach(function (filterName) {
        _this.set(filterName, EMPTY_FILTER_VALUE);
      });
    },
    initializeProperties: function initializeProperties() {
      this.setToTopLayer();
      this.recalculateMapped();
      this.setFiltersAsEmpty();
      this.setProperties({
        isCountLoading: false,
        filterCount: null
      });
    },
    didInsertElement: function didInsertElement() {
      this.initializeProperties();
    },
    filterCountValue: Ember.computed('filterCount', 'isCountLoading', function () {
      if (this.get('isCountLoading')) {
        return 'Loading...';
      }

      return Number(this.get('filterCount')).toLocaleString();
    }),
    canvasChangeObserver: Ember.observer('canvasElementStatusId', function () {
      this.recalculateMapped();
    }),
    areFiltersEmpty: Ember.computed.apply(void 0, _toConsumableArray(FILTER_NAMES).concat([function () {
      var _this2 = this;

      return FILTER_NAMES.every(function (filterName) {
        if (!_this2.get(filterName)) {
          return true;
        }

        return _this2.get(filterName) === EMPTY_FILTER_VALUE;
      });
    }])),
    isFilterSet: Ember.computed.not('areFiltersEmpty'),
    utms: Ember.computed('utmsList', 'mappedTrafficEntities', function () {
      var _this3 = this;

      var copy = _lodash.default.cloneDeep(this.get('utmsList'));

      var final = copy.map(function (utm) {
        utm.count = parseInt(utm.count, 10);
        return utm;
      });
      final.forEach(function (utm) {
        var utmParameters = _UtmConstants.default.ALLOWED_UTMS.map(function (utmKey) {
          var value = _lodash.default.get(utm, [utmKey]);

          var hasValue = !_lodash.default.isEmpty(value);
          return new _EntityParameter.default({
            key: utmKey,
            value: hasValue ? value : null,
            contains: hasValue
          });
        });

        var matchingEntity = _this3.get('canvasService').getEntityManager().findMatchingEntity(_this3.get('mappedTrafficEntities'), {
          parameters: utmParameters,
          url: null
        });

        var canvasId = matchingEntity ? matchingEntity.getID() : null;

        _lodash.default.set(utm, [_UtmConstants.default.MATCHING_CANVAS_ID], canvasId);
      });
      return final.sort(function (a, b) {
        return b.count - a.count;
      });
    }),
    filterChangedObserver: Ember.observer.apply(void 0, ['utms'].concat(_toConsumableArray(FILTER_NAMES), [function () {
      return this.fetchCountForFilters();
    }])),
    filteredUtms: Ember.computed.apply(void 0, ['utms'].concat(_toConsumableArray(FILTER_NAMES), [function () {
      var _this4 = this;

      return this.get('utms').map(function (utm, index) {
        var filterFails = FILTER_TO_UTM_MAP.some(function (filterMap) {
          if (_this4.get(filterMap[FILTER_KEY]) === EMPTY_FILTER_VALUE) {
            return false;
          }

          if (_this4.get(filterMap[FILTER_KEY]) === _UtmConstants.default.UTM_IS_NOT_SET) {
            return !_lodash.default.isEmpty(_lodash.default.get(utm, [filterMap[UTM_KEY]]));
          }

          return _this4.get(filterMap[FILTER_KEY]) && _this4.get(filterMap[FILTER_KEY]) !== _lodash.default.get(utm, [filterMap[UTM_KEY]]);
        });

        _this4.set("utms.".concat(index, ".").concat(IS_UTM_VISIBLE), !filterFails);

        return utm;
      });
    }])),
    uniqueFiltered: Ember.computed('filteredUtms', function () {
      var _this5 = this;

      return FILTER_TO_UTM_MAP.map(function (filterMap) {
        var clonedMap = _lodash.default.cloneDeep(filterMap);

        var uniques = _lodash.default.uniq(_this5.get('filteredUtms').filter(function (utm) {
          return _lodash.default.get(utm, [IS_UTM_VISIBLE]);
        }).map(function (utm) {
          return _lodash.default.get(utm, clonedMap[UTM_KEY]);
        }));

        var sorted = uniques.sort(function (a, b) {
          return ('' + a).localeCompare(b);
        });
        var hasEmpty = sorted.some(function (utmValue) {
          return _lodash.default.isEmpty(utmValue);
        });
        var nonEmptySorted = sorted.filter(function (utmValue) {
          return !_lodash.default.isEmpty(utmValue);
        });
        clonedMap[UNIQUES_KEY] = nonEmptySorted;

        if (hasEmpty) {
          clonedMap[UNIQUES_KEY].push(_UtmConstants.default.UTM_IS_NOT_SET);
        }

        clonedMap[FILTER_VALUE_KEY] = _this5.get(clonedMap[FILTER_KEY]);
        return clonedMap;
      });
    }),
    mappedTrafficEntities: Ember.computed('targetEntity', 'mappedUtmsIdentifier', function () {
      var targetEntity = this.get('targetEntity');

      if (targetEntity === null) {
        return this.get('canvasService').getEntityManager().getAllTrafficEntities();
      }

      var mappedTrafficEntities = targetEntity.getInboundLinks({
        isIgnoreInBetweenAllowed: false
      }).map(function (link) {
        return link.getSourceEntity();
      }).filter(function (entity) {
        return entity.getType() === _CanvasConstants.default.TYPE_SOURCE;
      });
      return mappedTrafficEntities;
    }),
    areFiltersMapped: Ember.computed('mappedFilterSourceId', function () {
      return !_lodash.default.isEmpty(this.get('mappedFilterSourceId'));
    }),
    mappedFilterSourceId: Ember.computed.apply(void 0, _toConsumableArray(FILTER_NAMES).concat(['mappedTrafficEntities', function () {
      var _this6 = this;

      var requiredParameters = FILTER_TO_UTM_MAP.filter(function (filterName) {
        return _this6.get(filterName[FILTER_KEY]) && _this6.get(filterName[FILTER_KEY]) !== EMPTY_FILTER_VALUE;
      }).map(function (filterName) {
        var filterValue = _this6.get(filterName[FILTER_KEY]);

        var value = filterValue === _UtmConstants.default.UTM_IS_NOT_SET ? null : filterValue;
        return new _EntityParameter.default({
          key: filterName[UTM_KEY],
          value: value
        });
      });
      var matchingEntity = this.get('canvasService').getEntityManager().findMatchingEntity(this.get('mappedTrafficEntities'), {
        parameters: requiredParameters,
        url: null
      });

      if (!matchingEntity) {
        return null;
      }

      return matchingEntity.getID();
    }])),
    setFilter: function setFilter(key, passedEvent) {
      passedEvent.preventDefault();

      var value = _lodash.default.get(passedEvent, ['target', 'value'], null);

      this.set(key, value);
    },
    onAddToMap: null,
    addToMap: function addToMap(utms) {
      var addToMapHandler = this.get('onAddToMap');

      if (addToMapHandler === null) {
        this.get('canvasService').addUtmSourceToMap({
          utms: utms,
          targetEntity: this.get('targetEntity')
        });
        return;
      }

      return addToMapHandler(utms);
    },
    debouncedFetchCountForFilters: _lodash.default.debounce(function () {
      var _this7 = this;

      this.get('commandService').startBatchCommand();
      var targetEntity = this.get('targetEntity');

      if (!targetEntity) {
        return;
      }

      var newEntity = this.get('canvasService').createElement({
        type: _CanvasConstants.default.TYPE_SOURCE_NAME
      });
      this.get('uniqueFiltered').forEach(function (filterMap) {
        if (filterMap[FILTER_VALUE_KEY]) {
          if (filterMap[FILTER_VALUE_KEY] === _UtmConstants.default.UTM_IS_NOT_SET) {
            newEntity.setAttributes([new _EntityParameter.default({
              key: filterMap[UTM_KEY],
              contains: false
            })]);
          } else if (filterMap[FILTER_VALUE_KEY] !== EMPTY_FILTER_VALUE) {
            newEntity.setUtm(filterMap[UTM_KEY], filterMap[FILTER_VALUE_KEY]);
          }
        }
      });
      var linkEntity = this.get('canvasService').createConnection({
        source: {
          id: newEntity.getID()
        },
        target: {
          id: targetEntity.getID()
        }
      });
      var entities = [targetEntity, newEntity, linkEntity];
      var linkId = linkEntity.getID();
      this.set('isCountLoading', true);
      this.set('filterCount', null);
      this.get('commandService').endBatchCommand();
      return this.get('analyticsService').oneOffCanvasRequest(entities).then(function (report) {
        newEntity.remove();
        linkEntity.remove();

        _this7.set('isCountLoading', false);

        _this7.set('filterCount', report.getHits(linkId));
      });
    }, 1500),
    fetchCountForFilters: function fetchCountForFilters() {
      this.set('filterCount', null);

      if (this.get('areFiltersEmpty')) {
        return;
      }

      this.set('isCountLoading', true);
      this.debouncedFetchCountForFilters();
    },
    selectCanvasElement: function selectCanvasElement() {},
    actions: {
      addFilteredToMap: function addFilteredToMap() {
        var _this8 = this;

        var utms = FILTER_TO_UTM_MAP.filter(function (filterMap) {
          return _this8.get(filterMap[FILTER_KEY]) !== EMPTY_FILTER_VALUE;
        }).map(function (filterMap) {
          // TODO: Need to do a similar thing for the count check!
          var filterValue = _this8.get(filterMap[FILTER_KEY]);

          var isContains = filterValue !== _UtmConstants.default.UTM_IS_NOT_SET;
          return new _EntityParameter.default({
            contains: isContains,
            key: filterMap[UTM_KEY],
            value: isContains ? filterValue : null
          });
        });
        this.addToMap(utms);
        this.recalculateMapped();
      },
      addRowToMap: function addRowToMap(utms) {
        this.addToMap(utms);
        this.recalculateMapped();
      },
      findMappedFilter: function findMappedFilter() {
        this.send('selectElement', this.get('mappedFilterSourceId'));
      },
      selectElement: function selectElement(targetId) {
        var handleSelectEntity = this.get('onSelectEntity');
        return handleSelectEntity(targetId);
      },
      setFilter: function setFilter(filterType, passedEvent) {
        this.setFilter(filterType, passedEvent);
      },
      clearAllFilters: function clearAllFilters() {
        this.setFiltersAsEmpty();
      }
    }
  });

  _exports.default = _default;
});