define("funnelytics/components/planning/planning-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    canvasService: Ember.inject.service(),
    isCanvasNotesEditorOpen: Ember.computed.readOnly('canvasService.isCanvasNotesEditorOpen'),
    entity: Ember.computed.alias('canvasService.editorEntity'),
    model: Ember.computed('entity', function () {
      var entity = this.get('entity');

      if (!entity || entity.isEmpty()) {
        return null;
      }

      return entity.getCell();
    }),
    label: Ember.computed('entity', function () {
      return this.get('entity').getLabel();
    }),
    actions: {
      backToFunnelNotes: function backToFunnelNotes() {
        this.get('canvasService').openFunnelNotes();
      },
      inputLabelChange: function inputLabelChange(evt) {
        var entity = this.get('entity');

        if (!entity) {
          return;
        }

        entity.setLabel(evt.target.value);
        this.notifyPropertyChange('label');
      }
    }
  });

  _exports.default = _default;
});