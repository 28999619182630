define("funnelytics/templates/components/for-reuse/ui-elements/testimonials", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gC3lBgxk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"trustpilot-widget\"],[11,\"data-locale\",\"en-US\"],[11,\"data-template-id\",\"539ad60defb9600b94d7df2c\"],[11,\"data-businessunit-id\",\"5da9bfc1abb50a0001722679\"],[11,\"data-style-height\",\"500px\"],[11,\"data-style-width\",\"100%\"],[11,\"data-theme\",\"light\"],[11,\"data-stars\",\"5\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"href\",\"https://www.trustpilot.com/review/funnelytics.io\"],[11,\"target\",\"_blank\"],[11,\"rel\",\"noopener\"],[9],[0,\"Trustpilot\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/ui-elements/testimonials.hbs"
    }
  });

  _exports.default = _default;
});