define("funnelytics/custom/constants/ProjectPermissionConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PermissionNames = Object.freeze({
    IS_COLLABORATION_ALLOWED: 'isCollaborationAllowed',
    IS_INTEGRATION_ALLOWED: 'isIntegrationAllowed',
    IS_ELIGIBLE_FOR_FREE_TRIAL: 'isEligibleForFreeTrial',
    UNLOCK_CANVAS_ALLOWANCE: 'unlockCanvasAllowance',
    UNLOCKED_CANVASES_ALLOWED: 'unlockCanvasAllowance.unlocked_canvases_allowed',
    UNLOCKED_CANVASES: 'unlockCanvasAllowance.unlocked_canvases',
    IS_READONLY_ACCESS: 'isReadonlyAccess',
    IS_COLLABORATOR: 'isCollaborator',
    IS_ADMIN_IMPERSONATION: 'isAdminImpersonation',
    IS_ANALYTICS_ALLOWED: 'isAnalyticsAllowed',
    IS_CROSSDOMAIN_TRACKING_ALLOWED: 'crossdomainTrackingAllowed',
    IS_WEBHOOK_ALLOWED: 'webhookIntegrationAllowed',
    IS_ZAPIER_ALLOWED: 'zapierIntegrationAllowed'
  });
  var _default = PermissionNames;
  _exports.default = _default;
});