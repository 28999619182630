define("funnelytics/templates/components/routable-modal-hold", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K2JiNhEV",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"outlet\",[\"routable-modal-outlet\"],null],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/routable-modal-hold.hbs"
    }
  });

  _exports.default = _default;
});