define("funnelytics/components/canvas-share", ["exports", "ember-uuid", "funnelytics/custom/collaborators", "lodash"], function (_exports, _emberUuid, _collaborators, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var initialClientData = {
    first_name: '',
    last_name: '',
    email: '',
    permissions: 0,
    placeholder: {
      first_name: 'John',
      last_name: 'Doe',
      email: 'example@example.com'
    }
  };

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    inviting: false,
    isEditing: false,
    error: null,
    currentClientData: null,
    isAddingClient: false,
    onClose: function onClose() {},
    newClient: Ember.Object.create(initialClientData),
    newFirstSet: Ember.computed.notEmpty('newClient.first_name'),
    newLastSet: Ember.computed.notEmpty('newClient.last_name'),
    newEmailSet: Ember.computed.notEmpty('newClient.email'),
    newPermissionsSet: Ember.computed.match('newClient.permissions', /[01]/),
    newClientReady: Ember.computed.and('newFirstSet', 'newLastSet', 'newEmailSet', 'newPermissionsSet'),
    newClientNotReady: Ember.computed.not('newClientReady'),
    cannotCreateNewClient: Ember.computed.or('newClientNotReady', 'inviting'),
    canCreateNewClient: Ember.computed.not('cannotCreateNewClient'),
    hasNoProject: Ember.computed.empty('funnel.project.id'),
    resetNewClient: function resetNewClient() {
      this.set('newClient', Ember.Object.create(initialClientData));
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.setProperties({
        modalId: (0, _emberUuid.v4)()
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$("#".concat(this.get('modalId'))).off('click');
    },
    didInsertElement: function didInsertElement() {
      var thisComponent = this;
      this.$("#".concat(this.get('modalId'))).on('click', function (evt) {
        if (this !== evt.target) {
          return;
        }

        thisComponent.onClose();
      });
      var projectID = this.get('project').get('id');
      this.set('collaborators', new _collaborators.default(this));
      var clientsForProject = [];
      var test = this.get('collaborators');
      test.context.clients.forEach(function (collaborator) {
        if (collaborator.project.get('id') === projectID) {
          clientsForProject.push(collaborator);
        }
      });
      this.set('clients', clientsForProject);
    },
    actions: {
      shareLinkPublicToggle: function shareLinkPublicToggle() {
        this.toggleProperty('funnel.is_private');
        this.get('funnel').save();
      },
      addClient: function addClient() {
        this.toggleProperty('isAddingClient');
      },
      sendInvite: function sendInvite() {
        this.get('collaborators').sendInvite(false, this.get('project'), this.get('user'));
      },
      handleUpdatePermissions: function handleUpdatePermissions(clientRelation, index, evt) {
        var permission = _lodash.default.get(evt, ['target', 'value'], 0);

        clientRelation.set('permissions', permission);
        this.send('updateClient', clientRelation, index);
      },
      handleCreatePermissions: function handleCreatePermissions(value) {
        this.set('newClient.permissions', value);
      },
      updateClient: function updateClient(clientRelation, index) {
        this.get('collaborators').updateClient(clientRelation, index);
      },
      deleteClient: function deleteClient(clientModel) {
        this.get('collaborators').deleteClient(clientModel, true);
      },
      performClose: function performClose() {
        this.onClose();
      }
    }
  });

  _exports.default = _default;
});