define("funnelytics/templates/components/dashboard/retirement-banner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "go5RX0sa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"announcement-banner show\"],[11,\"style\",\"background-color:black;\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"w-100\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"centering-container\"],[9],[0,\"\\n            \"],[1,[27,\"svg-display\",null,[[\"symbolDefId\",\"class\"],[\"announcement\",\"fl-icon__svg--announcement-retirement\"]]],false],[0,\"\\n            \"],[7,\"div\"],[11,\"style\",\"font-size: 12px;\"],[9],[0,\"It was a fun ride but we're retiring Funnelytics 1.0 and moving everbody to\\n                Funnelytics 2.0. Your account will be upgraded to 2.0 on the 31st October 2023.\"],[10],[0,\"\\n            \"],[7,\"div\"],[9],[0,\"\\n                \"],[7,\"a\"],[11,\"class\",\"fl-btn fl-btn--brand fl-btn-brand-banner\"],[12,\"href\",[21,\"btnLink\"]],[11,\"target\",\"_blank\"],[9],[0,\"Read More\"],[10],[0,\"\\n            \"],[10],[0,\"\\n        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/dashboard/retirement-banner.hbs"
    }
  });

  _exports.default = _default;
});