define("funnelytics/custom/constants/ColourHexConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ColourHexConstants = Object.freeze({
    BLACK: '#1E2532',
    BLUE: '#009BF3',
    RED: '#F43B46',
    GREEN: '#0ED073',
    ORANGE: '#FF8934',
    PURPLE: '#934DFF',
    TEAL: '#3AD3D3',
    YELLOW: '#FFD703',
    WHITE: '#FFFFFF',
    NONE: 'none',
    COLOUR_HEX_KEY: 'hex',
    COLOUR_CSS_CLASS: 'cssClass'
  });
  var _default = ColourHexConstants;
  _exports.default = _default;
});