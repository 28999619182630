define("funnelytics/templates/components/library/canvas-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vBRjApyo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"fl-funnel-card-v2 fl--p--1\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-overlay-v2\"],[12,\"onClick\",[27,\"action\",[[22,0,[]],\"onClick\"],null]],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"style\",\"display: flex; justify-content:space-between; padding: 8px 0 0 8px;\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"fl-funnel-card-name-v2\"],[11,\"style\",\"padding: 0\"],[9],[0,\"\\n            \"],[1,[23,[\"cardData\",\"funnel\",\"name\"]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[1,[27,\"component\",[\"library/import-count\"],[[\"importCount\"],[[23,[\"cardData\",\"import_count\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"component\",[\"library/creator-tags\"],[[\"creator\"],[[23,[\"creatorType\"]]]]],false],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fl-card-body-v2\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"cardData\",\"funnel\",\"version\"]],2],null]],null,{\"statements\":[[0,\"        \"],[7,\"img\"],[11,\"class\",\"fl-card-preview\"],[12,\"src\",[23,[\"cardData\",\"funnel\",\"preview_url\"]]],[11,\"onerror\",\"this.style.display = 'none'\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"object\"],[11,\"class\",\"fl-card-preview\"],[11,\"style\",\"z-index:0;\"],[12,\"data\",[23,[\"cardData\",\"funnel\",\"preview_url\"]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"fl-input-wrapper\"],[11,\"style\",\"letter-spacing: -0.3px; position: relative; z-index: 3;\"],[9],[0,\"\\n        \"],[1,[27,\"component\",[\"input/input-tags\"],[[\"mode\",\"canvasTags\",\"hideHeader\",\"maxTagCharacters\",\"restrictNumTagDisplayed\"],[\"card\",[23,[\"tags\"]],true,[23,[\"maxTagCharacters\"]],[23,[\"restrictNumTagDisplayed\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/library/canvas-card.hbs"
    }
  });

  _exports.default = _default;
});