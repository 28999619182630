define("funnelytics/templates/components/library/import-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y8XzB+2u",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"not\",[[23,[\"hideCount\"]]],null]],null,{\"statements\":[[7,\"div\"],[11,\"style\",\"background-color: white; display: flex; justify-content: space-between; width: fit-content;\"],[9],[0,\"\\n    \"],[7,\"div\"],[9],[0,\" \"],[7,\"object\"],[11,\"data\",\"downloads.svg\"],[11,\"width\",\"16\"],[11,\"height\",\"16\"],[9],[0,\" \"],[10],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"style\",\"padding-left: 6px;\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"style\",\"font-size: 12px;\"],[9],[0,\" \"],[1,[21,\"displayCount\"],false],[0,\" \"],[10],[0,\"  \\n    \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/library/import-count.hbs"
    }
  });

  _exports.default = _default;
});