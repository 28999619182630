define("funnelytics/templates/dashboard/projects/project/funnels/upsell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MhETuI18",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"routable-modal--dialog\"],[9],[0,\"\\n    \"],[1,[27,\"dashboard-elements/upsell/lite-trial\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/dashboard/projects/project/funnels/upsell.hbs"
    }
  });

  _exports.default = _default;
});