define("funnelytics/templates/dashboard/vault", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8DR4n6o7",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n  \"],[7,\"main\"],[11,\"class\",\"col-md-12 vault--container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"alert alert-warning mobile\"],[11,\"role\",\"alert\"],[9],[0,\"\\n      \"],[7,\"span\"],[9],[0,\"Unfortunately Funnelytics is not yet mobile friendly. Please use on your desktop for a better experience.\"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"main\"],[11,\"class\",\"vault-main\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-md-12  vault--container\"],[9],[0,\"\\n        \"],[1,[21,\"outlet\"],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/dashboard/vault.hbs"
    }
  });

  _exports.default = _default;
});