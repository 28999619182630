define("funnelytics/templates/dashboard/templates/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FeKgfTFX",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"funnel-editor\",null,[[\"type\",\"template\",\"templates\",\"edited\",\"onFunnelIsInUnsavedStateChange\",\"iconLimit\",\"userIcons\"],[\"template\",[23,[\"model\",\"template\"]],[23,[\"model\",\"templates\"]],[23,[\"edited\"]],[27,\"action\",[[22,0,[]],\"setEdited\"],null],[23,[\"model\",\"iconLimit\"]],[23,[\"model\",\"userIcons\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/dashboard/templates/view.hbs"
    }
  });

  _exports.default = _default;
});