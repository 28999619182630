define("funnelytics/components/vault/vault-sentence-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onSelectOfferType: function onSelectOfferType() {},
    onSelectIndustry: function onSelectIndustry() {},
    actions: {
      selectOfferType: function selectOfferType(offerType) {
        var handleOfferSelection = this.get('onSelectOfferType');
        handleOfferSelection(offerType);
      },
      selectIndustry: function selectIndustry(offerType) {
        var handleIndustrySelection = this.get('onSelectIndustry');
        handleIndustrySelection(offerType);
      }
    }
  });

  _exports.default = _default;
});