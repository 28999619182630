define("funnelytics/components/containers/funnel-editor/components/right-sidebar-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    handleClick: function handleClick() {},
    actions: {
      onClick: function onClick() {
        this.handleClick();
      }
    }
  });

  _exports.default = _default;
});