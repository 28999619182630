define("funnelytics/templates/components/upsells/upgrade-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0lyNckez",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"overlay-v2 \",[27,\"if\",[[23,[\"showMessageModal\"]],\" overlay-v2-show\"],null]]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"popup fit-content\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"upsell-modal__header\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"close\"],[3,\"action\",[[22,0,[]],\"close\"]],[9],[0,\"\\n            ×\\n        \"],[10],[0,\"\\n        \"],[1,[21,\"header\"],false],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[7,\"div\"],[11,\"class\",\"upsell-modal__body\"],[9],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"\\n            \"],[1,[21,\"firstCopy\"],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"p\"],[9],[0,\"\\n\"],[0,\"            \"],[1,[21,\"secondCopy\"],true],[0,\" \\n        \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"thirdCopy\"]]],null,{\"statements\":[[0,\"            \"],[7,\"p\"],[9],[0,\"\\n            \"],[1,[21,\"thirdCopy\"],false],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/upsells/upgrade-message.hbs"
    }
  });

  _exports.default = _default;
});