define("funnelytics/templates/components/containers/funnel-editor/components/right-sidebar-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tB8BFs7Z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[12,\"class\",[28,[\"\\n    analytics-panel__sidebar-tab\\n    \",[27,\"if\",[[23,[\"isActive\"]],\"analytics-panel__sidebar-tab--active\",\"\"],null],\"\\n  \"]]],[3,\"action\",[[22,0,[]],\"onClick\"]],[9],[0,\"\\n  \"],[7,\"svg\"],[11,\"class\",\"analytics-panel__sidebar-tab-icon\"],[9],[0,\"\\n    \"],[7,\"use\"],[12,\"xlink:href\",[28,[\"/assets/symbol-defs.svg#\",[21,\"iconName\"]]],\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/containers/funnel-editor/components/right-sidebar-tab.hbs"
    }
  });

  _exports.default = _default;
});