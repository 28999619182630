define("funnelytics/controllers/dashboard/icons/folders/new", ["exports", "funnelytics/custom/debug/logger"], function (_exports, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      create: function create(model) {
        var _this = this;

        var folder = this.store.createRecord('icon_folder', model);
        return folder.save().then(function () {
          _this.transitionToRoute('dashboard.icons');
        }).catch(function (err) {
          _logger.default.error(err);

          folder.deleteRecord();

          _this.set('error', 'There was an error creating your folder');
        });
      }
    }
  });

  _exports.default = _default;
});