define("funnelytics/components/containers/funnel-editor/components/right-sidebar-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onSelectNavigation: function onSelectNavigation() {},
    actions: {
      selectNavigation: function selectNavigation(selected) {
        this.onSelectNavigation(selected);
      }
    }
  });

  _exports.default = _default;
});