define("funnelytics/components/upsells/limited-mapping-offer", ["exports", "funnelytics/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    upsellManager: Ember.inject.service(),
    ajax: Ember.inject.service(),
    userDetails: Ember.inject.service(),
    isModalOpen: Ember.computed('upsellManager.showLimitedUpsell', function () {
      return this.upsellManager.showLimitedUpsell;
    }),
    userId: Ember.computed('userDetails.userId', function () {
      return this.userDetails.get('userId');
    }),
    userName: Ember.computed('userDetails.userName', function () {
      return this.userDetails.get('userName');
    }),
    isOpen: false,
    upgradeLink: Ember.computed('userDetails.userId', 'userDetails.email', function () {
      var id = this.userDetails.get('userId');
      var email = this.userDetails.get('email');
      var link = _environment.default.deployTarget === 'production' ? "https://buy.stripe.com/dR6fZneesdSY09ieWf?client_reference_id=".concat(id, "&prefilled_email=").concat(email) : "https://buy.stripe.com/test_14k5lveNB27jaGYcMS?client_reference_id=".concat(id, "&prefilled_email=").concat(email);
      return link;
    }),
    actions: {
      close: function close() {
        this.get('upsellManager').closeLimitedUpsell();
      }
    }
  });

  _exports.default = _default;
});