define("funnelytics/templates/order/confirmation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b29TQQmZ",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"funnel-pages/confirmation\",null,[[\"invoices\",\"funnelIdToProceed\"],[[23,[\"model\",\"invoices\"]],[23,[\"model\",\"funnelIdToProceed\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/order/confirmation.hbs"
    }
  });

  _exports.default = _default;
});