define("funnelytics/components/for-reuse/upsell-inline", ["exports", "lodash", "funnelytics/custom/constants/Upsell"], function (_exports, _lodash, _Upsell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var FORECASTING_CONTENT = {
    title: 'Forecasting is only available to Funnelytics Pro members!',
    subtitle: 'Join Funnelytics Pro and use Forecasting to...',
    reason1: 'Calculate potential results for your funnel',
    reason2: 'Instantly compare your real results with forecasted results',
    reason3: 'Spot problems before you launch',
    action: 'Want to create funnel simulations? Click below to upgrade!'
  };
  var KPI_CONTENT = {
    title: 'KPIs are only available to Pro members!',
    subtitle: 'Join Funnelytics Pro and use KPIs to...',
    reason1: 'Track users, leads, and sales',
    reason2: 'Compare your expenses, profit, and ROI right in the canvas',
    reason3: 'Easily identify problem areas in your funnel based on your goals',
    action: 'Want to start tracking your funnel? Click below to join Funnelytics Pro...'
  };
  var PEOPLE_CONTENT = {
    title: 'People tracking is only available to Pro members!',
    subtitle: 'Join Funnelytics Pro and use People tracking to...',
    reason1: 'Identify your site visitors and what country they’re from',
    reason2: 'Build a roadmap of all the pages they visited in your funnel, and what source they came from',
    reason3: 'Easily identify actions they took in your funnel over multiple visits',
    action: 'Want to start tracking your funnel? Click below to join Funnelytics Pro...'
  };
  var PREVIOUS_STEPS_CONTENT = {
    title: 'Previous Steps are only available to Pro members!',
    subtitle: 'Join Funnelytics Pro and use Previous Steps to...',
    reason1: 'See how all visitors got to your site',
    reason2: 'Easily identify what UTM parameters are used',
    reason3: 'Map them on your canvas in one click',
    action: 'Want to start tracking your funnel? Click below to join Funnelytics Pro...'
  };
  var DEFAULT_CONTENT = {
    title: 'This feature is only available to Pro members',
    subtitle: 'Join Funnelytics Pro and increase the profitability of your sales funnels, today!',
    reason1: 'Analyze each step of your sales process',
    reason2: 'Optimize your funnels',
    reason3: 'Collaborate with clients'
  };

  var _default = Ember.Component.extend({
    upsellTextStartTrial: _Upsell.UPSELL_TEXTS.start_free_trial,
    upsellUrlStartTrial: _Upsell.UPSELL_URLS.start_free_trial,
    didInsertElement: function didInsertElement() {
      var id = this.get('upsellID');

      switch (id) {
        case 'forecasting_panel':
          this.set('content', FORECASTING_CONTENT);
          return;

        case 'upsell_kpi':
          this.set('content', KPI_CONTENT);
          return;

        case 'upsell_people':
          this.set('content', PEOPLE_CONTENT);
          return;

        case 'upsell_previous_steps':
          this.set('content', PREVIOUS_STEPS_CONTENT);
          return;

        case 'upsell_tracking-section':
          this.set('content', _lodash.default.extend(DEFAULT_CONTENT, {
            title: 'Tracking features are only available to Pro members'
          }));
          return;
      }

      if (this.get('customContent')) {
        this.set('content', {
          title: this.get('title'),
          subtitle: this.get('subtitle'),
          reason1: this.get('reason1'),
          reason2: this.get('reason2'),
          reason3: this.get('reason3')
        });
      } else {
        this.set('content', DEFAULT_CONTENT);
      }
    }
  });

  _exports.default = _default;
});