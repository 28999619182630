define("funnelytics/components/canvas-elements/page-list", ["exports", "ember-uuid", "lodash", "funnelytics/custom/analytics/analytics/AnalyticsHelpers", "funnelytics/custom/constants/CanvasConstants", "funnelytics/custom/constants/ReferrerConstants"], function (_exports, _emberUuid, _lodash, _AnalyticsHelpers, _CanvasConstants, _ReferrerConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function sortByReferrerCountDesc(a, b) {
    if (_lodash.default.get(a, [_ReferrerConstants.default.COUNT]) < _lodash.default.get(b, [_ReferrerConstants.default.COUNT])) {
      return 1;
    }

    if (_lodash.default.get(a, [_ReferrerConstants.default.COUNT]) > _lodash.default.get(b, [_ReferrerConstants.default.COUNT])) {
      return -1;
    }

    return 0;
  }

  var _default = Ember.Component.extend({
    classNames: ['canvas-page-list'],
    analyticsService: Ember.inject.service(),
    canvasService: Ember.inject.service(),
    addToMapType: _ReferrerConstants.default.ADD_TO_MAP_PREVIOUS,
    page: Ember.computed.readOnly('analyticsService.page'),
    focusPage: Ember.computed.readOnly('page.model'),
    focusPageUrl: Ember.computed('focusPage', function () {
      var focusPage = this.get('focusPage');
      var focusPageFormattedURL = null;

      if (focusPage) {
        var focusURL = focusPage.attr('url/text');
        focusPageFormattedURL = _AnalyticsHelpers.default.parseURL(focusURL);
      }

      return focusPageFormattedURL;
    }),
    focusPageReferrer: Ember.computed('focusPageUrl', 'pages', function () {
      var focusPageFormattedURL = this.get('focusPageUrl');
      var focusPageReferrer = null;

      if (focusPageFormattedURL) {
        focusPageReferrer = this.get('pages').find(function (referrerJSON) {
          return focusPageFormattedURL === _lodash.default.get(referrerJSON, [_ReferrerConstants.default.URL]);
        });
      }

      return focusPageReferrer;
    }),
    focusPageReferrerIntCount: Ember.computed('focusPageReferrer', function () {
      var samePageReferrer = this.get('focusPageReferrer');
      var focusPageReferrerIntCount = parseInt(_lodash.default.get(samePageReferrer, [_ReferrerConstants.default.COUNT], 0), 10);

      if (!_lodash.default.isInteger(focusPageReferrerIntCount)) {
        return 0;
      }

      return focusPageReferrerIntCount;
    }),
    processedReferrers: Ember.computed('focusPageUrl', 'focusPageReferrerIntCount', 'pages', 'neighbouringEntities', function () {
      var _this = this;

      var focusPageFormattedURL = this.get('focusPageUrl');
      var samePageCount = this.get('focusPageReferrerIntCount');

      var filteredReferrers = _lodash.default.cloneDeep(this.get('pages'));

      if (focusPageFormattedURL && samePageCount > 0) {
        filteredReferrers = filteredReferrers.filter(function (referrerJSON) {
          // Same URL gets added as DIRECT in the referrer list
          return focusPageFormattedURL !== _lodash.default.get(referrerJSON, [_ReferrerConstants.default.URL]);
        }).map(function (referrerJSON) {
          var integerCount = parseInt(_lodash.default.get(referrerJSON, [_ReferrerConstants.default.COUNT]), 10);

          _lodash.default.set(referrerJSON, [_ReferrerConstants.default.COUNT], integerCount);

          return referrerJSON;
        }).reduce(function (updatedArray, referrerJSONWithInt) {
          var url = _lodash.default.get(referrerJSONWithInt, [_ReferrerConstants.default.URL]);

          if (!url) {
            // Same URL gets added as DIRECT in the referrer list
            var currentDirectCount = _lodash.default.get(referrerJSONWithInt, [_ReferrerConstants.default.COUNT]);

            _lodash.default.set(referrerJSONWithInt, [_ReferrerConstants.default.COUNT], currentDirectCount + samePageCount);
          }

          updatedArray.push(referrerJSONWithInt);
          return updatedArray;
        }, []).sort(sortByReferrerCountDesc);
      }

      filteredReferrers = filteredReferrers.map(function (referrer) {
        if (!_lodash.default.get(referrer, [_ReferrerConstants.default.URL])) {
          return referrer;
        }

        var parameters = _lodash.default.get(referrer, [_ReferrerConstants.default.IS_EXTERNAL]) ? _ReferrerConstants.default.IGNORED_UTM_PARAMETERS : [];

        var matchingEntity = _this.get('canvasService').getEntityManager().findMatchingEntity(_this.get('neighbouringEntities'), {
          url: _lodash.default.get(referrer, [_ReferrerConstants.default.URL]),
          parameters: parameters
        });

        var canvasId = matchingEntity ? matchingEntity.getID() : null;

        _lodash.default.set(referrer, [_ReferrerConstants.default.MATCHING_CANVAS_ID], canvasId);

        return referrer;
      });
      return filteredReferrers;
    }),
    focusedEntity: Ember.computed('analyticsService', 'canvasService', function () {
      var focused = this.get('analyticsService').getAnalyticsInstance().getFocusedStep();

      if (!focused) {
        return null;
      }

      return this.get('canvasService').getEntityManager().find(focused.id);
    }),
    neighbouringEntities: Ember.computed('focusedEntity', 'mappedStepsIdentifier', function () {
      var focusedEntity = this.get('focusedEntity');

      if (focusedEntity === null) {
        return [];
      }

      var neighbouringEntities = [];
      neighbouringEntities = focusedEntity.getInboundLinks({
        isIgnoreInBetweenAllowed: false
      }).map(function (link) {
        return link.getSourceEntity();
      }).filter(function (entity) {
        // Inbound neighbours for now can only be pages or traffic steps;
        return entity.getType() === _CanvasConstants.default.TYPE_SOURCE || entity.getType() === _CanvasConstants.default.TYPE_PAGE;
      });
      return neighbouringEntities;
    }),
    recalculateMapped: function recalculateMapped() {
      this.set('mappedStepsIdentifier', (0, _emberUuid.v4)());
    },
    canvasChangeObserver: Ember.observer('canvasElementStatusId', function () {
      this.recalculateMapped();
    }),
    init: function init() {
      this._super.apply(this, arguments); // Required in order to have observer listen for updates to .stats.report


      this.get('analyticsService');
    },
    onFindElement: function onFindElement() {},
    actions: {
      recalculateMapped: function recalculateMapped() {
        this.recalculateMapped();
      },
      findElement: function findElement(id) {
        var findHandler = this.get('onFindElement');
        findHandler(id);
      }
    }
  });

  _exports.default = _default;
});