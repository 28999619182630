define("funnelytics/components/canvas-elements/entity-toolbar/line", ["exports", "lodash", "funnelytics/custom/constants/ColourHexConstants"], function (_exports, _lodash, _ColourHexConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Actions that need to be passed into the component:
     */
    onClickChangeLineColour: function onClickChangeLineColour() {},
    onClickChangeArrowhead: function onClickChangeArrowhead() {},
    onClickToggleIgnoreInBetweenSteps: function onClickToggleIgnoreInBetweenSteps() {},
    onDelete: function onDelete() {},
    lineColourOptions: Ember.computed.readOnly('colourOptions'),
    isLineColourPickerClosed: Ember.computed.not('isLineColourPickerOpen'),
    isColourPickerClosed: Ember.computed.readOnly('isLineColourPickerClosed'),
    isArrowHeadPickerClosed: Ember.computed.not('isArrowHeadPickerOpen'),
    RESET_ON_NOT_VISIBLE: Ember.observer('isTransparent', function () {
      if (this.get('isTransparent')) {
        this.closeAllDropdowns();
      }
    }),
    currentlySelectedLineColourClass: Ember.computed('selectedLineColour', 'lineColourOptions', function () {
      var selectedColourHex = this.get('selectedLineColour');
      var colourOption = this.get('lineColourOptions').find(function (option) {
        return selectedColourHex === _lodash.default.get(option, _ColourHexConstants.default.COLOUR_HEX_KEY);
      });
      return _lodash.default.get(colourOption, _ColourHexConstants.default.COLOUR_CSS_CLASS, '');
    }),
    closeAllDropdowns: function closeAllDropdowns() {
      this.setProperties({
        isLineColourPickerOpen: false,
        isArrowHeadPickerClosed: true
      });
    },
    initializeProperties: function initializeProperties() {
      this.closeAllDropdowns();
    },
    didInsertElement: function didInsertElement() {
      this.initializeProperties();
    },
    actions: {
      toggleLineColourPicker: function toggleLineColourPicker() {
        var currentState = this.get('isLineColourPickerOpen');
        this.closeAllDropdowns();
        this.set('isLineColourPickerOpen', !currentState);
      },
      setLineColour: function setLineColour(hex) {
        var lineColourHandler = this.get('onClickChangeLineColour');
        lineColourHandler(hex);
      },
      toggleArrowheadDropdown: function toggleArrowheadDropdown() {
        var currentState = this.get('isArrowHeadPickerClosed');
        this.closeAllDropdowns();
        this.set('isArrowHeadPickerClosed', !currentState);
      },
      setArrowhead: function setArrowhead(type) {
        var setArrowheadHandler = this.get('onClickChangeArrowhead');
        setArrowheadHandler(type);
      },
      toggleIgnoreInBetweenSteps: function toggleIgnoreInBetweenSteps(type) {
        var toggleIgnoreInBetweenStepsHandler = this.get('onClickToggleIgnoreInBetweenSteps');
        toggleIgnoreInBetweenStepsHandler(type);
      },
      clickContext: function clickContext() {
        var contextHandler = this.get('onClickContext');
        contextHandler();
      },
      clickDelete: function clickDelete() {
        var deleteHandler = this.get('onDelete');
        deleteHandler();
      }
    }
  });

  _exports.default = _default;
});