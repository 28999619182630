define("funnelytics/components/canvas-elements/focus-dropdown", ["exports", "lodash", "funnelytics/custom/analytics/analytics/AnalyticsHelpers"], function (_exports, _lodash, _AnalyticsHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analyticsService: Ember.inject.service(),
    focusPageName: Ember.computed('page', function () {
      if (this.get('page')) {
        return _lodash.default.get(this.get('page'), ['model', 'attributes', 'attrs', '.label', 'textWrap', 'text']);
      }

      return null;
    }),
    sessionFocusIdentifier: Ember.computed('analyticsService.sessionsList', 'sessionFocus.id', function () {
      var sessionId = this.get('sessionFocus.id');

      if (!sessionId) {
        return null;
      } // TODO: This doesn't work correctly, profileItem.get('attrs') are not accessible for some reason
      // const profile = this.get('analyticsService.sessionsList').find(profileItem => {
      //   return _.get(profileItem, 'id') === sessionId;
      // });


      var profile = null;
      return _AnalyticsHelpers.default.userProfileReader(profile, 'name', sessionId);
    })
  });

  _exports.default = _default;
});