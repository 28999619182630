define("funnelytics/templates/components/left-sidebar/left-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "biZ5ZNyU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"popout page-icons \",[27,\"if\",[[23,[\"areIconsOpen\"]],\"show\",\"\"],null],\" \",[27,\"if\",[[23,[\"dragManager\",\"dragging\"]],\"grabbing\",\"\"],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"inner\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"page-icons-tabs\"],[9],[0,\"\\n      \"],[7,\"button\"],[12,\"class\",[28,[\"page-icon-tab \",[27,\"if\",[[27,\"eq\",[[23,[\"sideBarTabType\"]],\"map-icons\"],null],\"active\",\"\"],null]]]],[3,\"action\",[[22,0,[]],\"focusOnTabType\",\"map-icons\"]],[9],[0,\"\\n        Map\\n      \"],[10],[0,\"\\n      \"],[7,\"button\"],[12,\"class\",[28,[\"page-icon-tab \",[27,\"if\",[[27,\"eq\",[[23,[\"sideBarTabType\"]],\"traffic-explorer\"],null],\"active\",\"\"],null]]]],[3,\"action\",[[22,0,[]],\"focusOnTabType\",\"traffic-explorer\"]],[9],[0,\"\\n        Explore\\n        \"],[7,\"span\"],[11,\"class\",\"label-beta\"],[9],[0,\"BETA\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"isMapIconsTabVisible\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"left-sidebar/icons-mapper\",null,[[\"userIcons\",\"icons\",\"setIconType\",\"sideBarIconType\",\"paper\",\"closeCustomIconModal\"],[[23,[\"userIcons\"]],[23,[\"icons\"]],[23,[\"setIconType\"]],[23,[\"sideBarIconType\"]],[23,[\"paper\"]],[23,[\"closeCustomIconModal\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"isTrafficExplorerTabVisible\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"id\",\"traffic-explorer-content\"],[9],[0,\"\\n        \"],[1,[27,\"left-sidebar/traffic-explorer\",null,[[\"explorerLabel\"],[\"All Steps\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/left-sidebar/left-sidebar.hbs"
    }
  });

  _exports.default = _default;
});