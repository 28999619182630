define("funnelytics/models/recurly-user-subscription-add-on", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    unit_amount_in_cents: _emberData.default.attr('number'),
    quantity: _emberData.default.attr('number'),
    user_subscription: _emberData.default.belongsTo('recurly-user-subscription'),
    add_on: _emberData.default.belongsTo('recurly-add-on')
  });

  _exports.default = _default;
});