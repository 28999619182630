define("funnelytics/templates/components/account/invoice-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NA8EOHOp",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n  \"],[1,[27,\"account/invoice-summary\",null,[[\"invoice\",\"openBill\",\"showLineItem\",\"onClickDownloadPdf\"],[[23,[\"invoice\"]],[27,\"action\",[[22,0,[]],\"openBill\"],null],[23,[\"showLineItem\"]],[27,\"action\",[[22,0,[]],\"downloadPdf\"],null]]]],false],[0,\"\\n  \"],[1,[27,\"account/invoice-detail\",null,[[\"invoice\",\"showLineItem\"],[[23,[\"invoice\"]],[23,[\"showLineItem\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/account/invoice-row.hbs"
    }
  });

  _exports.default = _default;
});