define("funnelytics/templates/order/vault/offer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2MpP72K1",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"offer-component\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"vault-offer\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"sell-header\"],[9],[0,\"\\n      Congratulations, Your Account Has Been Created!\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"wd--50 fl--m--auto\"],[9],[0,\"\\n      Watch this short video (3-min) video to instantly add the top converting funnels into your Funnelytics Account.\\n    \"],[10],[0,\"\\n    \"],[7,\"iframe\"],[11,\"allowfullscreen\",\"\"],[11,\"width\",\"667px\"],[11,\"height\",\"375px\"],[11,\"border\",\"none\"],[11,\"class\",\"video-player center\"],[11,\"src\",\"https://player.vimeo.com/video/262279882?color&autopause=0&loop=0&muted=0&title=1&portrait=1&byline=1#t=\"],[9],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"order-cta\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"order.vault.checkout\"],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"class\",\"activate-pro blue\"],[9],[0,\"\\n          UNLOCK FUNNELYTICS VAULT\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",[\"dashboard\"],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"cta-opt-out fl--mb--2\"],[9],[0,\"\\n          NO THANKS, I DON’T WANT THESE MILLION DOLLAR FUNNELS…\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/order/vault/offer.hbs"
    }
  });

  _exports.default = _default;
});