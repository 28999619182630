define("funnelytics/helpers/step-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stepName = stepName;
  _exports.default = void 0;

  function stepName(params, hash) {
    if (!hash.step) {
      return;
    }

    return hash.step.attr('.label/textWrap').text;
  }

  var _default = Ember.Helper.helper(stepName);

  _exports.default = _default;
});