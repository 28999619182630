define("funnelytics/components/for-reuse/allowance-progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['allowance-progress-bar'],
    classNameBindings: ['isColourInverted:allowance-progress-bar--inverted-colour'],
    label: 'Canvas Steps',
    showTooltip: true,
    currentCount: Ember.computed.readOnly('allowance.current'),
    limitAllowed: Ember.computed.readOnly('allowance.limit'),
    allowanceProgress: Ember.computed('currentCount', 'limitAllowed', function () {
      return this.get('currentCount') / this.get('limitAllowed') * 100;
    }),
    allowanceProgressStyle: Ember.computed('allowanceProgress', function () {
      return Ember.String.htmlSafe("width: ".concat(this.get('allowanceProgress'), "%"));
    }),
    hasHitLimit: Ember.computed('currentCount', 'limitAllowed', function () {
      return this.get('currentCount') >= this.get('limitAllowed');
    })
  });

  _exports.default = _default;
});