define("funnelytics/templates/dashboard/projects/project/funnels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fsAlI2bm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"style\",[28,[\"margin: 0 3rem; width: 100%; height: \",[21,\"containerHeight\"],\"; overflow: scroll;\"]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"arePermissionsLoaded\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"funnel-list\",null,[[\"accessToNewVersion\",\"funnels\",\"projects\",\"project\",\"canEditFunnels\",\"isFunnelAdmin\",\"currentProjectId\",\"onDelete\",\"onCompleteSave\",\"projectTags\",\"externalFunnelTarget\",\"updateParameter\"],[[23,[\"model\",\"accessToNewVersion\"]],[23,[\"funnels\"]],[23,[\"model\",\"projects\"]],[23,[\"model\",\"project\"]],[23,[\"canEditFunnels\"]],[23,[\"isFunnelAdmin\"]],[23,[\"model\",\"project\",\"id\"]],[27,\"action\",[[22,0,[]],\"refreshRouteFromController\"],null],[27,\"action\",[[22,0,[]],\"transitionToRouteFromController\"],null],[23,[\"model\",\"projectTags\"]],[23,[\"paramFunnelTarget\"]],[27,\"action\",[[22,0,[]],\"changeParam\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"loading-dots\",null,[[\"large\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/dashboard/projects/project/funnels.hbs"
    }
  });

  _exports.default = _default;
});