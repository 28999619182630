define("funnelytics/models/tracker-step", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    page: _emberData.default.attr('string'),
    referrer: _emberData.default.attr('string'),
    params: _emberData.default.hasMany('tracker-step-param'),
    session: _emberData.default.belongsTo('tracker-session'),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date')
  });

  _exports.default = _default;
});