define("funnelytics/components/for-reuse/multi-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'fl-multi-select',
    itemComponent: 'for-reuse/multi-select-item',
    filterStrategy: function filterStrategy(items, filter) {
      var lowercaseFilter = filter.toLowerCase();
      return items.filter(function (item) {
        return item.value.toLowerCase().includes(lowercaseFilter);
      });
    },
    filteredData: Ember.computed('data', 'filterByLabel', 'filterStrategy', function () {
      if (!this.get('filterByLabel')) {
        return this.get('data');
      }

      return this.get('filterStrategy')(this.get('data'), this.get('filterByLabel'));
    }),
    hasSelectedData: Ember.computed.notEmpty('selectedData'),
    onToggleValue: function onToggleValue() {},
    onDeselectValue: function onDeselectValue() {},
    actions: {
      toggleItem: function toggleItem(item) {
        var toggleItemHandler = this.get('onToggleValue');
        toggleItemHandler(item);
      }
    }
  });

  _exports.default = _default;
});