define("funnelytics/components/canvas-elements/explorer/action-list-item", ["exports", "funnelytics/custom/constants/AnalyticsConstants"], function (_exports, _AnalyticsConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    name: Ember.computed.readOnly("explorerAction.".concat(_AnalyticsConstants.default.TRAFFIC_EXPLORER_OUTPUT.ACTIONS.NAME)),
    hits: Ember.computed.readOnly("explorerAction.".concat(_AnalyticsConstants.default.TRAFFIC_EXPLORER_OUTPUT.ACTIONS.HITS)),
    // Provide to convert action names
    displayNameConverter: function displayNameConverter(name) {
      return name;
    },
    isRecognizedAction: Ember.computed('name', 'displayName', function () {
      return this.get('name') !== this.get('displayName');
    }),
    displayName: Ember.computed('name', 'displayNameConverter', function () {
      var name = this.get('name');
      return this.get('displayNameConverter')(name);
    }),
    onClick: function onClick() {},
    actions: {
      clickActionItem: function clickActionItem() {
        var clickHandler = this.get('onClick');
        clickHandler(this.get('explorerAction'));
      }
    }
  });

  _exports.default = _default;
});