define("funnelytics/templates/components/dashboard-elements/subscriptions/view/woo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LwFc4BNJ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"fl-flex-row card-body-text fl--mt--1\"],[9],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[1,[21,\"name\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"hasNextPayment\"]]],null,{\"statements\":[[0,\"      Valid through \"],[1,[27,\"date-parser\",[[23,[\"nextPayment\"]],\"hide null\",\"YYYY-MM-DD\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/dashboard-elements/subscriptions/view/woo.hbs"
    }
  });

  _exports.default = _default;
});