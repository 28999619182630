define("funnelytics/components/account/segmentation-survey/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onSelect: function onSelect() {},
    selectedOptionCode: Ember.computed('selectedOption', function () {
      return this.get('selectedOption.code');
    }),
    selectedOptionTitle: Ember.computed('selectedOption', function () {
      return this.get('selectedOption.title');
    }),
    actions: {
      selectOption: function selectOption(option) {
        this.get('onSelect')(option);
      }
    }
  });

  _exports.default = _default;
});