define("funnelytics/custom/constants/ReferrerConstants", ["exports", "funnelytics/custom/analytics/analytics/canvas/EntityParameter", "funnelytics/custom/constants/UtmConstants"], function (_exports, _EntityParameter, _UtmConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ADD_TO_MAP_PREVIOUS = 'ADD_TO_MAP_PREVIOUS';
  var ADD_TO_MAP_NEXT = 'ADD_TO_MAP_NEXT'; // TODO: Need to change this file to reflect the broader application of these constants (previous and next steps)

  var ReferrerConstants = Object.freeze({
    // TODO: Need to examine these and coordinate:
    URL: 'referrer',
    COUNT: 'count',
    IS_EXTERNAL: 'is_referrer',
    // matches analytics api
    MATCHING_CANVAS_ID: 'canvasId',
    IGNORED_UTM_PARAMETERS: [new _EntityParameter.default({
      key: _UtmConstants.default.UTM_SOURCE,
      value: null,
      contains: false
    })],
    ADD_TO_MAP_PREVIOUS: ADD_TO_MAP_PREVIOUS,
    ADD_TO_MAP_NEXT: ADD_TO_MAP_NEXT
  });
  var _default = ReferrerConstants;
  _exports.default = _default;
});