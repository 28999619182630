define("funnelytics/helpers/username-or-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.usernameOrId = usernameOrId;
  _exports.default = void 0;

  function usernameOrId(params, namedArgs) {
    var profileAttributes = params[0];
    var userName = profileAttributes.reduce(function (name, profileAttribute) {
      if (name) {
        return name;
      }

      if (profileAttribute.get('key') === 'name') {
        name = profileAttribute.get('value');
      }

      return name;
    }, false);

    if (userName) {
      return userName;
    }

    var shortenUserID = "User ".concat(namedArgs.userID.split('-')[0]);
    return shortenUserID;
  }

  var _default = Ember.Helper.helper(usernameOrId);

  _exports.default = _default;
});