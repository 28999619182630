define("funnelytics/components/users/user-list", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var INITIAL_PAGE = 0;
  var EMPTY_EMAIL_QUERY = '';

  var _default = Ember.Component.extend({
    page: INITIAL_PAGE,
    email: EMPTY_EMAIL_QUERY,
    loading: false,
    store: Ember.inject.service(),
    userList: Ember.A(),
    nextPageExists: false,
    determineIfNextPageExists: function determineIfNextPageExists(response) {
      this.set('nextPageExists', _lodash.default.get(response, ['meta', 'pagination', 'next'], null) !== null);
    },
    loadNextPageOfUsers: function loadNextPageOfUsers() {
      var _this = this;

      if (this.get('loading')) {
        return;
      }

      this.set('loading', true);
      this.incrementProperty('page');
      var queryOptions = {
        page: this.get('page')
      };

      if (this.get('email') !== EMPTY_EMAIL_QUERY) {
        queryOptions.query = this.get('email');
      }

      return this.get('store').query('user', queryOptions).then(function (pageOfUsers) {
        _this.get('userList').pushObjects(pageOfUsers.toArray());

        _this.set('loading', false);

        _this.determineIfNextPageExists(pageOfUsers);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.loadNextPageOfUsers();
    },
    actions: {
      loadNextPage: function loadNextPage() {
        this.loadNextPageOfUsers();
      },
      search: function search() {
        this.set('page', INITIAL_PAGE);
        this.set('userList', Ember.A());
        this.loadNextPageOfUsers();
      }
    }
  });

  _exports.default = _default;
});