define("funnelytics/custom/analytics/analytics-request/SessionsAnalyticsRequest", ["exports", "lodash", "funnelytics/custom/constants/AnalyticsConstants", "funnelytics/custom/analytics/AnalyticsRequest", "funnelytics/custom/analytics/analytics-data/SessionsAnalyticsData"], function (_exports, _lodash, _AnalyticsConstants, _AnalyticsRequest, _SessionsAnalyticsData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var LAST_SESSION_ID = 'last_session_id';

  var SessionsAnalyticsRequest = _AnalyticsRequest.default.extend({
    store: null,
    _mainAnalyticsData: _SessionsAnalyticsData.default.create(),
    _createAnalyticsData: function _createAnalyticsData(initialValues) {
      return _SessionsAnalyticsData.default.create(initialValues);
    },
    _sendRequest: function _sendRequest(analyticsSettings) {
      var _this = this;

      return Ember.RSVP.Promise.resolve(true).then(function () {
        return _this._getAjax().request('/sessions/', {
          method: 'POST',
          host: _this._getAnalyticsApiHostname(),
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader(_AnalyticsConstants.default.PROJECT_ID_HEADER, analyticsSettings.getProject());
          },
          data: {
            last: _this.get('lastSessionId'),
            filter: {
              canvas: analyticsSettings.getCanvasSteps(),
              range: analyticsSettings.getRange(),
              device: analyticsSettings.getDevice(),
              focus: analyticsSettings.getFocus(),
              profiles: {
                countries: {
                  allow: analyticsSettings.getCountriesFilter()
                }
              }
            }
          }
        }).then(function (sessionsResults) {
          var _data;

          var sessionsListRaw = _lodash.default.get(sessionsResults, _AnalyticsConstants.default.SESSIONS_OUTPUT.PATHS.SESSIONS_LIST, []);

          var lastSession = _lodash.default.last(sessionsListRaw);

          var lastSessionId = null;

          if (lastSession) {
            lastSessionId = _lodash.default.get(lastSession, ['attributes', 'id']);
          }

          _this.get('store').pushPayload('tracker-session', sessionsResults);

          var formattedSessionsList = _lodash.default.map(sessionsListRaw, function (record) {
            return _this.get('store').peekRecord('tracker-session', record.id);
          });

          return {
            data: (_data = {}, _defineProperty(_data, _AnalyticsConstants.default.SESSIONS_OUTPUT.KEYS.SESSIONS_COUNT, _lodash.default.get(sessionsResults, _AnalyticsConstants.default.SESSIONS_OUTPUT.PATHS.SESSIONS_COUNT, 0)), _defineProperty(_data, _AnalyticsConstants.default.SESSIONS_OUTPUT.KEYS.SESSIONS_LIST, formattedSessionsList), _data),
            meta: _defineProperty({}, LAST_SESSION_ID, lastSessionId)
          };
        });
      });
    },
    _handleValidRequestBeforePopulate: function _handleValidRequestBeforePopulate(_ref) {
      var meta = _ref.meta;
      this.set('lastSessionId', _lodash.default.get(meta, [LAST_SESSION_ID]));
    },
    _handleIsNotPartial: function _handleIsNotPartial() {
      this.set('lastSessionId', null);
    }
  });

  var _default = SessionsAnalyticsRequest;
  _exports.default = _default;
});