define("funnelytics/components/canvas-settings", ["exports", "funnelytics/custom/constants", "funnelytics/custom/debug/logger", "funnelytics/custom/storage/StorageHelpers"], function (_exports, _constants, _logger, _StorageHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    canvasService: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    nameInputEmpty: Ember.computed.empty('currentFunnelName'),
    nameNotBeingUpdated: Ember.computed('currentFunnelName', 'funnel.name', function () {
      return this.get('currentFunnelName') === this.get('funnel.name');
    }),
    projectNotBeingUpdated: Ember.computed('currentProject.id', 'selectedProject', function () {
      return this.get('currentProject.id') === this.get('selectedProject');
    }),
    funnelNotBeingUpdated: Ember.computed.and('nameNotBeingUpdated', 'projectNotBeingUpdated'),
    cannotSave: Ember.computed.or('saving', 'nameInputEmpty', 'funnelNotBeingUpdated'),
    funnelHasProject: Ember.computed.notEmpty('funnel.project.content'),
    funnelHasNoProject: Ember.computed.not('funnel.project.content'),
    agreed: localStorage.getItem('agreed_to_tracking_terms'),
    NULL_PROJECT: _constants.NULL_PROJECT,
    gridIsOn: false,
    allowEdit: false,
    animationIsOn: Ember.computed('paper', function () {
      return this.get('paper.model.model.attributes.animationIsOn') || false;
    }),
    autoLayout: Ember.computed('paper', function () {
      return this.get('paper.model.model.attributes.autoLayout') || false;
    }),
    currentDropdownOpen: null,
    didInsertElement: function didInsertElement() {
      this.set('linkStyle', _StorageHelpers.default.getLinkStyle());
      this.set('animationSettings', _StorageHelpers.default.getAnimationSettings());
      this.set('snapLinesAreOn', _StorageHelpers.default.getSnapLineState());

      if (this.get('paper.gridSize') > 1) {
        this.set('gridIsOn', true);
      }

      this.setProperties({
        linkStyle: _StorageHelpers.default.getLinkStyle(),
        //linkColour: StorageHelpers.getLinkColour(),
        currentFunnelName: this.get('funnel.name'),
        currentProject: this.get('funnel.project'),
        selectedProject: this.get('funnel.project.id'),
        gridValue: this.get('paper.gridSize'),
        saving: false
      });
    },
    onChangeWorkspace: function onChangeWorkspace() {
      var _this = this;

      return Ember.RSVP.Promise.resolve(true).then(function () {
        return _this.get('funnel');
      });
    },
    actions: {
      toggleGrid: function toggleGrid() {
        this.get('paper').toggleGrid();
        this.toggleProperty('gridIsOn');
        this.set('gridValue', this.get('paper.gridSize'));
      },
      toggleSnapLines: function toggleSnapLines() {
        this.get('paper').toggleSnapLines();
        this.toggleProperty('snapLinesAreOn');
      },
      gridSlider: function gridSlider(event) {
        this.get('paper').setGridSize(event.target.value);

        if (event.target.value > 0) {
          this.set('gridIsOn', true);
        }

        this.set('gridValue', event.target.value);
      },
      selectProject: function selectProject(projectId) {
        if (projectId === _constants.NULL_PROJECT) {
          this.set('selectedProject', null);
          return;
        }

        this.set('selectedProject', projectId);
      },
      saveNewFunnelDetails: function saveNewFunnelDetails() {
        var _this2 = this;

        if (this.get('cannotSave')) {
          return;
        }

        this.set('saving', true);
        this.set('error', null);
        var selectedProject = this.get('selectedProject');
        var funnel = this.get('funnel');
        funnel.set('name', this.get('currentFunnelName'));
        var workspaceUpdatePromise = this.get('onChangeWorkspace');
        return funnel.save().then(function () {
          return workspaceUpdatePromise(selectedProject);
        }).then(function (finalFunnel) {
          if (_this2.get('isDestroyed')) {
            return;
          }

          _this2.set('currentProject', finalFunnel.get('project'));
        }).catch(function (err) {
          _logger.default.error(err);

          _this2.set('error', 'Oh no! Funnel edits failed to save.');
        }).finally(function () {
          if (_this2.get('isDestroyed')) {
            return;
          }

          _this2.set('saving', false);
        });
      },
      agree: function agree() {
        localStorage.setItem('agreed_to_tracking_terms', true);
        this.set('agreed', true);
      },
      changeLinkStyle: function changeLinkStyle(style, type) {
        if (type === 'router') {
          _StorageHelpers.default.setLinkStyle(style, 'router');

          this.set('linkStyle.router', style);
        } else {
          _StorageHelpers.default.setLinkStyle(style, 'corner');

          this.set('linkStyle.corner', style);
        }

        this.get('paper.model.model.attributes.cells.models').forEach(function (element) {
          if (element.attributes.type === 'funnel_misc.Connection') {
            if (type === 'router') {
              element.router(style);
            } else if (type === 'corner') {
              element.connector(style);
            }
          }
        });
      },

      /*toggleAutoLayout() {
        console.log('THIS FEATURE DOES NOT USE STORAGE HELPER TO STORE LAYOUT PREFERENCES. CHANGE BEFORE WORKING ON IT.');
        this.toggleProperty('autoLayout');
        if(this.get('autoLayout')) {
          this.set('paper.model.model.attributes.autoLayout', true);
        } else {
          this.set('paper.model.model.attributes.autoLayout', false);
        }
      },*/
      openNotesPanel: function openNotesPanel() {
        this.get('canvasService').openFunnelNotes();
      },
      changeLinkColour: function changeLinkColour(colour) {
        var _this3 = this;

        this.set('linkColour', colour);

        _StorageHelpers.default.setLinkColour(colour);

        this.get('paper.model.model.attributes.cells.models').forEach(function (element) {
          var linkEntity = _this3.get('canvasService').wrapEntity(element);

          linkEntity.setLineColour(colour);
        });
      }
    }
  });

  _exports.default = _default;
});