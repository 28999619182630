define("funnelytics/components/canvas-elements/kpi-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['kpi-item'],
    classNameBindings: ['highlight:kpi-item--highlight'],
    isCurrency: false,
    isPercentage: false,
    hasContent: Ember.computed('isEmptyHidden', 'value', function () {
      if (!this.get('isEmptyHidden')) {
        return true;
      }

      return Boolean(this.get('value'));
    })
  });

  _exports.default = _default;
});