define("funnelytics/templates/components/billing/stripe-billing-portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r3v8W8G8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"d-flex\"],[11,\"style\",\"line-height: 26px;\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"mb-1\"],[11,\"style\",\"width: 100%; height: 26px;\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"loading\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"shimmerBG content-line\"],[11,\"style\",\"max-width: 200px;\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[14,1,[[23,[\"billingPortalLink\"]],[23,[\"error\"]]]],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/billing/stripe-billing-portal.hbs"
    }
  });

  _exports.default = _default;
});