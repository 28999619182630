define("funnelytics/components/chat-bubble", ["exports", "funnelytics/custom/storage/StorageHelpers", "funnelytics/custom/constants/AcknowledgementConstants"], function (_exports, _StorageHelpers, _AcknowledgementConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MENU_ITEMS = [{
    title: 'Mapping',
    name: _AcknowledgementConstants.default.MAPPING,
    acknowledged: false,
    stepIndex: 1,
    video: 'https://player.vimeo.com/video/396809825?title=0&byline=0&portrait=0'
  }, {
    title: 'Actions',
    name: _AcknowledgementConstants.default.ACTIONS,
    acknowledged: false,
    stepIndex: 2,
    video: 'https://player.vimeo.com/video/396809833?title=0&byline=0&portrait=0'
  }, {
    title: 'Tracking',
    name: _AcknowledgementConstants.default.TRACKING,
    acknowledged: false,
    stepIndex: 3,
    video: 'https://player.vimeo.com/video/396809838?title=0&byline=0&portrait=0'
  }, {
    title: 'Numbers',
    name: _AcknowledgementConstants.default.NUMBERS,
    acknowledged: false,
    stepIndex: 4,
    video: 'https://player.vimeo.com/video/396809863?title=0&byline=0&portrait=0'
  }, {
    title: 'Flow',
    name: _AcknowledgementConstants.default.FLOW,
    acknowledged: false,
    stepIndex: 5,
    video: 'https://player.vimeo.com/video/379918531?title=0&byline=0&portrait=0'
  }, {
    title: 'KPI',
    name: _AcknowledgementConstants.default.KPI,
    acknowledged: false,
    stepIndex: 6,
    video: 'https://player.vimeo.com/video/396809875?title=0&byline=0&portrait=0'
  }, {
    title: 'Forecast',
    name: _AcknowledgementConstants.default.FORECAST,
    acknowledged: false,
    stepIndex: 7,
    video: 'https://player.vimeo.com/video/379918716?title=0&byline=0&portrait=0'
  }, {
    title: 'Focus',
    name: _AcknowledgementConstants.default.FOCUS,
    acknowledged: false,
    stepIndex: 8,
    video: 'https://player.vimeo.com/video/396809883?title=0&byline=0&portrait=0'
  }, {
    title: 'People',
    name: _AcknowledgementConstants.default.PEOPLE,
    acknowledged: false,
    stepIndex: 9,
    video: 'https://player.vimeo.com/video/379919004?title=0&byline=0&portrait=0'
  }];

  var _default = Ember.Component.extend({
    hubspotChat: Ember.inject.service(),
    userDetails: Ember.inject.service(),
    learningModalTrigger: Ember.inject.service(),
    isChatBubbleOpen: false,
    completionLevel: Ember.computed('updateAcknowledgements', function () {
      var _this = this;

      var level = 0;

      if (!this.get('acknowledgements')) {
        return false;
      }

      Object.keys(this.get('acknowledgements')).forEach(function (item) {
        if (_this.get("acknowledgements.".concat(item))) {
          level += 1;
        }
      });
      var number = Math.floor(level / MENU_ITEMS.length * 100);

      if (!number) {
        return 0;
      }

      return number;
    }),
    numberOfCompletedFeatures: Ember.computed('learningModalTrigger.notifyAcknowledgement', function () {
      var _this2 = this;

      var level = 0;

      if (!this.get('acknowledgements')) {
        this.set('acknowledgements', this.get('learningModalTrigger').getAllAcknowledgements());
      }

      Object.keys(this.get('acknowledgements')).forEach(function (item) {
        if (_this2.get("acknowledgements.".concat(item))) {
          level += 1;
        }
      });

      if (!level) {
        return 0;
      }

      return level;
    }),
    completionLevelStyle: Ember.computed('completionLevel', function () {
      return Ember.String.htmlSafe("width: ".concat(this.get('completionLevel'), "%;"));
    }),
    unreadCount: Ember.computed('hubspotChat.unreadCount', function () {
      return this.get('hubspotChat.unreadCount') > 0 ? "(".concat(this.get('hubspotChat.unreadCount'), ")") : '';
    }),
    areAllAckowledged: Ember.computed('updateAcknowledgements', function () {
      var _this3 = this;

      var allAcknowledged = true;

      if (!this.get('acknowledgements')) {
        return false;
      }

      Object.keys(this.get('acknowledgements')).forEach(function (item) {
        if (!_this3.get("acknowledgements.".concat(item))) {
          allAcknowledged = false;
        }
      });
      return allAcknowledged;
    }),
    setAcknowledgements: function setAcknowledgements() {
      var _this4 = this;

      this.set('acknowledgements', this.get('learningModalTrigger').getAllAcknowledgements());
      this.set('featureLinks', MENU_ITEMS);
      this.set('updateAcknowledgements', Math.random());
      this.get('featureLinks').forEach(function (feature) {
        Ember.set(feature, 'acknowledged', _this4.get("acknowledgements.".concat(feature.name)));
      });
    },
    didInsertElement: function didInsertElement() {
      var _this5 = this;

      this.set('acknowledgements', this.get('learningModalTrigger').getAllAcknowledgements());
      this.set('featureLinks', MENU_ITEMS);
      this.get('featureLinks').forEach(function (feature) {
        Ember.set(feature, 'acknowledged', _this5.get("acknowledgements.".concat(feature.name)));
      }); // this.setAcknowledgements();

      this.get('numberOfCompletedFeatures');
    },
    actions: {
      chatBubbleClick: function chatBubbleClick() {
        if (!this.get('isChatBubbleOpen')) {
          this.setAcknowledgements();
        }

        this.toggleProperty('isChatBubbleOpen');
      },
      completeStep: function completeStep(step) {
        step = Number(step);

        if (step <= this.get('currentCompletionLevel')) {
          return;
        }

        _StorageHelpers.default.setOnboardingCompletionLevel(step);

        this.set('currentCompletionLevel', step);
      },
      chatWithSupport: function chatWithSupport() {
        this.get('hubspotChat').refresh();
        this.get('hubspotChat').open();
        this.set('isChatBubbleOpen', false);
      },
      chatBubbleLearningModal: function chatBubbleLearningModal(type) {
        this.send('chatBubbleClick');

        if (type) {
          this.get('learningModalTrigger').showLearningModal({
            type: type,
            isOpeningFromChatBubble: true
          });
        } else {
          this.get('learningModalTrigger').showLearningModal({
            type: _AcknowledgementConstants.default.MAPPING,
            isOpeningFromChatBubble: true
          });
        }
      }
    }
  });

  _exports.default = _default;
});