define("funnelytics/custom/constants/KeyboardConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var KeyboardConstants = Object.freeze({
    CTRL_KEY: 91,
    WINDOWS_CTRL_KEY: 17,
    SHIFT: 16,
    B_KEY: 66,
    C_KEY: 67,
    I_KEY: 73,
    O_KEY: 79,
    P_KEY: 80,
    S_KEY: 83,
    U_KEY: 85,
    V_KEY: 86,
    Y_KEY: 89,
    X_KEY: 88,
    Z_KEY: 90,
    A_KEY: 65,
    J_KEY: 74,
    D_KEY: 68,
    PLUS_KEY: 187,
    MINUS_KEY: 189,
    BACKSPACE: 8,
    DELETE: 46
  });
  var _default = KeyboardConstants;
  _exports.default = _default;
});