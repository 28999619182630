define("funnelytics/controllers/dashboard/settings/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    projectPermissions: Ember.inject.service(),
    hasStripeSubscription: Ember.computed('projectPermissions.hasStripeSubscription', function () {
      return this.projectPermissions.hasStripeSubscription;
    }),
    updatedFields: [],
    accountDetailsConfirmation: '',
    // Set to true when the actual email has been update, not just email change request sent
    emailUpdated: false,
    modalOpen: false,
    actions: {
      setEmailUpdated: function setEmailUpdated() {
        this.set('emailUpdated', true);
      },
      submitAccountDetails: function submitAccountDetails(updatedUserInformation) {
        var _this = this;

        this.set('updatedFields', []);
        this.set('emailUpdated', false);
        var fieldToUpdate = ['first_name', 'last_name', 'email'];
        var model = this.get('model');
        fieldToUpdate.forEach(function (field) {
          if (model.get(field) !== updatedUserInformation[field]) {
            model.set(field, updatedUserInformation[field]);

            _this.get('updatedFields').push(field);
          }
        });
        return model.save().then(function () {
          return _this.get('updatedFields');
        }).catch(function (err) {
          model.rollbackAttributes();
          throw err;
        });
      },
      submitPasswordUpdate: function submitPasswordUpdate(_ref) {
        var current = _ref.current,
            updated = _ref.updated;
        return this.get('ajax').request('/users/update-password', {
          method: 'POST',
          data: {
            current: current,
            updated: updated
          }
        });
      },
      submitPasswordInitialSet: function submitPasswordInitialSet(_ref2) {
        var updated = _ref2.updated;
        var model = this.get('model');
        return this.get('ajax').request('/users/set-password', {
          method: 'POST',
          data: {
            password: updated
          }
        }).then(function () {
          model.set('is_password_set', true);
        });
      },
      setShowModal: function setShowModal(show) {
        this.set('modalOpen', show);
      }
    }
  });

  _exports.default = _default;
});