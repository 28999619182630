define("funnelytics/routes/order/vault/index", ["exports", "lodash", "funnelytics/config/environment"], function (_exports, _lodash, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    primaryProduct: {
      type: 'product',
      userFacingName: 'Vault',
      sku: 'vault',
      cost: null,
      periodOfTime: null,
      upsellRoute: 'order.confirmation'
    },
    bumpProducts: [{
      type: 'product',
      userFacingName: 'Quick Win Campaigns',
      sku: 'quick_wins',
      cost: null,
      periodOfTime: null,
      // eslint-disable-next-line max-len
      copy: "Marketing Service Providers! Add these 9 plug-and-play campaigns to supercharge your results with the Vault. With the campaigns, you'll be able to quickly win high-ticket clients who want Funnel Services. Only available right here."
    }],
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      controller.set('primaryProduct', this.get('primaryProduct'));
      controller.set('bumpProducts', this.get('bumpProducts'));
      controller.set('vaultInfo', true);
      controller.set('user', this.controllerFor('order').get('user'));
      this.get('ajax').request('/purchases/information', {
        method: 'GET',
        data: {
          plans: this.get('primaryProduct.planCode'),
          products: _lodash.default.map([].concat(_toConsumableArray(this.get('bumpProducts').toArray()), [this.get('primaryProduct')]), function (product) {
            return product.sku;
          }).join(',')
        }
      }).then(function (result) {
        _this.set('primaryProduct.cost', _lodash.default.get(result, ['products', _this.get('primaryProduct.sku'), 'priceInCents']) / 100);

        _lodash.default.each(_this.get('bumpProducts'), function (product, index) {
          _this.set("bumpProducts.".concat(index, ".cost"), _lodash.default.get(result, ['products', product.sku, 'priceInCents']) / 100);
        });

        controller.set('totalCost', _this.get('primaryProduct.cost'));
      }).then(function () {
        _this.controllerFor('order').get('user').then(function (user) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'checkout',
            ecommerce: {
              checkout: {
                actionField: {
                  step: 1
                },
                products: [{
                  name: _this.get('primaryProduct.userFacingName'),
                  id: _this.get('primaryProduct.sku'),
                  price: _this.get('primaryProduct.cost'),
                  category: 'funnelytics'
                }]
              }
            },
            user: {
              id: user.id,
              email: user.email
            }
          });
        });
      });
    },
    actions: {
      didTransition: function didTransition() {
        Ember.run.scheduleOnce('afterRender', this, function () {
          // eslint-disable-next-line no-undef
          recurly.configure({
            publicKey: _environment.default.recurly.publishableKey,
            fields: {
              number: {
                selector: '#recurly-number',
                inputType: 'text',
                format: true,
                style: {
                  placeholder: {
                    content: 'Card Number'
                  }
                }
              },
              month: {
                inputType: 'text',
                style: {
                  placeholder: {
                    content: 'MM'
                  }
                }
              },
              year: {
                inputType: 'text',
                style: {
                  placeholder: {
                    content: 'YYYY'
                  }
                }
              },
              cvv: {
                style: {
                  placeholder: {
                    content: 'CVC'
                  }
                }
              }
            }
          });
        });
        return true;
      }
    }
  });

  _exports.default = _default;
});