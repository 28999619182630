define("funnelytics/templates/components/containers/funnel-editor/components/right-sidebar-analytics-country", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4z637eFt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"analytics-session-group__item\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"analytics-session-group__item-country\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"analytics-session-group__item-country-position\"],[9],[1,[21,\"countryIndexDisplay\"],false],[10],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"flag flag-\",[21,\"countryCode\"]]]],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"analytics-session-group__item-country-name\"],[9],[1,[21,\"countryName\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"analytics-session-group__item-visitors\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"analytics-session-group__item-visitors-count\"],[9],[0,\"(\"],[1,[21,\"visitorsCount\"],false],[0,\")\"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"analytics-session-group__item-visitors-percent\"],[9],[1,[21,\"visitorsPercent\"],false],[0,\"%\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/containers/funnel-editor/components/right-sidebar-analytics-country.hbs"
    }
  });

  _exports.default = _default;
});