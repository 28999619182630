define("funnelytics/templates/components/canvas-elements/analytics-toolbar/country-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MZlhuQkO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"fl-flex-center fl-btn--no-style wd--100\"],[3,\"action\",[[22,0,[]],\"handleClick\",[23,[\"item\"]]]],[9],[0,\"\\n  \"],[7,\"input\"],[11,\"class\",\"fl-multi-select__checkbox\"],[12,\"checked\",[23,[\"item\",\"isSelected\"]]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n  \"],[1,[27,\"containers/funnel-editor/components/right-sidebar-analytics-country\",null,[[\"class\",\"countryData\",\"countryIndex\",\"totalVisitorsCount\"],[\"wd--100\",[23,[\"item\",\"countryData\"]],[23,[\"item\",\"countryIndex\"]],[23,[\"item\",\"totalVisitorsCount\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/analytics-toolbar/country-item.hbs"
    }
  });

  _exports.default = _default;
});