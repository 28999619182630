define("funnelytics/helpers/date-range", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateRange = dateRange;
  _exports.default = void 0;

  function dateRange(params) {
    if (!params[0] || !params[1]) {
      return null;
    }

    var format = params[2] || 'MMM D, YYYY';
    var fromDate = (0, _moment.default)(params[0]).format(format);
    var toDate = (0, _moment.default)(params[1]).format(format);
    return "".concat(fromDate, " - ").concat(toDate);
  }

  var _default = Ember.Helper.helper(dateRange);

  _exports.default = _default;
});