define("funnelytics/custom/canvas/ContextMenu/Actions", ["exports", "lodash", "funnelytics/custom/constants/CanvasConstants", "funnelytics/custom/constants/ContextMenuActionType", "funnelytics/custom/constants/ContextMenuActionLabels", "funnelytics/custom/canvas/ContextMenu/ContextMenuConstants"], function (_exports, _lodash, _CanvasConstants, _ContextMenuActionType, _ContextMenuActionLabels, _ContextMenuConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var IS_MAC = navigator.platform.indexOf('Mac') !== -1;
  var META_KEY_NAME = IS_MAC ? 'CMD' : 'CTRL';
  var projectId = localStorage.getItem('projectId');
  var _default = {
    CREATE_STEP: {
      title: _ContextMenuActionLabels.default.ADD_STEP,
      visible: true,
      action: function action(menu) {
        menu.setStyle(_ContextMenuConstants.default.STYLE_GRID);
      }
    },
    GENERATE_THUMBNAIL: {
      title: _ContextMenuActionLabels.default.GENERATE_THUMBNAIL,
      visible: false,
      action: function action(menu) {
        return menu.getEntity().generateCustomThumbnail(projectId);
      }
    },
    COPY: {
      title: _ContextMenuActionLabels.default.COPY,
      shortcut: [META_KEY_NAME, 'C'].join(' + '),
      visible: true,
      action: function action(menu) {
        var stage = [];

        if (menu.getEntity().getCell()) {
          stage = [menu.getEntity().getCell()];
        } else if (menu.getEntities().length > 0) {
          stage = _lodash.default.map(menu.getEntities(), function (entity) {
            return entity.getCell();
          });
        }

        menu.getPaper().commands.copy(stage);
        menu.setVisible(false);
      }
    },
    PASTE: {
      title: _ContextMenuActionLabels.default.PASTE,
      visible: true,
      shortcut: [META_KEY_NAME, 'V'].join(' + '),
      action: function action(menu) {
        var position = menu.getPaper().getMousePosition();
        menu.getPaper().commands.paste(position);
        menu.setVisible(false);
      }
    },
    DUPLICATE: {
      title: _ContextMenuActionLabels.default.DUPLICATE,
      shortcut: [META_KEY_NAME, 'D'].join(' + '),
      visible: true,
      action: function action(menu) {
        var entity = menu.getEntity();
        var isOneEntity = !entity.isEmpty();
        var entities = menu.getEntities();
        var entitiesToDuplicate = isOneEntity ? [entity] : entities;
        menu.getPaper().commands.duplicate(entitiesToDuplicate);
        menu.setVisible(false);
      }
    },
    DELETE: {
      title: _ContextMenuActionLabels.default.DELETE,
      visible: true,
      shortcut: ['DEL'].join(' + '),
      action: function action(menu) {
        var entity = menu.getEntity();
        var isOneEntity = !entity.isEmpty();
        var entities = isOneEntity ? [entity] : menu.getEntities();
        menu.getPaper().commands.delete(entities);
        menu.setVisible(false);
      }
    },
    EDIT_LABEL: {
      title: _ContextMenuActionLabels.default.EDIT_LABEL,
      visible: true,
      type: _ContextMenuActionType.default.INPUT,
      value: function value(menu) {
        return menu.getEntity().getLabel();
      },
      action: function action(menu, value) {
        menu.getEntity().setLabel(value);
      }
    },
    EDIT_URL: {
      title: _ContextMenuActionLabels.default.EDIT_URL,
      visible: true,
      type: _ContextMenuActionType.default.INPUT,
      hasGenerateThumbnail: true,
      secondaryAction: function secondaryAction(menu) {
        return menu.getEntity().generateCustomThumbnail(projectId);
      },
      value: function value(menu) {
        return menu.getEntity().getUrl();
      },
      action: function action(menu, value) {
        menu.getEntity().getCell().attr('url/text', value);
      }
    },
    CHANGE_LINE_STYLE: {
      title: _ContextMenuActionLabels.default.CHANGE_LINE_STYLE,
      visible: true,
      type: _ContextMenuActionType.default.MULTIPLE_OPTIONS,
      options: [{
        label: 'solid',
        value: _CanvasConstants.default.LINE_STYLE_SOLID
      }, {
        label: 'dashed',
        value: _CanvasConstants.default.LINE_STYLE_DASHED
      }],
      action: function action(menu, lineStyle) {
        menu.getEntity().setLineStyle(lineStyle);
        menu.setVisible(false);
      }
    },
    CHANGE_LINE_COLOUR: {
      title: 'Change Color',
      visible: true,
      type: _ContextMenuActionType.default.MULTIPLE_OPTIONS,
      options: [{
        label: 'red',
        value: '#f00'
      }, {
        label: 'pink',
        value: '#ffc0cb'
      }, {
        label: 'purple',
        value: '#800080'
      }, {
        label: 'magenta',
        value: '#f0f'
      }],
      action: function action(menu, colorSelected) {
        menu.getEntity().setLineColour(colorSelected);
        menu.setVisible(false);
      }
    },
    TO_FRONT: {
      title: 'To Front',
      visible: true,
      action: function action(menu) {
        var entity = menu.getEntity();
        var isOneEntity = !entity.isEmpty();
        var entities = isOneEntity ? [entity] : menu.getEntities(); // TODO: Maintain the z-index relationship between all currently selected entities if this is possible.

        menu.getPaper().commands.toFront(entities);
        menu.setVisible(false);
      }
    },
    TO_BACK: {
      title: 'To Back',
      visible: true,
      action: function action(menu) {
        var entity = menu.getEntity();
        var isOneEntity = !entity.isEmpty();
        var entities = isOneEntity ? [entity] : menu.getEntities(); // TODO: Maintain the z-index relationship between all currently selected entities if this is possible.

        menu.getPaper().commands.toBack(entities);
        menu.setVisible(false);
      }
    }
  };
  _exports.default = _default;
});