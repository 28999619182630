define("funnelytics/routes/dashboard/vault", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // vault.js
  var _default = Ember.Route.extend({
    model: function model() {
      return Ember.RSVP.hash({
        user: this.modelFor('dashboard').user,
        vaults: this.get('store').findAll('vault').then(function (vaults) {
          return vaults.toArray().sortBy('premium');
        }),
        offers: this.get('store').findAll('vault-offer-type'),
        industries: this.get('store').findAll('vault-industry')
      });
    }
  });

  _exports.default = _default;
});