define("funnelytics/services/banner-display", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BannerType = void 0;

  var _displayStates;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var BannerType = {
    anouncement: 'anouncement',
    lockFreeMap: 'lockFreeMap'
  };
  _exports.BannerType = BannerType;

  var _default = Ember.Service.extend({
    // eslint-disable-next-line no-undef
    displayStates: (_displayStates = {}, _defineProperty(_displayStates, BannerType.anouncement, true), _defineProperty(_displayStates, BannerType.lockFreeMap, true), _displayStates),
    setBannerState: function setBannerState(bannerType, value) {
      if (!this.displayStates.hasOwnProperty(bannerType)) {
        console.warn("No banner type ".concat(bannerType, " exists in map"));
        return;
      }

      this.set('displayStates', _objectSpread({}, this.displayStates, _defineProperty({}, bannerType, value)));
    },
    getBannerState: function getBannerState(bannerType) {
      if (!this.displayStates.hasOwnProperty(bannerType)) {
        console.warn("No banner type ".concat(bannerType, " exists in map"));
        return;
      }

      return this.displayStates[bannerType];
    }
  });

  _exports.default = _default;
});