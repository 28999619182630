define("funnelytics/templates/components/for-reuse/allowance-progress-bar/funnels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dtPTn+JS",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"areFunnelPermissionsDisplayed\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[[21,\"wrapperClasses\"]]]],[9],[0,\"\\n    \"],[1,[27,\"for-reuse/allowance-progress-bar\",null,[[\"label\",\"showTooltip\",\"allowance\",\"isColourInverted\"],[\"Funnels\",false,[23,[\"funnelPermissions\"]],[23,[\"isColourInverted\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/allowance-progress-bar/funnels.hbs"
    }
  });

  _exports.default = _default;
});