define("funnelytics/mixins/memberships", ["exports", "funnelytics/custom/constants/MembershipConstants"], function (_exports, _MembershipConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init: function init() {
      this._super.apply(this, arguments);

      var isMapping = _MembershipConstants.Membership.isMapping,
          isMap = _MembershipConstants.Membership.isMap,
          isPerformanceStarter = _MembershipConstants.Membership.isPerformanceStarter,
          isMarketer = _MembershipConstants.Membership.isMarketer,
          isPerformancePro = _MembershipConstants.Membership.isPerformancePro,
          isPerformanceBusiness = _MembershipConstants.Membership.isPerformanceBusiness,
          isPerformancePlus = _MembershipConstants.Membership.isPerformancePlus,
          isEnterprise = _MembershipConstants.Membership.isEnterprise,
          isLifeTime = _MembershipConstants.Membership.isLifeTime;
      this.setProperties({
        isMapping: isMapping,
        isMap: isMap,
        isPerformanceStarter: isPerformanceStarter,
        isMarketer: isMarketer,
        isPerformancePro: isPerformancePro,
        isPerformanceBusiness: isPerformanceBusiness,
        isPerformancePlus: isPerformancePlus,
        isEnterprise: isEnterprise,
        isLifeTime: isLifeTime
      });
    }
  });

  _exports.default = _default;
});