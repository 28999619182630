define("funnelytics/controllers/dashboard/funnels/unorganized", ["exports", "funnelytics/custom/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    router: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    NULL_PROJECT: _constants.NULL_PROJECT,
    actions: {
      refreshRouteFromController: function refreshRouteFromController() {
        this.send('refreshRoute');
      },
      transitionToRouteFromController: function transitionToRouteFromController() {
        this.transitionToRoute.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});