define("funnelytics/templates/components/library/creator-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dH3o3wyS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"style\",[28,[\"\\n    position: absolute;\\n    background-color: \",[21,\"backgroundColor\"],\";\\n    color: \",[21,\"textColor\"],\";\\n    border-radius: 0 12px 12px 0;\\n    width: 50%;\\n    transform: translate(-18px, 10px);\\n    padding: 0 20px;\\n    opacity: 69%;\\n    z-index: 4;\"]]],[9],[0,\"\\n    \"],[1,[21,\"text\"],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/library/creator-tags.hbs"
    }
  });

  _exports.default = _default;
});