define("funnelytics/helpers/get-normally", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getNormally = getNormally;
  _exports.default = void 0;

  function getNormally(params
  /*, hash*/
  ) {
    var value = _lodash.default.get(params[0], params[1], 'N/A');

    if (Object.keys(value)[0] === 'video') {
      value = 'Video';
    }

    if (Object.keys(value)[0] === 'image') {
      value = 'Image';
    }

    return value;
  }

  var _default = Ember.Helper.helper(getNormally);

  _exports.default = _default;
});