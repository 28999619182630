define("funnelytics/helpers/if-else", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ifElse = ifElse;
  _exports.default = void 0;

  function ifElse(params
  /*, hash*/
  ) {
    // return params;
    if (params[0]) {
      return params[1];
    } else {
      return params[2];
    }
  }

  var _default = Ember.Helper.helper(ifElse);

  _exports.default = _default;
});