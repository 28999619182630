define("funnelytics/components/canvas-elements/context-menu/action-attribute", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    key: Ember.computed('parameter', function () {
      var parameter = this.get('parameter');

      if (!parameter) {
        return '';
      }

      return parameter.getKey();
    }),
    value: Ember.computed('parameter', function () {
      var parameter = this.get('parameter');

      if (!parameter) {
        return '';
      }

      return parameter.getValue();
    }),
    isContains: Ember.computed('parameter', function () {
      var parameter = this.get('parameter');

      if (!parameter) {
        return '';
      }

      return parameter.isContains();
    }),
    onDeleteValue: function onDeleteValue() {},
    actions: {
      deleteValue: function deleteValue() {
        var deleteHandler = this.get('onDeleteValue');
        deleteHandler(this.get('parameter'));
      }
    }
  });

  _exports.default = _default;
});