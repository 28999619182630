define("funnelytics/templates/components/public/funnel-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "guvQuQBX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"alert alert-warning mobile\"],[11,\"style\",\"position: absolute; top: 0; left: 0; z-index: 999; margin: 10px;\"],[11,\"role\",\"alert\"],[9],[0,\"\\n  \"],[7,\"span\"],[9],[0,\"Unfortunately Funnelytics is not yet mobile friendly. Please use on your desktop for a better experience.\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"id\",\"app\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"work\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"top-toolbar top-toolbar-left toolbar-box fl--ptb--05 fl--pl--1 fl--pr--1\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"brand\"],[9],[0,\"\\n        \"],[7,\"img\"],[11,\"class\",\"logo\"],[11,\"src\",\"/assets/canvas-logo.svg\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"toolbar-vertical-separator fl--mlr--1\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"funnel-name\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"funnel-name__content\"],[9],[0,\"\\n          \"],[1,[21,\"funnelName\"],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"id\",\"paper\"],[11,\"tabindex\",\"0\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"bottom-toolbar bottom-toolbar-left\"],[9],[0,\"\\n  \"],[1,[27,\"canvas-elements/zoom-tools\",null,[[\"zoomValue\",\"isZoomDropdownOpen\",\"toggleZoomDropdown\",\"zoom\",\"zoomFit\"],[[23,[\"zoom\"]],[23,[\"isZoomDropdownOpen\"]],[27,\"action\",[[22,0,[]],\"toggleZoomDropdown\"],null],[27,\"action\",[[22,0,[]],\"zoom\"],null],[27,\"action\",[[22,0,[]],\"zoomFit\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/public/funnel-view.hbs"
    }
  });

  _exports.default = _default;
});