define("funnelytics/components/for-reuse/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    buttonStyle: Ember.computed(function () {
      var bkg = new Ember.String.htmlSafe("background: ".concat(this.get('bkgColor')));
      var width = new Ember.String.htmlSafe("width: ".concat(this.get('width')));
      return "".concat(bkg, ";").concat(width, ";");
    }),
    actions: {
      trigger: function trigger() {
        this.get('actionTrigger')();
      }
    }
  });

  _exports.default = _default;
});