define("funnelytics/models/canvas-library", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    funnel: _emberData.default.belongsTo('funnel'),
    offer_type: _emberData.default.attr('string'),
    industry: _emberData.default.attr('string'),
    purpose: _emberData.default.attr('string'),
    is_funnelytics: _emberData.default.attr('boolean'),
    is_paid: _emberData.default.attr('boolean'),
    enabled: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date'),
    import_count: _emberData.default.attr('number')
  });

  _exports.default = _default;
});