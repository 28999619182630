define("funnelytics/components/canvas-elements/kpi-summary", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['kpi-panel__kpi-row'],
    forecastService: Ember.inject.service(),
    profitColour: Ember.computed('profit', function () {
      return this.get('profit') > 0 ? 'green' : 'red';
    }),
    forecastedRoiPercentage: Ember.computed('forecastKPIs.roi', function () {
      var roi = parseFloat(this.get('forecastKPIs.metrics.roi'), 10);

      if (!_lodash.default.isFinite(roi)) {
        return 0;
      }

      return roi;
    }),
    forecastedProfit: Ember.computed('forecastKPIs.expenses', 'forecastKPIs.revenue', function () {
      return this.get('forecastKPIs.expenses') - this.get('forecastKPIs.revenue');
    })
  });

  _exports.default = _default;
});