define("funnelytics/routes/registered", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    metrics: Ember.inject.service(),
    session: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: function model(params) {
      this.set('params', params);
    },
    setupController: function setupController(controller, model) {
      controller.set('funnel', this.get('params.funnel'));

      if (this.get('session.isAuthenticated')) {
        this.get('store').findRecord('user', this.get('session.data.authenticated.id'), {
          include: ['membership']
        }).then(function (user) {
          // save to Google Tag Manager
          if (window.dataLayer) {
            window.dataLayer.push({
              event: 'REGISTRATION',
              visitorType: 'loggedIn',
              email: user.get('email').toLowerCase(),
              firstName: user.get('first_name').toLowerCase(),
              lastName: user.get('last_name').toLowerCase(),
              pageTitle: 'Registration',
              pageType: 'Registration'
            });
          }
        });
      }

      this._super(controller, model);
    }
  });

  _exports.default = _default;
});