define("funnelytics/components/containers/funnel-editor/components/right-sidebar-analytics-session-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sessionGroupsPage: 0,
    sessionGroupsPageSize: 5,
    analyticsSessionGroups: [],
    countriesFilter: [],
    filteredSessionGroups: Ember.computed('countriesFilter', 'analyticsSessionGroups', function () {
      if (!this.get('countriesFilter.length')) {
        return this.get('analyticsSessionGroups');
      }

      var selectedCountries = this.get('countriesFilter');
      return this.get('analyticsSessionGroups').filter(function (sessionGroup) {
        return selectedCountries.includes(sessionGroup['countryName']);
      });
    }),
    NEW_GROUPS_OR_FILTERS: Ember.observer('filteredSessionGroups', function () {
      this.set('sessionGroupsPage', 0);
    }),
    filteredSessionGroupsPeopleCount: Ember.computed('filteredSessionGroups', function () {
      return this.get('filteredSessionGroups').reduce(function (totalPeople, sessionGroup) {
        return totalPeople += parseInt(sessionGroup['visitorsCount'], 10);
      }, 0);
    }),
    sessionGroupsTotalCount: Ember.computed.readOnly('filteredSessionGroups.length'),
    sessionGroupsNextPageEnabled: Ember.computed('sessionGroupsTotalCount', 'sessionGroupsPage', 'sessionGroupsPageSize', 'isAnalyticsSessionGroupsLoading', function () {
      var page = this.get('sessionGroupsPage');
      var pageSize = this.get('sessionGroupsPageSize');
      var totalCount = this.get('sessionGroupsTotalCount');
      return (page + 1) * pageSize < totalCount && !this.get('isAnalyticsSessionGroupsLoading');
    }),
    sessionGroupsPrevPageEnabled: Ember.computed('sessionGroupsTotalCount', 'sessionGroupsPage', 'isAnalyticsSessionGroupsLoading', function () {
      var page = this.get('sessionGroupsPage');
      var totalCount = this.get('sessionGroupsTotalCount');
      return page > 0 && totalCount > 0 && !this.get('isAnalyticsSessionGroupsLoading');
    }),
    sessionGroupsList: Ember.computed('sessionGroupsPage', 'sessionGroupsPageSize', 'filteredSessionGroups', function () {
      var allGroups = this.get('filteredSessionGroups');
      var limit = this.get('sessionGroupsPageSize');
      var offset = this.get('sessionGroupsPage') * limit;
      return allGroups.slice(offset, offset + limit);
    }),
    sessionGroupsListRangeTo: Ember.computed('sessionGroupsPage', 'sessionGroupsPageSize', 'sessionGroupsTotalCount', function () {
      var page = this.get('sessionGroupsPage');
      var pageSize = this.get('sessionGroupsPageSize');
      var totalCount = this.get('sessionGroupsTotalCount');
      return Math.min((page + 1) * pageSize, totalCount);
    }),
    sessionGroupsListRangeFrom: Ember.computed('sessionGroupsPage', 'sessionGroupsPageSize', 'sessionGroupsTotalCount', function () {
      var page = this.get('sessionGroupsPage');
      var pageSize = this.get('sessionGroupsPageSize');
      var totalCount = this.get('sessionGroupsTotalCount');
      return Math.min(page * pageSize + 1, totalCount);
    }),
    actions: {
      sessionsGroupsNextPage: function sessionsGroupsNextPage() {
        if (!this.get('sessionGroupsNextPageEnabled')) {
          return;
        }

        this.set('sessionGroupsPage', this.get('sessionGroupsPage') + 1);
      },
      sessionsGroupsPrevPage: function sessionsGroupsPrevPage() {
        if (!this.get('sessionGroupsPrevPageEnabled')) {
          return;
        }

        this.set('sessionGroupsPage', this.get('sessionGroupsPage') - 1);
      }
    }
  });

  _exports.default = _default;
});