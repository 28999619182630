define("funnelytics/components/flash-message/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    route: Ember.computed.readOnly('link.route'),
    label: Ember.computed.readOnly('link.label'),
    isDisplayed: Ember.computed.and('route', 'label')
  });

  _exports.default = _default;
});