define("funnelytics/routes/dashboard/settings/cancel-subscriptions", ["exports", "ember-routable-modal/mixins/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STRIPE_VALIDATION = 'sub_';

  var _default = Ember.Route.extend(_route.default, {
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    model: function model() {
      var _user$payment, _user$payment$stripe, _user$payment2, _user$payment2$paddle;

      var user = this.modelFor('dashboard').user;
      var stripeID = ((_user$payment = user.payment) === null || _user$payment === void 0 ? void 0 : (_user$payment$stripe = _user$payment.stripe) === null || _user$payment$stripe === void 0 ? void 0 : _user$payment$stripe.subscription_id) || null;
      var paddleID = ((_user$payment2 = user.payment) === null || _user$payment2 === void 0 ? void 0 : (_user$payment2$paddle = _user$payment2.paddle) === null || _user$payment2$paddle === void 0 ? void 0 : _user$payment2$paddle.subscription_id) || null;
      var stripeValidation = stripeID === null || stripeID === void 0 ? void 0 : stripeID.includes(STRIPE_VALIDATION);
      var link = '';
      var stripeLink = "https://funnelytics.io/go/trial-change-survey-stripe?cea=".concat(user.email, "&subID=").concat(stripeID);
      var paddleLink = "https://funnelytics.io/go/trial-change-survey?cea=".concat(user.email, "&subID=").concat(paddleID);

      if (stripeID && stripeValidation) {
        link = stripeLink;
      } else if (paddleID) {
        link = paddleLink;
      } else {
        link = "https://funnelytics.io/go/trial-change-survey-stripe?cea=".concat(user.email, "&subID=");
      }

      return {
        link: link
      };
    },
    afterModel: function afterModel(model, transition) {
      if (!model.link) {
        return this.router.transitionTo('dashboard');
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        return this.router.transitionTo('dashboard.settings.my-subscriptions');
      }
    }
  });

  _exports.default = _default;
});