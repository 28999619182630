define("funnelytics/models/icon-folder", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    icons: _emberData.default.hasMany('icon'),
    user: _emberData.default.belongsTo('user')
  });

  _exports.default = _default;
});