define("funnelytics/templates/components/right-sidebar/benchmark-warning-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r7XUqsdi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"benchmarks-warning-modal fl-modal fl-modal-absolute \",[27,\"if\",[[23,[\"isOpen\"]],\"fl-modal--open\"],null]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"modal-content\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n      \"],[7,\"h2\"],[11,\"class\",\"modal-title fl--font--600 fl--txt--center wd--100\"],[9],[0,\"Benchmark From Analytics\"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"close\"],[11,\"type\",\"button\"],[3,\"action\",[[22,0,[]],[23,[\"onClose\"]]]],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"aria-hidden\",\"true\"],[9],[0,\"×\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"benchmarks-warning-modal__description\"],[9],[0,\"\\n        \"],[7,\"p\"],[11,\"class\",\"txt--grey\"],[9],[0,\"Automatically set benchmarks based on your current traffic\"],[10],[0,\"\\n        \"],[7,\"b\"],[11,\"class\",\"txt--brand-orange fl--875--font\"],[9],[0,\"\\n          Warning: this will override your current forecasting settings\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"fl--mt--1 fl-flex-row fl-flex-center fl--75--font\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"fl-btn fl-btn--brand fl-btn--small-tall\"],[3,\"action\",[[22,0,[]],[23,[\"onConfirm\"]]]],[9],[0,\"\\n          CONFIRM\\n        \"],[10],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"fl-btn fl-btn--no-borders fl-btn--brand-red-inverse fl-btn--small-tall\"],[3,\"action\",[[22,0,[]],[23,[\"onClose\"]]]],[9],[0,\"\\n          CANCEL\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/right-sidebar/benchmark-warning-modal.hbs"
    }
  });

  _exports.default = _default;
});