define("funnelytics/components/context-menu/item", ["exports", "funnelytics/custom/constants/ContextMenuActionType", "funnelytics/custom/constants/ContextMenuActionLabels"], function (_exports, _ContextMenuActionType, _ContextMenuActionLabels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    title: Ember.computed.readOnly('contextAction.title'),
    type: Ember.computed('contextAction.type', function () {
      var type = this.get('contextAction.type');

      if (!type) {
        return _ContextMenuActionType.default.SIMPLE;
      }

      return type;
    }),
    defaultValue: null,
    isSimpleButton: Ember.computed.equal('type', _ContextMenuActionType.default.SIMPLE),
    isInputAction: Ember.computed.equal('type', _ContextMenuActionType.default.INPUT),
    isMultipleOptions: Ember.computed.equal('type', _ContextMenuActionType.default.MULTIPLE_OPTIONS),
    options: Ember.computed('isMultipleOptions', 'contextAction.options', function () {
      if (!this.get('isMultipleOptions')) {
        return [];
      }

      return this.get('contextAction.options');
    }),
    onChange: function onChange() {},
    didInsertElement: function didInsertElement() {
      var func = this.get('contextAction').value;

      if (func) {
        var value = func(this.get('paper').getContextMenu());
        this.set('defaultValue', value);
      }
    },
    actions: {
      triggerInputAction: function triggerInputAction(evt) {
        var menu = this.get('paper').getContextMenu();

        if (menu.isVisible()) {
          var index = this.get('actionIndex');
          menu.triggerAction(index, evt.target.value);
        }
      },
      handleAction: function handleAction(functionTitle) {
        var _this = this;

        var menu = this.get('paper').getContextMenu();
        var index;

        if (functionTitle) {
          index = functionTitle;

          if (functionTitle === _ContextMenuActionLabels.default.GENERATE_THUMBNAIL) {
            this.set('loading', true);
          }

          menu.triggerAction(index).then(function () {
            _this.set('loading', false);
          });
        } else {
          index = this.get('actionIndex');
          menu.triggerAction(index);
        }
      },
      handleSecondaryAction: function handleSecondaryAction() {
        var _this2 = this;

        this.set('loading', true);
        var menu = this.get('paper').getContextMenu();
        var index = this.get('actionIndex');
        menu.triggerSecondaryAction(index).then(function () {
          return _this2.set('loading', false);
        });
      },
      selectOption: function selectOption(value) {
        this.send('handleAction', [value]);
      }
    }
  });

  _exports.default = _default;
});