define("funnelytics/templates/dashboard/import", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cSxnQzQP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"overlay-v2 overlay-v2-show\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"import-modal-content\"],[11,\"style\",\"z-index: 6;\"],[9],[0,\"\\n  \"],[1,[27,\"import-funnel\",null,[[\"model\"],[[23,[\"model\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/dashboard/import.hbs"
    }
  });

  _exports.default = _default;
});