define("funnelytics/components/canvas-elements/context-menu/tracking-settings/metrics-dropdown", ["exports", "lodash", "funnelytics/custom/analytics/settings/MetricConstants"], function (_exports, _lodash, _MetricConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    opened: false,
    totalSelected: Ember.computed("metrics.@each.".concat(_MetricConstants.default.KEYS.IS_SELECTED), function () {
      return this.get('metrics').filter(function (metric) {
        return _lodash.default.get(metric, [_MetricConstants.default.KEYS.IS_SELECTED]);
      }).length;
    }),
    dropdownOpened: Ember.computed('opened', 'disabled', function () {
      return this.get('opened') && !this.get('disabled');
    }),
    onToggleMetric: function onToggleMetric() {},
    actions: {
      toggleMetric: function toggleMetric(metric) {
        var isToggleable = _lodash.default.get(metric, [_MetricConstants.default.KEYS.IS_TOGGLEABLE]);

        if (!isToggleable) {
          return;
        }

        var toggleMetricHandler = this.get('onToggleMetric');

        var metricId = _lodash.default.get(metric, [_MetricConstants.default.KEYS.ID]);

        toggleMetricHandler(metricId);
      },
      toggleOpened: function toggleOpened() {
        this.toggleProperty('opened');
      },
      clickOutside: function clickOutside() {
        this.set('opened', false);
      }
    }
  });

  _exports.default = _default;
});