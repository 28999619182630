define("funnelytics/components/containers/funnel-editor/components/step-list-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.setProperties({
        isExpanded: false
      });
    },
    onSelect: function onSelect() {},
    onFind: function onFind() {},
    actions: {
      selectStep: function selectStep() {
        var selectHandler = this.get('onSelect');
        selectHandler.apply(void 0, arguments);
      },
      findStep: function findStep() {
        var findHandler = this.get('onFind');
        findHandler.apply(void 0, arguments);
      },
      toggleExpanded: function toggleExpanded() {
        this.toggleProperty('isExpanded');
      }
    }
  });

  _exports.default = _default;
});