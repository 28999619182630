define("funnelytics/templates/components/canvas-elements/commerce-skus/option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WTnSREVG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"option\"],[12,\"value\",[21,\"value\"]],[12,\"selected\",[21,\"isSelected\"]],[9],[0,\"\\n  \"],[1,[21,\"sku\"],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/commerce-skus/option.hbs"
    }
  });

  _exports.default = _default;
});