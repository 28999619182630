define("funnelytics/custom/misc/asyncWait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Can be used to only perform tasks after some unobservable asyncronous operations are (hopefully) complete.
   */
  var asyncWait = function asyncWait() {
    var amount = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    return new Ember.RSVP.Promise(function (resolve) {
      return setTimeout(resolve, amount);
    });
  };

  var _default = asyncWait;
  _exports.default = _default;
});