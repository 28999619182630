define("funnelytics/components/dashboard/announcement-banner", ["exports", "funnelytics/config/environment", "funnelytics/custom/constants/MembershipConstants", "funnelytics/services/banner-display"], function (_exports, _environment, _MembershipConstants, _bannerDisplay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    versionManager: Ember.inject.service(),
    projectPermissions: Ember.inject.service(),
    workspaceSelector: Ember.inject.service(),
    router: Ember.inject.service(),
    bannerDisplay: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    manualBannerDisable: false,
    showbanner: Ember.computed('projectPermissions.ownedCanvasCount', 'verisonManager.activeDashboardVersion', 'manualBannerDisable', function () {
      var canvasCount = this.get('projectPermissions').ownedCanvasCount;
      var membership = this.get('projectPermissions').hasMembership(_MembershipConstants.Membership.isMapping);
      var isEligibleForFreeTrial = this.get('projectPermissions').isEligibleForFreeTrial;
      var shouldShowBanner = !this.manualBannerDisable && canvasCount > 3 && membership && isEligibleForFreeTrial;
      this.get('bannerDisplay').setBannerState(_bannerDisplay.BannerType.anouncement, shouldShowBanner);
      return shouldShowBanner;
    }),
    hasPaymentMethod: Ember.computed('projectPermissions.hasStripeSubscription', function () {
      return this.projectPermissions.hasStripeSubscription;
    }),
    readMeLink: 'https://funnelytics.io/map-plus?utm_source=app&utm_medium=dashboard)',
    btnLink: "".concat(_environment.default.app, "/login?performance=map-plus&utm_source=app&utm_medium=dashboard"),
    actions: {
      closeBanner: function closeBanner() {
        this.set('manualBannerDisable', true);
        this.get('bannerDisplay').setBannerState(_bannerDisplay.BannerType.anouncement, false);
      }
    }
  });

  _exports.default = _default;
});