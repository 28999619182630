define("funnelytics/custom/misc/icons", ["exports", "funnelytics/custom/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    page: [{
      name: 'Generic',
      path: _constants.DEFAULT_ICONS_PATH + 'Generic Page.svg'
    }, {
      name: 'Download',
      path: _constants.DEFAULT_ICONS_PATH + 'Download.svg'
    }, {
      name: 'Opt In',
      path: _constants.DEFAULT_ICONS_PATH + 'Opt In.svg'
    }, {
      name: 'Order Page',
      path: _constants.DEFAULT_ICONS_PATH + 'Order Page.svg'
    }, {
      name: 'Sales Page',
      path: _constants.DEFAULT_ICONS_PATH + 'Sales Page.svg'
    }, {
      name: 'Sales Video',
      path: _constants.DEFAULT_ICONS_PATH + 'Sales Page w Video.svg'
    }, {
      name: 'Calendar',
      path: _constants.DEFAULT_ICONS_PATH + 'Calendar.svg'
    }, {
      name: 'Survey',
      path: _constants.DEFAULT_ICONS_PATH + 'Survey.svg'
    }, {
      name: 'Upsell',
      path: _constants.DEFAULT_ICONS_PATH + 'Upsell OTO.svg'
    }, {
      name: 'Webinar',
      path: _constants.DEFAULT_ICONS_PATH + 'Webinar Live.svg'
    }, {
      name: 'Webinar Replay',
      path: _constants.DEFAULT_ICONS_PATH + 'Webinar Replay.svg'
    }, {
      name: 'Blog Post',
      path: _constants.DEFAULT_ICONS_PATH + 'Blog Post.svg'
    }, {
      name: 'Members Area',
      path: _constants.DEFAULT_ICONS_PATH + 'Members Area.svg'
    }, {
      name: 'Thank You',
      path: _constants.DEFAULT_ICONS_PATH + 'Thank You.svg'
    }, {
      name: 'Popup',
      path: _constants.DEFAULT_ICONS_PATH + 'Popup.svg'
    }],
    source: [{
      name: 'Source',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/source.svg'
    }, {
      name: 'Search',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/search.svg'
    }, {
      name: 'Email',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/email.svg'
    }, {
      name: 'Google Ads',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/google-adblock-is-awful.svg'
    }, {
      name: 'Banner',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/banner.svg'
    }, {
      name: 'Ad Network',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/ad-network.svg'
    }, {
      name: 'Affiliate Program',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/affiliate-program.svg'
    }, {
      name: 'Amazon',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/amazon.svg'
    }, {
      name: 'Bing',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/bing.svg'
    }, {
      name: 'Biz Directory',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/biz-directory.svg'
    }, {
      name: 'Career Site',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/career-site.svg'
    }, {
      name: 'Chat Box',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/chat-box.svg'
    }, {
      name: 'Chat Bot',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/chatbot.svg'
    }, {
      name: 'Clutch',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/clutch.svg'
    }, {
      name: 'Facebook Ad',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/facebook-ad.svg'
    }, {
      name: 'Facebook Post',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/facebook-post.svg'
    }, {
      name: 'Facebook',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/facebook.svg'
    }, {
      name: 'Gmail',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/gmail.svg'
    }, {
      name: 'Google Maps',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/google-maps.svg'
    }, {
      name: 'Google',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/google.svg'
    }, {
      name: 'Guest Blog',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/guest-blog.svg'
    }, {
      name: 'Instagram Ad',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/instagram-ad.svg'
    }, {
      name: 'Instagram Post',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/instagram-post.svg'
    }, {
      name: 'Instagram Story',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/instagram-story.svg'
    }, {
      name: 'Instagram',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/instagram.svg'
    }, {
      name: 'Job Site',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/job-site.svg'
    }, {
      name: 'LinkedIn Ad',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/linkedin-ad.svg'
    }, {
      name: 'LinkedIn Post',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/linkedin-post.svg'
    }, {
      name: 'LinkedIn',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/linkedin.svg'
    }, {
      name: 'Messenger Bot',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/messenger-bot.svg'
    }, {
      name: 'Messenger',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/messenger.svg'
    }, {
      name: 'Microsoft Ads',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/microsoft-ads.svg'
    }, {
      name: 'Online Meeting',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/online-meeting.svg'
    }, {
      name: 'Pinterest',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/pinterest.svg'
    }, {
      name: 'Reddit',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/reddit.svg'
    }, {
      name: 'Report',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/report.svg'
    }, {
      name: 'Snapchat',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/snapchat.svg'
    }, {
      name: 'Spotify',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/spotify.svg'
    }, {
      name: 'TikTok',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/tiktok.svg'
    }, {
      name: 'Twitter',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/twitter.svg'
    }, {
      name: 'Yelp',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/yelp.svg'
    }, {
      name: 'Youtube Ads',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/youtube-ads.svg'
    }, {
      name: 'Youtube Series',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/youtube-series.svg'
    }, {
      name: 'Youtube',
      path: _constants.UPDATED_ICON_PATH + 'Traffic/youtube.svg'
    }],
    event: [
    /*
    {
      name: 'Generic Event',
      path: DEFAULT_ICONS_PATH + 'diamond.svg'
    },
    */
    {
      name: 'Purchase',
      path: _constants.UPDATED_ICON_PATH + 'Actions/purchase.svg',
      event_name: 'purchase',
      attributes: {
        product: 'Name of Product',
        price: 20.0
      }
    }, {
      name: 'Watch Video',
      path: _constants.UPDATED_ICON_PATH + 'Actions/watch-video.svg',
      event_name: 'video',
      attributes: {
        video: 'Upsell Video'
      }
    }, {
      name: 'Add to Cart',
      path: _constants.UPDATED_ICON_PATH + 'Actions/add-to-cart.svg',
      event_name: 'add-to-cart',
      attributes: {
        product: 'Name of Product'
      }
    }, {
      name: 'Complete Form',
      path: _constants.UPDATED_ICON_PATH + 'Actions/application.svg',
      event_name: 'complete-form',
      attributes: {
        name: 'Full Name'
      }
    }, {
      name: 'Scroll',
      path: _constants.UPDATED_ICON_PATH + 'Actions/scroll.svg',
      event_name: 'scroll',
      attributes: {
        position: 150
      }
    }, {
      name: 'Click Button',
      path: _constants.UPDATED_ICON_PATH + 'Actions/click.svg',
      event_name: 'click',
      attributes: {
        name: 'Open Modal'
      }
    }, {
      name: 'Pop-up Box',
      path: _constants.UPDATED_ICON_PATH + 'Actions/popup.svg',
      event_name: 'popup',
      attributes: {
        name: 'Upsell'
      }
    }, {
      name: 'Add Tag',
      path: _constants.UPDATED_ICON_PATH + 'Actions/add-tag.svg',
      event_name: 'add-tag',
      attributes: {
        name: 'Add Tag'
      }
    }, {
      name: 'Add To List',
      path: _constants.UPDATED_ICON_PATH + 'Actions/add-to-list.svg',
      event_name: 'add-to-list',
      attributes: {
        name: 'Add To List'
      }
    }, {
      name: 'Contact',
      path: _constants.UPDATED_ICON_PATH + 'Actions/contact.svg',
      event_name: 'contact',
      attributes: {
        name: 'Contact'
      }
    }, {
      name: 'Lead',
      path: _constants.UPDATED_ICON_PATH + 'Actions/lead.svg',
      event_name: 'lead',
      attributes: {
        name: 'Lead'
      }
    }, {
      name: 'Popup',
      path: _constants.UPDATED_ICON_PATH + 'Actions/popup.svg',
      event_name: 'popup',
      attributes: {
        name: 'Popup'
      }
    }, {
      name: 'Request Contact',
      path: _constants.UPDATED_ICON_PATH + 'Actions/request-contact.svg',
      event_name: 'request-contact',
      attributes: {
        name: 'Request Contact'
      }
    }, {
      name: 'Request Info',
      path: _constants.UPDATED_ICON_PATH + 'Actions/request-info.svg',
      event_name: 'request-info',
      attributes: {
        name: 'Request Info'
      }
    }, {
      name: 'Schedule Meeting',
      path: _constants.UPDATED_ICON_PATH + 'Actions/schedule-meeting.svg',
      event_name: 'schedule-meeting',
      attributes: {
        name: 'Schedule Meeting'
      }
    }],
    offline: [{
      name: 'Business Card',
      path: _constants.UPDATED_ICON_PATH + 'Misc/business-card.svg'
    }, {
      name: 'Meeting',
      path: _constants.UPDATED_ICON_PATH + 'Misc/meeting.svg'
    }, {
      name: 'Phone',
      path: _constants.UPDATED_ICON_PATH + 'Misc/phone.svg'
    }, {
      name: 'Print Ad',
      path: _constants.UPDATED_ICON_PATH + 'Misc/print-ad.svg'
    }, {
      name: 'Radio',
      path: _constants.UPDATED_ICON_PATH + 'Misc/radio.svg'
    }, {
      name: 'Text Message',
      path: _constants.UPDATED_ICON_PATH + 'Misc/sms.svg'
    }, {
      name: 'Active Campaign',
      path: _constants.UPDATED_ICON_PATH + 'Misc/active-campaign.svg'
    }, {
      name: 'Billboard',
      path: _constants.UPDATED_ICON_PATH + 'Misc/billboard.svg'
    }, {
      name: 'Clock',
      path: _constants.UPDATED_ICON_PATH + 'Misc/clock.svg'
    }, {
      name: 'Conference',
      path: _constants.UPDATED_ICON_PATH + 'Misc/conference.svg'
    }, {
      name: 'Constant Contact',
      path: _constants.UPDATED_ICON_PATH + 'Misc/constant-contact.svg'
    }, {
      name: 'Direct Mail',
      path: _constants.UPDATED_ICON_PATH + 'Misc/direct-mail.svg'
    }, {
      name: 'Drift',
      path: _constants.UPDATED_ICON_PATH + 'Misc/drift.svg'
    }, {
      name: 'Drip',
      path: _constants.UPDATED_ICON_PATH + 'Misc/drip.svg'
    }, {
      name: 'Go To Meeting',
      path: _constants.UPDATED_ICON_PATH + 'Misc/go-to-meeting.svg'
    }, {
      name: 'Hubspot',
      path: _constants.UPDATED_ICON_PATH + 'Misc/hubspot.svg'
    }, {
      name: 'Insightly',
      path: _constants.UPDATED_ICON_PATH + 'Misc/insightly.svg'
    }, {
      name: 'Intercom',
      path: _constants.UPDATED_ICON_PATH + 'Misc/intercom.svg'
    }, {
      name: 'Job Interview',
      path: _constants.UPDATED_ICON_PATH + 'Misc/job-interview.svg'
    }, {
      name: 'Keap',
      path: _constants.UPDATED_ICON_PATH + 'Misc/keap.svg'
    }, {
      name: 'Mail Chimp',
      path: _constants.UPDATED_ICON_PATH + 'Misc/mailchimp.svg'
    }, {
      name: 'Marketo',
      path: _constants.UPDATED_ICON_PATH + 'Misc/marketo.svg'
    }, {
      name: 'Next Funnel',
      path: _constants.UPDATED_ICON_PATH + 'Misc/next-funnel.svg'
    }, {
      name: 'Ontraport',
      path: _constants.UPDATED_ICON_PATH + 'Misc/ontraport.svg'
    }, {
      name: 'Pipedrive',
      path: _constants.UPDATED_ICON_PATH + 'Misc/pipedrive.svg'
    }, {
      name: 'QR Code',
      path: _constants.UPDATED_ICON_PATH + 'Misc/qr-code.svg'
    }, {
      name: 'Salesforce',
      path: _constants.UPDATED_ICON_PATH + 'Misc/salesforce.svg'
    }, {
      name: 'Slack',
      path: _constants.UPDATED_ICON_PATH + 'Misc/slack.svg'
    }, {
      name: 'Telegram',
      path: _constants.UPDATED_ICON_PATH + 'Misc/telegram.svg'
    }, {
      name: 'TV Ad',
      path: _constants.UPDATED_ICON_PATH + 'Misc/tv-ad.svg'
    }, {
      name: 'Workshop Seminar',
      path: _constants.UPDATED_ICON_PATH + 'Misc/workshop-seminar.svg'
    }, {
      name: 'Zapier',
      path: _constants.UPDATED_ICON_PATH + 'Misc/zapier.svg'
    }, {
      name: 'Zendesk',
      path: _constants.UPDATED_ICON_PATH + 'Misc/zendesk.svg'
    }, {
      name: 'Zoho',
      path: _constants.UPDATED_ICON_PATH + 'Misc/zoho.svg'
    }, {
      name: 'Zoom',
      path: _constants.UPDATED_ICON_PATH + 'Misc/zoom.svg'
    }]
  };
  _exports.default = _default;
});