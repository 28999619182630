define("funnelytics/templates/components/for-reuse/base-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PCrHyjaE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"fl-modal \",[27,\"if\",[[23,[\"isOpen\"]],\"fl-modal--open\"],null],\" \",[27,\"if\",[[23,[\"className\"]],[23,[\"className\"]]],null]]]],[11,\"tabindex\",\"-1\"],[11,\"role\",\"dialog\"],[12,\"aria-hidden\",[21,\"ariaHidden\"]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"role\",\"document\"],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"modal-content fl-modal__content \",[27,\"if\",[[23,[\"isOpenInternal\"]],\"fl-modal__content--open\"],null]]]],[11,\"style\",\"width: 50%; margin-left: 0; left: calc(50%/2); max-width:800px;\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"noHeader\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"class\",\"close close-absolute-position\"],[11,\"aria-label\",\"Close\"],[11,\"type\",\"button\"],[3,\"action\",[[22,0,[]],\"close\"]],[9],[0,\"\\n          \"],[7,\"span\"],[12,\"aria-hidden\",[21,\"ariaHidden\"]],[9],[0,\"\\n            ×\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"modal-header\"],[9],[0,\"\\n          \"],[7,\"h3\"],[11,\"class\",\"card-title\"],[9],[0,\"\\n            \"],[1,[21,\"title\"],false],[0,\"\\n          \"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"not\",[[23,[\"closeButtonHidden\"]]],null]],null,{\"statements\":[[0,\"            \"],[7,\"button\"],[11,\"class\",\"close\"],[11,\"aria-label\",\"Close\"],[11,\"type\",\"button\"],[3,\"action\",[[22,0,[]],\"close\"]],[9],[0,\"\\n              \"],[7,\"span\"],[12,\"aria-hidden\",[21,\"ariaHidden\"]],[9],[0,\"\\n                ×\\n              \"],[10],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[7,\"div\"],[11,\"class\",\"modal-body\"],[9],[0,\"\\n        \"],[14,1],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/base-modal.hbs"
    }
  });

  _exports.default = _default;
});