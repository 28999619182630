define("funnelytics/custom/constants/Upsell", ["exports", "funnelytics/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.UPSELL_TEXTS = _exports.UPSELL_URLS = void 0;
  var UPSELL_URLS = {
    discover: 'https://funnelytics.io/discover',
    start_free_trial: "".concat(_environment.default.app, "/dashboard/settings/my-subscriptions?h=performance-business"),
    mapping_by_limit: "".concat(_environment.default.app, "/login?performance=map-plus?utm_source=app&utm_medium=dashboard"),
    mapping_by_limit_stripe: 'https://funnelytics.io/checkout/map-plus-monthly?utm_source=app&utm_medium=dashboard'
  };
  _exports.UPSELL_URLS = UPSELL_URLS;
  var UPSELL_TEXTS = {
    book_a_demo: 'Book a demo',
    start_free_trial: 'Start Your Free Trial',
    start_performance: 'Start Funnelytics™ Performance Free Trial',
    upgrade: 'Upgrade',
    contact_support: 'Contact Support',
    mapping_by_limit: 'Upgrade to Funnelytics™ Mapping Plus'
  };
  _exports.UPSELL_TEXTS = UPSELL_TEXTS;
});