define("funnelytics/helpers/round-decimals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.round2Decimals = round2Decimals;
  _exports.default = void 0;

  function round2Decimals(params
  /*, hash*/
  ) {
    var numberOfDecimals = params[1];
    var number = Number(params[0]);
    number = number.toFixed(numberOfDecimals);
    return number;
  }

  var _default = Ember.Helper.helper(round2Decimals);

  _exports.default = _default;
});