define("funnelytics/components/funnel-pages/checkout/agreement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onClickAgreeCheckbox: function onClickAgreeCheckbox() {},
    onSubmitOrder: function onSubmitOrder() {},
    actions: {
      clickAgreeCheckbox: function clickAgreeCheckbox(eventObject) {
        var clickAgreeCheckboxHandler = this.get('onClickAgreeCheckbox');
        clickAgreeCheckboxHandler(eventObject);
      },
      submitOrder: function submitOrder() {
        var submitOrderHandler = this.get('onSubmitOrder');
        submitOrderHandler();
      }
    }
  });

  _exports.default = _default;
});