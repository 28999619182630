define("funnelytics/components/canvas-elements/context-menu/tracking-settings/advanced-actions/attribute-key-dropdown", ["exports", "lodash", "funnelytics/custom/analytics/settings/config/standard-action-keys-mapping"], function (_exports, _lodash, _standardActionKeysMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filteredActionAttributeKeys: Ember.computed('availableActionAttributes', 'propertiesFilterQuery', function () {
      return this._filterActionAttributes(this.get('availableActionAttributes'));
    }),
    standardActionKeysMapping: _standardActionKeysMapping.default,
    isNotLoading: Ember.computed.not('isLoading'),
    didInsertElement: function didInsertElement() {
      this.setProperties({
        propertiesFilterQuery: null,
        expandedActionProperties: false
      });
    },
    _filterActionAttributes: function _filterActionAttributes(attributesToFilter) {
      var query = this.get('propertiesFilterQuery');
      var formattedQuery = query ? query.trim().toLowerCase() : null;
      return attributesToFilter.filter(function (actionAttribute) {
        // TODO: Move key into constant
        var actionAttributeKey = _lodash.default.get(actionAttribute, ['key']);

        var mappedAttributeKey = _lodash.default.get(_standardActionKeysMapping.default, [actionAttributeKey], actionAttributeKey);

        return !formattedQuery || mappedAttributeKey.toLowerCase().includes(formattedQuery);
      });
    },
    actionKeyDisplayConverter: function actionKeyDisplayConverter(actionKey) {
      return _lodash.default.get(_standardActionKeysMapping.default, [actionKey], actionKey);
    },
    onSelectKey: function onSelectKey() {},
    actions: {
      togglePropertiesDropDownOpened: function togglePropertiesDropDownOpened() {
        this.toggleProperty('isPropertiesDropDownOpened');
        this.set('propertiesFilterQuery', null);
      },
      selectProperty: function selectProperty(property) {
        this.send('togglePropertiesDropDownOpened');
        this.get('onSelectKey')(property);
      },
      toggleExpandedActionProperties: function toggleExpandedActionProperties() {
        this.toggleProperty('expandedActionProperties');
      }
    }
  });

  _exports.default = _default;
});