define("funnelytics/components/collapsible-panel", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    targetId: '',
    title: '',
    subtitle: '',
    openOrOpening: true,
    startHidden: true,
    startHiddenStyle: '',
    onClickBack: function onClickBack() {},
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('startHidden')) {
        this.set('startHiddenStyle', Ember.String.htmlSafe('display: none;'));
        this.set('openOrOpening', false);
      } else {
        this.set('startHiddenStyle', Ember.String.htmlSafe(''));
      }

      this.set('targetId', (0, _emberUuid.v4)());
    },
    actions: {
      toggleCollapse: function toggleCollapse() {
        this.toggleProperty('openOrOpening');
        Ember.$("#".concat(this.get('targetId'))).slideToggle();
      },
      backAction: function backAction() {
        var backHandler = this.get('onClickBack');
        backHandler();
      }
    }
  });

  _exports.default = _default;
});