define("funnelytics/routes/dashboard/icons/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.get('store').find('icon_folder', params.id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('pending', []);
      controller.set('saving', false);
      controller.set('saved', false);
      controller.set('hovering', false);
    },
    actions: {
      willTransition: function willTransition() {
        var pending = this.get('controller.pending'),
            remove = [];
        pending.forEach(function (icon) {
          if (icon.record.get('isNew')) {
            icon.record.destroyRecord();
            remove.push(icon);
          }
        });
        pending.removeObjects(remove);
        return true;
      }
    }
  });

  _exports.default = _default;
});