define("funnelytics/templates/components/canvas-elements/entity-toolbar/arrowhead-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7hbuCciJ",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"class\",[28,[\"\\n      fl-btn--toolbar\\n      entity-toolbar__arrowhead-picker-option \\n      \",[27,\"if\",[[27,\"eq\",[[23,[\"currentArrowhead\"]],\"none\"],null],\"entity-toolbar__arrow-picker-option--selected\",\"\"],null],\"\\n    \"]]],[3,\"action\",[[22,0,[]],\"selectArrowhead\",\"none\"]],[9],[0,\"\\n    \"],[1,[27,\"svg-display\",null,[[\"symbolDefId\"],[\"icon-no-arrowhead\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"button\"],[12,\"class\",[28,[\"\\n      fl-btn--toolbar\\n      entity-toolbar__arrowhead-picker-option \\n      \",[27,\"if\",[[27,\"eq\",[[23,[\"currentArrowhead\"]],\"default\"],null],\"entity-toolbar__arrow-picker-option--selected\",\"\"],null],\"\\n    \"]]],[3,\"action\",[[22,0,[]],\"selectArrowhead\",\"default\"]],[9],[0,\"\\n    \"],[1,[27,\"svg-display\",null,[[\"symbolDefId\"],[\"icon-default-arrowhead\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"button\"],[12,\"class\",[28,[\"\\n      fl-btn--toolbar\\n      entity-toolbar__arrowhead-picker-option \\n      \",[27,\"if\",[[27,\"eq\",[[23,[\"currentArrowhead\"]],\"solid\"],null],\"entity-toolbar__arrow-picker-option--selected\",\"\"],null],\"\\n    \"]]],[3,\"action\",[[22,0,[]],\"selectArrowhead\",\"solid\"]],[9],[0,\"\\n    \"],[1,[27,\"svg-display\",null,[[\"symbolDefId\"],[\"icon-solid-arrowhead\"]]],false],[0,\"\\n  \"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/entity-toolbar/arrowhead-picker.hbs"
    }
  });

  _exports.default = _default;
});