define("funnelytics/helpers/progress-bar-values", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.progressBarValues = progressBarValues;
  _exports.default = void 0;

  function progressBarValues(params
  /*, hash*/
  ) {
    return params[0] / params[1] * 100;
  }

  var _default = Ember.Helper.helper(progressBarValues);

  _exports.default = _default;
});