define("funnelytics/custom/analytics/analytics/PartialRequests", ["exports", "lodash", "ember-uuid", "funnelytics/custom/analytics/analytics/PartialRequestOptions"], function (_exports, _lodash, _emberUuid, _PartialRequestOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var TIME_BETWEEN_REQUESTS = 3000; // ms

  var PartialRequests = Ember.Object.extend({
    // Elements we are combining to send out when making requests
    elementsToQuery: [],
    // Requests we are waiting for and will accept
    requestsInProgress: [],
    // Consumer must dictate what to do when request is ready.
    onRequestReady: null,
    readyRequestDebounce: _lodash.default.debounce(function () {}, TIME_BETWEEN_REQUESTS),
    init: function init() {
      var _this = this;

      if (!_lodash.default.isFunction(this.get('onRequestReady'))) {
        throw new Error('Must provide onRequestReady handler.');
      }

      this.set('readyRequestDebounce', _lodash.default.debounce(function (options) {
        return _this.getPartialRequest(options);
      }, TIME_BETWEEN_REQUESTS));
    },
    getPartialRequest: function getPartialRequest(options) {
      var elementsToQuery = this.get('elementsToQuery');

      if (_lodash.default.isEmpty(elementsToQuery)) {
        return;
      }

      this.set('elementsToQuery', []);
      var requestId = (0, _emberUuid.v4)();
      var requestsInProgress = this.get('requestsInProgress');
      requestsInProgress.pushObject(requestId);
      this.get('onRequestReady')({
        elementsToQuery: elementsToQuery,
        requestId: requestId
      }, options);
    },
    requestStatsForId: function requestStatsForId(id, options) {
      if (!(options instanceof _PartialRequestOptions.default)) {
        throw new Error('Options must be an instance of PartialRequestOptions');
      }

      var elements = this.get('elementsToQuery');
      elements.pushObject(id);

      if (options.getReloadAutomatically()) {
        this.get('readyRequestDebounce')(options);
      }
    },
    requestIsValid: function requestIsValid(id) {
      var requestIsValid = this.get('requestsInProgress').any(function (request) {
        return request === id;
      });
      return requestIsValid;
    },
    markRequestComplete: function markRequestComplete(id) {
      this.get('requestsInProgress').removeObject(id);
    },
    // Do send out any more partial requests.
    clearPartialRequests: function clearPartialRequests() {
      this.set('elementsToQuery', []);
      this.set('requestsInProgress', []);
      this.get('readyRequestDebounce').cancel();
    }
  });
  var _default = PartialRequests;
  _exports.default = _default;
});