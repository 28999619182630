define("funnelytics/custom/constants/PlanConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PlanConstants = Object.freeze({
    STARTER_PROJECTS: 0,
    PRO_PROJECTS: 2
  });
  var _default = PlanConstants;
  _exports.default = _default;
});