define("funnelytics/components/dashboard-elements/project-navbar-v2", ["exports", "funnelytics/custom/constants/MembershipConstants", "funnelytics/custom/constants", "funnelytics/mixins/memberships", "funnelytics/mixins/permissions"], function (_exports, _MembershipConstants, _constants, _memberships, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_permissions.default, _memberships.default, {
    versionManager: Ember.inject.service(),
    workspaceSelector: Ember.inject.service(),
    router: Ember.inject.service(),
    projectPermissions: Ember.inject.service(),
    userDetails: Ember.inject.service(),
    store: Ember.inject.service(),
    hasIntegration: Ember.computed('workspaceId', 'projectPermissions.updateStateTracker', function () {
      var hasIntegrationAccess = this.projectPermissions.hasPermission(this.workspaceId, this.IS_INTEGRATION_ALLOWED);
      var hasWebhookIntegration = this.projectPermissions.hasPermission(this.workspaceId, this.IS_WEBHOOK_ALLOWED);
      var hasZapierIntegration = this.projectPermissions.hasPermission(this.workspaceId, this.IS_ZAPIER_ALLOWED);
      return hasIntegrationAccess && hasWebhookIntegration && hasZapierIntegration;
    }),
    notUnorgPrj: Ember.computed('router.currentRouteName', function () {
      return this.get('router.currentRouteName') !== 'dashboard.projects.project.unorganized';
    }),
    workspaceId: Ember.computed('workspaceSelector.selectedV2Workspace.id', 'userDetails.membershipId', 'isLimitedWithoutCollaboration', function () {
      // Limited
      if ((this.get('isLimitedWithoutCollaboration') || this.get('userDetails.membershipId') === _MembershipConstants.MEMBERSHIP_CODES.Limited) && this.get('workspaceSelector.selectedV2Workspace.locked')) {
        return this.get('workspaceSelector.myDefaultWorkspace.id');
      }

      return this.get('workspaceSelector.selectedV2Workspace.id');
    }),
    isMapPlusUser: Ember.computed('projectPermissions.membership', function () {
      return this.projectPermissions.hasMembership(_MembershipConstants.Membership.isPerformanceStarter);
    }),
    hasCollabAccess: Ember.computed('projectPermissions.membership', 'versionManager.activeDashboardVersion', 'projectPermissions.updateStateTracker', 'workspaceId', function () {
      var collaborationAllowed = this.projectPermissions.getProjectPermission(this.workspaceId, this.IS_COLLABORATION_ALLOWED);
      return collaborationAllowed;
    }),
    userIsCollaborator: Ember.computed('projectPermissions.membership', 'versionManager.activeDashboardVersion', 'projectPermissions.updateStateTracker', 'workspaceId', function () {
      var isCollaborator = this.projectPermissions.getProjectPermission(this.workspaceId, this.IS_COLLABORATOR);
      return isCollaborator;
    }),
    isUnorganised: Ember.computed('workspaceId', function () {
      return this.workspaceId === _constants.NULL_PROJECT;
    }),
    isLockedProject: Ember.computed('workspaceId', function () {
      return this.store.peekRecord('project', this.workspaceId).get('is_locked');
    }),
    shouldDisplayAdditionalLinks: Ember.computed('isUnorganised', 'isLocked', 'userIsCollaborator', function () {
      return !this.isUnorganised && !this.isLockedProject && !this.userIsCollaborator;
    })
  });

  _exports.default = _default;
});