define("funnelytics/custom/constants/DeferredActionConstants", ["exports", "funnelytics/custom/constants/AcknowledgementConstants"], function (_exports, _AcknowledgementConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DeferredActionConstants = Object.freeze({
    FLOW: _AcknowledgementConstants.default.FLOW,
    FOCUS: _AcknowledgementConstants.default.FOCUS,
    FORECAST: _AcknowledgementConstants.default.FORECAST,
    KPI: _AcknowledgementConstants.default.KPI,
    MAPPING: _AcknowledgementConstants.default.MAPPING,
    NUMBERS: _AcknowledgementConstants.default.NUMBERS,
    PEOPLE: _AcknowledgementConstants.default.PEOPLE,
    TRACKING: _AcknowledgementConstants.default.TRACKING,
    PREVIOUS_STEPS: 'previous-steps-deferred-action'
  });
  var _default = DeferredActionConstants;
  _exports.default = _default;
});