define("funnelytics/helpers/date-parser", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateParser = dateParser;
  _exports.default = void 0;

  function dateParser(params) {
    if (params[1] === 'hide null' && !params[0]) {
      return null;
    }

    var format = 'YYYY-MM-DD, hh:mm A';

    if (params[2]) {
      format = params[2];
    }

    var formatedDate = (0, _moment.default)(params[0]).format(format);
    return formatedDate;
  }

  var _default = Ember.Helper.helper(dateParser);

  _exports.default = _default;
});