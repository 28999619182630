define("funnelytics/services/canvas-service", ["exports", "lodash", "@funnelytics/utilities", "funnelytics/custom/analytics/analytics/canvas/CanvasEntityManager", "funnelytics/custom/constants/CanvasConstants", "funnelytics/custom/canvas/managers/ConnectionValidator", "funnelytics/custom/analytics/analytics/canvas/CanvasEntity", "funnelytics/custom/analytics/analytics/PartialRequestOptions", "funnelytics/custom/analytics/analytics/commerce/CommerceConstants", "funnelytics/custom/analytics/settings/config/standard-action-names-mapping"], function (_exports, _lodash, _utilities, _CanvasEntityManager, _CanvasConstants, _ConnectionValidator, _CanvasEntity, _PartialRequestOptions, _CommerceConstants, _standardActionNamesMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Permissions = _utilities.constants.Permissions;

  var _default = Ember.Service.extend({
    canvasTextEditor: Ember.inject.service(),
    goalManager: Ember.inject.service(),
    analyticsService: Ember.inject.service(),
    userPermissions: Ember.inject.service(),
    commandService: Ember.inject.service(),
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),
    isEditorClosed: Ember.computed('editorSettings.type', function () {
      return !this.get('editorSettings.type');
    }),
    isStepSettingsEditorOpen: Ember.computed.equal('editorSettings.type', _CanvasConstants.default.EDITOR_TYPE_STEP_SETTINGS),
    isStepNotesEditorOpen: Ember.computed.equal('editorSettings.type', _CanvasConstants.default.EDITOR_TYPE_STEP_NOTES),
    isNotesEditorExpanded: Ember.computed.bool('editorSettings.isExpanded'),
    isCanvasSettingsEditorOpen: Ember.computed.equal('editorSettings.type', _CanvasConstants.default.EDITOR_TYPE_CANVAS_SETTINGS),
    isCanvasNotesEditorOpen: Ember.computed.equal('editorSettings.type', _CanvasConstants.default.EDITOR_TYPE_CANVAS_NOTES),
    editorEntity: Ember.computed.alias('editorSettings.entity'),
    isDraggingLink: Ember.computed.readOnly('_isDraggingLink'),
    hasImageEntityToUpdate: Ember.computed.bool('_updateImageEntity'),
    //* From now on, this should be the preferred way of keeping track of this detail, avoid 'type' on funnel-editor
    isFunnel: Ember.computed.equal('_canvasType', _CanvasConstants.default.CANVAS_TYPE_FUNNEL),
    isTemplate: Ember.computed.equal('_canvasType', _CanvasConstants.default.CANVAS_TYPE_TEMPLATE),
    hasEditPermission: Ember.computed.readOnly('_permissions.hasEditPermission'),
    hasAnalyticsReadPermission: Ember.computed.readOnly('_permissions.hasAnalyticsReadPermissions'),
    hasForecastReadPermission: Ember.computed.readOnly('_permissions.hasForecastReadPermissions'),
    funnelIsInProject: Ember.computed.readOnly('_funnelIsInProject'),
    canReadAnalytics: Ember.computed.and('funnelIsInProject', 'hasAnalyticsReadPermission'),
    canReadForecast: Ember.computed.and('funnelIsInProject', 'hasForecastReadPermission'),
    isTextEditorOpen: Ember.computed.readOnly('canvasTextEditor.isTextEditorOpen'),
    lastOpenedTextEntityId: Ember.computed.readOnly('canvasTextEditor.lastOpenedEntityId'),
    selectedLineX: Ember.computed.readOnly('_selectedLineX'),
    selectedLineY: Ember.computed.readOnly('_selectedLineY'),
    loadFunnelPermissions: function loadFunnelPermissions(_ref) {
      var _this = this;

      var funnelId = _ref.funnelId,
          _ref$isTemplate = _ref.isTemplate,
          isTemplate = _ref$isTemplate === void 0 ? false : _ref$isTemplate,
          _ref$isReload = _ref.isReload,
          isReload = _ref$isReload === void 0 ? false : _ref$isReload;
      return Ember.RSVP.Promise.resolve(true).then(function () {
        if (isTemplate) {
          return Ember.RSVP.Promise.resolve(true).then(function () {
            var permissions = {
              hasEditPermission: true,
              hasForecastReadPermissions: false,
              hasAnalyticsReadPermissions: false
            };

            _this.set('_permissions', permissions);

            return permissions;
          });
        }

        if (isReload) {
          _this.get('userPermissions').clearPermissionCache();
        }

        return Ember.RSVP.hash({
          hasEditPermission: _this.get('userPermissions').hasPermission({
            permission: Permissions.TYPE_STARTER,
            scope: Permissions.SCOPE_FUNNEL,
            scopeId: funnelId,
            accessLevel: Permissions.ACCESS_LEVEL_WRITE
          }),
          hasForecastReadPermissions: _this.get('userPermissions').hasPermission({
            permission: Permissions.TYPE_FORECAST,
            scope: Permissions.SCOPE_FUNNEL,
            scopeId: funnelId,
            accessLevel: Permissions.ACCESS_LEVEL_READ
          }),
          hasAnalyticsReadPermissions: _this.get('userPermissions').hasPermission({
            permission: Permissions.TYPE_ANALYTICS,
            scope: Permissions.SCOPE_FUNNEL,
            scopeId: funnelId,
            accessLevel: Permissions.ACCESS_LEVEL_READ
          })
        }).then(function (_ref2) {
          var hasEditPermission = _ref2.hasEditPermission,
              hasForecastReadPermissions = _ref2.hasForecastReadPermissions,
              hasAnalyticsReadPermissions = _ref2.hasAnalyticsReadPermissions;

          _this.set('_permissions', {
            hasEditPermission: hasEditPermission,
            hasForecastReadPermissions: hasForecastReadPermissions,
            hasAnalyticsReadPermissions: hasAnalyticsReadPermissions
          });

          return {
            hasEditPermission: hasEditPermission,
            hasForecastReadPermissions: hasForecastReadPermissions,
            hasAnalyticsReadPermissions: hasAnalyticsReadPermissions
          };
        });
      });
    },
    getEntityManager: function getEntityManager() {
      var entityManager = this.get('_entityManager'); // TODO: Check what happens when you leave funnel-editor and have ongoing tasks... no unrecoverable errors?

      if (!entityManager) {
        throw new Error('Ensure entityManager is available before attempting to use it.');
      }

      return entityManager;
    },
    getPaperJqueryNode: function getPaperJqueryNode() {
      var paperJqueryNode = this.get('_paperJqueryNode');

      if (!paperJqueryNode || paperJqueryNode.length !== 1) {
        throw new Error('Ensure paperJqueryNode is available before attempting to use it.');
      }

      return paperJqueryNode;
    },
    resetAllProperties: function resetAllProperties() {
      this.setProperties({
        _entityManager: null,
        _paperJqueryNode: null,
        keyboardShortcutsEnabled: true,
        selectedEntity: null,
        areMultipleSelected: false,
        // Used to keep track of whether we need to reposition the entity toolbar
        toolbarPositionWatcher: 'toolbarPositionWatcher',
        isEntityToolbarVisible: false,
        _selectionBoxNode: null,
        _updateImageEntity: null,
        updateImageNotifier: 'updateImageNotifier',
        isAnalyticsPanelOpen: false,
        _isBuildingAnalytics: false,
        _isDraggingLink: false,
        editorSettings: null,
        _canvasType: null,
        _funnelIsInProject: false,
        panelType: null
      });
    },
    getEntityCreator: function getEntityCreator() {
      return this._canvasEntityCreator;
    },
    wrapEntity: function wrapEntity(cell) {
      return this.getEntityCreator().wrapEntity(cell);
    },
    createEmptyEntity: function createEmptyEntity() {
      return this.getEntityCreator().createEmpty();
    },
    createConnection: function createConnection() {
      var _this$getEntityCreato;

      return (_this$getEntityCreato = this.getEntityCreator()).createConnection.apply(_this$getEntityCreato, arguments);
    },
    createElement: function createElement() {
      var _this$getEntityCreato2;

      return (_this$getEntityCreato2 = this.getEntityCreator()).createElement.apply(_this$getEntityCreato2, arguments);
    },
    createGhostLine: function createGhostLine() {
      var _this$getEntityCreato3;

      return (_this$getEntityCreato3 = this.getEntityCreator()).createGhostLine.apply(_this$getEntityCreato3, arguments);
    },
    createCustomImage: function createCustomImage() {
      var _this$getEntityCreato4;

      return (_this$getEntityCreato4 = this.getEntityCreator()).createCustomImage.apply(_this$getEntityCreato4, arguments);
    },
    getTrackableJSON: function getTrackableJSON() {
      var _this$getEntityCreato5;

      return (_this$getEntityCreato5 = this.getEntityCreator()).getTrackableJSON.apply(_this$getEntityCreato5, arguments);
    },
    createTextBox: function createTextBox() {
      var _this$getEntityCreato6;

      return (_this$getEntityCreato6 = this.getEntityCreator()).createTextBox.apply(_this$getEntityCreato6, arguments);
    },
    createShapeRaw: function createShapeRaw() {
      var _this$getEntityCreato7;

      return (_this$getEntityCreato7 = this.getEntityCreator()).createShapeRaw.apply(_this$getEntityCreato7, arguments);
    },
    initializeCanvasService: function initializeCanvasService(_ref3) {
      var canvasEntityCreator = _ref3.canvasEntityCreator,
          entityManager = _ref3.entityManager,
          paperJqueryNode = _ref3.paperJqueryNode,
          canvasType = _ref3.canvasType,
          funnelIsInProject = _ref3.funnelIsInProject,
          paper = _ref3.paper;

      _utilities.Assertion.instanceOf(entityManager, _CanvasEntityManager.default);

      _utilities.Assertion.instanceOf(paperJqueryNode, Ember.$);

      _utilities.Assertion.validString(canvasType, _CanvasConstants.default.VALID_CANVAS_TYPES);

      this.resetAllProperties();
      this.setProperties({
        _canvasEntityCreator: canvasEntityCreator,
        _entityManager: entityManager,
        _paperJqueryNode: paperJqueryNode,
        _canvasType: canvasType,
        _funnelIsInProject: funnelIsInProject,
        canvasServicePaper: paper
      });
    },
    fromGraphPointToHtmlPoint: function fromGraphPointToHtmlPoint(point) {
      return this.getEntityManager().fromGraphPointToHtmlPoint(point);
    },
    notifyToolbarPositionChange: function notifyToolbarPositionChange() {
      this.notifyPropertyChange('toolbarPositionWatcher');
    },
    showEntityToolbar: function showEntityToolbar() {
      this.set('isEntityToolbarVisible', true);
    },
    hideEntityToolbar: function hideEntityToolbar() {
      this.set('isEntityToolbarVisible', false);
    },
    tearDownCanvasService: function tearDownCanvasService() {
      this.resetAllProperties();
    },
    openAnalyticsPanel: function openAnalyticsPanel() {
      this.set('isAnalyticsPanelOpen', true);
    },
    closeAnalyticsPanel: function closeAnalyticsPanel() {
      this.set('isAnalyticsPanelOpen', false);
      this.set('panelType', null);
      this.closeEditor();
    },
    closeEditor: function closeEditor() {
      this.set('editorSettings', null);
      this.get('goalManager').cleanUpAfterGoalEditing();
    },
    getEntityView: function getEntityView(entity) {
      return this.getEntityManager().getView(entity);
    },
    getEntityViewId: function getEntityViewId(entity) {
      return _lodash.default.get(this.getEntityView(entity), ['id']);
    },
    openStepSettingsPanel: function openStepSettingsPanel(entity) {
      var view = this.getEntityView(entity);
      this.set('editorSettings', {
        type: _CanvasConstants.default.EDITOR_TYPE_STEP_SETTINGS,
        cell: view,
        entity: entity,
        attrs: entity.getAttrs()
      });
      this.openAnalyticsPanel();
    },
    closeStepSettingsPanel: function closeStepSettingsPanel() {
      this.closeAnalyticsPanel();
    },
    toggleStepSettingsPanel: function toggleStepSettingsPanel(entity) {
      if (this.get('isStepSettingsEditorOpen')) {
        this.closeAnalyticsPanel();
        return;
      }

      this.openStepSettingsPanel(entity);
    },
    openFunnelNotes: function openFunnelNotes() {
      this.closeAnalyticsPanel();
      this.set('editorSettings', {
        type: _CanvasConstants.default.EDITOR_TYPE_CANVAS_NOTES
      });
    },
    closeFunnelNotes: function closeFunnelNotes() {
      this.closeAnalyticsPanel();
    },
    openCanvasSettings: function openCanvasSettings() {
      this.closeAnalyticsPanel();
      this.set('editorSettings', {
        type: _CanvasConstants.default.EDITOR_TYPE_CANVAS_SETTINGS
      });
    },
    closeCanvasSettings: function closeCanvasSettings() {
      this.closeAnalyticsPanel();
    },
    openStepNotes: function openStepNotes(entity) {
      this.closeAnalyticsPanel();
      var view = this.getEntityView(entity);
      this.set('editorSettings', {
        type: _CanvasConstants.default.EDITOR_TYPE_STEP_NOTES,
        cell: view,
        entity: entity,
        attrs: entity.getAttrs(),
        isExpanded: false
      });
    },
    setPanelType: function setPanelType(type) {
      this.set('panelType', type);
    },
    getPanelType: function getPanelType() {
      return this.get('panelType');
    },
    expandStepNotesEditor: function expandStepNotesEditor() {
      if (this.get('isStepSettingsEditorOpen')) {
        this.set('editorSettings.isExpanded', true);
      }
    },
    collapseStepNotesEditor: function collapseStepNotesEditor() {
      if (this.get('isStepSettingsEditorOpen')) {
        this.set('editorSettings.isExpanded', false);
      }
    },
    closeStepNotes: function closeStepNotes() {
      this.closeAnalyticsPanel();
    },
    setIsBuildingAnalytics: function setIsBuildingAnalytics(isBuilding) {
      this.set('_isBuildingAnalytics', isBuilding);
    },
    isBuildingAnalytics: function isBuildingAnalytics() {
      return this.get('_isBuildingAnalytics');
    },
    getPaper: function getPaper() {
      return this.get('canvasServicePaper');
    },
    addStep: function addStep(type, explicitLabel, image) {
      var keyIdentifier = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      var entityParameters = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
      var forceIgnoreInBetween = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
      var menu = this.getPaper().getContextMenu();
      var position = menu.getPosition();
      var center = this.getEntityManager().getCurrentCenter(); //! We should change this to be more maintable:

      var typeForEntity = _CanvasConstants.default["TYPE_".concat(_lodash.default.upperCase(type), "_NAME")];

      var newEntity = this.createElement({
        label: explicitLabel || _lodash.default.upperFirst(type),
        image: image,
        type: typeForEntity,
        x: position.x !== null ? position.x : center.x,
        y: position.y !== null ? position.y : center.y
      });
      this.getEntityManager().addToCanvas(newEntity); //! This whole block must be refactored if we're going to continue on here:

      if (newEntity.getType() === _CanvasConstants.default.TYPE_PAGE && keyIdentifier !== null) {
        newEntity.setUrl(keyIdentifier);
        this.get('analyticsService').queueStatsForElement(newEntity.getID());
      } else if (newEntity.getType() === _CanvasConstants.default.TYPE_EVENT && keyIdentifier !== null) {
        newEntity.setName(keyIdentifier);

        if (keyIdentifier === _CommerceConstants.default.ACTION.DATABASE_NAME) {
          newEntity.setActionType(_CanvasConstants.default.ACTION_TYPE_COMMERCE);

          var userFriendlyLabel = _lodash.default.get(_standardActionNamesMapping.default, [keyIdentifier]);

          newEntity.setLabel(userFriendlyLabel);
        }

        this.get('analyticsService').queueStatsForElement(newEntity.getID());
      }

      newEntity.replaceAllAttributes(entityParameters);

      if (newEntity.getType() === _CanvasConstants.default.TYPE_SOURCE && entityParameters.length > 0) {
        this.get('analyticsService').queueStatsForElement(newEntity.getID());
      }

      var contextEntity = menu.getInitialEntity();
      var sourceEntity = menu.isInitialEntityATarget() ? newEntity : contextEntity;
      var targetEntity = menu.isInitialEntityATarget() ? contextEntity : newEntity;

      if (contextEntity) {
        if (menu.getInitialEntityLink()) {
          menu.getInitialEntityLink().setSource({
            id: sourceEntity.getID()
          });
          menu.getInitialEntityLink().setTarget({
            id: targetEntity.getID()
          });

          if (forceIgnoreInBetween || !_ConnectionValidator.default.canMakeConnectionBetween(sourceEntity, targetEntity) && _ConnectionValidator.default.canMakeGhostLineBetween(sourceEntity, targetEntity)) {
            this.getEntityManager().convertLineToGhostLine(menu.getInitialEntityLink());
          } else if (!_ConnectionValidator.default.canMakeLineBetween(sourceEntity, targetEntity)) {
            menu.getInitialEntityLink().remove();
          }

          if (forceIgnoreInBetween) {
            var lineToConvert = null;

            if (sourceEntity.isDependent()) {
              lineToConvert = sourceEntity.getDependentSourceConnection();
            } else if (targetEntity.isDependent()) {
              lineToConvert = targetEntity.getDependentTargetConnection();
            }

            if (lineToConvert) {
              this.getEntityManager().convertLineToGhostLine(lineToConvert);
            }
          }
        } else {
          var link;

          if (_ConnectionValidator.default.canMakeConnectionBetween(sourceEntity, targetEntity)) {
            link = this.createConnection({
              source: {
                id: sourceEntity.getID()
              },
              target: {
                id: targetEntity.getID()
              }
            });
          } else if (_ConnectionValidator.default.canMakeGhostLineBetween(sourceEntity, targetEntity)) {
            link = this.createGhostLine({
              source: {
                id: sourceEntity.getID()
              },
              target: {
                id: targetEntity.getID()
              }
            });
          }

          this.getEntityManager().addToCanvas(link);
        }

        this.getEntityManager().select([newEntity]);
      } // Shift position of contextEntity to recalculate link endpoints - M.Naik.


      if (contextEntity && contextEntity.getType() === _CanvasConstants.default.TYPE_EVENT) {
        contextEntity.setPositionX(contextEntity.getPosition().x + 1);
        contextEntity.setPositionX(contextEntity.getPosition().x - 1);
      }

      menu.setVisible(false);
    },
    getSelectionBoxNode: function getSelectionBoxNode() {
      var storedNode = this.get('_selectionBoxNode');

      if (storedNode) {
        return storedNode;
      }

      var queriedNode = Ember.$('#paper .joint-selection .selection-wrapper')[0];
      this.set('_selectionBoxNode', queriedNode);
      return queriedNode;
    },

    /**
     * Image updating
     */
    updateImageFor: function updateImageFor(entity) {
      this.set('_updateImageEntity', entity);
      this.notifyPropertyChange('updateImageNotifier');
    },
    getImageUpdateEntity: function getImageUpdateEntity() {
      return this.get('_updateImageEntity');
    },
    clearImageUpdate: function clearImageUpdate() {
      this.set('_updateImageEntity', null);
    },

    /**
     * END Image updating
     */
    startLinking: function startLinking() {
      this.set('_isDraggingLink', true);
    },
    stopLinking: function stopLinking() {
      this.set('_isDraggingLink', false);
    },
    unsetSelectedEntity: function unsetSelectedEntity() {
      this.set('selectedEntity', null);
    },
    selectEntity: function selectEntity(entity) {
      _utilities.Assertion.instanceOf(entity, _CanvasEntity.default);

      this.set('selectedEntity', entity);
    },
    getLineClickPosition: function getLineClickPosition() {
      return {
        x: this.get('selectedLineX'),
        y: this.get('selectedLineY')
      };
    },

    /**
     * This is not a true selection but is used to identify lines for the purpose of presenting the line entity toolbar
     */
    selectLine: function selectLine(entity) {
      var _ref4 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref4$x = _ref4.x,
          x = _ref4$x === void 0 ? undefined : _ref4$x,
          _ref4$y = _ref4.y,
          y = _ref4$y === void 0 ? undefined : _ref4$y;

      var currentlySelectedEntity = this.get('selectedEntity');
      this.set('_selectedLineX', x);
      this.set('_selectedLineY', y);
      var manager = this.getEntityManager(); // TODO: refactor to use this everywhere:

      manager.unselectAll();

      if (currentlySelectedEntity && currentlySelectedEntity.getID() === entity.getID()) {
        return;
      }

      this.selectEntity(entity);
    },
    getAllPageEntities: function getAllPageEntities() {
      return this.getEntityManager().getAllPageEntities();
    },
    getAllTrafficEntities: function getAllTrafficEntities() {
      return this.getEntityManager().getAllTrafficEntities();
    },
    getAllEventEntities: function getAllEventEntities() {
      return this.getEntityManager().getAllEventEntities();
    },
    addUtmSourceToMap: function addUtmSourceToMap() {
      var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          utms = _ref5.utms,
          targetEntity = _ref5.targetEntity,
          x = _ref5.x,
          y = _ref5.y;

      this.get('commandService').startBatchCommand();
      var xPosition = 0;
      var yPosition = 0;
      var offsetY = 0;

      if (targetEntity) {
        xPosition = targetEntity.getX() - 200;
        yPosition = targetEntity.getY();
        offsetY = targetEntity.getHeight();
      }

      xPosition = x || xPosition;
      yPosition = y || yPosition;
      var elementLabel = utms.reduce(function (labelString, entityParameter) {
        var glue = '';

        if (labelString !== '') {
          glue = '&';
        }

        var utmToAppend = (entityParameter.isContains() ? '' : 'NO ') + entityParameter.getKey() + (entityParameter.getValue() ? "=".concat(entityParameter.getValue()) : '');
        return "".concat(labelString).concat(glue).concat(utmToAppend);
      }, '');
      var newEntity = this.getEntityCreator().createElement({
        label: elementLabel,
        type: _CanvasConstants.default.TYPE_SOURCE_NAME,
        x: xPosition,
        y: yPosition
      });
      newEntity.setPositionY(newEntity.getY() + (offsetY - newEntity.getHeight()) / 2);
      newEntity.setAttributes(utms);
      this.getEntityManager().addToCanvas(newEntity);

      if (targetEntity) {
        var linkEntity = this.getEntityCreator().createConnection({
          source: {
            id: newEntity.getID()
          },
          target: {
            id: targetEntity.getID()
          }
        });
        this.getEntityManager().addToCanvas(linkEntity);
      }

      this.get('commandService').endBatchCommand();
      this.get('analyticsService').queueStatsForElement(newEntity.getID(), new _PartialRequestOptions.default({
        includeReferrers: false
      }));
    }
  });

  _exports.default = _default;
});