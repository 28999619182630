define("funnelytics/templates/components/containers/funnel-editor/components/right-sidebar-simple-action-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q0UOJ+RX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"rs-event-switch\"],[9],[0,\"\\n  \"],[7,\"a\"],[12,\"class\",[28,[\"rs-event-switch__button \",[27,\"if\",[[23,[\"isSimpleAction\"]],\"rs-event-switch__button-active\",\"\"],null]]]],[11,\"href\",\"#\"],[3,\"action\",[[22,0,[]],\"setSimple\"]],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      Simple\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"a\"],[12,\"class\",[28,[\"rs-event-switch__button \",[27,\"if\",[[23,[\"isAdvancedAction\"]],\"rs-event-switch__button-active\",\"\"],null]]]],[11,\"href\",\"#\"],[3,\"action\",[[22,0,[]],\"setAdvanced\"]],[9],[0,\"\\n    \"],[7,\"span\"],[9],[0,\"\\n      Advanced\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/containers/funnel-editor/components/right-sidebar-simple-action-switch.hbs"
    }
  });

  _exports.default = _default;
});