define("funnelytics/templates/dashboard/icons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "48Anug8g",
    "block": "{\"symbols\":[\"permitted\"],\"statements\":[[7,\"div\"],[11,\"class\",\"col-md-12 fl--mt--2 icons-panel\"],[9],[0,\"\\n\"],[4,\"permissions/has-permission\",null,[[\"showLoadingDots\",\"permission\"],[true,\"feature.icons\"]],{\"statements\":[[4,\"if\",[[22,1,[]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"clearfix mb-4\"],[9],[0,\"\\n        \"],[7,\"ul\"],[11,\"class\",\"nav nav-tabs justify-content-end pb-2\"],[9],[0,\"\\n          \"],[7,\"li\"],[11,\"class\",\"nav-item ml-3 d-flex align-items-center\"],[9],[0,\"\\n            \"],[4,\"link-to\",[\"dashboard.icons.folders.new\"],[[\"class\"],[\"btn btn-primary btn-sm\"]],{\"statements\":[[0,\"Create Folder\"]],\"parameters\":[]},null],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n          \"],[1,[21,\"outlet\"],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"fl-card fl--p--3 bkg--white wd--60 txt--darkgrey fl--m--auto fl--font--300 fl--alt--font fl--ml--font \"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"upgrade-header\"],[9],[0,\"\\n        Upload custom icon sets with Funnelytics Pro\\n        \"],[7,\"div\"],[11,\"class\",\"fl--s--font\"],[9],[0,\"\\n          Join Funnelytics Pro and increase the profitability of your sales funnels, today.\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"fl--m--auto\"],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"href\",\"https://funnelytics.io/discover\"],[11,\"class\",\"fl-hero-btn upgrade-banner-button bkg--green fl--sm--font\"],[11,\"target\",\"_blank\"],[9],[0,\"\\n          Join Pro\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/dashboard/icons.hbs"
    }
  });

  _exports.default = _default;
});