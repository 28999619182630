define("funnelytics/components/canvas-elements/entity-toolbar/untrackable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Actions that need to be passed into the component:
     */
    onClickSettings: function onClickSettings() {},
    onClickContext: function onClickContext() {},
    actions: {
      clickSettings: function clickSettings() {
        var settingsHandler = this.get('onClickSettings');
        settingsHandler();
      },
      clickContext: function clickContext() {
        var contextHandler = this.get('onClickContext');
        contextHandler();
      }
    }
  });

  _exports.default = _default;
});