define("funnelytics/custom/analytics/settings/MetricConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var KEYS = Object.freeze({
    ID: 'id',
    NAME: 'name',
    IS_SELECTED: 'selected',
    IS_TOGGLEABLE: 'toggleable'
  });

  var _default = Object.freeze({
    TOTAL_SALES: {
      ID: 'total-sales',
      NAME: 'Total sales'
    },
    TOTAL_REVENUE: {
      ID: 'total-revenue',
      NAME: 'Total revenue'
    },
    TOTAL_CUSTOMERS: {
      ID: 'total-customers',
      NAME: 'Total customers'
    },
    AVERAGE_ORDER_VALUE: {
      ID: 'avg-order-value',
      NAME: 'Avg. order value'
    },
    AVERAGE_PER_CUSTOMER: {
      ID: 'avg-per-customer',
      NAME: 'Avg. value per customer'
    },
    AVERAGE_PER_ORDER: {
      ID: 'avg-per-order',
      NAME: 'Avg. products per order'
    },
    TRIGGER_COUNT: {
      ID: 'trigger-count',
      NAME: 'Number of triggers'
    },
    KEYS: KEYS
  });

  _exports.default = _default;
});