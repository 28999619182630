define("funnelytics/templates/dashboard/projects/project/unorganized", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LgNXH0zi",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"scrollable-container\"],[11,\"style\",\"margin: 0 3rem;\"],[9],[0,\"\\n  \"],[1,[27,\"funnel-list\",null,[[\"funnels\",\"projects\",\"canEditFunnels\",\"isFunnelAdmin\",\"currentProjectId\",\"onDelete\",\"onCompleteSave\",\"accessToNewVersion\",\"projectVersion\"],[[23,[\"model\",\"funnels\"]],[23,[\"model\",\"projects\"]],true,true,[23,[\"NULL_PROJECT\"]],[27,\"action\",[[22,0,[]],\"refreshRouteFromController\"],null],[27,\"action\",[[22,0,[]],\"transitionToRouteFromController\"],null],[23,[\"model\",\"accessToNewVersion\"]],1]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/dashboard/projects/project/unorganized.hbs"
    }
  });

  _exports.default = _default;
});