define("funnelytics/custom/utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sanitizeXMLString = void 0;

  var sanitizeXMLString = function sanitizeXMLString(xmlString) {
    // remove everything forbidden by XML 1.0 specifications, plus the unicode replacement character U+FFFD
    // eslint-disable-next-line no-control-regex
    var regex = /((?:[\0-\x08\x0B\f\x0E-\x1F\uFFFD\uFFFE\uFFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF]))/g;
    return (xmlString || '').replace(regex, '');
  };

  _exports.sanitizeXMLString = sanitizeXMLString;
});