define("funnelytics/templates/components/for-reuse/card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QxWM0gvc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"card-template\"],[9],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"card-title\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"titleIcon\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"grey-icon\"],[9],[0,\"\\n      \"],[1,[21,\"iconNumber\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[21,\"title\"],false],[0,\"\\n  \"],[10],[0,\"\\n    \"],[14,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/card.hbs"
    }
  });

  _exports.default = _default;
});