define("funnelytics/components/for-reuse/input-with-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var STATUS_INFO = 'info';
  var STATUS_SUCCESS = 'success';
  var STATUS_LENGTH = 'length';
  var STATUS_ERROR = 'error';
  var STATUS_ERROR_UNSAFE = 'error-unsafe';

  var _default = Ember.Component.extend({
    classNames: ['wd--100'],
    isFieldStatusSuccess: Ember.computed.equal('fieldStatus', STATUS_SUCCESS),
    isFieldStatusInfo: Ember.computed.equal('fieldStatus', STATUS_INFO),
    isFieldStatusLength: Ember.computed.equal('fieldStatus', STATUS_LENGTH),
    isFieldStatusError: Ember.computed.equal('fieldStatus', STATUS_ERROR),
    isFieldStatusErrorUnsafe: Ember.computed.equal('fieldStatus', STATUS_ERROR_UNSAFE),
    classNameBindings: ['kpi-setter:kpi-setter__field'],
    onSpecialAction: function onSpecialAction() {},
    actions: {
      handleSpecialAction: function handleSpecialAction() {
        var specialHandler = this.get('onSpecialAction');
        specialHandler.apply(void 0, arguments);
      }
    }
  });

  _exports.default = _default;
});