define("funnelytics/components/left-sidebar/left-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    dragManager: Ember.inject.service(),
    onCloseSidebar: function onCloseSidebar() {},
    isMapIconsTabVisible: Ember.computed.equal('sideBarTabType', 'map-icons'),
    isTrafficExplorerTabVisible: Ember.computed.equal('sideBarTabType', 'traffic-explorer'),
    setTabType: function setTabType() {},
    actions: {
      focusOnTabType: function focusOnTabType(tabType) {
        this.setTabType(tabType);
      },
      closeSidebar: function closeSidebar() {
        this.onCloseSidebar();
      }
    }
  });

  _exports.default = _default;
});