define("funnelytics/routes/dashboard/order/complete", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    queryParams: {
      orders: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var _this = this;

      this.set('params', params);
      var paypalIds = [];
      var stripeIds = [];
      params.orders.split(',').forEach(function (_) {
        if (_.includes('PAY-')) {
          paypalIds.push(_);
        } else {
          stripeIds.push(_);
        }
      });
      var stripeOrdersReq = stripeIds.length ? this.get('ajax').request('/orders', {
        method: 'GET',
        data: {
          orders: stripeIds
        }
      }) : null;
      var paypalOrdersReq = paypalIds.length ? this.get('ajax').request("/paypal-orders/".concat(paypalIds[paypalIds.length - 1])) : null;
      return Ember.RSVP.all([stripeOrdersReq, paypalOrdersReq]).then(function (orders) {
        return Ember.RSVP.hash({
          user: _this.modelFor('dashboard').user,
          rawOrders: {
            stripe: orders[0],
            paypal: orders[1]
          }
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var serializeStripeData = function serializeStripeData(inputData) {
        return _lodash.default.flatten(_lodash.default.reduce(inputData, function (arr, order) {
          _lodash.default.each(order.items, function (item) {
            if (item.amount > 0) {
              /*
              _dcq.push([
              'track', `bought_${_.snakeCase(item.description.toLowerCase())}`,
              {
                value: item.amount
              }
              ]
              );
              */
              if (window.dataLayer) {
                var name = "BOUGHT_".concat(_lodash.default.snakeCase(item.description).toUpperCase());

                var product = _lodash.default.snakeCase(item.description).toLowerCase();

                switch (product) {
                  case 'funnelytics_lifetime_membership':
                    name = 'UPGRADE_PRO';
                    break;

                  case 'lifetime_vault':
                    name = 'PURCHASE_VAULT';
                    break;
                }

                window.dataLayer.push({
                  event: name,
                  visitorType: 'loggedIn',
                  email: model.user.get('email').toLowerCase(),
                  firstName: model.user.get('first_name').toLowerCase(),
                  lastName: model.user.get('last_name').toLowerCase(),
                  ecommerce: {
                    purchse: {
                      actionField: {
                        id: order.id,
                        affiliation: 'Funnelytics.io'
                      },
                      products: [{
                        name: product,
                        id: item.parent,
                        price: item.amount / 100,
                        brand: 'FUNNELYTICS.IO',
                        category: 'Product',
                        variant: 'Standard',
                        quantity: 1
                      }]
                    }
                  }
                });
              }

              arr.push(item.parent);
            }
          });

          return arr;
        }, []));
      };

      var serializePaypalData = function serializePaypalData(inputData) {
        var VAULT_SKU = 'sku_COIi3NqXe8O75u';
        var products = [];
        var results = [];
        var total = 0;
        var transaction = inputData.transactions[0];
        var item = transaction.item_list.items[0];

        if (item && item.sku === VAULT_SKU) {
          // only paid for Vault
          products.push(VAULT_SKU);
          results.push({
            items: [{
              amount: Number(item.price) * 100,
              description: item.name,
              parent: item.sku
            }],
            id: inputData.id
          });
          total = Number(item.price) * 100;
        } else {
          // paid for Vault & Pro
          var placeholderName = Math.random().toString(36).substring(7);
          products.push(VAULT_SKU);
          products.push(item.sku);
          results.push({
            items: [{
              amount: 4700,
              description: 'Funnelytics Vault',
              parent: VAULT_SKU
            }],
            id: "PAY-NOT-EXIST-".concat(placeholderName)
          });
          results.push({
            items: [{
              amount: Number(item.price) * 100,
              description: item.name,
              parent: item.sku
            }],
            id: inputData.id
          });
          total = 4700 + Number(item.price) * 100;
        }

        return {
          verifiedOrders: results,
          products: products,
          total: total
        };
      };

      var stripeOrders = model.rawOrders.stripe;
      var paypalOrders = model.rawOrders.paypal;
      var verifiedOrders;
      var products;
      var total;

      if (stripeOrders && stripeOrders.data.length) {
        verifiedOrders = stripeOrders.data;
        products = serializeStripeData(stripeOrders.data);
        total = _lodash.default.sumBy(stripeOrders.data, 'amount');
        model.orders = stripeOrders;
      } else if (!_lodash.default.isEmpty(paypalOrders)) {
        var paypalData = serializePaypalData(paypalOrders);
        verifiedOrders = paypalData.verifiedOrders;
        products = paypalData.products;
        total = paypalData.total;
      } // save to Google Tag Manager


      var hasSentGTM = localStorage.getItem('sent_order_complete_GTM') || false;

      if (window.dataLayer && !hasSentGTM) {
        var ecommerceOrders = verifiedOrders.map(function (_) {
          return {
            sku: _.items[0].parent,
            name: _.items[0].description,
            category: _.items[0].parent === 'sku_CCd3B0geyI6hEe' ? 'Pro Membership' : 'Vault',
            price: _.items[0].amount / 100,
            quantity: 1,
            id: _.id
          };
        });
        window.dataLayer.push({
          event: 'PURCHASE',
          visitorType: 'loggedIn',
          total: total / 100,
          email: model.user.get('email').toLowerCase(),
          firstName: model.user.get('first_name').toLowerCase(),
          lastName: model.user.get('last_name').toLowerCase(),
          pageTitle: 'OrderCompletion',
          pageType: 'OrderCompletion',
          ecommerce: {
            actionField: {
              id: ecommerceOrders.map(function (_) {
                return _.id;
              }).join('%2C'),
              revenue: total / 100,
              tax: 0,
              shipping: 0
            },
            purchase: {
              products: ecommerceOrders
            }
          }
        });
        localStorage.setItem('sent_order_complete_GTM', true);
      }

      window.scrollTo(0, 0);
      controller.set('total', total);
      controller.set('products', products);
      controller.set('verifiedOrders', verifiedOrders);
    },
    renderTemplate: function renderTemplate() {
      this.render('dashboard.order.complete', {
        into: 'application'
      });
    }
  });

  _exports.default = _default;
});