define("funnelytics/templates/components/sales-funnels/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4rO7tMRd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"landing-footer\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"landing-footer__content\"],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"landing-footer__copyright\"],[9],[0,\"\\n      © 2020 Funnelytics, Inc. All Rights Reserved.\\n    \"],[10],[0,\"\\n    \"],[7,\"img\"],[11,\"alt\",\"\"],[11,\"src\",\"/assets/primary-logo-white-text.svg\"],[11,\"class\",\"landing-footer__logo\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"landing-footer__links\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"href\",\"https://funnelytics.io/privacy-policy\"],[9],[0,\"\\n        Privacy\\n      \"],[10],[0,\"\\n      \"],[7,\"a\"],[11,\"href\",\"https://funnelytics.io/terms-of-service\"],[9],[0,\"\\n        Terms of Service\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/sales-funnels/footer.hbs"
    }
  });

  _exports.default = _default;
});