define("funnelytics/controllers/reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    actions: {
      updatePassword: function updatePassword(_ref) {
        var _this = this;

        var updated = _ref.updated;
        // Not actually part of that model, should be avoided(?) (didn't want to change dashboard API right now)
        this.get('model').set('password', updated);
        return this.get('ajax').request("/password-resets/".concat(this.get('model.key')), {
          method: 'PATCH',
          data: this.get('model').serialize()
        }).then(function (passwordReset) {
          // this.get('model').set('password', null);
          _this.get('store').pushPayload('password-reset', passwordReset);

          _this.get('router').transitionTo('login', {
            queryParams: {
              updated: true
            }
          });
        }).catch(function (e) {
          _this.get('model').set('password', null);

          throw e;
        });
      }
    }
  });

  _exports.default = _default;
});