define("funnelytics/routes/dashboard/icons/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model() {
      return this.get('store').findAll('icon-folder', {
        include: ['icons']
      });
    }
  });

  _exports.default = _default;
});