define("funnelytics/controllers/share", ["exports", "funnelytics/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['is_vault'],
    is_vault: null,
    isVault: Ember.computed.bool('is_vault'),
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    currentUser: null,
    private: true,
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },
    actions: {
      add: function add(id) {
        var queryParams = {
          cvID: id
        };

        if (this.is_vault) {
          queryParams['is_vault'] = true;
        }

        if (!this.get('session.isAuthenticated')) {
          var path = this.get('router').urlFor('dashboard.import', {
            queryParams: queryParams
          });
          var url = "".concat(_environment.default.app).concat(path);
          this.get('router').transitionTo('register', {
            queryParams: {
              url: url
            }
          });
          return;
        }

        return this.get('router').transitionTo('dashboard.import', {
          queryParams: queryParams
        });
      },
      backToDashboard: function backToDashboard() {
        this.get('router').transitionTo('dashboard');
      }
    }
  });

  _exports.default = _default;
});