define("funnelytics/models/vault", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    premium: _emberData.default.attr('boolean'),
    created_at: _emberData.default.attr('date'),
    offer: _emberData.default.belongsTo('vault-offer-type'),
    industry: _emberData.default.belongsTo('vault-industry'),
    media: _emberData.default.hasMany('vault-medium'),
    links: _emberData.default.hasMany('vault-link')
  });

  _exports.default = _default;
});