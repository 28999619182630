define("funnelytics/templates/components/flash-message/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l30ufpWt",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isDisplayed\"]]],null,{\"statements\":[[0,\"  \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[[23,[\"link\",\"route\"]]],null,{\"statements\":[[0,\"      \"],[1,[23,[\"link\",\"label\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/flash-message/link.hbs"
    }
  });

  _exports.default = _default;
});