define("funnelytics/routes/dashboard/templates/view", ["exports", "funnelytics/config/environment", "lodash"], function (_exports, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    userPermissions: Ember.inject.service(),
    canvasService: Ember.inject.service(),
    permissions: {
      hasEditPermission: true,
      hasForecastReadPermissions: false,
      hasAnalyticsReadPermissions: false
    },
    titleToken: function titleToken(model) {
      var template = _lodash.default.get(model, 'template');

      if (!template) {
        return 'Unknown Template';
      }

      return template.get('name');
    },
    model: function model(params) {
      var _this = this;

      return this.get('canvasService').loadFunnelPermissions({
        isTemplate: true
      }).then(function () {
        return Ember.RSVP.hash({
          template: _this.store.findRecord('template', params.id),
          templates: _this.store.findAll('template'),
          iconLimit: _this.get('userPermissions').getAllowanceLimit({
            permission: 'allowance.icons'
          }),
          userIcons: _this.get('store').findAll('icon').catch(function (error) {
            if (_lodash.default.get(error, 'errors[0].status') === 403) {
              return [];
            }

            throw error;
          })
        });
      });
    },
    renderTemplate: function renderTemplate() {
      this.render('dashboard.templates.view', {
        into: 'application'
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('permissions', this.get('permissions'));
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (this.controllerFor('dashboard.templates.view').get('edited')) {
          if (_environment.default.isFunnelWarningOff) {
            return true;
          }

          if (!confirm('You have unsaved changes. Are you sure you want to leave?')) {
            transition.abort();
            return false;
          }
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});