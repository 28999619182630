define("funnelytics/custom/constants/ListenerConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ENTITY_ATTRIBUTES_CHANGE = 'entity:attributesChange';
  var ENTITY_IS_DEPENDENT_CHANGE = 'entity:isDependentChange';
  var ENTITY_ACTION_TYPE_CHANGE = 'entity:actionTypeChange';
  var ListenerConstants = Object.freeze({
    ENTITY_ATTRIBUTES_CHANGE: ENTITY_ATTRIBUTES_CHANGE,
    ENTITY_IS_DEPENDENT_CHANGE: ENTITY_IS_DEPENDENT_CHANGE,
    ENTITY_ACTION_TYPE_CHANGE: ENTITY_ACTION_TYPE_CHANGE,
    VALID: Object.freeze([ENTITY_ATTRIBUTES_CHANGE, ENTITY_IS_DEPENDENT_CHANGE, ENTITY_ACTION_TYPE_CHANGE])
  });
  var _default = ListenerConstants;
  _exports.default = _default;
});