define("funnelytics/helpers/show-settings-for-step", ["exports", "funnelytics/custom/analytics/analytics/canvas/CanvasEntity"], function (_exports, _CanvasEntity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.showSettingsForStep = showSettingsForStep;
  _exports.default = void 0;

  function showSettingsForStep(params
  /*, hash*/
  ) {
    return _CanvasEntity.default.isAbleToHaveSettings(params[0]);
  }

  var _default = Ember.Helper.helper(showSettingsForStep);

  _exports.default = _default;
});