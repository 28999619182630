define("funnelytics/serializers/user", ["exports", "ember-data", "lodash"], function (_exports, _emberData, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Attribute key for links object dealing with pagination for this model.
  var LINKS_ATTR = 'links';

  function linksExist(payload) {
    if (!_lodash.default.has(payload, LINKS_ATTR)) {
      return false;
    }

    return _lodash.default.some(_lodash.default.get(payload, LINKS_ATTR, {}), function (link) {
      return link !== null;
    });
  }

  var _default = _emberData.default.JSONAPISerializer.extend({
    keyForAttribute: function keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },
    keyForRelationship: function keyForRelationship(attr) {
      return Ember.String.underscore(attr);
    },

    /**
     * If pagination links have been provided by the query response, we store the values inside the meta object.
     * Got this idea from here: https://emberigniter.com/pagination-in-ember-with-json-api-backend/
     */
    normalizeQueryResponse: function normalizeQueryResponse(store, clazz, payload) {
      var result = this._super.apply(this, arguments);

      if (linksExist(payload)) {
        result.meta = _lodash.default.get(result, 'meta', {});
        result.meta.pagination = _lodash.default.get(payload, LINKS_ATTR);
      }

      return result;
    }
  });

  _exports.default = _default;
});