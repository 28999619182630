define("funnelytics/services/flash-messages", ["exports", "funnelytics/custom/flash-messages/FlashMessage"], function (_exports, _FlashMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Created from the guide: https://emberway.io/adding-flash-messages-to-an-ember-app-437b13e49c1b by Lauren Tan
  var _default = Ember.Service.extend({
    queue: [],
    isEmpty: Ember.computed.equal('queue.length', 0),
    hasFlashes: Ember.computed.not('isEmpty'),
    defaultTimeout: 20000,
    predefined: function predefined(_ref) {
      var template = _ref.template,
          _ref$timeout = _ref.timeout,
          timeout = _ref$timeout === void 0 ? this.get('defaultTimeout') : _ref$timeout,
          _ref$type = _ref.type,
          type = _ref$type === void 0 ? 'success' : _ref$type;

      this._addToQueue('', type, timeout, {
        predefinedTemplate: template
      });
    },
    success: function success() {
      var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          message = _ref2.message,
          _ref2$timeout = _ref2.timeout,
          timeout = _ref2$timeout === void 0 ? this.get('defaultTimeout') : _ref2$timeout,
          _ref2$links = _ref2.links,
          links = _ref2$links === void 0 ? [] : _ref2$links;

      this._addToQueue(message, 'success', timeout, {
        links: links
      });
    },
    info: function info() {
      var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          message = _ref3.message,
          _ref3$timeout = _ref3.timeout,
          timeout = _ref3$timeout === void 0 ? this.get('defaultTimeout') : _ref3$timeout,
          _ref3$links = _ref3.links,
          links = _ref3$links === void 0 ? [] : _ref3$links;

      this._addToQueue(message, 'info', timeout, {
        links: links
      });
    },
    warning: function warning() {
      var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          message = _ref4.message,
          _ref4$timeout = _ref4.timeout,
          timeout = _ref4$timeout === void 0 ? this.get('defaultTimeout') : _ref4$timeout,
          _ref4$links = _ref4.links,
          links = _ref4$links === void 0 ? [] : _ref4$links;

      this._addToQueue(message, 'warning', timeout, {
        links: links
      });
    },
    error: function error() {
      var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          message = _ref5.message,
          _ref5$timeout = _ref5.timeout,
          timeout = _ref5$timeout === void 0 ? this.get('defaultTimeout') : _ref5$timeout,
          _ref5$links = _ref5.links,
          links = _ref5$links === void 0 ? [] : _ref5$links;

      this._addToQueue(message, 'error', timeout, {
        links: links
      });
    },
    addMessage: function addMessage() {
      var _ref6 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          message = _ref6.message,
          _ref6$timeout = _ref6.timeout,
          timeout = _ref6$timeout === void 0 ? this.get('defaultTimeout') : _ref6$timeout,
          _ref6$type = _ref6.type,
          type = _ref6$type === void 0 ? 'info' : _ref6$type,
          _ref6$links = _ref6.links,
          links = _ref6$links === void 0 ? [] : _ref6$links;

      this._addToQueue(message, type, timeout, {
        links: links
      });
    },
    clearMessages: function clearMessages() {
      this.get('queue').clear();
    },
    _addToQueue: function _addToQueue(message, type, timeout) {
      var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      var flash = this._newFlashMessage(message, type, timeout, options);

      this.get('queue').pushObject(flash);
    },
    _newFlashMessage: function _newFlashMessage(message) {
      var type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'info';
      var timeout = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.get('defaultTimeout');

      var _ref7 = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {},
          _ref7$links = _ref7.links,
          links = _ref7$links === void 0 ? [] : _ref7$links,
          predefinedTemplate = _ref7.predefinedTemplate;

      return _FlashMessage.default.create({
        type: type,
        message: message,
        timeout: timeout,
        flashService: this,
        predefinedTemplate: predefinedTemplate,
        links: links
      });
    }
  });

  _exports.default = _default;
});