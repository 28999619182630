define("funnelytics/custom/constants/TextElementConstants", ["exports", "funnelytics/custom/constants/ColourHexConstants"], function (_exports, _ColourHexConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _attrs;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var DEFAULT_FONT_SIZE = 12;
  var FONT_SIZE_KEY = 'font-size';
  var TEXT_ATTR_ARRAY = ['label', 'text'];
  var TEXT_ATTR_FULL_STRING_PATH = "attrs/".concat(TEXT_ATTR_ARRAY.join('/'));
  var TextElementConstants = Object.freeze({
    ALLOWED_FONT_SIZES: Object.freeze([10, 12, 14, 16, 18, 24, 36, 48, 72]),
    HIDE_AFTER_MOVING_MS: 50,
    DISPLAY_AFTER_STATIC_MS: 200,
    PADDING: 20,
    DEFAULT_FONT_SIZE: DEFAULT_FONT_SIZE,
    FONT_SIZE_KEY: FONT_SIZE_KEY,
    DEFAULT_ANNOTATION: {
      attrs: (_attrs = {
        fill: _ColourHexConstants.default.BLACK
      }, _defineProperty(_attrs, FONT_SIZE_KEY, DEFAULT_FONT_SIZE), _defineProperty(_attrs, 'font-weight', 'normal'), _defineProperty(_attrs, 'text-decoration', 'none'), _defineProperty(_attrs, 'font-style', 'normal'), _defineProperty(_attrs, 'font-family', 'Poppins'), _attrs)
    },
    TEXT_ATTR_ARRAY: TEXT_ATTR_ARRAY,
    TEXT_ATTR_FULL_STRING_PATH: TEXT_ATTR_FULL_STRING_PATH
  });
  var _default = TextElementConstants;
  _exports.default = _default;
});