define("funnelytics/components/canvas-elements/utm-item", ["exports", "lodash", "funnelytics/custom/constants/UtmConstants", "funnelytics/custom/analytics/analytics/canvas/EntityParameter"], function (_exports, _lodash, _UtmConstants, _EntityParameter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNames: ['utm-explorer__item'],
    classNameBindings: ['isNotVisible:d-none'],
    utmSource: Ember.computed('utm.utm_source', function () {
      return this.get('utm.utm_source') ? this.get('utm.utm_source') : _UtmConstants.default.UTM_IS_NOT_SET;
    }),
    utmMedium: Ember.computed('utm.utm_medium', function () {
      return this.get('utm.utm_medium') ? this.get('utm.utm_medium') : _UtmConstants.default.UTM_IS_NOT_SET;
    }),
    utmCampaign: Ember.computed('utm.utm_campaign', function () {
      return this.get('utm.utm_campaign') ? this.get('utm.utm_campaign') : _UtmConstants.default.UTM_IS_NOT_SET;
    }),
    utmContent: Ember.computed('utm.utm_content', function () {
      return this.get('utm.utm_content') ? this.get('utm.utm_content') : _UtmConstants.default.UTM_IS_NOT_SET;
    }),
    utmTerm: Ember.computed('utm.utm_term', function () {
      return this.get('utm.utm_term') ? this.get('utm.utm_term') : _UtmConstants.default.UTM_IS_NOT_SET;
    }),
    isVisible: Ember.computed.bool('utm.isUtmVisible'),
    isNotVisible: Ember.computed.not('isVisible'),
    isMapped: Ember.computed.notEmpty("utm.".concat(_UtmConstants.default.MATCHING_CANVAS_ID)),
    onAdd: function onAdd() {},
    onFindElement: function onFindElement() {},
    actions: {
      addRow: function addRow() {
        var thisUtm = this.get('utm');

        var utms = _UtmConstants.default.ALLOWED_UTMS.map(function (allowedUtm) {
          return new _EntityParameter.default({
            contains: !_lodash.default.isEmpty(thisUtm[allowedUtm]),
            key: allowedUtm,
            value: thisUtm[allowedUtm]
          });
        });

        this.onAdd(utms);
      },
      findElement: function findElement() {
        var thisUtm = this.get('utm');

        var canvasId = _lodash.default.get(thisUtm, [_UtmConstants.default.MATCHING_CANVAS_ID]);

        this.onFindElement(canvasId);
      }
    }
  });

  _exports.default = _default;
});