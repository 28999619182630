define("funnelytics/custom/upgrade/SuggestedActionsSet", ["exports", "@funnelytics/utilities", "lodash", "funnelytics/custom/upgrade/PrivateUpgradeConstants", "funnelytics/custom/upgrade/FrontEndUpgradeConstants", "funnelytics/custom/upgrade/SuggestedAction"], function (_exports, _utilities, _lodash, _PrivateUpgradeConstants, _FrontEndUpgradeConstants, _SuggestedAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var SuggestedActionsSet = Ember.Object.extend({
    _message: {},
    _suggestedActions: [],
    _isConfirmationRequired: false,
    _isConfirmed: false,
    _confirmationText: '',
    list: Ember.computed.readOnly('_suggestedActions'),
    hasSuggestedActions: Ember.computed.notEmpty('list'),
    messageText: Ember.computed.readOnly('_message.text'),
    messageType: Ember.computed.readOnly('_message.type'),
    hasMessage: Ember.computed.bool('messageText'),
    isMessageTypeWarning: Ember.computed.equal('messageType', _FrontEndUpgradeConstants.default.MESSAGE_TYPE_WARNING),
    isMessageTypeInfo: Ember.computed.equal('messageType', _FrontEndUpgradeConstants.default.MESSAGE_TYPE_INFO),
    isConfirmationRequired: Ember.computed.readOnly('_isConfirmationRequired'),
    isConfirmationNotRequired: Ember.computed.not('isConfirmationRequired'),
    isConfirmed: Ember.computed.or('isConfirmationNotRequired', '_isConfirmed'),
    confirmationText: Ember.computed.readOnly('_confirmationText'),
    finePrint: Ember.computed.readOnly('_finePrint'),
    hasFinePrint: Ember.computed.notEmpty('finePrint'),
    setSuggestedActions: function setSuggestedActions(suggestedActions) {
      _utilities.Assertion.arrayOfInstancesOf(suggestedActions, _SuggestedAction.default);

      this.set('_suggestedActions', suggestedActions);
    },
    setMessageText: function setMessageText(text) {
      _utilities.Assertion.string(text, {
        allowEmpty: false,
        allowNull: true
      });

      this.set('_message', {
        text: text,
        type: this.get('messageType')
      });
    },
    setMessageType: function setMessageType(type) {
      _utilities.Assertion.validString(type, _FrontEndUpgradeConstants.default.MESSAGE_TYPES);

      this.set('_message', {
        text: this.get('messageText'),
        type: type
      });
    },
    setMessage: function setMessage(options) {
      var message = _lodash.default.get(options, [_PrivateUpgradeConstants.default.MESSAGE_TEXT], null);

      var type = _lodash.default.get(options, [_PrivateUpgradeConstants.default.MESSAGE_TYPE], _FrontEndUpgradeConstants.default.MESSAGE_TYPE_INFO);

      this.setMessageText(message);
      this.setMessageType(type);
    },
    setRequiresConfirmation: function setRequiresConfirmation(isConfirmationRequired) {
      _utilities.Assertion.boolean(isConfirmationRequired);

      this.set('_isConfirmationRequired', isConfirmationRequired);
    },
    setConfirmed: function setConfirmed(isConfirmed) {
      _utilities.Assertion.boolean(isConfirmed);

      this.set('_isConfirmed', isConfirmed);
    },
    setConfirmationText: function setConfirmationText(text) {
      _utilities.Assertion.string(text, {
        allowEmpty: false
      });

      this.set('_confirmationText', text);
    },
    setFinePrint: function setFinePrint(text) {
      _utilities.Assertion.string(text, {
        allowEmpty: true
      });

      this.set('_finePrint', text);
    }
  });
  SuggestedActionsSet.reopenClass({
    createActions: function createActions(options) {
      var suggestedActionObjects = _lodash.default.get(options, [_PrivateUpgradeConstants.default.SUGGESTED_ACTIONS]);

      var messageObject = _lodash.default.get(options, [_PrivateUpgradeConstants.default.MESSAGE_OBJECT], {});

      var requiresConfirmation = _lodash.default.get(options, [_PrivateUpgradeConstants.default.REQUIRES_CONFIRMATION], false);

      var confirmationText = _lodash.default.get(options, [_PrivateUpgradeConstants.default.CONFIRMATION_TEXT], 'Confirm');

      var finePrint = _lodash.default.get(options, [_PrivateUpgradeConstants.default.FINE_PRINT], '');

      var suggestedActions = SuggestedActionsSet.create();
      suggestedActions.setSuggestedActions(suggestedActionObjects);
      suggestedActions.setMessage(messageObject);
      suggestedActions.setRequiresConfirmation(requiresConfirmation);
      suggestedActions.setConfirmed(false);
      suggestedActions.setConfirmationText(confirmationText);
      suggestedActions.setFinePrint(finePrint);
      return suggestedActions;
    },
    createFromConfig: function createFromConfig(config) {
      var _SuggestedActionsSet$;

      var suggestedActionConfigs = _lodash.default.get(config, [_PrivateUpgradeConstants.default.SUGGESTED_ACTIONS]);

      var messageConfig = _lodash.default.get(config, [_PrivateUpgradeConstants.default.MESSAGE_OBJECT]);

      var requiresConfirmation = _lodash.default.get(config, [_PrivateUpgradeConstants.default.REQUIRES_CONFIRMATION]);

      var confirmationText = _lodash.default.get(config, [_PrivateUpgradeConstants.default.CONFIRMATION_TEXT]);

      var finePrint = _lodash.default.get(config, [_PrivateUpgradeConstants.default.FINE_PRINT]);

      var suggestedActions = suggestedActionConfigs.map(function (config) {
        return _SuggestedAction.default.createAction(config);
      });
      return SuggestedActionsSet.createActions((_SuggestedActionsSet$ = {}, _defineProperty(_SuggestedActionsSet$, _PrivateUpgradeConstants.default.SUGGESTED_ACTIONS, suggestedActions), _defineProperty(_SuggestedActionsSet$, _PrivateUpgradeConstants.default.MESSAGE_OBJECT, messageConfig), _defineProperty(_SuggestedActionsSet$, _PrivateUpgradeConstants.default.REQUIRES_CONFIRMATION, requiresConfirmation), _defineProperty(_SuggestedActionsSet$, _PrivateUpgradeConstants.default.CONFIRMATION_TEXT, confirmationText), _defineProperty(_SuggestedActionsSet$, _PrivateUpgradeConstants.default.FINE_PRINT, finePrint), _SuggestedActionsSet$));
    }
  });
  var _default = SuggestedActionsSet;
  _exports.default = _default;
});