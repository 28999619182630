define("funnelytics/custom/constants/ContextMenuActionLabels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ContextMenuActionLabels = Object.freeze({
    ADD_STEP: 'Add Step',
    GENERATE_THUMBNAIL: 'Generate Thumbnail',
    COPY: 'Copy',
    PASTE: 'Paste',
    DUPLICATE: 'Duplicate',
    DELETE: 'Delete',
    EDIT_LABEL: 'Label',
    EDIT_URL: 'URL',
    CHANGE_LINE_STYLE: 'Change Style',
    CHANGE_LINE_COLOUR: 'Change Color'
  });
  var _default = ContextMenuActionLabels;
  _exports.default = _default;
});