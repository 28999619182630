define("funnelytics/custom/analytics/AnalyticsData", ["exports", "@funnelytics/utilities", "funnelytics/custom/constants/AnalyticsConstants"], function (_exports, _utilities, _AnalyticsConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var AnalyticsData = Ember.Object.extend({
    isLoading: Ember.computed.readOnly('_isLoading'),
    _isLoading: false,
    _requestId: null,
    requestId: Ember.computed.readOnly('_requestId'),
    type: Ember.computed.readOnly('_type'),

    /**
     * Takes another instance of AnalyticsData and either merges it into the current data, or else
     * replaces the current data with the new requests data.
     *
     * The type of action chosen depends on the requestId of the analyticsData passed. Requests that
     * seek to merge new data with existing data should be sent as having the same requestID as exising data.
     */
    accept: function accept(analyticsData) {
      var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          _ref$incorporationMet = _ref.incorporationMethod,
          incorporationMethod = _ref$incorporationMet === void 0 ? _AnalyticsConstants.default.REPLACE_WITH : _ref$incorporationMet;

      this._validate(analyticsData);

      return this._incorporateAnalyticsData(analyticsData, {
        incorporationMethod: incorporationMethod
      });
    },

    /**
     * Takes an analytics API JSON response and populates the data based on the subclass' _populate implementation.
     */
    populate: function populate(json) {
      _utilities.Assertion.plainObject(json);

      return this._populate(json);
    },
    setIsLoading: function setIsLoading(isLoading) {
      _utilities.Assertion.boolean(isLoading);

      this.set('_isLoading', isLoading);
    },
    handleFailedRequest: function handleFailedRequest() {
      //* Maybe want to do something here;
      return true;
    },

    /**
     * Ensure required attributes are set on the passed instance of AnalyticData
     */
    _validate: function _validate(analyticsData) {
      _utilities.Assertion.string(analyticsData.get('requestId'), {
        allowEmpty: false
      });

      _utilities.Assertion.string(analyticsData.get('type'), {
        allowEmpty: false
      });
    },
    _incorporateAnalyticsData: function _incorporateAnalyticsData(analyticsData) {
      var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
          incorporationMethod = _ref2.incorporationMethod;

      _utilities.Assertion.validString(incorporationMethod, _AnalyticsConstants.default.ANALYTICS_DATA_INCORPORATION_METHODS);

      var incomingType = analyticsData.get('type');
      var thisType = this.get('type');

      if (incomingType !== thisType) {
        throw new Error("Attepmpted to incorporate AnalyticsData of type ".concat(incomingType, " into AnalyticsData of type ").concat(thisType, ". Types must match."));
      }

      switch (incorporationMethod) {
        case _AnalyticsConstants.default.REPLACE_WITH:
          this._replaceWith(analyticsData);

          break;

        case _AnalyticsConstants.default.MERGE:
          this._merge(analyticsData);

          break;

        default:
          throw new Error("Unhandled incorporationMethod ".concat(incorporationMethod));
      }

      this.set('_requestId', analyticsData.get('requestId'));
    },
    _populate: function _populate() {
      throw new Error('Implement _populate inside a subclass of AnalyticsData');
    },
    _replaceWith: function _replaceWith() {
      throw new Error('Implement _replaceWith inside a subclass of AnalyticsData');
    },
    _merge: function _merge() {
      throw new Error('Implement _merge inside a subclass of AnalyticsData');
    }
  });
  var _default = AnalyticsData;
  _exports.default = _default;
});