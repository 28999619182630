define("funnelytics/templates/components/account/segmentation-survey/text-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NV8vpE6f",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"fl-input-wrapper\"],[9],[0,\"\\n  \"],[7,\"label\"],[11,\"for\",\"name\"],[9],[0,\"\\n    \"],[1,[21,\"title\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"placeholder\",\"value\"],[\"text\",\"fl-input-fit\",[23,[\"placeholder\"]],[23,[\"value\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/account/segmentation-survey/text-input.hbs"
    }
  });

  _exports.default = _default;
});