define("funnelytics/models/user-meta-property", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    key: _emberData.default.attr('string'),
    value_int: _emberData.default.attr('number'),
    value_bool: _emberData.default.attr('boolean'),
    value_string: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user')
  });

  _exports.default = _default;
});