define("funnelytics/routes/dashboard/projects/new", ["exports", "ember-routable-modal/mixins/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_route.default, {
    model: function model() {
      return Ember.RSVP.hash({
        user: this.modelFor('dashboard').user
      });
    }
  });

  _exports.default = _default;
});