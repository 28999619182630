define("funnelytics/components/canvas-elements/context-menu/tracking-settings/advanced-actions/dropdown-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    onClick: function onClick() {},
    // Provide to convert action names
    displayNameConverter: function displayNameConverter(name) {
      return name;
    },
    isRecognizedAction: Ember.computed('name', 'displayName', function () {
      return this.get('name') !== this.get('displayName');
    }),
    displayName: Ember.computed('name', 'displayNameConverter', function () {
      var name = this.get('name');
      return this.get('displayNameConverter')(name);
    }),
    hasHits: Ember.computed.notEmpty('hits'),
    actions: {
      handleClick: function handleClick() {
        var clickHandler = this.get('onClick');
        clickHandler(this.get('name'));
      }
    }
  });

  _exports.default = _default;
});