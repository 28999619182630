define("funnelytics/templates/components/account/segmentation-survey/chips", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MXkiLXUG",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\"],[11,\"class\",\"segmentation-survey__chips\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"options\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\"],[12,\"class\",[28,[\"segmentation-survey__chip \",[27,\"if\",[[27,\"eq\",[[22,1,[\"code\"]],[23,[\"selectedOption\",\"code\"]]],null],\"checked\",\"\"],null]]]],[9],[0,\"\\n      \"],[7,\"input\"],[12,\"name\",[21,\"name\"]],[12,\"checked\",[27,\"eq\",[[22,1,[\"code\"]],[23,[\"selectedOption\",\"code\"]]],null]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"checkOption\",[22,1,[]]],null]],[11,\"type\",\"radio\"],[9],[10],[0,\"\\n      \"],[7,\"span\"],[9],[1,[22,1,[\"title\"]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/account/segmentation-survey/chips.hbs"
    }
  });

  _exports.default = _default;
});