define("funnelytics/custom/constants/AnalyticsConstants", ["exports", "funnelytics/custom/analytics/analytics/commerce/CommerceConstants", "lodash"], function (_exports, _CommerceConstants, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var COMMERCE_SKU_KEY = '__sku__';
  var REPLACE_WITH = 'replace-with';
  var MERGE = 'merge';
  var QUERY_MODE_PEOPLE = 'individual-people';
  var QUERY_MODE_TOTAL_NUMBERS = 'total-numbers';
  var QUERY_MODES = Object.freeze({
    PEOPLE: QUERY_MODE_PEOPLE,
    TOTAL_NUMBERS: QUERY_MODE_TOTAL_NUMBERS
  });
  var QUERY_MODE_DEFAULT = QUERY_MODE_PEOPLE;

  var QUERY_MODES_ALLOWED = _lodash.default.values(QUERY_MODES);

  var ANALYTICS_COMPARE_MODE_DATES = 'dates';
  var ANALYTICS_COMPARE_MODE_PEOPLE = 'people';
  var AnalyticsConstants = Object.freeze({
    PROJECT_ID_HEADER: 'X-Project-Id',
    REPLACE_WITH: REPLACE_WITH,
    MERGE: MERGE,
    ANALYTICS_DATA_INCORPORATION_METHODS: Object.freeze([REPLACE_WITH, MERGE]),
    IGNORE_IN_BETWEEN_STEPS: 'ignore_in_between',
    PARAM_CONTAINS: 'contain',
    PARAM_NOT_CONTAINS: 'not-contain',
    PRIMARY_ANALYTICS_ENTITY_DATA: 'primary_analytics_entity_data',
    SECONDARY_ANALYTICS_ENTITY_DATA: 'secondary_analytics_entity_data',
    ANALYTICS_TYPE_NUMBERS: 'numbers',
    ANALYTICS_TYPE_FLOW: 'flow',
    ANALYTICS_TYPE_LEAD_TIME: 'lead-time',
    ATTRIBUTE_KEY: 'key',
    ATTRIBUTE_VALUE: 'value',
    VALUES_LIST: 'values',
    COMMERCE_SKU_KEY: COMMERCE_SKU_KEY,
    ANALYTICS_UNTRACKABLE_PLACEHOLDER: '0',
    PREDEFINED_ACTIONS_MAPPING: _defineProperty({}, _CommerceConstants.default.ACTION.DATABASE_NAME, _CommerceConstants.default.ACTION.DISPLAY_NAME),
    QUERY_MODES: QUERY_MODES,
    QUERY_MODES_ALLOWED: QUERY_MODES_ALLOWED,
    QUERY_MODE_DEFAULT: QUERY_MODE_DEFAULT,
    DEVICES: {
      DESKTOP: 'desktop',
      MOBILE: 'mobile',
      ALL_DEVICES: undefined // intentional to prevent passing filter to analytics api

    },
    TRAFFIC_EXPLORER_OUTPUT: {
      ACTIONS: {
        NAME: 'name',
        HITS: 'hits'
      }
    },
    PROFILE_ATTRIBUTES_OUTPUT: {
      COUNTRIES: {
        LIST: 'profile_countries',
        NAME: 'name',
        HITS: 'hits'
      }
    },
    SESSIONS_OUTPUT: {
      PATHS: {
        SESSIONS_COUNT: ['meta', 'count'],
        SESSIONS_LIST: ['data']
      },
      KEYS: {
        SESSIONS_COUNT: 'sessions_count',
        SESSIONS_LIST: 'sessions_list'
      }
    },
    ANALYTICS_COMPARE_MODE_DATES: ANALYTICS_COMPARE_MODE_DATES,
    ANALYTICS_COMPARE_MODE_PEOPLE: ANALYTICS_COMPARE_MODE_PEOPLE
  });
  var _default = AnalyticsConstants;
  _exports.default = _default;
});