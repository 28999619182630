define("funnelytics/controllers/dashboard/templates/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    edited: false,
    actions: {
      setEdited: function setEdited(edited) {
        this.set('edited', edited);
      }
    }
  });

  _exports.default = _default;
});