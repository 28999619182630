define("funnelytics/routes/dashboard/settings/lookup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service(),
    total: 0,
    model: function model() {
      var _this = this;

      return this.get('store').query('tracker-session', {
        include: ['profile.attrs'],
        isNotNull: ['profile'],
        order: {
          column: 'created_at',
          direction: 'ASC'
        }
      }).then(function (sessions) {
        _this.set('total', sessions.get('meta.count'));

        return sessions.toArray();
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('total', this.get('total'));
      controller.set('page', 1);
    }
  });

  _exports.default = _default;
});