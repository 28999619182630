define("funnelytics/components/containers/funnel-editor/components/right-sidebar-previous-steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var KEY_ORGANIC_SOURCES = 'organic-sources';
  var KEY_UTM_SOURCES = 'utm-explorer';

  var _default = Ember.Component.extend({
    analyticsService: Ember.inject.service(),
    CLEAR_FOCUS_OBSERVER: Ember.observer('analyticsService.focusEntity', function () {
      if (!this.get('analyticsService.focusEntity')) {
        var handler = this.get('onClearFocus');
        handler();
      }
    }),
    previousPagesError: Ember.computed.readOnly('analyticsService.referrers.error'),
    previousPages: Ember.computed.readOnly('analyticsService.referrers.list'),
    previousLoading: Ember.computed.readOnly('analyticsService.referrers.loading'),
    navigationOptions: [{
      key: KEY_ORGANIC_SOURCES,
      label: 'ORGANIC TRAFFIC SOURCES'
    }, {
      key: KEY_UTM_SOURCES,
      label: 'UTM EXPLORER'
    }],
    isUtmPanelOpen: Ember.computed('selectedPanel', function () {
      return this.get('selectedPanel') === KEY_UTM_SOURCES;
    }),
    onUtmExplorerOpen: function onUtmExplorerOpen() {},
    onUtmExplorerClose: function onUtmExplorerClose() {},
    onNavigateAway: function onNavigateAway() {},
    onSelectEntity: function onSelectEntity() {},
    onClearFocus: function onClearFocus() {},
    utmPanelStatusObserver: Ember.observer('isUtmPanelOpen', function () {
      if (this.get('isUtmPanelOpen')) {
        this.onUtmExplorerOpen();
      } else {
        this.onUtmExplorerClose();
      }
    }),
    isSourcesPanelOpen: Ember.computed('selectedPanel', function () {
      return this.get('selectedPanel') === KEY_ORGANIC_SOURCES;
    }),
    initializeProperties: function initializeProperties() {
      this.setProperties({
        selectedPanel: KEY_ORGANIC_SOURCES
      });
    },
    didInsertElement: function didInsertElement() {
      this.initializeProperties();
    },
    actions: {
      setPreviousStepsPanel: function setPreviousStepsPanel(type) {
        this.set('selectedPanel', type);
      },
      selectEntity: function selectEntity(entityId) {
        var selectHandler = this.get('onSelectEntity');
        selectHandler(entityId);
      },
      handleUTMSelectEntity: function handleUTMSelectEntity(entityId) {
        var selectHandler = this.get('onSelectEntity');
        var navigateAwayHandler = this.get('onNavigateAway');
        selectHandler(entityId);
        navigateAwayHandler();
      }
    }
  });

  _exports.default = _default;
});