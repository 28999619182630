define("funnelytics/helpers/integer-shortener", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.integerShortener = integerShortener;
  _exports.default = void 0;

  function formatThousands(num) {
    return Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + 'k' : Math.sign(num) * Math.abs(num);
  }

  function formatMillions(num) {
    return Math.abs(num) > 999999 ? Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + 'm' : formatThousands(num);
  }

  function integerShortener(params
  /*, hash*/
  ) {
    var input = _lodash.default.first(params);

    var integer = parseInt(input, 10);
    return formatMillions(integer);
  }

  var _default = Ember.Helper.helper(integerShortener);

  _exports.default = _default;
});