define("funnelytics/templates/components/canvas-elements/entity-toolbar/old-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yUB2YDjY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"hasEditPermission\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[28,[\"\\n    entity-toolbar__contents\\n    \",[27,\"if\",[[23,[\"isVisible\"]],\"entity-toolbar__contents--visible\"],null],\"\\n  \"]]],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"entity-toolbar__body\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"entity-toolbar__section\"],[9],[0,\"\\n\"],[4,\"canvas-elements/entity-toolbar/item\",null,[[\"id\",\"label\",\"additionalButtonClasses\",\"onClick\",\"isSelected\"],[\"upgrade-text-element\",\"Upgrade\",\"fl-btn--text\",[27,\"action\",[[22,0,[]],\"handleConvert\"],null],false]],{\"statements\":[[0,\"            Upgrade Text Tool\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\" \"],[0,\"\\n    \"],[10],[0,\" \"],[0,\"\\n  \"],[10],[0,\" \"],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/entity-toolbar/old-text.hbs"
    }
  });

  _exports.default = _default;
});