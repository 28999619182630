define("funnelytics/components/upsells/mapping-by-limit", ["exports", "funnelytics/custom/constants/CookieConstants"], function (_exports, _CookieConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    upsellManager: Ember.inject.service(),
    userDetails: Ember.inject.service(),
    cookies: Ember.inject.service(),
    projectPermissions: Ember.inject.service(),
    isMappingLimitModalOpen: Ember.computed('upsellManager.showMappingByLimit', function () {
      return this.upsellManager.showMappingByLimit;
    }),
    hasSubscription: Ember.computed('projectPermissions.hasStripeSubscription', function () {
      return this.projectPermissions.hasStripeSubscription;
    }),
    setDeactivateModalCookie: function setDeactivateModalCookie() {
      var cookiesService = this.get('cookies');
      var cookieKey = "".concat(_CookieConstants.default.MAPPING_LIMIT_PREFIX).concat(this.get('userDetails.userId'));
      cookiesService.write(cookieKey, 'true', {
        domain: 'funnelytics.io',
        sameSite: 'strict',
        path: '/',
        maxAge: 999999999
      });
    },
    actions: {
      close: function close() {
        this.setDeactivateModalCookie();
        this.set('upsellManager.showMappingByLimit', false);
      },
      sendUserToUpgrade: function sendUserToUpgrade() {
        this.setDeactivateModalCookie();
        this.set('upsellManager.showMappingByLimit', false);
        window.open(this.buttonUrl, '_blank');
      }
    }
  });

  _exports.default = _default;
});