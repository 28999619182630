define("funnelytics/templates/components/503-404-fallback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hcv6dG0M",
    "block": "{\"symbols\":[\"copy\"],\"statements\":[[7,\"div\"],[11,\"class\",\"maintainence\"],[9],[0,\"\\n  \"],[7,\"img\"],[11,\"src\",\"/assets/primary-logo.png\"],[11,\"class\",\"maintainence__logo\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"maintainence__body\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"maintainence__text-section\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"maintainence__subheader\"],[9],[0,\"\\n        \"],[1,[23,[\"content\",\"subHeader\"]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"h1\"],[11,\"class\",\"maintainence__header\"],[9],[0,\"\\n        \"],[1,[23,[\"content\",\"header\"]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"maintainence__explaination\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"content\",\"bodyText\"]]],null,{\"statements\":[[0,\"          \"],[7,\"p\"],[9],[0,\"\\n            \"],[1,[22,1,[]],false],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"img\"],[11,\"src\",\"/assets/dashboard-icons/broken-funnel.svg\"],[11,\"class\",\"maintainence__broken-funnel\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/503-404-fallback.hbs"
    }
  });

  _exports.default = _default;
});