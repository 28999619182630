define("funnelytics/helpers/first-letter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.firstLetter = firstLetter;
  _exports.default = void 0;

  function firstLetter(params
  /*, hash*/
  ) {
    return params[0].charAt(0).toUpperCase();
  }

  var _default = Ember.Helper.helper(firstLetter);

  _exports.default = _default;
});