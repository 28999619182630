define("funnelytics/components/canvas-elements/context-menu/tracking-settings/advanced-actions/metric", ["exports", "funnelytics/custom/analytics/settings/MetricConstants"], function (_exports, _MetricConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['metrics-dropdown-content__option'],
    isSelected: Ember.computed.readOnly("metric.".concat(_MetricConstants.default.KEYS.IS_SELECTED)),
    name: Ember.computed.readOnly("metric.".concat(_MetricConstants.default.KEYS.NAME)),
    onToggle: function onToggle() {},
    actions: {
      toggle: function toggle(e) {
        e.stopPropagation();
        e.preventDefault();
        var toggleHandler = this.get('onToggle');
        toggleHandler(this.get('metric'));
      }
    }
  });

  _exports.default = _default;
});