define("funnelytics/custom/constants/Redirect", ["exports", "funnelytics/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var PERFORMANCE = {
    LITE_TRIAL: 'lite-trial',
    PLUS_TRIAL: 'plus-trial',
    PERFORMANCE_STARTER: 'map-plus'
  };
  var trial = {
    'lite-trial': 'https://funnelytics.io/performance-lite-trial/',
    'plus-trial': 'https://funnelytics.io/performance-plus-trial/'
  };
  var blackFriday = {
    'lite-annual': 'https://funnelytics.io/performance-lite-annual/',
    'lite-monthly': 'https://funnelytics.io/performance-lite/',
    'lite-partner-annual': 'https://funnelytics.io/agency-lite-annual/',
    'lite-partner-monthly': 'https://funnelytics.io/agency-lite-monthly/'
  };
  var checkout = {
    'map-plus': 'https://funnelytics.io/checkout/map-plus-monthly'
  };
  var stripePaymentLinks = Object.freeze({
    'map-pro-trial': _environment.default.deployTarget === 'production' ? 'https://buy.stripe.com/5kA5kJees16c4pyaFH' : 'https://buy.stripe.com/test_4gw5lvbBph2ddTa28f',
    'map-pro-offer': 'https://buy.stripe.com/00g14t6M0bKQg8g6pk',
    'performance-starter-trial': _environment.default.deployTarget === 'production' ? 'https://buy.stripe.com/4gw00p5HW6qwg8geWb' : 'https://buy.stripe.com/test_5kA15f9thbHT7uM4gp',
    'performance-pro-trial': _environment.default.deployTarget === 'production' ? 'https://buy.stripe.com/8wM8wVgmA6qw1dm01i' : 'https://buy.stripe.com/test_28obJTgVJ7rDdTabIT',
    'performance-trial': 'https://buy.stripe.com/6oE4gFb2g8yEbS06p3',
    'performance-business-trial': 'https://buy.stripe.com/4gw7sR4DS9CIg8g4hb',
    'performance-agency-trial': 'https://buy.stripe.com/14k9AZ1rG16caNW5lk',
    discover: 'https://funnelytics.io/discover',
    'interactive-demo': 'https://canvas.funnelytics.io/tutorial/cd5dc687-2a4b-46ac-8681-8e36d7a17b90'
  });
  var validStripePaymentRedirectParams = Object.keys(stripePaymentLinks);
  var freeTrialPaymentRedirectParams = validStripePaymentRedirectParams.filter(function (i) {
    return i.indexOf('trial') !== -1;
  });
  var VALID_PERFORMANCE_REDIRECTS = [PERFORMANCE.LITE_TRIAL, PERFORMANCE.PERFORMANCE_STARTER];
  var VALID_HOSTS = ['qa.funnelytics.io', 'staging.funnelytics.io', 'app.funnelytics.io', 'canvas.funnelytics.io', 'hub.funnelytics.io', 'funnelytics.io', 'help.funnelytics.io', 'billing.stripe.com', //billing portal
  'buy.stripe.com', // stripe payment links
  _environment.default.host || _environment.default.app, _environment.default.new_canvas_host || _environment.default.newCanvasApp];
  var pricingTableLink = "https://funnelytics.io/pricing-table/";
  var REDIRECT_CONSTANTS = Object.freeze(_objectSpread({
    PERFORMANCE: PERFORMANCE
  }, blackFriday, {}, trial, {}, checkout, {
    stripePaymentLinks: stripePaymentLinks,
    validStripePaymentRedirectParams: validStripePaymentRedirectParams,
    freeTrialPaymentRedirectParams: freeTrialPaymentRedirectParams,
    VALID_PERFORMANCE_REDIRECTS: VALID_PERFORMANCE_REDIRECTS,
    VALID_HOSTS: VALID_HOSTS,
    pricingTableLink: pricingTableLink
  }));
  var _default = REDIRECT_CONSTANTS;
  _exports.default = _default;
});