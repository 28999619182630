define("funnelytics/templates/components/tutorial-btn", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "193ziC9p",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"display\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"class\",[27,\"if\",[[23,[\"isSmall\"]],\"tutorial-btn tutorial-btn__small\",\"tutorial-btn\"],null]],[3,\"action\",[[22,0,[]],\"openTutorialCanvas\"]],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"src\",\"/assets/tutorial-icon.svg\"],[11,\"class\",\"tutorial-btn-icon\"],[9],[10],[0,\"\\n    Tutorial canvas\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/tutorial-btn.hbs"
    }
  });

  _exports.default = _default;
});