define("funnelytics/components/library/creator-tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    /**
     * Props passed in include:
     * type: 'funnelytics', 'user:free', 'user:paid'
     */
    backgroundColor: null,
    //Community Purple - #9642f8, Brand blue - #009bf3
    text: null,
    // 'FUNNELYTICS', 'COMMUNITY'
    textColor: 'white',
    init: function init() {
      this._super.apply(this, arguments);

      this.setTagStyling();
    },
    setTagStyling: function setTagStyling() {
      var creator = this.creator;
      var tagStylingMap = {
        funnelytics: {
          backgroundColor: '#009bf3',
          text: 'FUNNELYTICS',
          textColor: 'white'
        },
        'user:free': {
          backgroundColor: '#c59cf5',
          text: 'COMMUNITY',
          textColor: 'white'
        }
      };
      var tagStyling = tagStylingMap[creator];
      this.setProperties(tagStyling);
    }
  });

  _exports.default = _default;
});