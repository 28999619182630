define("funnelytics/components/dashboard-elements/top-navbar", ["exports", "funnelytics/custom/constants/Redirect", "funnelytics/custom/constants/Upsell", "funnelytics/mixins/stripe-setup-links"], function (_exports, _Redirect, _Upsell, _stripeSetupLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_stripeSetupLinks.default, {
    router: Ember.inject.service(),
    authService: Ember.inject.service(),
    workspaceSelector: Ember.inject.service(),
    releaseManager: Ember.inject.service(),
    versionManager: Ember.inject.service(),
    cookies: Ember.inject.service(),
    projectPermissions: Ember.inject.service(),
    isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    isV2: Ember.computed('releaseManager.isV2', function () {
      return this.get('releaseManager.isV2');
    }),
    activeProjectVersion: Ember.computed('versionManager.activeDashboardVersion', function () {
      return this.get('versionManager.activeDashboardVersion');
    }),
    isInTrial: Ember.computed('projectPermissions.isInTrial', function () {
      return this.get('projectPermissions.isInTrial');
    }),
    upsellTextFreeTrial: _Upsell.UPSELL_TEXTS.start_performance,
    upsellUrlFreeTrial: _Upsell.UPSELL_URLS.start_free_trial,
    hasStripeSubscription: Ember.computed('projectPermissions.hasStripeSubscription', function () {
      return this.projectPermissions.hasStripeSubscription;
    }),
    showUpsellBtn: Ember.computed('isBuyPerformanceCTAVisible', 'isLimitedWithoutCollaboration', function () {
      return !this.get('isLimitedWithoutCollaboration') && this.get('isBuyPerformanceCTAVisible');
    }),
    setupStripePerformancePro: Ember.computed('projectPermissions.user.email', 'projectPermissions.user.id', function () {
      var url = new URL(_Redirect.default.pricingTableLink);
      var id = this.get('projectPermissions.user.id');
      var email = this.get('projectPermissions.user.email');
      url.searchParams.append('client-reference-id', id);
      url.searchParams.append('customer-email', email);
      return url.toString();
    }),
    anchorTarget: '_blank',
    didInsertElement: function didInsertElement() {
      var cookieService = this.get('cookies');
      var cookies = cookieService.read();

      var validSelfAnchorTargets = _Redirect.default.VALID_PERFORMANCE_REDIRECTS.includes(cookies.flx_upsl);

      if (cookies.flx_upsl && validSelfAnchorTargets) {
        this.set('anchorTarget', '_blank');
      }
    },
    actions: {
      logoutMenu: function logoutMenu() {
        var pv = this.get('activeProjectVersion');

        if (pv > 1) {
          Ember.$('.dropdown-content-v2').toggle();
        } else {
          Ember.$('.dropdown-content').toggle();
        }
      },
      logout: function logout() {
        this.get('authService').logout();
      },
      upgradeProject: function upgradeProject() {
        this.get('router').transitionTo('dashboard.projects.upgrade');
      }
    }
  });

  _exports.default = _default;
});