define("funnelytics/templates/components/canvas-elements/commerce-skus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RMjJUPjD",
    "block": "{\"symbols\":[\"sku\"],\"statements\":[[4,\"if\",[[23,[\"hasEntity\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[11,\"class\",\"sideBarLabel\"],[9],[0,\"\\n    Reload Commerce SKUs\\n  \"],[10],[0,\"\\n  \"],[1,[27,\"canvas-elements/analytics-status-button\",null,[[\"class\",\"isLoading\",\"isStale\",\"isGood\",\"hasError\",\"clickHandler\"],[\"commerce-skus__reload\",[23,[\"areSkusLoading\"]],false,true,false,[27,\"action\",[[22,0,[]],\"reloadSkus\"],null]]]],false],[0,\"\\n\\n  \"],[7,\"label\"],[11,\"class\",\"sideBarLabel\"],[11,\"for\",\"commerce-action-sku-picker\"],[9],[0,\"\\n    SKUs\\n  \"],[10],[0,\"\\n  \"],[7,\"select\"],[11,\"id\",\"commerce-action-sku-picker\"],[11,\"class\",\"fl-input-fit fl-input-fit--short\"],[12,\"disabled\",[21,\"areSkusLoading\"]],[12,\"onchange\",[27,\"action\",[[22,0,[]],\"selectSku\"],[[\"value\"],[\"target.value\"]]]],[9],[0,\"\\n    \"],[1,[27,\"canvas-elements/commerce-skus/option\",null,[[\"overrideValue\",\"overrideSelected\",\"sku\"],[[23,[\"NO_SKU_SELECTED\"]],[23,[\"isCurrentSkuNotInList\"]],\"All\"]]],false],[0,\"\\n\"],[4,\"each\",[[23,[\"skus\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"canvas-elements/commerce-skus/option\",null,[[\"sku\",\"currentSku\"],[[22,1,[]],[23,[\"currentSku\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/commerce-skus.hbs"
    }
  });

  _exports.default = _default;
});