define("funnelytics/custom/analytics/settings/config/standard-action-names-mapping", ["exports", "funnelytics/custom/analytics/analytics/commerce/CommerceConstants"], function (_exports, _CommerceConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _defineProperty({}, _CommerceConstants.default.ACTION.DATABASE_NAME, _CommerceConstants.default.ACTION.DISPLAY_NAME);

  _exports.default = _default;
});