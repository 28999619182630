define("funnelytics/components/account/segmentation-survey/chips", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onCheck: function onCheck() {},
    actions: {
      checkOption: function checkOption(option) {
        this.get('onCheck')(option);
      }
    }
  });

  _exports.default = _default;
});