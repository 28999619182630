define("funnelytics/custom/misc/shapemarkup", ["exports", "funnelytics/custom/constants/CanvasConstants"], function (_exports, _CanvasConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _ShapeMarkup;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ShapeMarkup = (_ShapeMarkup = {}, _defineProperty(_ShapeMarkup, _CanvasConstants.default.TYPE_PAGE, {
    markup: [{
      tagName: 'g',
      className: 'label__wrapper',
      selector: 'label__wrapper',
      children: [{
        tagName: 'rect',
        className: 'label__rectangle',
        selector: 'label__rectangle'
      }, {
        tagName: 'text',
        className: 'label'
      }]
    }, {
      tagName: 'g',
      className: 'visits__wrapper',
      selector: 'visits__wrapper',
      children: [{
        tagName: 'rect',
        className: 'visits__rectangle',
        selector: 'visits__rectangle'
      }, {
        tagName: 'text',
        className: 'visits'
      }]
    }, {
      tagName: 'g',
      className: 'forecast__wrapper',
      selector: 'forecast__wrapper',
      children: [{
        tagName: 'rect',
        className: 'forecast__rectangle',
        selector: 'forecast__rectangle'
      }, {
        tagName: 'text',
        className: 'forecast-label'
      }]
    }, {
      tagName: 'g',
      className: 'compare__wrapper',
      selector: 'compare__wrapper',
      children: [{
        tagName: 'text',
        className: 'compare-source__label',
        selector: 'compare-source__label'
      }, {
        tagName: 'text',
        className: 'compare-diff__label',
        selector: 'compare-diff__label'
      }, {
        tagName: 'text',
        className: 'compare-target__label',
        selector: 'compare-target__label'
      }]
    }, {
      tagName: 'image'
    }, {
      tagName: 'svg',
      attributes: {
        viewBox: '0 0 108 144',
        width: '60'
      },
      children: [{
        tagName: 'g',
        className: 'previewthumbnail__wrapper',
        children: [{
          tagName: 'image',
          className: 'previewthumbnail'
        }, {
          tagName: 'path',
          className: 'cls-2',
          attributes: {
            d: "M107,16V138.38a4.63,4.63,0,0,1-4.62,4.63H5.63A4.63,4.63,0,0,1,1,138.38V16H107m1-1H0V138.38A5.62,\n                        5.62,0,0,0,5.63,144h96.75a5.62,5.62,0,0,0,5.63-5.62V15Z"
          }
        }, {
          tagName: 'path',
          className: 'cls-3',
          attributes: {
            d: 'M.5,15.5V5.63A5.13,5.13,0,0,1,5.63.5h96.75a5.13,5.13,0,0,1,5.13,5.13V15.5Z'
          }
        }, {
          tagName: 'path',
          className: 'cls-2',
          attributes: {
            d: "M102.38,1A4.63,4.63,0,0,1,107,5.63V15H1V5.63A4.63,4.63,0,0,1,5.63,1h96.75m0-1H5.63A5.62,\n                        5.62,0,0,0,0,5.63V16H108V5.63A5.62,5.62,0,0,0,102.38,0Z"
          }
        }, {
          tagName: 'circle',
          className: 'cls-4',
          attributes: {
            cx: '11',
            cy: '8',
            r: '2'
          }
        }, {
          tagName: 'circle',
          className: 'cls-5',
          attributes: {
            cx: '17',
            cy: '8',
            r: '2'
          }
        }, {
          tagName: 'circle',
          className: 'cls-6',
          attributes: {
            cx: '23',
            cy: '8',
            r: '2'
          }
        }, {
          tagName: 'rect',
          className: 'cls-7',
          attributes: {
            x: '34.5',
            y: '4.5',
            width: '64',
            height: '7',
            rx: '1.22',
            ry: '1.22'
          }
        }, {
          tagName: 'path',
          className: 'cls-2',
          attributes: {
            d: "M97.28,5a.73.73,0,0,1,.72.72v4.55a.73.73,0,0,1-.72.72H35.72a.73.73,0,0,1-.72-.72V5.72A.73.73,0,0,1,35.72,5H97.28m0-1H35.72A1.72,\n                        1.72,0,0,0,34,5.72v4.55A1.72,1.72,0,0,0,35.72,12H97.28A1.72,1.72,0,0,0,99,10.28V5.72A1.72,1.72,0,0,0,97.28,4Z"
          }
        }]
      }]
    }, {
      tagName: 'path',
      className: 'in-out-step--in',
      selector: 'in-out-step--in',
      attributes: {
        d: 'M0 15C0 6.71573 6.71573 0 15 0V0H35V25.4182H9.68804C6.28867 25.4182 2.94855 26.3083 0 28V28V15Z',
        fill: 'url(#paint0_linear)'
      }
    }, {
      tagName: 'path',
      className: 'in-out-step--out',
      selector: 'in-out-step--out',
      attributes: {
        d: 'M0 13.9677C0 21.7175 6.28246 28 14.0323 28V28H33L33 2.58182H9.14801C5.91886 2.58182 2.75269 1.68824 0 0V0V13.9677Z',
        fill: 'url(#paint1_linear)'
      }
    }, {
      tagName: 'defs',
      children: [{
        tagName: 'linearGradient',
        attributes: {
          id: 'paint0_linear',
          x1: '31',
          y1: '13.3111',
          x2: '-0.499998',
          y2: '13.3111',
          gradientUnits: 'userSpaceOnUse'
        },
        children: [{
          tagName: 'stop',
          attributes: {
            'stop-color': '#E6FAF1',
            'stop-opacity': '0'
          }
        }, {
          tagName: 'stop',
          attributes: {
            offset: '1',
            'stop-color': '#B6F5D7'
          }
        }]
      }, {
        tagName: 'linearGradient',
        attributes: {
          id: 'paint1_linear',
          x1: '33',
          y1: '10.2',
          x2: '3.53226',
          y2: '10.2',
          gradientUnits: 'userSpaceOnUse'
        },
        children: [{
          tagName: 'stop',
          attributes: {
            'stop-color': '#FEEBEC',
            'stop-opacity': '0'
          }
        }, {
          tagName: 'stop',
          attributes: {
            offset: '1',
            'stop-color': '#FFD5D7'
          }
        }]
      }, {
        tagName: 'linearGradient',
        attributes: {
          id: 'paint0_linear--hover',
          x1: '31',
          y1: '13.3111',
          x2: '-0.499998',
          y2: '13.3111',
          gradientUnits: 'userSpaceOnUse'
        },
        children: [{
          tagName: 'stop',
          attributes: {
            'stop-color': 'rgb(148, 236, 197)',
            'stop-opacity': '0'
          }
        }, {
          tagName: 'stop',
          attributes: {
            offset: '1',
            'stop-color': '#B6F5D7'
          }
        }]
      }, {
        tagName: 'linearGradient',
        attributes: {
          id: 'paint1_linear--hover',
          x1: '33',
          y1: '10.2',
          x2: '3.53226',
          y2: '10.2',
          gradientUnits: 'userSpaceOnUse'
        },
        children: [{
          tagName: 'stop',
          attributes: {
            'stop-color': 'rgb(248, 139, 146)',
            'stop-opacity': '0'
          }
        }, {
          tagName: 'stop',
          attributes: {
            offset: '1',
            'stop-color': '#FFD5D7'
          }
        }]
      }]
    }, {
      tagName: 'text',
      className: 'in-out-step--in-label',
      selector: 'in-out-step--in-label'
    }, {
      tagName: 'text',
      className: 'in-out-step--out-label',
      selector: 'in-out-step--out-label'
    }]
  }), _defineProperty(_ShapeMarkup, _CanvasConstants.default.TYPE_EVENT, {
    markup: [{
      tagName: 'image'
    }, {
      tagName: 'circle',
      className: 'event-target'
    }, {
      tagName: 'g',
      className: 'label__wrapper',
      selector: 'label__wrapper',
      children: [{
        tagName: 'rect',
        className: 'label__rectangle',
        selector: 'label__rectangle'
      }, {
        tagName: 'text',
        className: 'label'
      }]
    }, {
      tagName: 'g',
      className: 'visits__wrapper',
      selector: 'visits__wrapper',
      children: [{
        tagName: 'rect',
        className: 'visits__rectangle',
        selector: 'visits__rectangle'
      }, {
        tagName: 'text',
        className: 'visits'
      }]
    }, {
      tagName: 'g',
      className: 'forecast__wrapper',
      selector: 'forecast__wrapper',
      children: [{
        tagName: 'rect',
        className: 'forecast__rectangle',
        selector: 'forecast__rectangle'
      }, {
        tagName: 'text',
        className: 'forecast-label'
      }]
    }, {
      tagName: 'g',
      className: 'lead_time__wrapper',
      selector: 'lead_time__wrapper',
      children: [{
        tagName: 'rect',
        className: 'lead_time__rectangle',
        selector: 'lead_time__rectangle'
      }, {
        tagName: 'text',
        className: 'lead_time-label'
      }]
    }, {
      tagName: 'g',
      className: 'compare__wrapper',
      selector: 'compare__wrapper',
      children: [{
        tagName: 'text',
        className: 'compare-source__label',
        selector: 'compare-source__label'
      }, {
        tagName: 'text',
        className: 'compare-diff__label',
        selector: 'compare-diff__label'
      }, {
        tagName: 'text',
        className: 'compare-target__label',
        selector: 'compare-target__label'
      }]
    }]
  }), _defineProperty(_ShapeMarkup, _CanvasConstants.default.TYPE_OFFLINE, {
    markup: [{
      tagName: 'g',
      className: 'label__wrapper',
      selector: 'label__wrapper',
      children: [{
        tagName: 'rect',
        className: 'label__rectangle',
        selector: 'label__rectangle'
      }, {
        tagName: 'text',
        className: 'label'
      }]
    }, {
      tagName: 'image'
    }]
  }), _defineProperty(_ShapeMarkup, _CanvasConstants.default.TYPE_SOURCE, {
    markup: [{
      tagName: 'image'
    }, {
      tagName: 'g',
      className: 'visits__wrapper',
      selector: 'visits__wrapper',
      children: [{
        tagName: 'rect',
        className: 'visits__rectangle',
        selector: 'visits__rectangle'
      }, {
        tagName: 'text',
        className: 'visits'
      }]
    }, {
      tagName: 'g',
      className: 'forecast__wrapper',
      selector: 'forecast__wrapper',
      children: [{
        tagName: 'rect',
        className: 'forecast__rectangle',
        selector: 'forecast__rectangle'
      }, {
        tagName: 'text',
        className: 'forecast-label'
      }]
    }, {
      tagName: 'g',
      className: 'label__wrapper',
      selector: 'label__wrapper',
      children: [{
        tagName: 'rect',
        selector: 'label__rectangle',
        className: 'label__rectangle'
      }, {
        tagName: 'text',
        selector: 'label',
        className: 'label'
      }]
    }, {
      tagName: 'g',
      className: 'compare__wrapper',
      selector: 'compare__wrapper',
      children: [{
        tagName: 'text',
        className: 'compare-source__label',
        selector: 'compare-source__label'
      }, {
        tagName: 'text',
        className: 'compare-diff__label',
        selector: 'compare-diff__label'
      }, {
        tagName: 'text',
        className: 'compare-target__label',
        selector: 'compare-target__label'
      }]
    }]
  }), _defineProperty(_ShapeMarkup, "solidLinkArrowhead", 'M9.77886 4.70432C10.7711 5.28314 10.7711 6.71684 9.77885 7.29566L2.25581 11.6841C1.25582 12.2674 -5.00851e-08 11.5461 0 10.3884L3.79715e-07 1.61154C4.298e-07 0.453857 1.25582 -0.26745 2.25581 0.315874L9.77886 4.70432Z'), _defineProperty(_ShapeMarkup, "defaultLinkArrowhead", 'M5.707,9.707c-0.391,0.391-1.023,0.391-1.414,0l-4-4c-0.391-0.391-0.391-1.023,0-1.414l4-4 c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414L2.414,5l3.293,3.293C6.098,8.684,6.098,9.316,5.707,9.707z'), _defineProperty(_ShapeMarkup, _CanvasConstants.default.TYPE_CONNECTION, {
    markup: ['<path class="connection" stroke="red" d="M 0 0 0 0"/>', '<path class="connection-wrap" d="M 0 0 0 0"/>', '<g class="labels"/>', '<g class="marker-vertices"/>', '<g class="marker-arrowheads"/>', '<g class="link-tools"/>'].join(''),
    vertexMarkup: ['<g class="marker-vertex-group" transform="translate(<%= x %>, <%= y %>)">', '<title>Reposition vertex.</title>', '<circle class="marker-vertex" idx="<%= idx %>" r="5" />', '<circle class="marker-vertex-remove-area" idx="<%= idx %>" r="10"/>', // eslint-disable-next-line max-len
    '<path class="marker-vertex-remove" idx="<%= idx %>" transform="scale(.3) translate(21.5, -65)" d="M24.778,21.419 19.276,15.917 24.777,10.415 21.949,7.585 16.447,13.087 10.945,7.585 8.117,10.415 13.618,15.917 8.116,21.419 10.946,24.248 16.447,18.746 21.948,24.248z">', '<title>Remove vertex.</title>', '</path>', '</g>'].join('')
  }), _defineProperty(_ShapeMarkup, _CanvasConstants.default.TYPE_GHOST_LINE, {
    markup: ['<path class="connection" stroke="black" d="M 0 0 0 0"/>', '<path class="connection-wrap" d="M 0 0 0 0"/>', '<g class="labels"/>', '<g class="marker-vertices"/>', '<g class="marker-arrowheads"/>', '<g class="link-tools"/>'].join(''),
    arrowheadMarkup: ['<g class="marker-arrowhead-group marker-arrowhead-group-<%= end %>">', '<circle class="marker-arrowhead" r="5" end="<%= end %>"/>', '</g>'].join('')
  }), _ShapeMarkup);
  var _default = ShapeMarkup;
  _exports.default = _default;
});