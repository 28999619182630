define("funnelytics/components/canvas-elements/page-row", ["exports", "lodash", "funnelytics/custom/analytics/analytics/PartialRequestOptions", "funnelytics/custom/constants/CanvasConstants", "funnelytics/custom/constants/ReferrerConstants"], function (_exports, _lodash, _PartialRequestOptions, _CanvasConstants, _ReferrerConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['canvas-page-list__item'],
    analyticsService: Ember.inject.service(),
    canvasService: Ember.inject.service(),
    commandService: Ember.inject.service(),
    addToMapType: _ReferrerConstants.default.ADD_TO_MAP_PREVIOUS,
    isAddToMapPrevious: Ember.computed.equal('addToMapType', _ReferrerConstants.default.ADD_TO_MAP_PREVIOUS),
    focusedEntity: Ember.computed.readOnly('analyticsService.focusEntity'),
    url: Ember.computed("referrer.".concat(_ReferrerConstants.default.URL), function () {
      var url = this.get("referrer.".concat(_ReferrerConstants.default.URL));

      if (!url) {
        return 'Direct';
      }

      return url;
    }),
    hasUrl: Ember.computed.notEmpty("referrer.".concat(_ReferrerConstants.default.URL)),
    count: Ember.computed.readOnly("referrer.".concat(_ReferrerConstants.default.COUNT)),
    isMapped: Ember.computed.notEmpty("referrer.".concat(_ReferrerConstants.default.MATCHING_CANVAS_ID)),
    onAdd: function onAdd() {},
    onFindElement: function onFindElement() {},
    actions: {
      addToMap: function addToMap() {
        this.get('commandService').startBatchCommand();
        var referrer = this.get('referrer');
        var focusedEntity = this.get('focusedEntity');

        if (!focusedEntity) {
          return;
        }

        if (!this.get('hasUrl')) {
          return;
        }

        var isExternal = this.get('isAddToMapPrevious') ? _lodash.default.get(referrer, [_ReferrerConstants.default.IS_EXTERNAL], false) : false;
        var entityType = isExternal ? _CanvasConstants.default.TYPE_SOURCE_NAME : _CanvasConstants.default.TYPE_PAGE_NAME;
        var xOffset = this.get('isAddToMapPrevious') ? -200 : 200;
        var newEntity = this.get('canvasService').createElement({
          label: _lodash.default.get(referrer, [_ReferrerConstants.default.URL]),
          type: entityType,
          x: focusedEntity.getX() + xOffset,
          y: focusedEntity.getY(),
          url: _lodash.default.get(referrer, [_ReferrerConstants.default.URL])
        });
        newEntity.setPositionY(newEntity.getY() + (focusedEntity.getHeight() - newEntity.getHeight()) / 2);
        var linkSourceId = this.get('isAddToMapPrevious') ? newEntity.getID() : focusedEntity.getID();
        var linkTargetId = this.get('isAddToMapPrevious') ? focusedEntity.getID() : newEntity.getID();
        var linkEntity = this.get('canvasService').createConnection({
          source: {
            id: linkSourceId
          },
          target: {
            id: linkTargetId
          }
        });

        if (isExternal) {
          newEntity.replaceAllAttributes(_ReferrerConstants.default.IGNORED_UTM_PARAMETERS);
        }

        this.get('canvasService').getEntityManager().addToCanvas(newEntity);
        this.get('canvasService').getEntityManager().addToCanvas(linkEntity);
        this.onAdd();
        this.get('analyticsService').queueStatsForElement(newEntity.getID(), new _PartialRequestOptions.default({
          includeReferrers: false
        }));
        this.get('commandService').endBatchCommand();
      },
      findElement: function findElement() {
        var thisReferrer = this.get('referrer');

        var canvasId = _lodash.default.get(thisReferrer, [_ReferrerConstants.default.MATCHING_CANVAS_ID]);

        this.onFindElement(canvasId);
      }
    }
  });

  _exports.default = _default;
});