define("funnelytics/components/canvas-elements/entity-toolbar/multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    canvasService: Ember.inject.service(),

    /**
     * Actions that need to be passed into the component:
     */
    onClickContext: function onClickContext() {},
    actions: {
      clickHorizontalAlign: function clickHorizontalAlign() {
        this.get('canvasService').getEntityManager().alignElements('x');
      },
      clickVerticalAlign: function clickVerticalAlign() {
        this.get('canvasService').getEntityManager().alignElements('y');
      },
      clickContext: function clickContext() {
        var contextHandler = this.get('onClickContext');
        contextHandler();
      }
    }
  });

  _exports.default = _default;
});