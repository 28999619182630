define("funnelytics/components/account/invoice-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    showLineItem: false,
    onClickDownloadPdf: function onClickDownloadPdf() {},
    actions: {
      openBill: function openBill() {
        this.toggleProperty('showLineItem');
      },
      downloadPdf: function downloadPdf(invoiceId) {
        var onClickHandler = this.get('onClickDownloadPdf');
        return onClickHandler(invoiceId);
      }
    }
  });

  _exports.default = _default;
});