define("funnelytics/templates/dashboard/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BzuqKgF2",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"activeProjectVersion\"]],1],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"fl-col-2 wd--90 fl--mlr--3 fl--pb--2\"],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"isLimitedWithoutCollaboration\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"style\",\"\\n  width:100%;\\n  margin: 4px 0 0;\\n  overflow: hidden;\\n  height: 100%;\\n  margin-right: -13px;\\n  \"],[9],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"style\",\"\\n  width:100%;\\n  margin: 4px 0 0;\\n  overflow: hidden;\\n  height: 100%;\\n  margin-right: -13px;\\n  \"],[9],[0,\"\\n    \"],[1,[27,\"dashboard-elements/project-navbar-v2\",null,[[\"route\",\"v2Landing\"],[\"isUserPermissions\",[23,[\"model\",\"v2Landing\"]]]]],false],[0,\"\\n    \"],[1,[21,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/dashboard/settings.hbs"
    }
  });

  _exports.default = _default;
});