define("funnelytics/templates/components/for-reuse/upsell-inline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0MyOWGGX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"upsell-message upsell-inline\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"header\"],[9],[0,\"\\n    \"],[1,[23,[\"content\",\"title\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"subheader\"],[9],[0,\"\\n    \"],[1,[23,[\"content\",\"subtitle\"]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"fl-row\"],[9],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"fl--p--0 fl--mr--05\"],[9],[0,\"\\n\"],[4,\"for-reuse/subcomponents/checklist-item\",null,null,{\"statements\":[[0,\"        \"],[1,[23,[\"content\",\"reason1\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"for-reuse/subcomponents/checklist-item\",null,null,{\"statements\":[[0,\"        \"],[1,[23,[\"content\",\"reason2\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"content\",\"reason3\"]]],null,{\"statements\":[[4,\"for-reuse/subcomponents/checklist-item\",null,null,{\"statements\":[[0,\"          \"],[1,[23,[\"content\",\"reason3\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"content\",\"action\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"subheader\"],[9],[1,[23,[\"content\",\"action\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"a\"],[12,\"id\",[21,\"upsellID\"]],[12,\"href\",[21,\"upsellUrlStartTrial\"]],[11,\"target\",\"_blank\"],[11,\"class\",\"fl-hero-btn cta bkg--green text-uppercase\"],[9],[0,\"\\n    \"],[1,[21,\"upsellTextStartTrial\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/upsell-inline.hbs"
    }
  });

  _exports.default = _default;
});