define("funnelytics/custom/analytics/settings/config/standard-action-keys-mapping", ["exports", "funnelytics/custom/analytics/analytics/commerce/CommerceConstants"], function (_exports, _CommerceConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _CommerceConstants$AT;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = (_CommerceConstants$AT = {}, _defineProperty(_CommerceConstants$AT, _CommerceConstants.default.ATTRIBUTES.CURRENCY.DATABASE_NAME, _CommerceConstants.default.ATTRIBUTES.CURRENCY.DISPLAY_NAME), _defineProperty(_CommerceConstants$AT, _CommerceConstants.default.ATTRIBUTES.ORDER.DATABASE_NAME, _CommerceConstants.default.ATTRIBUTES.ORDER.DISPLAY_NAME), _defineProperty(_CommerceConstants$AT, _CommerceConstants.default.ATTRIBUTES.SKU.DATABASE_NAME, _CommerceConstants.default.ATTRIBUTES.SKU.DISPLAY_NAME), _defineProperty(_CommerceConstants$AT, _CommerceConstants.default.ATTRIBUTES.TOTAL_IN_CENTS.DATABASE_NAME, _CommerceConstants.default.ATTRIBUTES.TOTAL_IN_CENTS.DISPLAY_NAME), _defineProperty(_CommerceConstants$AT, _CommerceConstants.default.ATTRIBUTES.DESCRIPTION.DATABASE_NAME, _CommerceConstants.default.ATTRIBUTES.DESCRIPTION.DISPLAY_NAME), _CommerceConstants$AT);

  _exports.default = _default;
});