define("funnelytics/templates/components/canvas-elements/page-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VhL1wb5J",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[12,\"title\",[28,[[21,\"url\"]]]],[11,\"class\",\"canvas-page-list__url\"],[9],[0,\"\\n  \"],[1,[21,\"url\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"canvas-page-list__row-count\"],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"isMapped\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"count\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"canvas-page-list__row-button\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isMapped\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"canvas-elements/button-find\",null,[[\"onClick\"],[[27,\"action\",[[22,0,[]],\"findElement\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"hasUrl\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"canvas-elements/button-map\",null,[[\"onClick\"],[[27,\"action\",[[22,0,[]],\"addToMap\"],null]]]],false],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/page-row.hbs"
    }
  });

  _exports.default = _default;
});