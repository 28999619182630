define("funnelytics/helpers/environment-url", ["exports", "funnelytics/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.environmentUrl = environmentUrl;
  _exports.default = void 0;

  function environmentUrl(params) {
    if (_environment.default.deployTarget === 'production') {
      return 'https://s3-us-west-2.amazonaws.com/funnelytics-production'.concat(params[0]);
    } else {
      return 'https://s3-us-west-2.amazonaws.com/funnelytics-staging'.concat(params[0]);
    }
  }

  var _default = Ember.Helper.helper(environmentUrl);

  _exports.default = _default;
});