define("funnelytics/templates/components/account/performance-lite", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0TQEA6w+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"login\"],[11,\"style\",\"overflow: none;\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"perf-lite-header-container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"perf-lite-header\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"class\",\"perf-lite-top-text-logo \"],[11,\"src\",\"assets/primary-logo-spacing.svg\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"perf-lite-footer-section\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"perf-lite-footer-container\"],[9],[0,\"\\n        \"],[7,\"div\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"divider\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"perf-lite-footer-content\"],[9],[0,\"\\n          \"],[7,\"div\"],[9],[7,\"img\"],[11,\"class\",\"perf-lite-footer-text-logo\"],[11,\"src\",\"assets/primary-logo-spacing.svg\"],[9],[10],[0,\" \"],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"perf-lite-footer-text\"],[9],[7,\"span\"],[11,\"style\",\"width: 75%; display: block; float: right;\"],[9],[0,\"Visualize the performance of your \"],[7,\"b\"],[9],[0,\"Customers' Journey\"],[10],[10],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/account/performance-lite.hbs"
    }
  });

  _exports.default = _default;
});