define("funnelytics/templates/dashboard/users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Q8S8SKP",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"users/user-list\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/dashboard/users/index.hbs"
    }
  });

  _exports.default = _default;
});