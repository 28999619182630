define("funnelytics/models/email-update", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    used: _emberData.default.attr('boolean'),
    new_email: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user'),
    expires_at: _emberData.default.attr('date')
  });

  _exports.default = _default;
});