define("funnelytics/templates/components/canvas-elements/analytics-layer-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1vwefTJS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"layer fl--ml--1 fl--mr--05 \",[27,\"if\",[[23,[\"disabled\"]],\"layer-disabled\",\"\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"hasPermission\"]]],null,{\"statements\":[[0,\"    \"],[7,\"svg\"],[12,\"class\",[28,[\"icon analytics-layer-button__icon \",[21,\"iconClass\"],\" fl--mr--05\"]]],[9],[0,\"\\n      \"],[7,\"use\"],[12,\"xlink:href\",[28,[[21,\"icon\"]]],\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"switch switch-sm fl--mr--05\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onPrimaryAction\"],null]],[9],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[27,\"eq\",[[23,[\"mode\"]],[23,[\"activeStateMode\"]]],null]]]],false],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"fl-slider-round round fl-slider-round-sm\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"title\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"svg\"],[12,\"class\",[28,[\"icon analytics-layer-button__icon \",[21,\"iconClass\"],\" fl--mr--05\"]]],[9],[0,\"\\n      \"],[7,\"use\"],[12,\"xlink:href\",[28,[[21,\"icon\"]]],\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"switch switch-sm fl--mr--05\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"onUpgradePrompt\",[23,[\"upsellType\"]],\"starter\"],null]],[9],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[27,\"eq\",[[23,[\"mode\"]],[23,[\"activeStateMode\"]]],null]]]],false],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"fl-slider-round round fl-slider-round-sm\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"title\"],[9],[1,[21,\"title\"],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/analytics-layer-button.hbs"
    }
  });

  _exports.default = _default;
});