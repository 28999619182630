define("funnelytics/components/for-reuse/session-progress-bar", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SHORT_DATE_FORMAT = 'MMM-DD';
  var STANDARD_DATE_FORMAT = 'MM/DD/YYYY';
  var COLORS = {
    BLUE: '#009BF3',
    YELLOW: '#F9D84A',
    ORANGE: '#F7846C',
    DARK_GREY: '#4A5468',
    TEXT_ORANGE: '#F9694A',
    RED: '#BB2200'
  };
  var BREAKPOINTS_PERCENT = {
    EIGHTY: 80,
    NINETY: 90,
    HUNDRED: 100
  };
  var visitorCount = 100000;
  var overageCost = '$100';

  var _default = Ember.Component.extend({
    userSessions: Ember.inject.service(),
    userDetails: Ember.inject.service(),
    sessionsTotalTrackedAsPercent: Ember.computed('userSessions.cycleTrackingLimitPercent', function () {
      return this.get('userSessions.cycleTrackingLimitPercent');
    }),
    formatedSessionsVolume: Ember.computed('userSessions.cycleTrackingCount', function () {
      var nf = new Intl.NumberFormat('en-US');
      return nf.format(this.get('userSessions.cycleTrackingCount'));
    }),
    formattedSessionsStartPeriod: Ember.computed('userSessions.cycleTrackingStartDate', function () {
      return (0, _moment.default)(this.get('userSessions.cycleTrackingStartDate')).format(STANDARD_DATE_FORMAT);
    }),
    formattedSessionsEndPeriod: Ember.computed('userSessions.cycleTrackingEndDate', function () {
      return (0, _moment.default)(this.get('userSessions.cycleTrackingEndDate')).format(STANDARD_DATE_FORMAT);
    }),
    shortSessionStartPeriod: Ember.computed('userSessions.cycleTrackingStartDate', function () {
      return (0, _moment.default)(this.get('userSessions.cycleTrackingStartDate')).format(SHORT_DATE_FORMAT);
    }),
    shortSessionEndPeriod: Ember.computed('userSessions.cycleTrackingEndDate', function () {
      return (0, _moment.default)(this.get('userSessions.cycleTrackingEndDate')).format(SHORT_DATE_FORMAT);
    }),
    refreshDate: Ember.computed('userSessions.cycleTrackingEndDate', function () {
      var endDate = this.get('userSessions.cycleTrackingEndDate');
      if (!endDate) return null;
      var formattedDate = (0, _moment.default)(endDate).add(1, 'days').format(STANDARD_DATE_FORMAT);
      return formattedDate;
    }),
    progressBar: Ember.computed('userSessions.cycleTrackingLimitPercent', function () {
      var ctlPercent = this.get('userSessions.cycleTrackingLimitPercent');
      if (typeof ctlPercent !== 'number') return null;
      if (ctlPercent > BREAKPOINTS_PERCENT.HUNDRED) return 100;
      return ctlPercent;
    }),
    progressBarColor: Ember.computed('progressBar', function () {
      var progressPercent = this.get('progressBar');

      if (progressPercent < BREAKPOINTS_PERCENT.EIGHTY) {
        return COLORS.BLUE;
      } else if (progressPercent < BREAKPOINTS_PERCENT.NINETY) {
        return COLORS.YELLOW;
      } else if (progressPercent <= BREAKPOINTS_PERCENT.HUNDRED) {
        return COLORS.ORANGE;
      }
    }),
    overageText: Ember.computed('userSessions.cycleTrackingLimitPercent', function () {
      var ctlPercent = this.get('userSessions.cycleTrackingLimitPercent');

      if (ctlPercent < BREAKPOINTS_PERCENT.NINETY) {
        return COLORS.DARK_GREY;
      } else if (ctlPercent <= BREAKPOINTS_PERCENT.HUNDRED) {
        return COLORS.TEXT_ORANGE;
      }

      return COLORS.RED;
    }),
    overageBar: Ember.computed('userSessions.cycleTrackingLimitPercent', function () {
      var ctlPercent = this.get('userSessions.cycleTrackingLimitPercent');
      if (typeof ctlPercent !== 'number') return null;
      if (ctlPercent < BREAKPOINTS_PERCENT.HUNDRED) return 0;
      var overage = ctlPercent - BREAKPOINTS_PERCENT.HUNDRED;
      return overage > 100 ? 100 : overage;
    }),
    isShown: Ember.computed('userSessions.cycleTrackingLimit', function () {
      return !!this.get('userSessions.cycleTrackingLimit');
    }),
    visitorCount: visitorCount,
    overageCost: overageCost
  });

  _exports.default = _default;
});