define("funnelytics/models/recurly-user-subscription", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    status: _emberData.default.attr('string'),
    term_ends_at: _emberData.default.attr('date'),
    period_ends_at: _emberData.default.attr('date'),
    unit_amount_in_cents: _emberData.default.attr('number'),
    remaining_billing_cycles: _emberData.default.attr('number'),
    external_status: _emberData.default.attr('string'),
    external_id: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user'),
    subscription: _emberData.default.belongsTo('recurly-subscription'),
    queued_subscription: _emberData.default.belongsTo('recurly-user-queued-subscription'),
    add_ons: _emberData.default.hasMany('recurly-user-subscription-add-on'),
    subscriptionPrice: Ember.computed('queued_subscription.new_plan_period_price_in_cents', 'unit_amount_in_cents', 'add_ons.[]', function () {
      var basePrice = 0;

      if (this.get('queued_subscription.new_plan_period_price_in_cents') !== undefined) {
        basePrice = this.get('queued_subscription.new_plan_period_price_in_cents');
      }

      basePrice = this.get('unit_amount_in_cents');
      var addOns = this.get('add_ons') || [];
      var addOnPriceSum = addOns.reduce(function (total, addOn) {
        return total + addOn.get('unit_amount_in_cents') * addOn.get('quantity');
      }, 0);
      return addOnPriceSum + basePrice;
    })
  });

  _exports.default = _default;
});