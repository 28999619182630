define("funnelytics/templates/components/containers/funnel-editor/components/right-sidebar-previous-steps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D3W629mp",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"containers/funnel-editor/components/right-sidebar-navigation\",null,[[\"navigationOptions\",\"selectedNavigation\",\"onSelectNavigation\"],[[23,[\"navigationOptions\"]],[23,[\"selectedPanel\"]],[27,\"action\",[[22,0,[]],\"setPreviousStepsPanel\"],null]]]],false],[0,\"\\n\\n\"],[7,\"section\"],[12,\"class\",[28,[[27,\"if\",[[27,\"not\",[[23,[\"isSourcesPanelOpen\"]]],null],\"d-none\"],null]]]],[9],[0,\"\\n  \"],[1,[27,\"canvas-elements/page-list\",null,[[\"canvasElementStatusId\",\"onFindElement\",\"isLoading\",\"pages\",\"error\"],[[23,[\"canvasElementStatusId\"]],[27,\"action\",[[22,0,[]],\"selectEntity\"],null],[23,[\"previousLoading\"]],[23,[\"previousPages\"]],[23,[\"previousPagesError\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"section\"],[12,\"class\",[28,[[27,\"if\",[[27,\"not\",[[23,[\"isUtmPanelOpen\"]]],null],\"d-none\"],null]]]],[9],[0,\"\\n  \"],[1,[27,\"canvas-elements/utm-explorer\",null,[[\"onSelectEntity\",\"canvasElementStatusId\"],[[27,\"action\",[[22,0,[]],\"handleUTMSelectEntity\"],null],[23,[\"canvasElementStatusId\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/containers/funnel-editor/components/right-sidebar-previous-steps.hbs"
    }
  });

  _exports.default = _default;
});