define("funnelytics/templates/components/public/permissions-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TP0Eep5S",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"share-page\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"error\"]]],null,{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"error text-danger text-center mt-2 mb-2\"],[9],[0,\"\\n      \"],[1,[21,\"error\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[11,\"class\",\"share-page__private\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"share-page__warning-text\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"class\",\"fl-logo fl--mb--2 \"],[11,\"src\",\"assets/primary-logo-white-text.png\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"share-page__text--header\"],[9],[0,\"\\n        Whoops! Either this funnel doesn’t exist or you don’t have permission to view it.\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"share-page__text--subheader\"],[9],[0,\"\\n        Contact the funnel’s owner, or if you believe you’re seeing this page in error, please contact our support team.\\n      \"],[10],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"share-page__private-button\"],[3,\"action\",[[22,0,[]],[23,[\"onBack\"]]]],[9],[0,\"\\n        \"],[1,[21,\"backButtonText\"],false],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/public/permissions-error.hbs"
    }
  });

  _exports.default = _default;
});