define("funnelytics/routes/dashboard/projects/project/integrations", ["exports", "lodash", "funnelytics/mixins/permissions", "funnelytics/config/environment"], function (_exports, _lodash, _permissions, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissions.default, {
    ajax: Ember.inject.service(),
    projectPermissions: Ember.inject.service(),
    workspaceSelector: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var hasAccess = this.projectPermissions.hasPermission(this.workspaceSelector.selectedV2Workspace.id, this.IS_INTEGRATION_ALLOWED);

      if (!hasAccess) {
        this.replaceWith('/404');
      }
    },
    model: function model() {
      var project = this.modelFor('dashboard.projects.project');
      return Ember.RSVP.hash({
        project: project,
        accessToNewVersion: _lodash.default.get(this.modelFor('dashboard'), 'accessToNewVersion'),
        apiKey: this.get('ajax').request("/projects/".concat(project.get('id'), "/api-key")).then(function (result) {
          return _lodash.default.get(result, ['api_key'], null);
        }).catch(function (e) {
          console.log('API key failed: ', e);
          return null;
        }),
        hubspot: this.get('ajax').request("/hubspot-integration/".concat(project.get('id')))
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('saving', false);
      controller.set('isProduction', _environment.default.deployTarget === 'production');
    }
  });

  _exports.default = _default;
});