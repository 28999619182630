define("funnelytics/routes/dashboard/settings/account/email-update", ["exports", "ember-routable-modal/mixins/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_route.default, {
    modal: Ember.inject.service('current-routed-modal'),
    // Prevent the default close behaviour of the routable modal
    specialModalSetup: function specialModalSetup() {
      this.set('modal.storageForCloseMethod', this.get('modal.close'));
      this.set('modal.close', function () {});
    },
    // Re-enable the default close behaviour of the routable modal and invoke it
    specialModalCloser: function specialModalCloser() {
      this.set('modal.close', this.get('modal.storageForCloseMethod'));
      this.get('modal').close();
    },
    model: function model(_ref) {
      var email_update_id = _ref.email_update_id;
      return this.get('store').findRecord('email-update', email_update_id).catch(function () {
        return null;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('password', '');
      controller.set('updating', false);
      var accountController = this.controllerFor('dashboard.settings.account');
      controller.set('emailUpdateHandler', function () {
        accountController.send('setEmailUpdated');
      });
      this.specialModalSetup();
      controller.set('modalCloseHelper', this.specialModalCloser.bind(this));
    },
    actions: {
      willTransition: function willTransition() {
        this.specialModalCloser();
        return true;
      }
    }
  });

  _exports.default = _default;
});