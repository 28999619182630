define("funnelytics/components/subscriptions/modals/cancel-membership", ["exports", "funnelytics/custom/constants/MembershipConstants"], function (_exports, _MembershipConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userDetails: Ember.inject.service(),
    downgradeModalManager: Ember.inject.service(),
    selectedChoice: null,
    // inputs
    isOpen: false,
    isAnnual: false,
    currentMembership: null,
    // computed
    userId: Ember.computed('userDetails.userId', function () {
      return this.userDetails.get('userId');
    }),
    benefitsToLose: Ember.computed('currentMembership', function () {
      return this.downgradeModalManager.getLosingBenefits(this.currentMembership, _MembershipConstants.DowngradeMembershipNames.limited);
    }),
    proposedMembership: Ember.computed('currentMembership', 'downgradeToMembership', function () {
      return this.downgradeModalManager.getProposedMembership(this.currentMembership, this.downgradeToMembership);
    }),
    proposeKeepPlan: Ember.computed('proposedMembership', function () {
      return this.proposedMembership === this.currentMembership && [_MembershipConstants.DowngradeMembershipNames.map, _MembershipConstants.DowngradeMembershipNames.mapPro].includes(this.proposedMembership);
    }),
    watchIsOpen: Ember.observer('isOpen', function () {
      // on open/close modal user choice should be resetted
      this.set('selectedChoice', null);
    }),
    // outputs
    onClose: function onClose() {},
    onSucess: function onSucess() {},
    onTakeMapOffer: function onTakeMapOffer() {},
    actions: {
      close: function close() {
        this.onClose();
      },
      success: function success() {
        // do nothing if user didn't choose anything
        if (!this.selectedChoice) {
          return;
        } // User decides to keep his current plan


        if (this.selectedChoice === 'propose' && this.get('proposeKeepPlan')) {
          this.onClose();
          return;
        }

        if (this.selectedChoice === 'propose') {
          this.onTakeMapOffer(_MembershipConstants.DowngradeMembershipNames.mapPro);
        } else {
          this.onSuccess(_MembershipConstants.DowngradeMembershipNames.limited);
        }
      },
      selectChoice: function selectChoice(choice) {
        this.set('selectedChoice', choice);
      }
    }
  });

  _exports.default = _default;
});