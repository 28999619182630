define("funnelytics/templates/components/funnel-pages/faastrack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "elhZveuj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"funnelmomentum\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"funnelmomentum__video-btn-container\"],[9],[0,\"\\n    \"],[7,\"h2\"],[11,\"class\",\"funnelmomentum__title\"],[9],[0,\"Join our Community of 20,000+ Funnel Marketers\"],[10],[0,\"\\n    \"],[7,\"h3\"],[11,\"class\",\"funnelmomentum__title funnelmomentum__title-small\"],[9],[0,\"Weekly live interviews with industry leaders, free trainings, some ridiculously talented marketers and more...\"],[10],[0,\"\\n    \"],[7,\"img\"],[11,\"class\",\"funnelmomentum__banner\"],[11,\"src\",\"/assets/cta/funnel-momentum.png\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"funnelmomentum__button-row\"],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"href\",\"https://www.facebook.com/groups/funnelytics\"],[11,\"class\",\"fl-btn fl-btn--bright fl-btn--medium-tall\"],[11,\"style\",\"margin: 0 auto;\"],[11,\"target\",\"_blank\"],[9],[0,\"\\n        Join the Facebook Community\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/funnel-pages/faastrack.hbs"
    }
  });

  _exports.default = _default;
});