define("funnelytics/routes/dashboard/projects/project/settings", ["exports", "lodash", "funnelytics/mixins/permissions"], function (_exports, _lodash, _permissions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_permissions.default, {
    ajax: Ember.inject.service(),
    projectPermissions: Ember.inject.service(),
    workspaceSelector: Ember.inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      var noAccess = this.projectPermissions.hasPermission(this.workspaceSelector.selectedV2Workspace.id, this.IS_COLLABORATOR);

      if (noAccess) {
        this.replaceWith('/404');
      }
    },
    model: function model() {
      var project = this.modelFor('dashboard.projects.project');

      var projectClients = _lodash.default.get(this.modelFor('dashboard'), 'projectClients', []);

      var filteredProjectClients = projectClients.filter(function (projectClient) {
        return projectClient.get('project.id') === project.get('id');
      });
      return Ember.RSVP.hash({
        project: project,
        projectClients: filteredProjectClients,
        domains: this.get('store').query('project-domain', {
          filter: {
            project: project.get('id')
          }
        }).then(function (domains) {
          return domains.toArray();
        }),
        blacklisted: this.get('store').query('project-ip', {
          filter: {
            project: project.get('id')
          }
        }).then(function (blacklisted) {
          return blacklisted.toArray();
        }),
        lastReset: this.get('store').queryRecord('statistics-reset', {
          filter: {
            project: project.get('id')
          }
        }).catch(function () {
          return null;
        }),
        apiKey: this.get('ajax').request("/projects/".concat(project.get('id'), "/api-key")).then(function (result) {
          return _lodash.default.get(result, ['api_key'], null);
        }).catch(function () {
          return null;
        })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('lastResetDate', model.lastReset ? model.lastReset.get('reset_at') : null);
      controller.set('error', null);
      controller.set('detailsError', null);
      controller.set('deleting', false);
      controller.set('saving', false);
      controller.set('deleteAcknowledgement', false);
      controller.set('name', model.project.get('name'));
    },
    actions: {
      willTransition: function willTransition() {
        var project = this.get('currentModel').project;

        if (project.get('hasDirtyAttributes')) {
          project.rollbackAttributes();
        }

        this.controller.set('showToggleWarning', false);
        return true;
      }
    }
  });

  _exports.default = _default;
});