define("funnelytics/templates/components/for-reuse/ui-elements/close-modal-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5PKJWu2g",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"modal__close-btn\"],[11,\"type\",\"button\"],[3,\"action\",[[22,0,[]],[23,[\"handleClick\"]]]],[9],[0,\"\\n  \"],[1,[27,\"svg-display\",null,[[\"symbolDefId\"],[\"icon-close-modal\"]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/ui-elements/close-modal-button.hbs"
    }
  });

  _exports.default = _default;
});