define("funnelytics/components/upsells/free-account-lock-warning", ["exports", "funnelytics/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    upsellManager: Ember.inject.service(),
    ajax: Ember.inject.service(),
    userDetails: Ember.inject.service(),
    // TOOD: Change this date
    lockDate: 'January 1st, 2024',
    confirmLock: false,
    isModalOpen: Ember.computed('upsellManager.showLockFreeMapping', function () {
      return this.upsellManager.showLockFreeMapping;
    }),
    userId: Ember.computed('userDetails.userId', function () {
      return this.userDetails.get('userId');
    }),
    isOpen: false,
    upgradeLink: Ember.computed('userDetails.userId', 'userDetails.email', function () {
      var id = this.userDetails.get('userId');
      var email = this.userDetails.get('email');
      var link = _environment.default.deployTarget === 'production' ? "https://buy.stripe.com/fZe4gFees16caNW8xA?prefilled_promo_code=MAPPROOFFER&client_reference_id=".concat(id, "&prefilled_email=").concat(email) : "https://buy.stripe.com/test_14k5lveNB27jaGYcMS?prefilled_promo_code=MAPPROOFFER&client_reference_id=".concat(id, "&prefilled_email=").concat(email);
      return link;
    }),
    actions: {
      toggleConfirmLock: function toggleConfirmLock() {
        this.toggleProperty('confirmLock');
      },
      lockAndClose: function lockAndClose() {
        var _this = this;

        if (!this.get('confirmLock')) {
          return;
        }

        var downgradeDate = new Date(); // Add 5 days

        downgradeDate.setDate(downgradeDate.getDate() + 5);
        var firstJanuary2024 = new Date('2024-01-01');

        if (downgradeDate <= firstJanuary2024) {
          downgradeDate = firstJanuary2024;
        }

        this.get('ajax').request("/users/".concat(this.get('userId')), {
          method: 'PATCH',
          data: {
            data: {
              attributes: {
                downgrade_date: downgradeDate.toISOString()
              }
            }
          }
        }).then(function () {
          _this.get('upsellManager').closeLockFreeMapping();
        });
      }
    }
  });

  _exports.default = _default;
});