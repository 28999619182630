define("funnelytics/routes/dashboard/projects/project/unorganized", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    userDetails: Ember.inject.service(),

    /*
    queryParams: {
      project: {
        refreshModel: true
      }
    },
    */
    model: function model() {
      var _this = this;

      var projectsArray = _lodash.default.get(this.modelFor('dashboard'), ['projects'], []);

      var projectClients = _lodash.default.get(this.modelFor('dashboard'), ['projectClients'], []);

      var funnels = this.get('store').query('funnel', {
        filter: {
          project: null
        }
      });
      return Ember.RSVP.hash({
        projects: projectsArray.filter(function (project) {
          if (project.get('user.id') === _this.get('userDetails.userId')) {
            return true;
          }

          var projectClient = projectClients.find(function (pc) {
            return pc.get('user.id') === _this.get('userDetails.userId') && pc.get('project.id') === project.get('id');
          });

          if (projectClient) {
            return projectClient.get('permissions') > 0;
          }

          return false;
        }),
        accessToNewVersion: _lodash.default.get(this.modelFor('dashboard'), 'accessToNewVersion'),
        funnels: funnels
      });
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});