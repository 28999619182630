define("funnelytics/components/account/account-password", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PASS_MATCH_WARNING = 'Your passwords must match';

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    strongPassword: Ember.inject.service(),
    error: null,
    passwordCurrent: '',
    passwordNew: '',
    passwordConfirm: '',
    updating: false,
    success: false,
    requireCurrent: true,
    autofocusPasswordBox: false,
    title: 'Reset Your Password',
    onUpdate: new Ember.RSVP.Promise(function (resolve) {
      return resolve();
    }),
    passwordNewEmpty: Ember.computed.empty('passwordNew'),
    passwordConfirmEmpty: Ember.computed.empty('passwordConfirm'),
    passwordFieldsEmpty: Ember.computed.and('passwordNewEmpty', 'passwordConfirmEmpty'),
    newPasswordNotStrong: Ember.computed('passwordNew', function () {
      var initData = {
        password: this.get('passwordNew'),
        first_name: this.get('user.first_name'),
        last_name: this.get('user.last_name')
      };
      this.get('strongPassword').initialize(initData);
      var errorPassword = this.get('strongPassword').checkPassword();
      return errorPassword;
    }),
    newPasswordTooShort: Ember.computed('passwordNew', function () {
      return _lodash.default.get(this.get('passwordNew'), 'length', 0) < 7;
    }),
    passwordsDoNotMatch: Ember.computed('passwordNew', 'passwordConfirm', function () {
      return this.get('passwordNew') !== this.get('passwordConfirm');
    }),
    submitDisabled: Ember.computed.or('updating', 'newPasswordNotStrong', 'passwordsDoNotMatch'),
    passwordNewWarning: Ember.computed('passwordFieldsEmpty', 'newPasswordNotStrong', function () {
      if (this.get('passwordFieldsEmpty')) {
        return {
          message: '',
          icon: ''
        };
      } else if (this.get('newPasswordNotStrong')) {
        return {
          message: this.get('newPasswordNotStrong'),
          icon: 'error-icon'
        };
      }

      return {
        message: '',
        icon: 'success-icon'
      };
    }),
    passwordConfirmWarning: Ember.computed('passwordFieldsEmpty', 'passwordsDoNotMatch', function () {
      if (this.get('passwordFieldsEmpty')) {
        return {
          message: '',
          icon: ''
        };
      } else if (this.get('passwordsDoNotMatch')) {
        return {
          message: PASS_MATCH_WARNING,
          icon: 'warning-icon'
        };
      }

      return {
        message: '',
        icon: 'success-icon'
      };
    }),
    resetPasswordForm: function resetPasswordForm() {
      this.setProperties({
        passwordCurrent: '',
        passwordNew: '',
        passwordConfirm: '',
        updating: false,
        error: null,
        success: false
      });
    },
    didInsertElement: function didInsertElement() {
      this.resetPasswordForm();
    },
    actions: {
      setUserPassword: function setUserPassword() {
        var _this = this;

        if (this.get('updating')) {
          return;
        }

        this.set('updating', true);
        this.set('error', null);
        this.set('success', false);
        return this.get('onUpdate')({
          current: this.get('passwordCurrent'),
          updated: this.get('passwordNew')
        }).then(function () {
          _this.resetPasswordForm();

          _this.set('success', true);
        }).catch(function (err) {
          var message = err.message;

          if (err.payload) {
            message = err.payload.errors[0].detail;
          }

          _this.resetPasswordForm();

          _this.set('error', message);
        });
      }
    }
  });

  _exports.default = _default;
});