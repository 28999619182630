define("funnelytics/models/goal", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    name: _emberData.default.attr('string'),
    value: _emberData.default.attr('number'),
    type: _emberData.default.attr('string'),
    target: _emberData.default.attr('string'),
    source: _emberData.default.attr('string'),
    funnel: _emberData.default.belongsTo('funnel'),
    added_to_kpi: _emberData.default.attr('boolean')
  });

  _exports.default = _default;
});