define("funnelytics/templates/components/ip-blacklist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wAp6wm/J",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"fl-input-icon-wrapper\"],[9],[0,\"\\n  \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"value\"],[\"text\",\"fl-input-icon-field padding-icon\",[23,[\"ip\",\"ip\"]]]]],false],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"txt--grey fl-input-icon input-text txt--hover--red fl-btn-txt\"],[3,\"action\",[[22,0,[]],\"delete\",[23,[\"ip\"]]]],[9],[0,\"\\n    Delete\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/ip-blacklist.hbs"
    }
  });

  _exports.default = _default;
});