define("funnelytics/custom/analytics/analytics/canvas/EntityChangeManager", ["exports", "lodash", "@funnelytics/utilities", "ember-uuid", "funnelytics/custom/constants/ListenerConstants"], function (_exports, _lodash, _utilities, _emberUuid, _ListenerConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  /**
   * This class interfaces with the CanvasEntity class and provides
   * pub and sub capabilities. The change manager is necessary in this
   * case rather than adding the pub and sub capabilities directly
   * to the CanvasEntities as was previously attempted since the
   * CanvasEntities themselves are shortlived and constantly being
   * destroyed and constructed. As such, we use this change manager
   * instead in order to keep track of the IDs of the cells publishing
   * updates to coordinate subscribers with publishers.
   */
  var CanvasChangeManager = Ember.Object.extend({
    _subscriptionPathsById: {},
    init: function init() {
      this._setupSubscriptions();
    },
    register: function register(entityId, subscriptionName, observerFunction) {
      var _ref = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {},
          _ref$debounceWait = _ref.debounceWait,
          debounceWait = _ref$debounceWait === void 0 ? 100 : _ref$debounceWait,
          _ref$debounceMaxWait = _ref.debounceMaxWait,
          debounceMaxWait = _ref$debounceMaxWait === void 0 ? undefined : _ref$debounceMaxWait,
          _ref$isDebounceLeadin = _ref.isDebounceLeading,
          isDebounceLeading = _ref$isDebounceLeadin === void 0 ? true : _ref$isDebounceLeadin;

      _utilities.Assertion.string(entityId, {
        allowEmpty: false,
        allowNull: false
      });

      _utilities.Assertion.validString(subscriptionName, _ListenerConstants.default.VALID);

      var subscriptionId = (0, _emberUuid.v4)();
      var subscriptions = this.get('_subscriptions');
      var subscriptionsForEntity = Ember.getWithDefault(subscriptions, entityId, {});
      var subscriptionsByName = Ember.getWithDefault(subscriptionsForEntity, subscriptionName, Ember.A([]));
      subscriptionsByName.pushObject({
        subscriptionId: subscriptionId,
        observerFunction: _lodash.default.debounce(function () {
          observerFunction.apply(void 0, arguments);
        }, debounceWait, {
          maxWait: debounceMaxWait,
          leading: isDebounceLeading
        })
      });
      Ember.set(subscriptionsForEntity, subscriptionName, subscriptionsByName);
      Ember.set(subscriptions, entityId, subscriptionsForEntity);
      this.set("_subscriptionPathsById.".concat(subscriptionId), [entityId, subscriptionName]);
      return subscriptionId;
    },
    unregister: function unregister(subscriptionId) {
      _utilities.Assertion.string(subscriptionId, {
        allowEmpty: false,
        allowNull: false
      });

      var subscriptionPath = this.get("_subscriptionPathsById.".concat(subscriptionId));

      if (!subscriptionPath) {
        return false;
      }

      var _subscriptionPath = _slicedToArray(subscriptionPath, 2),
          entityId = _subscriptionPath[0],
          subscriptionName = _subscriptionPath[1];

      var subscriptions = this.get('_subscriptions');
      var subscriptionsForEntity = Ember.getWithDefault(subscriptions, entityId, {});
      var subscriptionsByName = Ember.getWithDefault(subscriptionsForEntity, subscriptionName, Ember.A([]));

      var subscriptionObject = _lodash.default.find(subscriptionsByName, function (entitySub) {
        return Ember.get(entitySub, 'subscriptionId') === subscriptionId;
      });

      if (!subscriptionObject) {
        return false;
      }

      subscriptionsByName.removeObject(subscriptionObject);
      return subscriptionId;
    },
    unregisterAll: function unregisterAll(entityId) {
      _utilities.Assertion.string(entityId, {
        allowEmpty: false,
        allowNull: false
      });

      var subscriptions = this.get('_subscriptions'); // Remove all subscriptions associated with this entity

      Ember.set(subscriptions, entityId, undefined);
      return entityId;
    },
    notify: function notify(entityId, subscriptionName) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }

      var subscriptions = this.get('_subscriptions');
      var subscriptionsForEntity = Ember.getWithDefault(subscriptions, entityId, {});
      var subscriptionsByName = Ember.getWithDefault(subscriptionsForEntity, subscriptionName, Ember.A([]));
      subscriptionsByName.forEach(function (subscriptionObject) {
        var observerFunction = Ember.get(subscriptionObject, 'observerFunction');
        observerFunction.apply(void 0, args);
      });
    },
    _getObservers: function _getObservers(listener) {
      return _lodash.default.get(this._listeners, [listener], null);
    },
    _setupSubscriptions: function _setupSubscriptions() {
      this.set('_subscriptions', {});
    },
    _getValidSubscriptions: function _getValidSubscriptions() {
      return [_ListenerConstants.default.ENTITY_ATTRIBUTES_CHANGE, _ListenerConstants.default.ENTITY_IS_DEPENDENT_CHANGE];
    }
  });
  var _default = CanvasChangeManager;
  _exports.default = _default;
});