define("funnelytics/templates/components/left-sidebar/icon-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nP0Pj2SK",
    "block": "{\"symbols\":[\"page\"],\"statements\":[[7,\"div\"],[11,\"class\",\"section-icon\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"icons-set__header\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"icons-set__type\"],[9],[0,\"\\n      \"],[1,[21,\"section-title\"],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"hasIcons\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"icons-set\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"icons\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"left-sidebar-list-item\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"left-sidebar__container\"],[12,\"ondragstart\",[27,\"action\",[[22,0,[]],\"handleDragStart\",[23,[\"icon-type\"]],[22,1,[]]],null]],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"handleSelect\",[23,[\"icon-type\"]],[22,1,[]]],null]],[9],[0,\"\\n            \"],[7,\"img\"],[11,\"class\",\"left-sidebar__icon\"],[12,\"draggable\",[28,[[21,\"draggable\"]]]],[12,\"src\",[22,1,[\"path\"]]],[9],[10],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"left-sidebar__icon-title\"],[9],[0,\"\\n            \"],[1,[22,1,[\"name\"]],false],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/left-sidebar/icon-section.hbs"
    }
  });

  _exports.default = _default;
});