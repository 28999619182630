define("funnelytics/templates/components/for-reuse/alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "397Lvukk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"fl-alert \",[27,\"concat\",[\"fl-alert--\",[23,[\"severity\"]]],null]]]],[9],[0,\"\\n  \"],[7,\"svg\"],[11,\"class\",\"fl-alert__icon\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"severity\"]],\"warning\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-status-error\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"severity\"]],\"error\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-analytics-error\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"severity\"]],\"info\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-notification-circle\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"fl-alert__message\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"fl-alert__message--header\"],[9],[0,\"\\n      \"],[1,[21,\"header\"],false],[0,\":\\n    \"],[10],[0,\"\\n    \"],[1,[21,\"message\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/alert.hbs"
    }
  });

  _exports.default = _default;
});