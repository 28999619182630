define("funnelytics/components/input-validation/input-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['fl-input-wrapper', 'fl--pb--1']
  });

  _exports.default = _default;
});