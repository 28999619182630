define("funnelytics/templates/components/tool-tip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o9NaAobz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\"],[11,\"class\",\"sidebar-field-status icon-error\"],[12,\"src\",[21,\"iconPath\"]],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"hoverable-tooltip\"],[9],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"tool-tip\"],[9],[0,\"\\n  \"],[1,[21,\"text\"],false],[0,\"\\n  \"],[7,\"a\"],[12,\"href\",[28,[[21,\"link\"]]]],[11,\"target\",\"_blank\"],[9],[0,\"\\n    Learn More.\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/tool-tip.hbs"
    }
  });

  _exports.default = _default;
});