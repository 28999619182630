define("funnelytics/components/dashboard/mapping-lock-banner", ["exports", "moment", "funnelytics/custom/constants/MembershipConstants", "funnelytics/services/banner-display", "funnelytics/config/environment"], function (_exports, _moment, _MembershipConstants, _bannerDisplay, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    versionManager: Ember.inject.service(),
    projectPermissions: Ember.inject.service(),
    workspaceSelector: Ember.inject.service(),
    router: Ember.inject.service(),
    bannerDisplay: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    manualShowBanner: true,
    lockDate: Ember.computed('projectPermissions.user', function () {
      var downgradeDate = this.get('projectPermissions').getUser().get('downgrade_date');
      return (0, _moment.default)(downgradeDate).format('MMMM Do, YY');
    }),
    showbanner: Ember.computed('projectPermissions.membership', 'projectPermissions.user', function () {
      var membership = this.get('projectPermissions.membership');
      var user = this.get('projectPermissions').getUser();
      var shouldShowBanner = this.get('manualShowBanner') && membership === _MembershipConstants.Membership.isMapping && user.get('downgrade_date') !== null;
      this.get('bannerDisplay').setBannerState(_bannerDisplay.BannerType.lockFreeMap, shouldShowBanner);
      return shouldShowBanner;
    }),
    hasPaymentMethod: Ember.computed('projectPermissions.hasStripeSubscription', function () {
      return this.projectPermissions.hasStripeSubscription;
    }),
    btnLink: Ember.computed('projectPermissions.user', function () {
      var user = this.get('projectPermissions').getUser();
      var link = _environment.default.deployTarget === 'production' ? "https://buy.stripe.com/fZe4gFees16caNW8xA?prefilled_promo_code=MAPPROOFFER&client_reference_id=".concat(user.get('id'), "&prefilled_email=").concat(user.get('email')) : "https://buy.stripe.com/test_14k5lveNB27jaGYcMS?prefilled_promo_code=MAPPROOFFER&client_reference_id=".concat(user.get('id'), "&prefilled_email=").concat(user.get('email'));
      return link;
    }),
    actions: {
      closeBanner: function closeBanner() {
        this.set('manualBannerDisable', false);
        this.get('bannerDisplay').setBannerState(_bannerDisplay.BannerType.lockFreeMap, false);
      }
    }
  });

  _exports.default = _default;
});