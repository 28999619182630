define("funnelytics/components/goal-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    goalManager: Ember.inject.service(),
    targetLabel: Ember.computed('goal.source', function () {
      var source = this.get('goal').get('source');

      if (!source) {
        return '';
      }

      return this.get('goalManager').getElementLabelById(source);
    }),
    saveGoalOnChange: Ember.observer('goal.name', 'goal.value', 'goal.source', 'goal.type', function () {
      this.get('goalManager').saveGoalsDebounce();
    }),
    actions: {
      selectType: function selectType(value) {
        this.get('goal').set('type', value);
      },
      removeSource: function removeSource() {
        this.get('goal').set('source', null);
      },
      removeGoal: function removeGoal() {
        this.get('goalManager').deleteGoal(this.get('goal'));
      }
    }
  });

  _exports.default = _default;
});