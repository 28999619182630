define("funnelytics/components/containers/funnel-editor/components/right-sidebar-settings", ["exports", "lodash", "funnelytics/custom/encoding/converter", "funnelytics/custom/analytics/analytics/AnalyticsHelpers", "funnelytics/custom/constants/CanvasConstants", "funnelytics/custom/constants/AnalyticsConstants", "funnelytics/custom/analytics/analytics/canvas/EntityParameter"], function (_exports, _lodash, _converter, _AnalyticsHelpers, _CanvasConstants, _AnalyticsConstants, _EntityParameter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { if (typeof Symbol === "undefined" || !(Symbol.iterator in Object(arr))) return; var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var UTM_PROPS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
  var MAX_LENGTH_UTM_VALUE = 2048;
  var NAVIGATION_MAPPING = 'mapping';
  var NAVIGATION_FORECASTING = 'forecasting';
  var NAVIGATION_TRACKING = 'tracking';

  var _default = Ember.Component.extend({
    classNames: ['right-sidebar'],
    analyticsService: Ember.inject.service(),
    canvasService: Ember.inject.service(),
    eventOrParameterObserver: function eventOrParameterObserver() {},
    props: Ember.Object.create({}),
    fieldStatus: Ember.Object.create({}),
    trackingInputFieldVisibility: 'utm',
    sidebarType: NAVIGATION_MAPPING,
    isSetToMapping: Ember.computed.equal('sidebarType', NAVIGATION_MAPPING),
    isSetToForecasting: Ember.computed.equal('sidebarType', NAVIGATION_FORECASTING),
    isSetToTracking: Ember.computed.equal('sidebarType', NAVIGATION_TRACKING),
    urlParser: document.createElement('a'),
    CANVAS_TYPE_PAGE: _CanvasConstants.default.TYPE_PAGE,
    CANVAS_TYPE_EVENT: _CanvasConstants.default.TYPE_EVENT,
    CANVAS_TYPE_SOURCE: _CanvasConstants.default.TYPE_SOURCE,
    CANVAS_TYPE_OFFLINE: _CanvasConstants.default.TYPE_OFFLINE,
    navigationOptions: [{
      key: NAVIGATION_MAPPING,
      label: 'MAPPING'
    }, {
      key: NAVIGATION_FORECASTING,
      label: 'FORECASTING'
    }, {
      key: NAVIGATION_TRACKING,
      label: 'TRACKING'
    }],
    parameterCount: 0,
    parameterCountDisplay: Ember.computed('parameterCount', function () {
      if (this.get('parameterCount') <= 0) {
        return '';
      }

      return "(".concat(this.get('parameterCount'), ")");
    }),
    propertiesCount: 0,
    propertiesCountDisplay: Ember.computed('propertiesCount', function () {
      if (this.get('propertiesCount') <= 0) {
        return '';
      }

      return "(".concat(this.get('propertiesCount'), ")");
    }),
    utmCount: 0,
    utmCountDisplay: Ember.computed('utmCount', function () {
      if (this.get('utmCount') <= 0) {
        return '';
      }

      return "(".concat(this.get('utmCount'), ")");
    }),
    urlValidityRegEx: /^(?:(?:(?:https?):)?\/\/)?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
    // eslint-disable-line
    isAction: Ember.computed('model.type', function () {
      return this.get('model').prop('type') === _CanvasConstants.default.TYPE_EVENT;
    }),
    eventSettings: {
      type: 'custom',
      identifier: null
    },
    calculateParameterCount: function calculateParameterCount() {
      this.set('parameterCount', _lodash.default.get(this.get('model').get('parameters'), 'length', 0));
    },
    calculatePropertyCount: function calculatePropertyCount() {
      this.set('propertiesCount', _lodash.default.get(_lodash.default.keys(this.get('model').attr('properties')), 'length', 0));
    },
    calculateUTMCount: function calculateUTMCount() {
      var _this = this;

      if (this.get('model.attributes.type') !== _CanvasConstants.default.TYPE_SOURCE) {
        return;
      }

      var utmsWithContent = [];
      UTM_PROPS.forEach(function (utm) {
        if (_this.get("model.attributes.attrs.".concat(utm, ".text")).length > 0) {
          utmsWithContent.push(utm);
        }
      });
      this.set('utmCount', utmsWithContent.length);
    },
    persistUrlAndParameters: _lodash.default.debounce(function () {}, 1250),
    urlShouldUpdate: 0,
    init: function init() {
      this._super.apply(this, arguments);

      this.set('eventSettings', {
        type: _lodash.default.get(this.get('model'), ['attributes', 'attrs', 'triggerType', 'text']) || null,
        identifier: _lodash.default.get(this.get('model'), ['attributes', 'attrs', 'triggerIdentifier', 'text']) || null
      });
    },
    tearDownSidebar: function tearDownSidebar() {
      this.set('fieldStatus', {});
      this.get('persistUrlAndParameters').flush();
    },
    updateProps: function updateProps() {
      this.set('props', _lodash.default.reduce(this.get('model').attributes.attrs, function (hash, val, key) {
        var value = _lodash.default.get(val, 'text') || '';

        if (key === '.label') {
          hash['label'] = _lodash.default.get(val, ['textWrap', 'text']);
        }

        hash[key] = value;
        return hash;
      }, {}));
    },
    initializeProperties: function initializeProperties() {
      var _this2 = this;

      this.set('_modifyUrlParametersDebounce', _lodash.default.debounce(function (parsedParams) {
        var entityParameters = _lodash.default.map(parsedParams, function (value, key) {
          return new _EntityParameter.default({
            key: key,
            value: value,
            isTrafficUtm: _AnalyticsHelpers.default.isUTM(key)
          });
        });

        var entity = _this2.get('canvasService').wrapEntity(_this2.get('model'));

        entity.replaceAllAttributes(entityParameters);

        _this2.updateProps();
      }, 1500));
      this.set('hovering', true);
      this.set('persistUrlAndParameters', _lodash.default.debounce(function (input, target) {
        return _this2._persistUrlAndParametersInner(input, target);
      }, 1250));
      this.calculateParameterCount();
      this.calculatePropertyCount();
      this.calculateUTMCount();
      this.updateProps();
      /**
       * utm_content and utm_term are absent from shapes.js definition, we add the fields in with
       * empty values for historical elements
       */

      if (this.get('model').attributes.type === _CanvasConstants.default.TYPE_SOURCE && !this.get('model').attributes.attrs.utm_content) {
        this.modifyProps('utm_content/text', '');
      }

      if (this.get('model').attributes.type === _CanvasConstants.default.TYPE_SOURCE && !this.get('model').attributes.attrs.utm_term) {
        this.modifyProps('utm_term/text', '');
      } // Encode UTM parameters in case the values are stored without encoding


      _lodash.default.forEach(UTM_PROPS, function (utmParameter) {
        var currentValue = _this2.get("props.".concat(utmParameter));

        if (currentValue) {
          _this2.decodeAndStoreUtmParameter(currentValue, utmParameter);
        }
      }); // Perform initial prop validation


      _lodash.default.forEach(_lodash.default.keys(this.get('model').attr()), function (prop) {
        if (UTM_PROPS.includes(prop)) {
          _this2.utmVerification(_this2.get('model').attributes.attrs[prop].text, prop);
        }

        if (prop === 'url') {
          _this2.urlVerification(_this2.get('model').attributes.attrs[prop].text);
        }

        if (prop === 'name' && _this2.get('model').attributes.type === _CanvasConstants.default.TYPE_EVENT) {
          _this2.eventNameVerification(_this2.get('model').attributes.attrs[prop].text);
        }
      });
    },
    didInsertElement: function didInsertElement() {
      this.initializeProperties();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.get('model')) {
        this.initializeProperties();
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.tearDownSidebar();
    },
    eventNameVerification: function eventNameVerification(input) {
      if (!input || input.length === 0) {
        this.set('fieldStatus.event', 'error');
      } else if (input.length >= 512) {
        this.set('fieldStatus.event', 'length');
      } else {
        this.set('fieldStatus.event', 'success');
      }
    },
    urlParameterChecker: function urlParameterChecker(urlInput) {
      if (urlInput.includes('?')) {
        return true;
      }
    },
    urlSuccessTruthValidation: function urlSuccessTruthValidation(input) {
      if (input.length > 0 && !input.includes('&') && _AnalyticsHelpers.default.noUnsafeCharacters(input)) {
        return true;
      } else {
        return false;
      }
    },
    assembleQueryParameters: function assembleQueryParameters(input) {
      var startOfParametersIndex = input.indexOf('?') + 1;

      if (startOfParametersIndex === 0) {
        return [];
      }

      var splitUrlWithQueryParameters = input.substring(startOfParametersIndex).split('&');
      return splitUrlWithQueryParameters.map(function (element) {
        return element.split('=');
      }).map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            value = _ref2[1];

        return [key, value].map(function (string) {
          return _converter.default.decode(string);
        });
      }).filter(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
            key = _ref4[0],
            value = _ref4[1];

        return !_lodash.default.isEmpty(key) || !_lodash.default.isEmpty(value);
      }).map(function (_ref5) {
        var _ref6 = _slicedToArray(_ref5, 2),
            key = _ref6[0],
            value = _ref6[1];

        return {
          type: _AnalyticsConstants.default.PARAM_CONTAINS,
          key: key,
          value: value
        };
      });
    },
    validateURLWithRegEx: function validateURLWithRegEx(input) {
      var regExResult = this.urlValidityRegEx.exec(input);
      return regExResult;
    },
    _persistUrlAndParametersInner: function _persistUrlAndParametersInner(input) {
      var _this3 = this;

      var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;

      if (this.urlParameterChecker(input)) {
        this.set('urlShouldUpdate', this.get('urlShouldUpdate') + 1);
        this.set('fieldStatus.url', 'info');
        var arrayOfGeneratedParameters = this.assembleQueryParameters(input);
        arrayOfGeneratedParameters.forEach(function (element) {
          if (!_lodash.default.find(_this3.get('model').get('parameters'), {
            key: element.key,
            value: element.value,
            type: _AnalyticsConstants.default.PARAM_CONTAINS
          })) {
            var parameter = {
              key: element['key'],
              value: element['value'],
              type: _AnalyticsConstants.default.PARAM_CONTAINS
            };

            if (_this3.get('model').get('parameters')) {
              _this3.get('model').get('parameters').addObject(parameter);
            } else if (_this3.get('model').get('hidden_parameters')) {
              _this3.get('model').get('hidden_parameters').addObject(parameter);
            }
          }
        });
        this.calculateParameterCount();

        var _input$split = input.split('?'),
            _input$split2 = _slicedToArray(_input$split, 1),
            urlWithoutParameters = _input$split2[0];

        if (target) {
          target.value = urlWithoutParameters;
        }

        this.modifyProps('url/text', urlWithoutParameters);
        return;
      }

      this.modifyProps('url/text', input);
      return;
    },
    urlVerification: function urlVerification(input) {
      var target = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var validURL = this.validateURLWithRegEx(input);

      var urlIsSafe = _AnalyticsHelpers.default.noUnsafeCharacters(input);

      this.get('persistUrlAndParameters')(input, target);

      if (input.length === 0 || !validURL) {
        this.set('fieldStatus.url', 'error');
        return;
      }

      if (input.length >= 512) {
        this.set('fieldStatus.url', 'length');
        return;
      }

      if (!urlIsSafe) {
        this.set('fieldStatus.url', 'error-unsafe');
        return;
      }

      if (this.urlSuccessTruthValidation(input) && validURL) {
        this.set('fieldStatus.url', 'success');
        return;
      }

      if (this.urlParameterChecker(input)) {
        this.set('fieldStatus.url', 'info');
      }
    },
    utmVerification: function utmVerification(utmInput, utmField) {
      this.calculateUTMCount();

      var _utmField$split = utmField.split('/'),
          _utmField$split2 = _slicedToArray(_utmField$split, 1),
          utmFieldName = _utmField$split2[0];

      if (utmInput.length === 0) {
        this.set("fieldStatus.".concat(utmFieldName), {
          status: 'info',
          parameterMessage: 'Funnelytics uses UTM Parameters to track traffic sources.',
          helpLink: 'http://help.funnelytics.io/analytics-features/tracking/connecting-traffic-icons-utms'
        });
      } else if (utmInput.length >= MAX_LENGTH_UTM_VALUE) {
        this.set("fieldStatus.".concat(utmFieldName), {
          status: 'length',
          lengthMessage: 'Parameters must be equal to or less than 2048 characters.',
          helpLink: 'http://help.funnelytics.io/analytics-features/tracking/connecting-traffic-icons-utms'
        });
      } else {
        this.set("fieldStatus.".concat(utmFieldName), {
          status: 'success',
          helpLink: null
        });
      }
    },
    decodeAndStoreUtmParameter: function decodeAndStoreUtmParameter(value, utmParameter) {
      if (UTM_PROPS.includes(utmParameter)) {
        var newUtmValue = _converter.default.decode(value);

        var utmField = "".concat(utmParameter, "/text");
        this.modifyProps(utmField, newUtmValue);
        this.utmVerification(newUtmValue, utmField);
      }
    },
    modifyProps: function modifyProps(label, value) {
      if (!this.get('model')) {
        return;
      }

      var key;

      if (label === '.label/textWrap/text') {
        key = 'label';
      } else {
        key = label.replace('/text', '').replace('.', '');
      }

      this.set("props.".concat(key), value);
      this.get('model').attr(label, value);
    },
    onUpdateThumbnail: function onUpdateThumbnail() {},
    onFunnelIsEdited: function onFunnelIsEdited() {},
    actions: {
      updateThumbnail: function updateThumbnail() {
        var updateHandler = this.get('onUpdateThumbnail');
        updateHandler.apply(void 0, arguments);
      },
      hoverState: function hoverState() {
        this.set('hovering', true);
      },
      inputFieldChange: function inputFieldChange(fieldTarget, evt) {
        var newValue = evt.target.value;
        this.modifyProps(fieldTarget, newValue);

        if (fieldTarget === 'url/text') {
          this.urlVerification(newValue, evt.target);
        }

        if (fieldTarget === 'name/text') {
          this.eventNameVerification(newValue, fieldTarget);
        } else if (_lodash.default.map(UTM_PROPS, function (utmValue) {
          return "".concat(utmValue, "/text");
        }).includes(fieldTarget)) {
          this.utmVerification(newValue, fieldTarget);
          this.send('updateURLPreview');
        }
      },
      inputPasteFieldChange: function inputPasteFieldChange(fieldTarget, evt) {
        var _this4 = this;

        setTimeout(function () {
          _this4.send('inputFieldChange', fieldTarget, evt);
        }, 10);
      },
      updateIcon: function updateIcon(icon) {
        var model = this.get('model');

        if (_lodash.default.get(model, 'attributes.attrs[".previewthumbnail"].display') === 'block') {
          model.attr({
            '.previewthumbnail': {
              display: 'none'
            },
            g: {
              display: 'none'
            }
          });
        }

        var setFunnelAsEdited = this.get('onFunnelIsEdited');
        setFunnelAsEdited(true);
        model.attr(joint.util.deepSupplement({
          image: {
            'xlink:href': icon.path,
            display: 'block'
          }
        }, _lodash.default.get(joint.shapes, model.attributes.type).prototype.defaults));
      },
      utmEncodedCharacters: function utmEncodedCharacters(fieldName, event) {
        this.decodeAndStoreUtmParameter(event.target.value, fieldName);
      },
      propertiesAndParameterChangeObserver: function propertiesAndParameterChangeObserver() {
        this.calculateParameterCount(); // pages

        this.calculatePropertyCount(); // events

        this.eventOrParameterObserver();
      },
      updateURLPreview: function updateURLPreview() {
        // TODO: May want to check the model that this is a traffic type step
        this.set('urlPreview', this.get('analyticsService').generateURL(_lodash.default.reduce(_AnalyticsHelpers.default.getSourceParameters(this.get('model')), function (hash, entry) {
          if (entry.key && entry.contains) {
            hash[entry.key] = entry.value;
          }

          return hash;
        }, {}), this.get('model')));
      },
      modifyModelParameters: function modifyModelParameters(fullUrl) {
        this.get('urlParser').href = fullUrl;
        var parsedParameters = [];
        var parameters = this.get('urlParser').search;

        if (parameters) {
          parsedParameters = _AnalyticsHelpers.default.parseParamString(parameters);
        }

        var updateParametersHandler = this.get('_modifyUrlParametersDebounce');
        updateParametersHandler(parsedParameters);
      },
      toggleTypeOfTrackingInputs: function toggleTypeOfTrackingInputs(evt) {
        if (evt === 'utm') {
          this.setProperties({
            trackingInputFieldVisibility: 'parameters',
            utmSelectionActive: null,
            customSelectionActive: 'active'
          });
        } else {
          this.setProperties({
            trackingInputFieldVisibility: 'utm',
            utmSelectionActive: 'active',
            customSelectionActive: null
          });
        }
      },
      setTriggerType: function setTriggerType(value) {
        this.set('eventSettings.type', value);
        this.modifyProps('triggerType/text', value);
      },
      setActionIdentifier: function setActionIdentifier(value) {
        this.set('eventSettings.identifier', value);
        this.modifyProps('triggerIdentifier/text', value);
      },
      setSidebarType: function setSidebarType(type) {
        this.set('sidebarType', type);
      }
    }
  });

  _exports.default = _default;
});