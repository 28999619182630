define("funnelytics/custom/analytics/analytics-request/ExplorerAnalyticsRequest", ["exports", "lodash", "funnelytics/custom/constants/CanvasConstants", "funnelytics/custom/constants/AnalyticsConstants", "funnelytics/custom/analytics/AnalyticsRequest", "funnelytics/custom/analytics/analytics-data/ExplorerAnalyticsData"], function (_exports, _lodash, _CanvasConstants, _AnalyticsConstants, _AnalyticsRequest, _ExplorerAnalyticsData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var LOADED_PAGE = 'loaded_page';

  var ExplorerAnalyticsRequest = _AnalyticsRequest.default.extend({
    _mainAnalyticsData: _ExplorerAnalyticsData.default.create(),
    nextPage: 1,
    _createAnalyticsData: function _createAnalyticsData(initialValues) {
      return _ExplorerAnalyticsData.default.create(initialValues);
    },
    _sendRequest: function _sendRequest(analyticsSettings) {
      var _this = this;

      return Ember.RSVP.Promise.resolve(true).then(function () {
        var isDependentForExplorer = _this._isExploredEntityDependent(analyticsSettings);

        var _this$_getDependentNe = _this._getDependentNeighbours(analyticsSettings),
            sources = _this$_getDependentNe.sources,
            targets = _this$_getDependentNe.targets;

        var dependentSourceStepIds = sources.map(function (entity) {
          return entity.getID();
        });
        var dependentTargetStepIds = targets.map(function (entity) {
          return entity.getID();
        }); // May want to modify what getExploredStepFilter produces based on options passed
        // This would help us configure the query without modifying the underlying entity...
        // this is, however, a problem for other reasons (we'd be introducing a new way of
        // changing how entity settings are updated that are unique to this part of the
        // code and disconnected from the usual interactions)

        return _this._getAjax().request('/traffic-explorer/', {
          method: 'POST',
          host: _this._getAnalyticsApiHostname(),
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader(_AnalyticsConstants.default.PROJECT_ID_HEADER, analyticsSettings.getProject());
          },
          data: {
            filter: {
              device: analyticsSettings.getDevice(),
              explored_step: analyticsSettings.isExploredEntityHidden() ? undefined : analyticsSettings.getExploredStepFilter(),
              explorer: {
                explored_step_is_dependent_action: isDependentForExplorer,
                source_steps: dependentSourceStepIds,
                target_steps: dependentTargetStepIds
              },
              canvas: _this._formCanvasFilter(analyticsSettings),
              focus: analyticsSettings.getFocus(),
              session: analyticsSettings.getSession(),
              range: analyticsSettings.getRange(),
              profiles: {
                countries: {
                  allow: analyticsSettings.getCountriesFilter()
                }
              },
              pagination: {
                page: _this.get('nextPage')
              }
            },
            options: {
              peopleVsTotal: analyticsSettings.getPeopleVsPageviews()
            }
          }
        }).then(function (explorerResults) {
          var exploredEntityId = null;

          if (analyticsSettings.hasExploredEntity()) {
            exploredEntityId = analyticsSettings.getExploredEntity().getID();
          }

          var data = explorerResults;
          var loadedPage = parseInt(_lodash.default.get(data, ['page'], 1), 10);

          var meta = _defineProperty({
            exploredEntityId: exploredEntityId
          }, LOADED_PAGE, loadedPage);

          return {
            data: data,
            meta: meta
          };
        });
      });
    },

    /**
     * This doesn't reference only true dependent actions, but also actions that would potentially
     * have either a new next step or previous step cause them to become dependent.
     */
    _isExploredEntityDependent: function _isExploredEntityDependent(analyticsSettings) {
      if (analyticsSettings.areDependentNeighboursIncluded()) {
        return true;
      }

      if (!analyticsSettings.hasExploredEntity()) {
        return false;
      }

      var exploredEntity = analyticsSettings.getExploredEntity();

      if (exploredEntity.getType() !== _CanvasConstants.default.TYPE_EVENT) {
        return false;
      }

      var directOutboundLinks = exploredEntity.getOutboundLinksCount({
        isIgnoreInBetweenAllowed: false
      });
      var directInboundLinks = exploredEntity.getInboundLinksCount({
        isIgnoreInBetweenAllowed: false
      });
      return (exploredEntity.getDependentSource() !== null || exploredEntity.getDependentTarget() !== null) && directOutboundLinks <= 1 && directInboundLinks <= 1;
    },

    /**
     * Add all the elements to the canvas filter required to service this request
     */
    _populateCanvasFilter: function _populateCanvasFilter(canvasFilter, analyticsSettings) {
      var focusAndExploredEntities = [];

      if (analyticsSettings.hasFocusedEntity()) {
        focusAndExploredEntities.push(analyticsSettings.getFocusedEntity());
      }

      if (analyticsSettings.hasExploredEntity()) {
        focusAndExploredEntities.push(analyticsSettings.getExploredEntity());
      }

      var _this$_getDependentNe2 = this._getDependentNeighbours(analyticsSettings),
          sources = _this$_getDependentNe2.sources,
          targets = _this$_getDependentNe2.targets;

      var entitiesToAdd = _lodash.default.concat(focusAndExploredEntities, sources, targets);

      _AnalyticsRequest.default.addEntitiesToCanvasFilter(canvasFilter, entitiesToAdd);

      return canvasFilter;
    },
    _getDependentNeighbours: function _getDependentNeighbours(analyticsSettings) {
      var sources = [];
      var targets = [];

      if (analyticsSettings.hasExploredEntity() || analyticsSettings.areDependentNeighboursIncluded()) {
        var exploredEntity = analyticsSettings.getExploredEntity();

        if (this._isExploredEntityDependent(analyticsSettings)) {
          var sourceEntity = exploredEntity.getDependentSource();

          if (sourceEntity) {
            sources.push(sourceEntity);
          }

          var targetEntity = exploredEntity.getDependentTarget();

          if (targetEntity) {
            targets.push(targetEntity);
          }
        }
      }

      return {
        sources: sources,
        targets: targets
      };
    },
    _handleValidRequestBeforePopulate: function _handleValidRequestBeforePopulate(_ref) {
      var meta = _ref.meta;
      this.set('nextPage', _lodash.default.get(meta, [LOADED_PAGE], 1) + 1);
    },
    _handleIsNotPartial: function _handleIsNotPartial() {
      this.set('nextPage', 1);
    }
  });

  var _default = ExplorerAnalyticsRequest;
  _exports.default = _default;
});