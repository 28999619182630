define("funnelytics/components/library/import-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    displayCount: null,
    hideCount: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.setDisplayCount();
    },
    setDisplayCount: function setDisplayCount() {
      var count = this.get('importCount');

      if (count > 999) {
        var thousands = this.reduceThousands();
        this.set('displayCount', thousands);
      } else if (count === 0) {
        this.set('hideCount', true);
      } else {
        this.set('displayCount', count);
      }
    },
    reduceThousands: function reduceThousands() {
      /**
       * Round count up to nearest hundreds ex. 1120 -> 1200
       * Then divide by 1000 to get thousands ex. 1200 -> 1.2
       * Append 'k' to the end ex. 1.2 -> 1.2k
       */
      var count = this.get('importCount');
      var roundedCount = Math.ceil(count / 100) * 100;
      var thousands = roundedCount / 1000;
      return "".concat(thousands, "k");
    }
  });

  _exports.default = _default;
});