define("funnelytics/components/upsells/upgrade-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    upsellManager: Ember.inject.service(),
    router: Ember.inject.service(),
    header: null,
    firstCopy: null,
    secondCopy: null,
    reloadWindow: false,
    thirdCopy: null,
    showMessageModal: Ember.computed('upsellManager.showSubscriptionCTAMessage', function () {
      return this.upsellManager.showSubscriptionCTAMessage;
    }),
    resetParam: function resetParam() {},
    reset: function reset() {
      this.set('upsellManager.showSubscriptionCTA', false);
      this.set('upsellManager.showSubscriptionCTAMessage', false);
      this.resetParam();
      this.setProperties({
        header: null,
        firstCopy: null,
        secondCopy: null,
        reloadWindow: false,
        thirdCopy: null
      });
    },
    actions: {
      close: function close() {
        if (this.reloadWindow) {
          this.get('router').transitionTo('dashboard.settings.my-subscriptions');
        }

        this.reset();
        this.set('upsellManager.showSubscriptionCTA', false);
        this.set('upsellManager.showSubscriptionCTAMessage', false);
      }
    }
  });

  _exports.default = _default;
});