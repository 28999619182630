define("funnelytics/helpers/to-lower", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.toLower = toLower;
  _exports.default = void 0;

  function toLower(params
  /*, hash*/
  ) {
    if (params[0]) {
      return params[0].toLowerCase();
    }
  }

  var _default = Ember.Helper.helper(toLower);

  _exports.default = _default;
});