define("funnelytics/components/account/login-register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    emailProvided: Ember.computed.notEmpty('identification'),
    passwordProvided: Ember.computed.notEmpty('password'),
    notLoggingIn: Ember.computed.not('loggingIn'),
    canLogIn: Ember.computed.and('notLoggingIn', 'emailProvided', 'passwordProvided'),
    cannotLogIn: Ember.computed.not('canLogIn'),
    session: Ember.inject.service(),
    didInsertElement: function didInsertElement() {
      this.set('loginOrRegister', 'register');
    },
    actions: {
      toggleLoginOrRegister: function toggleLoginOrRegister() {
        if (this.get('loginOrRegister') === 'register') {
          this.set('loginOrRegister', 'login');
        } else {
          this.set('loginOrRegister', 'register');
        }
      },
      authenticate: function authenticate() {
        return; // console.log('is this shit firing');
        // const self = this;
        // const { identification, password } = this.getProperties(
        //   'identification',
        //   'password'
        // );
        // console.log({
        //   identification,
        //   password
        // });
        // if (this.get('canLogIn')) {
        //   this.set('loggingIn', true);
        //   this.get('session')
        //     .authenticate('authenticator:oauth2', identification, password)
        //     .then(() => {
        //       console.log('yeah success');
        //       // this.get('router').transitionTo(
        //       //   'dashboard.funnels.view',
        //       //   record.get('id')
        //       // );
        //     })
        //     .catch(res => {
        //       this.set('loggingIn', false);
        //       const msg = _object.has(res, 'message')
        //         ? res.message
        //         : 'Failed to login';
        //       self.set('error', msg);
        //     });
        // }
      }
    }
  });

  _exports.default = _default;
});