define("funnelytics/components/for-reuse/upsell-modal", ["exports", "funnelytics/custom/constants/Upsell", "funnelytics/custom/constants"], function (_exports, _Upsell, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ANALYTICS_CONTENT = {
    title: 'Viewing numbers is only available to Pro members…',
    description: 'Track your funnels visually with Pro!',
    reason1: 'Track each step of your sales process',
    reason2: 'See conversion rates and performance',
    reason3: 'Optimize your funnel based on real numbers',
    visualContent: '/assets/analyze-upsell.gif',
    backgroundColor: 'upsell-banner--analytics',
    textColor: 'upsell__text--analytics',
    id: 'upsell_numbers'
  };
  var ICON_LIMIT_CONTENT = {
    title: 'You hit the maximum steps allowed on the canvas',
    description: 'Understand your entire funnel by mapping every step out on the canvas!',
    reason1: 'Unlimited canvas steps',
    reason2: 'Workspaces for organization',
    reason3: 'Collaborate with your clients',
    visualContent: '/assets/mapping-upsell.gif',
    backgroundColor: 'upsell-banner--analytics',
    textColor: 'upsell__text--analytics',
    id: 'upsell_numbers'
  };
  var TEMPLATES_CONTENT = {
    title: 'Templates are only available with a Funnelytics Pro account',
    description: 'Upgrade today and get access to templates and our full suite of Pro features',
    reason1: 'Forecast the potential of your funnel',
    reason2: 'Track people in real-time who flow through your funnel',
    reason3: 'And more...',
    visualContent: '/assets/mapping-upsell.gif',
    backgroundColor: 'upsell-banner--analytics',
    textColor: 'upsell__text--analytics',
    id: 'upsell_numbers'
  };
  var ANIMATION_CONTENT = {
    title: 'Viewing flow animations is only available to Pro members…',
    description: 'Visualize your funnel with Pro!',
    reason1: 'Display Flow Animations based on real numbers',
    reason2: 'Enhanced funnel visualization',
    reason3: 'Better visual clarity',
    visualContent: '/assets/flow-upsell.gif',
    backgroundColor: 'upsell-banner--animations',
    textColor: 'upsell__text--animations',
    id: 'upsell_flow'
  };
  var FORECASTING_CONTENT_FREE = {
    title: 'Creating forecasts is only available on Paid plans',
    description: 'Forecast conversion rates, ROI and spend',
    reason1: 'Plan for profitability by forecasting scenarios',
    reason2: 'Gain clarity on what it takes to reach your goals',
    reason3: 'Compare forecast to actual numbers',
    visualContent: '/assets/forecasting-upsell.png',
    staticImage: true,
    backgroundColor: 'upsell-banner--forecasting',
    textColor: 'upsell__text--forecasting',
    id: 'upsell_forecast'
  };
  var PRO_USER_IN_UNORGANIZED = {
    title: 'Move your funnel to a workspace',
    description: 'Workspaces allow you to start tracking your funnel. Move this funnel to a workspace in order to experience the following',
    reason1: 'Plan for profitability by forecasting scenarios',
    reason2: 'Gain clarity on what it takes to reach your goals',
    reason3: 'Compare forecast to actual numbers',
    visualContent: '/assets/analyze-upsell.gif',
    staticImage: true,
    backgroundColor: 'upsell-banner--analytics',
    textColor: 'upsell__text--analytics',
    id: 'upsell_unorganized_funnel',
    hideUpgradeButton: true
  };
  var MARKETER_USER_IN_UNORGANIZED = {
    title: 'Move your funnel to a workspace',
    description: 'Move this funnel into a workspace to start forecasting and experience the following',
    reason1: 'Plan for profitability by forecasting scenarios',
    reason2: 'Gain clarity on what it takes to reach your goals',
    visualContent: '/assets/forecasting-upsell.png',
    staticImage: true,
    backgroundColor: 'upsell-banner--forecasting',
    textColor: 'upsell__text--forecasting',
    id: 'upsell_forecast',
    hideUpgradeButton: true
  };
  var CUSTOM_IMAGE_CONTENT = {
    title: 'Boundless creativity with custom images',
    description: 'Add brand customization to your canvas with custom images',
    reason1: 'Upload unlimited images to your canvas',
    reason2: 'Personalize your canvas to keep it on-brand',
    visualContent: '/assets/custom-image-upsell.gif',
    backgroundColor: 'upsell-banner--forecasting',
    textColor: 'upsell__text--icons',
    id: 'upsell_custom_images'
  };
  var CUSTOM_ICONS_CONTENT = {
    title: 'Boundless creativity with custom icons',
    description: 'Custom icons give you the creative freedom to customize steps on your canvas',
    reason1: 'Upload unlimited custom icons',
    reason2: 'Personalize your canvas to keep it on-brand',
    visualContent: '/assets/custom-icons-upsell.gif',
    backgroundColor: 'upsell-banner--forecasting',
    textColor: 'upsell__text--images',
    id: 'upsell_custom_icons'
  };

  var _default = Ember.Component.extend({
    userDetails: Ember.inject.service(),
    onSelectWorkspace: function onSelectWorkspace() {},
    NULL_PROJECT: _constants.NULL_PROJECT,
    upsellTextStartTrial: _Upsell.UPSELL_TEXTS.start_free_trial,
    upsellUrlStartTrial: _Upsell.UPSELL_URLS.start_free_trial,
    didInsertElement: function didInsertElement() {
      if (this.get('userDetails.isPro')) {
        this.set('upsellContent', PRO_USER_IN_UNORGANIZED);
        return;
      }

      switch (this.get('upsellType')) {
        case 'Analytics':
          this.set('upsellContent', ANALYTICS_CONTENT);
          break;

        case 'Flow':
          this.set('upsellContent', ANIMATION_CONTENT);
          break;

        case 'Forecasting':
          if (this.get('userDetails.isMarketer')) {
            this.set('upsellContent', MARKETER_USER_IN_UNORGANIZED);
            return;
          }

          this.set('upsellContent', FORECASTING_CONTENT_FREE);
          break;

        case 'IconLimit':
          this.set('upsellContent', ICON_LIMIT_CONTENT);
          break;

        case 'Template':
          this.set('upsellContent', TEMPLATES_CONTENT);
          break;

        case 'CustomImage':
          this.set('upsellContent', CUSTOM_IMAGE_CONTENT);
          break;

        case 'CustomIcon':
          this.set('upsellContent', CUSTOM_ICONS_CONTENT);
          break;

        default:
          this.set('upsellContent', ANALYTICS_CONTENT);
          break;
      }
    },
    actions: {
      selectWorkspace: function selectWorkspace(id) {
        var handler = this.get('onSelectWorkspace');
        return handler(id);
      }
    }
  });

  _exports.default = _default;
});