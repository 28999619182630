define("funnelytics/components/subscriptions/modals/downgrade-process", ["exports", "funnelytics/helpers/get-plan-name"], function (_exports, _getPlanName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userDetails: Ember.inject.service(),
    downgradeModalManager: Ember.inject.service(),
    selectedChoice: null,
    // inputs
    isOpen: false,
    isAnnual: false,
    currentMembership: null,
    // computed
    userId: Ember.computed('userDetails.userId', function () {
      return this.userDetails.get('userId');
    }),
    downgradeToMembership: Ember.computed('downgradeModalManager.selectedMembership', function () {
      return this.downgradeModalManager.selectedMembership;
    }),
    benefitsToLose: Ember.computed('currentMembership', 'downgradeToMembership', function () {
      return this.downgradeModalManager.getLosingBenefits(this.currentMembership, this.downgradeToMembership);
    }),
    proposedMembership: Ember.computed('currentMembership', 'downgradeToMembership', function () {
      return this.downgradeModalManager.getProposedMembership(this.currentMembership, this.downgradeToMembership);
    }),
    choiceTitles: Ember.computed('proposedMembership', function () {
      if (this.proposedMembership === this.currentMembership) {
        return {
          propose: "Keep my plan",
          downgrade: "Downgrade my Account"
        };
      }

      return {
        propose: "Downgrade to ".concat((0, _getPlanName.getPlanName)([this.proposedMembership])),
        downgrade: "Downgrade to ".concat((0, _getPlanName.getPlanName)([this.downgradeToMembership]))
      };
    }),
    proposedText: Ember.computed('proposedMembership', function () {
      if (this.proposedMembership === this.currentMembership) {
        return "I don't want to lose these awesome features at this incredible price.";
      }

      return "I don't need all my features, but I don't want to lose these awesome features at this incredible price.";
    }),
    watchIsOpen: Ember.observer('isOpen', function () {
      // on open/close modal user choice should be resetted
      this.set('selectedChoice', null);
    }),
    // outputs
    onClose: function onClose() {},
    onSucess: function onSucess() {},
    actions: {
      close: function close() {
        this.onClose();
      },
      success: function success() {
        // do nothing if user didn't choose anything
        if (!this.selectedChoice) {
          return;
        }

        var selectedMembership = this.selectedChoice === 'propose' ? this.proposedMembership : this.downgradeToMembership;
        this.onSuccess(selectedMembership);
      },
      selectChoice: function selectChoice(choice) {
        this.set('selectedChoice', choice);
      }
    }
  });

  _exports.default = _default;
});