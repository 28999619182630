define("funnelytics/mixins/standardized-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    dateOptions: null,
    defaultDateOptions: {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    },
    intlFormat: null,
    defaultIntlFormat: 'en-US',
    getFormattedDate: function getFormattedDate(date) {
      return new Intl.DateTimeFormat(this.intlFormat || this.defaultIntlFormat, this.dateOptions || this.defaultDateOptions).format(date);
    }
  });

  _exports.default = _default;
});