define("funnelytics/templates/components/project-domain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ue0oR0lZ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"fl-input-icon-wrapper\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"type\",\"class\",\"placeholder\",\"value\"],[\"text\",\"fl-input-icon-field\",\"example.com\",[23,[\"domain\",\"domain\"]]]]],false],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"txt--grey fl-input-icon txt--hover--red pointer-txt\"],[3,\"action\",[[22,0,[]],[23,[\"removeDomain\"]],[23,[\"domains\"]],[23,[\"domain\"]]]],[9],[0,\"Delete\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/project-domain.hbs"
    }
  });

  _exports.default = _default;
});