define("funnelytics/components/project-icon", ["exports", "funnelytics/custom/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    NULL_PROJECT: _constants.NULL_PROJECT
  });

  _exports.default = _default;
});