define("funnelytics/custom/constants/LibraryConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Offer types
  var OFFER_TYPES = Object.freeze({
    GENERATE_APPLICATIONS: 'Generate Applications',
    GENERATE_LEADS: 'Generate Leads',
    GENERATE_RECURRING_REVENUE: 'Generate Recurring Revenue',
    GENERATE_SALES: 'Generate Sales',
    LIQUIDATE_AD_COSTS: 'Liquidate Ad Costs',
    SCHEDULE_APPOINTMENTS: 'Schedule Appointments',
    STOP_CANCELLATION: 'Stop Cancellation',
    STRATEGY_PLANNING: 'Strategy & Planning'
  });
  var INDUSTRIES = Object.freeze({
    MARKETING: 'Marketing',
    NUTRITION_FITNESS: 'Nutrition/Fitness',
    SELF_HELP: 'Self Help',
    ECOMMERCE: 'eCommerce',
    LOCAL_BUSINESS: 'Local Business',
    REAL_ESTATE: 'Real Estate',
    TRAVEL: 'Travel',
    BUSINESS: 'Business',
    SALES: 'Sales',
    EVENT: 'Event',
    LEADERSHIP: 'Leadership',
    CONSULTING: 'Consulting',
    FRANCHISE: 'Franchise',
    AFFILIATE_MARKETING: 'Affiliate Marketing',
    SAAS: 'SaaS',
    OTHER: 'Other'
  });
  var PURPOSES = Object.freeze({
    FRAMEWORK: 'Framework',
    FUNNEL_BREAKDOWN: 'Funnel Breakdown',
    REPORTS: 'Reports'
  });
  var CREATOR = Object.freeze({
    COMMUNITY: 'Community',
    FUNNELYTICS: 'Funnelytics'
  });
  var LibraryConstants = {
    PURPOSES: PURPOSES,
    INDUSTRIES: INDUSTRIES,
    OFFER_TYPES: OFFER_TYPES,
    CREATOR: CREATOR
  };
  var _default = LibraryConstants;
  _exports.default = _default;
});