define("funnelytics/templates/components/dashboard-elements/users/create-workspace-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8C5XnFLF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"id\",[21,\"identifier\"]],[11,\"class\",\"d-flex my-2\"],[9],[0,\"\\n  \"],[7,\"label\"],[12,\"for\",[21,\"inputIdentifier\"]],[9],[10],[0,\"\\n  \"],[7,\"input\"],[12,\"placeholder\",[21,\"wsPlaceholder\"]],[12,\"name\",[21,\"inputIdentifier\"]],[11,\"style\",\"width: 100%;\"],[11,\"type\",\"text\"],[9],[10],[0,\"\\n  \"],[7,\"label\"],[12,\"for\",[21,\"selectIdentifier\"]],[9],[10],[0,\"\\n  \"],[7,\"select\"],[12,\"name\",[21,\"selectIdentifier\"]],[11,\"style\",\"margin-left: 16px\"],[9],[0,\"\\n    \"],[7,\"option\"],[11,\"value\",\"4\"],[11,\"selected\",\"\"],[9],[0,\"4\"],[10],[0,\"\\n    \"],[7,\"option\"],[11,\"value\",\"2\"],[9],[0,\"2\"],[10],[0,\"\\n    \"],[7,\"option\"],[11,\"value\",\"1\"],[9],[0,\"1\"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"not-eq\",[[23,[\"index\"]],0],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"btn text-danger\"],[11,\"role\",\"button\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],[23,[\"deleteInput\"]],[23,[\"identifier\"]]],null]],[12,\"title\",[21,\"identifier\"]],[11,\"style\",\"padding-top: 0; padding-bottom: 0;\"],[9],[0,\"x\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"style\",\"width: 42px;\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/dashboard-elements/users/create-workspace-input.hbs"
    }
  });

  _exports.default = _default;
});