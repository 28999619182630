define("funnelytics/routes/dashboard/projects/index", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel: function beforeModel(transition) {
      var projectId = _lodash.default.get(transition, ['queryParams', 'project']);

      if (projectId) {
        return this.replaceWith('dashboard.projects.project.funnels', projectId);
      }

      return this.replaceWith('dashboard');
    }
  });

  _exports.default = _default;
});