define("funnelytics/routes/dashboard/users/view", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    model: function model(_ref) {
      var _this = this;

      var id = _ref.id;
      return Ember.RSVP.hash({
        user: this.get('store').findRecord('user', id, {
          include: ['membership', 'role', 'userProducts.product', 'recurlySubscriptions.subscription', 'analytics_ranges']
        }),
        memberships: this.get('store').findAll('membership'),
        products: this.get('store').findAll('product'),
        userWorkspaces: this.get('ajax').request("/projects/user/".concat(id), {
          method: 'GET'
        }).then(function (workspaces) {
          _this.get('store').pushPayload('project', workspaces);

          var workspacesArr = _lodash.default.map(workspaces.data, function (_ref2) {
            var id = _ref2.id;
            return _this.get('store').peekRecord('project', id);
          });

          return _lodash.default.sortBy(workspacesArr, function (workspace) {
            return workspace.name;
          });
        }).then(function (projectModels) {
          return projectModels;
        })
      });
    },
    actions: {
      refreshRoute: function refreshRoute() {
        this.refresh();
      },
      willTransition: function willTransition() {
        this.get('controller.model.user').rollbackAttributes();
        return true;
      }
    }
  });

  _exports.default = _default;
});