define("funnelytics/components/canvas-elements/analytics-toolbar/filters-dropdown", ["exports", "lodash", "funnelytics/custom/constants/AnalyticsConstants", "funnelytics/custom/misc/countries"], function (_exports, _lodash, _AnalyticsConstants, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Object$freeze, _Object$freeze2;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var mapCountriesMultiSelectItem = function mapCountriesMultiSelectItem(_ref) {
    var countryName = _ref.countryName,
        isSelected = _ref.isSelected,
        visitorsCount = _ref.visitorsCount,
        index = _ref.index,
        totalVisitorsCount = _ref.totalVisitorsCount;
    return {
      value: countryName,
      isSelected: isSelected,
      countryData: {
        countryCode: _lodash.default.get(_countries.countryToCodeObject, [countryName]),
        countryName: countryName,
        visitorsCount: visitorsCount
      },
      countryIndex: index,
      totalVisitorsCount: totalVisitorsCount
    };
  };

  var _default = Ember.Component.extend({
    analyticsService: Ember.inject.service(),
    isDeviceDropdownOpen: false,
    isCountriesDropdownOpen: false,
    isStepsDropdownOpen: false,
    isPeopleDropdownOpen: false,
    countriesTotalVisitorsCount: Ember.computed('countriesData', function () {
      var countriesData = this.get('countriesData') || [];
      return countriesData.reduce(function (sum, countryData) {
        return sum + Number(countryData.visitorsCount);
      }, 0);
    }),
    countriesDataForMultiSelect: Ember.computed('countriesData', function () {
      var countriesData = this.get('countriesData') || [];
      var totalVisitorsCount = this.get('countriesTotalVisitorsCount');
      var selectedCountryNames = this.get('selectedCountryNames');
      return countriesData.map(function (countryData, index) {
        return mapCountriesMultiSelectItem({
          countryName: countryData.countryName,
          isSelected: selectedCountryNames.includes(countryData.countryName),
          visitorsCount: countryData.visitorsCount,
          index: index + 1,
          totalVisitorsCount: totalVisitorsCount
        });
      });
    }),
    selectedCountriesString: Ember.computed('countriesFilter', function () {
      var countriesFilter = this.get('countriesFilter');

      if (!countriesFilter.length) {
        return 'All Countries';
      }

      return countriesFilter.join(', ');
    }),
    selectedCountries: Ember.computed('countriesDataForMultiSelect', 'selectedCountryNames', function () {
      var selectedCountryNames = this.get('selectedCountryNames');
      var allCountries = this.get('countriesDataForMultiSelect');
      var totalVisitorsCount = this.get('countriesTotalVisitorsCount');
      var indexOfNotPresentedCountry = allCountries.length;
      var selectedCountryItems = selectedCountryNames.map(function (countryName) {
        var index = allCountries.findIndex(function (c) {
          return c.value === countryName;
        });

        if (index >= 0) {
          return allCountries[index];
        }

        indexOfNotPresentedCountry++;
        return mapCountriesMultiSelectItem({
          countryName: countryName,
          isSelected: true,
          visitorsCount: 0,
          index: indexOfNotPresentedCountry,
          totalVisitorsCount: totalVisitorsCount
        });
      });
      return _lodash.default.sortBy(selectedCountryItems, 'countryIndex');
    }),
    mobileFilterOptions: Object.freeze((_Object$freeze = {}, _defineProperty(_Object$freeze, _AnalyticsConstants.default.DEVICES.DESKTOP, 'Desktop'), _defineProperty(_Object$freeze, _AnalyticsConstants.default.DEVICES.MOBILE, 'Mobile'), _Object$freeze)),
    requestModeFilterOptions: Object.freeze((_Object$freeze2 = {}, _defineProperty(_Object$freeze2, _AnalyticsConstants.default.QUERY_MODES.PEOPLE, 'Individual People'), _defineProperty(_Object$freeze2, _AnalyticsConstants.default.QUERY_MODES.TOTAL_NUMBERS, 'Total Numbers'), _Object$freeze2)),
    stepsFilterOptions: Object.freeze({}),
    peopleFilterOptions: Object.freeze({}),
    onSetCountries: function onSetCountries() {},
    onClearStepFocus: function onClearStepFocus() {},
    onFindStep: function onFindStep() {},
    onClearUserFocus: function onClearUserFocus() {},
    onFocusOnEntity: function onFocusOnEntity() {},
    onSelectEntity: function onSelectEntity() {},
    onSetDevice: function onSetDevice() {},
    onFocusOnSession: function onFocusOnSession() {},
    onLoadAdditionalSessions: function onLoadAdditionalSessions() {},
    didInsertElement: function didInsertElement() {
      this.set('selectedCountryNames', this.get('countriesFilter'));
    },
    actions: {
      setPeopleVsPageviews: function setPeopleVsPageviews(setting) {
        this.get('analyticsService').setPeopleVsPageviews(setting);
        this.get('analyticsService').alignAllRequests({
          isNew: true
        });
      },
      toggleDeviceDropdown: function toggleDeviceDropdown() {
        this.set('isPeopleVsPageviewsDropdownOpen', false);
        this.set('isCountriesDropdownOpen', false);
        this.set('isStepsDropdownOpen', false);
        this.set('isPeopleDropdownOpen', false);
        this.toggleProperty('isDeviceDropdownOpen');
      },
      toggleStepsDropdown: function toggleStepsDropdown() {
        this.set('isCountriesDropdownOpen', false);
        this.set('isDeviceDropdownOpen', false);
        this.set('isPeopleDropdownOpen', false);
        this.toggleProperty('isStepsDropdownOpen');
      },
      toggleCountriesDropdown: function toggleCountriesDropdown() {
        this.set('isPeopleVsPageviewsDropdownOpen', false);
        this.set('isDeviceDropdownOpen', false);
        this.set('isStepsDropdownOpen', false);
        this.set('isPeopleDropdownOpen', false);
        this.toggleProperty('isCountriesDropdownOpen');
      },
      togglePeopleVsPageviewsDropdown: function togglePeopleVsPageviewsDropdown() {
        this.set('isDeviceDropdownOpen', false);
        this.set('isCountriesDropdownOpen', false);
        this.toggleProperty('isPeopleVsPageviewsDropdownOpen');
      },
      toggleFocusFilterDropdown: function toggleFocusFilterDropdown() {
        this.set('isDeviceDropdownOpen', false);
        this.set('isPeopleVsPageviewsDropdownOpen', false);
        this.set('isCountriesDropdownOpen', false);
      },
      togglePeopleDropdown: function togglePeopleDropdown() {
        this.set('isCountriesDropdownOpen', false);
        this.set('isDeviceDropdownOpen', false);
        this.set('isStepsDropdownOpen', false);
        this.toggleProperty('isPeopleDropdownOpen');
      },
      toggleCountryFilter: function toggleCountryFilter(country) {
        var countryName = country.countryData.countryName;
        var isOn = this.get('selectedCountryNames').includes(countryName);
        var newList;

        if (isOn) {
          newList = this.get('selectedCountryNames').filter(function (countryFilterName) {
            return countryFilterName !== countryName;
          });
        } else {
          newList = [].concat(_toConsumableArray(this.get('selectedCountryNames')), [countryName]);
        }

        this.set('selectedCountryNames', newList);
        var foundItem = this.get('countriesDataForMultiSelect').find(function (dataItem) {
          return dataItem.value === countryName;
        });

        if (foundItem) {
          Ember.set(foundItem, 'isSelected', !foundItem.isSelected);
        }

        this.get('onSetCountries')(this.get('selectedCountryNames'));
      },
      clearStepFocus: function clearStepFocus() {
        var handler = this.get('onClearStepFocus');
        handler();
      },
      findStep: function findStep() {
        var handler = this.get('onFindStep');
        handler();
      },
      clearUserFocus: function clearUserFocus() {
        var handler = this.get('onClearUserFocus');
        handler();
      },
      focusOnEntity: function focusOnEntity(entity) {
        var handler = this.get('onFocusOnEntity');
        handler(entity);
      },
      selectEntity: function selectEntity(entity) {
        var handler = this.get('onSelectEntity');
        handler(entity);
      },
      setDevice: function setDevice(device) {
        var handler = this.get('onSetDevice');
        handler(device);
      },
      focusOnSession: function focusOnSession(user) {
        this.set('isPeopleDropdownOpen', false);
        var handler = this.get('onFocusOnSession');
        handler(user);
      },
      loadAdditionalSessions: function loadAdditionalSessions() {
        var handler = this.get('onLoadAdditionalSessions');
        handler();
      }
    }
  });

  _exports.default = _default;
});