define("funnelytics/templates/dashboard/projects/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RNMUh4Gz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"routable-modal--dialog\"],[9],[0,\"\\n  \"],[1,[27,\"dashboard-elements/workspaces/create\",null,[[\"user\"],[[23,[\"model\",\"user\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/dashboard/projects/new.hbs"
    }
  });

  _exports.default = _default;
});