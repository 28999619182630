define("funnelytics/templates/components/canvas-elements/analytics-status-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KGpIpb4l",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"\\n    analytics-status-button\\n    \",[21,\"additionalClasses\"],\"\\n    \",[27,\"if\",[[23,[\"isLoading\"]],\"in-progress\",\"\"],null],\"\\n  \"]]],[3,\"action\",[[22,0,[]],\"pressButton\"]],[9],[0,\"\\n  \"],[7,\"svg\"],[11,\"class\",\"icon icon-sync\"],[9],[0,\"\\n    \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-refresh\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[27,\"not\",[[23,[\"isLoading\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"svg\"],[11,\"class\",\"load-status icon\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isStale\"]]],null,{\"statements\":[[0,\"      \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-status-error\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"hasError\"]]],null,{\"statements\":[[0,\"      \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-analytics-error\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"isGood\"]]],null,{\"statements\":[[0,\"      \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-checkmark\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/analytics-status-button.hbs"
    }
  });

  _exports.default = _default;
});