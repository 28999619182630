define("funnelytics/helpers/format-metric", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatMetric = formatMetric;
  _exports.default = void 0;
  var COUNTRY_CODE = 'en-US';

  function formatMetric(params, hash) {
    var number = parseFloat(params[0], 10);
    var options = {
      maximumFractionDigits: 2
    };

    if (!_lodash.default.isNumber(number) || isNaN(number)) {
      return '-';
    }

    if (hash.isCurrency === true) {
      options.style = 'currency';
      options.currency = 'USD';
    }

    if (_lodash.default.isInteger(hash.decimals)) {
      options.maximumFractionDigits = hash.decimals;
    }

    return number.toLocaleString(COUNTRY_CODE, options);
  }

  var _default = Ember.Helper.helper(formatMetric);

  _exports.default = _default;
});