define("funnelytics/templates/components/canvas-elements/project-change-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ydAn+eRV",
    "block": "{\"symbols\":[\"project\"],\"statements\":[[7,\"div\"],[11,\"class\",\"unorganized-funnel-warning fl--mr--1\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"dropdown-list\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"dropdown\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"dropdown-list__button\"],[3,\"action\",[[22,0,[]],[23,[\"toggleProjectDropdown\"]]]],[9],[0,\"\\n        Workspace\\n        \"],[7,\"svg\"],[11,\"class\",\"icon kpi-chevron kpi-chevron--inside-button\"],[9],[0,\"\\n          \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-chevron-down\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[28,[\"\\n          dropdown-content\\n          \",[27,\"if\",[[23,[\"isProjectDropdownOpen\"]],\"dropdown-content--visible\",\"\"],null],\"\\n        \"]]],[9],[0,\"\\n        \"],[7,\"h2\"],[11,\"class\",\"fl--75--font fl-padding-dropdown__item fl-heading fl-heading-normalize\"],[9],[0,\"MOVE TO WORKSPACE:\"],[10],[0,\"\\n        \"],[7,\"hr\"],[11,\"class\",\"fl-line-normalize\"],[9],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"userProjects\"]]],null,{\"statements\":[[0,\"          \"],[7,\"a\"],[11,\"class\",\"dropdown-blocks fl--75--font fl-padding-dropdown__item\"],[11,\"href\",\"#\"],[3,\"action\",[[22,0,[]],\"selectProject\",[22,1,[\"id\"]]]],[9],[0,\"\\n            \"],[1,[22,1,[\"name\"]],false],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"svg\"],[11,\"class\",\"icon unorganized-funnel-warning__icon\"],[9],[0,\"\\n    \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-status-error\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[1,[21,\"explanationMessage\"],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/project-change-dropdown.hbs"
    }
  });

  _exports.default = _default;
});