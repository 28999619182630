define("funnelytics/components/canvas-elements/kpi-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    forecastService: Ember.inject.service(),
    hasContent: Ember.computed('isEmptyHidden', 'value', function () {
      if (!this.get('isEmptyHidden')) {
        return true;
      }

      return Boolean(this.get('value'));
    }),
    colourClass: Ember.computed('cardColour', function () {
      switch (this.get('cardColour')) {
        case 'red':
          return 'data-card--red';

        case 'green':
          return 'data-card--green';

        default:
          return '';
      }
    }),
    // 'day' or 'days' depending on if talking about just 1 day
    daysLabel: Ember.computed('numberOfDays', function () {
      var numberOfDays = this.get('numberOfDays');

      if (numberOfDays === 1) {
        return "".concat(numberOfDays, " day");
      }

      return "".concat(numberOfDays, " days");
    }),
    isForecastingActive: Ember.computed('forecastService.isActive', function () {
      return this.get('forecastService.isActive');
    }),
    isBetterThanForecast: Ember.computed('higherValueIsBetter', 'value', 'forecastValue', function () {
      if (this.get('higherValueIsBetter')) {
        return this.get('value') > this.get('forecastValue');
      }

      return this.get('forecastValue') > this.get('value');
    }),
    higherValueIsBetter: true,
    actions: {
      addExpenses: function addExpenses() {
        this.toggleProperty('editingExpenses');
      },
      setExpenses: function setExpenses(e) {
        this.get('setExpenses')(e.target.value);
      }
    }
  });

  _exports.default = _default;
});