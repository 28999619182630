define("funnelytics/controllers/dashboard/projects/project/clients", ["exports", "funnelytics/custom/collaborators", "funnelytics/custom/constants/TrackingConstants", "funnelytics/custom/tracking/ProductTracker", "lodash"], function (_exports, _collaborators, _TrackingConstants, _ProductTracker, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var initialClientData = {
    first_name: '',
    last_name: '',
    email: '',
    permissions: 1,
    placeholder: {
      first_name: 'John',
      last_name: 'Doe',
      email: 'example@example.com'
    }
  };

  var _default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    versionManager: Ember.inject.service(),
    inviting: false,
    isEditing: false,
    error: null,
    currentClientData: null,
    isEnabled: Ember.computed('versionManager.activeDashboardVersion', function () {
      return this.get('versionManager.activeDashboardVersion') > 1 ? false : true;
    }),
    newClient: Ember.Object.create(initialClientData),
    newFirstSet: Ember.computed.notEmpty('newClient.first_name'),
    newLastSet: Ember.computed.notEmpty('newClient.last_name'),
    newEmailSet: Ember.computed.notEmpty('newClient.email'),
    newPermissionsSet: Ember.computed.match('newClient.permissions', /[01]/),
    newClientReady: Ember.computed.and('newFirstSet', 'newLastSet', 'newEmailSet', 'newPermissionsSet'),
    newClientNotReady: Ember.computed.not('newClientReady'),
    cannotCreateNewClient: Ember.computed.or('newClientNotReady', 'inviting'),
    canCreateNewClient: Ember.computed.not('cannotCreateNewClient'),
    resetNewClient: function resetNewClient() {
      this.set('newClient', Ember.Object.create(initialClientData));
      this.send('closeClientModal');
    },
    init: function init() {
      this.set('collaborators', new _collaborators.default(this));
    },
    actions: {
      activateEditMode: function activateEditMode(currentClient) {
        this.set('isEditing', true);
        this.set('currentClientData', {
          first_name: currentClient.get('user.first_name'),
          last_name: currentClient.get('user.last_name'),
          email: currentClient.get('user.email')
        });
      },
      handleCreatePermissions: function handleCreatePermissions(value) {
        this.set('newClient.permissions', value);
      },
      handleUpdatePermissions: function handleUpdatePermissions(clientRelation, index, evt) {
        var permission = _lodash.default.get(evt, ['target', 'value'], 0);

        clientRelation.set('permissions', permission);
        this.send('updateClient', clientRelation, index);
      },
      updateClient: function updateClient(clientRelation, index) {
        this.get('collaborators').updateClient(clientRelation, index);
      },
      sendInvite: function sendInvite() {
        var _this = this;

        var newClient = this.get('newClient');

        if (this.get('canCreateNewClient')) {
          this.set('inviting', true);
          this.get('ajax').request('/project-clients/invite', {
            method: 'POST',
            data: {
              newClient: {
                first_name: newClient.get('first_name'),
                last_name: newClient.get('last_name'),
                email: newClient.get('email'),
                permissions: newClient.get('permissions')
              },
              project: {
                id: this.get('model.project.id'),
                name: this.get('model.project.name')
              }
            }
          }).then(function (newClient) {
            _this.set('inviting', false);

            _this.get('store').pushPayload(newClient);

            var newClientModel = _this.get('store').peekRecord('project-client', _lodash.default.get(newClient, ['data', 'id']));

            if (newClientModel) {
              _this.get('model.clients').pushObject(newClientModel);
            }

            _this.set('error', null);

            _ProductTracker.default.trigger(_TrackingConstants.default.COLLABORATE_INVITE, {
              write: newClientModel.get('permissions') === 1
            }); // reset controller state


            _this.resetNewClient();
          }).catch(function (err) {
            _this.set('inviting', false);

            if ('errors' in err && err.errors.length > 0) {
              _this.set('error', err.errors[0].detail);
            } else {
              _this.set('error', 'An unexpected error occurred.');
            }
          });
        } else {
          this.set('error', 'Please fill out all the fields');
        }
      },
      deleteClient: function deleteClient(clientModel) {
        var _this2 = this;

        var permissions = clientModel.get('permissions');
        clientModel.destroyRecord().then(function () {
          var route = Ember.getOwner(_this2).lookup('route:dashboard.projects.project.clients');

          _ProductTracker.default.trigger(_TrackingConstants.default.COLLABORATE_REMOVE, {
            write: permissions === 1
          });

          route.refresh();
        }); // this.get('collaborators').sendInvite(true);
      },
      // deleteClient(clientModel) {
      //   this.get('collaborators').deleteClient(clientModel);
      // },
      openClientModal: function openClientModal() {
        Ember.$('#collaborator-add-modal').css('display', 'block');
      },
      closeClientModal: function closeClientModal() {
        Ember.$('#collaborator-add-modal').css('display', 'none');
      }
    }
  });

  _exports.default = _default;
});