define("funnelytics/components/funnel-pages/confirmation", ["exports", "@funnelytics/utilities"], function (_exports, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RecurlySKUs = _utilities.constants.RecurlySKUs;

  var _default = Ember.Component.extend({
    invoices: [],
    totalDue: Ember.computed('invoices', function () {
      return this.get('invoices').reduce(function (total, invoice) {
        return total += Number(invoice.get('total_in_cents'));
      }, 0);
    }),
    showProCTA: Ember.computed('invoices', function () {
      return invoicesHaveProductCodes(this.get('invoices'), [// TODO: Consider cleaning these up, which are we still even offering to users when they are purchasing something?
      // TODO: This might be something more appropriately managed through an array maintained on RecurlySKUs from utilities
      'pro_yearly', 'pro_yearly_upsell', 'pro_quarterly', 'pro_100k', 'pro_250k', 'pro_500k', 'pro_monthly_1_project', 'pro_monthly_2_projects', 'pro_monthly_10_projects', 'pro_monthly_20_projects', 'pro_annually_1_project', 'pro_annually_2_projects', 'pro_annually_10_projects', 'pro_annually_20_projects', 'pro_annual_tiered_120k', RecurlySKUs.PRO_ANNUALLY_TIERED_120K_TO_300K]);
    }),
    showVaultCTA: Ember.computed('invoices', function () {
      return invoicesHaveProductCodes(this.get('invoices'), ['vault', 'vault_lifetime']);
    }),
    showIgniteCTA: Ember.computed('invoices', function () {
      return invoicesHaveProductCodes(this.get('invoices'), ['ignite_templates']);
    }),
    showQuickWinsCTA: Ember.computed('invoices', function () {
      return invoicesHaveProductCodes(this.get('invoices'), ['quick_wins', 'quick_wins_lifetime']);
    })
  });

  _exports.default = _default;

  function invoicesHaveProductCodes(invoices, productCodes) {
    return invoices.reduce(function (allItems, invoice) {
      invoice.items.forEach(function (item) {
        allItems.push(item);
      });
      return allItems;
    }, []).some(function (item) {
      // TODO: This needs to be based off of the underlying subscriptions...!
      return productCodes.includes(item.get('product_code'));
    });
  }
});