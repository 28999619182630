define("funnelytics/components/canvas-elements/context-menu", ["exports", "funnelytics/custom/constants/CanvasConstants", "funnelytics/custom/canvas/ContextMenu/ContextMenuConstants"], function (_exports, _CanvasConstants, _ContextMenuConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    canvasService: Ember.inject.service(),
    analyticsService: Ember.inject.service(),
    isExploreTypeStep: Ember.computed.equal('initialEntityType', _ContextMenuConstants.default.INITIAL_ENTITY_TYPE_EXPLORE),
    isTracking: Ember.computed.equal('initialEntityType', _ContextMenuConstants.default.INITIAL_ENTITY_TYPE_TRACKING),
    isSimpleAction: Ember.computed('exploredEntity', function () {
      if (!this.get('exploredEntity')) {
        return false;
      }

      if (this.get('exploredEntity').getType() !== _CanvasConstants.default.TYPE_EVENT) {
        return false;
      }

      return this.get('exploredEntity').isSimpleAction();
    }),
    exploredEntity: Ember.computed.readOnly('analyticsService.explorerExploredEntity'),
    isList: Ember.computed.equal('style', _ContextMenuConstants.default.STYLE_LIST),
    isMapperOpen: Ember.computed.not('isExplorerOpen'),
    initialized: false,
    style: _ContextMenuConstants.default.STYLE_LIST,
    contextActions: [],
    hasActions: Ember.computed.notEmpty('contextActions'),
    isMenuVisible: Ember.computed.and('visible', 'hasActions'),
    sideBarIconType: undefined,
    onModifyEntities: function onModifyEntities() {},
    setup: Ember.observer('paper', function () {
      var _this = this;

      if (this.get('paper') && this.get('initialized') === false) {
        var menu = this.get('paper').getContextMenu();
        this.set('initialized', true);
        this.set('contextActions', menu.getActions());
        this.set('visible', menu.isVisible());
        this.set('entity', menu.getEntity());
        menu.on('menu:elementChange', function () {
          _this.set('entity', menu.getEntity());

          _this.set('contextActions', menu.getActions());
        });
        menu.on('menu:initialEntityChange', function () {
          var initialEntity = menu.getInitialEntity();

          _this.get('analyticsService').setExploredEntity(initialEntity);

          var initialEntityType = menu.getInitialEntityType();

          _this.set('initialEntityType', initialEntityType);

          if (initialEntity) {
            if (initialEntityType === _ContextMenuConstants.default.INITIAL_ENTITY_TYPE_EXPLORE) {
              _this.send('openExplorer');
            }

            if (_this.get('isSimpleAction')) {
              _this.send('openMapper');
            }
          }
        });
        menu.on('menu:visibilityChange', function () {
          _this.set('visible', menu.isVisible());
        });
        menu.on('menu:styleChange', function () {
          _this.set('style', menu.getStyle());
        });
      }
    }),
    initializeProperties: function initializeProperties() {
      this.setProperties({
        isExplorerOpen: false,
        sideBarIconType: 'page'
      });
    },
    didInsertElement: function didInsertElement() {
      this.initializeProperties();
    },
    setIconType: function setIconType(type) {
      this.set('sideBarIconType', type);
    },
    actions: {
      openExplorer: function openExplorer() {
        this.set('isExplorerOpen', true);
      },
      openMapper: function openMapper() {
        this.set('isExplorerOpen', false);
      },
      trigger: function trigger(index) {
        this.get('paper').getContextMenu().triggerAction(index);
      },
      onIconSelect: function onIconSelect(type, icon) {
        this.get('canvasService').addStep(type, icon.name, icon.path);
      },
      modifyEntities: function modifyEntities(entities) {
        var modifyEntitiesHandler = this.get('onModifyEntities');
        modifyEntitiesHandler(entities);
      }
    }
  });

  _exports.default = _default;
});