define("funnelytics/services/facebook", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    status: null,
    init: function init(args) {
      var _this = this;

      this._super(args);

      FB.getLoginStatus(function (res) {
        _this.set('status', res.status);
      }.bind(this));
    },
    connect: function connect() {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        FB.login(function (res) {
          if (res.error) {
            return reject(res.error);
          }

          _this2.set('status', res.status);

          return resolve(res);
        }.bind(_this2), {
          scope: 'ads_management,ads_read'
        });
      });
    },
    disconnect: function disconnect() {
      var _this3 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        FB.api('me/permissions', 'delete', function (res) {
          if (res.error) {
            return reject(res.error);
          }

          _this3.set('status', null);

          return resolve(res);
        }.bind(_this3));
      });
    }
  });

  _exports.default = _default;
});