define("funnelytics/components/goal-creator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    goalManager: Ember.inject.service(),
    goals: Ember.computed.reads('goalManager.goals'),
    isAbleToCreateGoal: Ember.computed.equal('goals.length', 0),
    defaultGoalName: 'Untitled Goal',

    /*
    didInsertElement() {
      console.log('goal manager', this.get('goalManager'));
    },
    */
    actions: {
      addGoal: function addGoal() {
        this.get('goalManager').addGoal({
          name: this.get('defaultGoalName')
        });
      }
    }
  });

  _exports.default = _default;
});