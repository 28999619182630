define("funnelytics/templates/dashboard/icons/folders/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0771WEnB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"col-md-12\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row justify-content-center\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-6 col-sm-12\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"error\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"alert alert-danger\"],[9],[1,[21,\"error\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"h2\"],[9],[0,\"Create Icon Folder\"],[10],[0,\"\\n      \"],[7,\"form\"],[11,\"method\",\"post\"],[11,\"class\",\"mt-4\"],[3,\"action\",[[22,0,[]],\"create\",[23,[\"model\"]]],[[\"on\"],[\"submit\"]]],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n          \"],[7,\"label\"],[11,\"for\",\"name\"],[9],[0,\"Name\"],[10],[0,\"\\n          \"],[1,[27,\"input\",null,[[\"type\",\"id\",\"class\",\"placeholder\",\"value\"],[\"text\",\"name\",\"form-control\",\"Folder Name\",[23,[\"model\",\"name\"]]]]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"dashboard.icons\"],[[\"class\"],[\"btn btn-link float-left\"]],{\"statements\":[[0,\"            \"],[7,\"i\"],[11,\"class\",\"fa fa-chevron-left\"],[11,\"aria-hidden\",\"true\"],[9],[10],[0,\"\\n            \"],[7,\"span\"],[9],[0,\"Go back\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"          \"],[7,\"input\"],[11,\"class\",\"btn btn-primary float-right\"],[11,\"value\",\"Create\"],[11,\"type\",\"submit\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/dashboard/icons/folders/new.hbs"
    }
  });

  _exports.default = _default;
});