define("funnelytics/controllers/dashboard/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SPLASH_CARDS = [{
    title: 'Plan & Launch a New Funnel',
    description: "If you are launching a new funnel for any product or service\u2014start here.\n    Learn about the Funnelytics features that will help get you from planning to\n    launch faster and with more confidence in the results you can achieve.",
    callToAction: 'Plan & Launch a New Funnel',
    video: 'https://player.vimeo.com/video/366545737?title=0&byline=0&portrait=0&controls=false',
    image: '/assets/dashboard/dashboard-learning-img-2.png',
    openModalType: 'plan-map'
  }, {
    title: 'Optimize an Existing Funnel',
    description: "Funnelytics helps you optimize a funnel by giving you all the metrics that\n    matter at your fingertips. Our canvas shows you exactly how each step is\n    performing giving you clarity on exactly what can be done to improve your funnel.",
    callToAction: 'Optimize An Existing Funnel',
    video: 'https://player.vimeo.com/video/366553925?title=0&byline=0&portrait=0&controls=false',
    image: '/assets/dashboard/dashboard-learning-img-1.png',
    openModalType: 'forecast-close'
  }, {
    title: 'Sell Funnels & Retain Clients',
    callToAction: 'Sell Funnels & Retain Clients',
    video: 'https://player.vimeo.com/video//386980374?title=0&byline=0&portrait=0&controls=false',
    description: "Funnelytics is the ultimate platform to help you sell funnels to other businesses\n    and retain them as clients for the long term. Learn about the Funnelytics\n    features that were built to help you create consistency in your marketing\n    services business.",
    image: '/assets/dashboard/dashboard-learning-img-3.png',
    openModalType: 'track-retain'
  }];

  var _default = Ember.Controller.extend({
    project: null,
    session: Ember.inject.service(),
    flashMessages: Ember.inject.service(),
    modalType: null,
    timestamp: new Date().getTime(),
    isFirefox: navigator.userAgent.toLowerCase().indexOf('firefox') > -1,
    splashCardData: SPLASH_CARDS,
    init: function init() {
      this.set('modalType', 'trackingLimit');
    },
    actions: {
      setModalType: function setModalType(type) {
        this.set('modalType', type);
      }
    }
  });

  _exports.default = _default;
});