define("funnelytics/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "funnelytics/config/environment", "lodash"], function (_exports, _applicationRouteMixin, _environment, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    funnelyticsSegmentation: Ember.inject.service(),
    funnelyticsRedirect: Ember.inject.service(),
    cookiesService: Ember.inject.service(),
    session: Ember.inject.service(),
    router: Ember.inject.service(),
    hubspotChat: Ember.inject.service(),
    routeAfterAuthentication: _environment.default['ember-simple-auth'].routeAfterAuthentication,
    redirectKey: 'authenticationRedirect',
    title: function title(tokens) {
      var funnelyticsTitle = 'Funnelytics';

      if (tokens.length <= 0) {
        return funnelyticsTitle;
      }

      return tokens.join(' - ') + " - ".concat(funnelyticsTitle);
    },
    sessionAuthenticated: function sessionAuthenticated() {
      var cookiesService = this.get('cookiesService');
      cookiesService.saveAuthToken();
      var isSegmenting = this.get('funnelyticsSegmentation').isSegmenting();

      if (isSegmenting) {
        return;
      }

      this._super.apply(this, arguments);
    },
    sessionInvalidated: function sessionInvalidated() {
      // this.get('hubspotChat').remove();
      var cookiesService = this.get('cookiesService');
      cookiesService.deleteAuthToken();
      var currentRoute = this.get('router.currentRouteName');

      if (currentRoute === 'library.index') {
        window.location.reload();
      } else {
        this._super.apply(this, arguments);
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },
    actions: {
      error: function error(err) {
        // this.get('session').invalidate();
        if (_lodash.default.get(err, 'errors.0.status') === 503) {
          this.get('router').transitionTo('503');
        }
      },
      getQueuedRedirect: function getQueuedRedirect() {
        return localStorage.getItem(this.get('redirectKey'));
      },
      hasQueuedRedirect: function hasQueuedRedirect() {
        return this.get('actions.getQueuedRedirect').apply(this) !== null;
      },
      doRedirect: function doRedirect(transition) {
        var search = window.location.search.slice(1);

        var params = _lodash.default.reduce(search.split('&'), function (hash, val) {
          var pair = val.split('=');
          hash[pair[0]] = decodeURIComponent(pair[1]);
          return hash;
        }, {});

        if (params.redirect) {
          window.localStorage.setItem(this.get('redirectKey'), JSON.stringify({
            redirect: params.redirect
          }));
        } else if (params.url) {
          window.localStorage.setItem(this.get('redirectKey'), JSON.stringify({
            url: params.url,
            param: params.param
          }));
        }

        this.get('funnelyticsRedirect').performStorageBasedRedirect({
          transition: transition
        });
      },
      didTransition: function didTransition() {
        var _this = this;

        this.send('doRedirect');
        Ember.run.scheduleOnce('afterRender', this, function () {
          var path = _this.get('router.currentRouteName').split('.');

          if (path[0] === 'dashboard') {
            if (path[1] !== 'funnels' && path[2] !== 'view' || path[1] !== 'templates' && path[2] !== 'view') {// Beamer.init();
            }
          }

          if (window.funnelytics) {
            if (!window.funnelytics.stepped) {
              window.funnelytics.stepped = true;

              if (window.funnelytics.functions && window.funnelytics.functions.step) {
                window.funnelytics.functions.step();
              }
            }
          } else {
            window.funnelytics_queued = true;
          }
        }); //this.send('dispatchDataLayer');
      },

      /*
        doRedirect will perform performStorageBasedRedirect.
        This function removes the redirect information from the localStorage.
        May be a contributing factor.
       */
      willTransition: function willTransition(transition) {
        this.send('doRedirect', transition);

        if (window.funnelytics) {
          window.funnelytics.stepped = false;
        }
      },
      dispatchDataLayer: function dispatchDataLayer() {
        var user;
        var currentPath = this.get('router.currentRouteName') || '';
        var isPublicRoute = Boolean(!currentPath || currentPath.includes('login') || currentPath.includes('register') || currentPath.includes('forgot') || currentPath.includes('reset') || currentPath.includes('share') || currentPath.includes('library'));

        if (!isPublicRoute && (user = this.modelFor('dashboard').user) && window.dataLayer) {
          window.dataLayer.push({
            event: 'LOAD_PAGE',
            visitorType: 'loggedIn',
            email: user.get('email').toLowerCase(),
            firstName: user.get('first_name').toLowerCase(),
            lastName: user.get('last_name').toLowerCase(),
            pageTitle: currentPath,
            pageType: currentPath
          });
        } // end if

      }
    }
  });

  _exports.default = _default;
});