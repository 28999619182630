define("funnelytics/custom/analytics/analytics-data/SessionsAnalyticsData", ["exports", "@funnelytics/utilities", "lodash", "funnelytics/custom/analytics/AnalyticsData", "funnelytics/custom/constants/AnalyticsConstants"], function (_exports, _utilities, _lodash, _AnalyticsData, _AnalyticsConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var SessionsAnalyticsData = _AnalyticsData.default.extend({
    _type: 'profile-attributes',
    sessionsList: Ember.computed.readOnly('_sessionsList'),
    sessionsCount: Ember.computed.readOnly('_sessionsCount'),
    _sessionsList: [],
    _sessionsCount: 0,
    _populate: function _populate(_ref) {
      var data = _ref.data;
      this.setProperties({
        _sessionsCount: parseInt(_lodash.default.get(data, [_AnalyticsConstants.default.SESSIONS_OUTPUT.KEYS.SESSIONS_COUNT], []), 10),
        _sessionsList: _lodash.default.get(data, [_AnalyticsConstants.default.SESSIONS_OUTPUT.KEYS.SESSIONS_LIST], 0)
      });
    },
    _replaceWith: function _replaceWith(profileAttributesAnalyticsData) {
      _utilities.Assertion.instanceOf(profileAttributesAnalyticsData, SessionsAnalyticsData);

      this.setProperties({
        _sessionsCount: profileAttributesAnalyticsData.get('sessionsCount'),
        _sessionsList: profileAttributesAnalyticsData.get('sessionsList')
      });
    },
    _merge: function _merge(explorerAnalyticsData) {
      _utilities.Assertion.instanceOf(explorerAnalyticsData, SessionsAnalyticsData);

      this.get('_sessionsList').addObjects(explorerAnalyticsData.get('sessionsList'));
    }
  });

  var _default = SessionsAnalyticsData;
  _exports.default = _default;
});