define("funnelytics/components/hubspot-chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['hubspot-chat-container'],
    hubspotChat: Ember.inject.service(),
    actions: {
      onClose: function onClose() {
        this.get('hubspotChat').close();
      }
    }
  });

  _exports.default = _default;
});