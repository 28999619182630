define("funnelytics/helpers/text-truncate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.textTruncate = textTruncate;
  _exports.default = void 0;

  function textTruncate(params
  /*, hash*/
  ) {
    var text = params.text,
        truncateLength = params.truncateLength,
        appendText = params.appendText;
    return text.length > truncateLength ? "".concat(text.substring(0, truncateLength)).concat(appendText) : text;
  }

  var _default = Ember.Helper.helper(textTruncate);

  _exports.default = _default;
});