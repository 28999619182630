define("funnelytics/custom/analytics/settings/config/standard-action-value-converters", ["exports", "funnelytics/custom/analytics/analytics/commerce/CommerceConstants"], function (_exports, _CommerceConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = _defineProperty({
    DEFAULT: function DEFAULT(attributeValue) {
      return attributeValue;
    }
  }, _CommerceConstants.default.ATTRIBUTES.TOTAL_IN_CENTS.DATABASE_NAME, function (attributeValue) {
    // Converting to full dollars
    var intValue = parseInt(attributeValue);

    if (!intValue) {
      return attributeValue;
    }

    var dollarValue = intValue / 100;
    return dollarValue.toFixed(2);
  });

  _exports.default = _default;
});