define("funnelytics/components/left-sidebar/icon-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dragManager: Ember.inject.service(),
    draggable: true,
    selectable: false,
    isExpanded: Ember.computed('sideBarIconType', function () {
      return this.get('sideBarIconType') !== 'all';
    }),
    latestCoords: {
      x: 0,
      y: 0
    },
    hasIcons: Ember.computed.gt('icons.length', 0),
    onSelect: function onSelect() {},
    actions: {
      handleDragStart: function handleDragStart(type, icon, evt) {
        evt.stopPropagation();
        evt.preventDefault();
        this.set('dragManager.element', {
          type: type,
          icon: icon
        });
        this.get('dragManager').start();
        this.paper.commands.manager.initBatchCommand();
      },
      handleSelect: function handleSelect(type, icon) {
        if (!this.get('selectable')) {
          return;
        }

        this.onSelect(type, icon);
      },
      toggleExpanded: function toggleExpanded() {
        this.toggleProperty('isExpanded');
      }
    }
  });

  _exports.default = _default;
});