define("funnelytics/components/canvas-elements/entity-toolbar/colour-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    onSelectColour: function onSelectColour() {},
    currentSelectedHex: null,
    actions: {
      selectColour: function selectColour(hex) {
        var handleSelect = this.get('onSelectColour');
        handleSelect(hex);
      }
    }
  });

  _exports.default = _default;
});