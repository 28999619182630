define("funnelytics/helpers/kebab-case", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.kebabCase = kebabCase;
  _exports.default = void 0;

  function kebabCase(params
  /*, hash*/
  ) {
    if (!params[0]) {
      params[0] = '';
    }

    return _lodash.default.kebabCase(params[0]);
  }

  var _default = Ember.Helper.helper(kebabCase);

  _exports.default = _default;
});