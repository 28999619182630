define("funnelytics/services/strong-password", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var LENGTH_CHAIN = 3;
  var TYPE_PASSWORD = 'password';

  var _default = Ember.Service.extend({
    password: '',
    specificWords: [],
    isShort: Ember.computed(TYPE_PASSWORD, function () {
      return _lodash.default.get(this.get(TYPE_PASSWORD), 'length', 0) < 8;
    }),
    initialize: function initialize(payload) {
      var specificWords = [];

      if (payload.first_name) {
        specificWords = specificWords.concat(payload.first_name.toLowerCase().split(' '));
      }

      if (payload.last_name) {
        specificWords = specificWords.concat(payload.last_name.toLowerCase().split(' '));
      }

      if (payload.phone) {
        specificWords = specificWords.concat(payload.phone.replace(/[^\d]/g, ''));
      }

      this.set('specificWords', specificWords);
      this.set('password', payload.password);
    },
    isRepeat: Ember.computed(TYPE_PASSWORD, function () {
      var totalRepeat = 0;
      var prevSymbol = '';
      var withoutRepeat = this.get(TYPE_PASSWORD).split('').every(function (item) {
        totalRepeat = item === prevSymbol ? totalRepeat + 1 : 0;

        if (totalRepeat >= LENGTH_CHAIN) {
          return false;
        }

        prevSymbol = item;
        return true;
      });
      return !withoutRepeat;
    }),
    isSequential: Ember.computed(TYPE_PASSWORD, function () {
      var totalRepeat = 0;
      var prevSymbol;
      var withoutSequential = this.get(TYPE_PASSWORD).split('').every(function (item) {
        var currentNumber = parseInt(item);
        totalRepeat = currentNumber === prevSymbol + 1 ? totalRepeat + 1 : 0;

        if (totalRepeat >= LENGTH_CHAIN) {
          return false;
        }

        prevSymbol = currentNumber;
        return true;
      });
      return !withoutSequential;
    }),
    isContextSpecific: Ember.computed(TYPE_PASSWORD, function () {
      var _this = this;

      var withoutContextSpecific = this.get('specificWords').filter(function (item) {
        return item.length >= LENGTH_CHAIN;
      }).every(function (item) {
        if (_this.get(TYPE_PASSWORD).toLowerCase().includes(item)) {
          return false;
        }

        return true;
      });
      return !withoutContextSpecific;
    }),
    checkPassword: function checkPassword() {
      var error = null;

      if (this.isContextSpecific) {
        error = 'Context-specific words, such as the name of the service or username are not allowed';
      }

      if (this.isSequential || this.isRepeat) {
        error = 'Must not be sequential (ex. “1234”) or repeated (ex. “aaaa”) characters';
      }

      if (this.isShort) {
        error = 'Your password must be at least 8 characters';
      }

      return error;
    }
  });

  _exports.default = _default;
});