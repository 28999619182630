define("funnelytics/templates/components/vault/vault-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yOdN68fp",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"vault-left-nav\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"vault-left-nav__header\"],[9],[0,\"\\n      CATEGORY\\n    \"],[10],[0,\"\\n    \"],[7,\"button\"],[12,\"class\",[28,[\"fl-btn fl-btn--hero vault__category-picker \",[27,\"if\",[[23,[\"showFree\"]],\"vault__category-picker--active\",\"\"],null]]]],[3,\"action\",[[22,0,[]],[23,[\"showVaultType\"]],\"Free\"]],[9],[0,\"\\n      Free Templates\\n    \"],[10],[0,\"\\n    \"],[7,\"button\"],[12,\"class\",[28,[\"fl-btn fl-btn--hero vault__category-picker \",[27,\"if\",[[23,[\"showPremium\"]],\"vault__category-picker--active\",\"\"],null],\" \"]]],[3,\"action\",[[22,0,[]],[23,[\"showVaultType\"]],\"Premium\"]],[9],[0,\"\\n      Premium Templates\\n    \"],[10],[0,\"\\n    \"],[7,\"button\"],[12,\"class\",[28,[\"fl-btn fl-btn--hero vault__category-picker \",[27,\"if\",[[23,[\"showTemplates\"]],\"vault__category-picker--active\",\"\"],null]]]],[3,\"action\",[[22,0,[]],[23,[\"showVaultType\"]],\"Templates\"]],[9],[0,\"\\n      My Templates\\n    \"],[10],[0,\"\\n  \"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/vault/vault-filter.hbs"
    }
  });

  _exports.default = _default;
});