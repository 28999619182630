define("funnelytics/components/containers/funnel-editor/components/right-sidebar-action-editor", ["exports", "ember-uuid", "lodash", "funnelytics/custom/constants/CanvasConstants", "funnelytics/custom/analytics/analytics/canvas/EntityParameter", "funnelytics/custom/constants/ListenerConstants", "funnelytics/custom/constants/AnalyticsConstants"], function (_exports, _emberUuid, _lodash, _CanvasConstants, _EntityParameter, _ListenerConstants, _AnalyticsConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_STORED_PUBLISH_IDS = 100;

  var _default = Ember.Component.extend({
    onChange: function onChange() {},
    canvasService: Ember.inject.service(),
    analyticsService: Ember.inject.service(),
    model: null,
    name: null,
    properties: [],
    hasProperties: false,
    currentSku: Ember.computed('properties.@each.key', 'properties.@each.value', function () {
      var skuProperty = this.getEventProperty(_AnalyticsConstants.default.COMMERCE_SKU_KEY);

      if (!skuProperty) {
        return null;
      }

      return Ember.getWithDefault(skuProperty, 'value', null);
    }),
    eventEntity: Ember.computed('model', function () {
      var entity = this.get('canvasService').wrapEntity(this.get('model'));

      if (entity.getType() !== _CanvasConstants.default.TYPE_EVENT) {
        return null;
      }

      return entity;
    }),
    getEventProperty: function getEventProperty(key) {
      var property = this.getWithDefault('properties', []).find(function (property) {
        return Ember.get(property, 'key') === key;
      });

      if (!property) {
        return null;
      }

      return property;
    },
    ENTITY_LISTENERS: Ember.observer('eventEntity', function () {
      this.setupListeners();
    }),
    listenersEntity: null,
    isPropertyChangeDetected: false,
    setupListeners: function setupListeners() {
      var _this = this;

      this.cleanUpListeners();
      var eventEntity = this.get('eventEntity');

      if (eventEntity) {
        eventEntity.on(_ListenerConstants.default.ENTITY_ATTRIBUTES_CHANGE, function (attributes, _ref) {
          var publishId = _ref.id;

          if (_this.get('localAttributeChangePublishIds').includes(publishId)) {
            // Do not read properties to avoid infinite loop
            return;
          }

          _this.readProperties();
        }, {
          isDebounceLeading: false
        });
        this.set('listenersEntity', eventEntity);
      }
    },
    // Avoid infinite loop between publish and subscribe in this component
    localAttributeChangePublishIds: [],
    cleanUpListeners: function cleanUpListeners() {
      var listenersEntity = this.get('listenersEntity');

      if (listenersEntity) {
        /**
         * since we are silencing the attributes change in this component, it is valuable to publish the change
         * for other listeners do pick up on a changes that might have occured. during the lifetime of this
         * component.
         */
        if (this.get('isPropertyChangeDetected')) {
          listenersEntity.publishAttributesChange();
        }

        listenersEntity.removeAllListenersOnThisObject();
      }
    },
    changedName: Ember.observer('name', function () {
      var _this2 = this;

      Ember.run.once(this, function () {
        _lodash.default.set(_this2.get('model'), ['attributes', 'attrs', 'name', 'text'], _this2.get('name'));
      });
    }),
    hasPropertiesObserver: Ember.observer('hasProperties', function () {
      this.readProperties();
    }),
    isPropertyObserverOn: false,
    changedProperties: Ember.observer('properties.@each.key', 'properties.@each.value', function () {
      var isPropertyObserverOn = this.get('isPropertyObserverOn');

      if (!isPropertyObserverOn) {
        // Prevent listeners from firing when first loading this component.
        return;
      }

      this.set('isPropertyChangeDetected', true);
      var listenersEntity = this.get('listenersEntity');

      if (listenersEntity) {
        var publishId = (0, _emberUuid.v4)(); // Simple way to prevent it from growing too large:

        if (this.get('localAttributeChangePublishIds.length') > MAX_STORED_PUBLISH_IDS) {
          this.set('localAttributeChangePublishIds', []);
        }
        /**
         * Allow us to recognize local attribute changes to prevent infinite reaction loop between two local listeners
         */


        this.get('localAttributeChangePublishIds').pushObject(publishId); // Allow other parts of the app to react to changes made in this component

        listenersEntity.publishAttributesChange({
          id: publishId
        });
      }

      Ember.run.once(this, function () {
        var eventEntity = this.get('eventEntity');

        if (eventEntity) {
          var entityParameters = this.get('properties').toArray().map(function (_ref2) {
            var key = _ref2.key,
                value = _ref2.value;
            return new _EntityParameter.default({
              key: key,
              value: value
            });
          });
          /**
           * Do not publish the attributes change to avoid an infinite loop with readProperties and setupListeners
           */

          eventEntity.replaceAllAttributes(entityParameters, {
            isPublished: false
          });
        }

        this.onChange();
      });
    }),
    readProperties: function readProperties() {
      this.set('properties', Ember.A(_lodash.default.reduce(_lodash.default.get(this.get('model'), 'attributes.attrs.properties'), function (arr, value, key) {
        arr.push(Ember.Object.create({
          key: key,
          value: value
        }));
        return arr;
      }, [])));
    },
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      this.set('name', _lodash.default.has(this.get('model'), 'attributes.attrs.name') ? Object.assign({}, this.get('model').attr('name')).text : '');
      this.readProperties();
      this.setupListeners();
      this.set('isPropertyObserverOn', true);
      Ember.$('.edit-panel').on('input', '.text', function (evt) {
        if (evt.target.dataset.attribute === 'attrs/name/text') {
          _this3.set('name', evt.target.value);
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      Ember.$('.edit-panel .text').off('keyup');
      this.cleanUpListeners();
    },
    actions: {
      addProperty: function addProperty() {
        this.get('properties').addObject({});
        this.get('analyticsService').set('analyticsAreStale', true);
      },
      eventChangeHandler: function eventChangeHandler() {
        this.onChange();
      },
      removeProperty: function removeProperty(property) {
        this.get('properties').removeObject(property);
        this.get('analyticsService').set('analyticsAreStale', true);
      },
      selectSku: function selectSku(sku) {
        var skuProperty = this.getEventProperty(_AnalyticsConstants.default.COMMERCE_SKU_KEY);

        if (!skuProperty) {
          return this.get('properties').pushObject({
            key: _AnalyticsConstants.default.COMMERCE_SKU_KEY,
            value: sku
          });
        }

        skuProperty.set('value', sku);
      }
    }
  });

  _exports.default = _default;
});