define("funnelytics/templates/components/sales-funnels/image-with-quote", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y9o20Rhr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"testimonal-quote\"],[9],[0,\"\\n  \"],[7,\"img\"],[12,\"src\",[21,\"image\"]],[11,\"class\",\"testimonial-quote__img\"],[9],[10],[0,\"\\n\"],[4,\"for-reuse/card\",null,[[\"content\"],[true]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"testimonial-quote__quote\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"testimonial-quote__name\"],[9],[0,\"\\n        \"],[1,[21,\"name\"],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[1,[21,\"quote\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/sales-funnels/image-with-quote.hbs"
    }
  });

  _exports.default = _default;
});