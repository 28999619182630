define("funnelytics/custom/analytics/analytics-request/ProfileAttributesAnalyticsRequest", ["exports", "funnelytics/custom/constants/AnalyticsConstants", "funnelytics/custom/analytics/AnalyticsRequest", "funnelytics/custom/analytics/analytics-data/ProfileAttributesAnalyticsData"], function (_exports, _AnalyticsConstants, _AnalyticsRequest, _ProfileAttributesAnalyticsData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ProfileAttributesAnalyticsRequest = _AnalyticsRequest.default.extend({
    _mainAnalyticsData: _ProfileAttributesAnalyticsData.default.create(),
    _createAnalyticsData: function _createAnalyticsData(initialValues) {
      return _ProfileAttributesAnalyticsData.default.create(initialValues);
    },
    _sendRequest: function _sendRequest(analyticsSettings) {
      var _this = this;

      return Ember.RSVP.Promise.resolve(true).then(function () {
        return _this._getAjax().request('/profile-attributes/', {
          method: 'POST',
          host: _this._getAnalyticsApiHostname(),
          beforeSend: function beforeSend(xhr) {
            xhr.setRequestHeader(_AnalyticsConstants.default.PROJECT_ID_HEADER, analyticsSettings.getProject());
          },
          data: {
            filter: {
              device: analyticsSettings.getDevice(),
              canvas: analyticsSettings.getCanvasSteps(),
              focus: analyticsSettings.getFocus(),
              range: analyticsSettings.getRange()
            }
          }
        }).then(function (profileAttributesResults) {
          return {
            data: profileAttributesResults
          };
        });
      });
    }
  });

  var _default = ProfileAttributesAnalyticsRequest;
  _exports.default = _default;
});