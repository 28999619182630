define("funnelytics/templates/components/for-reuse/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZYji3Qjt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[12,\"class\",[28,[\"proto-button \",[21,\"customClass\"]]]],[12,\"disabled\",[21,\"disabled\"]],[3,\"action\",[[22,0,[]],[23,[\"buttonAction\"]]]],[9],[0,\"\\n  \"],[1,[21,\"title\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/button.hbs"
    }
  });

  _exports.default = _default;
});