define("funnelytics/templates/components/canvas-elements/button-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6c2tuX8J",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[11,\"class\",\"fl-btn fl-btn--compact fl-btn--dark fl-btn--with-focus\"],[11,\"type\",\"button\"],[3,\"action\",[[22,0,[]],\"handleClick\"]],[9],[0,\"\\n  \"],[1,[27,\"svg-display\",null,[[\"symbolDefId\"],[\"icon-addition\"]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/button-map.hbs"
    }
  });

  _exports.default = _default;
});