define("funnelytics/custom/constants/PanelConstants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var PANEL_CONSTANTS = {
    PANEL_REFERRERS: 'previous-steps',
    PANEL_UTMS: 'utms',
    PANEL_USER_LIST: 'users',
    PANEL_USER_INFO: 'user-info',
    PANEL_KPI: 'kpi',
    PANEL_TYPE_USER_INFO: 'user-info',
    PANEL_INSIGHTS: 'insights',
    PANEL_SETTINGS: 'settings',
    PANEL_FORECAST: 'forecast'
  };
  var _default = PANEL_CONSTANTS;
  _exports.default = _default;
});