define("funnelytics/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "funnelytics/config/environment", "ember-inflector"], function (_exports, _emberData, _dataAdapterMixin, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var JSONAPIAdapter = _emberData.default.JSONAPIAdapter;

  var _default = JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.endpoint,
    authenticator: 'authenticator:jwt',
    authorize: function authorize(xhr) {
      var _this$get = this.get('session.data.authenticated'),
          access_token = _this$get.access_token;

      if (!access_token) {
        return;
      }

      xhr.setRequestHeader('Authorization', "Bearer ".concat(access_token));
    },
    pathForType: function pathForType(type) {
      if (type === 'library') {
        return type;
      }

      return Ember.String.dasherize((0, _emberInflector.pluralize)(type));
    }
  });

  _exports.default = _default;
});