define("funnelytics/models/analytics-range", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    level: _emberData.default.attr('string'),
    start_datetime: _emberData.default.attr('date'),
    end_datetime: _emberData.default.attr('date'),
    deleted_at: _emberData.default.attr('date'),
    user: _emberData.default.belongsTo('user'),
    isActive: Ember.computed('deleted_at', function () {
      return !this.get('deleted_at');
    })
  });

  _exports.default = _default;
});