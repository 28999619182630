define("funnelytics/components/for-reuse/pagination-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.setPages();
    },
    hasPrev: Ember.computed('pagination.page', function () {
      var _this$get = this.get('pagination'),
          page = _this$get.page;

      return page > 1;
    }),
    showPageSelection: Ember.computed('pagination.totalPages', function () {
      var _this$get2 = this.get('pagination'),
          totalPages = _this$get2.totalPages;

      return totalPages > 1;
    }),
    hasNext: Ember.computed('pagination.page', 'pagination.totalPages', function () {
      var _this$get3 = this.get('pagination'),
          page = _this$get3.page,
          totalPages = _this$get3.totalPages;

      return page < totalPages;
    }),
    nextPage: Ember.computed('pagination.page', function () {
      var _this$get4 = this.get('pagination'),
          page = _this$get4.page;

      return page + 1;
    }),
    prevPage: Ember.computed('pagination.page', function () {
      var _this$get5 = this.get('pagination'),
          page = _this$get5.page;

      return page - 1;
    }),
    setPages: function setPages() {
      var _this$get6 = this.get('pagination'),
          totalPages = _this$get6.totalPages;

      var pages = [];

      for (var i = 1; i <= totalPages; i++) {
        pages.push(i);
      }

      this.set('pages', pages);
    },
    onClick: function onClick() {},
    actions: {
      onPageClick: function onPageClick(page) {
        var onClickHandler = this.get('onClick');
        onClickHandler(page);
      }
    }
  });

  _exports.default = _default;
});