define("funnelytics/components/account/registration-form", ["exports", "funnelytics/custom/misc/countries"], function (_exports, _countries) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isSubmitting: false,
    error: false,
    countryOptions: Object.keys(_countries.codeToCountryObject).map(function (code) {
      return {
        code: code,
        name: _countries.codeToCountryObject[code].name,
        dialCode: _countries.codeToCountryObject[code].dialCode
      };
    }),
    countryCode: 'us',
    phoneNumber: '',
    submitPayload: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
      user_type: null
    },
    init: function init() {
      this._super.apply(this, arguments);

      var name = this.getQueryString('fn');
      var email = this.getQueryString('cea');

      if (name) {
        this.set('submitPayload.first_name', name);
      }

      if (email) {
        this.set('submitPayload.email', email);
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments); // If the container is taking a lot of window space we will hide the background


      var wrapper = document.getElementById('registration-wrapper');
      var container = document.getElementById('registration-container');

      if (container) {
        var bounds = container.getBoundingClientRect();
        var windowHeight = window.innerHeight;
        var containerHeight = bounds.height;
        var remainingSpace = windowHeight - containerHeight;

        if (remainingSpace < 60) {
          wrapper.style.backgroundImage = 'none';
        } else {
          wrapper.style.backgroundImage = "url('/assets/fl-register-background.jpg')";
        }
      }
    },
    getQueryString: function getQueryString(name) {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      var regexS = '[\\?&]' + name + '=([^&#]*)';
      var regex = new RegExp(regexS);
      var results = regex.exec(window.location.search);
      if (results === null) return '';else return decodeURIComponent(results[1].replace(/\+/g, ' '));
    },
    onSubmit: function onSubmit() {},
    actions: {
      submit: function submit() {
        var handler = this.get('onSubmit');
        handler(this.get('submitPayload'));
      },
      updatePhoneCountry: function updatePhoneCountry(countryOption) {
        this.set('countryCode', countryOption.code);
        this.set('submitPayload.phone', "".concat(countryOption.dialCode, "-").concat(this.get('phoneNumber')));
      },
      updatePhoneNumber: function updatePhoneNumber(value) {
        var parsedValue = (value || '').replace(/\D/g, '').substring(0, 12);
        var parts = [parsedValue.substring(0, 3), parsedValue.substring(3, 6), parsedValue.substring(6, 12)];
        var formattedValue = parts.filter(function (p) {
          return p;
        }).join('-');
        this.set('phoneNumber', formattedValue);
        this.notifyPropertyChange('phoneNumber');

        var dialCode = _countries.codeToCountryObject[this.get('countryCode')].dialCode;

        this.set('submitPayload.phone', "".concat(dialCode, "-").concat(this.get('phoneNumber')));
      }
    }
  });

  _exports.default = _default;
});