define("funnelytics/components/dashboard-elements/projects", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    versionManager: Ember.inject.service(),
    tagName: 'nav',
    classNames: ['fl-ul', 'sidebar', 'dashboard-panel', 'dashboard-panel--toggleable'],
    classNameBindings: ['isPanelTransitioning:dashboard-panel--animating', 'isShowingProjects:dashboard-panel--visible'],
    transitioningObserver: Ember.observer('isShowingProjects', function () {
      this.set('isPanelTransitioning', true);
    }),
    sortedProjects: Ember.computed('projects.[]', 'versionManager.hasUnorganizedFunnel', 'userId', function () {
      var _this$versionManager$;

      var projects = this.get('projects').toArray();

      if ((_this$versionManager$ = this.versionManager.hasUnorganizedFunnel) === null || _this$versionManager$ === void 0 ? void 0 : _this$versionManager$.v1) {
        projects.pushObject(new Ember.Object({
          id: null,
          name: 'Unorganized Funnels',
          user: {
            id: this.get('userId')
          },
          version: 1
        }));
      }

      var filterProjects = projects.filter(function (project) {
        var version = project.get('version');
        return version === null || version === 1 || version === 2;
      });

      var organized = _lodash.default.reduce(filterProjects.sort(), function (hash, project) {
        var name = (project.get('name') || '').toLowerCase();
        var first = name.substr(0, 1).toUpperCase();

        if (hash[first]) {
          hash[first].push(project);
        } else {
          hash[first] = [project];
        }

        return hash;
      }, {});

      var sorted = Object.keys(organized).sort().reduce(function (acc, key) {
        acc[key] = organized[key];
        return acc;
      }, {});
      return sorted;
    }),
    onPanelTransitionEnd: function onPanelTransitionEnd() {
      this.set('isPanelTransitioning', false);
    },
    clickOffHandler: function clickOffHandler() {},
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.get('element').addEventListener('transitionend', function () {
        _this.onPanelTransitionEnd();
      }, false);
      var jqueryElement = Ember.$(this.get('element'));
      this.set('clickOffHandler', function (event) {
        var $target = Ember.$(event.target);

        if ($target.closest(jqueryElement).length === 0) {
          if (_this.get('isShowingProjects') && !_this.get('isPanelTransitioning')) {
            var onClickOff = _this.get('onClickOff');

            onClickOff();
          }
        }
      });
      Ember.$('html').on('click', this.get('clickOffHandler'));
    },
    willDestroyElement: function willDestroyElement() {
      var _this2 = this;

      this.get('element').removeEventListener('transitionend', function () {
        _this2.onPanelTransitionEnd();
      }, false);
      Ember.$('html').off('click', this.get('clickOffHandler'));
    },
    onClickOff: function onClickOff() {},
    onCreateProject: function onCreateProject() {},
    onSelectLockedProject: function onSelectLockedProject() {},
    actions: {
      createProject: function createProject() {
        var onClickOffHandler = this.get('onClickOff');
        var onCreateProjectHandler = this.get('onCreateProject');
        onClickOffHandler();
        onCreateProjectHandler();
      },
      selectProjectToUnlock: function selectProjectToUnlock(project) {
        var handler = this.get('onSelectLockedProject');
        handler(project);
      }
    }
  });

  _exports.default = _default;
});