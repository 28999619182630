define("funnelytics/routes/login", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "funnelytics/config/environment", "funnelytics/custom/constants/QueryParameters"], function (_exports, _unauthenticatedRouteMixin, _environment, _QueryParameters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    queryParams: _objectSpread({}, _QueryParameters.defaultQueryParams, {}, _QueryParameters.additionalQueryParams),
    redirectService: Ember.inject.service(),
    routeIfAlreadyAuthenticated: _environment.default['ember-simple-auth'].routeIfAlreadyAuthenticated,
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      this.get('redirectService').setRedirectProperties();
    },
    afterModel: function afterModel() {
      //Dynamic query params redirect when login is successful or redirected.
      this.get('redirectService').setRedirectProperties();
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('loggingIn', false);
    }
  });

  _exports.default = _default;
});