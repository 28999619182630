define("funnelytics/templates/components/canvas-elements/analytics-loading-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/3TQv7Js",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"analytics-status analytics-status--loading\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"class\",\"icon-blue mr-2\"],[11,\"src\",\"/assets/sidebar-icons/info-icon-blue.svg\"],[9],[10],[0,\"\\n      \"],[7,\"span\"],[9],[0,\"Fetching data\"],[1,[21,\"renderedDots\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/analytics-loading-message.hbs"
    }
  });

  _exports.default = _default;
});