define("funnelytics/models/project-client", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;

  var _default = Model.extend({
    user: _emberData.default.belongsTo('user'),
    project: _emberData.default.belongsTo('project'),
    email: _emberData.default.attr('string'),
    permissions: _emberData.default.attr('number')
  });

  _exports.default = _default;
});