define("funnelytics/components/canvas-elements/commerce-skus", ["exports", "ember-uuid", "lodash", "funnelytics/custom/constants/AnalyticsConstants", "funnelytics/custom/analytics/analytics-request/ExplorerAnalyticsRequest", "funnelytics/custom/analytics/analytics/canvas/EntityParameter"], function (_exports, _emberUuid, _lodash, _AnalyticsConstants, _ExplorerAnalyticsRequest, _EntityParameter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    analyticsService: Ember.inject.service(),
    canvasService: Ember.inject.service(),
    ajax: Ember.inject.service(),
    NO_SKU_SELECTED: (0, _emberUuid.v4)(),
    mainAnalyticsRequestId: Ember.computed.readOnly('analyticsService.mainRequestId'),
    SYNC_WITH_MAIN_ANALYTICS: Ember.observer('mainAnalyticsRequestId', function () {
      this.loadSkus();
    }),
    hasEntity: Ember.computed.bool('entity'),
    clonedEntity: null,
    RELOAD_ON_NEW_ENTITY: Ember.observer('entity', function () {
      this.loadSkus();
    }),
    canReadAnalytics: Ember.computed.readOnly('canvasService.canReadAnalytics'),
    analyticsData: Ember.computed.readOnly('explorerAnalyticsRequest.mainAnalyticsData'),
    areSkusLoading: Ember.computed.readOnly('analyticsData.isLoading'),
    skus: Ember.computed('analyticsData.actionAttributes.@each', function () {
      var actionAttributes = this.getWithDefault('analyticsData.actionAttributes', []);
      var skuAttribute = actionAttributes.find(function (attribute) {
        return _lodash.default.get(attribute, [_AnalyticsConstants.default.ATTRIBUTE_KEY]) === _AnalyticsConstants.default.COMMERCE_SKU_KEY;
      });

      if (!skuAttribute) {
        return [];
      }

      return _lodash.default.get(skuAttribute, [_AnalyticsConstants.default.VALUES_LIST], []).map(function (attributeValue) {
        return _lodash.default.get(attributeValue, [_AnalyticsConstants.default.ATTRIBUTE_VALUE]);
      });
    }),
    isCurrentSkuNotInList: Ember.computed('currentSku', 'skus.@each', function () {
      return !this.get('skus').includes(this.get('currentSku'));
    }),
    initializeProperties: function initializeProperties() {
      this.setProperties({
        explorerAnalyticsRequest: _ExplorerAnalyticsRequest.default.create({
          name: 'commerce-skus-request',
          ajax: this.get('ajax'),
          canReadAnalytics: this.get('canReadAnalytics')
        }),
        entityManager: this.get('canvasService').getEntityManager()
      });
    },
    didInsertElement: function didInsertElement() {
      this.initializeProperties();
      this.loadSkus();
    },
    loadSkus: function loadSkus() {
      var entity = this.get('entity');

      if (!entity) {
        return;
      }

      var hasSkuAttribute = entity.hasAttributeByKey(_AnalyticsConstants.default.COMMERCE_SKU_KEY);
      var savedSkuValue = entity.getAttributeValue(_AnalyticsConstants.default.COMMERCE_SKU_KEY); // remove value set on sku to avoid filtering by it

      if (hasSkuAttribute) {
        entity.setAttributes([new _EntityParameter.default({
          key: _AnalyticsConstants.default.COMMERCE_SKU_KEY,
          value: null
        })]);
      }

      this.get('analyticsService').sendAnalyticsRequest(this.get('explorerAnalyticsRequest'), {
        isClearingExisting: false,
        exploredEntity: entity
      });

      if (hasSkuAttribute) {
        entity.setAttributes([new _EntityParameter.default({
          key: _AnalyticsConstants.default.COMMERCE_SKU_KEY,
          value: savedSkuValue
        })]);
      }

      this.get('analyticsService').queueStatsForElement(entity.getID());
    },
    onSelectSku: function onSelectSku() {},
    actions: {
      reloadSkus: function reloadSkus() {
        this.loadSkus();
      },
      selectSku: function selectSku(sku) {
        var handler = this.get('onSelectSku');

        if (sku === this.get('NO_SKU_SELECTED')) {
          return handler(null);
        }

        return handler(sku);
      }
    }
  });

  _exports.default = _default;
});