define("funnelytics/components/account/replace-card-modal", ["exports", "country-list", "lodash", "funnelytics/custom/misc/CheckoutUtility"], function (_exports, _countryList, _lodash, _CheckoutUtility) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    isOpen: false,
    title: 'Replace Card',
    buttonText: 'Update Card',
    buttonTextLoading: 'Updating...',
    extraInformation: '',
    extraInformationHtml: Ember.computed('extraInformation', function () {
      return Ember.String.htmlSafe(this.get('extraInformation'));
    }),
    countries: _lodash.default.sortBy((0, _countryList.getData)(), 'name'),
    billingZipLabel: Ember.computed('billingCountry', function () {
      if (this.get('billingCountry') === 'US') {
        return 'ZIP Code';
      }

      return 'Postal Code';
    }),
    billingZipLabelLowerCase: Ember.computed('billingZipLabel', function () {
      return _lodash.default.lowerCase(this.get('billingZipLabel'));
    }),
    hasExtraInformation: Ember.computed.notEmpty('extraInformation'),
    ON_OPEN_OBSERVER: Ember.observer('isOpen', function () {
      this.resetCardStatus();
      this.initializeRecurly();
    }),
    formId: Ember.computed('elementId', function () {
      return "replace-card-form-".concat(this.get('elementId'));
    }),
    firstNameId: Ember.computed('elementId', function () {
      return "first-name-".concat(this.get('elementId'));
    }),
    lastNameId: Ember.computed('elementId', function () {
      return "last-name-".concat(this.get('elementId'));
    }),
    cardId: Ember.computed('elementId', function () {
      return "card-".concat(this.get('elementId'));
    }),
    formIdSelector: Ember.computed('formId', function () {
      var formId = this.get('formId');

      if (!_lodash.default.isString(formId)) {
        return undefined;
      }

      return "#".concat(formId);
    }),
    cardIdSelector: Ember.computed('cardId', function () {
      var cardId = this.get('cardId');

      if (!_lodash.default.isString(cardId)) {
        return undefined;
      }

      return "#".concat(cardId);
    }),
    initializeProperties: function initializeProperties() {
      this.setProperties({
        billingCountry: 'US',
        billingZip: null,
        isUpdating: false
      });
      this.resetCardStatus();
    },
    resetCardStatus: function resetCardStatus() {
      this.set('cardError', []);
    },

    /**
     * This needs to be run each time that the form is being focused on...
     * only one Recurly card form seems to be allowed to be visible on the
     * screen at a given time.
     */
    initializeRecurly: function initializeRecurly() {
      Ember.run.later(this, function () {
        if (Ember.$(this.get('cardIdSelector')).length !== 1) {
          this.initializeRecurly();
          return;
        }

        _CheckoutUtility.default.configureRecurly({
          cardSelector: this.get('cardIdSelector'),
          hasIndividualCardFields: false
        });
      }, 100);
    },
    didInsertElement: function didInsertElement() {
      this.initializeProperties();
      this.initializeRecurly();
    },
    handleClose: function handleClose() {
      var closeHandler = this.get('onClose');
      closeHandler();
    },
    onClose: function onClose() {},
    onCardUpdated: function onCardUpdated() {},
    onSuccess: function onSuccess() {},
    actions: {
      close: function close() {
        this.handleClose();
      },
      setBillingZip: function setBillingZip(evt) {
        this.set('billingZip', evt.target.value);
      },
      setCountry: function setCountry(evt) {
        this.set('billingCountry', evt.target.value);
      },
      addMethod: function addMethod() {
        var _this = this;

        if (!this.get('billingZip')) {
          this.set('cardError', ["missing ".concat(this.get('billingZipLabelLowerCase'))]);
          return;
        }

        this.set('isUpdating', true);
        recurly.token(Ember.$(this.get('formIdSelector'))[0], function (err, token) {
          _this.set('cardError', []);

          if (err) {
            err.fields.forEach(function (error) {
              _this.get('cardError').addObject(_lodash.default.lowerCase(error));
            });

            _this.set('cardErrorLength', _this.get('cardError').length - 1);

            _this.set('isUpdating', false);
          } else {
            _this.get('ajax').request('/payment-methods/create', {
              method: 'POST',
              data: {
                token: token.id
              }
            }).then(function (result) {
              _this.set('updateCardSuccess', true);

              _this.set('isUpdating', false);

              var announceCardUpdate = _this.get('onCardUpdated');

              announceCardUpdate([{
                endingNumber: result.data.billing_info.last_four,
                expiryDate: "".concat(result.data.billing_info.month._, "/").concat(result.data.billing_info.year._),
                default: true
              }]);

              var handleSuccess = _this.get('onSuccess');

              handleSuccess();
            }).catch(function (err) {
              _this.set('isUpdating', false);

              var error = _lodash.default.get(err, ['payload', 'response', 'data', 'errors', 'error', '_']);

              _this.set('updateCardError', error || 'There was an error adding your card. Please try a different card.');
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});