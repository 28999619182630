define("funnelytics/components/containers/funnel-editor/components/right-sidebar-analytics-country", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Required props
    totalVisitorsCount: null,
    countryIndex: null,
    countryData: null,
    countryCode: Ember.computed.readOnly('countryData.countryCode'),
    countryName: Ember.computed.readOnly('countryData.countryName'),
    visitorsCount: Ember.computed.readOnly('countryData.visitorsCount'),
    visitorsPercent: Ember.computed('totalVisitorsCount', 'visitorsCount', function () {
      var totalVisitorsCount = this.get('totalVisitorsCount');

      if (!totalVisitorsCount) {
        return '-';
      }

      var rawPercent = this.get('visitorsCount') / totalVisitorsCount * 100;

      if (rawPercent < 0.5) {
        return '<1';
      }

      return Math.ceil(rawPercent);
    }),
    countryIndexDisplay: Ember.computed('countryIndex', function () {
      var countryIndex = this.get('countryIndex');

      if (!countryIndex) {
        return null;
      }

      return "".concat(this.get('countryIndex'), ".");
    })
  });

  _exports.default = _default;
});