define("funnelytics/routes/dashboard/order/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    stripe: Ember.inject.service('stripev3'),
    params: null,
    beforeModel: function beforeModel() {
      return; //return this.get('stripe').load();
    },
    model: function model(params) {
      this.set('params', params);
      return Ember.RSVP.hash({
        user: this.modelFor('dashboard').user,
        products: this.modelFor('dashboard.order')
      });
    },
    afterModel: function afterModel() {
      window.scrollTo(0, 0);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.send('setTotal');
      controller.send('nameCart');
      controller.set('cart', [this.get('params').id]);
      controller.set('user', model.user);
    },
    renderTemplate: function renderTemplate() {
      window.location = 'https://order.funnelytics.io/cart-checkout/the-vault/';
      return;
      /*this.render('dashboard.order.index', {
        into: 'application'
      });*/
    }
  });

  _exports.default = _default;
});