define("funnelytics/templates/components/for-reuse/suggested-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Uu2mOOJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"isDisplayed\"]]],null,{\"statements\":[[0,\"  \"],[7,\"button\"],[12,\"class\",[28,[\"fl-btn \",[21,\"additionalClasses\"]]]],[12,\"disabled\",[21,\"isButtonDisabled\"]],[11,\"type\",\"button\"],[3,\"action\",[[22,0,[]],\"click\"]],[9],[0,\"\\n    \"],[1,[21,\"label\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/suggested-action.hbs"
    }
  });

  _exports.default = _default;
});