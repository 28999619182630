define("funnelytics/templates/components/canvas-elements/forecasting-set-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7awM25vr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[12,\"for\",[28,[[27,\"concat\",[[23,[\"uniqueId\"]],[23,[\"identifier\"]]],null]]]],[9],[0,\"\\n  \"],[1,[21,\"label\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"forecasting-overview__input-container\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"iconLeft\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"forecasting-overview__input-unit\"],[9],[0,\"\\n      \"],[1,[21,\"iconLeft\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"iconRight\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"forecasting-overview__input-unit forecasting-overview__input-unit--right\"],[9],[0,\"\\n      \"],[1,[21,\"iconRight\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,[\"readOnly\"]]],null,{\"statements\":[[0,\"    \"],[7,\"input\"],[12,\"id\",[28,[[27,\"concat\",[[23,[\"uniqueId\"]],[23,[\"identifier\"]]],null]]]],[11,\"class\",\"rs-input\"],[12,\"value\",[27,\"format-metric\",[[23,[\"value\"]]],null]],[11,\"readonly\",\"\"],[11,\"disabled\",\"\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"canvas-elements/numerical-input\",null,[[\"inputClasses\",\"inputId\",\"value\",\"onInput\",\"syncDelay\",\"allowedDecimals\"],[[27,\"if\",[[23,[\"isLeftPaddingSmaller\"]],\"forecasting-overview__input--smaller-left-padding\",\"\"],null],[27,\"concat\",[[23,[\"uniqueId\"]],[23,[\"identifier\"]]],null],[23,[\"value\"]],[27,\"action\",[[22,0,[]],\"handleInput\"],null],[23,[\"setValueDelayInMs\"]],[23,[\"allowedDecimals\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/forecasting-set-input.hbs"
    }
  });

  _exports.default = _default;
});