define("funnelytics/custom/constants/QueryParameters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.additionalQueryParams = _exports.defaultQueryParams = void 0;
  var defaultQueryParams = {
    // project version
    v: {
      refreshModel: true
    },
    performance: {
      refreshModel: false
    },
    dcc: {
      refreshModel: false
    },
    discount: {
      refreshModel: false
    },
    utm_source: {
      refreshModel: false
    },
    utm_medium: {
      refreshModel: false
    },
    utm_campaign: {
      refreshModel: false
    },
    utm_content: {
      refreshModel: false
    },
    utm_term: {
      refreshModel: false
    },
    cea: {
      refreshModel: false
    },
    aID: {
      refreshModel: false
    },
    cvID: {
      refreshModel: false
    },
    is_vault: {
      refreshModel: false
    },
    reload: {
      refreshModel: false
    },
    redirectTo: {
      refreshModel: false
    },
    ref: {
      refreshModel: false
    },
    partner: {
      refreshModel: false
    },
    checkout: {
      refreshModel: false
    },
    jn: {
      refreshModel: false
    },
    cart: {
      refreshModel: false
    },
    // checkout page to redirect after login/register
    next: {
      refreshModel: false
    }
  };
  _exports.defaultQueryParams = defaultQueryParams;
  var additionalQueryParams = {
    ws: {
      refreshModel: false
    }
  };
  _exports.additionalQueryParams = additionalQueryParams;
});