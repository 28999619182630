define("funnelytics/templates/components/flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4ND4mA9z",
    "block": "{\"symbols\":[\"link\",\"&default\"],\"statements\":[[4,\"if\",[[23,[\"isPredefinedTemplate\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"component\",[[23,[\"predefinedTemplate\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"template\"]]],null,{\"statements\":[[0,\"  \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"svg\"],[11,\"class\",\"fl-alert__icon\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isWarning\"]]],null,{\"statements\":[[0,\"      \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-status-error\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"isError\"]]],null,{\"statements\":[[0,\"      \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-analytics-error\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,[\"isInfo\"]]],null,{\"statements\":[[0,\"      \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-notification-circle\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"fl-alert__message\"],[9],[0,\"\\n    \"],[1,[21,\"message\"],false],[0,\"\\n\"],[4,\"if\",[[23,[\"hasLinks\"]]],null,{\"statements\":[[0,\"    \"],[7,\"ul\"],[11,\"class\",\"fl-alert__list\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"links\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"flash-message/link\",null,[[\"link\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\\n  \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/flash-message.hbs"
    }
  });

  _exports.default = _default;
});