define("funnelytics/templates/components/for-reuse/tracking-script", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mGfv1pVA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"textarea\"],[11,\"class\",\"txt--disabled fl--s--font wd--100 fl--grey--boarder txt--area--resize--no analytics-script\"],[12,\"rows\",[28,[[27,\"if\",[[23,[\"rows\"]],[23,[\"rows\"]],7],null]]]],[11,\"onClick\",\"this.select()\"],[11,\"readonly\",\"\"],[3,\"action\",[[22,0,[]],\"onScriptClick\"]],[9],[0,\"<script type=\\\"text/javascript\\\"> (function(funnel) {var deferredEvents = [];window.funnelytics = {events: {trigger: function(name, attributes, callback, opts) {deferredEvents.push({ name: name, attributes: attributes, callback: callback, opts: opts });}}};var insert = document.getElementsByTagName('script')[0], script = document.createElement('script'); script.addEventListener('load', function() { window.funnelytics.init(funnel, false, deferredEvents); }); script.src = 'https://cdn.funnelytics.io/track-v3.js'; script.type = 'text/javascript'; script.async = true; insert.parentNode.insertBefore(script, insert); })('\"],[1,[21,\"projectID\"],false],[0,\"'); </script>\\n\"],[10],[0,\"\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/tracking-script.hbs"
    }
  });

  _exports.default = _default;
});