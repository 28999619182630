define("funnelytics/models/tracker-session", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    int_id: _emberData.default.attr('number'),
    device: _emberData.default.attr('string'),
    steps: _emberData.default.hasMany('tracker-step'),
    funnel: _emberData.default.belongsTo('funnel'),
    project: _emberData.default.belongsTo('funnel'),
    profile: _emberData.default.belongsTo('tracker-profile'),
    created_at: _emberData.default.attr('date'),
    updated_at: _emberData.default.attr('date')
  });

  _exports.default = _default;
});