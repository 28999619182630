define("funnelytics/templates/components/for-reuse/dropdown-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qR9aEyTg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"fl-input-wrapper\"],[3,\"click-outside\",[[27,\"action\",[[22,0,[]],\"clickOutside\"],null]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"fl-dropdown-button__label\"],[9],[0,\"\\n    \"],[1,[21,\"label\"],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"fl-dropdown-button__input-wrapper\"],[3,\"action\",[[22,0,[]],\"toggleOpened\"]],[9],[0,\"\\n    \"],[7,\"div\"],[12,\"class\",[28,[\"fl-input-fit \",[27,\"if\",[[23,[\"isShaded\"]],\"fl-input-fit--shaded\"],null]]]],[9],[0,\"\\n      \"],[1,[27,\"if\",[[23,[\"value\"]],[23,[\"value\"]],[23,[\"placeholder\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"unless\",[[23,[\"isDisabled\"]]],null,{\"statements\":[[0,\"      \"],[7,\"svg\"],[11,\"class\",\"fl-dropdown-button__chevron-down\"],[9],[0,\"\\n        \"],[7,\"use\"],[11,\"xlink:href\",\"/assets/symbol-defs.svg#icon-chevron-down\",\"http://www.w3.org/1999/xlink\"],[9],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"fl-dropdown-button__content-wrapper\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"opened\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"fl-dropdown-button__content\"],[3,\"action\",[[22,0,[]],\"clickInsideContent\"]],[9],[0,\"\\n        \"],[14,1],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/dropdown-button.hbs"
    }
  });

  _exports.default = _default;
});