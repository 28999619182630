define("funnelytics/templates/components/account/invoice-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+UWbSqZn",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"class\",\"fl-table-row card-body-text\"],[9],[0,\"\\n  \"],[1,[27,\"date-parser\",[[23,[\"invoice\",\"closed_at\"]]],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"fl-table-row card-body-text\"],[9],[0,\"\\n  Invoice #\"],[1,[21,\"invoiceId\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"fl-table-row card-body-text\"],[9],[0,\"\\n  \"],[1,[27,\"format-currency\",[[23,[\"invoice\",\"total_in_cents\"]],\"cents\"],null],false],[0,\" USD\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"fl-table-row card-body-text\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[23,[\"invoice\",\"state\"]],\"paid\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"payment-status paid\"],[9],[0,\"\\n    PAID\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"payment-status failed\"],[9],[0,\"\\n    FAILED\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"fl-table-row card-body-text\"],[9],[0,\"\\n  \"],[7,\"a\"],[12,\"href\",[21,\"invoicePdfUrl\"]],[11,\"class\",\"fl-btn fl-btn--brand\"],[3,\"action\",[[22,0,[]],\"downloadInvoice\"]],[9],[0,\"\\n    Download\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"fl-table-row txt--grey\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pointer-txt\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"openBill\"],null]],[9],[0,\"\\n    \"],[1,[27,\"if\",[[23,[\"showLineItem\"]],\"-\",\"+\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/account/invoice-summary.hbs"
    }
  });

  _exports.default = _default;
});