define("funnelytics/components/canvas-elements/account-settings-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    userDetails: Ember.inject.service(),
    authService: Ember.inject.service(),
    trackedLevel: Ember.computed('sessionsTierLimit', 'trackedSessions', function () {
      var sessionsTierLimit = this.get('sessionsTierLimit');
      var trackedSessions = this.get('trackedSessions');
      return sessionsTierLimit !== 0 ? 100 * Math.min(trackedSessions / sessionsTierLimit, 1) : 0;
    }),
    trackedLevelStyle: Ember.computed('trackedLevel', function () {
      return Ember.String.htmlSafe("width: ".concat(this.get('trackedLevel'), "%;"));
    }),
    actions: {
      logout: function logout() {
        this.get('authService').logout();
      }
    }
  });

  _exports.default = _default;
});