define("funnelytics/components/dashboard-elements/upsell/lite-trial", ["exports", "funnelytics/custom/constants/MembershipConstants"], function (_exports, _MembershipConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    modal: Ember.inject.service('current-routed-modal'),
    userDetails: Ember.inject.service(),
    cookies: Ember.inject.service(),
    router: Ember.inject.service(),
    projectPermissions: Ember.inject.service(),
    text: null,
    didInsertElement: function didInsertElement() {
      var membership = this.projectPermissions.membership;

      if (membership === _MembershipConstants.Membership.isStarter || membership === _MembershipConstants.Membership.isMarketer) {
        this.set('text', '<div>Looks like you have already signed up for free trial and your account ' + 'will be upgraded to Performance Pro plan promptly. Please wait a couple' + ' of minutes, then refresh the page.</div>');
      } else {
        this.set('text', "<div>Great news! It looks like you already have a ".concat(membership, "\n        subscription.</div><div>Hang tight -- we're redirecting you into your\n        account!</div><div>If the process takes too long to complete, click on\n        the button below and we will bring you there in the blink of an eye.</div>"));
      }

      document.getElementById('upsellBlock').innerHTML = this.text;
    },
    actions: {
      closeModal: function closeModal() {
        this.router.transitionTo('dashboard');
        this.modal.close();
      }
    }
  });

  _exports.default = _default;
});