define("funnelytics/components/canvas-elements/analytics-toolbar/people-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    onFocusOnSession: function onFocusOnSession() {},
    onLoadAdditionalSessions: function onLoadAdditionalSessions() {},
    actions: {
      focusOnSession: function focusOnSession(user) {
        var handler = this.get('onFocusOnSession');
        handler(user);
      },
      loadAdditionalSessions: function loadAdditionalSessions() {
        var handler = this.get('onLoadAdditionalSessions');
        handler();
      }
    }
  });

  _exports.default = _default;
});