define("funnelytics/components/canvas-elements/entity-notes-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    canvasService: Ember.inject.service(),
    onOpenStepNotes: function onOpenStepNotes() {},
    entity: Ember.computed('step', function () {
      return this.get('canvasService').wrapEntity(this.get('step'));
    }),
    image: Ember.computed('entity', function () {
      var entity = this.get('entity');

      if (!entity) {
        return '';
      }

      return entity.getImage();
    }),
    label: Ember.computed('entity', function () {
      var entity = this.get('entity');

      if (!entity) {
        return '';
      }

      var label = entity.getLabel();
      return label || 'No Label';
    }),
    notesPreview: Ember.computed('entity', function () {
      var entity = this.get('entity');

      if (!entity) {
        return '';
      }

      return entity.getNotesPreview();
    }),
    actions: {
      openStepNotes: function openStepNotes(model) {
        var openNotesHandler = this.get('onOpenStepNotes');
        openNotesHandler(model);
      }
    }
  });

  _exports.default = _default;
});