define("funnelytics/services/upsell-manager", ["exports", "funnelytics/custom/constants/CookieConstants", "funnelytics/custom/constants/MembershipConstants"], function (_exports, _CookieConstants, _MembershipConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var localStoragePerfOfferKey = 'perf_offer_decl';
  var localStorageMapProOfferKey = 'map_pro_offer_declined';

  var _default = Ember.Service.extend({
    projectPermissions: Ember.inject.service(),
    cookies: Ember.inject.service(),
    store: Ember.inject.service(),
    userDetails: Ember.inject.service(),
    workspaceSelector: Ember.inject.service(),
    declinedYearlyPerformanceOffer: localStorage.getItem(localStoragePerfOfferKey),
    declinedMapProChangePriceOffer: localStorage.getItem(localStorageMapProOfferKey),
    //Modals
    showPerformanceProUpsell: false,
    performanceProUpsellToPerformancePlus: false,
    showSubscriptionCTA: false,
    showMappingByLimit: Ember.computed('projectPermissions.ownedCanvasCount', 'projectPermissions.membership', function () {
      var cookiesService = this.get('cookies');
      var cookies = cookiesService.read();
      var cookieKey = "".concat(_CookieConstants.default.MAPPING_LIMIT_PREFIX).concat(this.get('userDetails.userId'));
      var hasMapPlusLimitCookie = !!cookies[cookieKey];
      var ownedCanvasCount = this.get('projectPermissions.ownedCanvasCount');
      var membership = this.get('projectPermissions.membership');
      return membership === _MembershipConstants.Membership.isMapping && ownedCanvasCount > 3 && !hasMapPlusLimitCookie;
    }),
    showLockFreeMapping: Ember.computed('manualShowLockFreeMapping', 'userDetails.membershipId', 'projectPermissions.user', function () {
      var membership = this.get('userDetails.membershipId');
      var user = this.get('projectPermissions').getUser();
      return this.get('manualShowLockFreeMapping') && membership === _MembershipConstants.MEMBERSHIP_CODES['Map Free'] && user.get('downgrade_date') === null;
    }),
    showLimitedUpsell: Ember.computed('declinedMapProChangePriceOffer', 'manualShowLimitedUpsell', 'userDetails.membershipId', 'projectPermissions.user', 'workspaceSelector.selectedV2Workspace.id', function () {
      var membership = this.get('userDetails.membershipId');
      var user = this.get('projectPermissions').getUser();
      var isCollaborator = this.get('projectPermissions').getProjectPermission(this.get('workspaceSelector.selectedV2Workspace.id'), 'isCollaborator');
      return !this.get('declinedMapProChangePriceOffer') && !isCollaborator && this.get('manualShowLimitedUpsell') && membership === _MembershipConstants.MEMBERSHIP_CODES.Limited && user.get('free_trial_started_at') !== null;
    }),
    // Performance Business and Performance Agencies allowed to have trial as well
    isPerformanceTrialUser: Ember.computed('projectPermissions.membership', 'projectPermissions.isInTrial', function () {
      var membership = this.get('projectPermissions.membership');
      var allowedMemberships = [_MembershipConstants.Membership.isPerformancePro, _MembershipConstants.Membership.isPerformanceBusiness, _MembershipConstants.Membership.isPerformanceAgency];
      return allowedMemberships.includes(membership) && this.get('projectPermissions.isInTrial');
    }),
    showYearlyUpsellNewUsers: Ember.computed('manualShowYearlyUpsellNewUsers', 'projectPermissions.membership', 'isPerformanceTrialUser', 'userDetails.otoOfferAccepted', function () {
      if (this.get('userDetails.otoOfferAccepted')) {
        return false;
      }

      var membership = this.get('projectPermissions.membership');
      var isPerformanceStarterUser = membership === _MembershipConstants.Membership.isPerformanceStarter;
      var isMapProLifetimeUser = membership === _MembershipConstants.Membership.isMapProLifetime;
      return !this.get('declinedYearlyPerformanceOffer') && this.get('manualShowYearlyUpsellNewUsers') && (isPerformanceStarterUser || isMapProLifetimeUser || this.get('isPerformanceTrialUser'));
    }),
    showPerformanceUpsell: Ember.computed('projectPermissions.membership', function () {
      var membershipsToDisplayUpsell = [_MembershipConstants.Membership.isLimited, _MembershipConstants.Membership.isMapping, _MembershipConstants.Membership.isMap, _MembershipConstants.Membership.isMapLifetime, _MembershipConstants.Membership.isMapPro, _MembershipConstants.Membership.isMapProLifetime, _MembershipConstants.Membership.isPerformanceStarter, _MembershipConstants.Membership.isPerformancePro, _MembershipConstants.Membership.isPerformanceBusiness];
      return membershipsToDisplayUpsell.includes(this.get('projectPermissions.membership'));
    }),
    showMapProPricingChangeModal: Ember.computed('manualShowMapProPricingChangeModal', 'projectPermissions.membership', function () {
      var membership = this.get('projectPermissions.membership');
      var isMapProUser = membership === _MembershipConstants.Membership.isMapPro;
      return !this.get('declinedMapProChangePriceOffer') && this.get('manualShowMapProPricingChangeModal') && isMapProUser;
    }),
    //Messages
    showSubscriptionCTAMessage: false,
    //Performance Pro Content
    showUnlockNotice: false,
    // Lock Free Mapping
    manualShowLockFreeMapping: true,
    // Upsell yearly plan for new customers
    manualShowYearlyUpsellNewUsers: true,
    // Upsell Map with discount for Limited users
    manualShowLimitedUpsell: true,
    // Map Pro modal about pricing change from 07/2024
    manualShowMapProPricingChangeModal: true,
    // Observer watches for changes to projectPermissions.updateStateTracker and
    showPerformanceProUpsellModal: function showPerformanceProUpsellModal(options) {
      if (options && options.performanceProUpsellToPerformancePlus) {
        this.set('performanceProUpsellToPerformancePlus', true);
      }

      this.setProperties({
        showPerformanceProUpsell: true,
        showUnlockNotice: false
      });
    },
    showPerformanceProUnlockWarningModal: function showPerformanceProUnlockWarningModal() {
      this.setProperties({
        showPerformanceProUpsell: true,
        showUnlockNotice: true
      });
    },
    resetPerformanceProUpsell: function resetPerformanceProUpsell() {
      this.setProperties({
        showPerformanceProUpsell: false,
        showUnlockNotice: false,
        performanceProUpsellToPerformancePlus: false
      });
    },
    resetMappingByLimit: function resetMappingByLimit() {
      this.setProperties({
        showMappingByLimit: false
      });
    },
    closeLockFreeMapping: function closeLockFreeMapping() {
      this.setProperties({
        manualShowLockFreeMapping: false
      });
    },
    closeLimitedUpsell: function closeLimitedUpsell() {
      this.setProperties({
        manualShowLimitedUpsell: false,
        declinedYearlyPerformanceOffer: true
      });
      localStorage.setItem(localStorageMapProOfferKey, true);
    },
    closeYearlyUpsellNewUsers: function closeYearlyUpsellNewUsers() {
      this.setProperties({
        manualShowYearlyUpsellNewUsers: false,
        declinedYearlyPerformanceOffer: true
      });
      localStorage.setItem(localStoragePerfOfferKey, true);
    },
    closeMapProPricingChangeModal: function closeMapProPricingChangeModal() {
      this.setProperties({
        manualShowMapProPricingChangeModal: false,
        declinedYearlyPerformanceOffer: true
      });
      localStorage.setItem(localStorageMapProOfferKey, true);
    }
  });

  _exports.default = _default;
});