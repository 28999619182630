define("funnelytics/custom/constants/ContextMenuActionType", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ContextMenuActionType = Object.freeze({
    SIMPLE: 'simple',
    MULTIPLE_OPTIONS: 'multiple',
    INPUT: 'input'
  });
  var _default = ContextMenuActionType;
  _exports.default = _default;
});