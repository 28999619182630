define("funnelytics/templates/components/for-reuse/subcomponents/checklist-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PeDJ9t7V",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"li\"],[11,\"class\",\"fl-list fl--mb--1 fl--mr--1\"],[9],[0,\"\\n  \"],[1,[27,\"svg-display\",null,[[\"symbolDefId\",\"tagName\"],[\"checkmark-icon\",\"\"]]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"upgrade-list fl--ml--05\"],[9],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/for-reuse/subcomponents/checklist-item.hbs"
    }
  });

  _exports.default = _default;
});