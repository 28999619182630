define("funnelytics/templates/components/planning/planning-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l41j0CtE",
    "block": "{\"symbols\":[],\"statements\":[[4,\"collapsible-panel\",null,[[\"title\",\"startHidden\",\"backButton\",\"isFunnelNotesOpen\",\"onClickBack\"],[\"NOTE SETTINGS\",false,true,[23,[\"isCanvasNotesEditorOpen\"]],[27,\"action\",[[22,0,[]],\"backToFunnelNotes\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"right-sidebar__section\"],[9],[0,\"\\n    \"],[7,\"label\"],[11,\"class\",\"sideBarLabel\"],[9],[0,\"\\n      Label\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"label-wrapper\"],[9],[0,\"\\n      \"],[7,\"input\"],[11,\"class\",\"sidebar-text notes-step-label\"],[12,\"value\",[21,\"label\"]],[12,\"oninput\",[27,\"action\",[[22,0,[]],\"inputLabelChange\"],null]],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"collapsible-panel\",null,[[\"title\",\"startHidden\"],[\"STEP NOTES\",false]],{\"statements\":[[0,\"  \"],[1,[27,\"planning/notes-editor\",null,[[\"funnel\",\"model\",\"isFunnel\"],[[23,[\"funnel\"]],[23,[\"model\"]],[23,[\"isFunnel\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/planning/planning-panel.hbs"
    }
  });

  _exports.default = _default;
});