define("funnelytics/routes/order/pro/index", ["exports", "lodash", "@funnelytics/utilities"], function (_exports, _lodash, _utilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _PRO_ANNUALLY_SESSION;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var RecurlySKUs = _utilities.constants.RecurlySKUs;
  var PRO_ANNUALLY_SKUS = [RecurlySKUs.PRO_ANNUALLY_TIERED_120K_TO_300K, RecurlySKUs.PRO_ANNUALLY_TIERED_300K_TO_600K, RecurlySKUs.PRO_ANNUALLY_TIERED_600K_TO_1200K, RecurlySKUs.PRO_ANNUALLY_TIERED_1200K_TO_3M, RecurlySKUs.PRO_ANNUALLY_TIERED_3M_TO_6M, RecurlySKUs.PRO_ANNUALLY_TIERED_6M_TO_12M];
  var PRO_ANNUALLY_SESSIONS = (_PRO_ANNUALLY_SESSION = {}, _defineProperty(_PRO_ANNUALLY_SESSION, RecurlySKUs.PRO_ANNUALLY_TIERED_120K_TO_300K, 300001), _defineProperty(_PRO_ANNUALLY_SESSION, RecurlySKUs.PRO_ANNUALLY_TIERED_300K_TO_600K, 600001), _defineProperty(_PRO_ANNUALLY_SESSION, RecurlySKUs.PRO_ANNUALLY_TIERED_600K_TO_1200K, 1200001), _defineProperty(_PRO_ANNUALLY_SESSION, RecurlySKUs.PRO_ANNUALLY_TIERED_1200K_TO_3M, 3000001), _defineProperty(_PRO_ANNUALLY_SESSION, RecurlySKUs.PRO_ANNUALLY_TIERED_3M_TO_6M, 6000001), _defineProperty(_PRO_ANNUALLY_SESSION, RecurlySKUs.PRO_ANNUALLY_TIERED_6M_TO_12M, 12000001), _PRO_ANNUALLY_SESSION);

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    primaryProduct: {
      type: 'subscription',
      userFacingName: 'Pro Annually',
      planCode: RecurlySKUs.PRO_ANNUALLY_TIERED_120K_TO_300K,
      cost: null,
      periodOfTime: 'year',
      periodSeparator: '/',
      upsellRoute: 'order.confirmation',
      addons: [],
      alternative: {
        type: 'subscription',
        userFacingName: 'Pro Monthly',
        planCode: RecurlySKUs.PRO_MONTHLY_TIERED,
        cost: null,
        periodOfTime: 'month',
        periodSeparator: '/',
        upsellRoute: 'order.confirmation'
      }
    },
    bumpProducts: [],
    setupController: function setupController(controller, model) {
      var _this = this;

      this._super(controller, model);

      var plans = [this.get('primaryProduct.planCode'), this.get('primaryProduct.alternative.planCode')];
      PRO_ANNUALLY_SKUS.forEach(function (sku) {
        if (!plans.includes(sku)) {
          plans.push(sku);
        }
      });
      this.get('ajax').request('/purchases/information', {
        method: 'GET',
        data: {
          plans: plans.join(','),
          products: _lodash.default.map(this.get('bumpProducts').toArray(), function (product) {
            return product.sku;
          }).join(','),
          add_ons: _lodash.default.map(this.get('primaryProduct.addons').toArray(), function (addon) {
            return addon.sku;
          }).join(',')
        }
      }).then(function (result) {
        // TODO: this will need to change if we start adding trials to plans.
        _this.set('primaryProduct.cost', _lodash.default.get(result, ['plans', _this.get('primaryProduct.planCode'), 'periodPriceInCents']) / 100);

        _this.set('primaryProduct.alternative.cost', _lodash.default.get(result, ['plans', _this.get('primaryProduct.alternative.planCode'), 'periodPriceInCents']) / 100);

        controller.set('proAnnuallyTiers', PRO_ANNUALLY_SKUS.map(function (sku) {
          return {
            price: _lodash.default.get(result, ['plans', sku, 'periodPriceInCents']),
            sessions: PRO_ANNUALLY_SESSIONS[sku]
          };
        }));

        _lodash.default.each(_this.get('bumpProducts'), function (product, index) {
          _this.set("bumpProducts.".concat(index, ".cost"), _lodash.default.get(result, ['products', product.sku, 'priceInCents']) / 100);
        });
      }).then(function () {
        controller.set('totalCost', _this.get('primaryProduct.cost'));
        window.dataLayer = window.dataLayer || []; // IMPORTANT! This code must be executed before all pushes to window.dataLayer.
        //GTM overrides this arrays push method with a custom implementation when it is first defined. Failing to execute this check can break the dataLayer

        return _this.controllerFor('order').get('user');
      }).then(function (user) {
        window.dataLayer.push({
          event: 'checkout',
          // name of event. In this case, always stays as checkout
          ecommerce: {
            // ecommerce object. This is the essential part of tracking. New EE data must always be pushed to this object
            checkout: {
              // name of an action. In this case, always stays as checkout
              actionField: {
                step: 1 // number of the checkout step that a user has entered (must always be 1 in this situation)

              },
              products: [{
                // list of product user has chosen before entering the checkout
                name: _this.get('primaryProduct.userFacingName'),
                // name of product that is currently viewed
                id: _this.get('primaryProduct.planCode'),
                // id of product
                price: _this.get('primaryProduct.cost'),
                // price of product
                category: 'funnelytics' // category of a product (options = 'funnelytics','course')

              }]
            }
          },
          user: {
            id: user.id,
            email: user.email
          }
        });
      });
      controller.set('copy', {
        'pro-explanation': {
          first: 'Unlimited Tracking'
        },
        exclusions: {
          'pro-explanation': {
            'map-funnels': true
          }
        }
      });
      controller.set('primaryProduct', this.get('primaryProduct'));
      controller.set('bumpProducts', this.get('bumpProducts'));
      controller.set('proInfo', true);
      controller.set('user', this.controllerFor('order').get('user'));
    }
  });

  _exports.default = _default;
});