define("funnelytics/controllers/forgot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    record: null,
    sent: Ember.computed.notEmpty('record'),
    sending: false,
    email: '',
    error: false,
    emailEmpty: Ember.computed.empty('email'),
    buttonDisabled: Ember.computed.or('emailEmpty', 'sending', 'sent'),
    actions: {
      reset: function reset() {
        var _this = this;

        this.set('sending', true);
        this.get('store').createRecord('password_reset', {
          email: this.get('email')
        }).save().then(function (reset) {
          _this.setProperties({
            error: false,
            record: reset,
            email: '',
            sending: false
          });
        }).catch(function (err) {
          _this.setProperties({
            error: err,
            sending: false
          });
        });
      }
    }
  });

  _exports.default = _default;
});