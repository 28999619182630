define("funnelytics/templates/components/canvas-elements/numerical-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VoabwepV",
    "block": "{\"symbols\":[],\"statements\":[[0,\"  \"],[7,\"input\"],[12,\"id\",[28,[[21,\"inputId\"]]]],[12,\"class\",[28,[[27,\"if\",[[23,[\"replaceClass\"]],\"\",\"rs-input\"],null],\" \",[21,\"inputClasses\"]]]],[12,\"value\",[21,\"_value\"]],[12,\"oninput\",[27,\"action\",[[22,0,[]],\"handleNumericalInput\"],null]],[12,\"disabled\",[21,\"disabled\"]],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/canvas-elements/numerical-input.hbs"
    }
  });

  _exports.default = _default;
});