define("funnelytics/components/canvas-elements/entity-toolbar/shape", ["exports", "lodash", "funnelytics/custom/constants/ColourHexConstants"], function (_exports, _lodash, _ColourHexConstants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    /**
     * Actions that need to be passed into the component:
     */
    onClickChangeFill: function onClickChangeFill() {},
    onClickChangeStroke: function onClickChangeStroke() {},
    onClickContext: function onClickContext() {},
    RESET_ON_NOT_VISIBLE: Ember.observer('isTransparent', function () {
      if (this.get('isTransparent')) {
        this.closeAllDropdowns();
      }
    }),
    fillColourOptions: Ember.computed('colourOptions', function () {
      var _ref, _ref2;

      return [].concat(_toConsumableArray(this.get('colourOptions')), [(_ref = {}, _defineProperty(_ref, _ColourHexConstants.default.COLOUR_HEX_KEY, _ColourHexConstants.default.WHITE), _defineProperty(_ref, _ColourHexConstants.default.COLOUR_CSS_CLASS, 'entity-toolbar__colour-picker-option--white'), _ref), (_ref2 = {}, _defineProperty(_ref2, _ColourHexConstants.default.COLOUR_HEX_KEY, _ColourHexConstants.default.NONE), _defineProperty(_ref2, _ColourHexConstants.default.COLOUR_CSS_CLASS, 'entity-toolbar__colour-picker-option--none'), _ref2)]);
    }),
    isFillColourPickerClosed: Ember.computed.not('isFillColourPickerOpen'),
    isStrokeColourPickerClosed: Ember.computed.not('isStrokeColourPickerOpen'),
    isColourPickerClosed: Ember.computed.and('isFillColourPickerClosed', 'isStrokeColourPickerClosed'),
    currentlySelectedFillColourClass: Ember.computed('selectedFillColour', 'fillColourOptions', function () {
      var selectedColourHex = this.get('selectedFillColour');
      var colourOption = this.get('fillColourOptions').find(function (option) {
        return selectedColourHex === _lodash.default.get(option, _ColourHexConstants.default.COLOUR_HEX_KEY);
      });
      return _lodash.default.get(colourOption, _ColourHexConstants.default.COLOUR_CSS_CLASS, '');
    }),
    currentlySelectedStrokeColourClass: Ember.computed('selectedStrokeColour', 'colourOptions', function () {
      var selectedColourHex = this.get('selectedStrokeColour');
      var colourOption = this.get('colourOptions').find(function (option) {
        return selectedColourHex === _lodash.default.get(option, _ColourHexConstants.default.COLOUR_HEX_KEY);
      });
      return _lodash.default.get(colourOption, _ColourHexConstants.default.COLOUR_CSS_CLASS, '');
    }),
    closeAllDropdowns: function closeAllDropdowns() {
      this.setProperties({
        isFillColourPickerOpen: false,
        isStrokeColourPickerOpen: false
      });
    },
    initializeProperties: function initializeProperties() {
      this.closeAllDropdowns();
    },
    didInsertElement: function didInsertElement() {
      this.initializeProperties();
    },
    actions: {
      toggleFillColourPicker: function toggleFillColourPicker() {
        var currentState = this.get('isFillColourPickerOpen');
        this.closeAllDropdowns();
        this.set('isFillColourPickerOpen', !currentState);
      },
      toggleStrokeColourPicker: function toggleStrokeColourPicker() {
        var currentState = this.get('isStrokeColourPickerOpen');
        this.closeAllDropdowns();
        this.set('isStrokeColourPickerOpen', !currentState);
      },
      setFillColour: function setFillColour(colour) {
        var fillHandler = this.get('onClickChangeFill');
        fillHandler(colour);
      },
      setStrokeColour: function setStrokeColour(colour) {
        var strokeHandler = this.get('onClickChangeStroke');
        strokeHandler(colour);
      },
      clickContext: function clickContext() {
        var contextHandler = this.get('onClickContext');
        contextHandler();
      },
      clickRotate: function clickRotate() {
        var handleRotate = this.get('onClickRotate');
        handleRotate();
      }
    }
  });

  _exports.default = _default;
});