define("funnelytics/components/canvas-elements/numerical-input", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    valueUpdatedObserver: Ember.observer('value', function () {
      /**
       * We need to update the value in this way to ensure any changes to value are actually
       * reflected in this component.
       */
      this.syncValue();
    }),
    syncValue: function syncValue() {
      this.set('_value', this.formatValue(this.get('value')));
    },
    syncDelay: 500,
    _syncDelay: Ember.computed('syncDelay', function () {
      /**
       * This is slightly longer than the input value in order to prevent flashes between one value and the next
       * in case this syncDelay is set to be equivalent to another delay such as a debounced updater in a parent
       * component.
       */
      return this.get('syncDelay') + 100;
    }),
    allowedDecimals: 2,
    formatValue: function formatValue(value) {
      var number = parseFloat(value, 10);
      var options = {
        maximumFractionDigits: 2
      };

      if (!_lodash.default.isNumber(number) || isNaN(number)) {
        return '';
      }

      if (_lodash.default.isInteger(this.get('allowedDecimals'))) {
        options.maximumFractionDigits = this.get('allowedDecimals');
      }

      return number.toLocaleString('en-US', options);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('allowedDecimals', parseInt(this.get('allowedDecimals')));
      this.syncValue();
      /**
       * Will need to sync value when passed in value hasn't changed because the local value is understood
       * to be equal, such as "1.2" and "1" when only integer are parsed as valid (meaning both will be interpreted
       * as just "1")
       */

      this.set('syncValueDebounce', _lodash.default.debounce(function () {
        _this.syncValue();
      }, parseInt(this.get('_syncDelay'), 10)));
    },
    inputClasses: '',
    onInput: function onInput() {},
    actions: {
      handleNumericalInput: function handleNumericalInput(e) {
        var initialValue = e.target.value; // Need to set it to the wrong value for the fixed value to register as a change.

        this.set('_value', initialValue);
        var fixedValue = initialValue.replace(/[^0-9.,\s]+/, '');
        var inputHandler = this.get('onInput');
        this.set('_value', fixedValue);
        inputHandler(fixedValue);
        var valueSync = this.get('syncValueDebounce');
        valueSync();
      }
    }
  });

  _exports.default = _default;
});