define("funnelytics/helpers/style-generator", ["exports", "lodash"], function (_exports, _lodash) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.styleGenerator = styleGenerator;
  _exports.default = void 0;

  function styleGenerator(params, hash) {
    return Ember.String.htmlSafe(_lodash.default.reduce(hash, function (str, val, key) {
      str += "".concat(key, ": ").concat(val, " !important;");
      return str;
    }, ''));
  }

  var _default = Ember.Helper.helper(styleGenerator);

  _exports.default = _default;
});