define("funnelytics/templates/components/account/registration/phone-country-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7LYUHAt9",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\"],[11,\"class\",\"phone-country-code__selected-value\"],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"flag flag-\",[21,\"selectedCountryCode\"]]]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"for-reuse/dropdown-button\",null,null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"phone-country-code__options\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"countryOptions\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"onclick\",[27,\"action\",[[22,0,[]],\"selectCountry\",[22,1,[]]],null]],[12,\"class\",[28,[\"phone-country-code__option \",[27,\"if\",[[27,\"eq\",[[23,[\"selectedCountryCode\"]],[22,1,[\"code\"]]],null],\"selected\",\"\"],null]]]],[9],[0,\"\\n        \"],[7,\"div\"],[12,\"class\",[28,[\"flag flag-\",[22,1,[\"code\"]]]]],[9],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"phone-country-code__option--country-name\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"phone-country-code__option--dial-code\"],[9],[1,[22,1,[\"dialCode\"]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "funnelytics/templates/components/account/registration/phone-country-code.hbs"
    }
  });

  _exports.default = _default;
});