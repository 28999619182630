define("funnelytics/services/workspace-selector", ["exports", "lodash", "funnelytics/custom/constants"], function (_exports, _lodash, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(n); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Service.extend({
    /**
     * Terminology of 'Workspace' references v1 vs v2 UI for displaying
     * projects (aka dashboard version) and the valid projects and funnels within it.
     * Unintentional overlap of the term 'workspace'.
     */
    colors: Ember.inject.service(),
    versionManager: Ember.inject.service(),
    userDetails: Ember.inject.service(),
    unlockCanvases: Ember.inject.service(),
    projectPermissions: Ember.inject.service(),
    myDefaultWorkspace: undefined,
    selectedV2Workspace: undefined,
    allV2Workspaces: {},
    navBarOrderV2: [],
    versionSeparatedProjects: {},
    hasUserOwnedWorkspace: false,
    overridenProjectId: null,
    defineV2Workspaces: function defineV2Workspaces(sortedProjects) {
      var _this = this,
          _this$versionManager$,
          _this$versionManager$2;

      this.setProperties({
        selectedV2Workspace: undefined,
        allV2Workspaces: {},
        navBarOrderV2: []
      });
      var colorCounter = 0;
      var navBarOrderV2 = sortedProjects.map(function (project) {
        var _project$name$;

        var shortName = (_project$name$ = project.name[0]) === null || _project$name$ === void 0 ? void 0 : _project$name$.toUpperCase();
        var isLocked = project.data.is_locked;
        var definedProj = Object.freeze({
          id: project.id,
          name: project.name,
          shortName: shortName,
          locked: isLocked,
          color: isLocked ? '#B9C1CE' : _this.colors.generateNextColor(colorCounter++)
        });
        _this.allV2Workspaces[project.id] = definedProj;
        return definedProj;
      });

      if (((_this$versionManager$ = this.versionManager.hasUnorganizedFunnel) === null || _this$versionManager$ === void 0 ? void 0 : _this$versionManager$.v1) || ((_this$versionManager$2 = this.versionManager.hasUnorganizedFunnel) === null || _this$versionManager$2 === void 0 ? void 0 : _this$versionManager$2.v2)) {
        var unorganizedProj = {
          id: _constants.NULL_PROJECT,
          name: 'Unorganized Canvases',
          shortName: 'U',
          color: this.colors.generateNextColor(colorCounter)
        };
        navBarOrderV2.push(unorganizedProj);
        this.allV2Workspaces[_constants.NULL_PROJECT] = unorganizedProj;
      }

      if (navBarOrderV2.length > 0) {
        this.set('navBarOrderV2', navBarOrderV2);
        this.set('myDefaultWorkspace', navBarOrderV2[0]);
        this.setCurrentV2Workspace(navBarOrderV2[0].id, true);
      }
    },
    fetchUnlockRestrictions: function fetchUnlockRestrictions() {
      this.get('unlockCanvases').fetchUnlockRestrictions();
    },
    setCurrentV2Workspace: function setCurrentV2Workspace(projectId) {
      var overrideIfPossible = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var project;

      if (overrideIfPossible && this.overridenProjectId && this.allV2Workspaces[this.overridenProjectId]) {
        project = this.allV2Workspaces[this.overridenProjectId];
      } else {
        project = this.allV2Workspaces[projectId];
      }

      this.set('selectedV2Workspace', project);
    },
    overrideProjectId: function overrideProjectId(id) {
      this.set('overridenProjectId', id);
    },
    renameWorkspace: function renameWorkspace(projectId, name) {
      if (this.allV2Workspaces[projectId]) {
        var shortName = name[0].toUpperCase();
        this.set("allV2Workspaces.".concat(projectId, ".name"), name);
        this.set("allV2Workspaces.".concat(projectId, ".shortName"), shortName);
        var elIndex = this.get('navBarOrderV2').findIndex(function (el) {
          return el.id === projectId;
        });
        this.set("navBarOrderV2[".concat(elIndex, "]"), this.allV2Workspaces[projectId]);
      }
    },
    sortWorkspaces: function sortWorkspaces(projects) {
      var _this2 = this;

      /** Project version definition:
       * v1 projects, show only in V1 Dashboard
       * v2 projects, show in V1 and V2 Dashboard
       * v3 projects, show only in V2 Dashboard
       * v4 projects, contain mixed v1 and v2 canvases show only in V2 Dashboard
       */
      var projectsSorted = {
        v1v2: [],
        v2Plus: [],
        //All unlocked v2+ projects the user has access to
        v4: [],
        userOwnedUnlockedV2Plus: [],
        //All unlocked v2+ projects the user owns
        userOwnedLockedV2Plus: [],
        //All locked v2+ projects the user owns
        accessibleV2Plus: [] //All unlocked collaborator/shared v2+ projects

      }; //Sort projects

      projects.toArray().forEach(function (project) {
        if (project.version === null || project.version === 1 || project.version === 2) {
          projectsSorted.v1v2.push(project);
        }

        if (project.version >= 2) {
          if (_this2.get('projectPermissions').v2ProjectBelongsToUser(project)) {
            if (!project.data.is_locked) {
              projectsSorted.userOwnedUnlockedV2Plus.push(project);
            } else {
              projectsSorted.userOwnedLockedV2Plus.push(project);
            }
          } else {
            projectsSorted.accessibleV2Plus.push(project);
          }
        }

        if (project.version === 4) {
          // Needed for the Import function
          projectsSorted.v4.push(project);
        }
      });
      Object.keys(projectsSorted).forEach(function (key) {
        projectsSorted[key] = _lodash.default.sortBy(projectsSorted[key], 'name');
      });
      projectsSorted.unlockedProjects = [].concat(_toConsumableArray(projectsSorted.userOwnedUnlockedV2Plus), _toConsumableArray(projectsSorted.accessibleV2Plus));
      projectsSorted.v2Plus = [].concat(_toConsumableArray(projectsSorted.unlockedProjects), _toConsumableArray(projectsSorted.userOwnedLockedV2Plus));
      var versionSeparatedProjects = {
        v1v2: projectsSorted.v1v2,
        v2Plus: projectsSorted.v2Plus,
        v4: projectsSorted.v4,
        v2PlusUnlockedOnly: projectsSorted.unlockedProjects
      };
      this.set('versionSeparatedProjects', versionSeparatedProjects);
      this.set('hasUserOwnedWorkspace', !![].concat(_toConsumableArray(projectsSorted.userOwnedUnlockedV2Plus), _toConsumableArray(projectsSorted.userOwnedLockedV2Plus)).length > 0); //set project navigation bar order

      this.defineV2Workspaces(projectsSorted.v2Plus);
    }
  });

  _exports.default = _default;
});