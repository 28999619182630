define("funnelytics/components/canvas-elements/analytics-layer-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    canvasService: Ember.inject.service(),
    canReadAnalytics: Ember.computed.readOnly('canvasService.canReadAnalyticss'),
    actions: {
      onPrimaryAction: function onPrimaryAction(e) {
        e.preventDefault();

        if (this.get('disabled')) {
          return;
        }

        this.get('primaryAction')();
      },
      onUpgradePrompt: function onUpgradePrompt(type, plan, e) {
        e.preventDefault();
        this.get('upgradePrompt')(type, plan);
      }
    }
  });

  _exports.default = _default;
});