define("funnelytics/controllers/dashboard/icons/new", ["exports", "ember-uuid", "funnelytics/config/environment"], function (_exports, _emberUuid, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    hovering: false,
    pending: [],
    saving: false,
    saved: false,
    actions: {
      addFile: function addFile(file) {
        this.get('pending').addObject({
          record: this.get('store').createRecord('icon', {
            id: (0, _emberUuid.v4)(),
            folder: this.get('model'),
            name: file.blob.name
          }),
          file: file
        });
      },
      dropzoneEnter: function dropzoneEnter() {
        this.set('hovering', true);
      },
      dropzoneLeave: function dropzoneLeave() {
        this.set('hovering', false);
      },
      dropzoneDrop: function dropzoneDrop() {
        this.set('hovering', false);
      },
      setType: function setType(icon, nothing, event) {
        icon.record.set('type', event.target.value);
      },
      upload: function upload() {
        var _this = this;

        if (this.get('saving')) {
          return;
        }

        this.set('saving', true);
        return Ember.RSVP.map(this.get('pending'), function (icon) {
          return _this.get('ajax').request('/icons/issue', {
            method: 'POST',
            data: {
              id: icon.record.get('id'),
              folder: icon.record.get('folder.id')
            }
          }).then(function (data) {
            return _this.get('ajax').request(data.url, {
              method: 'PUT',
              processData: false,
              contentType: 'binary/octet-stream',
              data: icon.file.blob,
              dataType: 'text'
            });
          }).then(function () {
            icon.record.set('path', "".concat(_environment.default.bucket, "/icons/users/").concat(_this.get('session.data.authenticated.id'), "/").concat(icon.record.get('folder.id'), "/").concat(icon.record.get('id')));
            return icon.record.save();
          }).catch(function () {
            if (icon.record) {
              icon.record.destroyRecord();
            }
          });
        }).then(function () {
          _this.set('saved', true);
        });
      }
    }
  });

  _exports.default = _default;
});